import { dateFormateToDB } from "../components/common/common";
import config from "../config/config";

var api = {
  /* <!---   user Api's   --> */

  //User Register API
  add_user(loginUserID, idToken, userName, full_name, email, password) {
    let url = config.apiDomain + "/add_user";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        user_name: userName ? userName.toUpperCase() : null,
        full_name: full_name ? full_name.toUpperCase() : null,
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // User Login API
    userLogin(userName, password) {
    let url = config.apiDomain + "/user_login";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user_name: userName,
        password: password,
        //keep_logged_in: "1"
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //get user Roles
  getUserRoles() {
    let url = config.apiDomain + "/get_roles";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  //for changing password
  userChangePswd(loginUserID, userID, newPswd, oldPswd, idToken) {
    let url = config.apiDomain + "/change_password";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        user_id: userID,
        new_password: newPswd,
        old_password: oldPswd,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //send forgot_password_link
  forgotPswdLink(userName, email) {
    let url = config.apiDomain + "/forgot_password";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user_name: userName,
        email: email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //for update password
  updatePswd(userID, newPswd) {
    let url = config.apiDomain + "update_password";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user_id: userID,
        new_password: newPswd,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //get users List
  getUserList(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_users";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // get user based on userId
  getUser(userID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_user";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user_id: userID,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  //update user
  updateUser(userID, loginUserID, userName, email, idToken) {
    let url = config.apiDomain + "/update_user";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user_id: userID,
        login_user_id: loginUserID,
        user_name: userName,
        email: email,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //TODO :<!----------------------- Countries Api ---------------------------------!>

  getCountries(loginUserID, idToken) {
    let url = config.apiDomain + "/get_countries";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //TODO:<!------------------------  Customer Api's ---------------- -----------------> */

  // for add_customer
  addCustomer(
    loginUserID,
    idToken,
    customerType,
    otherCustomer,
    customerName,
    customerAddress,
    customerCountry,
    contactPsn1,
    contactPsnPhone1,
    contactPsnHandPhone1,
    contactPsnEmail1,
    contactPsn2,
    contactPsnPhone2,
    contactPsnHandPhone2,
    contactPsnEmail2,
    contactPsn3,
    contactPsnPhone3,
    contactPsnHandPhone3,
    contactPsnEmail3,
    contactPsn4,
    contactPsnPhone4,
    contactPsnHandPhone4,
    contactPsnEmail4,
    contactPsn5,
    contactPsnPhone5,
    contactPsnHandPhone5,
    contactPsnEmail5,
    bankAcHolderName1,
    bankName1,
    address1,
    bankACNo1,
    Currency1,
    swiftCode1,
    IBAN_NUM1,
    bankAcHolderName2,
    bankName2,
    address2,
    bankACNo2,
    Currency2,
    swiftCode2,
    IBAN_NUM2,
    bankAcHolderName3,
    bankName3,
    address3,
    bankACNo3,
    Currency3,
    swiftCode3,
    IBAN_NUM3,
    bankAcHolderName4,
    bankName4,
    address4,
    bankACNo4,
    Currency4,
    swiftCode4,
    IBAN_NUM4,
    bankAcHolderName5,
    bankName5,
    address5,
    bankACNo5,
    Currency5,
    swiftCode5,
    IBAN_NUM5,
    taxId,
    shareDocLink,
    status,
    files
  ) {
    let url = config.apiDomain + "/add_customer";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        customer_type: customerType,
        other_customer_type: otherCustomer.toUpperCase(),
        customer_name: customerName.toUpperCase(),
        customer_address: customerAddress.toUpperCase(),
        customer_country: customerCountry,

        contact_person_name_1: contactPsn1.toUpperCase(),
        contact_person_phone_1: contactPsnPhone1,
        contact_person_handphone_1: contactPsnHandPhone1,
        contact_person_email_1: contactPsnEmail1.toUpperCase(),

        contact_person_name_2: contactPsn2.toUpperCase(),
        contact_person_phone_2: contactPsnPhone2,
        contact_person_handphone_2: contactPsnHandPhone2,
        contact_person_email_2: contactPsnEmail2.toUpperCase(),

        contact_person_name_3: contactPsn3.toUpperCase(),
        contact_person_phone_3: contactPsnPhone3,
        contact_person_handphone_3: contactPsnHandPhone3,
        contact_person_email_3: contactPsnEmail3.toUpperCase(),

        contact_person_name_4: contactPsn4.toUpperCase(),
        contact_person_phone_4: contactPsnPhone4,
        contact_person_handphone_4: contactPsnHandPhone4,
        contact_person_email_4: contactPsnEmail4.toUpperCase(),

        contact_person_name_5: contactPsn5.toUpperCase(),
        contact_person_phone_5: contactPsnPhone5,
        contact_person_handphone_5: contactPsnHandPhone5,
        contact_person_email_5: contactPsnEmail5.toUpperCase(),

        bank_account_holder_name_1: bankAcHolderName1.toUpperCase(),
        bank_name_1: bankName1.toUpperCase(),
        bank_address_1: address1.toUpperCase(),
        bank_account_number_1: bankACNo1,
        currency_1: Currency1,
        swift_code_1: swiftCode1.toUpperCase(),
        iban_number_1: IBAN_NUM1.toUpperCase(),

        bank_account_holder_name_2: bankAcHolderName2.toUpperCase(),
        bank_name_2: bankName2.toUpperCase(),
        bank_address_2: address2.toUpperCase(),
        bank_account_number_2: bankACNo2.toUpperCase(),
        currency_2: Currency2,
        swift_code_2: swiftCode2.toUpperCase(),
        iban_number_2: IBAN_NUM2.toUpperCase(),

        bank_account_holder_name_3: bankAcHolderName3.toUpperCase(),
        bank_name_3: bankName3.toUpperCase(),
        bank_address_3: address3.toUpperCase(),
        bank_account_number_3: bankACNo3,
        currency_3: Currency3,
        swift_code_3: swiftCode3.toUpperCase(),
        iban_number_3: IBAN_NUM3.toUpperCase(),

        bank_account_holder_name_4: bankAcHolderName4.toUpperCase(),
        bank_name_4: bankName4.toUpperCase(),
        bank_address_4: address4.toUpperCase(),
        bank_account_number_4: bankACNo4,
        currency_4: Currency4,
        swift_code_4: swiftCode4.toUpperCase(),
        iban_number_4: IBAN_NUM4.toUpperCase(),

        bank_account_holder_name_5: bankAcHolderName5.toUpperCase(),
        bank_name_5: bankName5.toUpperCase(),
        bank_address_5: address5.toUpperCase(),
        bank_account_number_5: bankACNo5,
        currency_5: Currency5,
        swift_code_5: swiftCode5.toUpperCase(),
        iban_number_5: IBAN_NUM5.toUpperCase(),
        tax_id: taxId.toUpperCase(),
        share_point_doc_link: shareDocLink,
        status: status,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get_customers
  getCustomers(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_customers";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get_customer
  getCustomer(customerID, loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_customer";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        customer_id: customerID,
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for update_customer
  updateCustomer(
    loginUserID,
    idToken,
    customerId,
    customerType,
    otherCustomer,
    customerName,
    customerAddress,
    customerCountry,
    contactPsn1,
    contactPsnPhone1,
    contactPsnHandPhone1,
    contactPsnEmail1,
    contactPsn2,
    contactPsnPhone2,
    contactPsnHandPhone2,
    contactPsnEmail2,
    contactPsn3,
    contactPsnPhone3,
    contactPsnHandPhone3,
    contactPsnEmail3,
    contactPsn4,
    contactPsnPhone4,
    contactPsnHandPhone4,
    contactPsnEmail4,
    contactPsn5,
    contactPsnPhone5,
    contactPsnHandPhone5,
    contactPsnEmail5,
    bankAcHolderName1,
    bankName1,
    address1,
    bankACNo1,
    Currency1,
    swiftCode1,
    IBAN_NUM1,
    bankAcHolderName2,
    bankName2,
    address2,
    bankACNo2,
    Currency2,
    swiftCode2,
    IBAN_NUM2,
    bankAcHolderName3,
    bankName3,
    address3,
    bankACNo3,
    Currency3,
    swiftCode3,
    IBAN_NUM3,
    bankAcHolderName4,
    bankName4,
    address4,
    bankACNo4,
    Currency4,
    swiftCode4,
    IBAN_NUM4,
    bankAcHolderName5,
    bankName5,
    address5,
    bankACNo5,
    Currency5,
    swiftCode5,
    IBAN_NUM5,
    taxId,
    shareDocLink,
    status,
    existing_file_ids,
    new_files
  ) {
    let url = config.apiDomain + "/update_customer";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        customer_id: customerId,
        customer_type: customerType,
        other_customer_type: otherCustomer.toUpperCase(),
        customer_name: customerName.toUpperCase(),
        customer_address: customerAddress.toUpperCase(),
        customer_country: customerCountry,

        contact_person_name_1: contactPsn1.toUpperCase(),
        contact_person_phone_1: contactPsnPhone1,
        contact_person_handphone_1: contactPsnHandPhone1,
        contact_person_email_1: contactPsnEmail1.toUpperCase(),

        contact_person_name_2: contactPsn2.toUpperCase(),
        contact_person_phone_2: contactPsnPhone2,
        contact_person_handphone_2: contactPsnHandPhone2,
        contact_person_email_2: contactPsnEmail2.toUpperCase(),

        contact_person_name_3: contactPsn3.toUpperCase(),
        contact_person_phone_3: contactPsnPhone3,
        contact_person_handphone_3: contactPsnHandPhone3,
        contact_person_email_3: contactPsnEmail3.toUpperCase(),

        contact_person_name_4: contactPsn4.toUpperCase(),
        contact_person_phone_4: contactPsnPhone4,
        contact_person_handphone_4: contactPsnHandPhone4,
        contact_person_email_4: contactPsnEmail4.toUpperCase(),

        contact_person_name_5: contactPsn5.toUpperCase(),
        contact_person_phone_5: contactPsnPhone5,
        contact_person_handphone_5: contactPsnHandPhone5,
        contact_person_email_5: contactPsnEmail5.toUpperCase(),

        bank_account_holder_name_1: bankAcHolderName1.toUpperCase(),
        bank_name_1: bankName1.toUpperCase(),
        bank_address_1: address1.toUpperCase(),
        bank_account_number_1: bankACNo1.toUpperCase(),
        currency_1: Currency1,
        swift_code_1: swiftCode1.toUpperCase(),
        iban_number_1: IBAN_NUM1.toUpperCase(),

        bank_account_holder_name_2: bankAcHolderName2.toUpperCase(),
        bank_name_2: bankName2.toUpperCase(),
        bank_address_2: address2.toUpperCase(),
        bank_account_number_2: bankACNo2.toUpperCase(),
        currency_2: Currency2,
        swift_code_2: swiftCode2.toUpperCase(),
        iban_number_2: IBAN_NUM2.toUpperCase(),

        bank_account_holder_name_3: bankAcHolderName3.toUpperCase(),
        bank_name_3: bankName3.toUpperCase(),
        bank_address_3: address3.toUpperCase(),
        bank_account_number_3: bankACNo3.toUpperCase(),
        currency_3: Currency3,
        swift_code_3: swiftCode3.toUpperCase(),
        iban_number_3: IBAN_NUM3.toUpperCase(),

        bank_account_holder_name_4: bankAcHolderName4.toUpperCase(),
        bank_name_4: bankName4.toUpperCase(),
        bank_address_4: address4.toUpperCase(),
        bank_account_number_4: bankACNo4.toUpperCase(),
        currency_4: Currency4,
        swift_code_4: swiftCode4.toUpperCase(),
        iban_number_4: IBAN_NUM4.toUpperCase(),

        bank_account_holder_name_5: bankAcHolderName5.toUpperCase(),
        bank_name_5: bankName5.toUpperCase(),
        bank_address_5: address5.toUpperCase(),
        bank_account_number_5: bankACNo5.toUpperCase(),
        currency_5: Currency5,
        swift_code_5: swiftCode5.toUpperCase(),
        iban_number_5: IBAN_NUM5.toUpperCase(),
        tax_id: taxId.toUpperCase(),
        share_point_doc_link: shareDocLink,
        status: status,
        existing_file_ids: existing_file_ids,
        files: new_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //TODO:<!-------------------   Vendor Api's  ---------------------------------------> */

  //for add vendor
  addVendor(
    loginUserID,
    idToken,
    vendorType,
    otherVendor,
    vendorName,
    vendorAddress,
    vendorCountry,
    contactPsn1,
    contactPsnPhone1,
    contactPsnHandPhone1,
    contactPsnEmail1,
    contactPsn2,
    contactPsnPhone2,
    contactPsnHandPhone2,
    contactPsnEmail2,
    contactPsn3,
    contactPsnPhone3,
    contactPsnHandPhone3,
    contactPsnEmail3,
    contactPsn4,
    contactPsnPhone4,
    contactPsnHandPhone4,
    contactPsnEmail4,
    contactPsn5,
    contactPsnPhone5,
    contactPsnHandPhone5,
    contactPsnEmail5,
    bankAcHolderName1,
    bankName1,
    address1,
    bankACNo1,
    Currency1,
    swiftCode1,
    IBAN_NUM1,
    bankAcHolderName2,
    bankName2,
    address2,
    bankACNo2,
    Currency2,
    swiftCode2,
    IBAN_NUM2,
    bankAcHolderName3,
    bankName3,
    address3,
    bankACNo3,
    Currency3,
    swiftCode3,
    IBAN_NUM3,
    bankAcHolderName4,
    bankName4,
    address4,
    bankACNo4,
    Currency4,
    swiftCode4,
    IBAN_NUM4,
    bankAcHolderName5,
    bankName5,
    address5,
    bankACNo5,
    Currency5,
    swiftCode5,
    IBAN_NUM5,
    taxId,
    shareDocLink,
    status,
    files
  ) {
    let url = config.apiDomain + "/add_vendor";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_type: vendorType,
        other_vendor_type: otherVendor.toUpperCase(),
        vendor_name: vendorName.toUpperCase(),
        vendor_address: vendorAddress.toUpperCase(),
        vendor_country: vendorCountry,

        contact_person_name_1: contactPsn1.toUpperCase(),
        contact_person_phone_1: contactPsnPhone1,
        contact_person_handphone_1: contactPsnHandPhone1,
        contact_person_email_1: contactPsnEmail1.toUpperCase(),

        contact_person_name_2: contactPsn2.toUpperCase(),
        contact_person_phone_2: contactPsnPhone2,
        contact_person_handphone_2: contactPsnHandPhone2,
        contact_person_email_2: contactPsnEmail2.toUpperCase(),

        contact_person_name_3: contactPsn3.toUpperCase(),
        contact_person_phone_3: contactPsnPhone3,
        contact_person_handphone_3: contactPsnHandPhone3,
        contact_person_email_3: contactPsnEmail3.toUpperCase(),

        contact_person_name_4: contactPsn4.toUpperCase(),
        contact_person_phone_4: contactPsnPhone4,
        contact_person_handphone_4: contactPsnHandPhone4,
        contact_person_email_4: contactPsnEmail4.toUpperCase(),

        contact_person_name_5: contactPsn5.toUpperCase(),
        contact_person_phone_5: contactPsnPhone5,
        contact_person_handphone_5: contactPsnHandPhone5,
        contact_person_email_5: contactPsnEmail5.toUpperCase(),

        bank_account_holder_name_1: bankAcHolderName1.toUpperCase(),
        bank_name_1: bankName1.toUpperCase(),
        bank_address_1: address1.toUpperCase(),
        bank_account_number_1: bankACNo1.toUpperCase(),
        currency_1: Currency1,
        swift_code_1: swiftCode1.toUpperCase(),
        iban_number_1: IBAN_NUM1.toUpperCase(),

        bank_account_holder_name_2: bankAcHolderName2.toUpperCase(),
        bank_name_2: bankName2.toUpperCase(),
        bank_address_2: address2.toUpperCase(),
        bank_account_number_2: bankACNo2.toUpperCase(),
        currency_2: Currency2,
        swift_code_2: swiftCode2.toUpperCase(),
        iban_number_2: IBAN_NUM2.toUpperCase(),

        bank_account_holder_name_3: bankAcHolderName3.toUpperCase(),
        bank_name_3: bankName3.toUpperCase(),
        bank_address_3: address3.toUpperCase(),
        bank_account_number_3: bankACNo3.toUpperCase(),
        currency_3: Currency3,
        swift_code_3: swiftCode3.toUpperCase(),
        iban_number_3: IBAN_NUM3.toUpperCase(),

        bank_account_holder_name_4: bankAcHolderName4.toUpperCase(),
        bank_name_4: bankName4.toUpperCase(),
        bank_address_4: address4.toUpperCase(),
        bank_account_number_4: bankACNo4.toUpperCase(),
        currency_4: Currency4,
        swift_code_4: swiftCode4.toUpperCase(),
        iban_number_4: IBAN_NUM4.toUpperCase(),

        bank_account_holder_name_5: bankAcHolderName5.toUpperCase(),
        bank_name_5: bankName5.toUpperCase(),
        bank_address_5: address5.toUpperCase(),
        bank_account_number_5: bankACNo5.toUpperCase(),
        currency_5: Currency5,
        swift_code_5: swiftCode5.toUpperCase(),
        iban_number_5: IBAN_NUM5.toUpperCase(),
        tax_id: taxId.toUpperCase(),
        share_point_doc_link: shareDocLink,
        status: status.toUpperCase(),
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get total vendors
  getVendors(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_vendors";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //for get vendor (based on perticular vendor)

  getVendor(vendorID, loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_vendor";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        vendor_id: vendorID,
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // for update Vendor
  updateVendor(
    vendorID,
    loginUserID,
    idToken,
    vendorType,
    otherVendor,
    vendorName,
    vendorAddress,
    vendorCountry,
    contactPsn1,
    contactPsnPhone1,
    contactPsnHandPhone1,
    contactPsnEmail1,
    contactPsn2,
    contactPsnPhone2,
    contactPsnHandPhone2,
    contactPsnEmail2,
    contactPsn3,
    contactPsnPhone3,
    contactPsnHandPhone3,
    contactPsnEmail3,
    contactPsn4,
    contactPsnPhone4,
    contactPsnHandPhone4,
    contactPsnEmail4,
    contactPsn5,
    contactPsnPhone5,
    contactPsnHandPhone5,
    contactPsnEmail5,
    bankAcHolderName1,
    bankName1,
    address1,
    bankACNo1,
    Currency1,
    swiftCode1,
    IBAN_NUM1,
    bankAcHolderName2,
    bankName2,
    address2,
    bankACNo2,
    Currency2,
    swiftCode2,
    IBAN_NUM2,
    bankAcHolderName3,
    bankName3,
    address3,
    bankACNo3,
    Currency3,
    swiftCode3,
    IBAN_NUM3,
    bankAcHolderName4,
    bankName4,
    address4,
    bankACNo4,
    Currency4,
    swiftCode4,
    IBAN_NUM4,
    bankAcHolderName5,
    bankName5,
    address5,
    bankACNo5,
    Currency5,
    swiftCode5,
    IBAN_NUM5,
    taxId,
    shareDocLink,
    status,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_vendor";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        vendor_id: vendorID,
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_type: vendorType,
        other_vendor_type: otherVendor.toUpperCase(),
        vendor_name: vendorName.toUpperCase(),
        vendor_address: vendorAddress.toUpperCase(),
        vendor_country: vendorCountry,

        contact_person_name_1: contactPsn1.toUpperCase(),
        contact_person_phone_1: contactPsnPhone1,
        contact_person_handphone_1: contactPsnHandPhone1,
        contact_person_email_1: contactPsnEmail1.toUpperCase(),

        contact_person_name_2: contactPsn2.toUpperCase(),
        contact_person_phone_2: contactPsnPhone2,
        contact_person_handphone_2: contactPsnHandPhone2,
        contact_person_email_2: contactPsnEmail2.toUpperCase(),

        contact_person_name_3: contactPsn3.toUpperCase(),
        contact_person_phone_3: contactPsnPhone3,
        contact_person_handphone_3: contactPsnHandPhone3,
        contact_person_email_3: contactPsnEmail3.toUpperCase(),

        contact_person_name_4: contactPsn4.toUpperCase(),
        contact_person_phone_4: contactPsnPhone4,
        contact_person_handphone_4: contactPsnHandPhone4,
        contact_person_email_4: contactPsnEmail4.toUpperCase(),

        contact_person_name_5: contactPsn5.toUpperCase(),
        contact_person_phone_5: contactPsnPhone5,
        contact_person_handphone_5: contactPsnHandPhone5,
        contact_person_email_5: contactPsnEmail5.toUpperCase(),

        bank_account_holder_name_1: bankAcHolderName1.toUpperCase(),
        bank_name_1: bankName1.toUpperCase(),
        bank_address_1: address1.toUpperCase(),
        bank_account_number_1: bankACNo1.toUpperCase(),
        currency_1: Currency1,
        swift_code_1: swiftCode1.toUpperCase(),
        iban_number_1: IBAN_NUM1.toUpperCase(),

        bank_account_holder_name_2: bankAcHolderName2.toUpperCase(),
        bank_name_2: bankName2.toUpperCase(),
        bank_address_2: address2.toUpperCase(),
        bank_account_number_2: bankACNo2.toUpperCase(),
        currency_2: Currency2,
        swift_code_2: swiftCode2.toUpperCase(),
        iban_number_2: IBAN_NUM2.toUpperCase(),

        bank_account_holder_name_3: bankAcHolderName3.toUpperCase(),
        bank_name_3: bankName3.toUpperCase(),
        bank_address_3: address3.toUpperCase(),
        bank_account_number_3: bankACNo3.toUpperCase(),
        currency_3: Currency3,
        swift_code_3: swiftCode3.toUpperCase(),
        iban_number_3: IBAN_NUM3.toUpperCase(),

        bank_account_holder_name_4: bankAcHolderName4.toUpperCase(),
        bank_name_4: bankName4.toUpperCase(),
        bank_address_4: address4.toUpperCase(),
        bank_account_number_4: bankACNo4.toUpperCase(),
        currency_4: Currency4,
        swift_code_4: swiftCode4.toUpperCase(),
        iban_number_4: IBAN_NUM4.toUpperCase(),

        bank_account_holder_name_5: bankAcHolderName5.toUpperCase(),
        bank_name_5: bankName5.toUpperCase(),
        bank_address_5: address5.toUpperCase(),
        bank_account_number_5: bankACNo5.toUpperCase(),
        currency_5: Currency5,
        swift_code_5: swiftCode5.toUpperCase(),
        iban_number_5: IBAN_NUM5.toUpperCase(),
        tax_id: taxId.toUpperCase(),
        share_point_doc_link: shareDocLink,
        status: status.toUpperCase(),
        existing_file_ids: existing_file_ids,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /* <!-----------   Currency Api's  -----------------> */

  // for add currency
  addCurrency(loginUserID, idToken, newCurrency, status) {
    let url = config.apiDomain + "/add_currency";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        currency: newCurrency.toUpperCase(),
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get currencies
  getCurrencies(loginUserID, idToken) {
    let url = config.apiDomain + "/get_currencies";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        //status : status
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*<!----------- -------------  Purchase Contract Apis  -------------------------------------------> */
  // for add-purchase contract
  addPurchaseContract(
    loginUserID,
    idToken,
    vendor,
    oldContractNo,
    supplierRefNo,
    contractDate,
    purchaseTypeRows,
    stevedorePrice,
    PNBP,
    bargingPrice,
    currency,
    laycan1,
    laycan2,
    laycan3,
    laycan4,
    laycan5,
    laycanQuantity1,
    laycanQuantity2,
    laycanQuantity3,
    laycanQuantity4,
    laycanQuantity5,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    buyerSurveyorName,
    buyerSurveyorAnalysisType,
    pol,
    vesselType,
    payment_adjustment,
    paymentTerm1,
    paymentTerm2,
    remarks,
    shareDocLink,
    contractType,
    baseContractID,
    baseContractNo,
    base_currency,
    addendumContractID,
    addendumContractNo,
    status,
    is_index_linked,
    files
  ) {
    let url = config.apiDomain + "/add_purchase_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor: vendor,
        old_contract_no: oldContractNo?.toUpperCase(),
        supplier_ref_no: supplierRefNo?.toUpperCase(),
        contract_date: dateFormateToDB(contractDate),
        qualities: purchaseTypeRows,
        stevedore_price: stevedorePrice,
        pnbp: PNBP,
        barging_price: bargingPrice,
        currency: currency,
        laycan_1: laycan1?.toUpperCase(),
        laycan_quantity_1: laycanQuantity1,
        laycan_2: laycan2?.toUpperCase(),
        laycan_quantity_2: laycanQuantity2,
        laycan_3: laycan3?.toUpperCase(),
        laycan_quantity_3: laycanQuantity3,
        laycan_4: laycan4?.toUpperCase(),
        laycan_quantity_4: laycanQuantity4,
        laycan_5: laycan5?.toUpperCase(),
        laycan_quantity_5: laycanQuantity5,
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: buyerSurveyorName,
        joint_surveyor_analysis_type: buyerSurveyorAnalysisType,
        pol: pol?.toUpperCase(),
        vessel_type: vesselType,
        payment_adjustment: payment_adjustment
          ? payment_adjustment?.toUpperCase()
          : "",
        payment_terms_1: paymentTerm1 ? paymentTerm1.toUpperCase() : "",
        payment_terms_2: paymentTerm2 ? paymentTerm2.toUpperCase() : "",
        remarks: remarks ? remarks.toUpperCase() : "",
        share_point_doc_link: shareDocLink,
        is_index_linked: is_index_linked,

        // Addenddum fileds.
        contract_type: contractType,
        base_contract_id: baseContractID,
        base_contract_no: baseContractNo,
        base_currency: base_currency,
        addendum_contract_id: addendumContractID,
        addendum_contract_no: addendumContractNo,
        status: status,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get-purchase-contracts (list)
  getPurchaseContracts(loginUserID, status, idToken) {
    let url = config.apiDomain + "/get_purchase_contracts";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        status: status,
        // status: "Active",
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get-purchase-contract
  getPurchaseContract(loginUserID, purchaseContractId, idToken, status) {
    let url = config.apiDomain + "/get_purchase_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        purchase_contract_id: purchaseContractId,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for Update-Purchase-Contract
  updatePurchaseContract(
    loginUserID,
    idToken,
    purchaseContractId,
    vendor,
    oldContractNo,
    supplierRefNo,
    contractDate,
    purchaseTypeRows,
    stevedorePrice,
    PNBP,
    bargingPrice,
    currency,
    laycan1,
    laycan2,
    laycan3,
    laycan4,
    laycan5,
    laycanQuantity1,
    laycanQuantity2,
    laycanQuantity3,
    laycanQuantity4,
    laycanQuantity5,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    buyerSurveyorName,
    buyerSurveyorAnalysisType,
    pol,
    vesselType,
    payment_adjustment,
    paymentTerm1,
    paymentTerm2,
    remarks,
    shareDocLink,
    contractType,
    baseContractID,
    baseContractNo,
    base_currency,
    addendumContractID,
    addendumContractNo,
    is_index_linked,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_purchase_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchaseContractId,
        vendor: vendor,
        old_contract_no: oldContractNo?.toUpperCase(),
        supplier_ref_no: supplierRefNo?.toUpperCase(),
        contract_date: dateFormateToDB(contractDate),
        qualities: purchaseTypeRows,
        stevedore_price: stevedorePrice,
        pnbp: PNBP,
        barging_price: bargingPrice,
        currency: currency,
        laycan_1: laycan1?.toUpperCase(),
        laycan_quantity_1: laycanQuantity1,
        laycan_2: laycan2?.toUpperCase(),
        laycan_quantity_2: laycanQuantity2,
        laycan_3: laycan3?.toUpperCase(),
        laycan_quantity_3: laycanQuantity3,
        laycan_4: laycan4?.toUpperCase(),
        laycan_quantity_4: laycanQuantity4,
        laycan_5: laycan5?.toUpperCase(),
        laycan_quantity_5: laycanQuantity5,
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: buyerSurveyorName,
        joint_surveyor_analysis_type: buyerSurveyorAnalysisType,
        pol: pol?.toUpperCase(),
        vessel_type: vesselType,
        payment_adjustment: payment_adjustment
          ? payment_adjustment.toUpperCase()
          : "",
        payment_terms_1: paymentTerm1 ? paymentTerm1.toUpperCase() : "",
        payment_terms_2: paymentTerm2 ? paymentTerm2.toUpperCase() : "",
        remarks: remarks ? remarks?.toUpperCase() : "",
        share_point_doc_link: shareDocLink,
        is_index_linked: is_index_linked,
        existing_file_ids: existing_file_ids,
        files: files,
        // Addenddum fileds.
        contract_type: contractType,
        base_contract_id: baseContractID,
        base_contract_no: baseContractNo,
        base_currency: base_currency,
        addendum_contract_id: addendumContractID,
        addendum_contract_no: addendumContractNo,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Update purchase Contract Status
  updatePurchaseContractStatus(request) {
    let url = config.apiDomain + "/update_purchase_contract_status";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Get Business Number By Purchase contract
  getBusinessByPC(loginUserID, purchaseContractId, idToken) {
    let url = config.apiDomain + "/get_business_no_by_pcid";
    return fetch(url, {
      method: "post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        purchase_contract_id: purchaseContractId,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_cps_contract_by_pc(request) {
    let url = config.apiDomain + "/get_cps_contract_by_pc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  purchaseQualityToleranceAdjusting(
    loginUserID,
    idToken,
    pcQualityId,
    adjustedTolerance
  ) {
    let url = config.apiDomain + "/update_pc_quality_tolerance_adjusted";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        pc_quality_id: pcQualityId,
        tolerance_adjusted: adjustedTolerance,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Clone Purchase Contract
  clone_purchase_contract(loginUserID, purchaseContractId, idToken) {
    let url = config.apiDomain + "/clone_purchase_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        purchase_contract_id: purchaseContractId,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get_adv_adjustments_by_purchase_contract_id

  get_adv_adjustments_by_purchase_contract_id(
    loginUserID,
    idToken,
    purchaseContractId
  ) {
    let url = config.apiDomain + "/get_adv_adjustments_by_purchase_contract_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchaseContractId,
        //status: status
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
       TODO<!----------------------  Purchase Contract  Advance Payments Api's  -----------------------------!>

    */

  // Add purchase contract Advance Payment
  add_pc_adv_payments(request) {
    let url = config.apiDomain + "/add_pc_adv_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // add_pc_adv_payments(
  //   loginUserID,
  //   idToken,
  //   purchaseContractId,
  //   payment_date,
  //   amount,
  //   payment_currency,
  //   currency_xchg_rate,
  //   amount_in_payment_currency,
  //   invoice,
  //   remarks,
  //   status
  // ) {
  //   let url = config.apiDomain + "/add_pc_adv_payments";
  //   return fetch(url, {
  //     method: "Post",
  //     body: JSON.stringify({
  //       login_user_id: loginUserID,
  //       idtoken: idToken,
  //       purchase_contract_id: purchaseContractId,
  //       payment_date: payment_date ? this.dateFormat(payment_date) : null,
  //       amount: amount,
  //       payment_currency: payment_currency,
  //       currency_xchg_rate: currency_xchg_rate,
  //       amount_in_payment_currency: amount_in_payment_currency,
  //       invoice: invoice.toUpperCase(),
  //       remarks: remarks.toUpperCase(),
  //       status: status,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  // },

  // Update Purchase Contract Advance Payments
  update_pc_adv_payments(request) {
    let url = config.apiDomain + "/update_pc_adv_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // update_pc_adv_payments(
  //   loginUserID,
  //   idToken,
  //   pc_adv_payments_id,
  //   purchaseContractId,
  //   payment_date,
  //   amount,
  //   payment_currency,
  //   currency_xchg_rate,
  //   amount_in_payment_currency,
  //   invoice,
  //   remarks,
  //   status
  // ) {
  //   let url = config.apiDomain + "/update_pc_adv_payments";
  //   return fetch(url, {
  //     method: "Post",
  //     body: JSON.stringify({
  //       login_user_id: loginUserID,
  //       idtoken: idToken,
  //       pc_adv_payments_id: pc_adv_payments_id,
  //       purchase_contract_id: purchaseContractId,
  //       payment_date: dateFormateToDB(payment_date),
  //       amount: amount,
  //       payment_currency: payment_currency,
  //       currency_xchg_rate: currency_xchg_rate,
  //       amount_in_payment_currency: amount_in_payment_currency,
  //       invoice: invoice ? invoice.toUpperCase() : "",
  //       remarks: remarks ? remarks.toUpperCase() : "",
  //       status: status,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  // },

  // Get Purchase Contract Advance Payment
  get_pc_adv_payment(loginUserID, idToken, pc_adv_payments_id, status) {
    let url = config.apiDomain + "/get_pc_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        pc_adv_payments_id: pc_adv_payments_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Total purchase Contract Advance Payments
  get_pc_adv_payments(loginUserID, purchaseContractId, idToken, status) {
    let url = config.apiDomain + "/get_pc_adv_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchaseContractId,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_pc_adv_invoices_by_pc(request) {
    let url = config.apiDomain + "/get_pc_adv_invoices_by_pc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Delete Purchase contract Advance Payment
  delete_pc_adv_payment(
    loginUserID,
    idToken,
    purchaseContractId,
    pc_adv_payments_id,
    status
  ) {
    let url = config.apiDomain + "/update_pc_adv_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchaseContractId,
        pc_adv_payments_id: pc_adv_payments_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_pc_adv_invoice(request) {
    let url = config.apiDomain + "/add_pc_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_pc_adv_invoice(request) {
    let url = config.apiDomain + "/update_pc_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
     TODO<!----------------------  Sales Contract Api's  -----------------------------!>
  */

  // for add sales Contract
  addSalesContract(
    loginUserID,
    idToken,
    customer,
    oldContractNo,
    buyerRefNo,
    contractDate,
    salesTypeRows,
    stevedorePrice,
    PNBP,
    currency,
    laycan1,
    laycan2,
    laycan3,
    laycan4,
    laycan5,
    laycanQuantity1,
    laycanQuantity2,
    laycanQuantity3,
    laycanQuantity4,
    laycanQuantity5,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    buyerSurveyorName,
    buyerSurveyorAnalysisType,
    portOfLoading,
    dischargeRate1,
    portOfDischarge1,
    dischargeRate2,
    portOfDischarge2,
    dischargeRate3,
    portOfDischarge3,
    loadingRateforGeared,
    loadingRateforGearless,
    payment_adjustment,
    paymentTerm1,
    paymentTerm2,
    remarks,
    vesselType,
    shareDocLink,
    contractType,
    baseContractID,
    baseContractNo,
    base_currency,
    addendumContractID,
    addendumContractNo,
    status,
    is_index_linked,
    files
  ) {
    let url = config.apiDomain + "/add_sale_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        customer: customer,
        old_contract_no: oldContractNo ? oldContractNo.toUpperCase() : null,
        buyer_ref_no: buyerRefNo ? buyerRefNo.toUpperCase() : null,
        contract_date: dateFormateToDB(contractDate),
        qualities: salesTypeRows,
        stevedore_price: stevedorePrice,
        pnbp: PNBP,
        currency: currency,
        laycan_1: laycan1 ? laycan1.toUpperCase() : null,
        laycan_quantity_1: laycanQuantity1,
        laycan_2: laycan2 ? laycan2.toUpperCase() : null,
        laycan_quantity_2: laycanQuantity2,
        laycan_3: laycan3 ? laycan3.toUpperCase() : null,
        laycan_quantity_3: laycanQuantity3,
        laycan_4: laycan4 ? laycan4.toUpperCase() : null,
        laycan_quantity_4: laycanQuantity4,
        laycan_5: laycan5 ? laycan5.toUpperCase() : null,
        laycan_quantity_5: laycanQuantity5,
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: buyerSurveyorName,
        joint_surveyor_analysis_type: buyerSurveyorAnalysisType,
        port_of_loading: portOfLoading ? portOfLoading.toUpperCase() : null,
        discharge_rate_1: dischargeRate1 ? dischargeRate1.toUpperCase() : null,
        discharge_rate_2: dischargeRate2 ? dischargeRate2.toUpperCase() : null,
        discharge_rate_3: dischargeRate3 ? dischargeRate3.toUpperCase() : null,
        port_of_discharge_1: portOfDischarge1
          ? portOfDischarge1.toUpperCase()
          : null,
        port_of_discharge_2: portOfDischarge2
          ? portOfDischarge2.toUpperCase()
          : null,
        port_of_discharge_3: portOfDischarge3
          ? portOfDischarge3.toUpperCase()
          : null,
        vessel_type: vesselType,
        loading_rate_for_geared: loadingRateforGeared
          ? loadingRateforGeared.toUpperCase()
          : null,
        loading_rate_for_gearless: loadingRateforGearless
          ? loadingRateforGearless.toUpperCase()
          : null,
        payment_adjustment: payment_adjustment
          ? payment_adjustment.toUpperCase()
          : "",
        payment_terms_1: paymentTerm1 ? paymentTerm1.toUpperCase() : "",
        payment_terms_2: paymentTerm2 ? paymentTerm2.toUpperCase() : "",
        remarks: remarks ? remarks.toUpperCase() : "",
        share_point_doc_link: shareDocLink,
        is_index_linked: is_index_linked,
        // Addenddum fileds.
        contract_type: contractType,
        base_contract_id: baseContractID,
        base_contract_no: baseContractNo,
        base_currency: base_currency ? base_currency : "",
        addendum_contract_id: addendumContractID,
        addendum_contract_no: addendumContractNo,
        status: status,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_adv_adjustments_by_sales_contract_id(
    loginUserID,
    idToken,
    sales_contract_id
  ) {
    let url = config.apiDomain + "/get_adv_adjustments_by_sales_contract_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_contract_id: sales_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // for get total Sales Contract List
  getSalesContractList(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_sale_contracts";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  //get sale_contract
  getSalesContract(loginUserID, salesContractId, idToken, status) {
    let url = config.apiDomain + "/get_sale_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        sale_contract_id: salesContractId,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for update Sales Contract
  updateSalesContract(
    salesContractId,
    loginUserID,
    idToken,
    customer,
    oldContractNo,
    buyerRefNo,
    contractDate,
    salesTypeRows,
    stevedorePrice,
    PNBP,
    currency,
    laycan1,
    laycan2,
    laycan3,
    laycan4,
    laycan5,
    laycanQuantity1,
    laycanQuantity2,
    laycanQuantity3,
    laycanQuantity4,
    laycanQuantity5,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    buyerSurveyorName,
    buyerSurveyorAnalysisType,
    portOfLoading,
    dischargeRate1,
    portOfDischarge1,
    dischargeRate2,
    portOfDischarge2,
    dischargeRate3,
    portOfDischarge3,
    loadingRateforGeared,
    loadingRateforGearless,
    payment_adjustment,
    paymentTerm1,
    paymentTerm2,
    remarks,
    vesselType,
    shareDocLink,
    contractType,
    baseContractID,
    baseContractNo,
    addendumContractID,
    addendumContractNo,
    status,
    is_index_linked,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_sale_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        sale_contract_id: salesContractId,
        login_user_id: loginUserID,
        idtoken: idToken,
        customer: customer,
        old_contract_no: oldContractNo ? oldContractNo.toUpperCase() : null,
        buyer_ref_no: buyerRefNo ? buyerRefNo.toUpperCase() : null,
        contract_date: dateFormateToDB(contractDate),
        qualities: salesTypeRows,
        stevedore_price: stevedorePrice,
        pnbp: PNBP,
        currency: currency,
        laycan_1: laycan1 ? laycan1.toUpperCase() : null,
        laycan_quantity_1: laycanQuantity1,
        laycan_2: laycan2 ? laycan2.toUpperCase() : null,
        laycan_quantity_2: laycanQuantity2,
        laycan_3: laycan3 ? laycan3.toUpperCase() : null,
        laycan_quantity_3: laycanQuantity3,
        laycan_4: laycan4 ? laycan4.toUpperCase() : null,
        laycan_quantity_4: laycanQuantity4,
        laycan_5: laycan5 ? laycan5.toUpperCase() : null,
        laycan_quantity_5: laycanQuantity5,
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: buyerSurveyorName,
        joint_surveyor_analysis_type: buyerSurveyorAnalysisType,
        port_of_loading: portOfLoading ? portOfLoading.toUpperCase() : null,
        discharge_rate_1: dischargeRate1 ? dischargeRate1.toUpperCase() : null,
        discharge_rate_2: dischargeRate2 ? dischargeRate2.toUpperCase() : null,
        discharge_rate_3: dischargeRate3 ? dischargeRate3.toUpperCase() : null,
        port_of_discharge_1: portOfDischarge1
          ? portOfDischarge1.toUpperCase()
          : null,
        port_of_discharge_2: portOfDischarge2
          ? portOfDischarge2.toUpperCase()
          : null,
        port_of_discharge_3: portOfDischarge3
          ? portOfDischarge3.toUpperCase()
          : null,
        vessel_type: vesselType,
        loading_rate_for_geared: loadingRateforGeared
          ? loadingRateforGeared.toUpperCase()
          : null,
        loading_rate_for_gearless: loadingRateforGeared
          ? loadingRateforGearless.toUpperCase()
          : null,
        payment_adjustment: payment_adjustment
          ? payment_adjustment.toUpperCase()
          : "",
        payment_terms_1: paymentTerm1 ? paymentTerm1.toUpperCase() : "",
        payment_terms_2: paymentTerm2 ? paymentTerm2.toUpperCase() : "",
        remarks: remarks ? remarks.toUpperCase() : "",
        share_point_doc_link: shareDocLink,
        is_index_linked: is_index_linked,
        existing_file_ids: existing_file_ids,
        files: files,
        // Addenddum fileds.
        contract_type: contractType,
        base_contract_id: baseContractID,
        base_contract_no: baseContractNo,
        addendum_contract_id: addendumContractID,
        addendum_contract_no: addendumContractNo,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Clone Sales Contract
  clone_sale_contract(loginUserID, idToken, salesContractId) {
    let url = config.apiDomain + "/clone_sale_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sale_contract_id: salesContractId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update sales Contract Status
  updateSaleContractStatus(request) {
    let url = config.apiDomain + "/update_sale_contract_status";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Business Number By Sales contract
  getBusinessBySC(loginUserID, salesContractId, idToken) {
    let url = config.apiDomain + "/get_business_no_by_scid";
    return fetch(url, {
      method: "post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        sale_contract_id: salesContractId,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  salesQualityToleranceAdjusting(
    loginUserID,
    idToken,
    scQualityId,
    adjustedTolerance
  ) {
    let url = config.apiDomain + "/update_sc_quality_tolerance_adjusted";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sc_quality_id: scQualityId,
        tolerance_adjusted: adjustedTolerance,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_sc_adv_invoice(request) {
    let url = config.apiDomain + "/add_sc_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_sc_adv_invoice(request) {
    let url = config.apiDomain + "/update_sc_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_sc_adv_invoices_by_sc(request) {
    let url = config.apiDomain + "/get_sc_adv_invoices_by_sc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /* TODO:<!--------------------------------   Mines Apis  ----------------------------------------> */

  // for add_mines
  addMines(
    loginUserID,
    idToken,
    mineName,
    mineArea,
    ETavailability,
    nofPits,
    ExploredArea,
    TotalAreaofPit,
    productionPresent,
    productionPrevMonth,
    productionTargetCurrMonth,
    productionTargetUpcomingMonth,
    NofBargesLoadedPrevMonth,
    NofBargesLoadedCurrentMonth,
    NofBargesLoadedUpcomingMonth,
    NofSeams,
    AvgThicknessSeams,
    gradeGAR,
    ASH,
    TS,
    HGI,
    ReservesCurrentPit,
    SR,
    totalReservesMine,
    contractorMining,
    HaulingToCrusher,
    crushingRate,
    NofCrusher,
    HaulingDistance,
    DTforHaulingCoal,
    DTforOverburden,
    loadingPort,
    loadingRate,
    distanceAnchorage,
    anchorageName
  ) {
    let url = config.apiDomain + "/add_mines";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mine_name: mineName.toUpperCase(),
        mine_area: mineArea.toUpperCase(),
        et_availability: ETavailability.toUpperCase(),
        no_of_pits: nofPits.toUpperCase(),
        explored_area: ExploredArea.toUpperCase(),
        total_area_of_pit: TotalAreaofPit.toUpperCase(),
        production_at_present_n_planned: productionPresent.toUpperCase(),
        production_previous_month: productionPrevMonth.toUpperCase(),
        production_target_current_month:
          productionTargetCurrMonth.toUpperCase(),
        production_target_upcoming_month:
          productionTargetUpcomingMonth.toUpperCase(),
        no_of_barges_loaded_previous_month:
          NofBargesLoadedPrevMonth.toUpperCase(),
        no_of_barges_loaded_current_month:
          NofBargesLoadedCurrentMonth.toUpperCase(),
        no_of_barges_to_be_loaded_upcoming_month:
          NofBargesLoadedUpcomingMonth.toUpperCase(),
        no_of_seams: NofSeams.toUpperCase(),
        avg_thickness_of_seams: AvgThicknessSeams.toUpperCase(),
        grade_in_gar: gradeGAR.toUpperCase(),
        ash: ASH.toUpperCase(),
        ts: TS.toUpperCase(),
        hgi: HGI.toUpperCase(),
        reserves_in_current_pit: ReservesCurrentPit.toUpperCase(),
        sr: SR.toUpperCase(),
        total_reserves_in_the_mine: totalReservesMine.toUpperCase(),
        contractor_mining: contractorMining.toUpperCase(),
        hauling_to_crusher_stockpile: HaulingToCrusher.toUpperCase(),
        crushing_rate: crushingRate.toUpperCase(),
        no_of_crusher: NofCrusher.toUpperCase(),
        hauling_distance: HaulingDistance.toUpperCase(),
        dt_for_hauling_coal: DTforHaulingCoal.toUpperCase(),
        dt_for_overburden_removal: DTforOverburden.toUpperCase(),
        loading_port: loadingPort,
        loading_rate_by__conveyor: loadingRate.toUpperCase(),
        distance_to_anchorage: distanceAnchorage.toUpperCase(),
        anchorage_name: anchorageName.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // for get_mine
  getMine(loginUserID, idToken, mineID) {
    let url = config.apiDomain + "/get_mine";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mine_id: mineID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // for get_mines
  getMines(loginUserID, idToken) {
    let url = config.apiDomain + "/get_mines";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  //for update_mine
  updateMine(
    loginUserID,
    idToken,
    mineID,
    mineName,
    mineArea,
    ETavailability,
    nofPits,
    ExploredArea,
    TotalAreaofPit,
    productionPresent,
    productionPrevMonth,
    productionTargetCurrMonth,
    productionTargetUpcomingMonth,
    NofBargesLoadedPrevMonth,
    NofBargesLoadedCurrentMonth,
    NofBargesLoadedUpcomingMonth,
    NofSeams,
    AvgThicknessSeams,
    gradeGAR,
    ASH,
    TS,
    HGI,
    ReservesCurrentPit,
    SR,
    totalReservesMine,
    contractorMining,
    HaulingToCrusher,
    crushingRate,
    NofCrusher,
    HaulingDistance,
    DTforHaulingCoal,
    DTforOverburden,
    loadingPort,
    loadingRate,
    distanceAnchorage,
    anchorageName
  ) {
    let url = config.apiDomain + "/update_mines";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mine_id: mineID,
        mine_name: mineName.toUpperCase(),
        mine_area: mineArea.toUpperCase(),
        et_availability: ETavailability.toUpperCase(),
        no_of_pits: nofPits.toUpperCase(),
        explored_area: ExploredArea.toUpperCase(),
        total_area_of_pit: TotalAreaofPit.toUpperCase(),
        production_at_present_n_planned: productionPresent.toUpperCase(),
        production_previous_month: productionPrevMonth.toUpperCase(),
        production_target_current_month:
          productionTargetCurrMonth.toUpperCase(),
        production_target_upcoming_month:
          productionTargetUpcomingMonth.toUpperCase(),
        no_of_barges_loaded_previous_month:
          NofBargesLoadedPrevMonth.toUpperCase(),
        no_of_barges_loaded_current_month:
          NofBargesLoadedCurrentMonth.toUpperCase(),
        no_of_barges_to_be_loaded_upcoming_month:
          NofBargesLoadedUpcomingMonth.toUpperCase(),
        no_of_seams: NofSeams.toUpperCase(),
        avg_thickness_of_seams: AvgThicknessSeams.toUpperCase(),
        grade_in_gar: gradeGAR.toUpperCase(),
        ash: ASH.toUpperCase(),
        ts: TS.toUpperCase(),
        hgi: HGI.toUpperCase(),
        reserves_in_current_pit: ReservesCurrentPit.toUpperCase(),
        sr: SR.toUpperCase(),
        total_reserves_in_the_mine: totalReservesMine.toUpperCase(),
        contractor_mining: contractorMining.toUpperCase(),
        hauling_to_crusher_stockpile: HaulingToCrusher.toUpperCase(),
        crushing_rate: crushingRate.toUpperCase(),
        no_of_crusher: NofCrusher.toUpperCase(),
        hauling_distance: HaulingDistance.toUpperCase(),
        dt_for_hauling_coal: DTforHaulingCoal.toUpperCase(),
        dt_for_overburden_removal: DTforOverburden.toUpperCase(),
        loading_port: loadingPort,
        loading_rate_by__conveyor: loadingRate.toUpperCase(),
        distance_to_anchorage: distanceAnchorage.toUpperCase(),
        anchorage_name: anchorageName.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //TODO:<!--------------------------------- Business Number Creation API's----------------!>

  // Create Business Number
  newBusinessNumber(
    loginUserID,
    idToken,
    business_head,
    oldBusinessNo,
    businessType,
    consignmentType,
    motherVesselName,
    bargeName,
    shipmentType,
    vessel_Type,
    stevedore_vendor,
    floatingCrane_vendor,
    floatingCrane_name,
    loading_Anchorage,
    Documents_boardDate,
    shipmentLaycan,
    loadingPort_agent,
    loading_rate,
    vessel_freight,
    vessel_owner,
    vessel_freight_price,
    vessel_arrived_on,
    ETA,
    nor_acceptance,
    vessel_sailingDate,
    stowagePlan,
    supplyTonnage,
    business_no_allocation,
    demurrage_rate_from_buyer,
    demurrage_rate_to_supplier,
    remarks,
    businessFlow,
    supplierReferenceNumber,
    buy_back_involved
  ) {
    let url = config.apiDomain + "/add_business_number";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_head: business_head,
        old_business_no: oldBusinessNo.toUpperCase(),
        business_type: businessType,
        consignment_type: consignmentType,
        mother_vessel_name: motherVesselName.toUpperCase(),
        barge_name: bargeName.toUpperCase(),
        shipment_type: shipmentType,
        vessel_type: vessel_Type,
        stevedore_vendor: stevedore_vendor,
        floating_crane_vendor: floatingCrane_vendor,
        floating_crane_name: floatingCrane_name.toUpperCase(),
        loading_anchorage: loading_Anchorage.toUpperCase(),
        shipment_laycan: shipmentLaycan.toUpperCase(),
        load_port_agent: loadingPort_agent.toUpperCase(),
        load_rate_per_day: loading_rate.toUpperCase(),
        vessel_freight: vessel_freight.toUpperCase(),
        vessel_owner: vessel_owner,
        vessel_freight_price: vessel_freight_price,
        vessel_arrived_on: dateFormateToDB(vessel_arrived_on),
        eta_nor: dateFormateToDB(ETA),
        nor_acceptance: dateFormateToDB(nor_acceptance),
        documents_on_board: dateFormateToDB(Documents_boardDate),
        vessel_sailing: dateFormateToDB(vessel_sailingDate),
        stowage_plan_in_mt: stowagePlan,
        supply_tonnage_in_mt: supplyTonnage,
        business_no_allocation: business_no_allocation,
        demurrage_rate_from_buyer: demurrage_rate_from_buyer.toUpperCase(),
        demurrage_rate_to_supplier: demurrage_rate_to_supplier.toUpperCase(),
        remarks: remarks.toUpperCase(),
        business_flow: businessFlow.toUpperCase(),
        supplier_reference_number: supplierReferenceNumber.toUpperCase(),
        buy_back_involved: buy_back_involved,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Business Numbers List
  getBusinessNumbers(loginUserID, idToken) {
    let url = config.apiDomain + "/get_business_numbers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Business Number Details Based on Business ID
  getBusinessNumber(businessNo, loginUserID, idToken) {
    let url = config.apiDomain + "/get_business_number";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        business_no_id: businessNo,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update Business Number details
  updateNewBusinessNumber(
    businessNo,
    loginUserID,
    idToken,
    business_head,
    oldBusinessNo,
    businessType,
    consignmentType,
    motherVesselName,
    bargeName,
    shipmentType,
    vessel_Type,
    stevedore_vendor,
    floating_crane_vendor,
    floatingCrane_name,
    loading_Anchorage,
    Documents_boardDate,
    shipmentLaycan,
    loadingPort_agent,
    loading_rate,
    vessel_freight,
    vessel_owner,
    vessel_freight_price,
    vessel_arrived_on,
    ETA,
    nor_acceptance,
    vessel_sailingDate,
    stowagePlan,
    supplyTonnage,
    demurrage_rate_from_buyer,
    demurrage_rate_to_supplier,
    remarks,
    businessFlow,
    supplierReferenceNumber,
    buy_back_involved
  ) {
    let url = config.apiDomain + "/update_business_number";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        business_no_id: businessNo,
        login_user_id: loginUserID,
        idtoken: idToken,
        business_head: business_head,
        old_business_no: oldBusinessNo.toUpperCase(),
        business_type: businessType,
        consignment_type: consignmentType,
        mother_vessel_name: motherVesselName.toUpperCase(),
        barge_name: bargeName.toUpperCase(),
        shipment_type: shipmentType,
        vessel_type: vessel_Type,
        stevedore_vendor: stevedore_vendor,
        floating_crane_vendor: floating_crane_vendor,
        floating_crane_name: floatingCrane_name.toUpperCase(),
        loading_anchorage: loading_Anchorage.toUpperCase(),
        shipment_laycan: shipmentLaycan.toUpperCase(),
        load_port_agent: loadingPort_agent.toUpperCase(),
        load_rate_per_day: loading_rate.toUpperCase(),
        vessel_freight: vessel_freight.toUpperCase(),
        vessel_owner: vessel_owner,
        vessel_freight_price: vessel_freight_price,
        vessel_arrived_on: dateFormateToDB(vessel_arrived_on),
        eta_nor: dateFormateToDB(ETA),
        nor_acceptance: dateFormateToDB(nor_acceptance),
        documents_on_board: dateFormateToDB(Documents_boardDate),
        vessel_sailing: dateFormateToDB(vessel_sailingDate),
        stowage_plan_in_mt: stowagePlan,
        supply_tonnage_in_mt: supplyTonnage,
        demurrage_rate_from_buyer: demurrage_rate_from_buyer.toUpperCase(),
        demurrage_rate_to_supplier: demurrage_rate_to_supplier.toUpperCase(),
        remarks: remarks.toUpperCase(),
        business_flow: businessFlow,
        supplier_reference_number: supplierReferenceNumber.toUpperCase(),
        buy_back_involved: buy_back_involved,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Change Business Number Status
  update_business_number_status(loginUserID, idToken, business_no_id, status) {
    let url = config.apiDomain + "/update_business_number_status";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Get Business Number Mother vessel data
  getBusinessNumberMotherVessel(businessNo, loginUserID, idToken) {
    let url = config.apiDomain + "/get_business_no_mvs";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        business_no_id: businessNo,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Get Business Number Barge Data
  getBusinessNumberBargeDetails() {
    let url = config.apiDomain + "/";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  addBusinessNumberAllocation(
    loginUserID,
    idToken,
    business_no,
    businessNo,
    businessType,
    consignmentType,
    shipmentType,
    pc_purchase_type_id,
    purchase_contract_id,
    pc_qualities_id,
    purchase_contract_quality,
    procurement_quality_standard,
    quantity_allocated,
    sale_contract_id,
    sc_qualities_id,
    sc_sales_type_id,
    selling_quality_standard,
    buy_back_alloc
  ) {
    let url = config.apiDomain + "/add_business_number_allocation";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no: business_no,
        business_no_id: businessNo,
        business_type: businessType,
        consignment_type: consignmentType,
        shipment_type: shipmentType,
        pc_purchase_type_id: pc_purchase_type_id,
        pc_qualities_id: pc_qualities_id,
        purchase_contract_id: purchase_contract_id,
        purchase_contract_quality: purchase_contract_quality,
        procurement_quality_standard: procurement_quality_standard,
        quantity_allocated: quantity_allocated,
        sale_contract_id: sale_contract_id,
        sc_qualities_id: sc_qualities_id,
        sc_sales_type_id: sc_sales_type_id,
        selling_quality_standard: selling_quality_standard,
        buy_back_alloc: buy_back_alloc,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateBusinessNumberAllocation(
    loginUserID,
    idToken,
    businessNo,
    businessAllocationID,
    updateType,
    quantityAllocating,
    purchaseTypeID,
    pc_qualities_id,
    salesTypeID,
    sc_qualities_id
  ) {
    let url = config.apiDomain + "/update_business_number_allocation";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNo,
        business_allocation_id: businessAllocationID,
        update_type: updateType,
        quantity_allocated: quantityAllocating,
        pc_qualities_id: pc_qualities_id,
        pc_purchase_type_id: purchaseTypeID,
        sc_qualities_id: sc_qualities_id,
        sc_sales_type_id: salesTypeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  deleteBusinessNumberAllocation(
    loginUserID,
    idToken,
    businessNo,
    businessAllocationID
  ) {
    let url = config.apiDomain + "/delete_business_number_allocation";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNo,
        business_allocation_id: businessAllocationID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Refresh Token
  refreshToken(loginUserID, idToken) {
    let url = config.apiDomain + "/new_token";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //New Business Number Operational MV
  addBusinessNoOpMV(
    loginUserID,
    idToken,
    businessNumberID,
    loadingCommencement,
    loadingCompletion,
    documentsOnBoard,
    vesselSailing,
    vessel_final_quantity_in_mt,
    cargo_gain_loss_in_mt,
    dischargePort,
    loadingType,
    loadingRatePerDay,
    deadFreightAccount,
    deadFreightIncurred,
    supplier_surveyor,
    supplierSurveyorAnalysisType,
    internal_surveyor,
    SDAMSAIISurveyorAnalysisType,
    joint_surveyor,
    surveyorBasePrice,
    surveyorVAT,
    surveyorTotalPrice,
    surveyorCurrencyType,
    surveyorAdditionalDays,
    stevedore_vendor,
    stevedoreAdditionalDays,
    floating_crane_vendor,
    floatingCraneName,
    floating_crane_additional_days,
    floatingCraneDemurrageDespatch,
    floatingCraneDemurrageDespatchCurrencyType,
    documentationAgent,
    supplierDocumentationAgent,
    SDAM_DocumentationAgent,
    buyerDocumentationAgent,
    vessel_arrived_on,
    nor_acceptance,
    vessel_type,
    load_port_agent,
    eta_nor,
    remarks
  ) {
    let url = config.apiDomain + "/add_business_no_mv";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNumberID,
        loading_commencement: dateFormateToDB(loadingCommencement),
        loading_completion: dateFormateToDB(loadingCompletion),
        documents_on_board: dateFormateToDB(documentsOnBoard),
        vessel_sailing: dateFormateToDB(vesselSailing),
        vessel_final_quantity_in_mt: vessel_final_quantity_in_mt,
        cargo_gain_loss_in_mt: cargo_gain_loss_in_mt,
        discharge_port: dischargePort.toUpperCase(),
        loading_type: loadingType,
        load_rate_per_day: loadingRatePerDay,
        dead_freight_incurred: deadFreightIncurred,
        dead_freight_account: deadFreightAccount,
        supplier_surveyor: supplier_surveyor,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: internal_surveyor,
        internal_surveyor_analysis_type: SDAMSAIISurveyorAnalysisType,
        joint_surveyor: joint_surveyor,
        surveyor_base_price: surveyorBasePrice,
        surveyor_vat_price: surveyorVAT,
        surveyor_total_price: surveyorTotalPrice,
        surveyor_currency: surveyorCurrencyType,
        surveyor_additional_days: surveyorAdditionalDays,
        stevedore_vendor: stevedore_vendor,
        floating_crane_vendor: floating_crane_vendor,
        floating_crane_name: floatingCraneName
          ? floatingCraneName.toUpperCase()
          : "",
        floating_crane_additional_days: floating_crane_additional_days,
        floating_crane_despatch: floatingCraneDemurrageDespatch,
        floating_crane_despatch_currency:
          floatingCraneDemurrageDespatchCurrencyType,
        //documentation_agent : documentationAgent,
        supplier_documentation_agent: supplierDocumentationAgent.toUpperCase(),
        internal_documentation_agent: SDAM_DocumentationAgent.toUpperCase(),
        joint_documentation_agent: buyerDocumentationAgent.toUpperCase(),
        vessel_arrived_on: dateFormateToDB(vessel_arrived_on),
        nor_acceptance: dateFormateToDB(nor_acceptance),
        vessel_type: vessel_type,
        load_port_agent: load_port_agent ? load_port_agent.toUpperCase() : "",
        eta_nor: dateFormateToDB(eta_nor),
        remarks: remarks.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getBusinessNoOpMVDetails(motherVesselID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_business_no_mv";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        business_no_mv_id: motherVesselID,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //New Business Number Operational MV
  updateBusinessNoOpMV(
    loginUserID,
    idToken,
    motherVesselID,
    businessNumberID,
    loadingCommencement,
    loadingCompletion,
    documentsOnBoard,
    vesselSailing,
    vessel_final_quantity_in_mt,
    cargo_gain_loss_in_mt,
    dischargePort,
    loadingType,
    loadingRatePerDay,
    deadFreightAccount,
    deadFreightIncurred,
    supplier_surveyor,
    supplierSurveyorAnalysisType,
    internal_surveyor,
    SDAMSAIISurveyorAnalysisType,
    joint_surveyor,
    surveyorBasePrice,
    surveyorVAT,
    surveyorTotalPrice,
    surveyorCurrencyType,
    surveyorAdditionalDays,
    stevedore_vendor,
    stevedoreAdditionalDays,
    floating_crane_vendor,
    floatingCraneName,
    floating_crane_additional_days,
    floatingCraneDemurrageDespatch,
    floatingCraneDemurrageDespatchCurrencyType,
    documentationAgent,
    supplierDocumentationAgent,
    SDAM_DocumentationAgent,
    buyerDocumentationAgent,
    vessel_arrived_on,
    nor_acceptance,
    vessel_type,
    load_port_agent,
    eta_nor,
    remarks
  ) {
    let url = config.apiDomain + "/update_business_no_mv";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_mv_id: motherVesselID,
        business_no_id: businessNumberID,
        loading_commencement: dateFormateToDB(loadingCommencement),
        loading_completion: dateFormateToDB(loadingCompletion),
        documents_on_board: dateFormateToDB(documentsOnBoard),
        vessel_sailing: dateFormateToDB(vesselSailing),
        vessel_final_quantity_in_mt: vessel_final_quantity_in_mt,
        cargo_gain_loss_in_mt: cargo_gain_loss_in_mt,
        discharge_port: dischargePort.toUpperCase(),
        loading_type: loadingType,
        load_rate_per_day: loadingRatePerDay,
        dead_freight_incurred: deadFreightIncurred,
        dead_freight_account: deadFreightAccount,
        supplier_surveyor: supplier_surveyor,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: internal_surveyor,
        internal_surveyor_analysis_type: SDAMSAIISurveyorAnalysisType,
        joint_surveyor: joint_surveyor,
        surveyor_base_price: surveyorBasePrice,
        surveyor_vat_price: surveyorVAT,
        surveyor_total_price: surveyorTotalPrice,
        surveyor_currency: surveyorCurrencyType,
        surveyor_additional_days: surveyorAdditionalDays,
        stevedore_vendor: stevedore_vendor,
        stevedore_additional_days: stevedoreAdditionalDays,
        floating_crane_vendor: floating_crane_vendor,
        floating_crane_name: floatingCraneName
          ? floatingCraneName.toUpperCase()
          : "",
        floating_crane_additional_days: floating_crane_additional_days,
        floating_crane_despatch: floatingCraneDemurrageDespatch,
        floating_crane_despatch_currency:
          floatingCraneDemurrageDespatchCurrencyType,
        //documentation_agent : documentationAgent,
        supplier_documentation_agent: supplierDocumentationAgent.toUpperCase(),
        internal_documentation_agent: SDAM_DocumentationAgent.toUpperCase(),
        joint_documentation_agent: buyerDocumentationAgent.toUpperCase(),

        vessel_arrived_on: dateFormateToDB(vessel_arrived_on),
        nor_acceptance: dateFormateToDB(nor_acceptance),
        vessel_type: vessel_type,
        load_port_agent: load_port_agent ? load_port_agent.toUpperCase() : "",
        eta_nor: dateFormateToDB(eta_nor),
        remarks: remarks.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getTotalBargeQuantities(loginUserID, idToken, businessNumberID) {
    let url = config.apiDomain + "/get_total_barges_quantity_mt";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNumberID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Get Business No Barge Details
  get_business_no_barge(
    bargeID,
    business_no_allocation_id,
    business_no_id,
    loginUserID,
    idToken
  ) {
    let url = config.apiDomain + "/get_business_no_barge";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        business_no_barge_id: bargeID,
        business_no_allocation_id: business_no_allocation_id,
        business_no_id: business_no_id,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //update_barges_status_and_dates
  update_barges_status_and_dates(
    loginUserID,
    idToken,
    businessNoID,
    updatedBarges
  ) {
    let url = config.apiDomain + "/update_barges_status_and_dates";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        barges: updatedBarges,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO :<!---------------------- BARGE PURCHASE FINANCIALS API'S ---------------------------!>
   *
   */

  getCoalPriceByBargeID(
    loginUserID,
    idToken,
    businessNoBargeID,
    business_no_allocation_id
  ) {
    let url = config.apiDomain + "/get_coal_prices_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        business_no_allocation_id: business_no_allocation_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get Vendor Advance Contract data based on Barge ID

  getVendorAdvanceContractsByBargeID(loginUserID, idToken, bargeID, status) {
    let url = config.apiDomain + "/get_vendor_adv_contracts_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: bargeID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  adv_contracts_by_barge_id(request) {
    let url = config.apiDomain + "/get_vendor_adv_contracts_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get purchase contract advance remaining amount based on Barge ID
  get_pc_adv_remaining_amount(request) {
    let url = config.apiDomain + "/get_pc_adv_remaining_amount";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Costings by Business number ID
  addBargePurchaseCoalCostings(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    coal_cost_coal_quantity,
    coal_cost_coal_price_pmt,
    coal_cost_billing_currency,
    coal_cost_penality_currency,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    paid_to_supplier,
    costing_type
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        business_no_id: businessNoID,
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        coal_price_pmt: coal_cost_coal_price_pmt,
        billing_currency: coal_cost_billing_currency,
        penality_currency: coal_cost_penality_currency,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        payable_to_supplier: paid_to_supplier,
        costing_type: costing_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Costings by Business number ID
  addBargeCoalCostings(
    loginUserID,
    idToken,
    bargeID,
    purchaseContractId,
    invoice_no,
    invoice_date,
    ppn,
    coal_index_type,
    index_ids,
    average_coal_index_price,
    prorata_gar,
    prorata_coal_index_price,
    bonus_coal_index_pmt,
    penality_coal_index_pmt,
    derivered_coal_index_pmt,
    final_coal_index_pmt,
    coal_cost_coal_quantity,
    coal_cost_coal_price_pmt,
    coal_cost_billing_currency,
    coal_cost_penality_currency,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    paid_to_supplier,
    costing_type,
    vat_applicable,
    invoice_percentage,
    vat_amount,
    vat_percent,
    vat_value,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: bargeID,
        purchase_contract_id: purchaseContractId,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        coal_index_type: coal_index_type,
        index_ids: index_ids,
        average_coal_index_price: average_coal_index_price,
        prorata_gar: prorata_gar,
        prorata_coal_index_price: prorata_coal_index_price,
        bonus_coal_index_pmt: bonus_coal_index_pmt,
        penality_coal_index_pmt: penality_coal_index_pmt,
        derivered_coal_index_pmt: derivered_coal_index_pmt,
        final_coal_index_pmt: final_coal_index_pmt,
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        coal_price_pmt: coal_cost_coal_price_pmt,
        billing_currency: coal_cost_billing_currency,
        penality_currency: coal_cost_penality_currency,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        payable_to_supplier: paid_to_supplier,
        costing_type: costing_type,
        vat_applicable: vat_applicable,
        invoice_percentage: invoice_percentage,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Costings by Business number ID
  updateBargePurchaseCoalCostings(
    loginUserID,
    idToken,
    bargePurFinaID,
    businessNoBargeID,
    coal_cost_coal_quantity,
    coal_cost_coal_price_pmt,
    coal_cost_billing_currency,
    coal_cost_penality_currency,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    paid_to_supplier
  ) {
    let url = config.apiDomain + "/update_barge_purchase_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        barge_pur_fin_id: bargePurFinaID,
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        coal_price_pmt: coal_cost_coal_price_pmt,
        billing_currency: coal_cost_billing_currency,
        penality_currency: coal_cost_penality_currency,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        payable_to_supplier: paid_to_supplier,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Costings by Business number ID
  update_barge_pur_fin_coal_costing(
    loginUserID,
    idToken,
    bargePurFinaID,
    businessNoBargeID,
    invoice_no,
    invoice_percentage,
    invoice_date,
    ppn,
    ppn_date,
    coal_index_type,
    index_ids,
    average_coal_index_price,
    prorata_gar,
    prorata_coal_index_price,
    bonus_coal_index_pmt,
    penality_coal_index_pmt,
    derivered_coal_index_pmt,
    final_coal_index_pmt,
    coal_cost_coal_quantity,
    coal_cost_coal_price_pmt,
    coal_cost_billing_currency,
    coal_cost_penality_currency,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    paid_to_supplier,
    vat_applicable,
    vat_amount,
    vat_percent,
    vat_value,
    existing_file_ids,
    files,
    costing
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: bargePurFinaID,
        barge_id: businessNoBargeID,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_percentage: invoice_percentage,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        coal_index_type: coal_index_type,
        index_ids: index_ids,
        average_coal_index_price: average_coal_index_price,
        prorata_gar: prorata_gar,
        prorata_coal_index_price: prorata_coal_index_price,
        bonus_coal_index_pmt: bonus_coal_index_pmt,
        penality_coal_index_pmt: penality_coal_index_pmt,
        derivered_coal_index_pmt: derivered_coal_index_pmt,
        final_coal_index_pmt: final_coal_index_pmt,
        barge_quantity: coal_cost_coal_quantity,
        billing_currency: coal_cost_billing_currency,
        penality_currency: coal_cost_penality_currency,
        coal_price_pmt: coal_cost_coal_price_pmt,
        coal_price: coal_cost_coal_price,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        payable_to_supplier: paid_to_supplier,
        advance_adjustments: advance_adjustments,
        vat_applicable: vat_applicable,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO Barge Purchase Financial Barge Costing api's
   *
   */

  // Add Barge Barge Costings by Business number ID
  addBargePurchaseBargeCosting(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    barge_quantity,
    pph15_account,
    barge_base_price,
    barge_vat_percent,
    barge_vat_value,
    barge_pph15_percent,
    barge_pph15_value,
    barge_vendor_total_value,
    currency_xchg_rate,
    barge_base_price_in_local_currency,
    barge_ctc,
    sdf_base_price,
    sdf_vat_percent,
    sdf_vat_value,
    sdf_vendor_total_value,
    sdf_pph15_percent,
    sdf_ctc,
    idf_base_price,
    idf_vat_percent,
    idf_vat_value,
    idf_pph15_percent,
    idf_pph15_value,
    idf_vendor_total_value,
    idf_ctc
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        business_no_id: businessNoID,
        barge_quantity: barge_quantity,
        pph15_account: pph15_account,
        barge_base_price: barge_base_price,
        barge_vat_percent: barge_vat_percent,
        barge_vat_value: barge_vat_value,
        barge_pph15_percent: barge_pph15_percent,
        barge_pph15_value: barge_pph15_value,
        barge_vendor_total_value: barge_vendor_total_value,
        currency_xchg_rate: currency_xchg_rate,
        barge_base_price_in_local_currency: barge_base_price_in_local_currency,
        barge_ctc: barge_ctc,
        sdf_base_price: sdf_base_price,
        sdf_vat_percent: sdf_vat_percent,
        sdf_vat_value: sdf_vat_value,
        sdf_vendor_total_value: sdf_vendor_total_value,
        sdf_ctc: sdf_ctc,
        idf_base_price: idf_base_price,
        idf_vat_percent: idf_vat_percent,
        idf_vat_value: idf_vat_value,
        idf_pph15_percent: idf_pph15_percent,
        idf_pph15_value: idf_pph15_value,
        idf_vendor_total_value: idf_vendor_total_value,
        idf_ctc: idf_ctc,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Delete Barge Costing financial
  delete_barge_purchase_financials_barging(
    loginUserID,
    idToken,
    bargePurFinaID
  ) {
    let url = config.apiDomain + "/delete_barge_purchase_financials_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: bargePurFinaID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update Barge Purchase Financial Barge Costing
  update_barge_purchase_financials_barging(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    bargePurFinaID,
    barge_quantity,
    pph15_account,
    barge_base_price,
    barge_vat_percent,
    barge_vat_value,
    barge_pph15_percent,
    barge_pph15_value,
    barge_vendor_total_value,
    currency_xchg_rate,
    barge_base_price_in_local_currency,
    barge_ctc,
    sdf_base_price,
    sdf_vat_percent,
    sdf_vat_value,
    sdf_vendor_total_value,
    sdf_pph15_percent,
    sdf_ctc,
    idf_base_price,
    idf_vat_percent,
    idf_vat_value,
    idf_pph15_percent,
    idf_pph15_value,
    idf_vendor_total_value,
    idf_ctc
  ) {
    let url = config.apiDomain + "/update_barge_purchase_financials_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        barge_pur_fin_id: bargePurFinaID,
        business_no_id: businessNoID,
        barge_quantity: barge_quantity,
        pph15_account: pph15_account,
        barge_base_price: barge_base_price,
        barge_vat_percent: barge_vat_percent,
        barge_vat_value: barge_vat_value,
        barge_pph15_percent: barge_pph15_percent,
        barge_pph15_value: barge_pph15_value,
        barge_vendor_total_value: barge_vendor_total_value,
        currency_xchg_rate: currency_xchg_rate,
        barge_base_price_in_local_currency: barge_base_price_in_local_currency,
        barge_ctc: barge_ctc,
        sdf_base_price: sdf_base_price,
        sdf_vat_percent: sdf_vat_percent,
        sdf_vat_value: sdf_vat_value,
        sdf_vendor_total_value: sdf_vendor_total_value,
        sdf_ctc: sdf_ctc,
        idf_base_price: idf_base_price,
        idf_vat_percent: idf_vat_percent,
        idf_vat_value: idf_vat_value,
        idf_pph15_percent: idf_pph15_percent,
        idf_pph15_value: idf_pph15_value,
        idf_vendor_total_value: idf_vendor_total_value,
        idf_ctc: idf_ctc,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Procurement Change by Business number ID
  addBargePurchaseCoalProCharge(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    multiRows,
    existing_file_ids,
    content_files
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: businessNoBargeID,
        cpc_data: multiRows,
        existing_file_ids: existing_file_ids,
        files: content_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Delete Barge Coal Procurement
  delete_barge_purchase_financials_cpc(loginUserID, idToken, bargePurFinaID) {
    let url = config.apiDomain + "/delete_barge_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: bargePurFinaID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // update update_barge_purchase_financials_cpc

  update_barge_purchase_financials_cpc(
    loginUserID,
    idToken,
    businessNoBargeID,
    multiRows
  ) {
    let url = config.apiDomain + "/update_barge_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        cpc_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Add Barge Coal Procurement Change by Business number ID
  addBargeSalesCoalSalesCharge(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    multiRows
  ) {
    let url = config.apiDomain + "/add_barge_sales_financials_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: businessNoBargeID,
        csc_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_sales_financials_csc(
    loginUserID,
    idToken,
    businessNoBargeID,
    multiRows
  ) {
    let url = config.apiDomain + "/update_barge_sales_financials_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        csc_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Delete coal sales charges Barge Sales financials
  delete_barge_sales_financials_csc(
    loginUserID,
    idToken,
    business_no_barge_id
  ) {
    let url = config.apiDomain + "/delete_barge_sales_financials_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barge_list(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_pur_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barge_coal_list(loginUserID, idToken, businessNoID, costing_type) {
    let url = config.apiDomain + "/get_barges_for_pur_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        costing_type: costing_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barge_coal_costing(bargePurFinaID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_purchase_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: bargePurFinaID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Sales contract based Barges by Business number ID
  get_sales_barge_coal_costing(
    barge_sales_fin_id,
    loginUserID,
    idToken,
    business_no_id
  ) {
    let url = config.apiDomain + "/get_barge_sales_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_fin_id: barge_sales_fin_id,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barge_costing(bargePurFinaID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_purchase_financials_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: bargePurFinaID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barge_cpc(business_no_barge_group_id, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_group_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_sales_barge_cpc(barge_sales_fin_id, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_sales_financials_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: barge_sales_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_sc_barge_demmurage(bargeID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_sales_financials_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: bargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barges(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_pur_financials_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_pc_barges_cpc_data(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_pur_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_barge_pre_shipments(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_barge_pre_shipments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? BARGE PURCHASE FINANCIAL PRE SHIPMENT INSHPETION API'S
   *
   */
  add_barge_pre_shipment(
    loginUserID,
    idToken,
    businessNoID,
    business_no_barge_id,
    barge_pre_shipment_data
  ) {
    let url = config.apiDomain + "/add_barge_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: business_no_barge_id,
        barge_pre_shipment_data: barge_pre_shipment_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pre_shipment(loginUserID, idToken, business_no_barge_group_id) {
    let url = config.apiDomain + "/get_barge_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_group_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pre_shipment(
    loginUserID,
    idToken,
    businessNoID,
    business_no_barge_id,
    barge_pre_shipment_data
  ) {
    let url = config.apiDomain + "/update_barge_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: business_no_barge_id,
        barge_pre_shipment_data: barge_pre_shipment_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pre_shipment(loginUserID, idToken, barge_pre_shipment_id) {
    let url = config.apiDomain + "/delete_barge_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_pre_shipment_payment_postings(
    loginUserID,
    idToken,
    barge_pre_shipment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pre_shipment_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pre_shipment_payment_postings(
    loginUserID,
    idToken,
    barge_pre_shipment_id
  ) {
    let url = config.apiDomain + "/get_barge_pre_shipment_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pre_shipment_payment_postings(
    loginUserID,
    idToken,
    barge_pre_shipment_id,
    pre_shipment_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pre_shipment_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
        pre_shipment_payment_id: pre_shipment_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *  TODO ??   BARGE PURCHASE FINANCIAL EXPORT TAX API'S
   *
   */

  add_barge_pur_fin_export_tax(
    loginUserID,
    idToken,
    businessNoID,
    business_no_barge_id,
    export_tax_applicable,
    quantity_for_export_tax,
    export_tax_per_mt,
    export_tax,
    export_currency_xchg_rate,
    export_tax_in_local_currency
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: business_no_barge_id,
        export_tax_applicable: export_tax_applicable,
        quantity_for_export_tax: quantity_for_export_tax,
        export_tax_per_mt: export_tax_per_mt,
        export_tax: export_tax,
        export_currency_xchg_rate: export_currency_xchg_rate,
        export_tax_in_local_currency: export_tax_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_export_tax(loginUserID, idToken, export_tax_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        export_tax_id: export_tax_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_export_tax(
    loginUserID,
    idToken,
    businessNoID,
    business_no_barge_id,
    export_tax_id,
    export_tax_applicable,
    quantity_for_export_tax,
    export_tax_per_mt,
    export_tax,
    export_currency_xchg_rate,
    export_tax_in_local_currency
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_barge_id: business_no_barge_id,
        export_tax_id: export_tax_id,
        export_tax_applicable: export_tax_applicable,
        quantity_for_export_tax: quantity_for_export_tax,
        export_tax_per_mt: export_tax_per_mt,
        export_tax: export_tax,
        export_currency_xchg_rate: export_currency_xchg_rate,
        export_tax_in_local_currency: export_tax_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_pur_fin_export_tax(
    loginUserID,
    idToken,
    barge_pur_fin_export_tax_id
  ) {
    let url = config.apiDomain + "/delete_barge_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_export_tax_id: barge_pur_fin_export_tax_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_barge_pur_fin_export_tax_payments(
    loginUserID,
    idToken,
    barge_pur_fin_export_tax_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_export_tax_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_export_tax_id: barge_pur_fin_export_tax_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_pur_fin_export_tax_payments(
    loginUserID,
    idToken,
    barge_pur_fin_export_tax_id,
    export_tax_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_export_tax_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_export_tax_id: barge_pur_fin_export_tax_id,
        export_tax_payment_id: export_tax_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_export_tax_payments(
    loginUserID,
    idToken,
    barge_pur_fin_export_tax_id
  ) {
    let url = config.apiDomain + "/get_barge_pur_fin_export_tax_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_export_tax_id: barge_pur_fin_export_tax_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Sales contract based Barges by Business number ID
  get_sc_barge_list(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_sales_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Sales contract based Barges by Business number ID
  get_sc_barge_coal_list(loginUserID, idToken, businessNoID, costing_type) {
    let url = config.apiDomain + "/get_barges_for_sales_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        costing_type: costing_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Sales contract based Barges by Business number ID
  get_sc_barge_csc_data(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_sales_financials_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Sales contract based Barges by Business number ID
  get_sc_barge_others_data(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_barges_for_sales_financials_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Coal Costings by Business number ID
  addBargeSalesCoalCostings(
    loginUserID,
    idToken,
    performa_mapping,
    businessNoID,
    business_allocation_id,
    businessNoBargeID,
    invoice_no,
    invoice_date,
    ppn,
    coal_index_type,
    index_ids,
    average_coal_index_price,
    prorata_gar,
    prorata_coal_index_price,
    bonus_coal_index_pmt,
    penality_coal_index_pmt,
    derivered_coal_index_pmt,
    final_coal_index_pmt,
    coal_cost_coal_quantity,
    coal_cost_billing_currency,
    coal_cost_coal_price_pmt,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    freight_price_pmt,
    receivable_from_supplier,
    costing_type,
    vat_applicable,
    vat_amount,
    vat_percent,
    vat_value,
    content_files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_sales_financials_coal";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        performa_mapping: performa_mapping,
        business_no_id: businessNoID,
        business_allocation_id: business_allocation_id,
        barge_id: businessNoBargeID,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        coal_index_type: coal_index_type,
        index_ids: index_ids,
        average_coal_index_price: average_coal_index_price,
        prorata_gar: prorata_gar,
        prorata_coal_index_price: prorata_coal_index_price,
        bonus_coal_index_pmt: bonus_coal_index_pmt,
        penality_coal_index_pmt: penality_coal_index_pmt,
        derivered_coal_index_pmt: derivered_coal_index_pmt,
        final_coal_index_pmt: final_coal_index_pmt,
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        billing_currency: coal_cost_billing_currency,
        coal_price_pmt: coal_cost_coal_price_pmt,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        freight_price_pmt: freight_price_pmt,
        receivable_from_supplier: receivable_from_supplier,
        costing_type: costing_type,
        vat_applicable: vat_applicable,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        commercial_files: content_files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // update barge Sales financial coal costing
  update_barge_sales_financials_coal(
    loginUserID,
    idToken,
    performa_mapping,
    barge_sales_fin_id,
    businessNoID,
    business_allocation_id,
    businessNoBargeID,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    coal_index_type,
    index_ids,
    average_coal_index_price,
    prorata_gar,
    prorata_coal_index_price,
    bonus_coal_index_pmt,
    penality_coal_index_pmt,
    derivered_coal_index_pmt,
    final_coal_index_pmt,
    coal_cost_coal_quantity,
    coal_cost_billing_currency,
    coal_cost_coal_price_pmt,
    coal_cost_coal_price,
    coal_cost_gar_bonus,
    coal_cost_gar_penality,
    coal_cost_ash_penality,
    coal_cost_sulphur_penality,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    freight_price_pmt,
    receivable_from_supplier,
    vat_applicable,
    vat_amount,
    vat_percent,
    vat_value,
    existing_file_ids,
    content_files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_barge_sales_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        performa_mapping: performa_mapping,
        barge_sales_fin_id: barge_sales_fin_id,
        business_no_id: businessNoID,
        business_allocation_id: business_allocation_id,
        barge_id: businessNoBargeID,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        coal_index_type: coal_index_type,
        index_ids: index_ids,
        average_coal_index_price: average_coal_index_price,
        prorata_gar: prorata_gar,
        prorata_coal_index_price: prorata_coal_index_price,
        bonus_coal_index_pmt: bonus_coal_index_pmt,
        penality_coal_index_pmt: penality_coal_index_pmt,
        derivered_coal_index_pmt: derivered_coal_index_pmt,
        final_coal_index_pmt: final_coal_index_pmt,
        barge_quantity: coal_cost_coal_quantity,
        billing_currency: coal_cost_billing_currency,
        coal_price: coal_cost_coal_price,
        coal_price_pmt: coal_cost_coal_price_pmt,
        gar_bonus_pmt: coal_cost_gar_bonus,
        gar_penality_pmt: coal_cost_gar_penality,
        ash_penality_pmt: coal_cost_ash_penality,
        sulphur_penality_pmt: coal_cost_sulphur_penality,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        freight_price_pmt: freight_price_pmt,
        receivable_from_supplier: receivable_from_supplier,
        vat_applicable: vat_applicable,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        commercial_existing_file_ids: existing_file_ids,
        commercial_files: content_files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
   * // TODO :::: ---------------- Business Number Barge Sales Financial API'S -----------------------
   */

  add_barge_sales_demmurage(
    loginUserID,
    idToken,
    businessNoID,
    businessNoBargeID,
    dmr_to_buyer_value,
    dmr_to_buyer_currency_xchg_rate,
    dmr_to_buyer_pph23_account,
    dmr_to_buyer_base_price,
    dmr_to_buyer_vat_percent,
    dmr_to_buyer_vat_value,
    dmr_to_buyer_pph23_percent,
    dmr_to_buyer_pph23_value,
    dmr_to_buyer_total,
    dmr_to_buyer_ctc,
    dmr_to_buyer_ctc_in_local_currency,
    dmr_from_buyer_value,
    dmr_from_buyer_currency_xchg_rate,
    dmr_from_buyer_pph23_account,
    dmr_from_buyer_base_price,
    dmr_from_buyer_vat_percent,
    dmr_from_buyer_vat_value,
    dmr_from_buyer_pph23_percent,
    dmr_from_buyer_pph23_value,
    dmr_from_buyer_total,
    dmr_from_buyer_ctc,
    dmr_from_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/add_barge_sales_financials_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        business_no_id: businessNoID,

        dmr_to_buyer_value: dmr_to_buyer_value,
        dmr_to_buyer_currency_xchg_rate: dmr_to_buyer_currency_xchg_rate,
        dmr_to_buyer_pph23_account: dmr_to_buyer_pph23_account,
        dmr_to_buyer_base_price: dmr_to_buyer_base_price,
        dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
        dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,
        dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
        dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
        dmr_to_buyer_total: dmr_to_buyer_total,
        dmr_to_buyer_ctc: dmr_to_buyer_ctc,
        dmr_to_buyer_ctc_in_local_currency: dmr_to_buyer_ctc_in_local_currency,

        dmr_from_buyer_value: dmr_from_buyer_value,
        dmr_from_buyer_currency_xchg_rate: dmr_from_buyer_currency_xchg_rate,
        dmr_from_buyer_pph23_account: dmr_from_buyer_pph23_account,
        dmr_from_buyer_base_price: dmr_from_buyer_base_price,
        dmr_from_buyer_vat_percent: dmr_from_buyer_vat_percent,
        dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
        dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
        dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
        dmr_from_buyer_total: dmr_from_buyer_total,
        dmr_from_buyer_ctc: dmr_from_buyer_ctc,
        dmr_from_buyer_ctc_in_local_currency:
          dmr_from_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update barge sales financial demurrage date
  update_barge_sales_financials_demurrage(
    loginUserID,
    idToken,
    businessNoID,
    barge_sales_fin_id,
    businessNoBargeID,
    dmr_to_buyer_value,
    dmr_to_buyer_currency_xchg_rate,
    dmr_to_buyer_pph23_account,
    dmr_to_buyer_base_price,
    dmr_to_buyer_vat_percent,
    dmr_to_buyer_vat_value,
    dmr_to_buyer_pph23_percent,
    dmr_to_buyer_pph23_value,
    dmr_to_buyer_total,
    dmr_to_buyer_ctc,
    dmr_to_buyer_ctc_in_local_currency,
    dmr_from_buyer_value,
    dmr_from_buyer_currency_xchg_rate,
    dmr_from_buyer_pph23_account,
    dmr_from_buyer_base_price,
    dmr_from_buyer_vat_percent,
    dmr_from_buyer_vat_value,
    dmr_from_buyer_pph23_percent,
    dmr_from_buyer_pph23_value,
    dmr_from_buyer_total,
    dmr_from_buyer_ctc,
    dmr_from_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/update_barge_sales_financials_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        business_no_id: businessNoID,
        barge_sales_fin_id: barge_sales_fin_id,
        dmr_to_buyer_value: dmr_to_buyer_value,
        dmr_to_buyer_currency_xchg_rate: dmr_to_buyer_currency_xchg_rate,
        dmr_to_buyer_pph23_account: dmr_to_buyer_pph23_account,
        dmr_to_buyer_base_price: dmr_to_buyer_base_price,
        dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
        dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,
        dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
        dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
        dmr_to_buyer_total: dmr_to_buyer_total,
        dmr_to_buyer_ctc: dmr_to_buyer_ctc,
        dmr_to_buyer_ctc_in_local_currency: dmr_to_buyer_ctc_in_local_currency,

        dmr_from_buyer_value: dmr_from_buyer_value,
        dmr_from_buyer_currency_xchg_rate: dmr_from_buyer_currency_xchg_rate,
        dmr_from_buyer_pph23_account: dmr_from_buyer_pph23_account,
        dmr_from_buyer_base_price: dmr_from_buyer_base_price,
        dmr_from_buyer_vat_percent: dmr_from_buyer_vat_percent,
        dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
        dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
        dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
        dmr_from_buyer_total: dmr_from_buyer_total,
        dmr_from_buyer_ctc: dmr_from_buyer_ctc,
        dmr_from_buyer_ctc_in_local_currency:
          dmr_from_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
   *
   * // TODO <!---------------------------------- Vendor Advance Contract API'S -------------------------------!>
   *
   */

  //New Vendor Advance Contract
  addVendorAdvanceContract(
    loginUserID,
    vendorName,
    supplierReferenceNo,
    advanceAmount,
    contractDate,
    remarks,
    idToken,
    contract_type,
    billing_currency
  ) {
    let url = config.apiDomain + "/add_vendor_adv_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_id: vendorName,
        supplier_ref_no: supplierReferenceNo
          ? supplierReferenceNo.toUpperCase()
          : "",
        advance_amount: advanceAmount,
        contract_date: dateFormateToDB(contractDate),
        remarks: remarks ? remarks.toUpperCase() : "",
        contract_type: contract_type,
        billing_currency: billing_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // for get total vendor advance contacts
  getVendorsAdvanceContracts(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_vendor_adv_contracts";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Get Vendor Advance Contract Details
  getVendorAdvanceContractDetails(
    vendorAdvanceContractID,
    loginUserID,
    idToken
  ) {
    let url = config.apiDomain + "/get_vendor_adv_contract";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        vendor_adv_contract_id: vendorAdvanceContractID,
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Update Vendor Advance Contract
  updateVendorAdvanceContract(
    loginUserID,
    vendorAdvanceContractID,
    vendorName,
    supplierReferenceNo,
    advanceAmount,
    contractDate,
    remarks,
    idToken,
    contract_type,
    billing_currency,
    status
  ) {
    let url = config.apiDomain + "/update_vendor_adv_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_contract_id: vendorAdvanceContractID,
        vendor_id: vendorName,
        supplier_ref_no: supplierReferenceNo
          ? supplierReferenceNo.toUpperCase()
          : "",
        advance_amount: advanceAmount,
        contract_date: dateFormateToDB(contractDate),
        remarks: remarks ? remarks.toUpperCase() : "",
        contract_type: contract_type,
        billing_currency: billing_currency,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO : ------------------------------------- Vendor Advanced Payments----------------------------
   *
   * */

  // Get vendor advance payment details based Advance Contract Id
  getVendor_adv_paymentByContractID(
    loginUserID,
    idToken,
    vendorAdvanceContractID,
    status
  ) {
    let url = config.apiDomain + "/get_vendor_adv_payments_by_contract_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_contract_id: vendorAdvanceContractID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Vendor Advance payment

  add_vendor_adv_payment(request) {
    let url = config.apiDomain + "/add_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_vendor_adv_payment(request) {
    let url = config.apiDomain + "/update_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  addVendorAdvancePayment(
    loginUserID,
    idToken,
    vendorAdvanceContractID,
    paymentDate,
    amount,
    invoice,
    payment_currency,
    currency_xchg_rate,
    amount_in_payment_currency,
    remarks
  ) {
    let url = config.apiDomain + "/add_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_contract_id: vendorAdvanceContractID,
        payment_date: dateFormateToDB(paymentDate),
        amount: amount,
        payment_currency: payment_currency,
        currency_xchg_rate: currency_xchg_rate,
        amount_in_payment_currency: amount_in_payment_currency,
        invoice: invoice.toUpperCase(),
        remarks: remarks.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Update Vendor Advance Payment Based on Advance Payment Id.
  updateVendorAdvancePayment(
    loginUserID,
    idToken,
    advancePaymentID,
    paymentDate,
    amount,
    invoice,
    vendorAdvanceContractID,
    payment_currency,
    currency_xchg_rate,
    amount_in_payment_currency,
    remarks
  ) {
    let url = config.apiDomain + "/update_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_payment_id: advancePaymentID,
        vendor_adv_contract_id: vendorAdvanceContractID,
        payment_date: dateFormateToDB(paymentDate),
        amount: amount,
        payment_currency: payment_currency,
        currency_xchg_rate: currency_xchg_rate,
        amount_in_payment_currency: amount_in_payment_currency,
        invoice: invoice.toUpperCase(),
        remarks: remarks.toUpperCase(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Get Vendor Advance Payment Based On Advance Payment ID.
  getVendorAdvancePayment(loginUserID, idToken, advancePaymentID) {
    let url = config.apiDomain + "/get_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_payment_id: advancePaymentID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Delete Vendor Advance Payment
  deleteVendorAdvancePayment(
    loginUserID,
    idToken,
    advancePaymentID,
    paymentDate,
    amount,
    vendorAdvanceContractID,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_vendor_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_payment_id: advancePaymentID,
        vendor_adv_contract_id: vendorAdvanceContractID,
        payment_date: dateFormateToDB(paymentDate),
        amount: amount,
        remarks: remarks,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //get_adv_adjustments_by_adv_contract_id
  get_adv_adjustments_by_adv_contract_id(
    loginUserID,
    idToken,
    vendor_adv_contract_id
  ) {
    let url = config.apiDomain + "/get_adv_adjustments_by_adv_contract_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_adv_contract_id: vendor_adv_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_vendor_adv_invoice(request) {
    let url = config.apiDomain + "/add_vendor_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_vendor_adv_invoice(request) {
    let url = config.apiDomain + "/update_vendor_adv_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
   *
   * // TODO <!---------------------------------- Customer Advance Contracts Payments -------------------------------!>
   *
   */

  // Add Customer Advance Payement
  addCustomerAdvancePayament(
    loginUserID,
    idToken,
    salesContractId,
    paymentDate,
    amount,
    payment_currency,
    currency_xchg_rate,
    amount_in_payment_currency,
    invoice,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/add_customer_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_contract_id: salesContractId,
        payment_date: dateFormateToDB(paymentDate),
        amount: amount,
        payment_currency: payment_currency,
        currency_xchg_rate: currency_xchg_rate,
        amount_in_payment_currency: amount_in_payment_currency,
        invoice: invoice.toUpperCase(),
        remarks: remarks.toUpperCase(),
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_customer_adv_payment(request) {
    let url = config.apiDomain + "/add_customer_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_customer_adv_payment(request) {
    let url = config.apiDomain + "/update_customer_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update Customer Advance Payment
  updateCustomerAdvancePayment(
    loginUserID,
    idToken,
    customerAdvancePaymentID,
    paymentDate,
    amount,
    payment_currency,
    currency_xchg_rate,
    amount_in_payment_currency,
    invoice,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_customer_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        customer_adv_payment_id: customerAdvancePaymentID,
        payment_date: dateFormateToDB(paymentDate),
        amount: amount,
        payment_currency: payment_currency,
        currency_xchg_rate: currency_xchg_rate,
        amount_in_payment_currency: amount_in_payment_currency,
        invoice: invoice.toUpperCase(),
        remarks: remarks.toUpperCase(),
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Total Customer Advance payments by sales contract ID.
  getCustomerAdvancePaymentsBySalesContractID(
    loginUserID,
    idToken,
    salesContractId,
    status
  ) {
    let url = config.apiDomain + "/get_customer_adv_payments_by_contract_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_contract_id: salesContractId,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Customer Advance Payment
  getCustomerAdvancePayment(
    loginUserID,
    idToken,
    customerAdvancePaymentID,
    status
  ) {
    let url = config.apiDomain + "/get_customer_adv_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        customer_adv_payment_id: customerAdvancePaymentID,
        status: status,
      }),
    });
  },

  /*
   *
   * // TODO <!---------------------------------- Barge Purchase Quality Results API -------------------------------!>
   *
   */
  // get Purchase Quality Results BY Barge ID.
  getAllPurchaseSpecsByBargeID(loginUserID, idToken, businessNoBargeID) {
    let url = config.apiDomain + "/get_pcq_typical_specs";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: businessNoBargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Purchase Quality Result
  addBargePurchaseQualityResults(
    loginUserID,
    idToken,
    businessNoBargeID,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/add_barge_pur_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update Barge Purchase Quality Results
  updateBargePurchaseQualityResults(
    loginUserID,
    idToken,
    businessNoBargeID,
    barge_pur_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        barge_pur_quality_id: barge_pur_quality_id,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual.toUpperCase(),
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Barge Purchase Quality Results
  getBargePurchaseQualityResult(loginUserID, idToken, barge_pur_quality_id) {
    let url = config.apiDomain + "/get_barge_pur_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_quality_id: barge_pur_quality_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get Purchase Quality Results BY Barge ID.
  getPurchaseQualityResultsByBargeID(loginUserID, idToken, businessNoBargeID) {
    let url = config.apiDomain + "/get_barge_pur_quality_results_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get Purchase Quality Results BY Barge ID.
  getQualityResultsByBargeID(loginUserID, idToken, bargeID) {
    let url = config.apiDomain + "/get_quality_results_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: bargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /*
   *
   * // TODO <!------------------------------------------- Barge Sales Quality Results API's ---------------------------!>
   *
   */

  // Get All Purchase Specification Based on Barge ID

  getAllSalesSpecByBargeID(
    loginUserID,
    idToken,
    business_no_id,
    business_allocation_id,
    businessNoBargeID
  ) {
    let url = config.apiDomain + "/get_scq_typical_specs";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_allocation_id: business_allocation_id,
        business_no_barge_id: businessNoBargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Add Barge Sales Quality Result
  addBargeSalesQualityResults(
    loginUserID,
    idToken,
    business_no_id,
    businessNoBargeID,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/add_barge_sales_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_no_barge_id: businessNoBargeID,
        quality_calorific_value_actual: quality_calorific_value_actual,
        quality_calorific_value2_actual: quality_calorific_value2_actual,
        quality_calorific_value3_actual: quality_calorific_value3_actual,
        quality_total_moisture_actual: quality_total_moisture_actual,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual,
        quality_ash_actual: quality_ash_actual,
        quality_volatile_matter_actual: quality_volatile_matter_actual,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual,
        quality_total_sulphur_actual: quality_total_sulphur_actual,
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // update Barge Sales Quality Result
  updateBargeSalesQualityResults(
    loginUserID,
    idToken,
    business_no_id,
    businessNoBargeID,
    barge_sales_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_no_barge_id: businessNoBargeID,
        barge_sales_quality_id: barge_sales_quality_id,
        quality_calorific_value_actual: quality_calorific_value_actual,
        quality_calorific_value2_actual: quality_calorific_value2_actual,
        quality_calorific_value3_actual: quality_calorific_value3_actual,
        quality_total_moisture_actual: quality_total_moisture_actual,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual,
        quality_ash_actual: quality_ash_actual,
        quality_volatile_matter_actual: quality_volatile_matter_actual,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual,
        quality_total_sulphur_actual: quality_total_sulphur_actual,
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Barge Sales Quality Results Based ON Barge ID.
  getBargSalesQualityResultByBargeID(
    loginUserID,
    idToken,
    business_no_id,
    businessNoBargeID
  ) {
    let url = config.apiDomain + "/get_barge_sales_quality_results_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_no_barge_id: businessNoBargeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO <!-------------------------- User Access Management API's -----------------------------!>
   *
   */

  // Add User Feature Maping
  addUserFeatureMaping(loginUserID, idToken, userID, feature_ids) {
    let url = config.apiDomain + "/add_user_feature_mapping";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        user_id: userID,
        feature_ids: feature_ids,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get user Feature Maping Based on User ID.

  getUserFeatureMaping(loginUserID, idToken, userID) {
    let url = config.apiDomain + "/get_user_feature_mapping_by_user";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        user_id: userID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Check user Feature Maping

  checkUserFeatureMaping(loginUserID, idToken, userID, featureName) {
    let url = config.apiDomain + "/check_user_feature_access";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        user_id: userID,
        feature_name: featureName,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Update Quality Standard for View Business Number Page
  updateQualityStandard(
    loginUserID,
    idToken,
    businessAllocationID,
    type,
    selling_quality_standard,
    procurement_quality_standard
  ) {
    let url = config.apiDomain + "/update_quality_standard";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_allocation_id: businessAllocationID,
        type: type,
        selling_quality_standard: selling_quality_standard,
        procurement_quality_standard: procurement_quality_standard,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * ?? Barge Purchase Payment Postings API's
   *
   */

  /**
   *
   * get_barge_purchase_payment_posting_by_financial_id
   *
   */

  get_barge_purchase_payment_posting_by_financial_id(
    loginUserID,
    idToken,
    bargePurchaseFinancialID,
    status
  ) {
    let url =
      config.apiDomain + "/get_barge_purchase_payment_posting_by_financial_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurchaseFinancialID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pc_coal_payments(
    loginUserID,
    idToken,
    bargePurchaseFinancialID,
    status
  ) {
    let url = config.apiDomain + "/get_barge_purchase_financials_coal_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurchaseFinancialID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //New service for coal costing payment  list
  get_barge_pur_fin_coal_payments(
    loginUserID,
    idToken,
    bargePurchaseFinancialID,
    status
  ) {
    let url = config.apiDomain + "/get_barge_pur_fin_coal_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_coal_costing_id: bargePurchaseFinancialID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pc_barge_payments(
    loginUserID,
    idToken,
    bargePurchaseFinancialID,
    status
  ) {
    let url =
      config.apiDomain + "/get_barge_purchase_financials_barge_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurchaseFinancialID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pc_cpc_payments(
    loginUserID,
    idToken,
    bargePurchaseFinancialID,
    status
  ) {
    let url = config.apiDomain + "/get_barge_purchase_financials_cpc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurchaseFinancialID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pc_demurrage_payments(loginUserID, idToken, bargeID, status) {
    let url =
      config.apiDomain + "/get_barge_purchase_financials_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: bargeID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * add_barge_purchase_financials_coal_payment
   *
   */

  add_barge_purchase_financials_coal_payment(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_coal_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //new coal costing payments
  add_barge_pur_fin_coal_payment(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_coal_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_coal_costing_id: bargePurFinaID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // update barge purchase financial coal costing paymet posting
  update_barge_pur_fin_coal_payment(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_coal_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        coal_payment_id: payment_id,
        barge_pur_fin_coal_costing_id: bargePurFinaID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO Barge Purchase Financial Barge Costing Payments
   *
   */

  add_barge_purchase_barge_payment_posting(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_financials_barge_payment(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_id: payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO Barge Purchase Financial Coal Procurements Payments
   *
   */

  add_barge_purchase_cpc_payment_posting(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_financials_cpc_payment(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_id: payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /***
   *
   * TODO Barge Purchase Financial Demurrage Payments
   *
   */

  add_barge_purchase_demurrage_payment_posting(
    loginUserID,
    idToken,
    businessNoBargeID,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/add_barge_purchase_financials_demurrage_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_financials_demurrage_payment(
    loginUserID,
    idToken,
    businessNoBargeID,
    payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_demurrage_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        payment_id: payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * update_barge_purchase_payment_posting
   *
   */
  update_barge_purchase_payment_posting(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_id: payment_posting_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_cpc_payment_posting(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_id: payment_posting_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_demurrage_payment_posting(
    loginUserID,
    idToken,
    bargePurFinaID,
    payment_posting_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_demurrage_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_financials_id: bargePurFinaID,
        payment_id: payment_posting_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /***
   *
   *  TODO Barge Purchase Financial other Payment Posting apis
   *
   */

  add_barge_purchase_financials_addl_payment(
    loginUserID,
    idToken,
    businessNoBargeID,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financials_addl_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        //payment_posting_id: payment_posting_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_purchase_financials_addl_payments(
    loginUserID,
    idToken,
    bargeID,
    status
  ) {
    let url = config.apiDomain + "/get_barge_purchase_financials_addl_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: bargeID,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_purchase_financials_addl_payment(
    loginUserID,
    idToken,
    businessNoBargeID,
    payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financials_addl_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: businessNoBargeID,
        payment_id: payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *
   *  TODO /// Sales finanical payment postings api's
   *
   */

  // add barge sales payment posting
  add_mv_sales_performa_coal_invoice_payment(
    loginUserID,
    idToken,
    invoiceID,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_mv_sales_performa_coal_invoice_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        invoice_id: invoiceID,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //  getting  all payment posting data against * financial id
  get_barge_sales_financials_coal_payments(
    loginUserID,
    idToken,
    sales_financials_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_financials_coal_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_financials_id: sales_financials_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // update barge sales payment posting
  update_mv_sales_performa_coal_invoice_payment(
    loginUserID,
    idToken,
    invoice_id,
    payment_id,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_mv_sales_performa_coal_invoice_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        invoice_id: invoice_id,
        payment_id: payment_id,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add barge sales payment posting
  add_barge_sales_payment_demurrage_posting(
    loginUserID,
    idToken,
    invoice_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/add_barge_sales_financials_demurrage_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        //sales_financials_id: sales_financials_id,
        payment_posting_type: payment_posting_type,
        invoice_id: invoice_id,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no,
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // update barge sales payment posting
  update_barge_sales_payment_demurrage_posting(
    loginUserID,
    idToken,
    payment_posting_type,
    payment_posting_id,
    amount,
    invoice_id,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_sales_financials_demurrage_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        payment_posting_type: payment_posting_type,
        payment_id: payment_posting_id,
        amount: amount,
        invoice_id: invoice_id,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no,
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //  getting  payment posting data against * financial id
  get_barge_sales_csc_payment(
    loginUserID,
    idToken,
    sales_financials_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_financials_csc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_financials_id: sales_financials_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //  getting  payment posting data against * financial id
  get_barge_sales_demurrage_payment(
    loginUserID,
    idToken,
    sales_financials_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_financials_csc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_financials_id: sales_financials_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO // Barge Sales Financial Invoice Api's
   *
   */

  //get_barge_sales_fin_coal_invoices
  get_barge_sales_financial_coal_invoices(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_financial_coal_invoices";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_fin_id: barge_sales_financials_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //get_barge_sales_fin_coal_invoices
  get_barge_sales_fin_csc_invoices(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_fin_csc_invoices";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_financials_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_coal_invoices
  add_mv_sales_performa_coal_invoice(
    loginUserID,
    idToken,
    mv_sales_costing_id,
    invoice_number,
    invoice_date,
    invoice_amount,
    invoice_percent,
    invoice_type,
    remarks
  ) {
    let url = config.apiDomain + "/add_mv_sales_performa_coal_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sales_costing_id: mv_sales_costing_id,
        invoice_no: invoice_number ? invoice_number.toUpperCase() : "",
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        invoice_percent: invoice_percent,
        invoice_type: invoice_type,
        remarks: remarks ? remarks.toUpperCase() : "",
        status: "Active",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_demurrage_invoices
  add_barge_sales_financials_demurrage_invoices(
    loginUserID,
    idToken,
    barge_sales_fin_id,
    payment_posting_type,
    invoice_number,
    invoice_date,
    invoice_amount,
    remarks
  ) {
    let url = config.apiDomain + "/add_barge_sales_fin_demurrage_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_fin_id,
        payment_posting_type: payment_posting_type,
        invoice_no: invoice_number ? invoice_number.toUpperCase() : "",
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        remarks: remarks ? remarks.toUpperCase() : "",
        status: "Active",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get invoices list of barge sales financial demurrage invoices
  get_barge_sales_fin_demurrage_invoices(
    loginUserID,
    idToken,
    barge_sales_fin_id,
    status
  ) {
    let url = config.apiDomain + "/get_barge_sales_fin_demurrage_invoices";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_fin_id,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_sales_fin_demurrage_invoice(
    loginUserID,
    idToken,
    barge_sales_fin_id,
    payment_posting_type,
    invoice_number,
    invoice_date,
    invoice_amount,
    invoiceID,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_fin_demurrage_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_fin_id,
        payment_posting_type: payment_posting_type,
        invoice_no: invoice_number ? invoice_number.toUpperCase() : "",
        barge_sales_fin_demurrage_invoice_id: invoiceID,
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        remarks: remarks ? remarks.toUpperCase() : "",
        status: "Active",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_demurrage_invoices
  add_barge_sales_fin_csc_invoice(
    loginUserID,
    idToken,
    barge_sales_fin_id,
    payment_posting_type,
    invoice_type,
    invoice_percent,
    invoice_number,
    invoice_date,
    invoice_amount,
    remarks
  ) {
    let url = config.apiDomain + "/add_barge_sales_fin_csc_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_fin_id,
        payment_posting_type: payment_posting_type,
        invoice_type: invoice_type,
        invoice_percent: invoice_percent,
        invoice_no: invoice_number ? invoice_number.toUpperCase() : "",
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        remarks: remarks ? remarks.toUpperCase() : "",
        status: "Active",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_invoices
  update_mv_sales_performa_coal_invoice(
    loginUserID,
    idToken,
    mv_sales_costing_id,
    mv_sales_performa_coal_invoice_id,
    invoice_number,
    invoice_date,
    invoice_amount,
    invoice_percent,
    invoice_type,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_mv_sales_performa_coal_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sales_costing_id: mv_sales_costing_id,
        mv_sales_performa_coal_invoice_id: mv_sales_performa_coal_invoice_id,
        invoice_no: invoice_number.toUpperCase(),
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        invoice_percent: invoice_percent,
        invoice_type: invoice_type,
        remarks: remarks,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_invoices
  delete_barge_sales_fin_coal_invoice(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    barge_sales_financial_invoice_id,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_financial_coal_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_fin_id: barge_sales_financials_id,
        barge_sales_fin_coal_invoice_id: barge_sales_financial_invoice_id,
        remarks: remarks,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add_barge_sales_financials_invoices
  update_barge_sales_fin_csc_invoice(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    barge_sales_fin_csc_invoice_id,
    invoice_number,
    invoice_date,
    invoice_amount,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_fin_csc_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_financials_id,
        barge_sales_fin_csc_invoice_id: barge_sales_fin_csc_invoice_id,
        //invoice_type : invoice_type,
        //invoice_percent : invoice_percent,
        invoice_no: invoice_number.toUpperCase(),
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        remarks: remarks.toUpperCase(),
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO // Business Number Mother Vessel Purchase Quality Results Api's
   *
   */

  // Add Business Number Mother Vessel Purchase Quality
  add_bn_mv_pur_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/add_bn_mv_pur_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Add Business Number Mother Vessel Purchase Quality
  update_bn_mv_pur_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    bn_mv_pur_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/update_bn_mv_pur_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        bn_mv_pur_quality_id: bn_mv_pur_quality_id,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get_bn_mv_pur_quality_results_by_business_no_id
  get_bn_mv_pur_quality_results_by_business_no_id(
    loginUserID,
    idToken,
    business_no_id
  ) {
    let url =
      config.apiDomain + "/get_bn_mv_pur_quality_results_by_business_no_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO // Mother Vessel Sales Quality Results Api's
   *
   */

  //add_bn_mv_sales_quality_results
  add_bn_mv_sales_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/add_bn_mv_sales_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_bn_mv_sales_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    bn_mv_pur_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status
  ) {
    let url = config.apiDomain + "/update_bn_mv_sales_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        bn_mv_pur_quality_id: bn_mv_pur_quality_id,
        quality_calorific_value_actual:
          quality_calorific_value_actual.toUpperCase(),
        quality_calorific_value2_actual:
          quality_calorific_value2_actual.toUpperCase(),
        quality_calorific_value3_actual:
          quality_calorific_value3_actual.toUpperCase(),
        quality_total_moisture_actual:
          quality_total_moisture_actual.toUpperCase(),
        quality_inherent_moisture_actual:
          quality_inherent_moisture_actual.toUpperCase(),
        quality_ash_actual: quality_ash_actual.toUpperCase(),
        quality_volatile_matter_actual:
          quality_volatile_matter_actual.toUpperCase(),
        quality_fixed_carbon_actual: quality_fixed_carbon_actual.toUpperCase(),
        quality_fuel_ratio_actual: quality_fuel_ratio_actual.toUpperCase(),
        quality_total_sulphur_actual:
          quality_total_sulphur_actual.toUpperCase(),
        quality_hgi_actual: quality_hgi_actual,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //get_bn_mv_sales_quality_results_by_business_no_id
  get_bn_mv_sales_quality_results_by_business_no_id(
    loginUserID,
    idToken,
    business_no_id
  ) {
    let url =
      config.apiDomain + "/get_bn_mv_sales_quality_results_by_business_no_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Update purchase contract in Allocation
  updatePurchaseContractAllocation(
    loginUserID,
    idToken,
    allocationID,
    updatePurchaseContractID,
    updatePurchaseQualitySelectionID,
    updatePurchaseTypeSelectionID
  ) {
    let url = config.apiDomain + "/update_pc_in_business_allocation";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        allocation_id: allocationID,
        purchase_contract_id: updatePurchaseContractID,
        pc_qualities_id: updatePurchaseQualitySelectionID,
        pc_purchase_type_id: updatePurchaseTypeSelectionID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Update Sales contract in Allocation
  updateSalesContractAllocation(
    loginUserID,
    idToken,
    allocationID,
    updateSalesContractID,
    updateSalesQualitySelectionID,
    updateSalesTypeSelectionID
  ) {
    let url = config.apiDomain + "/update_sc_in_business_allocation";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        allocation_id: allocationID,
        sale_contract_id: updateSalesContractID,
        sc_qualities_id: updateSalesQualitySelectionID,
        sc_sales_type_id: updateSalesTypeSelectionID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Delete Barge sales fiancials group/individual
  delete_barge_sales_financials_coal(loginUserID, idToken, barge_sales_fin_id) {
    let url = config.apiDomain + "/delete_barge_sales_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_fin_id: barge_sales_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Delete Barge Purchase fiancials group/individual
  delete_barge_purchase_financials_coal(
    loginUserID,
    idToken,
    barge_pur_fin_id
  ) {
    let url = config.apiDomain + "/delete_barge_purchase_financials_coal";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: barge_pur_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pur_fin_doc_charges(
    loginUserID,
    idToken,
    barge_pur_fin_doc_charges_id
  ) {
    let url = config.apiDomain + "/delete_barge_pur_fin_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_doc_charges_id: barge_pur_fin_doc_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_sac_charges(loginUserID, idToken, business_no_barge_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_sac_charges(
    loginUserID,
    idToken,
    business_no_id,
    business_no_barge_id,
    sac_charges_data
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_no_barge_id: business_no_barge_id,
        sac_charges_data: sac_charges_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pur_fin_sac_charges(
    loginUserID,
    idToken,
    barge_pur_fin_sac_charges_id
  ) {
    let url = config.apiDomain + "/delete_barge_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_sac_charges_id: barge_pur_fin_sac_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_barge_pur_fin_sac_charges_payments(
    loginUserID,
    idToken,
    business_no_barge_id,
    sac_charges_vendor_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_id,
        sac_charges_vendor_id: sac_charges_vendor_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_pur_fin_sac_charges_payments(
    loginUserID,
    idToken,
    business_no_barge_id
  ) {
    let url = config.apiDomain + "/get_barge_pur_fin_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_pur_fin_sac_charges_payments(
    loginUserID,
    idToken,
    business_no_barge_id,
    sac_charges_payment_id,
    sac_charges_vendor_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_barge_id: business_no_barge_id,
        sac_charges_vendor_id: sac_charges_vendor_id,
        sac_charges_payment_id: sac_charges_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO // CHEMICAL SPRAY API'S FOR BARGE PURCHASE FINANCIAL
   *
   */

  add_barge_pur_fin_chemical_spray(
    loginUserID,
    idToken,
    business_no_id,
    business_no_barge_id,
    payee_name,
    currency_exchange_rate,
    local_currency,
    remarks
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_no_barge_id: business_no_barge_id,
        payee_name: payee_name ? payee_name.toUpperCase() : "",
        currency_exchange_rate: currency_exchange_rate,
        local_currency: local_currency,
        remarks: remarks ? remarks.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_chemical_spray(
    loginUserID,
    idToken,
    business_no_id,
    business_no_barge_id,
    chemical_spray_id,
    payee_name,
    currency_exchange_rate,
    local_currency,
    remarks
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        chemical_spray_id: chemical_spray_id,
        business_no_barge_id: business_no_barge_id,
        payee_name: payee_name ? payee_name.toUpperCase() : "",
        currency_exchange_rate: currency_exchange_rate,
        local_currency: local_currency,
        remarks: remarks ? remarks.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_chemical_sprays(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_chemical_sprays";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pur_fin_chemical_sprays(
    loginUserID,
    idToken,
    chemical_spray_id
  ) {
    let url = config.apiDomain + "/delete_barge_pur_fin_chemical_sprays";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_barge_pur_fin_chemical_spray_payments(
    loginUserID,
    idToken,
    chemical_spray_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_chemical_spray_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_chemical_spray(loginUserID, idToken, chemical_spray_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_chemical_spray_payments(
    loginUserID,
    idToken,
    chemical_spray_id,
    chemical_spray_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_pur_fin_chemical_spray_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
        chemical_spray_payment_id: chemical_spray_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO // Mother Vessel Stevedor Costing Api's
   *
   */
  get_barges_for_pur_fin_sac_charges(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_barges_for_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_mv_purchase_financials_stevedore(
    loginUserID,
    idToken,
    businessNoID,
    stevedore_costing,
    files
  ) {
    let url = config.apiDomain + "/add_mv_purchase_financials_stevedore";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        stevedore_costing: stevedore_costing,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_purchase_financials_stevedore(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_purchase_financials_stevedore";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getStevedoreCosting(loginUserID, idToken, stevedoreID) {
    let url = config.apiDomain + "/get_mv_pur_fin_stevedore";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_stevedore_id: stevedoreID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_purchase_financials_stevedore(
    loginUserID,
    idToken,
    businessNoID,
    stevedore_costing
  ) {
    let url = config.apiDomain + "/update_mv_purchase_financials_stevedore";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        stevedore_costing: stevedore_costing,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_stevedore_costing(finalData) {
    let url = config.apiDomain + "/update_mv_pur_fin_stevedore";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings

  add_mv_stevedore_costing_payments(
    loginUserID,
    idToken,
    business_no_id,
    mv_stevedore_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_stevedore_costing_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_stevedore_id: mv_stevedore_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_stevedore_costing_payments(
    loginUserID,
    idToken,
    business_no_id,
    stevedore_vendor_id
  ) {
    let url = config.apiDomain + "/get_mv_stevedore_costing_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        stevedore_vendor_id: stevedore_vendor_id,
      }),
    });
  },

  update_mv_stevedore_costing_payments(
    loginUserID,
    idToken,
    business_no_id,
    mv_stevedore_id,
    stevedore_costing_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_stevedore_costing_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_stevedore_id: mv_stevedore_id,
        stevedore_costing_payment_id: stevedore_costing_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? MOTHER VESSEL FLOATING CRANE CHARGES
   *
   */

  add_mv_pur_fin_floating_crane_charges(
    loginUserID,
    idToken,
    businessNoID,
    floating_crane_vendor,
    files
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        floating_crane_charges: floating_crane_vendor,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_floating_crane_charges(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pur_fin_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getNewFloatingCraneCharges(loginUserID, idToken, floatingCraneID) {
    let url = config.apiDomain + "/get_mv_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        floating_crane_id: floatingCraneID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_floating_crane_charges(
    loginUserID,
    idToken,
    businessNoID,
    floating_crane_vendor
  ) {
    let url = config.apiDomain + "/update_mv_pur_fin_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        floating_crane_vendor: floating_crane_vendor,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_floating_crane_charges(finalData) {
    let url = config.apiDomain + "/update_mv_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Delete floating crane charge
  delete_mv_floating_crane_charges(finalData) {
    let url = config.apiDomain + "/update_mv_floating_crane_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_mv_floating_crane_charges_payments(
    loginUserID,
    idToken,
    business_no_id,
    floating_crane_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_floating_crane_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        floating_crane_id: floating_crane_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_floating_crane_charges_payments(
    loginUserID,
    idToken,
    business_no_id,
    floating_crane_id,
    floating_crane_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_floating_crane_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        floating_crane_id: floating_crane_id,
        floating_crane_payment_id: floating_crane_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO // Mother Vessel Purchase financial Coal Procurement Charges  Api's
   *
   */

  add_mv_purchase_financials_cpc(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_mv_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_pur_fin_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_purchase_financials_cpc(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_cpc(loginUserID, idToken, coalProcurementChargesID) {
    let url = config.apiDomain + "/get_mv_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_cpc_id: coalProcurementChargesID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_purchase_financials_cpc(
    loginUserID,
    idToken,
    businessNoID,
    multiRows
  ) {
    let url = config.apiDomain + "/update_mv_purchase_financials_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_pur_fin_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_cpc(finalData) {
    let url = config.apiDomain + "/update_mv_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? MOTHER VESSEL PURCHASE FINANCIAL PRE SHIPMENT INSPECTION
   *
   */

  add_mv_pre_shipment(
    loginUserID,
    idToken,
    businessNoID,
    mv_pre_shipment_data,
    files
  ) {
    let url = config.apiDomain + "/add_mv_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_pre_shipment_data: mv_pre_shipment_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pre_shipment(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_pre_shipment(loginUserID, idToken, preShipmentID) {
    let url = config.apiDomain + "/get_mv_pur_fin_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_pre_shipment_id: preShipmentID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pre_shipment(
    loginUserID,
    idToken,
    businessNoID,
    mv_pre_shipment_data
  ) {
    let url = config.apiDomain + "/update_mv_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_pre_shipment_data: mv_pre_shipment_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_pre_shipment(finalData) {
    let url = config.apiDomain + "/update_mv_pur_fin_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings

  add_mv_pre_shipment_payment_postings(
    loginUserID,
    idToken,
    mv_pre_shipment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_pre_shipment_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_pre_shipment_id: mv_pre_shipment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pre_shipment_payment_postings(
    loginUserID,
    idToken,
    pre_shipment_payment_id,
    mv_pre_shipment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_pre_shipment_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        pre_shipment_payment_id: pre_shipment_payment_id,
        mv_pre_shipment_id: mv_pre_shipment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? Mother Vessel Sales Financials Api's
   *
   */

  add_mv_sales_fin_csc(loginUserID, idToken, businessNoID, multiRows, files) {
    let url = config.apiDomain + "/add_mv_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_sales_fin_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_sales_fin_csc(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_sales_csc(loginUserID, idToken, coalSalesChargesID) {
    let url = config.apiDomain + "/get_mv_sales_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sales_financials_id: coalSalesChargesID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_sales_fin_csc(loginUserID, idToken, businessNoID, multiRows) {
    let url = config.apiDomain + "/update_mv_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_sales_fin_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_sales_csc(finalData) {
    let url = config.apiDomain + "/update_mv_sales_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO :<!---------------------- MOTHER VESSEL DEMURRAGE  API'S ---------------------------!>
   *
   */

  add_mv_supplier_demurrage(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_mv_supplier_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_demurrage_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_supplier_demurrage(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_supplier_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_supplier_demurrage(
    loginUserID,
    idToken,
    MvSupplierDemurrageID
  ) {
    let url = config.apiDomain + "/get_mv_pur_fin_supplier_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        supplier_demurrage_id: MvSupplierDemurrageID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_supplier_demurrage(loginUserID, idToken, businessNoID, multiRows) {
    let url = config.apiDomain + "/update_mv_supplier_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_demurrage_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_supplier_demurrage(finalData) {
    let url = config.apiDomain + "/update_mv_pur_fin_supplier_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_mv_supplier_demurrage_payments(
    loginUserID,
    idToken,
    business_no_id,
    supplier_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_supplier_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        supplier_demurrage_id: supplier_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_supplier_demurrage_payments(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mv_supplier_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_supplier_demurrage_payments(
    loginUserID,
    idToken,
    business_no_id,
    supplier_demurrage_id,
    supplier_demurrage_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_supplier_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        supplier_demurrage_id: supplier_demurrage_id,
        supplier_demurrage_payment_id: supplier_demurrage_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  Mother Vessel Buyer Api's
   *
   */

  add_mv_buyer_demurrage(loginUserID, idToken, businessNoID, multiRows, files) {
    let url = config.apiDomain + "/add_mv_buyer_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_demurrage_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_buyer_demurrage(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_buyer_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_buyer_demurrage(loginUserID, idToken, MvBuyerDemurrageID) {
    let url = config.apiDomain + "/get_mv_pur_fin_buyer_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        buyer_demurrage_id: MvBuyerDemurrageID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_buyer_demurrage(loginUserID, idToken, businessNoID, multiRows) {
    let url = config.apiDomain + "/update_mv_buyer_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_demurrage_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_buyer_demurrage(finalData) {
    let url = config.apiDomain + "/update_mv_pur_fin_buyer_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_mv_buyer_demurrage_payments(
    loginUserID,
    idToken,
    business_no_id,
    buyer_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_buyer_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        buyer_demurrage_id: buyer_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_buyer_demurrage_payments(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mv_buyer_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_buyer_demurrage_payments(
    loginUserID,
    idToken,
    business_no_id,
    buyer_demurrage_id,
    buyer_demurrage_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_buyer_demurrage_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        buyer_demurrage_id: buyer_demurrage_id,
        buyer_demurrage_payment_id: buyer_demurrage_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO // Export Tax Api's for Mother Vessel Purchase Financials
   *
   */

  add_mv_pur_fin_export_tax(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_export_tax_data: multiRows,
        files: files,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_export_tax(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_export_tax(loginUserID, idToken, ExportTaxID) {
    let url = config.apiDomain + "/get_mv_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_export_tax_id: ExportTaxID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_export_tax(loginUserID, idToken, businessNoID, multiRows) {
    let url = config.apiDomain + "/update_mv_pur_fin_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_export_tax_data: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_export_tax(finalData) {
    let url = config.apiDomain + "/update_mv_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? Documentation Charges for Mother Vessel Purchase Financials
   *
   */

  add_mv_pur_fin_doc_charges(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_doc_charges_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_doc_charges(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pur_fin_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_doc_charges(loginUserID, idToken, MVDocChargesID) {
    let url = config.apiDomain + "/get_mv_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_doc_charges_id: MVDocChargesID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_laparon_survey_charges(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pur_fin_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_doc_charges(finalData) {
    let url = config.apiDomain + "/update_mv_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? SAMPLING & ANALAYSIS CHARGES FOR MOTHER VESSEL PURCHASE FINANCIALS
   *
   */

  add_mv_pur_fin_sac_charges(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        mv_sac_charges_data: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_sac_charges(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_mv_pur_fin_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_sac_charges(loginUserID, idToken, MVSamplingChargesID) {
    let url = config.apiDomain + "/get_mv_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sac_charges_id: MVSamplingChargesID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_sac_charges(finalData) {
    let url = config.apiDomain + "/update_mv_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment postings
  add_mv_sac_charges_payment_postings(
    loginUserID,
    idToken,
    business_no_id,
    mv_sac_charges_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_sac_charges_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_sac_charges_id: mv_sac_charges_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_mv_sac_charges_payment_postings(
    loginUserID,
    idToken,
    business_no_id,
    mv_sac_charges_id,
    sac_charges_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_sac_charges_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_sac_charges_id: mv_sac_charges_id,
        sac_charges_payment_id: sac_charges_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO // Mother Vessel Purchase Financial CPC Payment Postings
   *
   */

  add_mv_pur_fin_cpc_payments(
    loginUserID,
    idToken,
    mv_purchase_financials_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_cpc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_purchase_financials_id: mv_purchase_financials_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no,
        description: description,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_cpc_payments(
    loginUserID,
    idToken,
    payment_id,
    mv_purchase_financials_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_pur_fin_cpc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        payment_id: payment_id,
        mv_purchase_financials_id: mv_purchase_financials_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no,
        description: description,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? MOTHER VESSEL SALES FINANCIALS CSC PAYMENT POSTINGS
   *
   */

  add_mv_sales_fin_csc_payments(
    loginUserID,
    idToken,
    mv_sales_financials_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_sales_fin_csc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sales_financials_id: mv_sales_financials_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_sales_fin_csc_payments(
    loginUserID,
    idToken,
    payment_id,
    mv_sales_financials_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_sales_fin_csc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        payment_id: payment_id,
        mv_sales_financials_id: mv_sales_financials_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? MOTHER VESSEL PURCHASE FINANCIAL EXPORT TAX PAYMENT POSTINGS
   *
   */

  add_mv_export_tax_payment_postings(
    loginUserID,
    idToken,
    mv_export_tax_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_export_tax_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_export_tax_id: mv_export_tax_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_export_tax_payment_postings(
    loginUserID,
    idToken,
    export_tax_payment_id,
    mv_export_tax_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_export_tax_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_export_tax_id: mv_export_tax_id,
        export_tax_payment_id: export_tax_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? MOTHER VESSEL DOCUMENTATION CHARGES PAYMENT POSTINGS API'S
   *
   */

  add_mv_doc_charges_payment_postings(
    loginUserID,
    idToken,
    mv_doc_charges_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_doc_charges_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_doc_charges_id: mv_doc_charges_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_doc_charges_payment_postings(
    loginUserID,
    idToken,
    mv_doc_charges_id,
    doc_charges_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_doc_charges_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_doc_charges_id: mv_doc_charges_id,
        doc_charges_payment_id: doc_charges_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * TODO ?? MOTHER VESSEL FREIGHT COSTING API'S
   *
   */

  add_mv_pur_fin_freight_costing(
    loginUserID,
    idToken,
    business_no_id,
    invoice_no,
    invoice_date,
    ppn,
    vessel_owner,
    quantity,
    price_pmt,
    total,
    vat_percent,
    vat_value,
    cost_to_company,
    billing_currency,
    currency_xchg_rate,
    remarks,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_freight_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        vessel_owner: vessel_owner,
        quantity: quantity,
        price_pmt: price_pmt,
        total: total,
        vat_percent: vat_percent,
        vat_value: vat_value,
        cost_to_company: cost_to_company,
        billing_currency: billing_currency,
        currency_xchg_rate: currency_xchg_rate,
        remarks: remarks ? remarks.toUpperCase() : "",
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_pur_fin_freight_costing(
    loginUserID,
    idToken,
    business_no_id,
    freight_costing_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    vessel_owner,
    quantity,
    price_pmt,
    total,
    vat_percent,
    vat_value,
    cost_to_company,
    billing_currency,
    currency_xchg_rate,
    remarks,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_mv_pur_fin_freight_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        freight_costing_id: freight_costing_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        vessel_owner: vessel_owner,
        quantity: quantity,
        price_pmt: price_pmt,
        total: total,
        vat_percent: vat_percent,
        vat_value: vat_value,
        cost_to_company: cost_to_company,
        billing_currency: billing_currency,
        currency_xchg_rate: currency_xchg_rate,
        remarks: remarks ? remarks.toUpperCase() : "",
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pur_fin_freight_costing(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mv_pur_fin_freight_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_mv_freight_costing_payment_postings(
    loginUserID,
    idToken,
    mv_freight_costing_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_mv_freight_costing_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_freight_costing_id: mv_freight_costing_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_freight_costing_payment_postings(
    loginUserID,
    idToken,
    mv_freight_costing_id,
    freight_costing_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_mv_freight_costing_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_freight_costing_id: mv_freight_costing_id,
        freight_costing_payment_id: freight_costing_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_freight_costing_payment_postings(
    loginUserID,
    idToken,
    mv_freight_costing_id
  ) {
    let url = config.apiDomain + "/get_mv_freight_costing_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_freight_costing_id: mv_freight_costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ??   BUSINESS NO DEBIT NOTE API'S
   *
   */

  add_business_no_debit_note(
    loginUserID,
    idToken,
    businessNoID,
    debit_type,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_business_no_debit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        debit_type: debit_type,
        business_no_debit_note: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_debit_note(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_business_no_debit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_buss_no_debit_note(loginUserID, idToken, debitNoteID) {
    let url = config.apiDomain + "/get_buss_no_debit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        debit_note_id: debitNoteID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_debit_note(
    loginUserID,
    idToken,
    businessNoID,
    debit_type,
    multiRows
  ) {
    let url = config.apiDomain + "/update_business_no_debit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        debit_type: debit_type,
        business_no_debit_note: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_buss_no_debit_note(finalData) {
    let url = config.apiDomain + "/update_buss_no_debit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings

  add_business_no_debit_note_payments(
    loginUserID,
    idToken,
    business_no_id,
    debit_note_id,
    debit_type,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_business_no_debit_note_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        debit_note_id: debit_note_id,
        debit_type: debit_type,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_debit_note_payments(
    loginUserID,
    idToken,
    business_no_id,
    debit_note_id,
    debit_note_payment_id,
    debit_type,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_business_no_debit_note_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        debit_note_id: debit_note_id,
        debit_note_payment_id: debit_note_payment_id,
        debit_type: debit_type,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? BUSINESS NO CREDIT NOTE API'S
   *
   */

  add_business_no_credit_note(
    loginUserID,
    idToken,
    businessNoID,
    credit_type,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_business_no_credit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        credit_type: credit_type,
        business_no_credit_note: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_credit_note(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_business_no_credit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_buss_no_credit_note(loginUserID, idToken, creditNoteID) {
    let url = config.apiDomain + "/get_buss_no_credit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        credit_note_id: creditNoteID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_credit_note(
    loginUserID,
    idToken,
    businessNoID,
    credit_type,
    multiRows
  ) {
    let url = config.apiDomain + "/update_business_no_credit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        credit_type: credit_type,
        business_no_credit_note: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_buss_no_credit_note(finalData) {
    let url = config.apiDomain + "/update_buss_no_credit_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_business_no_credit_note_payments(
    loginUserID,
    idToken,
    business_no_id,
    credit_note_id,
    credit_type,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_business_no_credit_note_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        credit_note_id: credit_note_id,
        credit_type: credit_type,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_credit_note_payments(
    loginUserID,
    idToken,
    business_no_id,
    credit_note_id,
    credit_note_payment_id,
    credit_type,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_business_no_credit_note_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        credit_note_id: credit_note_id,
        credit_note_payment_id: credit_note_payment_id,
        credit_type: credit_type,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? OTHER EXPENSES API'S
   *
   */

  add_business_no_other_expenses(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_business_no_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_other_expenses: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_other_expenses(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_business_no_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_buss_no_other_expenses(loginUserID, idToken, OtherExpenseID) {
    let url = config.apiDomain + "/get_buss_no_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_expense_id: OtherExpenseID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_other_expenses(
    loginUserID,
    idToken,
    businessNoID,
    multiRows
  ) {
    let url = config.apiDomain + "/update_business_no_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_other_expenses: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_buss_no_other_expenses(finalData) {
    let url = config.apiDomain + "/update_buss_no_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_business_no_other_expenses_payments(
    loginUserID,
    idToken,
    business_no_id,
    other_expense_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_business_no_other_expenses_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        other_expense_id: other_expense_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_other_expenses_payments(
    loginUserID,
    idToken,
    business_no_id,
    other_expense_id,
    other_expenses_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_business_no_other_expenses_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        other_expense_id: other_expense_id,
        other_expenses_payment_id: other_expenses_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? OTHER INCOME API'S
   *
   */

  add_business_no_other_income(
    loginUserID,
    idToken,
    businessNoID,
    multiRows,
    files
  ) {
    let url = config.apiDomain + "/add_business_no_other_income";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_other_income: multiRows,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_other_income(loginUserID, idToken, businessNoID) {
    let url = config.apiDomain + "/get_business_no_other_income";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_buss_no_other_income(loginUserID, idToken, OtherIncomeID) {
    let url = config.apiDomain + "/get_buss_no_other_income";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_income_id: OtherIncomeID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_other_income(
    loginUserID,
    idToken,
    businessNoID,
    multiRows
  ) {
    let url = config.apiDomain + "/update_business_no_other_income";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: businessNoID,
        business_no_other_income: multiRows,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_buss_no_other_income(finalData) {
    let url = config.apiDomain + "/update_buss_no_other_income";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(finalData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Payment Postings
  add_business_no_other_income_payments(
    loginUserID,
    idToken,
    business_no_id,
    other_income_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_business_no_other_income_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        other_income_id: other_income_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_business_no_other_income_payments(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_business_no_other_income_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_other_income_payments(
    loginUserID,
    idToken,
    business_no_id,
    other_income_id,
    other_income_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_business_no_other_income_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        other_income_id: other_income_id,
        other_income_payment_id: other_income_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  TODO ?? BARGE API'S
   *
   */

  get_barges_qty_by_purchase_contract(
    loginUserID,
    idToken,
    purchase_contract_id,
    pc_qualities_id
  ) {
    let url = config.apiDomain + "/get_barges_qty_by_purchase_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchase_contract_id,
        pc_qualities_id: pc_qualities_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_sales_contract_data_by_allocation_id(
    loginUserID,
    idToken,
    business_no_allocation_id
  ) {
    let url = config.apiDomain + "/get_sales_contract_data_by_allocation_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_allocation_id: business_no_allocation_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge(
    loginUserID,
    idToken,
    purchase_contract_id,
    pc_qualities_id,
    pc_purchase_type_id,
    procurement_quality_standard,
    bargeNomination,
    barge_captain_phone_no,
    bargeVendorName,
    bargeBasePricePerMT,
    barge_planned_quantity_in_mt,
    barging_type,
    lumpsum_price,
    lumpsum_price_currency,
    vat_incl_or_excl,
    vat_applicability,
    bargeQuantityInMT,
    return_cargo_quantity_in_mt,
    barging_base_price_currency,
    deadFreight,
    loadingJetty,
    loadingDate,
    supplier_barge_window,
    buyer_barge_window,
    completionTime,
    NORAnchorageDate,
    supplierDeadFreightIncurred,
    supplierDeadFreightValue,
    SDAMSAIIDeadFreightIncurred,
    SDAMSAIIDeadFreightValue,
    timeAtAnchorageDays,
    timeAtAnchorageHours,
    timeAtAnchorageMinutes,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    witnessSurveyorName,
    witnessSurveyorAnalysisType,
    chemicalSpray,
    price,
    redraftQuantity,
    redraftParty,
    quantityDifference,
    quantityDifferenceAccount,
    remarks,
    barging_agreement_price_list_id,
    files
  ) {
    let url = config.apiDomain + "/add_barge";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchase_contract_id,
        pc_qualities_id: pc_qualities_id,
        pc_purchase_type_id: pc_purchase_type_id,
        procurement_quality_standard: procurement_quality_standard,
        barge_nomination: bargeNomination
          ? bargeNomination.toUpperCase()
          : null,
        barge_captain_phone_no: barge_captain_phone_no,
        barge_vendor: bargeVendorName,
        barging_base_price: bargeBasePricePerMT,
        barge_planned_quantity_in_mt: barge_planned_quantity_in_mt,
        barging_base_price_currency: barging_base_price_currency,
        barging_type: barging_type,
        lumpsum_price: lumpsum_price,
        lumpsum_price_currency: lumpsum_price_currency,
        vat_incl_or_excl: vat_incl_or_excl,
        vat_applicability: vat_applicability,
        barge_quantity_in_mt: bargeQuantityInMT,
        return_cargo_quantity_in_mt: return_cargo_quantity_in_mt,
        dead_freight: deadFreight,
        loading_jetty: loadingJetty ? loadingJetty.toUpperCase() : null,
        supplier_barge_window: supplier_barge_window.toUpperCase(),
        buyer_barge_window: buyer_barge_window.toUpperCase(),
        loading_date: dateFormateToDB(loadingDate),
        completion_time: dateFormateToDB(completionTime),
        supplier_dead_freight_incurred: supplierDeadFreightIncurred,
        supplier_dead_freight_value: supplierDeadFreightValue,
        internal_dead_freight_incurred: SDAMSAIIDeadFreightIncurred,
        internal_dead_freight_value: SDAMSAIIDeadFreightValue,
        nor_anchorage: dateFormateToDB(NORAnchorageDate),
        time_at_anchorage_days: timeAtAnchorageDays,
        time_at_anchorage_hours: timeAtAnchorageHours,
        time_at_anchorage_minutes: timeAtAnchorageMinutes,
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: witnessSurveyorName,
        joint_surveyor_analysis_type: witnessSurveyorAnalysisType,
        chemical_spray: chemicalSpray,
        chemical_spray_price: price,
        redraft_quantity: redraftQuantity,
        redraft_party: redraftParty,
        quantity_difference: quantityDifference,
        quantity_difference_account: quantityDifferenceAccount,
        barging_agreement_price_list_id: barging_agreement_price_list_id,
        remarks: remarks ? remarks.toUpperCase() : null,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barging_agreement_by_vendor(request) {
    let url = config.apiDomain + "/get_barging_agreement_by_vendor";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge(
    loginUserID,
    idToken,
    barge_id,
    purchase_contract_id,
    pc_qualities_id,
    pc_purchase_type_id,
    procurement_quality_standard,
    bargeNomination,
    barge_captain_phone_no,
    bargeVendorName,
    bargeBasePricePerMT,
    barge_planned_quantity_in_mt,
    barging_type,
    lumpsum_price,
    lumpsum_price_currency,
    vat_incl_or_excl,
    vat_applicability,
    bargeQuantityInMT,
    return_cargo_quantity_in_mt,
    barging_base_price_currency,
    deadFreight,
    loadingJetty,
    loadingDate,
    barge_status,
    supplier_barge_window,
    buyer_barge_window,
    completionTime,
    NORAnchorageDate,
    commenceDischargeDate,
    completionDischargeDate,
    supplierDeadFreightIncurred,
    supplierDeadFreightValue,
    SDAMSAIIDeadFreightIncurred,
    SDAMSAIIDeadFreightValue,
    timeAtAnchorageDays,
    timeAtAnchorageHours,
    timeAtAnchorageMinutes,
    supplierSurveyorName,
    supplierSurveyorAnalysisType,
    saiiSurveyorName,
    saiiSurveyorAnalysisType,
    witnessSurveyorName,
    witnessSurveyorAnalysisType,
    chemicalSpray,
    price,
    redraftQuantity,
    redraftParty,
    quantityDifference,
    quantityDifferenceAccount,
    remarks,
    barging_agreement_price_list_id,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_barge";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        purchase_contract_id: purchase_contract_id,
        pc_qualities_id: pc_qualities_id,
        pc_purchase_type_id: pc_purchase_type_id,
        procurement_quality_standard: procurement_quality_standard,
        barge_nomination: bargeNomination
          ? bargeNomination.toUpperCase()
          : null,
        barge_captain_phone_no: barge_captain_phone_no,
        barge_vendor: bargeVendorName,
        barging_base_price: bargeBasePricePerMT,
        barging_base_price_currency: barging_base_price_currency,
        barging_type: barging_type,
        lumpsum_price: lumpsum_price,
        lumpsum_price_currency: lumpsum_price_currency,
        vat_incl_or_excl: vat_incl_or_excl,
        vat_applicability: vat_applicability,
        barge_quantity_in_mt: bargeQuantityInMT,
        return_cargo_quantity_in_mt: return_cargo_quantity_in_mt,
        barge_planned_quantity_in_mt: barge_planned_quantity_in_mt,
        dead_freight: deadFreight,
        loading_jetty: loadingJetty ? loadingJetty.toUpperCase() : null,
        //barge_status: barge_status,
        supplier_barge_window: supplier_barge_window.toUpperCase(),
        buyer_barge_window: buyer_barge_window.toUpperCase(),
        loading_date: dateFormateToDB(loadingDate),
        completion_time: dateFormateToDB(completionTime),
        supplier_dead_freight_incurred: supplierDeadFreightIncurred,
        supplier_dead_freight_value: supplierDeadFreightValue,
        internal_dead_freight_incurred: SDAMSAIIDeadFreightIncurred,
        internal_dead_freight_value: SDAMSAIIDeadFreightValue,
        nor_anchorage: dateFormateToDB(NORAnchorageDate),
        supplier_surveyor: supplierSurveyorName,
        supplier_surveyor_analysis_type: supplierSurveyorAnalysisType,
        internal_surveyor: saiiSurveyorName,
        internal_surveyor_analysis_type: saiiSurveyorAnalysisType,
        joint_surveyor: witnessSurveyorName,
        joint_surveyor_analysis_type: witnessSurveyorAnalysisType,
        chemical_spray: chemicalSpray,
        chemical_spray_price: price,
        redraft_quantity: redraftQuantity,
        redraft_party: redraftParty,
        quantity_difference: quantityDifference,
        quantity_difference_account: quantityDifferenceAccount,
        remarks: remarks ? remarks.toUpperCase() : null,
        barging_agreement_price_list_id: barging_agreement_price_list_id,
        existing_file_ids: existing_file_ids,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges(
    loginUserID,
    idToken,
    financial_type,
    business_no,
    barge_vendor,
    purchase_contract_id,
    barge_name,
    costing_type,
    jetty_name,
    sampling_surveyor,
    surveyor,
    documentation_agent
  ) {
    let url = config.apiDomain + "/get_barges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
        business_no_id: business_no,
        purchase_contract_id: purchase_contract_id,
        barge_vendor: barge_vendor,
        barge_nomination: barge_name,
        //pc_qualities_id: pc_qualities_id,
        //pc_purchase_type_id: pc_purchase_type_id,
        costing_type: costing_type,
        loading_jetty: jetty_name,
        sampling_surveyor: sampling_surveyor,
        surveyor: surveyor,
        documentation_agent: documentation_agent,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  cancel_barge(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/cancel_barge";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_doc(
    loginUserID,
    idToken,
    financial_type,
    business_no,
    documentation_agent
  ) {
    let url = config.apiDomain + "/get_barges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
        business_no_id: business_no,
        documentation_agent: documentation_agent,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_cpc(
    loginUserID,
    idToken,
    financial_type,
    business_no,
    purchase_contract_id,
    surveyor
  ) {
    let url = config.apiDomain + "/get_barges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
        business_no_id: business_no,
        purchase_contract_id: purchase_contract_id,
        surveyor: surveyor,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_mapping(
    loginUserID,
    idToken,
    financial_type,
    purchase_contract_id,
    pc_qualities_id,
    pc_purchase_type_id
  ) {
    let url = config.apiDomain + "/get_barges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
        purchase_contract_id: purchase_contract_id,
        pc_qualities_id: pc_qualities_id,
        pc_purchase_type_id: pc_purchase_type_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_barge";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *
   *      ======================= Return Cargo Charges =================
   *
   */
  get_barges_for_return_cargo(loginUserID, idToken, return_cargo_charges_id) {
    let url = config.apiDomain + "/get_barges_for_return_cargo";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        return_cargo_charges_id: return_cargo_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // return cargo list api
  get_list_of_return_cargo_charges(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_list_of_return_cargo_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // add return cargo api
  add_return_cargo_charges(
    loginUserID,
    idToken,
    barge_id,
    vendor_id,
    dc_price_type,
    dc_quantity_in_mt,
    dc_price_per_mt,
    dc_lumpsum_price,
    dc_base_price,
    dc_vat_percent,
    dc_vat_value,
    dc_pph23_percent,
    dc_pph23_value,
    dc_total,
    dc_ctc,
    billing_currency,
    dc_currency_xchg_rate,
    dc_ctc_in_local_currency,

    et_price_type,
    et_quantity_in_mt,
    et_price_per_mt,
    et_lumpsum_price,
    et_base_price,
    et_vat_percent,
    et_vat_value,
    et_pph23_percent,
    et_pph23_value,
    et_total,
    et_ctc,
    et_currency,
    et_currency_xchg_rate,
    et_ctc_in_local_currency,

    ls_price_type,
    ls_quantity_in_mt,
    ls_price_per_mt,
    ls_lumpsum_price,
    ls_base_price,
    ls_vat_percent,
    ls_vat_value,
    ls_pph23_percent,
    ls_pph23_value,
    ls_total,
    ls_ctc,
    ls_currency,
    ls_currency_xchg_rate,
    ls_ctc_in_local_currency,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_return_cargo_charges";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        vendor_id: vendor_id,

        dc_price_type: dc_price_type,
        dc_quantity_in_mt: dc_quantity_in_mt,
        dc_price_per_mt: dc_price_per_mt,
        dc_lumpsum_price: dc_lumpsum_price,
        dc_base_price: dc_base_price,
        dc_vat_percent: dc_vat_percent,
        dc_vat_value: dc_vat_value,
        dc_pph23_percent: dc_pph23_percent,
        dc_pph23_value: dc_pph23_value,
        dc_total: dc_total,
        dc_ctc: dc_ctc,
        billing_currency: billing_currency,
        dc_currency_xchg_rate: dc_currency_xchg_rate,
        dc_ctc_in_local_currency: dc_ctc_in_local_currency,

        et_price_type: et_price_type,
        et_quantity_in_mt: et_quantity_in_mt,
        et_price_per_mt: et_price_per_mt,
        et_lumpsum_price: et_lumpsum_price,
        et_base_price: et_base_price,
        et_vat_percent: et_vat_percent,
        et_vat_value: et_vat_value,
        et_pph23_percent: et_pph23_percent,
        et_pph23_value: et_pph23_value,
        et_total: et_total,
        et_ctc: et_ctc,
        et_currency: et_currency,
        et_currency_xchg_rate: et_currency_xchg_rate,
        et_ctc_in_local_currency: et_ctc_in_local_currency,

        ls_price_type: ls_price_type,
        ls_quantity_in_mt: ls_quantity_in_mt,
        ls_price_per_mt: ls_price_per_mt,
        ls_lumpsum_price: ls_lumpsum_price,
        ls_base_price: ls_base_price,
        ls_vat_percent: ls_vat_percent,
        ls_vat_value: ls_vat_value,
        ls_pph23_percent: ls_pph23_percent,
        ls_pph23_value: ls_pph23_value,
        ls_total: ls_total,
        ls_ctc: ls_ctc,
        ls_currency: ls_currency,
        ls_currency_xchg_rate: ls_currency_xchg_rate,
        ls_ctc_in_local_currency: ls_ctc_in_local_currency,

        invoice_no: invoice_no,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn,
        ppn_date: dateFormateToDB(ppn_date),
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Update return cargo api
  update_return_cargo_charges(
    loginUserID,
    idToken,
    barge_id,
    return_cargo_charges_id,
    vendor_id,
    dc_price_type,
    dc_quantity_in_mt,
    dc_price_per_mt,
    dc_lumpsum_price,
    dc_base_price,
    dc_vat_percent,
    dc_vat_value,
    dc_pph23_percent,
    dc_pph23_value,
    dc_total,
    dc_ctc,
    billing_currency,
    dc_currency_xchg_rate,
    dc_ctc_in_local_currency,

    et_price_type,
    et_quantity_in_mt,
    et_price_per_mt,
    et_lumpsum_price,
    et_base_price,
    et_vat_percent,
    et_vat_value,
    et_pph23_percent,
    et_pph23_value,
    et_total,
    et_ctc,
    et_currency,
    et_currency_xchg_rate,
    et_ctc_in_local_currency,
    ls_price_type,
    ls_quantity_in_mt,
    ls_price_per_mt,
    ls_lumpsum_price,
    ls_base_price,
    ls_vat_percent,
    ls_vat_value,
    ls_pph23_percent,
    ls_pph23_value,
    ls_total,
    ls_ctc,
    ls_currency,
    ls_currency_xchg_rate,
    ls_ctc_in_local_currency,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_return_cargo_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        return_cargo_charges_id: return_cargo_charges_id,
        vendor_id: vendor_id,

        dc_price_type: dc_price_type,
        dc_quantity_in_mt: dc_quantity_in_mt,
        dc_price_per_mt: dc_price_per_mt,
        dc_lumpsum_price: dc_lumpsum_price,
        dc_base_price: dc_base_price,
        dc_vat_percent: dc_vat_percent,
        dc_vat_value: dc_vat_value,
        dc_pph23_percent: dc_pph23_percent,
        dc_pph23_value: dc_pph23_value,
        dc_total: dc_total,
        dc_ctc: dc_ctc,
        billing_currency: billing_currency,
        dc_currency_xchg_rate: dc_currency_xchg_rate,
        dc_ctc_in_local_currency: dc_ctc_in_local_currency,

        et_price_type: et_price_type,
        et_quantity_in_mt: et_quantity_in_mt,
        et_price_per_mt: et_price_per_mt,
        et_lumpsum_price: et_lumpsum_price,
        et_base_price: et_base_price,
        et_vat_percent: et_vat_percent,
        et_vat_value: et_vat_value,
        et_pph23_percent: et_pph23_percent,
        et_pph23_value: et_pph23_value,
        et_total: et_total,
        et_ctc: et_ctc,
        et_currency: et_currency,
        et_currency_xchg_rate: et_currency_xchg_rate,
        et_ctc_in_local_currency: et_ctc_in_local_currency,

        ls_price_type: ls_price_type,
        ls_quantity_in_mt: ls_quantity_in_mt,
        ls_price_per_mt: ls_price_per_mt,
        ls_lumpsum_price: ls_lumpsum_price,
        ls_base_price: ls_base_price,
        ls_vat_percent: ls_vat_percent,
        ls_vat_value: ls_vat_value,
        ls_pph23_percent: ls_pph23_percent,
        ls_pph23_value: ls_pph23_value,
        ls_total: ls_total,
        ls_ctc: ls_ctc,
        ls_currency: ls_currency,
        ls_currency_xchg_rate: ls_currency_xchg_rate,
        ls_ctc_in_local_currency: ls_ctc_in_local_currency,

        invoice_no: invoice_no,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn,
        ppn_date: dateFormateToDB(ppn_date),
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_return_cargo_charges(loginUserID, idToken, return_cargo_charges_id) {
    let url = config.apiDomain + "/get_return_cargo_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        return_cargo_charges_id: return_cargo_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  RETURN CARGO CHARGES PAYMENT POSTINGS
   *
   */
  add_return_cargo_charges_payment(
    loginUserID,
    idToken,
    return_cargo_charges_id,
    amount,
    payment_posting_type,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_return_cargo_charges_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        return_cargo_charges_id: return_cargo_charges_id,
        amount: amount,
        payment_posting_type: payment_posting_type,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_return_cargo_charges_payment(
    loginUserID,
    idToken,
    return_cargo_charges_id,
    cargo_charges_payment_id,
    amount,
    payment_posting_type,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_return_cargo_charges_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        return_cargo_charges_id: return_cargo_charges_id,
        cargo_charges_payment_id: cargo_charges_payment_id,
        amount: amount,
        payment_posting_type: payment_posting_type,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_return_cargo_charges_payments(
    loginUserID,
    idToken,
    return_cargo_charges_id
  ) {
    let url = config.apiDomain + "/get_return_cargo_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        return_cargo_charges_id: return_cargo_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_purchase_contract_data(
    loginUserID,
    idToken,
    barge_id,
    purchase_contract_id,
    pc_qualities_id,
    pc_purchase_type_id,
    procurement_quality_standard
  ) {
    let url = config.apiDomain + "/update_purchase_contract_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        purchase_contract_id: purchase_contract_id,
        pc_qualities_id: pc_qualities_id,
        pc_purchase_type_id: pc_purchase_type_id,
        procurement_quality_standard: procurement_quality_standard,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_business_no_barge_mapping(
    loginUserID,
    idToken,
    business_no_id,
    mapping_data
  ) {
    let url = config.apiDomain + "/add_business_no_barge_mapping";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mapping_data: mapping_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_barge_mapping(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_business_no_barge_mapping";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mapped_barges_for_business_no_id(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mapped_barges_for_business_no_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_business_no_barge_mapping(
    loginUserID,
    idToken,
    business_no_id,
    mapping_data
  ) {
    let url = (config.apiDomain = "/update_business_no_barge_mapping");
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mapping_data: mapping_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Delete Barge Purchase fiancials group/individual
  delete_barge_pur_fin_coal_costing(loginUserID, idToken, barge_pur_fin_id) {
    let url = config.apiDomain + "/delete_barge_pur_fin_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: barge_pur_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Get Purchase contract based Barges by Business number ID
  get_barge_pur_fin_coal_costing(bargePurFinaID, loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_pur_fin_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: bargePurFinaID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_coal_costing_barges(
    loginUserID,
    idToken,
    purchase_contract_id,
    business_no,
    costing_type,
    barge_nomination
  ) {
    let url = config.apiDomain + "/get_barges_for_pur_financials_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchase_contract_id,
        business_no_id: business_no,
        costing_type: costing_type,
        barge_nomination: barge_nomination,
        financial_type: "barge_purchase_financial_coal_costing",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  Coal Costing End
   *
   */

  add_barge_chemical_spray(
    loginUserID,
    idToken,
    barge_id,
    chemical_spray_price,
    vendor_id,
    payee_name,
    billing_currency,
    currency_exchange_rate,
    local_currency,
    remarks,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_chemical_spray";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        chemical_spray_price: chemical_spray_price,
        vendor_id: vendor_id,
        payee_name: payee_name ? payee_name.toUpperCase() : null,
        billing_currency: billing_currency,
        currency_exchange_rate: currency_exchange_rate,
        local_currency: local_currency,
        remarks: remarks ? remarks.toUpperCase() : null,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_chemical_spray(
    loginUserID,
    idToken,
    business_no,
    barge_id,
    barge_name,
    jetty_name
  ) {
    let url = config.apiDomain + "/get_barges_for_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        barge_id: barge_id,
        barge_nomination: barge_name,
        loading_jetty: jetty_name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_chemical_spray(loginUserID, idToken, chemical_spray_id) {
    let url = config.apiDomain + "/get_barge_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_chemical_spray(
    loginUserID,
    idToken,
    barge_id,
    chemical_spray_id,
    chemical_spray_price,
    vendor_id,
    payee_name,
    billing_currency,
    currency_exchange_rate,
    local_currency,
    remarks,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_barge_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        chemical_spray_id: chemical_spray_id,
        chemical_spray_price: chemical_spray_price,
        vendor_id: vendor_id,
        payee_name: payee_name ? payee_name.toUpperCase() : null,
        billing_currency: billing_currency,
        currency_exchange_rate: currency_exchange_rate,
        local_currency: local_currency,
        remarks: remarks ? remarks.toUpperCase() : null,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_chemical_spray(loginUserID, idToken, chemical_spray_id) {
    let url = config.apiDomain + "/delete_barge_chemical_spray";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_chemical_spray_payments(
    loginUserID,
    idToken,
    chemical_spray_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_chemical_spray_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_chemical_spray_payments(
    loginUserID,
    idToken,
    chemical_spray_id,
    chemical_spray_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_chemical_spray_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        chemical_spray_id: chemical_spray_id,
        chemical_spray_payment_id: chemical_spray_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  EXPORT TAX
   *
   */
  get_barges_for_export_tax(
    loginUserID,
    idToken,
    business_no,
    barge_id,
    barge_name,
    jetty_name
  ) {
    let url = config.apiDomain + "/get_barges_for_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        barge_id: barge_id,
        barge_nomination: barge_name,
        loading_jetty: jetty_name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_purchase_contract_data_by_barge_id(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_purchase_contract_data_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_export_tax(
    loginUserID,
    idToken,
    barge_id,
    export_tax_applicable,
    quantity_for_export_tax,
    export_tax_per_mt,
    export_tax,
    billing_currency,
    export_currency_xchg_rate,
    export_tax_in_local_currency,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        export_tax_applicable: export_tax_applicable,
        quantity_for_export_tax: quantity_for_export_tax,
        export_tax_per_mt: export_tax_per_mt,
        export_tax: export_tax,
        billing_currency: billing_currency,
        export_currency_xchg_rate: export_currency_xchg_rate,
        export_tax_in_local_currency: export_tax_in_local_currency,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_export_tax(
    loginUserID,
    idToken,
    export_tax_id,
    export_tax_applicable,
    quantity_for_export_tax,
    export_tax_per_mt,
    export_tax,
    billing_currency,
    export_currency_xchg_rate,
    export_tax_in_local_currency,
    existing_file_ids,
    files,
    costing
  ) {
    let url = config.apiDomain + "/update_barge_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        export_tax_id: export_tax_id,
        export_tax_applicable: export_tax_applicable,
        quantity_for_export_tax: quantity_for_export_tax,
        export_tax_per_mt: export_tax_per_mt,
        export_tax: export_tax,
        billing_currency: billing_currency,
        export_currency_xchg_rate: export_currency_xchg_rate,
        export_tax_in_local_currency: export_tax_in_local_currency,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_export_tax(loginUserID, idToken, export_tax_id) {
    let url = config.apiDomain + "/get_barge_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        export_tax_id: export_tax_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_export_tax(loginUserID, idToken, export_tax_id) {
    let url = config.apiDomain + "/delete_barge_export_tax";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        export_tax_id: export_tax_id,
      }),
    });
  },

  // Payment postings
  add_barge_export_tax_payments(
    loginUserID,
    idToken,
    barge_export_tax_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_export_tax_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_export_tax_id: barge_export_tax_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_export_tax_payments(loginUserID, idToken, barge_export_tax_id) {
    let url = config.apiDomain + "/get_barge_export_tax_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_export_tax_id: barge_export_tax_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  Doumentation charges
   *
   */
  get_barges_for_doc_charges(
    loginUserID,
    idToken,
    business_no,
    documentation_agent
  ) {
    let url = config.apiDomain + "/get_barges_for_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        documentation_agent: documentation_agent,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_laparon_charges(
    loginUserID,
    idToken,
    business_no,
    dc_laprn_surv_by
  ) {
    let url = config.apiDomain + "/get_barges_for_laparon_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        dc_laprn_surv_by: dc_laprn_surv_by,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_doc_charges(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dc_applicable,
    dc_documentation_party,
    dc_documentation_by,
    dc_quantity_in_mt,
    dc_price_type,
    dc_price_per_mt,
    dc_base_price,
    dc_pph23_account,
    dc_vat_percent,
    dc_vat_value,
    dc_pph23_percent,
    dc_pph23_value,
    dc_total,
    dc_ctc,
    billing_currency,
    dc_currency_xchg_rate,
    dc_ctc_in_local_currency,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dc_applicable: dc_applicable,
        dc_documentation_party: dc_documentation_party,
        dc_documentation_by: dc_documentation_by,
        dc_quantity_in_mt: dc_quantity_in_mt,
        dc_price_type: dc_price_type,
        dc_price_per_mt: dc_price_per_mt,
        dc_base_price: dc_base_price,
        dc_pph23_account: dc_pph23_account,
        dc_vat_percent: dc_vat_percent,
        dc_vat_value: dc_vat_value,
        dc_pph23_percent: dc_pph23_percent,
        dc_pph23_value: dc_pph23_value,
        dc_total: dc_total,
        dc_ctc: dc_ctc,
        billing_currency: billing_currency,
        dc_currency_xchg_rate: dc_currency_xchg_rate,
        dc_ctc_in_local_currency: dc_ctc_in_local_currency,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_laparon_survey_charges(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dc_laprn_surv_party,
    dc_laprn_surv_by,
    dc_laprn_quantity_in_mt,
    dc_laprn_surv_price_type,
    dc_laprn_surv_price_per_mt,
    dc_laprn_pph23_account,
    dc_laprn_surv_base_price,
    dc_laprn_surv_vat_percent,
    dc_laprn_surv_vat_value,
    dc_laprn_surv_pph23_percent,
    dc_laprn_surv_pph23_value,
    dc_laprn_surv_total,
    dc_laprn_surv_ctc,
    billing_currency,
    dc_laprn_surv_currency_xchg_rate,
    dc_laprn_surv_ctc_in_local_currency,
    dc_cek_fisic,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dc_laprn_surv_party: dc_laprn_surv_party,
        dc_laprn_surv_by: dc_laprn_surv_by,
        dc_laprn_quantity_in_mt: dc_laprn_quantity_in_mt,
        dc_laprn_surv_price_type: dc_laprn_surv_price_type,
        dc_laprn_surv_price_per_mt: dc_laprn_surv_price_per_mt,
        dc_laprn_pph23_account: dc_laprn_pph23_account,
        dc_laprn_surv_base_price: dc_laprn_surv_base_price,
        dc_laprn_surv_vat_percent: dc_laprn_surv_vat_percent,
        dc_laprn_surv_vat_value: dc_laprn_surv_vat_value,
        dc_laprn_surv_pph23_percent: dc_laprn_surv_pph23_percent,
        dc_laprn_surv_pph23_value: dc_laprn_surv_pph23_value,
        dc_laprn_surv_total: dc_laprn_surv_total,
        dc_laprn_surv_ctc: dc_laprn_surv_ctc,
        billing_currency: billing_currency,
        dc_laprn_surv_currency_xchg_rate: dc_laprn_surv_currency_xchg_rate,
        dc_laprn_surv_ctc_in_local_currency:
          dc_laprn_surv_ctc_in_local_currency,
        dc_cek_fisic: dc_cek_fisic,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_doc_charges(
    loginUserID,
    idToken,
    doc_charges_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    dc_applicable,
    dc_documentation_party,
    dc_documentation_by,
    dc_quantity_in_mt,
    dc_price_per_mt,
    dc_base_price,
    dc_pph23_account,
    dc_vat_percent,
    dc_vat_value,
    dc_pph23_percent,
    dc_pph23_value,
    dc_total,
    dc_ctc,
    billing_currency,
    dc_currency_xchg_rate,
    dc_ctc_in_local_currency,
    existing_file_ids,
    files,
    costing
  ) {
    let url = config.apiDomain + "/update_barge_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        doc_charges_id: doc_charges_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        dc_applicable: dc_applicable,
        dc_documentation_party: dc_documentation_party,
        dc_documentation_by: dc_documentation_by,
        dc_quantity_in_mt: dc_quantity_in_mt,
        dc_price_per_mt: dc_price_per_mt,
        dc_base_price: dc_base_price,
        dc_pph23_account: dc_pph23_account,
        dc_vat_percent: dc_vat_percent,
        dc_vat_value: dc_vat_value,
        dc_pph23_percent: dc_pph23_percent,
        dc_pph23_value: dc_pph23_value,
        dc_total: dc_total,
        dc_ctc: dc_ctc,
        billing_currency: billing_currency,
        dc_currency_xchg_rate: dc_currency_xchg_rate,
        dc_ctc_in_local_currency: dc_ctc_in_local_currency,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_laparon_survey_charges(
    loginUserID,
    idToken,
    barge_id,
    laparon_charges_id,
    invoice_no,
    invoice_date,
    ppn,
    dc_laprn_surv_party,
    dc_laprn_surv_by,
    dc_laprn_quantity_in_mt,
    dc_laprn_surv_price_type,
    dc_laprn_surv_price_per_mt,
    dc_laprn_pph23_account,
    dc_laprn_surv_base_price,
    dc_laprn_surv_vat_percent,
    dc_laprn_surv_vat_value,
    dc_laprn_surv_pph23_percent,
    dc_laprn_surv_pph23_value,
    dc_laprn_surv_total,
    dc_laprn_surv_ctc,
    billing_currency,
    dc_laprn_surv_currency_xchg_rate,
    dc_laprn_surv_ctc_in_local_currency,
    dc_cek_fisic,
    files,
    existing_file_ids,
    costing_files
  ) {
    let url = config.apiDomain + "/update_barge_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        laparon_charges_id: laparon_charges_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dc_laprn_surv_party: dc_laprn_surv_party,
        dc_laprn_surv_by: dc_laprn_surv_by,
        dc_laprn_quantity_in_mt: dc_laprn_quantity_in_mt,
        dc_laprn_surv_price_type: dc_laprn_surv_price_type,
        dc_laprn_surv_price_per_mt: dc_laprn_surv_price_per_mt,
        dc_laprn_pph23_account: dc_laprn_pph23_account,
        dc_laprn_surv_base_price: dc_laprn_surv_base_price,
        dc_laprn_surv_vat_percent: dc_laprn_surv_vat_percent,
        dc_laprn_surv_vat_value: dc_laprn_surv_vat_value,
        dc_laprn_surv_pph23_percent: dc_laprn_surv_pph23_percent,
        dc_laprn_surv_pph23_value: dc_laprn_surv_pph23_value,
        dc_laprn_surv_total: dc_laprn_surv_total,
        dc_laprn_surv_ctc: dc_laprn_surv_ctc,
        billing_currency: billing_currency,
        dc_laprn_surv_currency_xchg_rate: dc_laprn_surv_currency_xchg_rate,
        dc_laprn_surv_ctc_in_local_currency:
          dc_laprn_surv_ctc_in_local_currency,
        dc_cek_fisic: dc_cek_fisic,
        files: files,
        existing_file_ids: existing_file_ids,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_doc_charges(loginUserID, idToken, doc_charges_id) {
    let url = config.apiDomain + "/get_barge_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        doc_charges_id: doc_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_laparon_survey_charges(loginUserID, idToken, laparon_charges_id) {
    let url = config.apiDomain + "/get_barge_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        laparon_charges_id: laparon_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_doc_charges(loginUserID, idToken, doc_charges_id) {
    let url = config.apiDomain + "/delete_barge_doc_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        doc_charges_id: doc_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_laparon_survey_charges(
    loginUserID,
    idToken,
    laparon_charges_id
  ) {
    let url = config.apiDomain + "/delete_barge_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        laparon_charges_id: laparon_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_barge_doc_charges_payments(
    loginUserID,
    idToken,
    barge_doc_charges_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_doc_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_doc_charges_id: barge_doc_charges_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_doc_charges_payments(loginUserID, idToken, barge_doc_charges_id) {
    let url = config.apiDomain + "/get_barge_doc_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_doc_charges_id: barge_doc_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_laparon_survey_charges_payments(
    loginUserID,
    idToken,
    barge_doc_charges_id
  ) {
    let url = config.apiDomain + "/get_barge_laparon_survey_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_laparon_survey_charges_id: barge_doc_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_doc_charges_payments(
    loginUserID,
    idToken,
    barge_doc_charges_id,
    doc_charges_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_doc_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_doc_charges_id: barge_doc_charges_id,
        doc_charges_payment_id: doc_charges_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  Sampling & Analaysis
   *
   */

  get_barges_for_sac_charges(
    loginUserID,
    idToken,
    business_no,
    sampling_surveyor,
    barge_name,
    jetty_name
  ) {
    let url = config.apiDomain + "/get_barges_for_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        sampling_surveyor: sampling_surveyor,
        barge_nomination: barge_name,
        loading_jetty: jetty_name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_sac_charges(
    loginUserID,
    idToken,
    barge_id,
    sac_charges_data,
    files
  ) {
    let url = config.apiDomain + "/add_barge_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        sac_charges_data: sac_charges_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_sac_charges(
    loginUserID,
    idToken,
    barge_id,
    sac_charges_data,
    existing_file_ids,
    content_files
  ) {
    let url = config.apiDomain + "/update_barge_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        sac_charges_data: sac_charges_data,
        existing_file_ids: existing_file_ids,
        files: content_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_sac_charges(loginUserID, idToken, barge_sac_charges_id) {
    let url = config.apiDomain + "/get_barge_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sac_charges_id: barge_sac_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_sac_charges(loginUserID, idToken, barge_sac_charges_id) {
    let url = config.apiDomain + "/delete_barge_sac_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sac_charges_id: barge_sac_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_sac_charges_payments(
    loginUserID,
    idToken,
    barge_id,
    barge_sac_charges_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        barge_sac_charges_id: barge_sac_charges_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_sac_charges_payments(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_barge_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_sac_charges_payments(
    loginUserID,
    idToken,
    barge_id,
    barge_sac_charges_id,
    sac_charges_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sac_charges_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        barge_sac_charges_id: barge_sac_charges_id,
        payment_posting_type: payment_posting_type,
        sac_charges_payment_id: sac_charges_payment_id,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * -===================================================== Sampling & Analaysis Charges END ===========================================
   *
   */

  /**
   *
   *  Coal Procurment Charges
   *
   */

  add_barge_pur_fin_cpc(loginUserID, idToken, barge_id, cpc_data, files) {
    let url = config.apiDomain + "/add_barge_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        cpc_data: cpc_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_cpc(
    loginUserID,
    idToken,
    barge_id,
    cpc_data,
    existing_file_ids,
    content_files
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        cpc_data: cpc_data,
        existing_file_ids: existing_file_ids,
        files: content_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_pur_fin_cpc(
    loginUserID,
    idToken,
    business_no,
    purchase_contract_id,
    coal_vendor
  ) {
    let url = config.apiDomain + "/get_barges_for_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        purchase_contract_id: purchase_contract_id,
        vendor_id: coal_vendor,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_cpc(loginUserID, idToken, costing_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        costing_id: costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pur_fin_cpc(loginUserID, idToken, costing_id) {
    let url = config.apiDomain + "/delete_barge_pur_fin_cpc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        costing_id: costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_pur_fin_cpc_payment(
    loginUserID,
    idToken,
    barge_pur_fin_cpc_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_cpc_id: barge_pur_fin_cpc_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_cpc_payments(loginUserID, idToken, barge_pur_fin_cpc_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_cpc_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_cpc_id: barge_pur_fin_cpc_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_cpc_payment(loginUserID, idToken, cpc_payment_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cpc_payment_id: cpc_payment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_cpc_payment(
    loginUserID,
    idToken,
    barge_pur_fin_cpc_id,
    cpc_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_cpc_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_cpc_id: barge_pur_fin_cpc_id,
        cpc_payment_id: cpc_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  /**
   *
   *  ======================================== COAL PROCUREMENT CHARGES END =======================
   *
   */

  /**
   *
   *  Pre shipment inspection
   *
   */

  add_barge_pur_fin_pre_shipment(
    loginUserID,
    idToken,
    shipment_type,
    barge_id,
    barge_pre_shipment_data,
    files
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_pre_shipment";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        shipment_type: shipment_type,
        barge_id: barge_id,
        barge_pre_shipment_data: barge_pre_shipment_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_pre_shipment(
    loginUserID,
    idToken,
    shipment_type,
    barge_id,
    barge_pre_shipment_data,
    existing_file_ids,
    content_files
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        shipment_type: shipment_type,
        barge_id: barge_id,
        barge_pre_shipment_data: barge_pre_shipment_data,
        existing_file_ids: existing_file_ids,
        files: content_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_pre_shipment(
    loginUserID,
    idToken,
    barge_id,
    pre_shipment_id
  ) {
    let url = config.apiDomain + "/get_barge_pur_fin_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        pre_shipment_id: pre_shipment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barges_for_barge_pur_fin_pre_shipments(
    loginUserID,
    idToken,
    business_no,
    shipment_surveyor,
    barge_name,
    jetty_name
  ) {
    let url = config.apiDomain + "/get_barges_for_barge_pur_fin_pre_shipments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        surveyor: shipment_surveyor,
        barge_nomination: barge_name,
        loading_jetty: jetty_name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_pur_fin_pre_shipment(
    loginUserID,
    idToken,
    barge_pre_shipment_id
  ) {
    let url = config.apiDomain + "/delete_barge_pur_fin_pre_shipment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_barge_pur_fin_pre_shipment_payment(
    loginUserID,
    idToken,
    barge_pur_fin_pre_shipment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_pre_shipment_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_pre_shipment_id: barge_pur_fin_pre_shipment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_pre_shipment_payment(
    loginUserID,
    idToken,
    barge_pre_shipment_id,
    pre_shipment_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_pre_shipment_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pre_shipment_id: barge_pre_shipment_id,
        pre_shipment_payment_id: pre_shipment_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  ======================================== Pre shipment inspection End =======================
   *
   */

  /**
   *
   *  Barge Costing Start
   *
   */

  get_recent_uncosted_barging_barges(loginUserID, idToken) {
    let url = config.apiDomain + "/get_recent_uncosted_barging_barges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_pur_fin_barging(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    barge_quantity,
    pph15_account,
    barge_base_price,
    barge_vat_percent,
    barge_vat_value,
    barge_pph15_percent,
    barge_pph15_value,
    barge_vendor_total_value,
    billing_currency,
    currency_xchg_rate,
    barge_base_price_in_local_currency,
    barge_ctc,
    sdf_base_price,
    sdf_vat_percent,
    sdf_vat_value,
    sdf_vendor_total_value,
    sdf_pph15_percent,
    sdf_ctc,
    idf_base_price,
    idf_vat_percent,
    idf_vat_value,
    idf_pph15_percent,
    idf_pph15_value,
    idf_vendor_total_value,
    idf_ctc,
    advance_adjustments,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_barging";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        barge_quantity: barge_quantity,
        pph15_account: pph15_account,
        barge_base_price: barge_base_price,
        barge_vat_percent: barge_vat_percent,
        barge_vat_value: barge_vat_value,
        barge_pph15_percent: barge_pph15_percent,
        barge_pph15_value: barge_pph15_value,
        barge_vendor_total_value: barge_vendor_total_value,
        billing_currency: billing_currency,
        currency_xchg_rate: currency_xchg_rate,
        barge_base_price_in_local_currency: barge_base_price_in_local_currency,
        barge_ctc: barge_ctc,
        sdf_base_price: sdf_base_price,
        sdf_vat_percent: sdf_vat_percent,
        sdf_vat_value: sdf_vat_value,
        sdf_vendor_total_value: sdf_vendor_total_value,
        sdf_ctc: sdf_ctc,
        idf_base_price: idf_base_price,
        idf_vat_percent: idf_vat_percent,
        idf_vat_value: idf_vat_value,
        idf_pph15_percent: idf_pph15_percent,
        idf_pph15_value: idf_pph15_value,
        idf_vendor_total_value: idf_vendor_total_value,
        idf_ctc: idf_ctc,
        advance_adjustments: advance_adjustments,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_pur_fin_barging(
    loginUserID,
    idToken,
    business_no,
    barge_vendor,
    barge_name,
    jetty_name,
    barge_ids
  ) {
    let url = config.apiDomain + "/get_barges_for_pur_fin_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
        barge_vendor: barge_vendor,
        barge_nomination: barge_name,
        loading_jetty: jetty_name,
        barge_id: barge_ids,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_barging(loginUserID, idToken, barge_pur_fin_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: barge_pur_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_barging(
    loginUserID,
    idToken,
    barge_id,
    barge_pur_fin_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    barge_quantity,
    pph15_account,
    barge_base_price,
    barge_vat_percent,
    barge_vat_value,
    barge_pph15_percent,
    barge_pph15_value,
    barge_vendor_total_value,
    billing_currency,
    currency_xchg_rate,
    barge_base_price_in_local_currency,
    barge_ctc,
    sdf_base_price,
    sdf_vat_percent,
    sdf_vat_value,
    sdf_vendor_total_value,
    sdf_pph15_percent,
    sdf_ctc,
    idf_base_price,
    idf_vat_percent,
    idf_vat_value,
    idf_pph15_percent,
    idf_pph15_value,
    idf_vendor_total_value,
    idf_ctc,
    advance_adjustments,
    existing_file_ids,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        barge_pur_fin_id: barge_pur_fin_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        barge_quantity: barge_quantity,
        pph15_account: pph15_account,
        barge_base_price: barge_base_price,
        barge_vat_percent: barge_vat_percent,
        barge_vat_value: barge_vat_value,
        barge_pph15_percent: barge_pph15_percent,
        barge_pph15_value: barge_pph15_value,
        barge_vendor_total_value: barge_vendor_total_value,
        billing_currency: billing_currency,
        currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
        barge_base_price_in_local_currency: barge_base_price_in_local_currency,
        barge_ctc: barge_ctc,
        sdf_base_price: sdf_base_price,
        sdf_vat_percent: sdf_vat_percent,
        sdf_vat_value: sdf_vat_value,
        sdf_vendor_total_value: sdf_vendor_total_value,
        sdf_ctc: sdf_ctc,
        idf_base_price: idf_base_price,
        idf_vat_percent: idf_vat_percent,
        idf_vat_value: idf_vat_value,
        idf_pph15_percent: idf_pph15_percent,
        idf_pph15_value: idf_pph15_value,
        idf_vendor_total_value: idf_vendor_total_value,
        idf_ctc: idf_ctc,
        advance_adjustments: advance_adjustments,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_pur_fin_barging(loginUserID, idToken, barge_pur_fin_id) {
    let url = config.apiDomain + "/delete_barge_pur_fin_barging";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_id: barge_pur_fin_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_barge_purchase_financial_barge_payment(
    loginUserID,
    idToken,
    barge_pur_fin_barge_costing_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_purchase_financial_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_barge_costing_id: barge_pur_fin_barge_costing_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_purchase_financial_barge_payments(
    loginUserID,
    idToken,
    barge_pur_fin_barge_costing_id
  ) {
    let url = config.apiDomain + "/get_barge_purchase_financial_barge_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_barge_costing_id: barge_pur_fin_barge_costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_purchase_financial_barge_payment(
    loginUserID,
    idToken,
    barge_payment_id
  ) {
    let url = config.apiDomain + "/get_barge_purchase_financial_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_payment_id: barge_payment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_purchase_financial_barge_payment(
    loginUserID,
    idToken,
    barge_pur_fin_barge_costing_id,
    barge_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_purchase_financial_barge_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_pur_fin_barge_costing_id: barge_pur_fin_barge_costing_id,
        barge_payment_id: barge_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  ======================================== Barge Costing End =======================
   *
   */

  /**
   *
   *  PURCHASE QUALITY RESULTS
   *
   */

  get_pcq_typical_specs(loginUserID, idToken, barge_id) {
    var url = config.apiDomain + "/get_pcq_typical_specs";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_pur_fin_quality_results(
    loginUserID,
    idToken,
    barge_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status,
    files
  ) {
    var url = config.apiDomain + "/add_barge_pur_fin_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        quality_calorific_value_actual: quality_calorific_value_actual
          ? quality_calorific_value_actual.toUpperCase()
          : null,
        quality_calorific_value2_actual: quality_calorific_value2_actual
          ? quality_calorific_value2_actual.toUpperCase()
          : null,
        quality_calorific_value3_actual: quality_calorific_value3_actual
          ? quality_calorific_value3_actual.toUpperCase()
          : null,
        quality_total_moisture_actual: quality_total_moisture_actual
          ? quality_total_moisture_actual.toUpperCase()
          : null,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual
          ? quality_inherent_moisture_actual.toUpperCase()
          : null,
        quality_ash_actual: quality_ash_actual
          ? quality_ash_actual.toUpperCase()
          : null,
        quality_volatile_matter_actual: quality_volatile_matter_actual
          ? quality_volatile_matter_actual.toUpperCase()
          : null,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual
          ? quality_fixed_carbon_actual.toUpperCase()
          : null,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual
          ? quality_fuel_ratio_actual.toUpperCase()
          : null,
        quality_total_sulphur_actual: quality_total_sulphur_actual
          ? quality_total_sulphur_actual.toUpperCase()
          : null,
        quality_hgi_actual: quality_hgi_actual
          ? quality_hgi_actual.toUpperCase()
          : null,
        status: status,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_pur_fin_quality_results(
    loginUserID,
    idToken,
    barge_id,
    barge_pur_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status,
    existing_file_ids,
    new_files
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        barge_pur_quality_id: barge_pur_quality_id,
        quality_calorific_value_actual: quality_calorific_value_actual
          ? quality_calorific_value_actual.toUpperCase()
          : null,
        quality_calorific_value2_actual: quality_calorific_value2_actual
          ? quality_calorific_value2_actual.toUpperCase()
          : null,
        quality_calorific_value3_actual: quality_calorific_value3_actual
          ? quality_calorific_value3_actual.toUpperCase()
          : null,
        quality_total_moisture_actual: quality_total_moisture_actual
          ? quality_total_moisture_actual.toUpperCase()
          : null,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual
          ? quality_inherent_moisture_actual.toUpperCase()
          : null,
        quality_ash_actual: quality_ash_actual
          ? quality_ash_actual.toUpperCase()
          : null,
        quality_volatile_matter_actual: quality_volatile_matter_actual
          ? quality_volatile_matter_actual.toUpperCase()
          : null,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual
          ? quality_fixed_carbon_actual.toUpperCase()
          : null,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual
          ? quality_fuel_ratio_actual.toUpperCase()
          : null,
        quality_total_sulphur_actual: quality_total_sulphur_actual
          ? quality_total_sulphur_actual.toUpperCase()
          : null,
        quality_hgi_actual: quality_hgi_actual
          ? quality_hgi_actual.toUpperCase()
          : null,
        status: status,
        existing_file_ids: existing_file_ids,
        files: new_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_quality_results(loginUserID, idToken) {
    var url = config.apiDomain + "/get_barge_pur_fin_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_pur_fin_quality_result(
    loginUserID,
    idToken,
    barge_purchase_quality_id
  ) {
    var url = config.apiDomain + "/get_barge_pur_fin_quality_result";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_purchase_quality_id: barge_purchase_quality_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_quality_results_by_barge_id(loginUserID, idToken, barge_id) {
    var url = config.apiDomain + "/get_quality_results_by_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  GENERIC EXPENSE API'S =============== START =================
   *
   */

  add_generic_expense(
    loginUserID,
    idToken,
    expense_type,
    description,
    base_price,
    vat,
    pph,
    expense_date,
    payment_date,
    remarks
  ) {
    let url = config.apiDomain + "/add_generic_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        expense_type: expense_type ? expense_type.toUpperCase() : null,
        description: description ? expense_type.toUpperCase() : null,
        base_price: base_price,
        vat: vat,
        pph: pph,
        expense_date: dateFormateToDB(expense_date),
        payment_date: dateFormateToDB(payment_date),
        remarks: remarks ? remarks.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_generic_expenses(loginUserID, idToken) {
    let url = config.apiDomain + "/get_generic_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_generic_expense(loginUserID, idToken, generic_expense_id) {
    let url = config.apiDomain + "/get_generic_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        generic_expense_id: generic_expense_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_generic_expense(
    loginUserID,
    idToken,
    generic_expense_id,
    expense_type,
    description,
    base_price,
    vat,
    pph,
    expense_date,
    payment_date,
    remarks
  ) {
    let url = config.apiDomain + "/update_generic_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        generic_expense_id: generic_expense_id,
        expense_type: expense_type ? expense_type.toUpperCase() : null,
        description: description ? expense_type.toUpperCase() : null,
        base_price: base_price,
        vat: vat,
        pph: pph,
        expense_date: dateFormateToDB(expense_date),
        payment_date: dateFormateToDB(payment_date),
        remarks: remarks ? remarks.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_generic_expense(loginUserID, idToken, generic_expense_id) {
    let url = config.apiDomain + "/delete_generic_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        generic_expense_id: generic_expense_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  /**
   *
   *  GENERIC EXPENSE API'S =============== END =================
   *
   */

  /**
   *
   *  INDIRECT EXPENDITURE API'S ========================= START ===============
   *
   */
  add_indirect_expenditure_account(
    loginUserID,
    idToken,
    account_name,
    expenditure_group
  ) {
    let url = config.apiDomain + "/add_indirect_expenditure_account";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        account_name: account_name ? account_name.toUpperCase() : null,
        expenditure_group: expenditure_group,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_accounts(loginUserID, idToken) {
    let url = config.apiDomain + "/get_indirect_expenditure_accounts";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_groups(loginUserID, idToken) {
    let url = config.apiDomain + "/get_indirect_expenditure_groups";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_indirect_expenditure_expense_types(loginUserID, idToken) {
    let url = config.apiDomain + "/get_indirect_expenditure_expense_types";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_indirect_expenditure_expense(
    loginUserID,
    idToken,
    selected_account_id,
    description,
    cost_center
  ) {
    let url = config.apiDomain + "/add_indirect_expenditure_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        account_id: selected_account_id,
        description: description ? description.toUpperCase() : null,
        cost_center: cost_center,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_expense(loginUserID, idToken, expense_id) {
    let url = config.apiDomain + "/get_indirect_expenditure_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        expense_id: expense_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_indirect_expenditure_expense(
    loginUserID,
    idToken,
    expense_id,
    selected_account_id,
    expenditure_group,
    expense_type,
    is_receivable,
    description,
    invoice_date,
    invoice_no,
    ppn,
    base_price,
    vat,
    pph,
    expense_date,
    payment_date,
    remarks
  ) {
    let url = config.apiDomain + "/update_indirect_expenditure_expense";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        expense_id: expense_id,
        account_id: selected_account_id,
        expenditure_group: expenditure_group,
        expense_type: expense_type,
        is_receivable: is_receivable,
        description: description ? description.toUpperCase() : null,
        base_price: base_price,
        invoice_date: dateFormateToDB(invoice_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        ppn: ppn ? ppn.toUpperCase() : null,
        vat: vat,
        pph: pph,
        expense_date: dateFormateToDB(expense_date),
        payment_date: dateFormateToDB(payment_date),
        remarks: remarks ? remarks.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_indirect_expenditure_cost_center_expenses(data) {
    let url =
      config.apiDomain + "/update_indirect_expenditure_cost_center_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_amount(loginUserID, idToken, selected_account_id) {
    let url = config.apiDomain + "/get_indirect_expenditure_amount";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        account_id: selected_account_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Receipts

  add_indirect_expenditure_receipt(
    loginUserID,
    idToken,
    selected_account_id,
    amount,
    date_of_receipt,
    receipt_remarks
  ) {
    let url = config.apiDomain + "/add_indirect_expenditure_receipt";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        account_id: selected_account_id,
        amount: amount,
        date_of_receipt: dateFormateToDB(date_of_receipt),
        remarks: receipt_remarks ? receipt_remarks.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_receipt(loginUserID, idToken, receipt_id) {
    let url = config.apiDomain + "/get_indirect_expenditure_receipt";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        receipt_id: receipt_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_indirect_expenditure_receipt(
    loginUserID,
    idToken,
    receipt_id,
    selected_account_id,
    amount,
    date_of_receipt,
    receipt_remarks
  ) {
    let url = config.apiDomain + "/update_indirect_expenditure_receipt";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        receipt_id: receipt_id,
        account_id: selected_account_id,
        amount: amount,
        date_of_receipt: dateFormateToDB(date_of_receipt),
        remarks: receipt_remarks ? receipt_remarks.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Expenditure History
  get_history_for_indirect_expenditure(
    loginUserID,
    idToken,
    selected_account_id,
    cost_center_id,
    report_type,
    expenditure_group,
    start_date,
    end_date
  ) {
    let url = config.apiDomain + "/get_history_for_indirect_expenditure";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        report_level: report_type,
        account_id: selected_account_id,
        cost_center_id: cost_center_id,
        expenditure_group: expenditure_group,
        start_date: start_date ? start_date : null,
        end_date: end_date ? end_date : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_data(loginUserID, idToken, id, transaction_type) {
    let url = config.apiDomain + "/get_indirect_expenditure_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        id: id,
        transaction_type: transaction_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_cost_centers(loginUserID, idToken) {
    let url = config.apiDomain + "/get_indirect_expenditure_cost_centers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_indirect_expenditure_cost_center(loginUserID, idToken, cost_center) {
    let url = config.apiDomain + "/add_indirect_expenditure_cost_center";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cost_center: cost_center ? cost_center.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_indirect_expenditure_for_account_id(
    loginUserID,
    idToken,
    account_id,
    start_date,
    end_date,
    approval_status
  ) {
    let url = config.apiDomain + "/get_indirect_expenditure_for_account_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        account_id: account_id,
        start_date: start_date,
        end_date: end_date,
        approval_status: approval_status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  approve_cost_center_expenses(loginUserID, idToken, cost_center_expense_id) {
    let url = config.apiDomain + "/approve_cost_center_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cost_center_expense_id: cost_center_expense_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  INDIRECT EXPENDITURE API'S ========================= END ===============
   *
   */

  /**
   *
   *   BUSINESS NUMBER PROFIT OR LOSS SUMMERY API'S
   *
   */
  get_business_no_profit_loss_summary(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_business_no_profit_loss_summary";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * BUSINESS NUMBER PROFIT OR LOSS AVG EXCHANGE RATE
   *
   */
  get_profit_loss_avg_exchange_list(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_profit_loss_avg_exchange_list";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_profit_loss_avg_exchange(
    loginUserID,
    idToken,
    business_no_id,
    customer_id,
    amount_in_usd,
    transfer_date,
    exchange_rate,
    amount_in_idr
  ) {
    let url = config.apiDomain + "/add_profit_loss_avg_exchange";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        customer_id: customer_id,
        amount_in_usd: amount_in_usd,
        transfer_date: dateFormateToDB(transfer_date),
        exchange_rate: exchange_rate,
        amount_in_idr: amount_in_idr,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_profit_loss_avg_exchange(
    loginUserID,
    idToken,
    business_no_id,
    exchange_id,
    customer_id,
    amount_in_usd,
    transfer_date,
    exchange_rate,
    amount_in_idr,
    status
  ) {
    let url = config.apiDomain + "/update_profit_loss_avg_exchange";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        exchange_id: exchange_id,
        customer_id: customer_id,
        amount_in_usd: amount_in_usd,
        transfer_date: dateFormateToDB(transfer_date),
        exchange_rate: exchange_rate,
        amount_in_idr: amount_in_idr,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Exchange Conversion
  add_profit_loss_avg_exchange_conversion(
    loginUserID,
    idToken,
    transfer_id,
    amount_in_usd,
    conversion_date,
    exchange_rate,
    amount_in_idr
  ) {
    let url = config.apiDomain + "/add_profit_loss_avg_exchange_conversion";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        transfer_id: transfer_id,
        amount_in_usd: amount_in_usd,
        conversion_date: dateFormateToDB(conversion_date),
        exchange_rate: exchange_rate,
        amount_in_idr: amount_in_idr,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_profit_loss_avg_exchange_conversion(
    loginUserID,
    idToken,
    exchange_conversion_id,
    transfer_id,
    amount_in_usd,
    conversion_date,
    exchange_rate,
    amount_in_idr,
    status
  ) {
    let url = config.apiDomain + "/update_profit_loss_avg_exchange_conversion";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        exchange_conversion_id: exchange_conversion_id,
        transfer_id: transfer_id,
        amount_in_usd: amount_in_usd,
        conversion_date: dateFormateToDB(conversion_date),
        exchange_rate: exchange_rate,
        amount_in_idr: amount_in_idr,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Sales Exchange Conversion
  add_profit_loss_sales_exchange_rate(
    loginUserID,
    idToken,
    business_no_id,
    sales_exchange_rate_data
  ) {
    let url = config.apiDomain + "/add_profit_loss_sales_exchange_rate";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        sales_exchange_rate_data: sales_exchange_rate_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE SALES FINANCIALS
   *
   */
  get_barges_list_data(
    loginUserID,
    idToken,
    financial_type,
    costing_type,
    sales_fin_coal_costing_id
  ) {
    let url = config.apiDomain + "/get_barges_list_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
        costing_type: costing_type,
        sales_fin_coal_costing_id: sales_fin_coal_costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_mv_sales_performa_coal_costing(
    loginUserID,
    idToken,
    business_no_id,
    sale_contract_id,
    sc_qualities_id,
    sc_sales_type_id,
    invoice_no,
    invoice_date,
    ppn,
    coal_cost_coal_quantity,
    coal_cost_billing_currency,
    coal_cost_coal_price_pmt,
    coal_cost_coal_price,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    vat_applicable,
    vat_amount,
    vat_percent,
    vat_value,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    receivable_from_supplier,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_mv_sales_performa_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        sale_contract_id: sale_contract_id,
        sc_qualities_id: sc_qualities_id,
        sc_sales_type_id: sc_sales_type_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        billing_currency: coal_cost_billing_currency,
        coal_price_pmt: coal_cost_coal_price_pmt,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        vat_applicable: vat_applicable,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        receivable_from_supplier: receivable_from_supplier,
        mv_performa_files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get barge sales coal costing list
  get_mv_sales_performa_coal_costings(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mv_sales_performa_coal_costings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_sales_contracts_by_business_no(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_sales_contracts_by_business_no";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // get barge sales coal costing
  get_mv_sales_performa_coal_costing(
    loginUserID,
    idToken,
    mv_sales_costing_id
  ) {
    let url = config.apiDomain + "/get_mv_sales_performa_coal_costing";
    return fetch(url, {
      method: "post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_sales_costing_id: mv_sales_costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_mv_sales_performa_coal_costing(
    loginUserID,
    idToken,
    business_no_id,
    mv_sales_costing_id,
    sale_contract_id,
    sc_qualities_id,
    sc_sales_type_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    coal_cost_coal_quantity,
    coal_cost_billing_currency,
    coal_cost_coal_price_pmt,
    coal_cost_coal_price,
    coal_cost_adjusted_coal_price_pmt,
    coal_cost_adjusted_coal_price,
    coal_cost_currency_xchg_rate,
    coal_cost_adjusted_coal_price_in_local_currency,
    vat_applicable,
    vat_amount,
    vat_percent,
    vat_value,
    coal_cost_pph22_barge_quantity_in_mt,
    coal_cost_pph22_coal_price_per_mt,
    coal_cost_pph22_percent,
    coal_cost_pph22_percent_val,
    coal_cost_pph22_export,
    coal_cost_pph22_export_amount,
    coal_cost_pph22_export_percent,
    coal_cost_pph22_export_value,
    advance_adjustments,
    receivable_from_supplier,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_mv_sales_performa_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_sales_costing_id: mv_sales_costing_id,
        sale_contract_id: sale_contract_id,
        sc_qualities_id: sc_qualities_id,
        sc_sales_type_id: sc_sales_type_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        barge_quantity: coal_cost_coal_quantity,
        coal_price: coal_cost_coal_price,
        billing_currency: coal_cost_billing_currency,
        coal_price_pmt: coal_cost_coal_price_pmt,
        adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        adjusted_coal_price: coal_cost_adjusted_coal_price,
        currency_xchg_rate: coal_cost_currency_xchg_rate,
        adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        vat_applicable: vat_applicable,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,
        pph22_barge_quantity: coal_cost_pph22_barge_quantity_in_mt,
        pph22_coal_price_pmt: coal_cost_pph22_coal_price_per_mt,
        pph22_percent: coal_cost_pph22_percent,
        pph22_value: coal_cost_pph22_percent_val,
        pph22_export_applicable: coal_cost_pph22_export,
        pph22_export_amount: coal_cost_pph22_export_amount,
        pph22_export_percent: coal_cost_pph22_export_percent,
        pph22_export_value: coal_cost_pph22_export_value,
        advance_adjustments: advance_adjustments,
        receivable_from_supplier: receivable_from_supplier,
        mv_performa_existing_file_ids: existing_file_ids,
        mv_performa_files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_mv_sales_performa_coal_costing(data) {
    let url = config.apiDomain + "/update_mv_sales_performa_coal_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_sales_fin_list_data(loginUserID, idToken, financial_type) {
    let url = config.apiDomain + "/get_barge_sales_fin_list_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE SALES FINANCIAL COAL SALES CHARGES
   *
   */
  get_barges_list_for_sales_fin_csc(
    loginUserID,
    idToken,
    barge_sales_fin_csc_id
  ) {
    let url = config.apiDomain + "/get_barges_list_for_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_fin_csc_id: barge_sales_fin_csc_id,
      }),
    });
  },

  add_barge_sales_fin_csc(
    loginUserID,
    idToken,
    sale_contract_id,
    sc_qualities_id,
    sc_sales_type_id,
    barge_data,
    csc_data
  ) {
    let url = config.apiDomain + "/add_barge_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sale_contract_id: sale_contract_id,
        sc_qualities_id: sc_qualities_id,
        sc_sales_type_id: sc_sales_type_id,
        barge_data: barge_data,
        csc_data: csc_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_sales_fin_csc(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_barge_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_for_sales_fin_csc(loginUserID, idToken, sale_contract_id) {
    let url = config.apiDomain + "/get_barge_for_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        sale_contract_id: sale_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_sales_fin_csc(loginUserID, idToken, barge_data, csc_data) {
    let url = config.apiDomain + "/update_barge_sales_fin_csc";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_data: barge_data,
        csc_data: csc_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  SALES QUALITY RESULTS
   *
   */
  add_barge_sales_fin_quality_result(
    loginUserID,
    idToken,
    business_no_id,
    business_allocation_id,
    barge_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status,
    files
  ) {
    var url = config.apiDomain + "/add_barge_sales_fin_quality_result";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_allocation_id: business_allocation_id,
        barge_id: barge_id,
        quality_calorific_value_actual: quality_calorific_value_actual
          ? quality_calorific_value_actual.toUpperCase()
          : null,
        quality_calorific_value2_actual: quality_calorific_value2_actual
          ? quality_calorific_value2_actual.toUpperCase()
          : null,
        quality_calorific_value3_actual: quality_calorific_value3_actual
          ? quality_calorific_value3_actual.toUpperCase()
          : null,
        quality_total_moisture_actual: quality_total_moisture_actual
          ? quality_total_moisture_actual.toUpperCase()
          : null,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual
          ? quality_inherent_moisture_actual.toUpperCase()
          : null,
        quality_ash_actual: quality_ash_actual
          ? quality_ash_actual.toUpperCase()
          : null,
        quality_volatile_matter_actual: quality_volatile_matter_actual
          ? quality_volatile_matter_actual.toUpperCase()
          : null,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual
          ? quality_fixed_carbon_actual.toUpperCase()
          : null,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual
          ? quality_fuel_ratio_actual.toUpperCase()
          : null,
        quality_total_sulphur_actual: quality_total_sulphur_actual
          ? quality_total_sulphur_actual.toUpperCase()
          : null,
        quality_hgi_actual: quality_hgi_actual
          ? quality_hgi_actual.toUpperCase()
          : null,
        status: status,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_sales_fin_quality_result(
    loginUserID,
    idToken,
    business_no_id,
    business_allocation_id,
    barge_id,
    barge_sales_quality_id,
    quality_calorific_value_actual,
    quality_calorific_value2_actual,
    quality_calorific_value3_actual,
    quality_total_moisture_actual,
    quality_inherent_moisture_actual,
    quality_ash_actual,
    quality_volatile_matter_actual,
    quality_fixed_carbon_actual,
    quality_fuel_ratio_actual,
    quality_total_sulphur_actual,
    quality_hgi_actual,
    status,
    existing_file_ids,
    new_files
  ) {
    var url = config.apiDomain + "/update_barge_sales_fin_quality_result";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_allocation_id: business_allocation_id,
        barge_id: barge_id,
        barge_sales_quality_id: barge_sales_quality_id,
        quality_calorific_value_actual: quality_calorific_value_actual
          ? quality_calorific_value_actual.toUpperCase()
          : null,
        quality_calorific_value2_actual: quality_calorific_value2_actual
          ? quality_calorific_value2_actual.toUpperCase()
          : null,
        quality_calorific_value3_actual: quality_calorific_value3_actual
          ? quality_calorific_value3_actual.toUpperCase()
          : null,
        quality_total_moisture_actual: quality_total_moisture_actual
          ? quality_total_moisture_actual.toUpperCase()
          : null,
        quality_inherent_moisture_actual: quality_inherent_moisture_actual
          ? quality_inherent_moisture_actual.toUpperCase()
          : null,
        quality_ash_actual: quality_ash_actual
          ? quality_ash_actual.toUpperCase()
          : null,
        quality_volatile_matter_actual: quality_volatile_matter_actual
          ? quality_volatile_matter_actual.toUpperCase()
          : null,
        quality_fixed_carbon_actual: quality_fixed_carbon_actual
          ? quality_fixed_carbon_actual.toUpperCase()
          : null,
        quality_fuel_ratio_actual: quality_fuel_ratio_actual
          ? quality_fuel_ratio_actual.toUpperCase()
          : null,
        quality_total_sulphur_actual: quality_total_sulphur_actual
          ? quality_total_sulphur_actual.toUpperCase()
          : null,
        quality_hgi_actual: quality_hgi_actual
          ? quality_hgi_actual.toUpperCase()
          : null,
        status: status,
        existing_file_ids: existing_file_ids,
        files: new_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_sales_fin_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    business_allocation_id,
    barge_id
  ) {
    let url = config.apiDomain + "/get_barge_sales_fin_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_allocation_id: business_allocation_id,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barges_for_sales_fin_quality_results(
    loginUserID,
    idToken,
    business_no_id,
    business_allocation_id,
    barge_id
  ) {
    let url = config.apiDomain + "/get_barges_for_sales_fin_quality_results";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        business_allocation_id: business_allocation_id,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE SALES FINANCIAL COMMERCIAL COAL COSTING
   *
   */

  add_barge_sales_fin_coal_invoice(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    invoice_no,
    invoice_date,
    invoice_amount,
    invoice_type,
    payment_posting_type,
    invoice_percent,
    remarks
  ) {
    let url = config.apiDomain + "/add_barge_sales_fin_coal_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_financials_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        invoice_type: invoice_type,
        payment_posting_type: payment_posting_type,
        invoice_percent: invoice_percent,
        remarks: remarks ? remarks.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_sales_fin_coal_invoices(
    loginUserID,
    idToken,
    barge_sales_financials_id
  ) {
    let url = config.apiDomain + "/get_barge_sales_fin_coal_invoices";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_financials_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_sales_fin_coal_invoice(
    loginUserID,
    idToken,
    barge_sales_financials_id,
    invoice_id,
    invoice_no,
    invoice_date,
    invoice_amount,
    invoice_type,
    payment_posting_type,
    invoice_percent,
    remarks,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_fin_coal_invoice";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_sales_financials_id: barge_sales_financials_id,
        barge_sales_fin_coal_invoice_id: invoice_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        invoice_amount: invoice_amount,
        payment_posting_type: payment_posting_type,
        invoice_percent: invoice_percent,
        remarks: remarks ? remarks.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_sales_financials_coal_payment(
    loginUserID,
    idToken,
    invoice_id,
    invoice_no,
    date_of_payment,
    amount,
    payment_posting_type,
    description
  ) {
    let url = config.apiDomain + "/add_barge_sales_financials_coal_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        invoice_id: invoice_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        date_of_payment: dateFormateToDB(date_of_payment),
        amount: amount,
        payment_posting_type: payment_posting_type,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_sales_financials_coal_payment(
    loginUserID,
    idToken,
    invoice_id,
    payment_id,
    invoice_no,
    date_of_payment,
    amount,
    payment_posting_type,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_sales_financials_coal_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        invoice_id: invoice_id,
        payment_id: payment_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        date_of_payment: dateFormateToDB(date_of_payment),
        amount: amount,
        payment_posting_type: payment_posting_type,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE PURCHASE FINANCIAL DEMURRAGES
   *
   */
  get_barges_for_barge_fin_demurrage(
    loginUserID,
    idToken,
    demurrage_type,
    vendor_id
  ) {
    let url = config.apiDomain + "/get_barges_for_barge_fin_demurrage";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_type: demurrage_type,
        vendor_id: vendor_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_demurrage_to_barge_vendor(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dmr_to_bg_vend_laycan_commence,
    dmr_to_bg_vend_barge_demurrage,
    billing_currency,
    dmr_to_bg_vend_demurrage_days,
    dmr_to_bg_vend_demurrage_value,
    dmr_to_bg_vend_currency_xchg_rate,
    dmr_to_bg_vend_pph23_account,
    dmr_to_bg_vend_base_price,
    dmr_to_bg_vend_vat_percent,
    dmr_to_bg_vend_vat_value,
    dmr_to_bg_vend_pph23_percent,
    dmr_to_bg_vend_pph23_value,
    dmr_to_bg_vend_total,
    dmr_to_bg_vend_ctc,
    dmr_to_bg_vend_ctc_in_local_currency,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_demurrage_to_barge_vendor";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dmr_to_bg_vend_laycan_commence: dateFormateToDB(
          dmr_to_bg_vend_laycan_commence
        ),
        dmr_to_bg_vend_barge_demurrage: dmr_to_bg_vend_barge_demurrage,
        billing_currency: billing_currency,
        dmr_to_bg_vend_demurrage_days: dmr_to_bg_vend_demurrage_days,
        dmr_to_bg_vend_demurrage_value: dmr_to_bg_vend_demurrage_value,

        dmr_to_bg_vend_currency_xchg_rate: dmr_to_bg_vend_currency_xchg_rate,
        dmr_to_bg_vend_pph23_account: dmr_to_bg_vend_pph23_account,
        dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
        dmr_to_bg_vend_vat_percent: dmr_to_bg_vend_vat_percent,
        dmr_to_bg_vend_vat_value: dmr_to_bg_vend_vat_value,
        dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
        dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
        dmr_to_bg_vend_total: dmr_to_bg_vend_total,
        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
        dmr_to_bg_vend_ctc_in_local_currency:
          dmr_to_bg_vend_ctc_in_local_currency,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_to_barge_vendors(loginUserID, idToken, barge_vendor) {
    let url = config.apiDomain + "/get_barge_demurrage_to_barge_vendors";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor: barge_vendor,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_demurrage_to_barge_vendor(
    loginUserID,
    idToken,
    barge_vendor_demurrage_id,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    dmr_to_bg_vend_laycan_commence,
    dmr_to_bg_vend_barge_demurrage,
    billing_currency,
    dmr_to_bg_vend_demurrage_days,
    dmr_to_bg_vend_demurrage_value,
    dmr_to_bg_vend_currency_xchg_rate,
    dmr_to_bg_vend_pph23_account,
    dmr_to_bg_vend_base_price,
    dmr_to_bg_vend_vat_percent,
    dmr_to_bg_vend_vat_value,
    dmr_to_bg_vend_pph23_percent,
    dmr_to_bg_vend_pph23_value,
    dmr_to_bg_vend_total,
    dmr_to_bg_vend_ctc,
    dmr_to_bg_vend_ctc_in_local_currency,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_barge_demurrage_to_barge_vendor";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor_demurrage_id: barge_vendor_demurrage_id,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        dmr_to_bg_vend_laycan_commence: dateFormateToDB(
          dmr_to_bg_vend_laycan_commence
        ),
        dmr_to_bg_vend_barge_demurrage: dmr_to_bg_vend_barge_demurrage,
        billing_currency: billing_currency,
        dmr_to_bg_vend_demurrage_days: dmr_to_bg_vend_demurrage_days,
        dmr_to_bg_vend_demurrage_value: dmr_to_bg_vend_demurrage_value,

        dmr_to_bg_vend_currency_xchg_rate: dmr_to_bg_vend_currency_xchg_rate,
        dmr_to_bg_vend_pph23_account: dmr_to_bg_vend_pph23_account,
        dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
        dmr_to_bg_vend_vat_percent: dmr_to_bg_vend_vat_percent,
        dmr_to_bg_vend_vat_value: dmr_to_bg_vend_vat_value,
        dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
        dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
        dmr_to_bg_vend_total: dmr_to_bg_vend_total,
        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
        dmr_to_bg_vend_ctc_in_local_currency:
          dmr_to_bg_vend_ctc_in_local_currency,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_demurrage_to_barge_vendor(
    loginUserID,
    idToken,
    barge_vendor_demurrage_id
  ) {
    let url = config.apiDomain + "/delete_barge_demurrage_to_barge_vendor";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor_demurrage_id: barge_vendor_demurrage_id,
      }),
    });
  },

  get_barge_demurrage_to_barge_vendor(
    loginUserID,
    idToken,
    barge_vendor_demurrage_id
  ) {
    let url = config.apiDomain + "/get_barge_demurrage_to_barge_vendor";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor_demurrage_id: barge_vendor_demurrage_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_dmr_to_barge_vendor_payments(
    loginUserID,
    idToken,
    barge_vendor_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_dmr_to_barge_vendor_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor_demurrage_id: barge_vendor_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_dmr_to_barge_vendor_payments(
    loginUserID,
    idToken,
    barge_vendor_demurrage_id,
    barge_vendor_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_dmr_to_barge_vendor_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_vendor_demurrage_id: barge_vendor_demurrage_id,
        barge_vendor_payment_id: barge_vendor_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  COMPANY TO SUPPLIER DEMURRAGE API
   *
   */
  add_barge_demurrage_to_supplier(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dmr_to_supplier_time_allowed_for_discharge,
    dmr_to_supplier_barge_detention,
    dmr_to_supplier_detention_in_days,
    dmr_to_supplier_detention_value,
    billing_currency,
    dmr_to_supplier_currency_xchg_rate,
    dmr_to_supplier_pph23_account,
    dmr_to_supplier_base_price,
    dmr_to_supplier_vat_percent,
    dmr_to_supplier_vat_value,
    dmr_to_supplier_pph23_percent,
    dmr_to_supplier_pph23_value,
    dmr_to_supplier_total,
    dmr_to_supplier_ctc,
    dmr_to_supplier_ctc_in_local_currency,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_demurrage_to_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dmr_to_supplier_time_allowed_for_discharge:
          dmr_to_supplier_time_allowed_for_discharge,
        dmr_to_supplier_barge_detention: dmr_to_supplier_barge_detention,
        dmr_to_supplier_detention_in_days: dmr_to_supplier_detention_in_days,
        dmr_to_supplier_detention_value: dmr_to_supplier_detention_value,
        billing_currency: billing_currency,
        dmr_to_supplier_currency_xchg_rate: dmr_to_supplier_currency_xchg_rate,
        dmr_to_supplier_pph23_account: dmr_to_supplier_pph23_account,
        dmr_to_supplier_base_price: dmr_to_supplier_base_price,
        dmr_to_supplier_vat_percent: dmr_to_supplier_vat_percent,
        dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
        dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
        dmr_to_supplier_pph23_value: dmr_to_supplier_pph23_value,
        dmr_to_supplier_total: dmr_to_supplier_total,
        dmr_to_supplier_ctc: dmr_to_supplier_ctc,
        dmr_to_supplier_ctc_in_local_currency:
          dmr_to_supplier_ctc_in_local_currency,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_to_suppliers(loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_demurrage_to_suppliers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_to_supplier(
    loginUserID,
    idToken,
    demurrage_to_supplier_id
  ) {
    let url = config.apiDomain + "/get_barge_demurrage_to_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_to_supplier_id: demurrage_to_supplier_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_demurrage_to_supplier(
    loginUserID,
    idToken,
    barge_id,
    demurrage_to_supplier_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    dmr_to_supplier_time_allowed_for_discharge,
    dmr_to_supplier_barge_detention,
    dmr_to_supplier_detention_in_days,
    dmr_to_supplier_detention_value,
    dmr_to_supplier_currency_xchg_rate,
    dmr_to_supplier_pph23_account,
    dmr_to_supplier_base_price,
    dmr_to_supplier_vat_percent,
    dmr_to_supplier_vat_value,
    dmr_to_supplier_pph23_percent,
    dmr_to_supplier_pph23_value,
    dmr_to_supplier_total,
    dmr_to_supplier_ctc,
    dmr_to_supplier_ctc_in_local_currency,
    existing_file_ids,
    content_files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_barge_demurrage_to_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        demurrage_to_supplier_id: demurrage_to_supplier_id,
        dmr_to_supplier_time_allowed_for_discharge:
          dmr_to_supplier_time_allowed_for_discharge,
        dmr_to_supplier_barge_detention: dmr_to_supplier_barge_detention,
        dmr_to_supplier_detention_in_days: dmr_to_supplier_detention_in_days,
        dmr_to_supplier_detention_value: dmr_to_supplier_detention_value,
        dmr_to_supplier_currency_xchg_rate: dmr_to_supplier_currency_xchg_rate,
        dmr_to_supplier_pph23_account: dmr_to_supplier_pph23_account,
        dmr_to_supplier_base_price: dmr_to_supplier_base_price,
        dmr_to_supplier_vat_percent: dmr_to_supplier_vat_percent,
        dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
        dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
        dmr_to_supplier_pph23_value: dmr_to_supplier_pph23_value,
        dmr_to_supplier_total: dmr_to_supplier_total,
        dmr_to_supplier_ctc: dmr_to_supplier_ctc,
        dmr_to_supplier_ctc_in_local_currency:
          dmr_to_supplier_ctc_in_local_currency,
        existing_file_ids: existing_file_ids,
        files: content_files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_demurrage_to_supplier(
    loginUserID,
    idToken,
    demurrage_to_supplier_id
  ) {
    let url = config.apiDomain + "/delete_barge_demurrage_to_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_to_supplier_id: demurrage_to_supplier_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // Payment postings
  add_barge_dmr_to_supplier_payments(
    loginUserID,
    idToken,
    barge_to_suppiler_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_dmr_to_supplier_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_to_suppiler_demurrage_id: barge_to_suppiler_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_dmr_to_supplier_payments(
    loginUserID,
    idToken,
    barge_to_suppiler_demurrage_id,
    barge_to_supplier_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_dmr_to_supplier_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_to_suppiler_demurrage_id: barge_to_suppiler_demurrage_id,
        barge_to_supplier_payment_id: barge_to_supplier_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  SUPPLIER TO COMPANY DEMURRAGES
   *
   */
  add_barge_demurrage_from_supplier(
    loginUserID,
    idToken,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,

    dmr_from_supplier_barge_demurrage,
    billing_currency,
    dmr_from_supplier_ctc_in_local_currency,
    dmr_from_supplier_demurrage_in_days,
    dmr_from_supplier_demurrage_value,
    dmr_from_supplier_currency_xchg_rate,
    dmr_from_supplier_pph23_account,
    dmr_from_supplier_base_price,
    dmr_from_supplier_vat_percent,
    dmr_from_supplier_vat_value,
    dmr_from_supplier_pph23_percent,
    dmr_from_supplier_pph23_value,
    dmr_from_supplier_total,
    dmr_from_supplier_ctc,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_barge_demurrage_from_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dmr_from_supplier_barge_demurrage: dmr_from_supplier_barge_demurrage,
        billing_currency: billing_currency,
        dmr_from_supplier_demurrage_in_days:
          dmr_from_supplier_demurrage_in_days,
        dmr_from_supplier_demurrage_value: dmr_from_supplier_demurrage_value,

        dmr_from_supplier_currency_xchg_rate:
          dmr_from_supplier_currency_xchg_rate,
        dmr_from_supplier_pph23_account: dmr_from_supplier_pph23_account,
        dmr_from_supplier_base_price: dmr_from_supplier_base_price,
        dmr_from_supplier_vat_percent: dmr_from_supplier_vat_percent,
        dmr_from_supplier_vat_value: dmr_from_supplier_vat_value,
        dmr_from_supplier_pph23_percent: dmr_from_supplier_pph23_percent,
        dmr_from_supplier_pph23_value: dmr_from_supplier_pph23_value,
        dmr_from_supplier_total: dmr_from_supplier_total,
        dmr_from_supplier_ctc: dmr_from_supplier_ctc,
        dmr_from_supplier_ctc_in_local_currency:
          dmr_from_supplier_ctc_in_local_currency,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_from_suppliers(loginUserID, idToken) {
    let url = config.apiDomain + "/get_barge_demurrage_from_suppliers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_from_supplier(
    loginUserID,
    idToken,
    demurrage_from_supplier_id
  ) {
    let url = config.apiDomain + "/get_barge_demurrage_from_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_from_supplier_id: demurrage_from_supplier_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_demurrage_from_supplier(
    loginUserID,
    idToken,
    barge_id,
    demurrage_from_supplier_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,

    dmr_from_supplier_barge_demurrage,
    billing_currency,
    dmr_from_supplier_ctc_in_local_currency,
    dmr_from_supplier_demurrage_in_days,
    dmr_from_supplier_demurrage_value,
    dmr_from_supplier_currency_xchg_rate,
    dmr_from_supplier_pph23_account,
    dmr_from_supplier_base_price,
    dmr_from_supplier_vat_percent,
    dmr_from_supplier_vat_value,
    dmr_from_supplier_pph23_percent,
    dmr_from_supplier_pph23_value,
    dmr_from_supplier_total,
    dmr_from_supplier_ctc,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_barge_demurrage_from_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        demurrage_from_supplier_id: demurrage_from_supplier_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        dmr_from_supplier_barge_demurrage: dmr_from_supplier_barge_demurrage,
        billing_currency: billing_currency,
        dmr_from_supplier_demurrage_in_days:
          dmr_from_supplier_demurrage_in_days,
        dmr_from_supplier_demurrage_value: dmr_from_supplier_demurrage_value,

        dmr_from_supplier_currency_xchg_rate:
          dmr_from_supplier_currency_xchg_rate,
        dmr_from_supplier_pph23_account: dmr_from_supplier_pph23_account,
        dmr_from_supplier_base_price: dmr_from_supplier_base_price,
        dmr_from_supplier_vat_percent: dmr_from_supplier_vat_percent,
        dmr_from_supplier_vat_value: dmr_from_supplier_vat_value,
        dmr_from_supplier_pph23_percent: dmr_from_supplier_pph23_percent,
        dmr_from_supplier_pph23_value: dmr_from_supplier_pph23_value,
        dmr_from_supplier_total: dmr_from_supplier_total,
        dmr_from_supplier_ctc: dmr_from_supplier_ctc,
        dmr_from_supplier_ctc_in_local_currency:
          dmr_from_supplier_ctc_in_local_currency,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_demurrage_from_supplier(
    loginUserID,
    idToken,
    demurrage_from_supplier_id
  ) {
    let url = config.apiDomain + "/delete_barge_demurrage_from_supplier";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_from_supplier_id: demurrage_from_supplier_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //Payment postings
  add_barge_dmr_from_supplier_payments(
    loginUserID,
    idToken,
    barge_from_suppiler_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_dmr_from_supplier_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_from_suppiler_demurrage_id: barge_from_suppiler_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_dmr_from_supplier_payments(
    loginUserID,
    idToken,
    barge_from_suppiler_demurrage_id,
    barge_from_supplier_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_dmr_from_supplier_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_from_suppiler_demurrage_id: barge_from_suppiler_demurrage_id,
        barge_from_supplier_payment_id: barge_from_supplier_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE SALES FINANCIAL DEMURRAGES
   *
   */
  get_barges_for_barge_demurrage_buyer(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_barges_for_barge_demurrage_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // Company to Buyer
  add_barge_demurrage_to_buyer(
    loginUserID,
    idToken,
    business_no_id,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dmr_to_buyer_allowed_for_discharge,
    dmr_to_buyer_barge_demurrage,
    billing_currency,
    dmr_to_buyer_demurrage_in_days,
    dmr_to_buyer_demurrage_value,
    dmr_to_buyer_currency_xchg_rate,
    dmr_to_buyer_pph23_account,
    dmr_to_buyer_base_price,
    dmr_to_buyer_vat_percent,
    dmr_to_buyer_vat_value,
    dmr_to_buyer_pph23_percent,
    dmr_to_buyer_pph23_value,
    dmr_to_buyer_total,
    dmr_to_buyer_ctc,
    dmr_to_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/add_barge_demurrage_to_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dmr_to_buyer_allowed_for_discharge: dmr_to_buyer_allowed_for_discharge,
        dmr_to_buyer_barge_demurrage: dmr_to_buyer_barge_demurrage,
        billing_currency: billing_currency,
        dmr_to_buyer_demurrage_in_days: dmr_to_buyer_demurrage_in_days,
        dmr_to_buyer_demurrage_value: dmr_to_buyer_demurrage_value,

        dmr_to_buyer_currency_xchg_rate: dmr_to_buyer_currency_xchg_rate,
        dmr_to_buyer_pph23_account: dmr_to_buyer_pph23_account,
        dmr_to_buyer_base_price: dmr_to_buyer_base_price,
        dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
        dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,
        dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
        dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
        dmr_to_buyer_total: dmr_to_buyer_total,
        dmr_to_buyer_ctc: dmr_to_buyer_ctc,
        dmr_to_buyer_ctc_in_local_currency: dmr_to_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_demurrage_to_buyer(
    loginUserID,
    idToken,
    business_no_id,
    demurrage_to_buyer_id,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    dmr_to_buyer_allowed_for_discharge,
    dmr_to_buyer_barge_demurrage,
    billing_currency,
    dmr_to_buyer_demurrage_in_days,
    dmr_to_buyer_demurrage_value,
    dmr_to_buyer_currency_xchg_rate,
    dmr_to_buyer_pph23_account,
    dmr_to_buyer_base_price,
    dmr_to_buyer_vat_percent,
    dmr_to_buyer_vat_value,
    dmr_to_buyer_pph23_percent,
    dmr_to_buyer_pph23_value,
    dmr_to_buyer_total,
    dmr_to_buyer_ctc,
    dmr_to_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/update_barge_demurrage_to_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        demurrage_to_buyer_id: demurrage_to_buyer_id,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        dmr_to_buyer_allowed_for_discharge: dmr_to_buyer_allowed_for_discharge,
        dmr_to_buyer_barge_demurrage: dmr_to_buyer_barge_demurrage,
        billing_currency: billing_currency,
        dmr_to_buyer_demurrage_in_days: dmr_to_buyer_demurrage_in_days,
        dmr_to_buyer_demurrage_value: dmr_to_buyer_demurrage_value,

        dmr_to_buyer_currency_xchg_rate: dmr_to_buyer_currency_xchg_rate,
        dmr_to_buyer_pph23_account: dmr_to_buyer_pph23_account,
        dmr_to_buyer_base_price: dmr_to_buyer_base_price,
        dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
        dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,
        dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
        dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
        dmr_to_buyer_total: dmr_to_buyer_total,
        dmr_to_buyer_ctc: dmr_to_buyer_ctc,
        dmr_to_buyer_ctc_in_local_currency: dmr_to_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_to_buyer(loginUserID, idToken, demurrage_to_buyer_id) {
    let url = config.apiDomain + "/get_barge_demurrage_to_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_to_buyer_id: demurrage_to_buyer_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_to_buyers(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_barge_demurrage_to_buyers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_demurrage_to_buyer(loginUserID, idToken, demurrage_to_buyer_id) {
    let url = config.apiDomain + "/delete_barge_demurrage_to_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_to_buyer_id: demurrage_to_buyer_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_barge_dmr_to_buyer_payments(
    loginUserID,
    idToken,
    barge_to_buyer_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_dmr_to_buyer_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_to_buyer_demurrage_id: barge_to_buyer_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_dmr_to_buyer_payments(
    loginUserID,
    idToken,
    barge_to_buyer_demurrage_id,
    barge_to_buyer_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_dmr_to_buyer_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_to_buyer_demurrage_id: barge_to_buyer_demurrage_id,
        barge_to_buyer_payment_id: barge_to_buyer_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_demurrage_from_buyer(
    loginUserID,
    idToken,
    business_no_id,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    dmr_from_buyer_time_allowed_for_discharge,
    dmr_from_buyer_barge_detention,
    dmr_from_buyer_detention_in_days,
    dmr_from_buyer_detention_value,
    dmr_from_buyer_barge_currency,
    dmr_from_buyer_currency_xchg_rate,
    dmr_from_buyer_pph23_account,
    dmr_from_buyer_base_price,
    dmr_from_buyer_vat_percent,
    dmr_from_buyer_vat_value,
    dmr_from_buyer_pph23_percent,
    dmr_from_buyer_pph23_value,
    dmr_from_buyer_total,
    dmr_from_buyer_ctc,
    dmr_from_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/add_barge_demurrage_from_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        dmr_from_buyer_time_allowed_for_discharge:
          dmr_from_buyer_time_allowed_for_discharge,
        dmr_from_buyer_barge_detention: dmr_from_buyer_barge_detention,
        dmr_from_buyer_detention_in_days: dmr_from_buyer_detention_in_days,
        dmr_from_buyer_detention_value: dmr_from_buyer_detention_value,
        dmr_from_buyer_barge_currency: dmr_from_buyer_barge_currency,
        dmr_from_buyer_currency_xchg_rate: dmr_from_buyer_currency_xchg_rate,
        dmr_from_buyer_pph23_account: dmr_from_buyer_pph23_account,
        dmr_from_buyer_base_price: dmr_from_buyer_base_price,
        dmr_from_buyer_vat_percent: dmr_from_buyer_vat_percent,
        dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
        dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
        dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
        dmr_from_buyer_total: dmr_from_buyer_total,
        dmr_from_buyer_ctc: dmr_from_buyer_ctc,
        dmr_from_buyer_ctc_in_local_currency:
          dmr_from_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_from_buyers(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_barge_demurrage_from_buyers";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_demurrage_from_buyer(
    loginUserID,
    idToken,
    demurrage_from_buyer_id
  ) {
    let url = config.apiDomain + "/get_barge_demurrage_from_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_from_buyer_id: demurrage_from_buyer_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_demurrage_from_buyer(
    loginUserID,
    idToken,
    business_no_id,
    demurrage_from_buyer_id,
    barge_id,
    invoice_no,
    invoice_date,
    ppn,
    ppn_date,
    dmr_from_buyer_time_allowed_for_discharge,
    dmr_from_buyer_barge_detention,
    dmr_from_buyer_detention_in_days,
    dmr_from_buyer_detention_value,
    dmr_from_buyer_barge_currency,
    dmr_from_buyer_currency_xchg_rate,
    dmr_from_buyer_pph23_account,
    dmr_from_buyer_base_price,
    dmr_from_buyer_vat_percent,
    dmr_from_buyer_vat_value,
    dmr_from_buyer_pph23_percent,
    dmr_from_buyer_pph23_value,
    dmr_from_buyer_total,
    dmr_from_buyer_ctc,
    dmr_from_buyer_ctc_in_local_currency
  ) {
    let url = config.apiDomain + "/update_barge_demurrage_from_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        demurrage_from_buyer_id: demurrage_from_buyer_id,
        barge_id: barge_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        ppn_date: dateFormateToDB(ppn_date),
        dmr_from_buyer_time_allowed_for_discharge:
          dmr_from_buyer_time_allowed_for_discharge,
        dmr_from_buyer_barge_detention: dmr_from_buyer_barge_detention,
        dmr_from_buyer_detention_in_days: dmr_from_buyer_detention_in_days,
        dmr_from_buyer_detention_value: dmr_from_buyer_detention_value,
        dmr_from_buyer_barge_currency: dmr_from_buyer_barge_currency,
        dmr_from_buyer_currency_xchg_rate: dmr_from_buyer_currency_xchg_rate,
        dmr_from_buyer_pph23_account: dmr_from_buyer_pph23_account,
        dmr_from_buyer_base_price: dmr_from_buyer_base_price,
        dmr_from_buyer_vat_percent: dmr_from_buyer_vat_percent,
        dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
        dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
        dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
        dmr_from_buyer_total: dmr_from_buyer_total,
        dmr_from_buyer_ctc: dmr_from_buyer_ctc,
        dmr_from_buyer_ctc_in_local_currency:
          dmr_from_buyer_ctc_in_local_currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_demurrage_from_buyer(
    loginUserID,
    idToken,
    demurrage_from_buyer_id
  ) {
    let url = config.apiDomain + "/delete_barge_demurrage_from_buyer";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        demurrage_from_buyer_id: demurrage_from_buyer_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_barge_dmr_from_buyer_payments(
    loginUserID,
    idToken,
    barge_from_buyer_demurrage_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_dmr_from_buyer_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_from_buyer_demurrage_id: barge_from_buyer_demurrage_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_dmr_from_buyer_payments(
    loginUserID,
    idToken,
    barge_from_buyer_demurrage_id,
    barge_from_buyer_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_barge_dmr_from_buyer_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_from_buyer_demurrage_id: barge_from_buyer_demurrage_id,
        barge_from_buyer_payment_id: barge_from_buyer_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        description: description ? description.toUpperCase() : null,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BUSINESS NUMBER NOTIFY
   *
   */
  add_business_no_notify_party(
    loginUserID,
    idToken,
    business_no_id,
    notify_party_data
  ) {
    let url = config.apiDomain + "/add_business_no_notify_party";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        notify_party_data: notify_party_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_business_no_notify_partys(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_business_no_notify_partys";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_business_no_notify_party(loginUserID, idToken, notify_party_id) {
    let url = config.apiDomain + "/get_business_no_notify_party";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        notify_party_id: notify_party_id,
      }),
    });
  },
  update_business_no_notify_party(
    loginUserID,
    idToken,
    business_no_id,
    notify_party_data
  ) {
    let url = config.apiDomain + "/update_business_no_notify_party";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        notify_party_data: notify_party_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * !!  SERVICES API'S
   *
   */

  add_service_type(loginUserID, idToken, service_type) {
    let url = config.apiDomain + "/add_service_type";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_type: service_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_service_types(loginUserID, idToken) {
    let url = config.apiDomain + "/get_service_types";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_service(
    loginUserID,
    idToken,
    invoice_no,
    invoice_date,
    ppn,
    service_type,
    business_no_id,
    barge_id,
    vendor_id,
    payable_type,
    payable_quantity_in_mt,
    payable_price_per_mt,
    payable_base_price,
    payable_currency,
    payable_pph_percent,
    payable_pph_value,
    payable_vat_percent,
    payable_vat_value,
    payable_total_value,
    payable_ctc,
    payable_exchg_rate,
    debit_note_no,
    payable_remarks,

    customer_id,
    receivable_type,
    receivable_quantity_in_mt,
    receivable_price_per_mt,
    receivable_base_price,
    receivable_currency,
    receivable_pph_percent,
    receivable_pph_value,
    receivable_vat_percent,
    receivable_vat_value,
    receivable_total_value,
    receivable_ctc,
    receivable_exchg_rate,
    credit_note_no,
    receivable_remarks,
    payable_files,
    receivable_files
  ) {
    let url = config.apiDomain + "/add_service";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : null,
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn ? ppn.toUpperCase() : null,
        service_type: service_type,
        business_no_id: business_no_id,
        barge_id: barge_id,
        vendor_id: vendor_id,
        payable_type: payable_type,
        payable_quantity_in_mt: payable_quantity_in_mt,
        payable_price_per_mt: payable_price_per_mt,
        payable_base_price: payable_base_price,
        payable_currency: payable_currency,
        payable_pph_percent: payable_pph_percent,
        payable_pph_value: payable_pph_value,
        payable_vat_percent: payable_vat_percent,
        payable_vat_value: payable_vat_value,
        payable_total_value: payable_total_value,
        payable_ctc: payable_ctc,
        payable_exchg_rate: payable_exchg_rate,
        debit_note_no: debit_note_no ? debit_note_no.toUpperCase() : "",
        payable_remarks: payable_remarks ? payable_remarks.toUpperCase() : "",

        customer_id: customer_id,
        receivable_type: receivable_type,
        receivable_quantity_in_mt: receivable_quantity_in_mt,
        receivable_price_per_mt: receivable_price_per_mt,
        receivable_base_price: receivable_base_price,
        receivable_currency: receivable_currency,
        receivable_pph_percent: receivable_pph_percent,
        receivable_pph_value: receivable_pph_value,
        receivable_vat_percent: receivable_vat_percent,
        receivable_vat_value: receivable_vat_value,
        receivable_total_value: receivable_total_value,
        receivable_ctc: receivable_ctc,
        receivable_exchg_rate: receivable_exchg_rate,
        credit_note_no: credit_note_no ? credit_note_no.toUpperCase() : "",
        receivable_remarks: receivable_remarks
          ? receivable_remarks.toUpperCase()
          : "",
        payable_files: payable_files,
        receivable_files: receivable_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_services(loginUserID, idToken) {
    let url = config.apiDomain + "/get_services";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_service(loginUserID, idToken, service_id) {
    let url = config.apiDomain + "/get_service";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_id: service_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_service(
    loginUserID,
    idToken,
    service_id,
    invoice_no,
    invoice_date,
    ppn,
    service_type,
    business_no_id,
    barge_id,
    vendor_id,
    payable_type,
    payable_quantity_in_mt,
    payable_price_per_mt,
    payable_base_price,
    payable_currency,
    payable_pph_percent,
    payable_pph_value,
    payable_vat_percent,
    payable_vat_value,
    payable_total_value,
    payable_exchg_rate,
    payable_ctc,
    debit_note_no,
    payable_remarks,

    customer_id,
    receivable_type,
    receivable_quantity_in_mt,
    receivable_price_per_mt,
    receivable_base_price,
    receivable_currency,
    receivable_pph_percent,
    receivable_pph_value,
    receivable_vat_percent,
    receivable_vat_value,
    receivable_total_value,
    receivable_exchg_rate,
    receivable_ctc,
    credit_note_no,
    receivable_remarks,
    payable_content_files,
    receivable_content_files,
    payable_existing_file_ids,
    receivable_existing_file_ids
  ) {
    let url = config.apiDomain + "/update_service";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_id: service_id,
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        invoice_date: dateFormateToDB(invoice_date),
        ppn: ppn,
        service_type: service_type,
        business_no_id: business_no_id,
        barge_id: barge_id,
        vendor_id: vendor_id,
        payable_type: payable_type,
        payable_quantity_in_mt: payable_quantity_in_mt,
        payable_price_per_mt: payable_price_per_mt,
        payable_base_price: payable_base_price,
        payable_currency: payable_currency,
        payable_pph_percent: payable_pph_percent,
        payable_pph_value: payable_pph_value,
        payable_vat_percent: payable_vat_percent,
        payable_vat_value: payable_vat_value,
        payable_total_value: payable_total_value,
        payable_ctc: payable_ctc,
        payable_exchg_rate: payable_exchg_rate,
        debit_note_no: debit_note_no ? debit_note_no.toUpperCase() : "",
        payable_remarks: payable_remarks ? payable_remarks.toUpperCase() : "",

        customer_id: customer_id,
        receivable_type: receivable_type,
        receivable_quantity_in_mt: receivable_quantity_in_mt,
        receivable_price_per_mt: receivable_price_per_mt,
        receivable_base_price: receivable_base_price,
        receivable_currency: receivable_currency,
        receivable_pph_percent: receivable_pph_percent,
        receivable_pph_value: receivable_pph_value,
        receivable_vat_percent: receivable_vat_percent,
        receivable_vat_value: receivable_vat_value,
        receivable_total_value: receivable_total_value,
        receivable_ctc: receivable_ctc,
        receivable_exchg_rate: receivable_exchg_rate,
        credit_note_no: credit_note_no ? credit_note_no.toUpperCase() : "",
        receivable_remarks: receivable_remarks
          ? receivable_remarks.toUpperCase()
          : "",
        payable_files: payable_content_files,
        receivable_files: receivable_content_files,
        payable_existing_file_ids: payable_existing_file_ids,
        receivable_existing_file_ids: receivable_existing_file_ids,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_services_payment(
    loginUserID,
    idToken,
    service_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/add_services_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_id: service_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_services_payments(loginUserID, idToken, service_id) {
    let url = config.apiDomain + "/get_services_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_id: service_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_services_payment(
    loginUserID,
    idToken,
    service_id,
    service_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_services_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        service_id: service_id,
        service_payment_id: service_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  ! reports api's
   *
   */
  get_profit_loss_report(
    loginUserID,
    idToken,
    coal_vendor,
    from_date,
    to_date,
    grade
  ) {
    let url = config.apiDomain + "/get_profit_loss_report";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        coal_vendor: coal_vendor,
        from_date: from_date,
        to_date: to_date,
        grade: grade,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_grades_based_on_coal_vendor(loginUserID, idToken, coal_vendor) {
    let url = config.apiDomain + "/get_grades_based_on_coal_vendor";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        coal_vendor: coal_vendor,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_report_exchg_rate() {
    let url = "https://api.exchangeratesapi.io/2020-01-10?base=USD&symbols=IDR";
    return fetch(url, {
      method: "Get",
      // body: JSON.stringify({

      // }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_pc_available_quantity_report(
    loginUserID,
    idToken,
    vendor_id,
    purchase_contract_id,
    duration
  ) {
    let url = config.apiDomain + "/get_pc_available_quantity_report";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        vendor_id: vendor_id,
        purchase_contract_id: purchase_contract_id,
        duration: duration,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * VESSEL COMPLETION REPORT API'S
   *
   */
  add_business_no_vessel_completion(
    loginUserID,
    idToken,
    business_no_id,
    management_remarks,
    business_no_vessel_com_bl_data,
    business_no_vessel_com_coo_data,
    files
  ) {
    let url = config.apiDomain + "/add_business_no_vessel_completion";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        management_remarks: management_remarks
          ? management_remarks.toUpperCase()
          : "",
        business_no_vessel_com_bl_data: business_no_vessel_com_bl_data,
        business_no_vessel_com_coo_data: business_no_vessel_com_coo_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_business_no_vessel_completion(
    loginUserID,
    idToken,
    business_no_id,
    vessel_completion_id,
    management_remarks,
    business_no_vessel_com_bl_data,
    business_no_vessel_com_coo_data,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_business_no_vessel_completion";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        vessel_completion_id: vessel_completion_id,
        management_remarks: management_remarks
          ? management_remarks.toUpperCase()
          : "",
        business_no_vessel_com_bl_data: business_no_vessel_com_bl_data,
        business_no_vessel_com_coo_data: business_no_vessel_com_coo_data,
        existing_file_ids: existing_file_ids,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_business_no_vessel_completions(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_business_no_vessel_completions";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  FIXTURE NOTES API'S
   *
   */
  add_fixture_notes(
    loginUserID,
    idToken,
    fixture_note_type,
    supplier_reference_no,
    mother_vessel_name,
    agreement_date,
    vendor,
    laycan,
    quantity_in_mt,
    allowed_days,
    loading_rate_per_day,
    dead_freight,
    vessel_type,
    port_of_loading,
    currency,
    price_per_mt,
    price_per_mt_flf,
    price_per_day,
    demurrage_type,
    demurrage_rate_per_day,
    cancellation_fee,
    payment_terms,
    other_t_and_c,
    remarks,
    files
  ) {
    let url = config.apiDomain + "/add_fixture_notes";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        fixture_note_type: fixture_note_type,
        supplier_reference_no: supplier_reference_no
          ? supplier_reference_no.toUpperCase()
          : "",
        mother_vessel_name: mother_vessel_name
          ? mother_vessel_name.toUpperCase()
          : "",
        agreement_date: dateFormateToDB(agreement_date),
        vendor: vendor,
        laycan: laycan ? laycan.toUpperCase() : "",
        quantity_in_mt: quantity_in_mt,
        allowed_days: allowed_days,
        loading_rate_per_day: loading_rate_per_day,
        dead_freight: dead_freight,
        vessel_type: vessel_type,
        port_of_loading: port_of_loading ? port_of_loading.toUpperCase() : "",
        currency: currency,
        price_per_mt: price_per_mt,
        price_per_mt_flf: price_per_mt_flf,
        price_per_day: price_per_day,
        demurrage_type: demurrage_type,
        demurrage_rate_per_day: demurrage_rate_per_day,
        cancellation_fee: cancellation_fee
          ? cancellation_fee.toUpperCase()
          : "",
        payment_terms: payment_terms ? payment_terms.toUpperCase() : "",
        other_t_and_c: other_t_and_c ? other_t_and_c.toUpperCase() : "",
        remarks: remarks ? remarks.toUpperCase() : "",
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_fixture_notes(loginUserID, idToken) {
    let url = config.apiDomain + "/get_fixture_notes";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_fixture_note(loginUserID, idToken, fixture_note_id) {
    let url = config.apiDomain + "/get_fixture_note";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        fixture_note_id: fixture_note_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_fixture_notes(
    loginUserID,
    idToken,
    fixture_note_id,
    fixture_note_type,
    supplier_reference_no,
    mother_vessel_name,
    agreement_date,
    vendor,
    laycan,
    quantity_in_mt,
    allowed_days,
    loading_rate_per_day,
    dead_freight,
    vessel_type,
    port_of_loading,
    currency,
    price_per_mt,
    price_per_mt_flf,
    price_per_day,
    demurrage_type,
    demurrage_rate_per_day,
    cancellation_fee,
    payment_terms,
    other_t_and_c,
    remarks,
    existing_file_ids,
    new_files
  ) {
    let url = config.apiDomain + "/update_fixture_notes";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        fixture_note_id: fixture_note_id,
        fixture_note_type: fixture_note_type,
        supplier_reference_no: supplier_reference_no
          ? supplier_reference_no.toUpperCase()
          : "",
        mother_vessel_name: mother_vessel_name
          ? mother_vessel_name.toUpperCase()
          : "",
        agreement_date: dateFormateToDB(agreement_date),
        vendor: vendor,
        laycan: laycan ? laycan.toUpperCase() : "",
        quantity_in_mt: quantity_in_mt,
        allowed_days: allowed_days,
        loading_rate_per_day: loading_rate_per_day,
        dead_freight: dead_freight,
        vessel_type: vessel_type,
        port_of_loading: port_of_loading ? port_of_loading.toUpperCase() : "",
        currency: currency,
        price_per_mt: price_per_mt,
        price_per_mt_flf: price_per_mt_flf,
        price_per_day: price_per_day,
        demurrage_type: demurrage_type,
        demurrage_rate_per_day: demurrage_rate_per_day,
        cancellation_fee: cancellation_fee
          ? cancellation_fee.toUpperCase()
          : "",
        payment_terms: payment_terms ? payment_terms.toUpperCase() : "",
        other_t_and_c: other_t_and_c ? other_t_and_c.toUpperCase() : "",
        remarks: remarks ? remarks.toUpperCase() : "",
        existing_file_ids: existing_file_ids,
        files: new_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGE OTHER EXPENSES
   *
   */
  get_barges_list_for_other_expenses(loginUserID, idToken, other_expenses_id) {
    let url = config.apiDomain + "/get_barges_list_for_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_expenses_id: other_expenses_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_barge_pur_fin_other_expenses(
    loginUserID,
    idToken,
    barge_id,
    other_expenses_data,
    files
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        other_expenses_data: other_expenses_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barges_for_other_expenses(loginUserID, idToken) {
    let url = config.apiDomain + "/get_barges_for_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barge_pur_fin_other_expenses(loginUserID, idToken, costing_id) {
    let url = config.apiDomain + "/get_barge_pur_fin_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        costing_id: costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_pur_fin_other_expenses(
    loginUserID,
    idToken,
    barge_id,
    other_expenses_data,
    files
  ) {
    let url = config.apiDomain + "/update_barge_pur_fin_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        other_expenses_data: other_expenses_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_barge_pur_fin_other_expenses(loginUserID, idToken, other_expenses_id) {
    let url = config.apiDomain + "/delete_barge_pur_fin_other_expenses";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_expenses_id: other_expenses_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // payment postings
  add_barge_pur_fin_other_expenses_payments(
    loginUserID,
    idToken,
    other_expenses_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_pur_fin_other_expenses_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_expenses_id: other_expenses_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barge_pur_fin_other_expenses_payments(
    loginUserID,
    idToken,
    other_expenses_id,
    other_expenses_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url =
      config.apiDomain + "/update_barge_pur_fin_other_expenses_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        other_expenses_id: other_expenses_id,
        other_expenses_payment_id: other_expenses_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  INSURANCE MODULE API'S
   *
   */
  get_barges_for_insurance_and_declaration(loginUserID, idToken) {
    let url = config.apiDomain + "/get_barges_for_insurance_and_declaration";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_insurance_and_declaration(
    loginUserID,
    idToken,
    insurance_level,
    barge_id,
    business_no_id,
    voyage_type,
    vessel_type,
    barge_vessel_name,
    imo_no,
    year_built,
    flag,
    grt,
    classification,
    owners_p_and_i_cover,
    charterparty,
    delivery_date,
    estimated_duration,
    time_or_voyage,
    cargo,
    charterers_p_and_i_liability,
    charterers_fd_and_d_liability,
    back_to_back_sublet,
    charterers_b_l,
    contract_type,
    p_and_i_value,
    fd_and_d_value,
    currency,
    exchange_rate,
    cost_to_company,
    files,
    costing_files
  ) {
    let url = config.apiDomain + "/add_insurance_and_declaration";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_level: insurance_level,
        barge_id: barge_id,
        business_no_id: business_no_id,
        voyage_type: voyage_type ? voyage_type.toUpperCase() : null,
        vessel_type: vessel_type ? vessel_type.toUpperCase() : null,
        barge_vessel_name: barge_vessel_name
          ? barge_vessel_name.toUpperCase()
          : null,
        imo_no: imo_no ? imo_no.toUpperCase() : null,
        year_built: year_built ? year_built.toUpperCase() : null,
        flag: flag ? flag.toUpperCase() : null,
        grt: grt ? grt.toUpperCase() : null,
        classification: classification ? classification.toUpperCase() : null,
        owners_p_and_i_cover: owners_p_and_i_cover
          ? owners_p_and_i_cover.toUpperCase()
          : null,
        charterparty: charterparty ? charterparty.toUpperCase() : null,
        delivery_date: dateFormateToDB(delivery_date),
        estimated_duration: estimated_duration
          ? estimated_duration.toUpperCase()
          : null,
        time_or_voyage: time_or_voyage ? time_or_voyage.toUpperCase() : null,
        cargo: cargo ? cargo.toUpperCase() : null,
        charterers_p_and_i_liability: charterers_p_and_i_liability,
        charterers_fd_and_d_liability: charterers_fd_and_d_liability,
        back_to_back_sublet: back_to_back_sublet,
        charterers_b_l: charterers_b_l,
        contract_type: contract_type,
        p_and_i_value: p_and_i_value,
        fd_and_d_value: fd_and_d_value,
        currency: currency,
        exchange_rate: exchange_rate,
        cost_to_company: cost_to_company,
        files: files,
        costing_files: costing_files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_insurance_and_declarations(loginUserID, idToken, business_no) {
    let url = config.apiDomain + "/get_insurance_and_declarations";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_insurance_and_declaration(
    loginUserID,
    idToken,
    insurance_id,
    business_no_id
  ) {
    let url = config.apiDomain + "/get_insurance_and_declaration";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_id: insurance_id,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_insurance_and_declaration(
    loginUserID,
    idToken,
    insurance_id,
    insurance_level,
    barge_id,
    business_no_id,
    voyage_type,
    vessel_type,
    barge_vessel_name,
    imo_no,
    year_built,
    flag,
    grt,
    classification,
    owners_p_and_i_cover,
    charterparty,
    delivery_date,
    estimated_duration,
    time_or_voyage,
    cargo,
    charterers_p_and_i_liability,
    charterers_fd_and_d_liability,
    back_to_back_sublet,
    charterers_b_l,
    contract_type,
    p_and_i_value,
    fd_and_d_value,
    currency,
    exchange_rate,
    cost_to_company,
    existing_file_ids,
    files,
    costing_file
  ) {
    let url = config.apiDomain + "/update_insurance_and_declaration";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_id: insurance_id,
        insurance_level: insurance_level,
        barge_id: barge_id,
        business_no_id: business_no_id,
        voyage_type: voyage_type ? voyage_type.toUpperCase() : null,
        vessel_type: vessel_type ? vessel_type.toUpperCase() : null,
        barge_vessel_name: barge_vessel_name
          ? barge_vessel_name.toUpperCase()
          : null,
        imo_no: imo_no ? imo_no.toUpperCase() : null,
        year_built: year_built ? year_built.toUpperCase() : null,
        flag: flag ? flag.toUpperCase() : null,
        grt: grt ? grt.toUpperCase() : null,
        classification: classification ? classification.toUpperCase() : null,
        owners_p_and_i_cover: owners_p_and_i_cover
          ? owners_p_and_i_cover.toUpperCase()
          : null,
        charterparty: charterparty ? charterparty.toUpperCase() : null,
        delivery_date: dateFormateToDB(delivery_date),
        estimated_duration: estimated_duration
          ? estimated_duration.toUpperCase()
          : null,
        time_or_voyage: time_or_voyage ? time_or_voyage.toUpperCase() : null,
        cargo: cargo ? cargo.toUpperCase() : null,
        charterers_p_and_i_liability: charterers_p_and_i_liability,
        charterers_fd_and_d_liability: charterers_fd_and_d_liability,
        back_to_back_sublet: back_to_back_sublet,
        charterers_b_l: charterers_b_l,
        contract_type: contract_type,
        p_and_i_value: p_and_i_value,
        fd_and_d_value: fd_and_d_value,
        currency: currency,
        exchange_rate: exchange_rate,
        cost_to_company: cost_to_company,
        existing_file_ids: existing_file_ids,
        files: files,
        costing_files: costing_file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete_insurance_and_declaration(loginUserID, idToken, insurance_id) {
    let url = config.apiDomain + "/delete_insurance_and_declaration";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_id: insurance_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // payment postings
  add_insurance_and_declaration_payments(
    loginUserID,
    idToken,
    insurance_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_insurance_and_declaration_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_id: insurance_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_insurance_and_declaration_payments(
    loginUserID,
    idToken,
    insurance_id,
    insurance_payment_id,
    payment_posting_type,
    amount,
    date_of_payment,
    invoice_no,
    description,
    status
  ) {
    let url = config.apiDomain + "/update_insurance_and_declaration_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        insurance_id: insurance_id,
        insurance_payment_id: insurance_payment_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_files_data(
    loginUserID,
    idToken,
    feature_name,
    feature_seq_id,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_files_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        feature_name: feature_name,
        feature_seq_id: feature_seq_id,
        existing_file_ids: existing_file_ids,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  ================= VAT INPUT REPORT'S ==============================
   *
   */
  get_vat_input_report(loginUserID, idToken, month, year) {
    let url = config.apiDomain + "/get_vat_input_report";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        month: month,
        year: year,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  bulk_update_ppn_value(loginUserID, idToken, costing_types) {
    let url = config.apiDomain + "/bulk_update_ppn_value";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        costing_types: costing_types,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  /**
   *
   * ============= Costing Approvals
   *
   */
  approve_costing_api(request) {
    let url = config.apiDomain + "/approve_costing_api";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  approve_payment_postings_api(request) {
    let url = config.apiDomain + "/approve_payment_postings_api";

    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_pending_approval_costing_list(loginUserID, idToken, financial_type) {
    let url = config.apiDomain + "/get_pending_approval_costing_list";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_approved_costing_list(loginUserID, idToken, financial_type) {
    let url = config.apiDomain + "/get_approved_costing_list";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        financial_type: financial_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  Payments Limits For Approvals
   *
   */

  get_payment_limits(loginUserID, idToken) {
    let url = config.apiDomain + "/get_payment_limits";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_payment_limits(loginUserID, idToken, payments_limit_data) {
    let url = config.apiDomain + "/add_payment_limits";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        payments_limit_data: payments_limit_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  BARGING AGREEMENTS
   *
   */

  get_barging_agreements(request) {
    let url = config.apiDomain + "/get_barging_agreements";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barging_agreement(request) {
    let url = config.apiDomain + "/add_barging_agreement";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_barging_agreement(request) {
    let url = config.apiDomain + "/get_barging_agreement";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barging_agreement(request) {
    let url = config.apiDomain + "/update_barging_agreement";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_barging_agreement_status(request) {
    let url = config.apiDomain + "/update_barging_agreement_status";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_business_no_info_for_barge(request) {
    let url = config.apiDomain + "/get_business_no_info_for_barge";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  ================== COAL PROCURMENT SERVICE contracts ============
   *
   */

  add_cps_contract(
    loginUserID,
    idToken,
    purchase_contract_ids,
    contract_date,
    contract_type,
    vendor_id,
    other_vendor_name,
    currency,
    cps_contract_mappping_data,
    payment_terms,
    remarks,
    contract_kind,
    base_contract_id,
    base_contract_no,
    files
  ) {
    let url = config.apiDomain + "/add_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_ids: purchase_contract_ids,
        contract_date: dateFormateToDB(contract_date),
        contract_type: contract_type,
        vendor_id: vendor_id,
        other_vendor_name: other_vendor_name
          ? other_vendor_name.toUpperCase()
          : null,
        currency: currency,
        payment_terms: payment_terms ? payment_terms.toUpperCase() : null,
        remarks: remarks ? remarks.toUpperCase() : null,
        contract_kind: contract_kind,
        base_contract_id: base_contract_id,
        base_contract_no: base_contract_no,
        cps_contract_mappping_data: cps_contract_mappping_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_cps_contracts(loginUserID, idToken, status) {
    let url = config.apiDomain + "/get_cps_contracts";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_cps_contract(loginUserID, idToken, cps_contract_id) {
    let url = config.apiDomain + "/get_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cps_contract_id: cps_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_cps_costing_details(request) {
    let url = config.apiDomain + "/get_barge_cps_costing_details";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  cancel_cps_contract(loginUserID, idToken, cps_contract_id) {
    let url = config.apiDomain + "/cancel_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cps_contract_id: cps_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  close_cps_contract(loginUserID, idToken, cps_contract_id) {
    let url = config.apiDomain + "/close_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cps_contract_id: cps_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_cps_contract(
    loginUserID,
    idToken,
    cps_contract_id,
    purchase_contract_ids,
    contract_date,
    contract_type,
    vendor_id,
    other_vendor_name,
    currency,
    cps_contract_mappping_data,
    payment_terms,
    remarks,
    existing_file_ids,
    files
  ) {
    let url = config.apiDomain + "/update_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        cps_contract_id: cps_contract_id,
        purchase_contract_ids: purchase_contract_ids,
        contract_date: dateFormateToDB(contract_date),
        contract_type: contract_type,
        vendor_id: vendor_id,
        other_vendor_name: other_vendor_name
          ? other_vendor_name.toUpperCase()
          : null,
        currency: currency,
        payment_terms: payment_terms ? payment_terms.toUpperCase() : null,
        remarks: remarks ? remarks.toUpperCase() : null,
        cps_contract_mappping_data: cps_contract_mappping_data,
        existing_file_ids: existing_file_ids,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *
   *   TODO ?? BARGE PURCHASE FINANCIALS COAL PROCUREMENT SERVICES
   *
   */

  get_barges_for_cps_costing(
    loginUserID,
    idToken,
    purchase_contract_id,
    cps_contract_id,
    cps_invoice_id,
    costing_type
  ) {
    let url = config.apiDomain + "/get_barges_for_cps_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchase_contract_id,
        cps_contract_id: cps_contract_id,
        cps_costing_id: cps_invoice_id,
        costing_type: costing_type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_cps_contract_data_based_on_barge_id(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_cps_contract_data_based_on_barge_id";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_barge_cps_costing(
    loginUserID,
    idToken,
    barge_id,
    cps_contract_id,
    barge_cps_costing_data,
    files
  ) {
    let url = config.apiDomain + "/add_barge_cps_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        cps_contract_id: cps_contract_id,
        barge_cps_costing_data: barge_cps_costing_data,
        files: files,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_cps_costings(
    loginUserID,
    idToken,
    purchase_contract_id,
    cps_contract_id
  ) {
    let url = config.apiDomain + "/get_barge_cps_costings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchase_contract_id,
        cps_contract_id: cps_contract_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_cps_costing(loginUserID, idToken, barge_cps_costing_id) {
    let url = config.apiDomain + "/get_barge_cps_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_cps_costing_id: barge_cps_costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_barge_cps_costing(
    loginUserID,
    idToken,
    barge_id,
    cps_contract_id,
    barge_cps_costing_data,
    files,
    existing_file_ids
  ) {
    let url = config.apiDomain + "/update_barge_cps_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
        cps_contract_id: cps_contract_id,
        barge_cps_costing_data: barge_cps_costing_data,
        files: files,
        existing_file_ids: existing_file_ids,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_barge_cps_costing(loginUserID, idToken, costing_id) {
    let url = config.apiDomain + "/delete_barge_cps_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        costing_id: costing_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // BARGE PURCHASE FINANCIAL CPS PAYMENTS

  add_barge_cps_costing_payment(
    loginUserID,
    idToken,
    barge_cps_costing_id,
    payment_posting_type,
    amount,
    date_of_payment,
    payment_due_date,
    invoice_no,
    description
  ) {
    let url = config.apiDomain + "/add_barge_cps_costing_payment";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_cps_costing_id: barge_cps_costing_id,
        payment_posting_type: payment_posting_type,
        amount: amount,
        date_of_payment: dateFormateToDB(date_of_payment),
        payment_due_date: dateFormateToDB(payment_due_date),
        invoice_no: invoice_no ? invoice_no.toUpperCase() : "",
        description: description ? description.toUpperCase() : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *
   *  TAX PERCENTAGES
   *
   **/
  add_tax_percentages(loginUserID, idToken, tax_percentages_data) {
    let url = config.apiDomain + "/add_tax_percentages";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        tax_percentages_data: tax_percentages_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_tax_percentages_data(loginUserID, idToken) {
    let url = config.apiDomain + "/get_tax_percentages_data";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_tax_percentages(loginUserID, idToken, tax_type, applicable_date) {
    let url = config.apiDomain + "/get_tax_percentages";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        tax_type: tax_type,
        applicable_date: dateFormateToDB(new Date()),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_email_config(loginUserID, idToken, action_types) {
    let url = config.apiDomain + "/update_email_config";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        action_types: action_types,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_email_config(loginUserID, idToken) {
    let url = config.apiDomain + "/get_email_config";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *    COAL PRICE INDEXES
   *
   */

  add_weekly_coal_index(reqParams) {
    let url = config.apiDomain + "/add_weekly_coal_index";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqParams),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_weekly_coal_indexes(reqParams) {
    let url = config.apiDomain + "/get_weekly_coal_indexes";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqParams),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_monthly_coal_index(reqParams) {
    let url = config.apiDomain + "/add_monthly_coal_index";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqParams),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_monthly_coal_indexes(reqParams) {
    let url = config.apiDomain + "/get_monthly_coal_indexes";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqParams),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  upload_coal_indexes_files(reqParams) {
    let url = config.apiDomain + "/upload_coal_indexes_files";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqParams),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_pending_payment_approvals(reqBody) {
    let url = config.apiDomain + "/get_pending_payment_approvals";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_approved_payments(reqBody) {
    let url = config.apiDomain + "/get_approved_payments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_payment_date(reqBody) {
    let url = config.apiDomain + "/update_payment_date";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_payment_due_date(reqBody) {
    let url = config.apiDomain + "/update_payment_due_date";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_invoice_details(request) {
    let url = config.apiDomain + "/update_invoice_details";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete_payment_postings(request) {
    let url = config.apiDomain + "/delete_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_payment_postings(request) {
    let url = config.apiDomain + "/add_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // GET BARGE QUANTITIES
  get_barges_quantity(request) {
    let url = config.apiDomain + "/get_barges_quantity";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_payment_postings(request) {
    let url = config.apiDomain + "/update_payment_postings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  // ADVANCE ADJUSTMENTS
  add_costing_adv_adjustments(request) {
    let url = config.apiDomain + "/add_costing_adv_adjustments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_sales_adv_adjustments(request) {
    let url = config.apiDomain + "/add_sales_adv_adjustments";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   *  MOTHER VESSEL DEAD FREIGHT
   *
   */
  get_mv_dead_freight_by_bno(request) {
    let url = config.apiDomain + "/get_mv_dead_freight_by_bno";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_mv_dead_freight(request) {
    let url = config.apiDomain + "/add_mv_dead_freight";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_mv_dead_freight(request) {
    let url = config.apiDomain + "/update_mv_dead_freight";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  approve_purchase_contract(request) {
    let url = config.apiDomain + "/approve_purchase_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  approve_sales_contract(request) {
    let url = config.apiDomain + "/approve_sales_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_contracts_for_approval(request) {
    let url = config.apiDomain + "/get_contracts_for_approval";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_pending_receivable_approvals(request) {
    let url = config.apiDomain + "/get_pending_receivable_approvals";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update_cps_contract_status(request) {
    let url = config.apiDomain + "/update_cps_contract_status";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  approve_cps_contract(request) {
    let url = config.apiDomain + "/approve_cps_contract";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_barge_financials(loginUserID, idToken, barge_id) {
    let url = config.apiDomain + "/get_barge_financials";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        barge_id: barge_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_mv_pur_fin_laparon_survey_charges(
    loginUserID,
    idToken,
    business_no_id,
    mv_laparon_charges_data
  ) {
    let url = config.apiDomain + "/add_mv_pur_fin_laparon_survey_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
        mv_laparon_charges_data: mv_laparon_charges_data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_laparon_charges(loginUserID, idToken, mv_laparon_charges_id) {
    let url = config.apiDomain + "/get_mv_laparon_charges";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        mv_laparon_charges_id: mv_laparon_charges_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pnbp_jasa_pbm_by_bno(loginUserID, idToken, business_no_id) {
    let url = config.apiDomain + "/get_mv_pnbp_jasa_pbm_by_bno";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify({
        login_user_id: loginUserID,
        idtoken: idToken,
        business_no_id: business_no_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  add_mv_pnbp_jasa_pbm(request) {
    let url = config.apiDomain + "/add_mv_pnbp_jasa_pbm";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  get_mv_pnbp_jasa_pbm(request) {
    let url = config.apiDomain + "/get_mv_pnbp_jasa_pbm";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_mv_pnbp_jasa_pbm(request) {
    let url = config.apiDomain + "/update_mv_pnbp_jasa_pbm";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  /**
   *
   *  BUDGET & COSTING
   *
   */
  get_cps_contracts_for_budget_costing(request) {
    let url = config.apiDomain + "/get_cps_contracts_for_budget_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  add_budget_costing(request) {
    let url = config.apiDomain + "/add_budget_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_budget_costings(request) {
    let url = config.apiDomain + "/get_budget_costings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_budget_costing(request) {
    let url = config.apiDomain + "/get_budget_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  update_budget_costing(request) {
    let url = config.apiDomain + "/update_budget_costing";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get_coal_sales_perf_costings(request) {
    let url = config.apiDomain + "/get_coal_sales_perf_costings";
    return fetch(url, {
      method: "Post",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default api;
