import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class Mv_Freight_Costing_Add extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vessel_owner: "0",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      quantity: "",
      price_pmt: "",
      total: "",
      remarks: "",
      isLoading: true,
      vendors: [],
      features: [],
      vat_percent: 10,
      vat_value: "",
      cost_to_company: "",
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Freight_Costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("379") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      // get vat value api
      const tax_type = "vat";
      await api
        .get_tax_percentages(loginUserID, idToken, tax_type)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.tax_percentages) {
                  this.setState({
                    vat_percent:
                      res.tax_percentages.length > 0
                        ? res.tax_percentages[0].tax_percentage
                        : 10,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      var vendors = [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
        >
          Please Select
        </MenuItem>,
      ];
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                for (var i of res.vendors) {
                  if (i.vendor_type === "Vessel Owner") {
                    vendors.push(
                      <MenuItem
                        style={{ fontFamily: "Poppins", fontSize: "14px" }}
                        value={i.id}
                        key={[i.id]}
                      >
                        {i.vendor_name}
                      </MenuItem>
                    );
                  }
                }
                this.setState({
                  vendors: vendors,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      try {
        // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNo;
      } catch (e) {
        //window.location.href = '/business-number-list';
      }

      //Get businessNumber API based on businessNumber
      await api
        .getBusinessNumber(businessNoID, loginUserID, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.business_number) {
                  if (
                    res.business_number.consignment_type === "Mother Vessel"
                  ) {
                    // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                    //Get Business No Operational Mother Vessel list
                    api
                      .getBusinessNumberMotherVessel(
                        businessNoID,
                        loginUserID,
                        idToken
                      )
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              if (res.idtoken)
                                this.Cookie.setIdTokenCookie(res.idtoken);
                              if (res.business_no_MV.length > 0) {
                                var motherVesselID = res.business_no_MV[0].id;
                                //Get Business No Operational Mother Vessel Details
                                api
                                  .getBusinessNoOpMVDetails(
                                    motherVesselID,
                                    loginUserID,
                                    idToken
                                  )
                                  .then((response) => {
                                    if (
                                      response.status >= 200 &&
                                      response.status < 300
                                    ) {
                                      response.json().then((res) => {
                                        if (res.business_no_MV) {
                                          this.setState({
                                            motherVesselID: motherVesselID,
                                            vessel_type: res.business_no_MV
                                              .vessel_type
                                              ? res.business_no_MV.vessel_type
                                              : "",
                                            vessel_final_quantity_in_mt: res
                                              .business_no_MV
                                              .vessel_final_quantity_in_mt
                                              ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                              : "",
                                            quantity: res.business_no_MV
                                              .vessel_final_quantity_in_mt
                                              ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                              : "",
                                          });
                                        }
                                      });
                                    }
                                  });
                              }
                            }
                          });
                        }
                      });
                  }
                  this.setState({
                    businessNo: res.business_number.business_no
                      ? res.business_number.business_no
                      : "",
                    mother_vessel_name: res.business_number.mother_vessel_name
                      ? res.business_number.mother_vessel_name
                      : "",
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }

    // document.title = config.documentTitle + "Mother Vessel Purchase Financial Freight Costing";
  }

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      invoice_no,
      invoice_date,
      ppn,
      vessel_owner,
      quantity,
      price_pmt,
      total,
      vat_percent,
      vat_value,
      cost_to_company,
      remarks,
    } = this.state;
    var billing_currency =
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
        ? "USD"
        : "IDR";
    var currency_xchg_rate = "1";
    // checking mandatory fields.
    if (vessel_owner === "0") {
      this.setState({
        vessel_owner_error: true,
      });
      alert("Please fill mandatory fields.");
      return;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    try {
      var businessNoID = this.props.businessNo;
      api
        .add_mv_pur_fin_freight_costing(
          loginUserID,
          idToken,
          businessNoID,
          invoice_no,
          invoice_date,
          ppn,
          vessel_owner,
          quantity,
          price_pmt,
          total,
          vat_percent,
          vat_value,
          cost_to_company,
          billing_currency,
          currency_xchg_rate,
          remarks,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              // if (res.code === '200') {
              //   this.props.onAddResponse(res.code)
              //   // window.location.href = "/mv-pur-fin-view-freight-costing/" + btoa(businessNoID);
              // }

              if (res.code === "200") {
                // window.location.href = '/mv-purchase-view-pre-shipment-inspection/' + btoa(businessNoID);
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse(true);
                    }, 2000);
                  }
                );
                //window.location.href = "/mv-purchase-view-floating-crane/" + btoa(businessNoID);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    } catch (e) {
      //window.location.href = '/business-number-list';
    }
  };

  vatCalculations = (vat) => {
    let base_price = (
      Number(this.state.quantity) * Number(this.state.price_pmt)
    ).toFixed(2);
    let vat_value = (Number(base_price) * Number(this.state.vat_percent)) / 100;
    if (vat) {
      vat_value = this.state.vat_value;
    }
    let cost_to_company = (Number(base_price) + Number(vat_value)).toFixed();
    this.setState({
      vat_value: vat_value,
      cost_to_company: cost_to_company,
    });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Add Freight Costing</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                <div className="row">
                  <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="row mb-3 ">
                      <div className="row">
                        <div className="col-lg-6 mb-1">
                          <label className="form_label mb-0">
                            Vessel Owner
                          </label>
                          <TextField
                            name="vessel_owner"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.vessel_owner}
                            error={this.state.vessel_owner_error}
                            select
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                vessel_owner: e.target.value,
                                vessel_owner_error: false,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            {this.state.vendors}
                          </TextField>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">Quantity</label>
                          <TextField
                            name="quantity"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.quantity}
                            fullWidth
                            placeholder="Quantity"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    quantity: e.target.value,
                                    total: (
                                      Number(e.target.value) *
                                      Number(this.state.price_pmt)
                                    ).toFixed(2),
                                  },
                                  () => this.vatCalculations()
                                );
                              }
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">
                            Price Price PMT
                          </label>
                          <TextField
                            name="price_pmt"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.price_pmt}
                            fullWidth
                            placeholder="Price per MT"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    price_pmt: e.target.value,
                                    total: (
                                      Number(this.state.quantity) *
                                      Number(e.target.value)
                                    ).toFixed(2),
                                  },
                                  () => this.vatCalculations()
                                );
                              }
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  className="adornment_background"
                                  position="end"
                                >
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                // padding: 0
                              },
                            }}
                          />
                          <p style={{ fontSize: "14px" }}>
                            Total:{" "}
                            {this.state.total
                              ? toLocaleString(this.state.total)
                              : 0}{" "}
                            {config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </p>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-2">VAT %</label>
                          <div>
                            {this.state.vat_percent
                              ? this.state.vat_percent
                              : "-"}
                          </div>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">VAT</label>
                          <TextField
                            name="vat_value"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.vat_value}
                            fullWidth
                            placeholder="Enter Value"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    vat_value: e.target.value,
                                  },
                                  () => this.vatCalculations("vat")
                                );
                              }
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-2">
                            Cost to Comapany
                          </label>
                          <div>
                            {this.state.cost_to_company
                              ? toLocaleString(this.state.cost_to_company)
                              : "-"}
                          </div>
                        </div>

                        <div className="col-lg-12 mb-1">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.remarks}
                            fullWidth
                            placeholder="Remarks"
                            multiline
                            rows={3}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {(this.state.features.includes('528') || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Mv_Pur_Fin_Freight_Costing"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
