import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Checkbox,
  MenuItem,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CoalCostingAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      coal_cost_billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      coal_cost_penality_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      coal_cost_coal_price_pmt_local_currency: "",
      invoice_no: "",
      invoice_date: null,
      invoice_percentage: 100,
      ppn: "",
      coal_cost_coal_quantity: "",
      coal_cost_coal_price_pmt: "",
      purchase_index_linked: "No",
      coal_cost_coal_price: "",
      coal_cost_gar_penality: "",
      coal_cost_ash_penality: "",
      coal_cost_sulphur_penality: "",
      coal_cost_gar_bonus: "",
      coal_cost_adjusted_coal_price: "",
      quality_total_sulphur_penality: "",
      coal_cost_pph22_export: "Yes",
      coal_cost_pph22_export_amount: "",
      coal_cost_pph22_export_percent: 1.5,
      coal_cost_pph22_export_value: "",

      coal_cost_pph22_percent: 1.5,
      coal_cost_pph22_percent_val: "",
      coal_cost_pph22_barge_quantity_in_mt: "",

      coal_cost_currency_xchg_rate: "",
      coal_cost_adjusted_coal_price_in_local_currency: "",

      paid_to_supplier: "",
      advance_adjustment: "No",
      adjustment_per_mt: "",
      advance_adjustment_value: "",
      purchase_currency: "",
      mother_vesselName: "",
      bargingBasePriceCurrency: "",

      advance_adjustment_barge_quantity: "",
      barge_quantity: "",

      // Advance Adjustment Rows
      advance_adjustments: [],

      coal_cost_pph22_export_value_local_cur: "",
      coal_cost_pph22_percent_val_local_cur: "",
      //
      purchasing_term: "Specs",

      quality_calorific_value_typical: "",
      quality_calorific_value_rejection: "",
      quality_calorific_value_basis: "",
      quality_calorific_value2_typical: "",
      quality_calorific_value2_rejection: "",
      quality_calorific_value2_basis: "",
      quality_calorific_value3_typical: "",
      quality_calorific_value3_rejection: "",
      quality_calorific_value3_basis: "",

      quality_ash_typical: "",
      quality_ash_rejection: "",
      quality_ash_basis: "",

      quality_total_sulphur_typical: "",
      quality_total_sulphur_rejection: "",
      quality_total_sulphur_basis: "",

      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_ash_actual: "",
      quality_total_sulphur_actual: "",

      // Advance Adjustment Value
      purchase_contract_id: "",
      features: [],
      purchaseBargeList: [],
      purchase_contracts: [],
      purchaseDataForMenu: [],
      bargeList: [],
      advanceContractsData: [],
      selectedPurchaseContractId: "",
      barge_nomination: "",
      total_barge_quantity: null,
      purchaseContractId: null,
      purchaseContractSelected: null,
      bonusCap: null,
      bonusCap2: null,
      bonusCap3: null,
      penalityCap: null,
      penalityCap2: null,
      penalityCap3: null,
      total_advance_adjustment_value: 0,
      display_pph22: 0,
      vat_applicable: "Yes",
      vat_amount: "",
      vat_percent: 10,
      vat_value: "",

      bargeSelectAll: false,
      enableBargeList: false,
      showDrawer: false,
      isLoading: true,
      business_no_drop_value: [],
      business_no_ids: [],
      activeStep: 0,
      snackBarErrorOpen: false,
      snackBarSuccessOpen: false,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Purchase_fin_coal_costing",
        },
      ],
      costing_files: [],
      pc_quality: 0,
      coal_index: {
        coal_index_type: "Weekly",
        index_ids: [],
        average_coal_index_price: 0,
        prorata_gar: "0",
        prorata_coal_index_price: 0,
        bonus_coal_index_pmt: "",
        penality_coal_index_pmt: "",
        derivered_coal_index_pmt: "",
        final_coal_index_pmt: "",
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("126") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var status = "";
    // Get business numbers
    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                //loading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    // get vat value api
    const tax_type = "vat";
    await api
      .get_tax_percentages(loginUserID, idToken, tax_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.tax_percentages) {
                this.setState({
                  vat_percent:
                    res.tax_percentages.length > 0
                      ? res.tax_percentages[0].tax_percentage
                      : 10,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    //Get purchase contracts
    await api
      .getPurchaseContracts(loginUserID, status, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                let purchaseDataforMenu = [];
                let filteredPContracts = [];
                if (this.props.costing_type === "Commercial") {
                  filteredPContracts = res.purchase_contract.filter(
                    (e, indx) => e.status === "Active" || e.status === "Freezed"
                  );
                } else {
                  filteredPContracts = res.purchase_contract.filter(
                    (e, indx) =>
                      e.status !== "Closed" && e.status !== "cancelled"
                  );
                }
                for (let i = 0; i < filteredPContracts.length; i++) {
                  purchaseDataforMenu.push({
                    name: filteredPContracts[i].contract_no,
                    value: filteredPContracts[i].id,
                    key: i,
                    vendor_name: filteredPContracts[i].vendor_name,
                  });
                }

                this.setState({
                  purchaseDataForMenu: purchaseDataforMenu,
                  //purchaseContractList: res.purchase_contract,
                  purchaseContractList: filteredPContracts,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "Barge Coal Costing";
  }

  searchBargesHandler = () => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var purchase_contract_id = this.state.purchase_contract_id
      ? this.state.purchase_contract_id
      : "";
    if (purchase_contract_id === null || purchase_contract_id === "") {
      alert("Select purchase contract");
      this.setState({
        purchaseContractError: true,
        isLoading: false,
      });
      return;
    } else {
      var barge_nomination = this.state.barge_nomination;
      // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
      var costing_type = this.props.costing_type;
      let business_no = this.state.business_no_ids;
      api
        .get_coal_costing_barges(
          loginUserID,
          idToken,
          purchase_contract_id,
          business_no,
          costing_type,
          barge_nomination
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.purchase_contracts) {
                  if (res.purchase_contracts[0].barges.length > 0) {
                    this.setState({
                      enableBargeList: true,
                    });
                  }
                  this.setState(
                    {
                      purchaseBargeList: res.purchase_contracts,
                    },
                    () => {
                      this.getCommercialData();
                    }
                  );
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  getCommercialData() {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var purchase_contract_id = this.state.purchase_contract_id;
    // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
    var costing_type = this.props.costing_type;
    var barge_nomination = this.state.barge_nomination;
    if (costing_type === "Commercial") {
      costing_type = "Performa";
      let business_no = this.state.business_no_ids;
      api
        .get_coal_costing_barges(
          loginUserID,
          idToken,
          purchase_contract_id,
          business_no,
          costing_type,
          barge_nomination
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.purchase_contracts) {
                  var purchase_contracts = res.purchase_contracts;
                  for (let i = 0; i < purchase_contracts.length; i++) {
                    let barges = [];
                    let bargeData = [];
                    let purchase_contracts_barges =
                      purchase_contracts[i].barges;
                    let com_purchase_contracts_barges =
                      this.state.purchaseBargeList[i].barges;
                    let newData = [];
                    let newData1 = [];
                    for (let k = 0; k < purchase_contracts_barges.length; k++) {
                      purchase_contracts_barges[k].com_barge_pur_fin_id =
                        com_purchase_contracts_barges[k].barge_pur_fin_id;
                      purchase_contracts_barges[k].com_quality_results =
                        com_purchase_contracts_barges[k].quality_results;
                      purchase_contracts_barges[k].com_purchasing_term =
                        com_purchase_contracts_barges[k].purchasing_term;
                      let id = purchase_contracts_barges[k].barge_pur_fin_id;
                      if (id !== null) {
                        barges.push(purchase_contracts_barges[k]);
                      } else {
                        bargeData.push(purchase_contracts_barges[k]);
                      }
                    }
                    var result = barges.reduce(function (r, a) {
                      r[a.barge_pur_fin_id] = r[a.barge_pur_fin_id] || [];
                      r[a.barge_pur_fin_id].push(a);
                      return r;
                    }, Object.create(null));
                    for (let l = 0; l < Object.keys(result).length; l++) {
                      newData.push({
                        new_barges: result[Object.keys(result)[l]],
                      });
                    }

                    var result1 = bargeData.map((r, a) => {
                      return r;
                    });
                    for (let l = 0; l < Object.keys(result1).length; l++) {
                      newData1.push({
                        new_barges: [result1[Object.keys(result1)[l]]],
                      });
                    }
                    purchase_contracts[i].new_barges = newData.concat(newData1);
                  }
                  this.setState({
                    purchase_contracts: purchase_contracts,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async callingAsync(bargeId) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (bargeId) {
      // var bargeID = decodeURIComponent(window.atob(this.props.match.params.bargeID));
      var bargeID = bargeId;

      // Get All Purchase Specification data based on Barge ID.
      await api
        .getAllPurchaseSpecsByBargeID(loginUserID, idToken, bargeID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  purchasing_term: res.purchasing_term,
                });
                if (res.pcq_typical_specs) {
                  this.setState({
                    garActiualValue: res.pcq_typical_specs
                      .qality_calorific_value_actual
                      ? res.pcq_typical_specs.qality_calorific_value_actual
                      : null,
                    garTypicalValue: res.pcq_typical_specs
                      .quality_calorific_value_typical
                      ? res.pcq_typical_specs.quality_calorific_value_typical
                      : null,
                    ashPenality: res.pcq_typical_specs.quality_ash_penality
                      ? res.pcq_typical_specs.quality_ash_penality
                      : "",
                    sulphurPenality: res.pcq_typical_specs
                      .quality_total_sulphur_penality
                      ? res.pcq_typical_specs.quality_total_sulphur_penality
                      : "",
                    bonusCap: res.pcq_typical_specs
                      .quality_calorific_value_bonus_cap
                      ? res.pcq_typical_specs.quality_calorific_value_bonus_cap
                      : "",
                    bonusCap2: res.pcq_typical_specs
                      .quality_calorific_value2_bonus_cap
                      ? res.pcq_typical_specs.quality_calorific_value2_bonus_cap
                      : "",
                    bonusCap3: res.pcq_typical_specs
                      .quality_calorific_value3_bonus_cap
                      ? res.pcq_typical_specs.quality_calorific_value3_bonus_cap
                      : "",
                    penalityCap: res.pcq_typical_specs
                      .quality_calorific_value_penality_cap
                      ? res.pcq_typical_specs
                          .quality_calorific_value_penality_cap
                      : "",
                    penalityCap2: res.pcq_typical_specs
                      .quality_calorific_value2_penality_cap
                      ? res.pcq_typical_specs
                          .quality_calorific_value2_penality_cap
                      : "",
                    penalityCap3: res.pcq_typical_specs
                      .quality_calorific_value3_penality_cap
                      ? res.pcq_typical_specs
                          .quality_calorific_value3_penality_cap
                      : "",

                    quality_calorific_value_typical:
                      res.pcq_typical_specs.quality_calorific_value_typical,
                    quality_calorific_value_rejection:
                      res.pcq_typical_specs.quality_calorific_value_rejection,
                    quality_calorific_value_basis:
                      res.pcq_typical_specs.quality_calorific_value_basis,
                    quality_calorific_value2_typical:
                      res.pcq_typical_specs.quality_calorific_value2_typical,
                    quality_calorific_value2_rejection:
                      res.pcq_typical_specs.quality_calorific_value2_rejection,
                    quality_calorific_value2_basis:
                      res.pcq_typical_specs.quality_calorific_value2_basis,
                    quality_calorific_value3_typical:
                      res.pcq_typical_specs.quality_calorific_value3_typical,
                    quality_calorific_value3_rejection:
                      res.pcq_typical_specs.quality_calorific_value3_rejection,
                    quality_calorific_value3_basis:
                      res.pcq_typical_specs.quality_calorific_value3_basis,

                    quality_ash_typical:
                      res.pcq_typical_specs.quality_ash_typical,
                    quality_ash_rejection:
                      res.pcq_typical_specs.quality_ash_rejection,
                    quality_ash_basis: res.pcq_typical_specs.quality_ash_basis,

                    quality_total_sulphur_typical:
                      res.pcq_typical_specs.quality_total_sulphur_typical,
                    quality_total_sulphur_rejection:
                      res.pcq_typical_specs.quality_total_sulphur_rejection,
                    quality_total_sulphur_basis:
                      res.pcq_typical_specs.quality_total_sulphur_basis,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      // get Barge Purchase Quality Results By Barge ID
      await api
        .getQualityResultsByBargeID(loginUserID, idToken, bargeID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_purchase_qualities) {
                  this.setState({
                    barge_pur_quality_id: res.barge_purchase_qualities.id,
                    quality_calorific_value_actual:
                      res.barge_purchase_qualities
                        .quality_calorific_value_actual,
                    quality_calorific_value2_actual:
                      res.barge_purchase_qualities
                        .quality_calorific_value2_actual,
                    quality_calorific_value3_actual:
                      res.barge_purchase_qualities
                        .quality_calorific_value3_actual,
                    quality_ash_actual:
                      res.barge_purchase_qualities.quality_ash_actual,
                    quality_total_sulphur_actual:
                      res.barge_purchase_qualities.quality_total_sulphur_actual,
                  });
                } else {
                  //alert('Unexpected error occured. Please contact administrator.');
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      await api
        .get_purchase_contract_data_by_barge_id(loginUserID, idToken, bargeID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  purchase_currency:
                    res.purchase_contract_data.purchase_currency,
                  purchase_price_pmt:
                    res.purchase_contract_data.purchase_price_pmt,
                  coal_cost_coal_price_pmt:
                    res.purchase_contract_data.purchase_price_pmt,
                  pc_quality: res.purchase_contract_data.pc_quality,
                  purchase_type: res.purchase_contract_data.purchase_type,
                  // sales_price_pmt: res.sales_price_pmt,
                  // sales_type: res.sales_type,
                });
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      // Calling Get Business Number Operation Barge Details API
      // const businessNumberBargeDataResponse = await api.get_business_no_barge(bargeID, loginUserID, idToken);
      await api.get_barge(loginUserID, idToken, bargeID).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              var barge_quantity = this.state.coal_cost_coal_quantity;
              if (res.barges) {
                // barge_quantity = res.barges.barge_quantity_in_mt;
                // if (barge_quantity === null || Number(barge_quantity) <= 0) {
                //   barge_quantity = res.barges.barge_planned_quantity_in_mt;
                // }
                // var purchaseType = res.barges.purchase_type;

                // if (purchaseType === "FAS Anchorage" && res.barges.redraft_quantity !== null && res.barges.quantity_difference_account === "Supplier") {
                //   barge_quantity = res.barges.redraft_quantity
                // }

                //Get purchase Coal Price.
                api
                  .get_purchase_contract_data_by_barge_id(
                    loginUserID,
                    idToken,
                    bargeID
                  )
                  .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                      response.json().then((res) => {
                        if (res.code === "200") {
                          var garPenality = "";
                          var garBonus = "";
                          var ashPenality = "";
                          var sulphurPenality = "";

                          let garActiualValue = this.state.garActiualValue;
                          let garTypicalValue = this.state.garTypicalValue;
                          let bonusCap = this.state.bonusCap;
                          let penalityCap = this.state.penalityCap;
                          // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
                          var costing_type = this.props.costing_type;
                          if (
                            this.state.purchasing_term === "Specs" &&
                            costing_type === "Commercial"
                          ) {
                            if (
                              garActiualValue !== null &&
                              garTypicalValue !== null &&
                              garActiualValue !== "" &&
                              garTypicalValue !== ""
                            ) {
                              // if (Number(garActiualValue) > Number(bonusCap)) {
                              //   garActiualValue = bonusCap;
                              // } else {
                              //   garActiualValue = penalityCap;
                              // }
                              if (
                                Number(garActiualValue) >
                                Number(garTypicalValue)
                              ) {
                                if (bonusCap !== null && bonusCap !== "") {
                                  if (
                                    Number(garActiualValue) > Number(bonusCap)
                                  ) {
                                    garActiualValue = bonusCap;
                                  }
                                }
                              }

                              if (
                                Number(garActiualValue) <
                                Number(garTypicalValue)
                              ) {
                                if (
                                  penalityCap !== null &&
                                  penalityCap !== ""
                                ) {
                                  if (
                                    Number(garActiualValue) <
                                    Number(penalityCap)
                                  ) {
                                    garActiualValue = penalityCap;
                                  }
                                }
                              }

                              if (
                                Number(garActiualValue) >
                                Number(garTypicalValue)
                              ) {
                                garBonus =
                                  ((Number(garActiualValue) -
                                    Number(garTypicalValue)) /
                                    Number(garTypicalValue)) *
                                  Number(
                                    res.purchase_contract_data
                                      .purchase_price_pmt
                                  );
                              }
                              if (
                                Number(garTypicalValue) >
                                Number(garActiualValue)
                              ) {
                                garPenality =
                                  ((Number(garTypicalValue) -
                                    Number(garActiualValue)) /
                                    Number(garTypicalValue)) *
                                  Number(
                                    res.purchase_contract_data
                                      .purchase_price_pmt
                                  );
                              }
                            }
                            if (
                              this.state.quality_ash_actual !== null &&
                              this.state.quality_ash_actual !== "" &&
                              this.state.quality_ash_typical !== null &&
                              this.state.quality_ash_typical !== ""
                            ) {
                              if (
                                Number(this.state.quality_ash_actual) >
                                  Number(this.state.quality_ash_typical) &&
                                this.state.ashPenality !== null &&
                                this.state.ashPenality !== ""
                              ) {
                                ashPenality =
                                  Number(this.state.ashPenality) *
                                  ((Number(this.state.quality_ash_actual) -
                                    Number(this.state.quality_ash_typical)) /
                                    1);
                              }
                            }
                            if (
                              this.state.quality_total_sulphur_actual !==
                                null &&
                              this.state.quality_total_sulphur_actual !== "" &&
                              this.state.quality_total_sulphur_typical !==
                                null &&
                              this.state.quality_total_sulphur_typical !== ""
                            ) {
                              if (
                                Number(
                                  this.state.quality_total_sulphur_actual
                                ) >
                                  Number(
                                    this.state.quality_total_sulphur_typical
                                  ) &&
                                this.state.sulphurPenality !== null &&
                                this.state.sulphurPenality !== ""
                              ) {
                                sulphurPenality =
                                  Number(this.state.sulphurPenality) *
                                  ((Number(
                                    this.state.quality_total_sulphur_actual
                                  ) -
                                    Number(
                                      this.state.quality_total_sulphur_typical
                                    )) /
                                    0.1);
                              }
                            }
                          }

                          var coal_cost_gar_bonus = "";
                          var coal_cost_gar_penality = "";
                          var coal_cost_ash_penality = "";
                          var coal_cost_sulphur_penality = "";
                          if (
                            garBonus !== "" &&
                            (this.state.coal_cost_gar_bonus === null ||
                              this.state.coal_cost_gar_bonus === "")
                          ) {
                            coal_cost_gar_bonus = garBonus.toFixed(2);
                            this.setState({
                              coal_cost_gar_bonus: Number(coal_cost_gar_bonus),
                            });
                          }
                          if (
                            garPenality !== "" &&
                            (this.state.coal_cost_gar_penality === null ||
                              this.state.coal_cost_gar_penality === "")
                          ) {
                            coal_cost_gar_penality = garPenality.toFixed(2);
                            this.setState({
                              coal_cost_gar_penality: Number(
                                coal_cost_gar_penality
                              ),
                            });
                          }
                          if (
                            ashPenality !== "" &&
                            (this.state.coal_cost_ash_penality === null ||
                              this.state.coal_cost_ash_penality === "")
                          ) {
                            coal_cost_ash_penality = ashPenality.toFixed(2);
                            this.setState({
                              coal_cost_ash_penality: Number(
                                coal_cost_ash_penality
                              ),
                            });
                          }
                          if (
                            sulphurPenality !== "" &&
                            (this.state.coal_cost_sulphur_penality === null ||
                              this.state.coal_cost_sulphur_penality === "")
                          ) {
                            coal_cost_sulphur_penality =
                              sulphurPenality.toFixed(2);
                            this.setState({
                              coal_cost_sulphur_penality: Number(
                                coal_cost_sulphur_penality
                              ),
                            });
                          }

                          let coal_cost_coal_price_pmt =
                            res.purchase_contract_data.purchase_price_pmt;
                          var coal_cost_coal_price = "";
                          if (barge_quantity !== null && barge_quantity !== 0) {
                            coal_cost_coal_price = (
                              Number(barge_quantity) *
                              Number(this.state.purchase_price_pmt)
                            ).toFixed(2);
                          }

                          var coal_cost_currency_xchg_rate =
                            this.state.coal_cost_currency_xchg_rate;
                          if (coal_cost_currency_xchg_rate === "") {
                            coal_cost_currency_xchg_rate = 1;
                          }
                          var purchase_currency =
                            res.purchase_contract_data.purchase_currency;

                          let coal_cost_gar_bonus_new = (
                            Number(coal_cost_gar_bonus) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          let coal_cost_gar_penality_new = (
                            Number(coal_cost_gar_penality) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          let coal_cost_ash_penality_new = (
                            Number(coal_cost_ash_penality) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          let coal_cost_sulphur_penality_new = (
                            Number(coal_cost_sulphur_penality) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          let purchase_price_pmt = (
                            Number(coal_cost_coal_price_pmt) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);

                          var coal_cost_adjusted_coal_price_pmt = Number(
                            Number(purchase_price_pmt) +
                              Number(coal_cost_gar_bonus_new) -
                              Number(coal_cost_gar_penality_new) -
                              Number(coal_cost_ash_penality_new) -
                              Number(coal_cost_sulphur_penality_new)
                          ).toFixed(2);

                          // var coal_cost_adjusted_coal_price_pmt = Number(Number(res.purchase_contract_data.purchase_price_pmt) + Number(this.state.coal_cost_gar_bonus) - Number(this.state.coal_cost_gar_penality) - Number(this.state.coal_cost_ash_penality) - Number(this.state.coal_cost_sulphur_penality)).toFixed(2);
                          var coal_cost_adjusted_coal_price = Number(
                            Number(barge_quantity) *
                              Number(coal_cost_adjusted_coal_price_pmt)
                          ).toFixed(2);
                          var coal_cost_adjusted_coal_price_in_local_currency =
                            Number(coal_cost_adjusted_coal_price).toFixed(0);

                          var coal_cost_pph22_export_percent = 1.5;
                          var coal_cost_pph22_export_value = Number(
                            (Number(
                              coal_cost_adjusted_coal_price_in_local_currency
                            ) *
                              Number(coal_cost_pph22_export_percent)) /
                              100
                          ).toFixed(0);

                          var coal_cost_pph22_percent_val = "";

                          var vat_amount =
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? 0
                              : Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                );
                          var vat_percent = this.state.vat_percent
                            ? this.state.vat_percent
                            : 10;
                          var vat_value =
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? 0
                              : (Number(vat_amount) * Number(vat_percent)) /
                                100;
                          var paid_to_supplier = (
                            Number(barge_quantity) *
                              Number(coal_cost_adjusted_coal_price_pmt) +
                            Number(vat_value) -
                            Number(coal_cost_pph22_percent_val) -
                            Number(coal_cost_pph22_export_value)
                          ).toFixed(2);

                          var coal_cost_pph22_export_value_local_cur = "";
                          var coal_cost_pph22_percent_val_local_cur = "";
                          if (
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              purchase_currency !== "IDR") ||
                            ((config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL") &&
                              purchase_currency !== "USD")
                          ) {
                            if (
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) {
                              coal_cost_pph22_export_value_local_cur =
                                coal_cost_pph22_export_value;
                              paid_to_supplier = Number(
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                  Number(vat_value) -
                                  Number(coal_cost_pph22_export_value_local_cur)
                              ).toFixed(2);
                            } else {
                              let total_pph22_local_amount =
                                Number(barge_quantity) *
                                Number(coal_cost_adjusted_coal_price_pmt);
                              coal_cost_pph22_percent_val = Number(
                                (Number(total_pph22_local_amount) *
                                  Number(this.state.coal_cost_pph22_percent)) /
                                  100
                              );
                              coal_cost_pph22_percent_val_local_cur = Number(
                                coal_cost_pph22_percent_val
                              ).toFixed(0);

                              coal_cost_pph22_export_value = Number(
                                (Number(coal_cost_adjusted_coal_price) *
                                  Number(coal_cost_pph22_export_percent)) /
                                  100
                              );
                              coal_cost_pph22_export_value_local_cur = Number(
                                coal_cost_pph22_export_value
                              ).toFixed(0);

                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                Number(vat_value) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur)
                              ).toFixed(2);
                            }
                          } else {
                            if (
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) {
                              coal_cost_pph22_export_value_local_cur =
                                coal_cost_pph22_export_value;
                              paid_to_supplier = Number(
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                  Number(vat_value) -
                                  Number(coal_cost_pph22_export_value_local_cur)
                              ).toFixed(2);
                            } else {
                              let total_pph22_local_amount =
                                Number(barge_quantity) *
                                Number(coal_cost_adjusted_coal_price_pmt);
                              coal_cost_pph22_percent_val = Number(
                                (Number(total_pph22_local_amount) *
                                  Number(this.state.coal_cost_pph22_percent)) /
                                  100
                              );
                              coal_cost_pph22_percent_val_local_cur = Number(
                                coal_cost_pph22_percent_val
                              ).toFixed(0);

                              coal_cost_pph22_export_value = Number(
                                (Number(coal_cost_adjusted_coal_price) *
                                  Number(coal_cost_pph22_export_percent)) /
                                  100
                              );
                              coal_cost_pph22_export_value_local_cur = Number(
                                coal_cost_pph22_export_value
                              ).toFixed(0);

                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                Number(vat_value) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur)
                              ).toFixed(2);
                            }
                          }
                          var display_pph22 =
                            Number(coal_cost_pph22_percent_val_local_cur) +
                            Number(coal_cost_pph22_export_value_local_cur);
                          // if((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                          //   display_pph22= Number(coal_cost_pph22_export_value_local_cur);
                          // }
                          let coal_cost_coal_price_pmt_local_currency = (
                            Number(
                              res.purchase_contract_data.purchase_price_pmt
                            ) * Number(coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          if (
                            (config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                            this.state.coal_cost_billing_currency === "IDR"
                          ) {
                            coal_cost_coal_price_pmt_local_currency = (
                              Number(
                                res.purchase_contract_data.purchase_price_pmt
                              ) * Number(coal_cost_currency_xchg_rate)
                            ).toFixed(0);
                            coal_cost_adjusted_coal_price_pmt = Number(
                              coal_cost_adjusted_coal_price_pmt
                            ).toFixed(0);
                            coal_cost_adjusted_coal_price = Number(
                              coal_cost_adjusted_coal_price
                            ).toFixed(0);
                          }

                          this.setState(
                            {
                              // coal_cost_coal_quantity: barge_quantity ? barge_quantity : '',
                              coal_cost_coal_price: Number(
                                Number(coal_cost_coal_price) *
                                  Number(coal_cost_currency_xchg_rate)
                              ).toFixed(2),
                              purchase_price_pmt:
                                res.purchase_contract_data.purchase_price_pmt,
                              purchase_currency:
                                res.purchase_contract_data.purchase_currency,
                              pc_quality: res.purchase_contract_data.pc_quality,
                              coal_cost_coal_price_pmt: Number(
                                res.purchase_contract_data.purchase_price_pmt
                              ).toFixed(4),
                              coal_cost_coal_price_pmt_local_currency:
                                coal_cost_coal_price_pmt_local_currency,
                              coal_cost_currency_xchg_rate:
                                coal_cost_currency_xchg_rate !== 1
                                  ? Number(coal_cost_currency_xchg_rate)
                                  : "",

                              coal_cost_adjusted_coal_price:
                                coal_cost_adjusted_coal_price,
                              coal_cost_adjusted_coal_price_pmt:
                                coal_cost_adjusted_coal_price_pmt,
                              coal_cost_adjusted_coal_price_in_local_currency:
                                coal_cost_adjusted_coal_price_in_local_currency,
                              vat_amount: vat_amount,
                              vat_percent: vat_percent,
                              vat_value: vat_value,
                              paid_to_supplier: paid_to_supplier,

                              coal_cost_pph22_barge_quantity_in_mt:
                                barge_quantity ? Number(barge_quantity) : "",
                              coal_cost_pph22_coal_price_per_mt: Number(
                                coal_cost_adjusted_coal_price_pmt
                              ),
                              coal_cost_pph22_percent_val: Number(
                                coal_cost_pph22_percent_val
                              ).toFixed(0),
                              coal_cost_pph22_percent_val_local_cur:
                                coal_cost_pph22_percent_val_local_cur,
                              coal_cost_pph22_percent_val_local_cur_new:
                                coal_cost_pph22_percent_val_local_cur,

                              coal_cost_pph22_export_amount: Number(
                                coal_cost_adjusted_coal_price
                              ),
                              coal_cost_pph22_export_percent:
                                coal_cost_pph22_export_percent,
                              coal_cost_pph22_export_value: Number(
                                coal_cost_pph22_export_value
                              ).toFixed(0),
                              coal_cost_pph22_export_value_local_cur: Number(
                                coal_cost_pph22_export_value_local_cur
                              ).toFixed(0),
                              coal_cost_pph22_export_value_local_cur_new:
                                Number(
                                  coal_cost_pph22_export_value_local_cur
                                ).toFixed(0),

                              display_pph22: Number(display_pph22).toFixed(0),
                            },
                            () => {
                              this.onAdvanceAdjustmentsCal();
                            }
                          );
                        }
                      });
                    }
                  });

                this.setState({
                  // businessNo: res.barges.business_no ? res.barges.business_no : '',
                  // mother_vesselName: res.barges.mother_vessel_name ? res.barges.mother_vessel_name : '',
                  bargeNomination: res.barges.barge_nomination
                    ? res.barges.barge_nomination
                    : "",
                  // businessNoID: res.barges.business_no_id,

                  barge_quantity: barge_quantity,
                  // advance_adjustment_value: Number(redraftQuantity).toFixed(4),//res.barges.redraft_quantity ? res.barges.redraft_quantity:'',
                  sales_type: res.barges.sales_type,
                  purchase_type: res.barges.purchase_type,
                  // purchase_index_linked: res.barges.is_index_linked,
                  pc_quality: res.barges.pc_quality,

                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            }
          });
        }
      });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Invalid barge id.",
      });
    }
  }

  submitHandler = (e) => {
    // return;
    this.setState({ isLoading: true });
    var {
      invoice_percentage,
      coal_cost_coal_quantity,
      coal_cost_coal_price,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_adjusted_coal_price,
      coal_cost_currency_xchg_rate,
      coal_cost_adjusted_coal_price_in_local_currency,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_pph22_percent_val,
      coal_cost_pph22_export,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export_value,
      advance_adjustments,
      paid_to_supplier,
      coal_cost_pph22_percent_val_local_cur,
      coal_cost_pph22_export_value_local_cur,
      coal_cost_billing_currency,
      coal_cost_penality_currency,
      vat_applicable,
      vat_percent,
      vat_amount,
      vat_value,
      invoice_date,
      ppn,
      invoice_no,
      costing_files,
    } = this.state;

    if (coal_cost_gar_bonus === "") {
      coal_cost_gar_bonus = null;
    }
    if (coal_cost_gar_penality === "") {
      coal_cost_gar_penality = null;
    }
    if (coal_cost_ash_penality === "") {
      coal_cost_ash_penality = null;
    }
    if (coal_cost_sulphur_penality === "") {
      coal_cost_sulphur_penality = null;
    }
    // if (purchase_index_linked === "Yes") {
    //   if (coal_cost_coal_price_pmt === "") {
    //     this.setState({ isLoading: false });
    //     alert("Coal Price per MT cannot be empty");
    //     return;
    //   }
    // }
    if (
      coal_cost_currency_xchg_rate === "" ||
      coal_cost_currency_xchg_rate === null
    ) {
      coal_cost_currency_xchg_rate = "1";
      coal_cost_adjusted_coal_price_in_local_currency =
        coal_cost_adjusted_coal_price;
    }
    coal_cost_pph22_percent_val = coal_cost_pph22_percent_val_local_cur;
    coal_cost_pph22_export_value = coal_cost_pph22_export_value_local_cur;

    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_amount = "";
      coal_cost_pph22_export_percent = "";
      coal_cost_pph22_export_value = "";
    }

    if (vat_applicable === "No") {
      vat_amount = "";
      //vat_percent = "";
      vat_value = "";
    }

    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      coal_cost_pph22_barge_quantity_in_mt = "";
      coal_cost_pph22_coal_price_per_mt = "";
      coal_cost_pph22_percent_val = "";
      coal_cost_pph22_percent = "";
      vat_applicable = "No";
      vat_amount = "";
      //vat_percent = "";
      vat_value = "";
    }

    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      coal_cost_currency_xchg_rate = this.state.currency_xchg_rate;
    }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = files_data.filter((e) => e.file_content !== "");
    const costing = costing_files.filter((e) => e.file_content !== "");

    const {
      coal_index_type,
      index_ids,
      average_coal_index_price,
      prorata_gar,
      prorata_coal_index_price,
      bonus_coal_index_pmt,
      penality_coal_index_pmt,
      derivered_coal_index_pmt,
      final_coal_index_pmt,
    } = this.state.coal_index;
    // Add barge purchase financial api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var costing_type = this.props.costing_type;
    var bargeID = this.state.bargeList?.toString();
    var purchaseContractId = this.state.purchase_contract_id;
    if (this.state.bargeList.length > 0) {
      api
        .addBargeCoalCostings(
          loginUserID,
          idToken,
          bargeID,
          purchaseContractId,
          invoice_no,
          invoice_date,
          ppn,
          coal_index_type,
          index_ids,
          average_coal_index_price,
          prorata_gar,
          prorata_coal_index_price,
          bonus_coal_index_pmt,
          penality_coal_index_pmt,
          derivered_coal_index_pmt,
          final_coal_index_pmt,
          coal_cost_coal_quantity,
          coal_cost_coal_price_pmt,
          coal_cost_billing_currency,
          coal_cost_penality_currency,
          coal_cost_coal_price,
          coal_cost_gar_bonus,
          coal_cost_gar_penality,
          coal_cost_ash_penality,
          coal_cost_sulphur_penality,
          coal_cost_adjusted_coal_price_pmt,
          coal_cost_adjusted_coal_price,
          coal_cost_currency_xchg_rate,
          coal_cost_adjusted_coal_price_in_local_currency,
          coal_cost_pph22_barge_quantity_in_mt,
          coal_cost_pph22_coal_price_per_mt,
          coal_cost_pph22_percent,
          coal_cost_pph22_percent_val,
          coal_cost_pph22_export,
          coal_cost_pph22_export_amount,
          coal_cost_pph22_export_percent,
          coal_cost_pph22_export_value,
          advance_adjustments,
          paid_to_supplier,
          costing_type,
          vat_applicable,
          invoice_percentage,
          vat_amount,
          vat_percent,
          vat_value,
          content_files,
          costing
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                let comboID = res.id + "$#" + purchaseContractId;
                this.props.onAddResponse(comboID);
                this.setState({
                  successMsg: res.message,
                  errorMsg: "",
                });
                // window.location.href = '/barge-list';
              } else if (res.code === "601") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                });
              }
            });
          }
        });
    } else {
      alert("Please select altleast one barge to submit");
      this.setState({
        isLoading: false,
        errorMsg: "Please select altleast one barge to submit",
        successMsg: "",
      });
    }
  };

  handleRadioButtons = (e) => {
    if (e.target.value === "No") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_pph22_export_value: 0,
          coal_cost_pph22_export_value_local_cur: 0,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  onBargesCheckCommercial = (bargeId, purchaseId, checked, barge) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    this.setState({
      selectedPurchaseContractId: purchaseId,
      isLoading: true,
    });
    let bargeIds = [];
    var bargeQuantity = 0;
    for (let n = 0; n < barge.length; n++) {
      bargeIds.push(barge[n].barge_id);
      if (
        barge[n].barge_quantity_in_mt === null ||
        barge[n].barge_quantity_in_mt <= 0
      ) {
        bargeQuantity += Number(barge[n].planned_quantity);
      } else {
        bargeQuantity += Number(barge[n].barge_quantity_in_mt);
      }
    }
    var total_barge_quantity = "";
    if (
      this.state.selectedPurchaseContractId === "" ||
      this.state.selectedPurchaseContractId === purchaseId
    ) {
      if (!!checked) {
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) + Number(bargeQuantity)
        ).toFixed(3);
        // let bargeList = [...this.state.bargeList, bargeId];
        let bargeList = this.state.bargeList.concat(bargeIds);
        this.setState({
          selectedPurchaseContractId: purchaseId,
          bargeList: bargeList,
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length === 0) {
          // Calling Get Business Number Operation Barge Details API
          api.get_barge(loginUserID, idToken, bargeId).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  var purchaseType = res.barges.purchase_type;
                  let bargeQuantityNew = 0;
                  for (let n = 0; n < barge.length; n++) {
                    if (
                      purchaseType === "FAS Anchorage" &&
                      barge[n].redraft_quantity !== null &&
                      res.barges.quantity_difference_account === "Supplier"
                    ) {
                      bargeQuantityNew += Number(barge[n].redraft_quantity);
                    }
                  }
                  if (bargeQuantityNew === 0) {
                    bargeQuantityNew = bargeQuantity;
                  }
                  total_barge_quantity = (
                    Number(this.state.coal_cost_coal_quantity) +
                    Number(bargeQuantityNew)
                  ).toFixed(3);
                  let coa_index_data = {
                    ...this.state.coal_index,
                    coal_index_type: res.barges.coal_index_type,
                    index_ids: res.barges.index_ids,
                    average_coal_index_price:
                      res.barges.average_coal_index_price,
                    prorata_gar: res.barges.prorata_gar,
                    prorata_coal_index_price:
                      res.barges.prorata_coal_index_price,
                    bonus_coal_index_pmt: res.barges.bonus_coal_index_pmt,
                    penality_coal_index_pmt: res.barges.penality_coal_index_pmt,
                    derivered_coal_index_pmt:
                      res.barges.derivered_coal_index_pmt,
                    final_coal_index_pmt: res.barges.final_coal_index_pmt,
                  };
                  this.setState(
                    {
                      coal_index: coa_index_data,
                      selectedPurchaseContractId: purchaseId,
                      bargeList: bargeList,
                      total_barge_quantity:
                        Number(total_barge_quantity).toFixed(3),
                      coal_cost_coal_quantity:
                        Number(total_barge_quantity).toFixed(3),
                      quantity_difference_account:
                        res.barges.quantity_difference_account,
                      purchaseType: purchaseType,
                      // purchase_index_linked: res.barges.is_index_linked,
                      pc_quality: res.barges.pc_quality,
                      isLoading: true,
                    },
                    () => {
                      this.callingAsync(bargeId);
                    }
                  );
                }
              });
            }
          });
        } else if (this.state.bargeList.length > 0) {
          let bargeQuantityNew = 0;
          for (let n = 0; n < barge.length; n++) {
            if (
              this.state.purchaseType === "FAS Anchorage" &&
              barge[n].redraft_quantity !== null &&
              this.state.quantity_difference_account === "Supplier"
            ) {
              bargeQuantityNew += Number(barge[n].redraft_quantity);
            }
          }
          if (bargeQuantityNew === 0) {
            bargeQuantityNew = bargeQuantity;
          }

          // if (this.state.purchaseType === "FAS Anchorage" && barge.redraft_quantity !== null && this.state.quantity_difference_account === "Supplier") {
          //   bargeQuantity = barge.redraft_quantity
          // }
          total_barge_quantity = (
            Number(this.state.coal_cost_coal_quantity) +
            Number(bargeQuantityNew)
          ).toFixed(3);
          let value = total_barge_quantity;
          this.setState(
            {
              bargeList: bargeList,
              coal_cost_coal_quantity: Number(value).toFixed(3),
              operationalBargeQuantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        let bargeQuantityNew = 0;
        for (let n = 0; n < barge.length; n++) {
          if (
            this.state.purchaseType === "FAS Anchorage" &&
            barge[n].redraft_quantity !== null &&
            this.state.quantity_difference_account === "Supplier"
          ) {
            bargeQuantityNew += Number(barge[n].redraft_quantity);
          }
        }
        if (bargeQuantityNew === 0) {
          bargeQuantityNew = bargeQuantity;
        }

        // if (this.state.purchaseType === "FAS Anchorage" && barge.redraft_quantity !== null && this.state.quantity_difference_account === "Supplier") {
        //   bargeQuantity = barge.redraft_quantity
        // }
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) - Number(bargeQuantityNew)
        ).toFixed(3);
        this.setState({
          bargeList: this.state.bargeList.filter((e) => e !== bargeId),
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          coal_cost_coal_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length !== 0) {
          let value = total_barge_quantity;
          this.setState(
            {
              coal_cost_coal_quantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  onBargesCheck = (bargeId, purchaseId, checked, barge) => {
    let loginUserID = this.Cookie.getCookie("loginUserId");
    let idToken = this.Cookie.getIdTokenCookie();
    this.setState({
      selectedPurchaseContractId: purchaseId,
      isLoading: true,
    });
    var bargeQuantity = "";
    if (
      barge.barge_quantity_in_mt === null ||
      barge.barge_quantity_in_mt <= 0
    ) {
      bargeQuantity = barge.planned_quantity;
    } else {
      bargeQuantity = barge.barge_quantity_in_mt;
    }
    var total_barge_quantity = "";
    if (
      this.state.selectedPurchaseContractId === "" ||
      this.state.selectedPurchaseContractId === purchaseId
    ) {
      if (!!checked) {
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) + Number(bargeQuantity)
        ).toFixed(3);
        let bargeList = [...this.state.bargeList, bargeId];
        this.setState({
          selectedPurchaseContractId: purchaseId,
          bargeList: bargeList,
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length === 0) {
          // Calling Get Business Number Operation Barge Details API
          api.get_barge(loginUserID, idToken, bargeId).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  var purchaseType = res.barges.purchase_type;
                  if (
                    purchaseType === "FAS Anchorage" &&
                    barge.redraft_quantity !== null &&
                    res.barges.quantity_difference_account === "Supplier"
                  ) {
                    bargeQuantity = barge.redraft_quantity;
                  }
                  total_barge_quantity = (
                    Number(this.state.coal_cost_coal_quantity) +
                    Number(bargeQuantity)
                  ).toFixed(3);
                  this.setState(
                    {
                      selectedPurchaseContractId: purchaseId,
                      bargeList: bargeList,
                      total_barge_quantity:
                        Number(total_barge_quantity).toFixed(3),
                      coal_cost_coal_quantity:
                        Number(total_barge_quantity).toFixed(3),
                      quantity_difference_account:
                        res.barges.quantity_difference_account,
                      purchaseType: purchaseType,
                      // purchase_index_linked: res.barges.is_index_linked,
                      pc_quality: res.barges.pc_quality,
                      isLoading: true,
                    },
                    () => {
                      this.callingAsync(bargeId);
                    }
                  );
                }
              });
            }
          });
        } else if (this.state.bargeList.length > 0) {
          if (
            this.state.purchaseType === "FAS Anchorage" &&
            barge.redraft_quantity !== null &&
            this.state.quantity_difference_account === "Supplier"
          ) {
            bargeQuantity = barge.redraft_quantity;
          }
          total_barge_quantity = (
            Number(this.state.coal_cost_coal_quantity) + Number(bargeQuantity)
          ).toFixed(3);
          let value = total_barge_quantity;
          this.setState(
            {
              bargeList: bargeList,
              coal_cost_coal_quantity: Number(value).toFixed(3),
              operationalBargeQuantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        var data = this.state.bargeList;
        if (
          this.state.purchaseType === "FAS Anchorage" &&
          barge.redraft_quantity !== null &&
          this.state.quantity_difference_account === "Supplier"
        ) {
          bargeQuantity = barge.redraft_quantity;
        }
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) - Number(bargeQuantity)
        ).toFixed(3);
        if (data.includes(bargeId)) {
          let idx = data.includes(bargeId);
          data.splice(idx, 1);
        }
        this.setState({
          bargeList: data,
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          coal_cost_coal_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length !== 0) {
          let value = total_barge_quantity;
          this.setState(
            {
              coal_cost_coal_quantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  onSelectAllBarges = (purchaseId, checked, barge) => {
    this.setState({ isLoading: true });
    if (!!checked) {
      // let bargeID = barge.barges[0].barge_id;
      const filter_barge = barge.barges.filter(
        (e) => e.commercial_availability === 0 && e.barge_pur_fin_id === null
      );
      let bargeID = filter_barge.length > 0 ? filter_barge[0].barge_id : null;
      if (!bargeID) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid barge id",
        });
        return;
      }
      let loginUserID = this.Cookie.getCookie("loginUserId");
      let idToken = this.Cookie.getIdTokenCookie();
      // Calling Get Business Number Operation Barge Details API
      api.get_barge(loginUserID, idToken, bargeID).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              var bargeQuantity = 0;
              var bargeList = [];
              var purchaseType = res.barges.purchase_type;
              for (let n = 0; n < barge.barges.length; n++) {
                if (
                  barge.barges[n].barge_pur_fin_id === null &&
                  barge.barges[n].commercial_availability === 0
                ) {
                  bargeList.push(barge.barges[n].barge_id);
                  if (
                    barge.barges[n].allocation_type === "Full" ||
                    barge.barges[n].allocation_type === null
                  ) {
                    if (
                      barge.barges[n].barge_quantity_in_mt === null ||
                      barge.barges[n].barge_quantity_in_mt <= 0
                    ) {
                      bargeQuantity += Number(barge.barges[n].planned_quantity);
                    } else {
                      bargeQuantity += Number(
                        barge.barges[n].barge_quantity_in_mt
                      );
                    }
                  } else if (barge.barges[n].allocation_type === "Partial") {
                    bargeQuantity += Number(barge.barges[n].barge_quantity);
                  }
                  if (
                    purchaseType === "FAS Anchorage" &&
                    barge.barges[n].redraft_quantity !== null &&
                    res.barges.quantity_difference_account === "Supplier"
                  ) {
                    bargeQuantity += barge.barges[n].redraft_quantity;
                  } else {
                    // bargeQuantity += Number(barge.barges[n].barge_quantity_in_mt);
                  }
                }
              }
              this.setState(
                {
                  selectedPurchaseContractId: purchaseId,
                  bargeList: bargeList,
                  bargeSelectAll: checked,
                  coal_cost_coal_quantity: bargeQuantity,
                  // purchase_index_linked: res.barges.is_index_linked,
                  pc_quality: res.barges.pc_quality,
                  isLoading: true,
                },
                () => {
                  this.callingAsync(bargeID);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    } else {
      this.setState(
        {
          // selectedPurchaseContractId: '',
          bargeList: [],
          bargeSelectAll: checked,
          coal_cost_coal_quantity: null,
          isLoading: false,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  onAdvanceAdjustmentsCal = () => {
    var row = this.state.advance_adjustments;
    var total_advance_adjustment_value = 0;
    for (var i of row) {
      total_advance_adjustment_value += Number(i.advance_adjustment_value);
    }
    var {
      coal_cost_adjusted_coal_price_in_local_currency,
      vat_value,
      coal_cost_pph22_percent_val_local_cur,
      coal_cost_pph22_export_value_local_cur,
    } = this.state;

    var display_pph22 =
      Number(coal_cost_pph22_percent_val_local_cur) +
      Number(coal_cost_pph22_export_value_local_cur);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      display_pph22 = Number(coal_cost_pph22_export_value_local_cur);
      vat_value = 0;
    }
    var payable_to_supplier =
      Number(coal_cost_adjusted_coal_price_in_local_currency) +
      Number(vat_value) -
      Number(coal_cost_pph22_percent_val_local_cur) -
      Number(coal_cost_pph22_export_value_local_cur) -
      Number(total_advance_adjustment_value);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      payable_to_supplier =
        Number(coal_cost_adjusted_coal_price_in_local_currency) +
        Number(vat_value) -
        Number(coal_cost_pph22_export_value_local_cur) -
        Number(total_advance_adjustment_value);
    }
    this.setState({
      paid_to_supplier: Number(payable_to_supplier).toFixed(2),
      total_advance_adjustment_value: Number(
        total_advance_adjustment_value
      ).toFixed(0),
      display_pph22: Number(display_pph22).toFixed(0),
      isLoading: false,
    });
  };

  //Barge Data calculations
  bargeQuantityChange = () => {
    var {
      coal_cost_coal_quantity,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_percent,
      coal_cost_billing_currency,
      coal_cost_pph22_export_percent,
      vat_applicable,
      vat_percent,
      coal_cost_pph22_export,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      purchase_currency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_adjusted_coal_price = Number(
      Number(coal_cost_coal_quantity) *
        Number(coal_cost_adjusted_coal_price_pmt)
    ).toFixed(2);
    var coal_cost_adjusted_coal_price_in_local_currency = Number(
      coal_cost_adjusted_coal_price
    ).toFixed(2);

    if (this.props.costing_type === "Performa") {
      let invoice_percentage = this.state.invoice_percentage
        ? parseFloat(this.state.invoice_percentage)
        : 100;
      coal_cost_adjusted_coal_price_in_local_currency = (
        (parseFloat(coal_cost_adjusted_coal_price) * invoice_percentage) /
        100
      ).toFixed(2);
    }
    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_pph22_percent)
      ) / 100;
    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(2);

    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_adjusted_coal_price) *
          Number(coal_cost_pph22_export_percent)
      ) / 100;
    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(2);
    var vat_value = (
      (Number(coal_cost_adjusted_coal_price_in_local_currency) *
        Number(vat_percent)) /
      100
    ).toFixed(2);

    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }
    if (vat_applicable === "No") {
      vat_value = 0;
    }
    let coal_cost_pph22_percent_val_new = "";
    let coal_cost_pph22_export_value_new = "";
    if (purchase_currency !== coal_cost_billing_currency) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
      coal_cost_pph22_percent_val_local_cur = Number(
        coal_cost_pph22_percent_val
      ).toFixed(0);
      // coal_cost_pph22_export_value_local_cur = Number(coal_cost_pph22_export_value).toFixed(0);
    } else {
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_coal_quantity) *
            Number(coal_cost_adjusted_coal_price_pmt) *
            Number(coal_cost_currency_xchg_rate) *
            Number(coal_cost_pph22_percent)
        ) / 100;
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_coal_quantity) *
            Number(coal_cost_adjusted_coal_price_pmt) *
            Number(coal_cost_currency_xchg_rate) *
            Number(coal_cost_pph22_export_percent)
        ) / 100;
      vat_value =
        Number(
          Number(coal_cost_adjusted_coal_price_in_local_currency) *
            Number(coal_cost_currency_xchg_rate) *
            Number(vat_percent)
        ) / 100;
    }
    if (
      coal_cost_billing_currency === "0" ||
      purchase_currency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    let coal_cost_coal_price_pmt_local_currency = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    this.setState(
      {
        coal_cost_coal_price: Number(
          Number(coal_cost_coal_price_pmt_local_currency) *
            Number(coal_cost_coal_quantity)
        ).toFixed(2),
        coal_cost_adjusted_coal_price: coal_cost_adjusted_coal_price,
        coal_cost_coal_price_pmt_local_currency:
          coal_cost_coal_price_pmt_local_currency,
        coal_cost_adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,

        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,

        //PPH22 Local
        coal_cost_pph22_barge_quantity_in_mt: Number(coal_cost_coal_quantity),
        coal_cost_pph22_coal_price_per_mt: coal_cost_adjusted_coal_price_pmt,
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val_local_cur,
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,
        // VAT
        vat_amount: Number(coal_cost_adjusted_coal_price).toFixed(2),
        vat_value: vat_value,
        //PPH22 Export
        coal_cost_pph22_export_amount: Number(
          coal_cost_adjusted_coal_price
        ).toFixed(2),
        coal_cost_pph22_export_value: coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,

        isLoading: false,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onVatChangeHandler = () => {
    var {
      coal_cost_currency_xchg_rate,
      vat_applicable,
      vat_amount,
      vat_percent,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;

    let vat_value = 0;
    if (vat_applicable === "No") {
      vat_value = 0;
      vat_amount = 0;
    }

    vat_value =
      Number(Number(vat_amount) * Number(vat_percent ? vat_percent : 10)) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    //var coal_cost_pph22_export_value_local_cur = Number(coal_cost_pph22_export_value).toFixed(0);

    //let coal_cost_pph22_export_value_new = '';
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      // coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur
    } else {
      // let coal_cost_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate));
      vat_value =
        Number(
          Number(vat_amount) *
            Number(coal_cost_currency_xchg_rate) *
            Number(vat_percent ? vat_percent : 10)
        ) / 100;
    }
    // console.log('coal_cost_pph22_export_value_new',coal_cost_pph22_export_value_new);
    this.setState(
      {
        vat_value: Number(vat_value).toFixed(2),
        vat_percent: this.state.vat_percent ? this.state.vat_percent : 10,

        //coal_cost_pph22_export_value_local_cur: coal_cost_pph22_export_value_local_cur,
        // coal_cost_pph22_export_value_local_cur_new: coal_cost_pph22_export_value_new
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  //Only PPH22 Local Calculations
  onPph22LocalDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_coal_quantity,
    } = this.state;

    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_pph22_barge_quantity_in_mt) *
          Number(coal_cost_pph22_coal_price_per_mt) *
          Number(coal_cost_pph22_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(0);

    let coal_cost_pph22_percent_val_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.purchase_currency
    ) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
    } else {
      let coal_cost_pph22_coal_price_per_mt_new = Number(
        Number(coal_cost_pph22_coal_price_per_mt) *
          Number(coal_cost_currency_xchg_rate)
      );
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_pph22_barge_quantity_in_mt) *
            Number(coal_cost_pph22_coal_price_per_mt_new) *
            Number(coal_cost_pph22_percent)
        ) / 100;
    }
    this.setState(
      {
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val.toFixed(2),
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22LocalChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  onPph22LocalCoalPriceChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  //Only PPH22 Export Calculations
  onPph22ExportDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export,
      coal_cost_billing_currency,
      purchase_currency,
    } = this.state;
    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_pph22_export_amount) *
          Number(coal_cost_pph22_export_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      purchase_currency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(0);
    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }

    let coal_cost_pph22_export_value_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.purchase_currency
    ) {
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
    } else {
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_pph22_export_amount) *
            Number(this.state.coal_cost_currency_xchg_rate) *
            Number(coal_cost_pph22_export_percent)
        ) / 100;
    }
    this.setState(
      {
        coal_cost_pph22_export_value: Number(
          coal_cost_pph22_export_value
        ).toFixed(2),
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22ExportChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    }
  };

  //Barge quantity changes
  onBargeChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Barge quantity changes
  onCoalCostChange = (e) => {
    // const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_penality_currency,
      coal_cost_billing_currency,
      purchase_currency,
    } = this.state;
    // if (e.target.value === "" || quan.test(e.target.value)) {
    var value = this.state.coal_cost_coal_price_pmt;
    if (value === null || value === "") {
      value = 0;
    }
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    let coal_cost_coal_price_pmt = (
      Number(value) * Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var garPenality = "";
    var garBonus = "";
    var ashPenality = "";
    var sulphurPenality = "";

    let garActiualValue = this.state.garActiualValue;
    let garTypicalValue = this.state.garTypicalValue;
    let bonusCap = this.state.bonusCap;
    let penalityCap = this.state.penalityCap;
    var costing_type = this.props.costing_type;

    if (
      this.state.purchasing_term === "Specs" &&
      costing_type === "Commercial"
    ) {
      if (
        garActiualValue !== null &&
        garTypicalValue !== null &&
        garActiualValue !== "" &&
        garTypicalValue !== ""
      ) {
        // if (Number(garActiualValue) > Number(bonusCap)) {
        //   garActiualValue = bonusCap;
        // } else {
        //   garActiualValue = penalityCap;

        // }
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        }
        if (Number(garActiualValue) < Number(garTypicalValue)) {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          garBonus =
            ((Number(garActiualValue) - Number(garTypicalValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
        if (Number(garTypicalValue) > Number(garActiualValue)) {
          garPenality =
            ((Number(garTypicalValue) - Number(garActiualValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
            Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          ashPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
            Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
        }
      }
    }

    var coal_cost_gar_bonus = "";
    var coal_cost_gar_penality = "";
    var coal_cost_ash_penality = "";
    var coal_cost_sulphur_penality = "";

    if (garBonus !== "") {
      coal_cost_gar_bonus = garBonus.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_bonus = garBonus.toFixed(0);
      }
    }
    if (garPenality !== "") {
      coal_cost_gar_penality = garPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_penality = garPenality.toFixed(0);
      }
    }
    if (ashPenality !== "") {
      coal_cost_ash_penality = ashPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_ash_penality = ashPenality.toFixed(0);
      }
    }
    if (sulphurPenality !== "") {
      coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_sulphur_penality = sulphurPenality.toFixed(0);
      }
    }

    let coal_cost_adjusted_coal_price_pmt = Number(
      Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
    ).toFixed(2);

    if (
      purchase_currency !== coal_cost_billing_currency &&
      purchase_currency === coal_cost_penality_currency
    ) {
      coal_cost_coal_price_pmt = Number(
        this.state.coal_cost_coal_price_pmt
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt_value = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(coal_cost_gar_penality) -
          Number(coal_cost_ash_penality) -
          Number(coal_cost_sulphur_penality)
      );
      coal_cost_adjusted_coal_price_pmt = (
        Number(coal_cost_adjusted_coal_price_pmt_value) *
        Number(this.state.coal_cost_currency_xchg_rate)
      ).toFixed(2);
    }
    this.setState(
      {
        // [e.target.name]: e.target.value,
        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,
        coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
      },
      () => {
        this.bargeQuantityChange();
      }
    );
    // }
  };

  onPenalityChanges() {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_billing_currency,
      purchase_currency,
      coal_cost_penality_currency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      purchase_currency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_penality_currency === "0" ||
      purchase_currency === coal_cost_penality_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    let coal_cost_coal_price_pmt = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var garPenality = "";
    var garBonus = "";
    var ashPenality = "";
    var sulphurPenality = "";

    let garActiualValue = this.state.garActiualValue;
    let garTypicalValue = this.state.garTypicalValue;
    let bonusCap = this.state.bonusCap;
    let penalityCap = this.state.penalityCap;
    // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
    var costing_type = this.props.costing_type;

    if (
      this.state.purchasing_term === "Specs" &&
      costing_type === "Commercial"
    ) {
      if (
        garActiualValue !== null &&
        garTypicalValue !== null &&
        garActiualValue !== "" &&
        garTypicalValue !== ""
      ) {
        // if (Number(garActiualValue) > Number(bonusCap)) {
        //   garActiualValue = bonusCap;
        // }
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        }
        if (Number(garActiualValue) < Number(garTypicalValue)) {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }

        // console.log("garTypicalValue",this.state.garTypicalValue);
        // console.log("this.state.garActiualValue",this.state.garActiualValue);
        // console.log("garActiualValue",garActiualValue);
        // console.log("coal_cost_coal_price_pmt",coal_cost_coal_price_pmt);

        if (Number(garActiualValue) > Number(garTypicalValue)) {
          garBonus =
            ((Number(garActiualValue) - Number(garTypicalValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
        if (Number(garTypicalValue) > Number(garActiualValue)) {
          garPenality =
            ((Number(garTypicalValue) - Number(garActiualValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
            Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          ashPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
            Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
        }
      }
    }

    var coal_cost_gar_bonus = "";
    var coal_cost_gar_penality = "";
    var coal_cost_ash_penality = "";
    var coal_cost_sulphur_penality = "";
    if (garBonus !== "") {
      coal_cost_gar_bonus = garBonus.toFixed(2);
    }
    if (garPenality !== "") {
      coal_cost_gar_penality = garPenality.toFixed(2);
    }
    if (ashPenality !== "") {
      coal_cost_ash_penality = ashPenality.toFixed(2);
    }
    if (sulphurPenality !== "") {
      coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
    }
    let coal_cost_adjusted_coal_price_pmt = Number(
      Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
    ).toFixed(2);

    if (
      purchase_currency !== coal_cost_billing_currency &&
      purchase_currency === coal_cost_penality_currency
    ) {
      coal_cost_coal_price_pmt = Number(
        this.state.coal_cost_coal_price_pmt
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt_value = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(coal_cost_gar_penality) -
          Number(coal_cost_ash_penality) -
          Number(coal_cost_sulphur_penality)
      );
      coal_cost_adjusted_coal_price_pmt = (
        Number(coal_cost_adjusted_coal_price_pmt_value) *
        Number(this.state.coal_cost_currency_xchg_rate)
      ).toFixed(2);
    }

    // console.log("garPenality",garPenality)
    // console.log("coal_cost_gar_penality",coal_cost_gar_penality)
    this.setState(
      {
        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,
        coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
      },
      () => {
        this.bargeQuantityChange();
      }
    );
  }

  //Exchange rate changes
  onExchangeRateChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      purchase_currency,
      coal_cost_billing_currency,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      if (value === null || value === "") {
        value = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        purchase_currency === coal_cost_billing_currency
      ) {
        value = 1;
      }
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        value = 1 / Number(value);
      }
      coal_cost_gar_bonus = (
        Number(coal_cost_gar_bonus) * Number(value)
      ).toFixed(2);
      coal_cost_gar_penality = (
        Number(coal_cost_gar_penality) * Number(value)
      ).toFixed(2);
      coal_cost_ash_penality = (
        Number(coal_cost_ash_penality) * Number(value)
      ).toFixed(2);
      coal_cost_sulphur_penality = (
        Number(coal_cost_sulphur_penality) * Number(value)
      ).toFixed(2);
      coal_cost_coal_price_pmt = (
        Number(coal_cost_coal_price_pmt) * Number(value)
      ).toFixed(2);
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_currency_xchg_rate: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
              Number(coal_cost_gar_bonus) -
              Number(coal_cost_gar_penality) -
              Number(coal_cost_ash_penality) -
              Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.onPenalityChanges();
        }
      );
    }
  };

  //Gar bonus changes
  onGarBonusChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      purchase_currency,
      coal_cost_billing_currency,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_penality_currency,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        purchase_currency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(value) -
          Number(coal_cost_gar_penality) -
          Number(coal_cost_ash_penality) -
          Number(coal_cost_sulphur_penality)
      ).toFixed(2);
      if (
        purchase_currency !== coal_cost_billing_currency &&
        purchase_currency === coal_cost_penality_currency
      ) {
        coal_cost_coal_price_pmt = Number(
          this.state.coal_cost_coal_price_pmt
        ).toFixed(2);
        let coal_cost_adjusted_coal_price_pmt_value = Number(
          Number(coal_cost_coal_price_pmt) +
            Number(value) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
        );
        coal_cost_adjusted_coal_price_pmt = (
          Number(coal_cost_adjusted_coal_price_pmt_value) *
          Number(this.state.coal_cost_currency_xchg_rate)
        ).toFixed(2);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Gar Penality changes
  onGarPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      purchase_currency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_penality_currency,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        purchase_currency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      var value = e.target.value;
      let coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(value) -
          Number(coal_cost_ash_penality) -
          Number(coal_cost_sulphur_penality)
      ).toFixed(2);
      if (
        purchase_currency !== coal_cost_billing_currency &&
        purchase_currency === coal_cost_penality_currency
      ) {
        coal_cost_coal_price_pmt = Number(
          this.state.coal_cost_coal_price_pmt
        ).toFixed(2);
        let coal_cost_adjusted_coal_price_pmt_value = Number(
          Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(value) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
        );
        coal_cost_adjusted_coal_price_pmt = (
          Number(coal_cost_adjusted_coal_price_pmt_value) *
          Number(this.state.coal_cost_currency_xchg_rate)
        ).toFixed(2);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Ash Penality changes
  onAshPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      purchase_currency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_sulphur_penality,
      coal_cost_penality_currency,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        purchase_currency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;

      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(coal_cost_gar_penality) -
          Number(value) -
          Number(coal_cost_sulphur_penality)
      ).toFixed(2);
      if (
        purchase_currency !== coal_cost_billing_currency &&
        purchase_currency === coal_cost_penality_currency
      ) {
        coal_cost_coal_price_pmt = Number(
          this.state.coal_cost_coal_price_pmt
        ).toFixed(2);
        let coal_cost_adjusted_coal_price_pmt_value = Number(
          Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(value) -
            Number(coal_cost_sulphur_penality)
        );
        coal_cost_adjusted_coal_price_pmt = (
          Number(coal_cost_adjusted_coal_price_pmt_value) *
          Number(this.state.coal_cost_currency_xchg_rate)
        ).toFixed(2);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Sulphur Penality changes
  onSulphurPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      purchase_currency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_penality_currency,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        purchase_currency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(coal_cost_gar_penality) -
          Number(coal_cost_ash_penality) -
          Number(value)
      ).toFixed(2);
      if (
        purchase_currency !== coal_cost_billing_currency &&
        purchase_currency === coal_cost_penality_currency
      ) {
        coal_cost_coal_price_pmt = Number(
          this.state.coal_cost_coal_price_pmt
        ).toFixed(2);
        let coal_cost_adjusted_coal_price_pmt_value = Number(
          Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(value)
        );
        coal_cost_adjusted_coal_price_pmt = (
          Number(coal_cost_adjusted_coal_price_pmt_value) *
          Number(this.state.coal_cost_currency_xchg_rate)
        ).toFixed(2);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
      // this.setState({
      //   [e.target.name]: e.target.value,
      // }, () => {
      //   this.onPenalityChanges();
      // });
    }
  };

  billingCurrencyChange = (e) => {
    if (e.target.value === this.state.purchase_currency) {
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_penality_currency: e.target.value,
        },
        () => {
          this.onPenalityChanges();
        }
      );
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.onPenalityChanges();
        }
      );
    }
  };

  penalityCurrencyChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.onPenalityChanges();
      }
    );
  };

  //Adjusted coal price PMT changes
  onAdjustedCoalPMT = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  onSelectAllCommercialBarges = (purchaseId, checked, barge) => {
    this.setState({ isLoading: true });
    if (!!checked) {
      const filter_com_barge = barge.barges.filter(
        (e) =>
          e.commercial_availability === 0 && e.com_barge_pur_fin_id === null
      );
      let bargeID =
        filter_com_barge.length > 0 ? filter_com_barge[0].barge_id : null;
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      if (bargeID) {
        // Calling Get Business Number Operation Barge Details API
        api.get_barge(loginUserID, idToken, bargeID).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                var bargeQuantity = 0;
                var bargeList = [];
                var purchaseType = res.barges.purchase_type;
                for (let n = 0; n < barge.barges.length; n++) {
                  if (
                    barge.barges[n].com_barge_pur_fin_id === null &&
                    barge.barges[n].commercial_availability === 0
                  ) {
                    bargeList.push(barge.barges[n].barge_id);
                    if (
                      barge.barges[n].allocation_type === "Full" ||
                      barge.barges[n].allocation_type === null
                    ) {
                      if (
                        barge.barges[n].barge_quantity_in_mt === null ||
                        barge.barges[n].barge_quantity_in_mt <= 0
                      ) {
                        bargeQuantity += Number(
                          barge.barges[n].planned_quantity
                        );
                      } else {
                        bargeQuantity += Number(
                          barge.barges[n].barge_quantity_in_mt
                        );
                      }
                    } else if (barge.barges[n].allocation_type === "Partial") {
                      bargeQuantity += Number(barge.barges[n].barge_quantity);
                    }
                    if (
                      purchaseType === "FAS Anchorage" &&
                      barge.barges[n].redraft_quantity !== null &&
                      res.barges.quantity_difference_account === "Supplier"
                    ) {
                      bargeQuantity += barge.barges[n].redraft_quantity;
                    } else {
                      // bargeQuantity += Number(barge.barges[n].barge_quantity_in_mt);
                    }
                  }
                }
                this.setState(
                  {
                    selectedPurchaseContractId: purchaseId,
                    bargeList: bargeList,
                    bargeSelectAll: checked,
                    coal_cost_coal_quantity: bargeQuantity,
                    // purchase_index_linked: res.barges.is_index_linked,
                    pc_quality: res.barges.pc_quality,
                    isLoading: true,
                  },
                  () => {
                    this.callingAsync(bargeID);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid barge id",
        });
      }
    } else {
      this.setState(
        {
          // selectedPurchaseContractId: '',
          bargeList: [],
          bargeSelectAll: checked,
          coal_cost_coal_quantity: null,
          isLoading: false,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  render() {
    try {
      var costing_type = this.props.costing_type;
    } catch (e) {
      window.location.href = "/barge-list";
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Coal Costing Purchase Financial for Barge{" "}
                  {"(" + costing_type + ")"}{" "}
                  {this.state.bargeNomination
                    ? " (" + this.state.bargeNomination + ")"
                    : ""}{" "}
                  {this.state.mother_vesselName
                    ? "- (" + this.state.mother_vesselName + ")"
                    : ""}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>

              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
            </div>
            <div className="card">
              {this.state.activeStep === 0 && (
                <div className="section_block w-100">
                  <div style={{ width: "70%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Select Barge to create costing
                    </h5>
                  </div>
                  <div className="row p-2">
                    <div className="col-lg-4 pl-0">
                      <Autocomplete
                        options={this.state.purchaseDataForMenu}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({
                              purchaseContractSelected: value,
                              purchase_contract_id: value.value,
                              selectedPurchaseContractId: value.value,
                              purchase_contract_no: value.name,
                              purchaseContractError: false,
                            });
                          } else {
                            this.setState({
                              purchase_contract_id: "",
                              purchase_contract_no: "",
                              purchaseContractSelected: null,
                              purchaseContractError: false,
                            });
                          }
                        }}
                        value={this.state.purchaseContractSelected}
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        size="small"
                        name="purchaseContract"
                        renderOption={(option) => (
                          <React.Fragment>
                            {/* {option.name} ({option.vendor_name}) */}
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {option.vendor_name
                                  ? "( " + option.vendor_name + " )"
                                  : null}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Purchase Contract"
                            label="Purchase Contract"
                            error={this.state.purchaseContractError}
                            variant="outlined"
                            fullWidth
                            style={{ top: 8 }}
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-4 pl-0">
                      {/* <label className='form_label mb-0'>Purchase Contract *</label> */}
                      <Autocomplete
                        multiple
                        options={this.state.business_no_drop_value}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {/* {option.name} */}
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.name + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({
                              business_no_ids: data,
                              business_no_value: value,
                            });
                          } else {
                            this.setState({
                              business_no_ids: [],
                              business_no_value: { name: "", value: "" },
                            });
                          }
                        }}
                        disableCloseOnSelect
                        name="Business_Number"
                        size="small"
                        value={this.state.business_no_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Business No"
                            label="Business No"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    {/* <div className='col-lg-4 pr-0 mt-1'>
                    <label className='form_label mb-0'>Barge Name</label>
                    <div className='row mb-0'>
                      <TextField
                        name='barge_nomination'
                        margin='dense'
                        variant='outlined'
                        value={this.state.barge_nomination}
                        placeholder='Barge Name'
                        inputProps ={{
                          style: {
                            fontFamily: 'Poppins',
                            fontSize: '12px'
                          }
                        }}
                        className="col-lg-12"
                        onChange={(e)=>{
                          this.setState({barge_nomination: e.target.value})
                        }}
                      />
                    </div>
                  </div> */}
                    <div className="col-lg-4">
                      <button
                        onClick={this.searchBargesHandler}
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "25px" }}
                      >
                        Load barges
                      </button>
                    </div>
                  </div>
                  {this.state.enableBargeList ? (
                    <div>
                      {costing_type === "Performa" ? (
                        <div>
                          {this.state.purchaseBargeList.map((p, index) => (
                            <div
                              className="col shadow-sm p-2 mb-2 bg-white border"
                              key={index}
                            >
                              <div className="row">
                                <div className="table-responsive">
                                  <table className="table table-borderless mb-0 border-0">
                                    <thead className="border-0">
                                      <tr>
                                        {costing_type === "Performa" && (
                                          <th
                                            nowrap="true"
                                            className="p-0 text-center pt-1 border-0"
                                          >
                                            <CheckBox
                                              color="primary"
                                              checked={
                                                this.state.bargeList.length !==
                                                  0 &&
                                                this.state.bargeList.length ===
                                                  p.barges.filter(
                                                    (e) =>
                                                      e.barge_pur_fin_id ===
                                                        null &&
                                                      e.commercial_availability ===
                                                        0
                                                  ).length
                                              }
                                              disabled={
                                                p.barges.filter(
                                                  (e) =>
                                                    e.commercial_availability ===
                                                      0 &&
                                                    e.barge_pur_fin_id === null
                                                ).length === 0
                                              }
                                              // onChange={this.onBargesCheck}
                                              onChange={(e) => {
                                                this.onSelectAllBarges(
                                                  p.purchase_contract_id,
                                                  e.target.checked,
                                                  p
                                                );
                                              }}
                                            />
                                            <i
                                              className="fa fa-exclamation-triangle"
                                              style={{
                                                fontSize: 20,
                                                color: "orange",
                                                cursor: "pointer",
                                                verticalAlign: "middle",
                                                visibility: "hidden",
                                              }}
                                              data-toggle="modal"
                                              title="Commercial Invoice already generated"
                                              data-placement="bottom"
                                              data-keyboard="false"
                                              data-backdrop="static"
                                            />
                                          </th>
                                        )}
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Purchase Contract
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Barge ID
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Barge Nomination
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Jetty
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Loading Date
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Business No
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Quality
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Purchase Type
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-right"
                                        >
                                          Quantity
                                        </th>
                                      </tr>
                                    </thead>
                                    {p.barges.map((id, idx) => (
                                      <tbody key={idx}>
                                        <tr
                                          style={{
                                            display:
                                              id.commercial_availability ===
                                                1 ||
                                              id.barge_pur_fin_id !== null
                                                ? "none"
                                                : "hide",
                                          }}
                                        >
                                          {/* {costing_type === 'Performa' && */}
                                          <td className="p-0 text-center pt-1 pb-1">
                                            <CheckBox
                                              value={id.barge_id}
                                              color="primary"
                                              checked={this.state.bargeList.includes(
                                                id.barge_id
                                              )}
                                              disabled={
                                                id.commercial_availability ===
                                                  1 ||
                                                id.barge_pur_fin_id !== null
                                              }
                                              // onChange={this.onBargesCheck}
                                              onChange={(e) => {
                                                this.onBargesCheck(
                                                  e.target.value,
                                                  p.purchase_contract_id,
                                                  e.target.checked,
                                                  id
                                                );
                                              }}
                                            />
                                            {id.commercial_availability === 1 &&
                                            this.state
                                              .selectedPurchaseContractId ===
                                              p.purchase_contract_id ? (
                                              <i
                                                className="fa fa-exclamation-triangle"
                                                style={{
                                                  fontSize: 20,
                                                  color: "orange",
                                                  cursor: "pointer",
                                                  verticalAlign: "middle",
                                                }}
                                                data-toggle="modal"
                                                title="Commercial Invoice already generated"
                                                data-placement="bottom"
                                                data-keyboard="false"
                                                data-backdrop="static"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-exclamation-triangle"
                                                style={{
                                                  fontSize: 20,
                                                  color: "orange",
                                                  cursor: "pointer",
                                                  verticalAlign: "middle",
                                                  visibility: "hidden",
                                                }}
                                                data-toggle="modal"
                                                title="Commercial Invoice already generated"
                                                data-placement="bottom"
                                                data-keyboard="false"
                                                data-backdrop="static"
                                              ></i>
                                            )}
                                          </td>
                                          {/* } */}
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.purchase_contract_no}
                                          </td>
                                          <td className="table_td text-left">
                                            {pad(id.barge_id)}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.barge_nomination
                                              ? id.barge_nomination
                                              : "-"}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.loading_jetty
                                              ? id.loading_jetty
                                              : "-"}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {localDateFormate(id.loading_date)}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.business_no
                                              ? id.business_no
                                              : "-"}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.quality ? id.quality : "-"}
                                          </td>
                                          <td
                                            className="table_td text-left"
                                            nowrap="true"
                                          >
                                            {id.purchase_type
                                              ? id.purchase_type
                                              : "-"}
                                          </td>
                                          <td
                                            className="table_td text-right"
                                            nowrap="true"
                                          >
                                            {id.allocation_type === "Partial"
                                              ? toLocaleString(
                                                  id.barge_quantity
                                                )
                                              : id.barge_quantity_in_mt
                                              ? toLocaleString(
                                                  id.barge_quantity_in_mt
                                                )
                                              : toLocaleString(
                                                  id.planned_quantity
                                                )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          {this.state.purchase_contracts.map((p, index) => (
                            <div
                              className={
                                p.new_barges.length > 0
                                  ? "shadow-sm p-2 mb-2 bg-white border"
                                  : ""
                              }
                              key={index}
                            >
                              <div className="row">
                                <div className="table-responsive">
                                  <table className="table table-borderless mb-0 border-0">
                                    <thead className="border-0">
                                      <tr>
                                        <th
                                          nowrap="true"
                                          className="p-0 text-center pt-1 border-0"
                                        >
                                          <CheckBox
                                            // value={id.business_no_barge_id}
                                            color="primary"
                                            checked={
                                              this.state.bargeList.length !==
                                                0 &&
                                              this.state.bargeList.length ===
                                                p.barges.filter(
                                                  (e) =>
                                                    e.commercial_availability ===
                                                      0 &&
                                                    e.com_barge_pur_fin_id ===
                                                      null
                                                ).length
                                            }
                                            disabled={
                                              p.barges.filter(
                                                (e) =>
                                                  e.commercial_availability ===
                                                    0 &&
                                                  e.com_barge_pur_fin_id ===
                                                    null
                                              ).length === 0
                                            }
                                            onChange={(e) => {
                                              this.onSelectAllCommercialBarges(
                                                p.purchase_contract_id,
                                                e.target.checked,
                                                p
                                              );
                                            }}
                                          />
                                          <i
                                            className="fa fa-exclamation-triangle"
                                            style={{
                                              fontSize: 20,
                                              color: "orange",
                                              cursor: "pointer",
                                              verticalAlign: "middle",
                                              visibility: "hidden",
                                            }}
                                            data-toggle="modal"
                                            title="Commercial Invoice already generated"
                                            data-placement="bottom"
                                            data-keyboard="false"
                                            data-backdrop="static"
                                          />
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Purchase Contract
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Barge ID
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Barge Nomination
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Jetty
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Loading Date
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Business No
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Quality
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-left"
                                        >
                                          Purchase Type
                                        </th>
                                        <th
                                          nowrap="true"
                                          style={{ fontSize: 16 }}
                                          className="table_header_barge_label text-uppercase border-0 text-right"
                                        >
                                          Quantity
                                        </th>
                                      </tr>
                                    </thead>
                                    {p.new_barges.map((id, idx) => (
                                      <tbody key={idx}>
                                        {id.new_barges.map((idr, indx) => (
                                          <tr key={indx}>
                                            {indx === 0 && (
                                              <td
                                                className="p-0 text-center pt-1 pb-1"
                                                rowSpan={id.new_barges.length}
                                              >
                                                {idr.commercial_availability ===
                                                  0 && (
                                                  <CheckBox
                                                    value={idr.barge_id}
                                                    color="primary"
                                                    checked={this.state.bargeList.includes(
                                                      idr.barge_id
                                                    )}
                                                    disabled={
                                                      idr.com_purchasing_term ===
                                                        "Specs" &&
                                                      idr.com_quality_results ===
                                                        0
                                                    }
                                                    // onChange={this.onBargesCheck}
                                                    onChange={(e) => {
                                                      this.onBargesCheckCommercial(
                                                        e.target.value,
                                                        p.purchase_contract_id,
                                                        e.target.checked,
                                                        id.new_barges
                                                      );
                                                    }}
                                                  />
                                                )}

                                                {idr.com_quality_results ===
                                                  0 &&
                                                  idr.commercial_availability ===
                                                    0 &&
                                                  idr.com_purchasing_term ===
                                                    "Specs" && (
                                                    <i
                                                      className="fa fa-exclamation-triangle"
                                                      style={{
                                                        fontSize: 20,
                                                        color: "orange",
                                                        cursor: "pointer",
                                                        verticalAlign: "middle",
                                                      }}
                                                      data-toggle="modal"
                                                      title="Quality results are not available"
                                                      data-placement="bottom"
                                                      data-keyboard="false"
                                                      data-backdrop="static"
                                                    />
                                                  )}
                                              </td>
                                            )}
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.purchase_contract_no}
                                            </td>
                                            <td className="table_td text-left">
                                              {pad(idr.barge_id)}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.barge_nomination
                                                ? idr.barge_nomination
                                                : "-"}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.loading_jetty
                                                ? idr.loading_jetty
                                                : "-"}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {localDateFormate(
                                                idr.loading_date
                                              )}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.business_no
                                                ? idr.business_no
                                                : "-"}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.quality}
                                            </td>
                                            <td
                                              className="table_td text-left"
                                              nowrap="true"
                                            >
                                              {idr.purchase_type}
                                            </td>
                                            <td
                                              className="table_td text-right"
                                              nowrap="true"
                                            >
                                              {idr.allocation_type === "Partial"
                                                ? toLocaleString(
                                                    idr.barge_quantity
                                                  )
                                                : idr.barge_quantity_in_mt
                                                ? toLocaleString(
                                                    idr.barge_quantity_in_mt
                                                  )
                                                : toLocaleString(
                                                    idr.planned_quantity
                                                  )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <span className="contract_display_header_value pt-2">
                        No barges available
                      </span>
                    </div>
                  )}
                </div>
              )}

              {this.state.activeStep === 1 && this.state.bargeList.length > 0 && (
                <div>
                  {/*=============================================
                  =    Coal Costing Section                  =
                =============================================*/}
                  <div className="col shadow-sm p-0 mb-2 bg-white border ">
                    <h5 className="mb-3 p-2 section_header">Coal Costing</h5>
                    <div className="row">
                      <div className="row col-lg-12">
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">
                            Barge Quantity in MT
                          </label>
                          <TextField
                            name="coal_cost_coal_quantity"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.coal_cost_coal_quantity}
                            fullWidth
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "12px",
                              },
                            }}
                            placeholder="Barge Quantity in MT"
                            onChange={this.onBargeChange}
                          />
                        </div>
                        {/* {this.state.purchase_index_linked === "Yes" && (
                          <div className="col-lg-4 pl-0 mb-2">
                            <label className="form_label mb-0">
                              Coal Price per MT in{" "}
                              {this.state.purchase_currency}
                            </label>
                            <div className="contract_display_header_value mt-3">
                              {toLocaleString(
                                this.state.coal_cost_coal_price_pmt
                              )}
                            </div>
                            <TextField
                              name="coal_cost_coal_price_pmt"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              disabled
                              value={this.state.coal_cost_coal_price_pmt}
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "12px",
                                },
                              }}
                              placeholder="Coal Price per MT"
                              onChange={this.onCoalCostChange}
                            /> 
                          </div>
                        )}*/}
                        <div className="col-lg-4 pl-0 mb-2">
                          <label className="form_label mb-0">
                            Coal Price per MT
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {`${toLocaleString(
                              this.state.coal_cost_coal_price_pmt
                            )} ${this.state.purchase_currency}`}
                          </label>
                        </div>

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.purchase_currency !== "IDR" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Billing Currency
                              </label>
                              <TextField
                                name="coal_cost_billing_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_billing_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_billing_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.billingCurrencyChange}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                <MenuItem
                                  value={
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"
                                  }
                                >
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </MenuItem>
                                <MenuItem value={this.state.purchase_currency}>
                                  {this.state.purchase_currency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.purchase_currency !== "USD" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Billing Currency
                              </label>
                              <TextField
                                name="coal_cost_billing_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_billing_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_billing_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.billingCurrencyChange}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                <MenuItem
                                  value={
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"
                                  }
                                >
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </MenuItem>
                                <MenuItem value={this.state.purchase_currency}>
                                  {this.state.purchase_currency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.purchase_currency !== "IDR" &&
                          costing_type === "Commercial" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Penality Currency
                              </label>
                              <TextField
                                name="coal_cost_penality_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_penality_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_penality_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.penalityCurrencyChange}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                {this.state.coal_cost_billing_currency ===
                                  "IDR" && (
                                  <MenuItem
                                    value={
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"
                                    }
                                  >
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </MenuItem>
                                )}
                                <MenuItem value={this.state.purchase_currency}>
                                  {this.state.purchase_currency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.purchase_currency !== "USD" &&
                          costing_type === "Commercial" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Penality Currency
                              </label>
                              <TextField
                                name="coal_cost_penality_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_penality_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_penality_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.penalityCurrencyChange}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                {this.state.coal_cost_billing_currency ===
                                  "USD" && (
                                  <MenuItem
                                    value={
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"
                                    }
                                  >
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </MenuItem>
                                )}
                                <MenuItem value={this.state.purchase_currency}>
                                  {this.state.purchase_currency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.purchase_currency !== "IDR" &&
                          this.state.purchase_currency !==
                            this.state.coal_cost_billing_currency && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Exchange rate {this.state.purchase_currency} to
                                IDR
                              </label>
                              <TextField
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                value={this.state.currency_xchg_rate}
                                placeholder="Exchange rate"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={this.onExchangeRateChange}
                              />
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.purchase_currency !== "USD" &&
                          this.state.purchase_currency !==
                            this.state.coal_cost_billing_currency && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Exchange rate USD to{" "}
                                {this.state.purchase_currency}
                              </label>
                              <TextField
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                value={this.state.currency_xchg_rate}
                                placeholder="Exchange rate"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={this.onExchangeRateChange}
                              />
                            </div>
                          )}

                        {this.state.coal_cost_billing_currency !==
                          this.state.purchase_currency &&
                          this.state.coal_cost_billing_currency !== "0" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Coal Price per MT in{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {toLocaleString(
                                  this.state
                                    .coal_cost_coal_price_pmt_local_currency
                                ) +
                                  " " +
                                  this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}

                        <div className="col-lg-4 pl-0 mb-2">
                          <label className="form_label mb-0">Coal Price</label>
                          <label className="contract_display_header_value mt-3">
                            {toLocaleString(
                              Number(this.state.coal_cost_coal_price).toFixed(2)
                            )}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>

                        {this.state.purchasing_term === "Specs" &&
                          costing_type === "Commercial" && (
                            <div>
                              {this.state.coal_cost_billing_currency !==
                                this.state.purchase_currency &&
                                this.state.coal_cost_billing_currency !==
                                  this.state.coal_cost_penality_currency && (
                                  <div className="col-lg-4 pl-0 mb-2">
                                    <label className="form_label mb-0">
                                      Adjusted Coal Price PMT in{" "}
                                      {this.state.coal_cost_penality_currency}
                                    </label>
                                    <label className="contract_display_header_value mt-3">
                                      {toLocaleString(
                                        (
                                          Number(
                                            this.state
                                              .coal_cost_adjusted_coal_price_pmt
                                          ) /
                                          Number(
                                            this.state
                                              .coal_cost_currency_xchg_rate
                                          )
                                        ).toFixed(2)
                                      )}
                                    </label>
                                  </div>
                                )}
                            </div>
                          )}
                        {this.state.purchasing_term === "Specs" &&
                          costing_type === "Commercial" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Adjusted Coal Price PMT in{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                              <TextField
                                name="coal_cost_adjusted_coal_price_pmt"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                value={
                                  this.state.coal_cost_adjusted_coal_price_pmt
                                }
                                placeholder="Adjusted Coal Price PMT"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={this.onAdjustedCoalPMT}
                              />
                            </div>
                          )}

                        {this.state.purchasing_term === "Specs" &&
                          costing_type === "Commercial" && (
                            <div className="col-lg-4 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Adjusted Coal Price
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {toLocaleString(
                                  Number(
                                    this.state.coal_cost_adjusted_coal_price
                                  ).toFixed(2)
                                )}{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <div className="col-lg-4 pl-0 mb-2">
                            <label className="form_label mb-0">VAT Value</label>
                            <label className="contract_display_header_value mt-3">
                              {toLocaleString(this.state.vat_value)}
                              {this.state.coal_cost_billing_currency}
                            </label>
                          </div>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <div className="col-lg-4 pl-0 mb-2">
                            <label className="form_label mb-0">
                              PPh22 Local
                            </label>
                            <label className="contract_display_header_value mt-3">
                              {this.state
                                .coal_cost_pph22_percent_val_local_cur !== "0"
                                ? "(" +
                                  toLocaleString(
                                    this.state
                                      .coal_cost_pph22_percent_val_local_cur
                                  ) +
                                  ")"
                                : "(0)"}{" "}
                              {this.state.coal_cost_billing_currency}
                            </label>
                          </div>
                        )}
                        <div className="col-lg-4 pl-0 mb-2">
                          <label className="form_label mb-0">
                            PPh22 Export
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {this.state
                              .coal_cost_pph22_export_value_local_cur !== "0"
                              ? "(" +
                                toLocaleString(
                                  this.state
                                    .coal_cost_pph22_export_value_local_cur
                                ) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                        <div className="col-lg-4 pl-0 mb-2">
                          <label className="form_label mb-0">
                            Advance Adjustments
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {this.state.total_advance_adjustment_value !== "0"
                              ? "(" +
                                toLocaleString(
                                  this.state.total_advance_adjustment_value
                                ) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                        {this.props.costing_type === "Performa" && (
                          <div className="col-lg-4 mt-2">
                            <span className="contract_display_header_label">
                              Invoice %
                            </span>
                            <TextField
                              name="invoice_percentage"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              placeholder="Enter Value"
                              value={this.state.invoice_percentage}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    invoice_percentage: e.target.value,
                                  },
                                  () => this.bargeQuantityChange()
                                );
                              }}
                            />
                          </div>
                        )}
                        <div className="col-lg-4 pl-0 mb-2">
                          <label className="form_label mb-0">
                            Payable to Supplier
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {toLocaleString(this.state.paid_to_supplier)}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {this.state.purchasing_term === "Specs" &&
                          costing_type === "Commercial" && (
                            <div>
                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  GAR Penality / Bonus per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                              <th
                                                className="allocation_table_header_label border-0"
                                                style={{ minWidth: 90 }}
                                              >
                                                Bonus Cap
                                              </th>
                                              <th
                                                className="allocation_table_header_label border-0"
                                                style={{ minWidth: 90 }}
                                              >
                                                Penality Cap
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap2}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap3}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap3}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ width: "30%", padding: 8 }}>
                                        <TextField
                                          name="coal_cost_gar_penality"
                                          margin="dense"
                                          variant="outlined"
                                          type="number"
                                          value={
                                            this.state.coal_cost_gar_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="GAR Penality per MT"
                                          // label='GAR Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                            "0"
                                              ? "GAR Penality per MT in " +
                                                this.state
                                                  .coal_cost_billing_currency
                                              : "GAR Penality per MT"
                                          }
                                          onChange={this.onGarPenalityChange}
                                        />
                                        <TextField
                                          name="coal_cost_gar_bonus"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={this.state.coal_cost_gar_bonus}
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="GAR Bonus per MT"
                                          // label='GAR Bonus per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                            "0"
                                              ? "GAR Bonus per MT in " +
                                                this.state
                                                  .coal_cost_billing_currency
                                              : "GAR Bonus per MT"
                                          }
                                          onChange={this.onGarBonusChange}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  ASH Penality per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_basis}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_typical}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_ash_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_actual}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ padding: 8 }}>
                                        <TextField
                                          name="coal_cost_ash_penality"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={
                                            this.state.coal_cost_ash_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="ASH Penality per MT"
                                          // label='ASH Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                            "0"
                                              ? "ASH Penality per MT in " +
                                                this.state
                                                  .coal_cost_billing_currency
                                              : "ASH Penality per MT"
                                          }
                                          onChange={this.onAshPenalityChange}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  Sulphur Penality per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_actual
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ padding: 8 }}>
                                        <TextField
                                          name="coal_cost_sulphur_penality"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={
                                            this.state
                                              .coal_cost_sulphur_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="Sulphur Penality per MT"
                                          // label='Sulphur Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                            "0"
                                              ? "Sulphur Penality per MT in " +
                                                this.state
                                                  .coal_cost_billing_currency
                                              : "Sulphur Penality per MT"
                                          }
                                          onChange={
                                            this.onSulphurPenalityChange
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    {(config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") && (
                      <div className="col-lg pb-3">
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="form_label mb-0">
                            PPH22 Export Applicable
                          </label>
                          <div className="col-lg pl-0">
                            <RadioGroup
                              aria-label="coal_cost_pph22_export"
                              name="coal_cost_pph22_export"
                              onChange={this.handleRadioButtons}
                              style={{ flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="Yes"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.coal_cost_pph22_export ===
                                      "Yes"
                                    }
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="No"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.coal_cost_pph22_export === "No"
                                    }
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        {this.state.coal_cost_pph22_export === "Yes" && (
                          <div
                            className="col-lg-6 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>PPH22 Export</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Amount
                                </label>
                                <div style={{ color: "#42352d" }}>
                                  <TextField
                                    name="coal_cost_pph22_export_amount"
                                    margin="dense"
                                    type="number"
                                    // variant='outlined'
                                    fullWidth
                                    value={
                                      this.state.coal_cost_pph22_export_amount
                                    }
                                    onChange={this.onPph22ExportChange}
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH22 Export %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.coal_cost_pph22_export_percent}
                                </div>
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH22 Export in{" "}
                                  {this.state.coal_cost_billing_currency}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(
                                    this.state
                                      .coal_cost_pph22_export_value_local_cur
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {(config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") && (
                      <div className="row col-lg pb-3">
                        <div className="col-lg-6 pl-0 pr-0 pb-2">
                          <div className="col-lg-6 pl-0 mb-2">
                            <label className="form_label mb-0">
                              VAT Applicable
                            </label>
                            <div className="col-lg pl-0">
                              <RadioGroup
                                aria-label="vat_applicable"
                                name="vat_applicable"
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      vat_applicable: e.target.value,
                                    },
                                    () => {
                                      this.onVatChangeHandler();
                                    }
                                  );
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.vat_applicable === "Yes"
                                      }
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.vat_applicable === "No"
                                      }
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                          {this.state.vat_applicable === "Yes" && (
                            <div
                              className="col-lg-12 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>VAT</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Amount
                                  </label>
                                  <div style={{ color: "#42352d" }}>
                                    <TextField
                                      name="vat_amount"
                                      margin="dense"
                                      //variant='outlined'
                                      type="number"
                                      value={this.state.vat_amount}
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={(e) => {
                                        const quan = /^\d*(\.\d{0,4})?$/;
                                        if (
                                          e.target.value === "" ||
                                          quan.test(e.target.value)
                                        ) {
                                          var value = e.target.value;
                                          this.setState(
                                            {
                                              vat_amount: value,
                                            },
                                            () => {
                                              this.onVatChangeHandler();
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.vat_percent}
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(this.state.vat_value)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 pr-0 pl-2">
                          <div className="col-lg-12 pl-0 mb-2">
                            <label className="form_label mb-0">
                              PPH22 Export Applicable
                            </label>
                            <div className="col-lg pl-0">
                              <RadioGroup
                                aria-label="coal_cost_pph22_export"
                                name="coal_cost_pph22_export"
                                onChange={this.handleRadioButtons}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.coal_cost_pph22_export ===
                                        "Yes"
                                      }
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.coal_cost_pph22_export ===
                                        "No"
                                      }
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                          {this.state.coal_cost_pph22_export === "Yes" && (
                            <div
                              className="col-lg-12 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>PPH22 Export</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Amount
                                  </label>
                                  <div style={{ color: "#42352d" }}>
                                    <TextField
                                      name="coal_cost_pph22_export_amount"
                                      margin="dense"
                                      type="number"
                                      // variant='outlined'
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "12px",
                                        },
                                      }}
                                      value={
                                        this.state.coal_cost_pph22_export_amount
                                      }
                                      onChange={this.onPph22ExportChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH22 Export %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.coal_cost_pph22_export_percent}
                                  </div>
                                </div>
                                {this.state.purchase_currency ===
                                  this.state.coal_cost_billing_currency && (
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH22 Export in{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(
                                        this.state
                                          .coal_cost_pph22_export_value_local_cur
                                      )}
                                    </div>
                                  </div>
                                )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.purchase_currency !== "IDR" && (
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH22 Export in{" "}
                                        {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? " USD"
                                          : "IDR"}
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {/* {toLocaleString(
                                      Number(
                                        this.state
                                          .coal_cost_pph22_export_value_local_cur_new
                                      ).toFixed(0)
                                    )} */}
                                        {toLocaleString(
                                          this.state
                                            .coal_cost_pph22_export_value_local_cur
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="col-lg-6 p-2 rounded"
                          style={{ backgroundColor: "#f7f3f0" }}
                        >
                          <div className="row col-lg-12 p-0">
                            <h6>PPH22 Local</h6>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 pl-0 mb-2 pr-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Barge Quantity in MT
                              </label>
                              <div style={{ color: "#42352d" }}>
                                <TextField
                                  name="coal_cost_pph22_barge_quantity_in_mt"
                                  margin="dense"
                                  // variant='outlined'
                                  type="number"
                                  value={
                                    this.state
                                      .coal_cost_pph22_barge_quantity_in_mt
                                  }
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  onChange={this.onPph22LocalChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 p-0 mb-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Coal Price per MT in{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                              <div>
                                <TextField
                                  name="coal_cost_pph22_coal_price_per_mt"
                                  margin="dense"
                                  type="number"
                                  value={
                                    this.state.coal_cost_pph22_coal_price_per_mt
                                  }
                                  // variant='outlined'
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  onChange={this.onPph22LocalCoalPriceChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 p-0 mb-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                PPH22 Local %
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.state.coal_cost_pph22_percent}
                              </div>
                            </div>
                            {this.state.purchase_currency ===
                              this.state.coal_cost_billing_currency && (
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH22 Local in {this.state.purchase_currency}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(
                                    Number(
                                      this.state
                                        .coal_cost_pph22_percent_val_local_cur
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              this.state.purchase_currency !== "IDR" && (
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH22 Local in{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? " USD"
                                      : "IDR"}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {/* {this.state
                                      .coal_cost_pph22_percent_val_local_cur_new
                                      ? toLocaleString(
                                          Number(
                                            this.state
                                              .coal_cost_pph22_percent_val_local_cur_new
                                          ).toFixed(0)
                                        )
                                      : ""} */}
                                    {toLocaleString(
                                      Number(
                                        this.state
                                          .coal_cost_pph22_percent_val_local_cur
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/*=============================================
                    =   Advance Adjustment          =
                =============================================*/}
                  {/* <AdvanceAdjustments
                    adjustments={this.state.advance_adjustments}
                    vendorType="Coal"
                    bargeID={this.state.bargeList[0]}
                    callbackFn={(data) => {
                      this.setState({ advance_adjustments: data }, () => {
                        this.onAdvanceAdjustmentsCal();
                      });
                    }}
                  /> */}

                  {/* {(this.state.features.includes("365")  ||
                    this.state.features.includes("97") ) && ( */}
                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    feature_name="Purchase_fin_coal_costing"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
