import React, { useState, useEffect } from 'react';
import config from '../../../config/config';

import {
  MenuItem,
  TextField,
  ButtonGroup,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import QualitySpecs from "./QualitySpecs";
import IndexLinkCalculations from './IndexLinkCalculations';
import { SPECFICIATIONS } from '../constants';
import { toLocaleString } from '../../common/common';

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
    "&$checked + $track": {
      backgroundColor: config.themeColor,
    },
  },
  checked: {},
  track: {},
})(Switch);
export default function SalesQualities({ quality, mines, callbackFn }) {

  const [qualities, setQualities] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (quality) {
      setQualities(quality)
    }
  }, [quality])

  const AddMoreQualities = () => {
    const item = {
      quality: "",
      quantity_in_mt: "",
      tolerance: "",

      sales_types: [
        {
          mine: "",
          sales_type: "0",
          anchorage_name: "",
          price_pmt: "",
          sales_typeError: false,
          price_pmtError: false,
          coal_index_type: "Weekly",
          index_ids: [],
          average_coal_index_price: 0,
          prorata_gar: "0",
          prorata_coal_index_price: 0,
          bonus_coal_index_pmt: "",
          penality_coal_index_pmt: "",
          derivered_coal_index_pmt: "",
          final_coal_index_pmt: "",
          coal_index_remarks: "",
        },
      ],
      qualityError: false,
      quantity_in_mtError: false,
      toleranceError: false,
      anchorage_nameError: false,
      sales_term: "0",
      specification_standard: "0",
      specifications: SPECFICIATIONS
    };

    setQualities([...qualities, item]);
    callbackFn([...qualities, item]);
    forceUpdate();
  };

  const onPurchaseTermChange = (value, idx) => {
    var rows = qualities;
    rows[idx].sales_term = value;
    rows[idx].specification_standard = "ASTM";
    rows[idx].openDrawer = true;
    rows[idx].sales_termError = false;
    setQualities(rows);
    callbackFn(rows);
    forceUpdate();
  };


  const AddMorePurchaseTypes = (idx) => {
    const item = {
      mine: "",
      sales_type: "0",
      anchorage_name: "",
      price_pmt: "",
      coal_index_type: "Weekly",
      index_ids: [],
      average_coal_index_price: 0,
      prorata_gar: "0",
      prorata_coal_index_price: 0,
      bonus_coal_index_pmt: "",
      penality_coal_index_pmt: "",
      derivered_coal_index_pmt: "",
      final_coal_index_pmt: "",
      coal_index_remarks: "",
    };

    let rows = qualities
    let newRows = [...rows[idx].sales_types, item]
    rows[idx].sales_types = newRows;
    setQualities(rows)
    callbackFn(rows);
    forceUpdate();
  };

  const RemovePurchaseTypesHandler = (idx, index) => {
    const row = qualities[idx].sales_types;
    row.splice(index, 1);
    setQualities(qualities);
    callbackFn(qualities);
    forceUpdate();
  };


  const RemoveQualityHandler = (idx) => {
    const rows = qualities;
    rows.splice(idx, 1);
    setQualities(rows);
    callbackFn(rows);
    forceUpdate();
  };

  const purchaseTypeTextHandler = (idx) => (e) => {
    var rows = qualities;
    var name = e.target.name;
    if (name === "quality") {
      //const re = /^[0-9]*\.?[0-9]*$/;
      //if(e.target.value ==='' || re.test(e.target.value)) {
      rows[idx].qualityError = false;
      let findDuplicate = rows.find((x) => x.quality === e.target.value);
      if (findDuplicate) {
        rows[idx].qualityError = true;
        //alert("Quality already available");
        // qualityDuplicate = false;
      } else {
        //qualityDuplicate = true;
      }
      rows[idx].quality = e.target.value;
      //}
    }
    if (name === "quantity") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].quantity_in_mt = e.target.value;
        rows[idx].quantity_in_mtError = false;
        const { quantity_allocated = 0, sc_quantity_delivered = 0 } = rows[idx] || {};

        if (parseFloat(quantity_allocated) > parseFloat(rows[idx].quantity_in_mt) || parseFloat(sc_quantity_delivered) > parseFloat(rows[idx].quantity_in_mt)) {
          rows[idx].quantity_in_mtError = false;
          setQualities(rows);
          callbackFn(rows);
          forceUpdate();
          return;
        }
      }
    }
    if (name === "tolerance") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].tolerance = e.target.value;
        rows[idx].ToleranceError = false;
      }

      if (Number(rows[idx].tolerance) > 15) {
        alert("Tolerance value must not be greater than 15");
        rows[idx].tolerance = 15;
      }
    }
    if (e.target.name === "sales_term") {
      rows[idx].sales_term = e.target.value;
      rows[idx].sales_termError = false;
    }
    if (e.target.name === "specification_standard") {
      rows[idx].specification_standard = e.target.value;
      rows[idx].specification_standardError = false;
    }
    setQualities(rows);
    callbackFn(rows);
    forceUpdate();
  };

  const purchaseTypesRowTextHandler = (idx, index) => (e) => {
    var rows = qualities[idx].sales_types;
    if (e.target.name === "sales_type") {
      if (e.target.value !== "FAS Anchorage") {
        rows[index].anchorage_name = "";
      }
      rows[index].sales_type = e.target.value;
      rows[index].sales_typeError = false;
    }
    if (e.target.name === "anchorageName") {
      rows[index].anchorage_name = e.target.value;
    }
    if (e.target.name === "price_pmt") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[index].price_pmt = e.target.value;
        rows[index].price_pmtError = false;
      }
    }
    //TODO <!----- if salesType as CFR DP || CIF DP open Discharge Rate And Port of Discharge  -----!>
    // if (
    //     rows[index].sales_type === "CFR DP" ||
    //     rows[index].sales_type === "CIF DP"
    //   ) {
    //     this.setState({ dischargeRateField: true });
    //   }
    setQualities(qualities);
    callbackFn(qualities);
    forceUpdate();
  };

  return (
    <>
      {qualities.map((e, idx) => (
        <div className="row col-lg p-0" key={idx}>
          <div className="col-lg p-0">
            <div className="row col-lg pb-4 mb-2 section_background_contract">
              <div className="col-lg-4 pl-0">
                <label className="form_label mb-0"> Quality *</label>
                <TextField
                  name="quality"
                  margin="dense"
                  variant="outlined"
                  placeholder="Quality"
                  value={qualities[idx].quality}
                  onChange={purchaseTypeTextHandler(idx)}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  fullWidth
                  error={
                    qualities[idx].qualityError
                  }
                  InputProps={{
                    style: {
                      fontFamily: "Poppins",
                      backgroundColor: "white",
                      fontSize: "14px",
                    },
                  }}
                />
              </div>
              <div className="col-lg-4 pl-0">
                <label className="form_label mb-0"> Quantity (MT) * </label>
                <TextField
                  name="quantity"
                  margin="dense"
                  variant="outlined"
                  placeholder="Quantity in MT"
                  value={
                    qualities[idx]
                      .quantity_in_mt
                  }
                  onChange={purchaseTypeTextHandler(idx)}
                  fullWidth
                  error={
                    qualities[idx]
                      .quantity_in_mtError
                  }
                  InputProps={{
                    style: {
                      fontFamily: "Poppins",
                      backgroundColor: "white",
                      fontSize: "14px",
                    },
                  }}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                />
              </div>
              <div className="col-lg-4 p-0">
                <label className="form_label mb-0"> Tolerance (%) * </label>
                <TextField
                  name="tolerance"
                  margin="dense"
                  variant="outlined"
                  placeholder="Tolerance "
                  inputProps={{
                    style: { textTransform: "uppercase" },
                    maxLength: 2,
                  }}
                  value={
                    qualities[idx].tolerance
                  }
                  onChange={purchaseTypeTextHandler(idx)}
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "Poppins",
                      backgroundColor: "white",
                      fontSize: "14px",
                    },
                  }}
                  error={
                    qualities[idx]
                      .ToleranceError
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Sales Types
                </h5>
              </div>
              <div className="row mb-2 col-lg-12 p-0">
                {e.sales_types.map((key, index) => (
                  <div className='row col-lg-12 p-0'>

                    <div
                      className="row col-lg-11 border rounded-lg mb-2 pr-0"
                      key={index}
                    >
                      <div className="col-lg-4 pl-0" >
                        <Autocomplete
                          options={mines}
                          getOptionLabel={(option) =>
                            option.mine_name
                          }
                          onChange={(event, value) => {
                            var row =
                              qualities[idx]
                                .sales_types;
                            if (value) {
                              row[index].mine = value.value;
                              row[index].mine_name =
                                value.mine_name;
                            } else {
                              row[index].mine = "";
                              row[index].mine_name = "";
                            }
                          }}
                          size="small"
                          name="vendorName"
                          value={e.sales_types[index]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Mine Name"
                              label="Search Mine Name"
                              variant="outlined"
                              style={{
                                backgroundColor: "white",
                                top: 8,
                                borderRadius: "5px",
                              }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-4 pl-0">
                        <TextField
                          name="sales_type"
                          margin="dense"
                          label="Sales Type *"
                          variant="outlined"
                          placeholder="Sales Type"
                          value={
                            e.sales_types[index].sales_type
                          }
                          onChange={purchaseTypesRowTextHandler(
                            idx,
                            index
                          )}
                          fullWidth
                          error={
                            e.sales_types[index]
                              .sales_typeError
                          }
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              backgroundColor: "white",
                              border: "none",
                              fontSize: "14px",
                            },
                          }}
                          select
                        >
                          <MenuItem value="0" disabled key={-1}>
                            Please Select
                          </MenuItem>
                          ,
                          <MenuItem value="FOB Barge" key={0}>
                            FOB Barge
                          </MenuItem>
                          ,
                          <MenuItem value="FAS Anchorage" key={1}>
                            FAS Anchorage
                          </MenuItem>
                          ,
                          <MenuItem
                            value="FOB Mother Vessel"
                            key={2}
                          >
                            FOB Mother Vessel
                          </MenuItem>
                          ,
                          <MenuItem value="CFR DP" key={3}>
                            CFR DP
                          </MenuItem>
                          ,
                          <MenuItem value="CIF DP" key={4}>
                            CIF DP
                          </MenuItem>
                        </TextField>
                      </div>
                      {(key.sales_type ===
                        "FAS Anchorage" || key.sales_type === "FOB Barge") && (
                          <div className="col-lg-4 pl-0">
                            <TextField
                              name="anchorageName"
                              margin="dense"
                              variant="outlined"
                              label={key.sales_type === "FAS Anchorage" ? "Anchorage Name" : "Jetty Name"}
                              placeholder={key.sales_type === "FAS Anchorage" ? "Anchorage Name" : "Jetty Name"}
                              value={
                                qualities[idx]
                                  .sales_types[index]
                                  .anchorage_name
                              }
                              onChange={purchaseTypesRowTextHandler(
                                idx,
                                index
                              )}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  border: "none",
                                  fontSize: "14px",
                                },
                              }}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </div>
                        )}
                      <div className="col-lg-4 pr-0">
                        <label className="form_label mb-0">
                          Index Linked
                        </label>
                        <div>
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>No</Grid>
                              <Grid
                                item
                                style={{
                                  position: "relative",
                                  top: "3px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <PurpleSwitch
                                      checked={
                                        key.is_index_linked === "Yes"
                                      }
                                      onChange={(e) => {
                                        let row = qualities;
                                        let rows = qualities[idx].sales_types;
                                        if (e.target.checked) {
                                          rows[index].is_index_linked = "Yes";
                                          rows[index].openCoalIndexPop = true;
                                        }
                                        else {
                                          rows[index].is_index_linked = "No";
                                          rows[index].openCoalIndexPop = false;
                                        }
                                        setQualities(row);
                                        // setAnchorEn(e.currentTarget)
                                        callbackFn(row);
                                        forceUpdate();
                                      }}
                                      name="checkedA"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item>Yes</Grid>
                            </Grid>
                          </Typography>
                        </div>
                      </div>
                      {key.is_index_linked === "Yes" &&
                        <div className='col-lg-4'>
                          <label className="form_label mb-1">
                            Coal Price PMT
                          </label>
                          <div className=''>{toLocaleString(key.price_pmt)}</div>
                        </div>
                      }
                      {key.is_index_linked !== "Yes" &&

                        <div className="col-lg-4 pl-0">
                          <TextField
                            name="price_pmt"
                            margin="dense"
                            label="Coal Price PMT *"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            placeholder="Coal Price"
                            value={key.price_pmt}
                            onChange={purchaseTypesRowTextHandler(
                              idx,
                              index
                            )}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                backgroundColor: "white",
                                border: "none",
                                fontSize: "12px",
                              },
                            }}
                            error={
                              key.price_pmtError
                            }
                          />
                        </div>
                      }
                    </div>
                    {e.sales_types.length > 1 && (
                      <div className="col-lg-1 d-flex justify-content-center m-auto">
                        <div className="icon_hover text-align-center">
                          <i
                            className="fa fa-trash-o m-auto"
                            onClick={() => RemovePurchaseTypesHandler(
                              idx,
                              index
                            )}
                            style={{
                              fontSize: 24,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <IndexLinkCalculations
                      open={key.openCoalIndexPop}
                      grade={e.quality}
                      coal_index={key}
                      callbackFn={(coalIndex) => {

                        let row = qualities;
                        let rows = qualities[idx].sales_types;

                        if (coalIndex) {
                          rows[index].coal_index_type = coalIndex?.coal_index_type;
                          rows[index].index_ids = coalIndex?.index_ids?.toString();
                          rows[index].average_coal_index_price = coalIndex?.average_coal_index_price;
                          rows[index].prorata_gar = coalIndex?.prorata_gar;
                          rows[index].prorata_coal_index_price = coalIndex?.prorata_coal_index_price;
                          rows[index].bonus_coal_index_pmt = coalIndex?.bonus_coal_index_pmt;
                          rows[index].penality_coal_index_pmt = coalIndex?.penality_coal_index_pmt;
                          rows[index].derivered_coal_index_pmt = coalIndex?.derivered_coal_index_pmt;
                          rows[index].price_pmt = coalIndex?.final_coal_index_pmt;
                          // rows[index].final_coal_index_pmt = coalIndex?.final_coal_index_pmt;
                          rows[index].coal_index_remarks = coalIndex?.coal_index_remarks;
                        }
                        rows[index].openCoalIndexPop = false;

                        setQualities(row);
                        callbackFn(row);
                        forceUpdate();
                      }}

                    />
                  </div>
                ))}
                {/* {e.sales_types.map((key, index) => (
                  <div
                    className="row col-lg-12 mb-2 pl-0 pr-0"
                    key={index}
                  >
                    <div
                      className={
                        e.sales_types[index].sales_type === "FAS Anchorage" ? "col-lg-3 pl-0" : "col-lg-4 pl-0"
                      }
                    >
                      <Autocomplete
                        options={mines}
                        getOptionLabel={(option) =>
                          option.mine_name
                        }
                        onChange={(event, value) => {
                          var row =
                            qualities[idx]
                              .sales_types;
                          if (value) {
                            row[index].mine = value.value;
                            row[index].mine_name =
                              value.mine_name;
                          } else {
                            row[index].mine = "";
                            row[index].mine_name = "";
                          }
                        }}
                        size="small"
                        name="vendorName"
                        value={e.sales_types[index]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Mine Name"
                            label="Search Mine Name"
                            variant="outlined"
                            style={{
                              backgroundColor: "white",
                              top: 8,
                              borderRadius: "5px",
                            }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div
                      className={
                        e.sales_types[index].sales_type === "FAS Anchorage" ? "col-lg-3 pl-0" : "col-lg-4 pl-0"
                      }
                    >
                      <TextField
                        name="sales_type"
                        margin="dense"
                        label="Sales Type *"
                        variant="outlined"
                        placeholder="Sales Type"
                        value={
                          e.sales_types[index].sales_type
                        }
                        onChange={purchaseTypesRowTextHandler(
                          idx,
                          index
                        )}
                        fullWidth
                        error={
                          e.sales_types[index]
                            .sales_typeError
                        }
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            backgroundColor: "white",
                            border: "none",
                            fontSize: "14px",
                          },
                        }}
                        select
                      >
                        <MenuItem value="0" disabled key={-1}>
                          Please Select
                        </MenuItem>
                        ,
                        <MenuItem value="FOB Barge" key={0}>
                          FOB Barge
                        </MenuItem>
                        ,
                        <MenuItem value="FAS Anchorage" key={1}>
                          FAS Anchorage
                        </MenuItem>
                        ,
                        <MenuItem
                          value="FOB Mother Vessel"
                          key={2}
                        >
                          FOB Mother Vessel
                        </MenuItem>
                        ,
                        <MenuItem value="CFR DP" key={3}>
                          CFR DP
                        </MenuItem>
                        ,
                        <MenuItem value="CIF DP" key={4}>
                          CIF DP
                        </MenuItem>
                      </TextField>
                    </div>
                    {e.sales_types[index].sales_type ===
                      "FAS Anchorage" && (
                        <div
                          className={
                            e.sales_types[index]
                              .sales_type === "FAS Anchorage"
                              ? "col-lg-3 pl-0"
                              : "col-lg-4 pl-0"
                          }
                        >
                          <TextField
                            name="anchorageName"
                            margin="dense"
                            variant="outlined"
                            label="Anchorage Name"
                            placeholder="Anchorage Name"
                            value={
                              qualities[idx]
                                .sales_types[index]
                                .anchorage_name
                            }
                            onChange={purchaseTypesRowTextHandler(idx, index)}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                backgroundColor: "white",
                                border: "none",
                                fontSize: "14px",
                              },
                            }}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      )}
                    <div
                      className={
                        e.sales_types[index].sales_type ===
                          "FAS Anchorage"
                          ? "col-lg-2 pl-0"
                          : "col-lg-3 pl-0"
                      }
                    >
                      <TextField
                        name="price_pmt"
                        margin="dense"
                        label="Coal Price PMT *"
                        variant="outlined"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                        placeholder="Coal Price"
                        value={e.sales_types[index].price_pmt}
                        onChange={purchaseTypesRowTextHandler(
                          idx,
                          index
                        )}
                        fullWidth
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            backgroundColor: "white",
                            border: "none",
                            fontSize: "12px",
                          },
                        }}
                        error={
                          e.sales_types[index].price_pmtError
                        }
                      />
                    </div>
                    {e.sales_types.length > 1 && (
                      <div className="col-lg-1 d-flex justify-content-center m-auto">
                        <div className="icon_hover text-align-center">
                          <i
                            className="fa fa-trash-o m-auto"
                            onClick={() => RemovePurchaseTypesHandler(
                              idx,
                              index
                            )}
                            style={{
                              fontSize: 24,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))} */}
              </div>
              <br />
              <div className="row col-lg-12 mb-2 pl-0">
                <button
                  type="button"
                  onClick={() => AddMorePurchaseTypes(idx)}
                  className="header_button header_button_text addrow_button_adjustment"
                  style={{
                    width: 160,
                    border: "none",
                    color: config.themeColor,
                    backgroundColor: "#f4f2f0",
                  }}
                >
                  Add Sales Type
                </button>
              </div>

              <div className="row col-lg-12 mb-2 pl-0">
                <div className="col-lg-6 pl-0">
                  <label className="form_label mb-2"> Sales Term  <span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        onClick={() => onPurchaseTermChange("Specs", idx)}
                        style={{
                          textTransform: "capitalize",
                          backgroundColor:
                            qualities[idx]
                              .sales_term === "Specs"
                              ? config.themeColor
                              : "#eae4e2",
                          color:
                            qualities[idx]
                              .sales_term !== "Specs"
                              ? "#483b34b3"
                              : "white",
                        }}
                      >
                        Specs
                      </Button>
                      <Button
                        onClick={() => onPurchaseTermChange("Non Specs", idx)}
                        style={{
                          textTransform: "capitalize",
                          backgroundColor:
                            qualities[idx]
                              .sales_term === "Non Specs"
                              ? config.themeColor
                              : "#eae4e2",
                          color:
                            qualities[idx]
                              .sales_term !== "Non Specs"
                              ? "#483b34b3"
                              : "white",
                        }}
                      >
                        Non Specs
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              {qualities[idx]
                .specification_standard !== "0" && (
                  <Button
                    className="mt-2"
                    onClick={() => onPurchaseTermChange(qualities[idx].sales_term, idx)}
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#fff",
                    }}
                  >
                    {
                      qualities[idx]
                        .specification_standard
                    }
                    <i
                      className="fa fa-pencil stepper_sub_icon"
                      aria-hidden="true"
                      style={{
                        top: 2,
                        marginLeft: 10,
                        color: "#000",
                      }}
                    />
                  </Button>
                )}
              <QualitySpecs
                open={e.openDrawer}
                specs={e.specifications}
                specification_standard={e.specification_standard}
                closeDrawerHandler={() => {
                  let row = qualities;
                  row[idx].openDrawer = false;
                  setQualities(row);
                  callbackFn(row);
                  forceUpdate();
                }}
                saveSpecsHandler={(specStandard, specs) => {
                  let row = qualities;
                  row[idx].specification_standard = specStandard;
                  row[idx].specifications = specs;
                  row[idx].openDrawer = false;
                  setQualities(row);
                  callbackFn(row);
                  forceUpdate();

                }}
              />
            </div>
          </div>
          {(qualities.length > 1 && e.sc_quantity_delivered <= 0 && parseFloat(e.quantity_allocated) <= 0) && (
            <div className="icon_hover d-flex justify-content-center m-auto text-align-center m-auto">
              <i
                className="fa fa-trash-o m-auto"
                onClick={() => RemoveQualityHandler(idx)}
                style={{
                  fontSize: 24,
                  color: config.themeColor,
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
      ))}

      <div className="row mb-3">
        <div className="col-lg-6 p-0">
          <div style={{ padding: 5 }}>
            <button
              type="button"
              onClick={AddMoreQualities}
              className="header_button header_button_text addrow_button_adjustment"
              style={{ color: config.themeColor, width: 110 }}
            >
              Add Quality
            </button>
          </div>
        </div>
      </div>

    </>
  )
}
