import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import CoalProChargesAdd from "./Coal-Procurement-Charges-Add";
import CoalProChargesView from "./Coal-Procurement-Charges-View";
import CoalProChargesUpdate from "./Coal-Procurement-Charges-Update";

import { TextField, Snackbar, Checkbox, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CoalProcurementChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      cpc_costing_list: [],
      isLoading: true,
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_ids: [],
      coal_vendor_drop_value: [],
      coal_vendor_ids: [],
      pc_contrcts_drop_value: [],
      pc_contract_ids: [],
      features: [],
      showDrawer: false,
      CoalProChargesAdd: false,
      CoalProChargesView: false,
      CoalProChargesUpdate: false,
      comboID: null,
      updateDocuments: true,
      deleteCosting: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.indexOf("143") >= 0 || features.indexOf("97") >= 0)) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "Active";
      await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                let business_no_drop_value = [];
                for (var i of res.business_numbers) {
                  business_no_drop_value.push({
                    name: i.business_no,
                    value: i.id,
                    mother_vessel_name: i.mother_vessel_name,
                  });
                }
                this.setState({
                  business_no_drop_value: business_no_drop_value,
                  //loading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      await api
        .getPurchaseContracts(loginUserID, "", idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.purchase_contract) {
                  let purchaseDataforMenu = [];
                  const filteredPContracts = res.purchase_contract.filter(
                    (e, indx) =>
                      e.status === "Active" ||
                      e.status === "Freezed" ||
                      e.status === "Draft"
                  );
                  for (var i of filteredPContracts) {
                    purchaseDataforMenu.push({
                      name: i.contract_no,
                      value: i.id,
                      key: i,
                      vendor_name: i.vendor_name,
                    });
                  }

                  this.setState({
                    pc_contrcts_drop_value: purchaseDataforMenu,
                    //purchaseContractList: res.purchase_contract,
                    purchaseContractList: filteredPContracts,
                    //isLoading: false
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                const coal_vendor_drop_value = [];
                for (var i of res.vendors) {
                  if (i.vendor_type === "Coal") {
                    coal_vendor_drop_value.push({
                      name: i.vendor_name,
                      value: i.id,
                    });
                  }
                }
                this.setState({
                  coal_vendor_drop_value: coal_vendor_drop_value,
                  isLoading: false,
                });
              }
            }
          });
        }
      });
    }
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let purchase_contract_id = this.state.pc_contract_ids;
    let business_no = this.state.business_no_ids;
    let coal_vendor = this.state.coal_vendor_ids;
    api
      .get_barges_for_pur_fin_cpc(
        loginUserID,
        idToken,
        business_no,
        purchase_contract_id,
        coal_vendor
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  cpc_costing_list: purchase_contracts_barges.reverse(),
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var costing_id = data.id;
    api
      .delete_barge_pur_fin_cpc(loginUserID, idToken, costing_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: res.message,
                errorMsg: "",
                isLoading: false,
                deleteCosting: false,
              });
              this.searchBargeHandler();
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                deleteCosting: true,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // successMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        CoalProChargesUpdate: false,
        CoalProChargesAdd: false,
        CoalProChargesView: true,
        updateDocuments: false,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        CoalProChargesAdd: false,
        CoalProChargesView: false,
        showDrawer: false,
        updateDocuments: false,
      });
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        CoalProChargesUpdate: false,
        CoalProChargesAdd: false,
        CoalProChargesView: false,
        showDrawer: false,
        comboID: null,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        CoalProChargesAdd: false,
        CoalProChargesView: false,
        CoalProChargesUpdate: true,
        showDrawer: true,
        updateDocuments: false,
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.CoalProChargesAdd) {
      drawerComponent = (
        <CoalProChargesAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.CoalProChargesView && this.state.comboID) {
      drawerComponent = (
        <CoalProChargesView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              CoalProChargesUpdate: true,
              CoalProChargesView: false,
              CoalProChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.CoalProChargesUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProChargesUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 850 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="row mb-1 p-2">
            <div className="col-lg-4 p-0">
              <Autocomplete
                multiple
                options={this.state.business_no_drop_value}
                getOptionLabel={(option) => option.mother_vessel_name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">
                        {/* {option.name} */}
                        {option.mother_vessel_name}
                      </label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {"( " + option.name + " )"}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ business_no_ids: data });
                  } else {
                    this.setState({ business_no_ids: [] });
                  }
                }}
                disableCloseOnSelect
                name="Business_Number"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Business No"
                    label="Business No"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-4 pr-0">
              <Autocomplete
                multiple
                options={this.state.pc_contrcts_drop_value}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}{" "}
                    {option.vendor_name
                      ? "( " + option.vendor_name + " )"
                      : null}
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ pc_contract_ids: data });
                  } else {
                    this.setState({ pc_contract_ids: [] });
                  }
                }}
                disableCloseOnSelect
                name="Purchase Contract"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Purchase Contract"
                    label="Purchase Contract"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-4 pr-0">
              <Autocomplete
                multiple
                options={this.state.coal_vendor_drop_value}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ coal_vendor_ids: data });
                  } else {
                    this.setState({ coal_vendor_ids: [] });
                  }
                }}
                disableCloseOnSelect
                name="Coal Supplier"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Coal Supplier"
                    label="Coal Supplier"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col mt-4 p-0 float-sm-left">
              <button
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "15px" }}
                onClick={() => this.searchBargeHandler()}
              >
                Load
              </button>
            </div>
            {(this.state.features.indexOf("142") >= 0 ||
              this.state.features.indexOf("97") >= 0) && (
              <div className="col pr-0 mt-4 text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "16px" }}
                  onClick={() =>
                    this.setState({ CoalProChargesAdd: true, showDrawer: true })
                  }
                >
                  New
                </button>
              </div>
            )}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>

          {this.state.cpc_costing_list.length > 0 && (
            <div
              className={
                this.state.cpc_costing_list.length > 0
                  ? "shadow-sm p-2 mb-2 bg-white border"
                  : ""
              }
            >
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm mb-0">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true" className=" text-uppercase"></th>
                        <th nowrap="true" className=" text-uppercase">
                          Costing ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge Nomination
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Business No
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge Vendor
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Quantity
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Price PMT
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Total Payable
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Paid
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    {this.state.cpc_costing_list.map((p, index) => (
                      <tbody key={index}>
                        {p.new_barges.map((idr, indx) => (
                          <tr key={indx} className="rounded">
                            {indx === 0 && (
                              <td
                                className=""
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 22,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      CoalProChargesView: true,
                                      comboID: idr.id,
                                      showDrawer: true,
                                    })
                                  }
                                ></i>
                                {(this.state.features.indexOf("185") >= 0 ||
                                  this.state.features.indexOf("97") >= 0) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: 21,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          comboID: idr.id,
                                          deleteData: idr,
                                          deleteCosting: true,
                                        })
                                      }
                                    ></i>
                                  )}
                              </td>
                            )}
                            {indx === 0 && (
                              <td rowSpan={p.new_barges.length}>
                                {idr.costing_no}
                              </td>
                            )}
                            <td className=" text-left">{pad(idr.barge_id)}</td>
                            <td className=" text-left" nowrap="true">
                              {idr.barge_nomination}
                            </td>
                            <td className=" text-left" nowrap="true">
                              {idr.business_no}
                            </td>
                            <td className=" text-left" nowrap="true">
                              {idr.vendor}
                            </td>
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.barge_quantity)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.price_per_mt)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.total_payable)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.paid)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.Remaining_amount)}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          )}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() =>
                this.deleteBargePurchaseFinancial(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
