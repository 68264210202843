import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";

import {
  TextField,
  Snackbar,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class DocAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      dc_applicable: "Yes",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      dc_quantity_in_mt: "",
      dc_documentation_party: "Third Party",
      dc_documentation_by: "",
      dc_price_type: "Per MT",
      dc_price_per_mt: "",
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "0",
      dc_currency_xchg_rate: "",
      dc_ctc_in_local_currency: "",
      dc_base_price: "",
      dc_vat_percent: "10",
      dc_vat_value: "",
      dc_ctc: "",
      dc_total: "",
      dc_pph23_percent: "2",
      dc_pph23_value: "",
      isLoading: true,
      activeStep: 0,
      barge_id: null,
      business_no_drop_value: [],
      documentation_agent_drop_value: [],
      documentation_agent_ids: [],
      business_no_id: [],
      barges: [],
      bargeList: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_doc_charges",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("182") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              sucessMsg: "",
              // isLoading: false
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // calling Vendors Api
    let status = "Active";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [];
              const vendorAsOthers = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({ name: i.vendor_name, value: i.id, key: i });
                }
                if (i.vendor_type === "Others" || i.vendor_type === "Coal") {
                  vendorAsOthers.push({
                    name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorTypeAsOthers: vendorAsOthers,
                documentation_agent_drop_value: vendorAsOthers,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (!this.state.barge_id) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select barge.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  onSelectBarge(barge_data) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = barge_data.id;
    if (barge_data) {
      api
        .get_purchase_contract_data_by_barge_id(loginUserID, idToken, barge_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              var barge_quantity = "";
              if (res.purchase_contract_data) {
                barge_quantity = barge_data.barge_quantity_in_mt;
                if (barge_quantity === null || Number(barge_quantity) <= 0) {
                  barge_quantity = barge_data.barge_planned_quantity_in_mt;
                } else if (
                  barge_data.barge_planned_quantity_in_mt === null ||
                  Number(barge_data.barge_planned_quantity_in_mt) <= 0
                ) {
                  barge_quantity = barge_data.barge_quantity_in_mt;
                }

                var redraftQuantity = barge_data.barge_quantity_in_mt
                  ? barge_data.barge_quantity_in_mt
                  : "";
                if (
                  res.purchase_contract_data.purchase_type ===
                    "FAS Anchorage" &&
                  barge_data.redraft_quantity !== null
                ) {
                  redraftQuantity = barge_data.redraft_quantity;
                }
                this.setState({
                  dc_quantity_in_mt:
                    res.purchase_contract_data.purchase_type === "FAS Anchorage"
                      ? redraftQuantity
                      : barge_quantity,
                  dc_laprn_quantity_in_mt:
                    res.purchase_contract_data.purchase_type === "FAS Anchorage"
                      ? redraftQuantity
                      : barge_quantity,
                  operationalBargeQuantity:
                    res.purchase_contract_data.purchase_type === "FAS Anchorage"
                      ? redraftQuantity
                      : barge_quantity,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    }
  }

  documentation_charges_calculations_handler() {
    var dc_base_price = 0;
    if (this.state.dc_documentation_party === "Third Party") {
      dc_base_price = (
        Number(this.state.dc_quantity_in_mt) *
        Number(this.state.dc_price_per_mt)
      ).toFixed(4);
    } else {
      //dc_base_price = Number(this.state.dc_quantity_in_mt);
    }
    let dc_currency_xchg_rate = this.state.dc_currency_xchg_rate
      ? this.state.dc_currency_xchg_rate
      : 1;

    dc_base_price = (
      (Number(this.state.dc_quantity_in_mt) *
        Number(this.state.dc_price_per_mt)) /
      Number(dc_currency_xchg_rate)
    ).toFixed(4);

    if (this.state.dc_price_type === "Lumpsum") {
      dc_base_price = (
        Number(this.state.dc_price_per_mt) / Number(dc_currency_xchg_rate)
      ).toFixed(4);
    }

    let dc_vat_value = (
      (Number(dc_base_price) * Number(this.state.dc_vat_percent)) /
      100
    ).toFixed(2);
    let dc_pph23_value = (
      (Number(dc_base_price) * Number(this.state.dc_pph23_percent)) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dc_pph23_value = 0;
    }
    let dc_total = (
      Number(dc_base_price) +
      Number(dc_vat_value) -
      Number(dc_pph23_value)
    ).toFixed(2);
    let dc_ctc = (Number(dc_base_price) + Number(dc_vat_value)).toFixed(2);
    let dc_ctc_in_local_currency = dc_ctc;

    this.setState({
      dc_base_price: dc_base_price,
      dc_vat_value: dc_vat_value,
      dc_pph23_value: dc_pph23_value,
      dc_total: dc_total,
      dc_ctc: dc_ctc,
      dc_ctc_in_local_currency: dc_ctc_in_local_currency,
    });
  }

  submitHandler = () => {
    let {
      invoice_no,
      invoice_date,
      ppn,
      dc_applicable,
      dc_documentation_party,
      dc_documentation_by,
      dc_quantity_in_mt,
      dc_price_type,
      dc_price_per_mt,
      dc_base_price,
      dc_pph23_account,
      dc_vat_percent,
      dc_vat_value,
      dc_pph23_percent,
      dc_pph23_value,
      dc_total,
      dc_ctc,
      billing_currency,
      dc_currency_xchg_rate,
      dc_ctc_in_local_currency,
      costing_files,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_id = this.state.barge_id;

    if (
      billing_currency === "USD" &&
      (config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS")
    ) {
      dc_currency_xchg_rate = 1;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    const costing = costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    api
      .add_barge_doc_charges(
        loginUserID,
        idToken,
        barge_id,
        invoice_no,
        invoice_date,
        ppn,
        dc_applicable,
        dc_documentation_party,
        dc_documentation_by,
        dc_quantity_in_mt,
        dc_price_type,
        dc_price_per_mt,
        dc_base_price,
        dc_pph23_account,
        dc_vat_percent,
        dc_vat_value,
        dc_pph23_percent,
        dc_pph23_value,
        dc_total,
        dc_ctc,
        billing_currency,
        dc_currency_xchg_rate,
        dc_ctc_in_local_currency,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onAddResponse(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Created sucessfully",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  async searchBargeHandler() {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const financial_type = "barge_doc_charges";
    const business_no = this.state.business_no_id;
    const documentation_agent = this.state.documentation_agent_ids;
    await api
      .get_barges_for_doc(
        loginUserID,
        idToken,
        financial_type,
        business_no,
        documentation_agent
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_no_costing_barges = res.barges.filter(
                  (e) => e.doc_costing === null && e.status === "Active"
                );
                if (filter_no_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: filter_no_costing_barges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "8px 20px", margin: "0px" }}>
                  Barge Purchase Financial Documentation Charges
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>

              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Documentation Charges
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
            </div>
            <div className="card">
              <div className="card-body border_dashed">
                {this.state.activeStep === 0 && (
                  <div className=" w-100 m-0">
                    <div className="row  mb-1 p-2">
                      <div className="col-lg-5 pl-0 mb-2">
                        <Autocomplete
                          multiple
                          options={this.state.business_no_drop_value}
                          getOptionLabel={(option) => option.mother_vessel_name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <CheckBox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.mother_vessel_name}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{ fontSize: 10, paddingLeft: 5 }}
                                >
                                  {"( " + option.name + " )"}
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              var data = [];
                              for (var i of value) {
                                data.push(i.value);
                              }
                              this.setState({
                                business_no_id: data,
                                business_no_value: value,
                              });
                            } else {
                              this.setState({
                                business_no_id: [],
                                business_no_value: { name: "", value: "" },
                              });
                            }
                          }}
                          disableCloseOnSelect
                          value={this.state.business_no_value}
                          name="Business_Number"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Business No"
                              label="Business No"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0 mb-2">
                        <Autocomplete
                          multiple
                          options={this.state.documentation_agent_drop_value}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <CheckBox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              var data = [];
                              for (var i of value) {
                                data.push(i.value);
                              }
                              //var floating_crane_vendor = data.toString();
                              this.setState({
                                documentation_agent_ids: data,
                                documentation_value: value,
                              });
                            } else {
                              this.setState({
                                documentation_agent_ids: [],
                                documentation_value: { name: "", value: "" },
                              });
                            }
                          }}
                          disableCloseOnSelect
                          name="Documentation Charges"
                          size="small"
                          value={this.state.documentation_value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Documentation Agent"
                              label="Documentation Agent"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>

                      <div className="col p-0 mt-2">
                        <button
                          className="header_button header_button_text add_button_adjustment"
                          style={{ width: "auto", marginTop: "15px" }}
                          onClick={() => this.searchBargeHandler()}
                        >
                          Load Barges
                        </button>
                      </div>
                    </div>

                    {this.state.barges.length > 0 && (
                      <div className="row">
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Select Barge to create costing
                          </h5>
                        </div>
                        <div
                          className="table border-0"
                          style={{ overflowX: "scroll" }}
                        >
                          <table className="table table-borderless border-0">
                            <thead className="border-0">
                              <tr>
                                <th
                                  nowrap="true"
                                  className="p-0 text-center pt-1 border-0"
                                ></th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-left table_header_barge_label text-uppercase border-0"
                                >
                                  Barge ID
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-left table_header_barge_label text-uppercase border-0"
                                >
                                  Barge Name
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-left table_header_barge_label text-uppercase border-0"
                                >
                                  Jetty
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-left table_header_barge_label text-uppercase border-0"
                                >
                                  Loading Date
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-left table_header_barge_label text-uppercase border-0"
                                >
                                  Business No
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  nowrap="true"
                                  className="text-right table_header_barge_label text-uppercase border-0"
                                >
                                  Quantity
                                </th>
                              </tr>
                            </thead>
                            {this.state.barges.map((p, index) => (
                              <tbody key={index}>
                                <tr className="rounded">
                                  <td className="p-0 text-center">
                                    <NewRadio
                                      onClick={() => {
                                        this.setState({
                                          barge_id: p.id,
                                        });
                                      }}
                                      checked={p.id === this.state.barge_id}
                                      color="primary"
                                      size="small"
                                    />
                                  </td>
                                  <td>{pad(p.id)}</td>
                                  <td nowrap="true">{p.barge_nomination}</td>
                                  <td nowrap="true">{p.loading_jetty}</td>
                                  <td nowrap="true">
                                    {localDateFormate(p.loading_date)}
                                  </td>
                                  <td nowrap="true">{p.business_no}</td>
                                  <td className="text-right" nowrap="true">
                                    {toLocaleString(p.barge_quantity_in_mt)}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div>
                    <div style={{ width: "100%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create Documentation Charges costing
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 rounded_lg p-2 mb-2">
                        <div className="row mb-3">
                          <div className="col-lg-5 pl-0">
                            <label className="form_label mb-0">
                              Documentation Charges Applicable
                            </label>
                            <RadioGroup
                              aria-label="dc_applicable"
                              name="dc_applicable"
                              onChange={(e) => {
                                this.setState({
                                  dc_applicable: e.target.value,
                                });
                              }}
                              style={{ flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="Yes"
                                control={
                                  <NewRadio
                                    checked={this.state.dc_applicable === "Yes"}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="No"
                                control={
                                  <NewRadio
                                    checked={this.state.dc_applicable === "No"}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </div>
                          {this.state.dc_applicable === "Yes" && (
                            <div className="row">
                              <div className="col-lg-6 pl-0 mb-2">
                                <div className="row">
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Documentation Party
                                    </label>
                                    <RadioGroup
                                      aria-label="dc_documentation_party"
                                      name="dc_documentation_party"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            dc_documentation_party:
                                              e.target.value,
                                          },
                                          () => {
                                            this.documentation_charges_calculations_handler();
                                          }
                                        );
                                      }}
                                      style={{ flexDirection: "row" }}
                                    >
                                      <FormControlLabel
                                        value="Third Party"
                                        control={
                                          <NewRadio
                                            checked={
                                              this.state
                                                .dc_documentation_party ===
                                              "Third Party"
                                            }
                                          />
                                        }
                                        label="Third Party"
                                      />
                                      <FormControlLabel
                                        value="Internal"
                                        control={
                                          <NewRadio
                                            checked={
                                              this.state
                                                .dc_documentation_party ===
                                              "Internal"
                                            }
                                          />
                                        }
                                        label="Internal"
                                      />
                                    </RadioGroup>
                                  </div>

                                  <div className="col-lg-12 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Documentation by
                                    </label>
                                    <Autocomplete
                                      options={this.state.vendorTypeAsOthers}
                                      getOptionLabel={(option) => option.name}
                                      size="small"
                                      onChange={(event, value) => {
                                        if (value) {
                                          this.setState({
                                            dc_documentation_by: value.value,
                                            dc_documentation_by_name: value,
                                          });
                                        } else {
                                          this.setState({
                                            dc_documentation_by: "",
                                            dc_documentation_by_name: {
                                              name: "",
                                              value: "",
                                            },
                                          });
                                        }
                                      }}
                                      name="vendorName"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Search Vendor"
                                          variant="outlined"
                                          //error={this.state.vendorTypeError}
                                          fullWidth
                                        />
                                      )}
                                      className="mt-2"
                                    />
                                  </div>

                                  <div className="col-lg-12 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Quantity in MT
                                    </label>
                                    <TextField
                                      name="dc_quantity_in_mt"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          // fontSize: '14px'
                                        },
                                      }}
                                      value={this.state.dc_quantity_in_mt}
                                      placeholder="Quantity in MT"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          quan.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dc_quantity_in_mt: e.target.value,
                                            },
                                            () => {
                                              this.documentation_charges_calculations_handler();
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-12 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Price Type
                                    </label>
                                    <RadioGroup
                                      aria-label="dc_price_type"
                                      name="dc_price_type"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            dc_price_type: e.target.value,
                                          },
                                          () =>
                                            this.documentation_charges_calculations_handler()
                                        );
                                      }}
                                      style={{ flexDirection: "row" }}
                                    >
                                      <FormControlLabel
                                        value="Per MT"
                                        control={
                                          <NewRadio
                                            checked={
                                              this.state.dc_price_type ===
                                              "Per MT"
                                            }
                                          />
                                        }
                                        label="Per MT"
                                      />
                                      <FormControlLabel
                                        value="Lumpsum"
                                        control={
                                          <NewRadio
                                            checked={
                                              this.state.dc_price_type ===
                                              "Lumpsum"
                                            }
                                          />
                                        }
                                        label="Lumpsum"
                                      />
                                    </RadioGroup>
                                  </div>

                                  {this.state.dc_documentation_party ===
                                    "Third Party" && (
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        {this.state.dc_price_type === "Per MT"
                                          ? "Price"
                                          : "Lumpsum"}
                                      </label>
                                      <TextField
                                        name="dc_price_per_mt"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.dc_price_per_mt}
                                        placeholder={
                                          this.state.dc_price_type === "Per MT"
                                            ? "Price"
                                            : "Lumpsum"
                                        }
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            this.setState(
                                              {
                                                dc_price_per_mt: e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (config.company ===
                                            "SRPL" ||
                                            config.company === "PRPL" ||
                                            config.company === "AVS") && (
                                            <InputAdornment
                                              className="adornment_background"
                                              // variant="outlined"
                                              position="end"
                                              style={{
                                                marginRight: "-14px",
                                                width: "50%",
                                              }}
                                            >
                                              <TextField
                                                name="billing_currency"
                                                margin="dense"
                                                // variant='outlined'
                                                fullWidth
                                                select
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    margin: 0,
                                                  },
                                                  disableUnderline: true,
                                                }}
                                                value={
                                                  this.state.billing_currency
                                                }
                                                onChange={(e) => {
                                                  this.setState(
                                                    {
                                                      billing_currency:
                                                        e.target.value,
                                                    },
                                                    () => {
                                                      this.documentation_charges_calculations_handler();
                                                    }
                                                  );
                                                }}
                                              >
                                                {this.state.currencyData}
                                              </TextField>
                                            </InputAdornment>
                                          ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                      />
                                    </div>
                                  )}
                                  {this.state.dc_documentation_party ===
                                    "Internal" &&
                                    (config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL") && (
                                      <div className="col-lg-12 p-0 mb-2">
                                        <label className="form_label mb-0">
                                          Currency
                                        </label>
                                        <TextField
                                          name="billing_currency"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          placeholder="Currency"
                                          label="Currency"
                                          value={this.state.billing_currency}
                                          select
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                billing_currency:
                                                  e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </div>
                                    )}
                                  {(config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL") &&
                                    this.state.billing_currency !== "USD" &&
                                    this.state.billing_currency !== "0" && (
                                      <div className="col-lg-12 p-0 mb-2">
                                        <label className="form_label mb-0">
                                          Currency Exchange Rate
                                        </label>
                                        <TextField
                                          name="dc_currency_xchg_rate"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          placeholder="Exchange rate to USD"
                                          value={
                                            this.state.dc_currency_xchg_rate
                                          }
                                          type="number"
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                dc_currency_xchg_rate:
                                                  e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                className="col-lg-6 p-2 rounder"
                                style={{ backgroundColor: "#f7f3f0" }}
                              >
                                <div className="row col-lg-12 p-1">
                                  <h6>Documentation Charges</h6>
                                </div>
                                <div className="row pl-2">
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Base Price
                                    </label>
                                    <TextField
                                      name="dc_base_price"
                                      margin="dense"
                                      variant="standard"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      //fullWidth
                                      value={this.state.dc_base_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var value = e.target.value;
                                          if (value === "") {
                                            value = "1";
                                          }
                                          var dc_vat_value = (
                                            (Number(value) *
                                              Number(
                                                this.state.dc_vat_percent
                                              )) /
                                            100
                                          ).toFixed(0);
                                          let dc_pph23_value = (
                                            (Number(value) *
                                              Number(
                                                this.state.dc_pph23_percent
                                              )) /
                                            100
                                          ).toFixed(0);
                                          if (
                                            config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                          ) {
                                            dc_pph23_value = 0;
                                          }
                                          let dc_ctc = (
                                            Number(e.target.value) +
                                            Number(dc_vat_value)
                                          ).toFixed(2);
                                          let dc_ctc_in_local_currency = dc_ctc;
                                          this.setState({
                                            dc_base_price: e.target.value,
                                            dc_vat_value: dc_vat_value,
                                            dc_pph23_value: dc_pph23_value,
                                            dc_total: (
                                              Number(e.target.value) +
                                              Number(dc_vat_value) -
                                              Number(dc_pph23_value)
                                            ).toFixed(2),
                                            dc_ctc: dc_ctc,
                                            dc_ctc_in_local_currency:
                                              dc_ctc_in_local_currency,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-4 mb-2 pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dc_vat_percent}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 mb-2 p-0">
                                    <label className="form_label mb-0">
                                      VAT
                                    </label>
                                    <TextField
                                      name="dc_vat_value"
                                      margin="dense"
                                      variant="standard"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={this.state.dc_vat_value}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dc_pph23_value = (
                                            (Number(this.state.dc_base_price) *
                                              Number(
                                                this.state.dc_pph23_percent
                                              )) /
                                            100
                                          ).toFixed(0);
                                          if (
                                            config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                          ) {
                                            dc_pph23_value = 0;
                                          }
                                          let dc_ctc = (
                                            Number(this.state.dc_base_price) +
                                            Number(e.target.value)
                                          ).toFixed(2);
                                          let dc_ctc_in_local_currency = "";
                                          if (
                                            (config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL") &&
                                            this.state.billing_currency !==
                                              "USD" &&
                                            this.state.billing_currency !== "0"
                                          ) {
                                            dc_ctc_in_local_currency = (
                                              Number(
                                                this.state.dc_currency_xchg_rate
                                              ) * Number(dc_ctc)
                                            ).toFixed(0);
                                          }
                                          this.setState({
                                            dc_vat_value: e.target.value,
                                            dc_pph23_value: dc_pph23_value,
                                            dc_total:
                                              Number(this.state.dc_base_price) +
                                              Number(e.target.value) -
                                              Number(dc_pph23_value),
                                            dc_ctc: dc_ctc,
                                            dc_ctc_in_local_currency:
                                              dc_ctc_in_local_currency,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") && (
                                    <div className="col-lg-6 mb-2 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23 %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.dc_pph23_percent}
                                      </div>
                                    </div>
                                  )}
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") && (
                                    <div className="col-lg-6 mb-2 pr-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(
                                          this.state.dc_pph23_value
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-lg-6 mb-2 p-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(this.state.dc_total)}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2 pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(this.state.dc_ctc)}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* {(this.state.features.includes('368')  || this.state.features.includes('97') ) && */}

                        <FileUpload
                          files={this.state.files}
                          type="Costings"
                          feature_name="Barge_doc_charges"
                          callbackFn={(data, mandatoryFiles) => {
                            this.setState({
                              files: data,
                              costing_files: mandatoryFiles,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
