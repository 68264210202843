import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import NavIcon3 from "../../images/nav_icon/Angle-double-left@3x.png";
import Config from "../../config/config";
import Logo from "../../images/SDAM_Logo.png";
// import generic_expense_icon from '../../images/expense.png';
import VendorIcon from "../../images/icons/sidebar_vendor_icon.svg";
import ExpandIcon from "../../images/nav_icon/Angle-double-right@3x.png";
import Barges from "../../images/nav_icon/Barges.svg";
import AccessManagement from "../../images/nav_icon/Access-Managment.svg";
import DashboardIcon from "../../images/icons/dashboard_icon.svg";
import SalesIcon from "../../images/icons/sales_icon.svg";
import PurchaseIcon from "../../images/icons/purchase_icon.svg";
import BusinessIcon from "../../images/icons/business_icon.svg";
import CustomerIcon from "../../images/icons/customer_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDolly,
  faUserEdit,
  faUserCheck,
  faMoneyBillWaveAlt,
} from "@fortawesome/free-solid-svg-icons";
import Popover from "@material-ui/core/Popover";
import { MenuItem } from "@material-ui/core";
import CookieHandler from "./CookieHandler";
import config from "../../config/config";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: Config.themeColor,
    backgroundColor: "#ffffff",
    maxHeight: 50,
    boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 24,
    position: "relative",
    left: -5,
    top: -7,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1, 0, 0),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    maxHeight: 50,
    minHeight: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function NewSidebar() {
  const classes = useStyles();
  // const theme = useTheme();
  const drawerStatus = new CookieHandler().getCookie("drawerStatus");
  // console.log(drawerStatus);
  const [open, setOpen] = React.useState(drawerStatus);
  const [addOpen, addButtonOpen] = React.useState(false);
  const [anchorEn, anchorEnData] = React.useState("");
  const [anchorEr, anchorElData] = React.useState("");
  const [openDrawer, openDrawerList] = React.useState(false);
  const loginUserFullName = new CookieHandler().getCookie("loginUserFullName");

  const handleDrawerOpen = () => {
    setOpen(true);
    new CookieHandler().setCookie("drawerStatus", true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    new CookieHandler().setCookie("drawerStatus", false);
  };

  const addButtonClose = () => {
    addButtonOpen(false);
  };
  const addButton = (event) => {
    event.preventDefault();
    // alert('inside')
    addButtonOpen(true);
    openDrawerList(false);
    anchorEnData(event.currentTarget);
  };

  const handleClose = () => {
    openDrawerList(false);
  };
  const handleOpen = (event) => {
    event.preventDefault();
    openDrawerList(true);
    anchorElData(event.currentTarget);
  };
  var path = window.location.pathname;
  var pa = path.substr(1, path.length);

  var features = new CookieHandler().getCookie("features");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <img
              src={ExpandIcon}
              alt="Expand Icon"
              className="Stockholm-icons--Navigation--Angle-double-left"
            />
          </IconButton>
          <div>
            <div className="header-imageMobile">
              {Config.company === "SDAM" ? (
                <span className="right_block_login_logo_container">
                  <img
                    src={Logo}
                    alt="SDAM LOGO"
                    className="mobile_header_logo"
                  />
                </span>
              ) : (
                <h1 style={{ fontSize: 16 }}>
                  {config.company === "SRPL"
                    ? `SAII RESOURCE`
                    : config.company === "PRPL"
                    ? `PRAKURTHI`
                    : config.company === "SRK"
                    ? `SUMBER REZEKI KALTIM`
                    : config.company === "AVS"
                    ? `AVS RESOURCES`
                    : `BUMI TAMBANG RESOURCES`}
                </h1>
              )}
            </div>
            <div className="header_add">
              <button
                onClick={addButton}
                className="header_button header_button_text"
                name="submit"
              >
                New
              </button>
            </div>
            <div
              className="header-icon"
              style={{
                float: "right",
                position: "absolute",
                top: 15,
                right: 10,
                cursor: "pointer",
              }}
            >
              {/* <AccountCircle data-toggle="tooltip" title="Profile" data-html="true"  style={{color:'black', fontSize:30}} onClick={this.handleOpen}/> */}
              <i
                className="fa fa-user-circle login_short_icon"
                aria-hidden="true"
                style={{ color: "black", fontSize: 26 }}
                onClick={handleOpen}
              />
            </div>
            <div
              className="header-name"
              style={{
                position: "absolute",
                top: 12,
                right: 10,
                cursor: "pointer",
              }}
              onClick={handleOpen}
            >
              <i
                className="fa fa-user-circle login_short_icon"
                aria-hidden="true"
              />
              <span className="login_short_mes" style={{ color: "#000" }}>
                Hi,{" "}
              </span>
              <span className="login_name">{loginUserFullName}</span>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {Config.company === "SDAM" ? (
            <a href="/dashboard">
              <span className="sidebar_logo_container">
                <img
                  src={Logo}
                  alt="SDAM LOGO"
                  className="sidebar_header_logo"
                />
              </span>
            </a>
          ) : (
            <a className="sidebar_logo_container" href="/dashboard">
              <h1
                style={{
                  fontSize:
                    config.company === "SRPL" ||
                    config.company === "PRPL" ||
                    config.company === "AVS"
                      ? 22
                      : 13,
                  margin: "0px",
                  position: "relative",
                  left: 60,
                  top: 0,
                }}
              >
                {config.company === "SRPL"
                  ? `SAII RESOURCES`
                  : config.company === "PRPL"
                  ? `PRAKURTHI`
                  : config.company === "SRK"
                  ? `SUMBER REZEKI KALTIM`
                  : config.company === "AVS"
                  ? `AVS RESOURCES`
                  : `BUMI TAMBANG RESOURCES`}
              </h1>
            </a>
          )}
          <IconButton onClick={handleDrawerClose}>
            <img
              src={NavIcon3}
              alt="back"
              className="Stockholm-icons--Navigation--Angle-double-left"
            />
          </IconButton>
        </div>
        <Divider />
        <List
          className={
            Config.company === "SRPL" ||
            Config.company === "PRPL" ||
            Config.company === "AVS"
              ? "sidebar_drawer_saii"
              : "sidebar_drawer_sdam"
          }
        >
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "dashboard" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/dashboard">
              <abbr
                title={open ? "" : "Dashboard"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={DashboardIcon}
                    alt="back"
                    className="sidebar_icons"
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/dashboard">
              <ListItemText
                style={{
                  color:
                    pa === "dashboard"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "dashboard"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Dashboard
                </span>
              </ListItemText>
            </a>
          </ListItem>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "business-partner" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/business-partner">
              <abbr
                title={open ? "" : "Business Partners"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={VendorIcon}
                    alt="Vendor Icon"
                    className={
                      Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS"
                        ? "sidebar_icons saii_icon"
                        : "sidebar_icons"
                    }
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/business-partner">
              <ListItemText
                style={{
                  color:
                    pa === "business-partner"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "business-partner"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Business Partner
                </span>
              </ListItemText>
            </a>
          </ListItem>
          {open ? (
            <div className="sidebar_sub_heading">Contracts</div>
          ) : (
            <ListItem>
              <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                <i
                  className="fa fa-user sidebar_icons"
                  aria-hidden="true"
                  style={{
                    fontSize: 24,
                    color: "rgb(143, 123, 112)",
                    paddingLeft: 5,
                    display: "none",
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <span
                  className="sidebar_sub_heading"
                  style={{ marginLeft: 40 }}
                >
                  Contracts
                </span>
              </ListItemText>
            </ListItem>
          )}
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "sales-contract-list" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/sales-contract-list">
              <abbr
                title={open ? "" : "Sales Contracts"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={SalesIcon}
                    alt="Sales Icon"
                    className="sidebar_icons"
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/sales-contract-list">
              <ListItemText
                style={{
                  color:
                    pa === "sales-contract-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "sales-contract-list"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Sales
                </span>
              </ListItemText>
            </a>
          </ListItem>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "purchase-contract-list"
                  ? Config.themeColor
                  : "#80808000",
            }}
          >
            <a href="/purchase-contract-list">
              <abbr
                title={open ? "" : "Purchase Contracts"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={PurchaseIcon}
                    alt="Purchase Icon"
                    className="sidebar_icons"
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/purchase-contract-list">
              <ListItemText
                style={{
                  color:
                    pa === "purchase-contract-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "purchase-contract-list"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Purchase
                </span>
              </ListItemText>
            </a>
          </ListItem>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "coal-procurement-contracts"
                  ? Config.themeColor
                  : "#80808000",
            }}
          >
            <a href="/coal-procurement-contracts">
              <abbr
                title={open ? "" : "Purchase Contracts"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <i
                    className="fa fa-cube sidebar_icons"
                    aria-hidden="true"
                    style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/coal-procurement-contracts">
              <ListItemText
                style={{
                  color:
                    pa === "coal-procurement-contracts"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "coal-procurement-contracts"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Procurement Service
                </span>
              </ListItemText>
            </a>
          </ListItem>
          {(features.indexOf("2") >= 0 || features.indexOf("97") >= 0) && (
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "advance-payments" ? Config.themeColor : "#80808000",
              }}
            >
              <a href="/advance-payments">
                <abbr
                  title={open ? "" : "Advance Contracts"}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      display: "inline-block",
                      position: "relative",
                      left: 5,
                      top: 2,
                    }}
                  >
                    <i
                      className="fa fa-user sidebar_icons"
                      aria-hidden="true"
                      style={{ fontSize: 20, color: "rgb(143, 123, 112)" }}
                    />
                  </ListItemIcon>
                </abbr>
              </a>
              <a href="/advance-payments">
                <ListItemText
                  style={{
                    color:
                      pa === "advance-payments"
                        ? "rgb(244, 244, 244)"
                        : Config.company === "SRPL" ||
                          Config.company === "PRPL" ||
                          Config.company === "AVS"
                        ? "#918176"
                        : "rgba(238, 238, 238, 0.7)",
                  }}
                >
                  <span
                    className={
                      (Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS") &&
                      pa !== "advance-payments"
                        ? "list_text"
                        : "list-text"
                    }
                  >
                    Advance
                  </span>
                </ListItemText>
              </a>
            </ListItem>
          )}
          {(features.indexOf("334") >= 0 || features.indexOf("97") >= 0) && (
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "fixture-notes" ? Config.themeColor : "#80808000",
              }}
            >
              <a href="/fixture-notes">
                <abbr
                  title={open ? "" : "Fixture Notes"}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      display: "inline-block",
                      position: "relative",
                      left: 5,
                      top: 2,
                    }}
                  >
                    <i
                      className="fa fa-handshake-o sidebar_icons"
                      aria-hidden="true"
                      style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                    />
                  </ListItemIcon>
                </abbr>
              </a>
              <a href="/fixture-notes">
                <ListItemText
                  style={{
                    color:
                      pa === "fixture-notes"
                        ? "rgb(244, 244, 244)"
                        : Config.company === "SRPL" ||
                          Config.company === "PRPL" ||
                          Config.company === "AVS"
                        ? "#918176"
                        : "rgba(238, 238, 238, 0.7)",
                  }}
                >
                  <span
                    className={
                      (Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS") &&
                      pa !== "fixture-notes"
                        ? "list_text"
                        : "list-text"
                    }
                  >
                    Fixture Notes
                  </span>
                </ListItemText>
              </a>
            </ListItem>
          )}
          {(features.indexOf("12") >= 0 ||
            features.indexOf("21") >= 0 ||
            features.indexOf("97") >= 0) && (
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "contract-approvals" ? Config.themeColor : "#80808000",
              }}
            >
              <a href="/contract-approvals">
                <abbr
                  title={open ? "" : "Contract Approvals"}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      display: "inline-block",
                      position: "relative",
                      left: 5,
                      top: 2,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      className="sidebar_icons"
                      style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                    />
                  </ListItemIcon>
                </abbr>
              </a>
              <a href="/contract-approvals">
                <ListItemText
                  style={{
                    color:
                      pa === "contract-approvals"
                        ? "rgb(244, 244, 244)"
                        : Config.company === "SRPL" ||
                          Config.company === "PRPL" ||
                          Config.company === "AVS"
                        ? "#918176"
                        : "rgba(238, 238, 238, 0.7)",
                  }}
                >
                  <span
                    className={
                      (Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS") &&
                      pa !== "contract-approvals"
                        ? "list_text"
                        : "list-text"
                    }
                  >
                    Approvals
                  </span>
                </ListItemText>
              </a>
            </ListItem>
          )}
          {open ? (
            <div className="sidebar_sub_heading">Others</div>
          ) : (
            <ListItem>
              <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                <i
                  className="fa fa-user sidebar_icons"
                  aria-hidden="true"
                  style={{
                    fontSize: 24,
                    color: "rgb(143, 123, 112)",
                    paddingLeft: 5,
                    display: "none",
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <span
                  className="sidebar_sub_heading"
                  style={{ marginLeft: 40 }}
                >
                  Others
                </span>
              </ListItemText>
            </ListItem>
          )}

          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "barge-list" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="barge-list">
              <abbr title={open ? "" : "Barges"} style={{ cursor: "pointer" }}>
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={Barges}
                    alt="Barges"
                    className="sidebar_icons saii_icon"
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/barge-list">
              <ListItemText
                style={{
                  color:
                    pa === "barge-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "barge-list"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Barges
                </span>
              </ListItemText>
            </a>
          </ListItem>

          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "business-number-list" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/business-number-list">
              <abbr
                title={open ? "" : "Business Numbers"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <img
                    src={BusinessIcon}
                    alt="Business Icon"
                    className="sidebar_icons"
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/business-number-list">
              <ListItemText
                style={{
                  color:
                    pa === "business-number-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "business-number-list"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Business Numbers
                </span>
              </ListItemText>
            </a>
          </ListItem>

          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "financials" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/financials">
              <abbr
                title={open ? "" : "Business Numbers"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <i
                    className="fa fa-cubes sidebar_icons pl-1"
                    aria-hidden="true"
                    style={{ height: "7px", color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/financials">
              <ListItemText
                style={{
                  color:
                    pa === "financials"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "financials"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Financials
                </span>
              </ListItemText>
            </a>
          </ListItem>

          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "indirect-expenditure" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="indirect-expenditure">
              <abbr
                title={open ? "" : "Generic Expenses"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <i
                    className="fa fa-money sidebar_icons pl-1"
                    aria-hidden="true"
                    style={{ height: "7px", color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/indirect-expenditure">
              <ListItemText
                style={{
                  color:
                    pa === "indirect-expenditure"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "indirect-expenditure"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Indirect Expenditure
                </span>
              </ListItemText>
            </a>
          </ListItem>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "services" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="services">
              <abbr
                title={open ? "" : "Generic Expenses"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <i
                    className="fa fa-cogs sidebar_icons pl-1"
                    aria-hidden="true"
                    style={{ height: "7px", color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/services">
              <ListItemText
                style={{
                  color:
                    pa === "services"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "services"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Service Requests
                </span>
              </ListItemText>
            </a>
          </ListItem>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "budget-costing" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="budget-costing">
              <abbr
                title={open ? "" : "Generic Expenses"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <FontAwesomeIcon
                    // icon={faUserCheck}
                    icon={faMoneyBillWaveAlt}
                    className="sidebar_icons"
                    style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/budget-costing">
              <ListItemText
                style={{
                  color:
                    pa === "budget-costing"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "budget-costing"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Budget and Costing
                </span>
              </ListItemText>
            </a>
          </ListItem>

          {/* {(features.indexOf("332") >= 0 || features.indexOf('97') >= 0 || features.indexOf('387') >= 0) && */}
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa.search("reports") >= 0 ? Config.themeColor : "#80808000",
            }}
          >
            <a href="reports">
              <abbr title={open ? "" : "Reports"} style={{ cursor: "pointer" }}>
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <i
                    className="fa fa-file-text sidebar_icons pl-1"
                    aria-hidden="true"
                    style={{ height: "7px", color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/reports">
              <ListItemText
                style={{
                  color:
                    pa.search("reports") >= 0
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "reports"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Reports
                </span>
              </ListItemText>
            </a>
          </ListItem>

          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "business-approvals" ? Config.themeColor : "#80808000",
            }}
          >
            <a href="/business-approvals">
              <abbr
                title={open ? "" : "Business Approvals"}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon style={{ minWidth: 0, display: "inline-block" }}>
                  <FontAwesomeIcon
                    icon={faUserEdit}
                    className="sidebar_icons"
                    style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                  />
                </ListItemIcon>
              </abbr>
            </a>
            <a href="/business-approvals">
              <ListItemText
                style={{
                  color:
                    pa === "business-approvals"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    (Config.company === "SRPL" ||
                      Config.company === "PRPL" ||
                      Config.company === "AVS") &&
                    pa !== "business-approvals"
                      ? "list_text"
                      : "list-text"
                  }
                >
                  Business Approvals
                </span>
              </ListItemText>
            </a>
          </ListItem>
          {/* )} */}
          {(features.includes("97") || features.includes("554")) && (
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "financial-approvals"
                    ? Config.themeColor
                    : "#80808000",
              }}
            >
              <a href="/financial-approvals">
                <abbr
                  title={open ? "" : "Financial Approvals"}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon
                    style={{ minWidth: 0, display: "inline-block" }}
                  >
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      className="sidebar_icons"
                      style={{ fontSize: 17, color: "rgb(143, 123, 112)" }}
                    />
                  </ListItemIcon>
                </abbr>
              </a>
              <a href="/financial-approvals">
                <ListItemText
                  style={{
                    color:
                      pa === "financial-approvals"
                        ? "rgb(244, 244, 244)"
                        : Config.company === "SRPL" ||
                          Config.company === "PRPL" ||
                          Config.company === "AVS"
                        ? "#918176"
                        : "rgba(238, 238, 238, 0.7)",
                  }}
                >
                  <span
                    className={
                      (Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS") &&
                      pa !== "financial-approvals"
                        ? "list_text"
                        : "list-text"
                    }
                  >
                    Financial Approvals
                  </span>
                </ListItemText>
              </a>
            </ListItem>
          )}

          {/* } */}
          {features.indexOf("97") >= 0 && (
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "user-access" ? Config.themeColor : "#80808000",
              }}
            >
              <a href="/user-access">
                <abbr
                  title={open ? "" : "User Access Management"}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon
                    style={{ minWidth: 0, display: "inline-block" }}
                  >
                    <img
                      src={AccessManagement}
                      alt="Access Management"
                      className="sidebar_icons saii_icon"
                    />
                  </ListItemIcon>
                </abbr>
              </a>
              <a href="/user-access">
                <ListItemText
                  style={{
                    color:
                      pa === "user-access"
                        ? "rgb(244, 244, 244)"
                        : Config.company === "SRPL" ||
                          Config.company === "PRPL" ||
                          Config.company === "AVS"
                        ? "#918176"
                        : "rgba(238, 238, 238, 0.7)",
                  }}
                >
                  <span
                    className={
                      (Config.company === "SRPL" ||
                        Config.company === "PRPL" ||
                        Config.company === "AVS") &&
                      pa !== "user-access"
                        ? "list_text"
                        : "list-text"
                    }
                  >
                    Access Management
                  </span>
                </ListItemText>
              </a>
            </ListItem>
          )}
        </List>
      </Drawer>
      <Popover
        open={addOpen}
        anchorEl={anchorEn}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={addButtonClose}
      >
        <div className="card border p-0">
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-vendor";
            }}
          >
            <img
              src={VendorIcon}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            Add Vendor
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-customer";
            }}
          >
            <img
              src={CustomerIcon}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            Add Customer
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-purchase";
            }}
          >
            <img
              src={PurchaseIcon}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            New Coal Purchase Contract
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-sales-contract";
            }}
          >
            <img
              src={SalesIcon}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            New Coal Sales Contract
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/new-cps-contract";
            }}
          >
            <i
              className="fa fa-cube sidebar_icons ml-1 mr-1"
              aria-hidden="true"
              style={{ fontSize: 20, color: "rgb(143, 123, 112)" }}
            />
            New Coal Procurement Contract
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/new-vendor-advance-contract";
            }}
          >
            <i
              className="fa fa-user sidebar_icons ml-1 mr-1"
              aria-hidden="true"
              style={{ fontSize: 20, color: "rgb(143, 123, 112)" }}
            />
            New Vendor Advance Contract
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-barge";
            }}
          >
            <img
              src={Barges}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            New Barge
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/new-business";
            }}
          >
            <img
              src={BusinessIcon}
              alt="back"
              className={
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "sidebar_icons saii_icon mr-2"
                  : "sidebar_icons mr-2"
              }
            />
            New Business Number
          </MenuItem>
          <MenuItem
            className="drawer_links"
            style={{
              fontSize: "17px",
              color:
                Config.company === "SRPL" ||
                Config.company === "PRPL" ||
                Config.company === "AVS"
                  ? "#918176"
                  : "rgb(169, 126, 103)",
            }}
            onClick={() => {
              window.location.href = "/add-mine";
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#8f7b70", fontSize: 20 }}
              icon={faDolly}
              className="listIcons mr-2"
            />
            Add Mine
          </MenuItem>
        </div>
      </Popover>
      <Popover
        open={openDrawer}
        anchorEl={anchorEr}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={handleClose}
      >
        <div className="card border">
          <MenuItem
            onClick={() => {
              window.location.href = "/change-pswd";
            }}
          >
            <i className="fa fa-key" style={{ color: "black", fontSize: 20 }} />
            &emsp;Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/logout";
            }}
          >
            {" "}
            <i
              className="fa fa-sign-out"
              style={{ color: "black", fontSize: 20 }}
            />
            &emsp;Logout
          </MenuItem>
        </div>
      </Popover>
    </div>
  );
}
