import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";

import WarningIcon from "@material-ui/icons/Warning";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import {
  TextField,
  Snackbar,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import DeletePayments from "../../common/DeletePayments";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import Loader from "../../common/Loader";
import AdvanceAdjustments from "../../contracts/AdvanceAdjustments";
import AuditInfo from "../../common/AuditInfo";
export default class CoalCostingView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      features: [],
      coalPaymentPostings: [],
      purchaseBargeList: [],
      bargeList: [],
      bonusCap: null,
      bonusCap2: null,
      bonusCap3: null,
      penalityCap: null,
      penalityCap2: null,
      penalityCap3: null,
      selectedPurchaseContractId: null,
      displayQualityResAlert: false,
      coal_cost_gar_bonus: null,
      coal_cost_gar_penality: null,
      coal_cost_ash_penality: null,
      coal_cost_sulphur_penality: null,
      coal_cost_coal_price_pmt: null,
      files: [],
      advance_adjustments: [
        {
          advance_adjustment: "No",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          purchase_contract_id: "",
          pc_adv_remaining_amount: "",
          vendor_contract_no: "",
        },
      ],
      coal_index: {
        coal_index_type: "Weekly",
        index_ids: [],
        average_coal_index_price: 0,
        prorata_gar: "0",
        prorata_coal_index_price: 0,
        bonus_coal_index_pmt: "",
        penality_coal_index_pmt: "",
        derivered_coal_index_pmt: "",
        final_coal_index_pmt: "",
      },
      payment_due_date: null,
      invoiceDialog: false,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
        invoice_percentage: "",
        invoice_amount: "",
      },
      purchase_index_linked: "No",
      weekly_coal_indexes: [],
      monthly_coal_indexes: [],
      vendor_name: "",
      mv_name: "",
      editInvoicePercentage: false,
      coal_cost_coal_price_pmt_local_currency: "",
      adjusted_coal_price_pmtL: "",
      paid_to_supplier: "",
      barge_pur_quality_id: "",
      openAdvAdjustments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("127") ||
          features.includes("388") ||
          features.includes("439") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      } else {
        // await this.get_weekly_coal_prices();
        // await this.get_monthly_coal_prices();
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const comboID = this.props.comboID;
        const bargePurFinaID = comboID.split("$#")[0];
        const purchaseContractId = comboID.split("$#")[1];

        var status = "Active";

        //Get businessNumber API based on businessNumber
        api
          .get_barge_pur_fin_coal_payments(
            loginUserID,
            idToken,
            bargePurFinaID,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  const filterCoalPaymentPostings = res.payment_posting.filter(
                    (e) => e.payment_posting_type === "Coal"
                  );
                  var totalCoalPaymentsAmount = 0;
                  for (var i of filterCoalPaymentPostings) {
                    if (i.date_of_payment) {
                      totalCoalPaymentsAmount += Number(i.amount);
                    }
                  }
                  this.setState({
                    openAdvAdjustments: false,
                    coalPaymentPostings: filterCoalPaymentPostings,
                    totalCoalPaymentsAmount: totalCoalPaymentsAmount,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              });
            }
          });

        //Get businessNumber API based on businessNumber
        api
          .get_barge_pur_fin_coal_costing(bargePurFinaID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  var barges = res.financials.barge_id.split(",");
                  this.callingGet_pc_barge_coal_costingAsync(barges[0]);
                  var totalAdjustments = 0;
                  for (var i of res.financials.advance_adjustments) {
                    totalAdjustments += Number(i.advance_adjustment_value);
                  }

                  let coal_cost_coal_price_pmt_local_currency = (
                    Number(res.financials.coal_price_pmt) *
                    Number(res.financials.currency_xchg_rate)
                  ).toFixed(2);
                  if (
                    config.company === "SRPL" ||
                    config.company === "AVS" ||
                    config.company === "PRPL"
                  ) {
                    coal_cost_coal_price_pmt_local_currency = (
                      Number(res.financials.coal_price_pmt) /
                      Number(res.financials.currency_xchg_rate)
                    ).toFixed(2);
                  }

                  let costing_type = res.financials.costing_type;
                  // Get Purchase contract based Barges by Business number ID

                  let business_no = [];
                  let barge_nomination = "";

                  api
                    .get_coal_costing_barges(
                      loginUserID,
                      idToken,
                      purchaseContractId,
                      business_no,
                      costing_type,
                      barge_nomination
                    )
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.code === "200") {
                            if (res.purchase_contracts) {
                              var purchase_contracts = res.purchase_contracts;
                              for (
                                let i = 0;
                                i < purchase_contracts.length;
                                i++
                              ) {
                                let barges = [];
                                let purchase_contracts_barges =
                                  purchase_contracts[i].barges;
                                let newData = [];
                                for (
                                  let k = 0;
                                  k < purchase_contracts_barges.length;
                                  k++
                                ) {
                                  let id =
                                    purchase_contracts_barges[k]
                                      .barge_pur_fin_id;
                                  if (id !== null) {
                                    barges.push(purchase_contracts_barges[k]);
                                  }
                                }

                                var result = barges.reduce(function (r, a) {
                                  r[a.barge_pur_fin_id] =
                                    r[a.barge_pur_fin_id] || [];
                                  r[a.barge_pur_fin_id].push(a);
                                  return r;
                                }, Object.create(null));
                                for (
                                  let l = 0;
                                  l < Object.keys(result).length;
                                  l++
                                ) {
                                  newData.push({
                                    new_barges: result[Object.keys(result)[l]],
                                  });
                                }
                                purchase_contracts[i].new_barges = newData;
                              }
                              this.setState({
                                purchaseBargeList: purchase_contracts,
                              });
                            }
                          }
                        });
                      }
                    });
                  let performa_advance_adjustment = 0;
                  if (costing_type === "Commercial") {
                    for (
                      let n = 0;
                      n < res.financials.performa_advance_adjustments.length;
                      n++
                    ) {
                      performa_advance_adjustment += Number(
                        res.financials.performa_advance_adjustments[n]
                          .advance_adjustment_value
                      );
                    }
                  }
                  let coal_index_ids =
                    res.financials.index_ids?.split(",") || [];
                  let coal_index = {
                    ...this.state.coal_index,
                    coal_index_type: res.financials.coal_index_type,
                    index_ids: coal_index_ids,
                    average_coal_index_price:
                      res.financials.average_coal_index_price,
                    prorata_gar: res.financials.prorata_gar,
                    prorata_coal_index_price:
                      res.financials.prorata_coal_index_price,
                    bonus_coal_index_pmt: res.financials.bonus_coal_index_pmt,
                    penality_coal_index_pmt:
                      res.financials.penality_coal_index_pmt,
                    derivered_coal_index_pmt:
                      res.financials.derivered_coal_index_pmt,
                    final_coal_index_pmt: res.financials.final_coal_index_pmt,
                  };

                  let invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: res.financials.invoice_no,
                    invoice_date: res.financials.invoice_date,
                    ppn: res.financials.ppn,
                    ppn_date: res.financials.ppn_date,
                    invoice_percentage: res.financials.invoice_percentage,
                  };

                  this.setState({
                    // editInvoicePercentage: false,
                    invoiceDetails: invoice_details,
                    coal_index: coal_index,
                    bargePurFinaID: bargePurFinaID,
                    costing_type: res.financials.costing_type,
                    costing_no: res.financials.costing_no,
                    invoice_file: res.financials.invoice_file,
                    bl_file: res.financials.bl_file,
                    coa_file: res.financials.coa_file,
                    dsr_file: res.financials.dsr_file,
                    bargeList: res.financials.barge_id.split(","),
                    is_commercial_invoices_raised:
                      res.financials.is_commercial_invoices_raised,
                    coal_cost_coal_quantity: res.financials.barge_quantity
                      ? res.financials.barge_quantity
                      : "",
                    coal_cost_coal_price_pmt: res.financials.coal_price_pmt
                      ? res.financials.coal_price_pmt
                      : null,
                    coal_cost_coal_price_pmt_local_currency:
                      res.financials.coal_price_pmt &&
                      res.financials.currency_xchg_rate
                        ? coal_cost_coal_price_pmt_local_currency
                        : null,
                    coal_cost_coal_price: res.financials.coal_price
                      ? res.financials.coal_price
                      : "",
                    coal_cost_billing_currency: res.financials.billing_currency
                      ? res.financials.billing_currency
                      : "",
                    coal_cost_penality_currency: res.financials
                      .penality_currency
                      ? res.financials.penality_currency
                      : "",
                    performa_payments_amount: res.financials
                      .performa_payments_amount
                      ? res.financials.performa_payments_amount
                      : 0,
                    performa_advance_adjustment: performa_advance_adjustment,
                    coal_cost_gar_bonus: res.financials.gar_bonus_pmt
                      ? res.financials.gar_bonus_pmt
                      : null,
                    coal_cost_gar_penality: res.financials.gar_penality_pmt
                      ? res.financials.gar_penality_pmt
                      : null,
                    coal_cost_ash_penality: res.financials.ash_penality_pmt
                      ? res.financials.ash_penality_pmt
                      : null,
                    coal_cost_sulphur_penality: res.financials
                      .sulphur_penality_pmt
                      ? res.financials.sulphur_penality_pmt
                      : null,
                    coal_cost_adjusted_coal_price_pmt: res.financials
                      .adjusted_coal_price_pmt
                      ? res.financials.adjusted_coal_price_pmt
                      : "",
                    coal_cost_adjusted_coal_price: res.financials
                      .adjusted_coal_price
                      ? res.financials.adjusted_coal_price
                      : "",
                    coal_cost_currency_xchg_rate: res.financials
                      .currency_xchg_rate
                      ? res.financials.currency_xchg_rate
                      : 1,

                    coal_cost_adjusted_coal_price_in_local_currency: res
                      .financials.adjusted_coal_price_in_local_currency
                      ? res.financials.adjusted_coal_price_in_local_currency
                      : "",
                    adjusted_coal_price_in_local_currency: res.financials
                      .adjusted_coal_price_in_local_currency
                      ? res.financials.adjusted_coal_price_in_local_currency
                      : 0,
                    coal_cost_pph22_barge_quantity_in_mt: res.financials
                      .pph22_barge_quantity
                      ? res.financials.pph22_barge_quantity
                      : "",
                    coal_cost_pph22_coal_price_per_mt: res.financials
                      .pph22_coal_price_pmt
                      ? res.financials.pph22_coal_price_pmt
                      : "",
                    coal_cost_pph22_percent: res.financials.pph22_percent
                      ? res.financials.pph22_percent
                      : "",
                    coal_cost_pph22_percent_val: res.financials.pph22_value
                      ? res.financials.pph22_value
                      : "",
                    pph22_value: res.financials.pph22_value
                      ? res.financials.pph22_value
                      : 0,
                    coal_cost_pph22_export: res.financials
                      .pph22_export_applicable
                      ? res.financials.pph22_export_applicable
                      : "No",
                    coal_cost_pph22_export_amount: res.financials
                      .pph22_export_amount
                      ? res.financials.pph22_export_amount
                      : "",
                    coal_cost_pph22_export_percent: res.financials
                      .pph22_export_percent
                      ? res.financials.pph22_export_percent
                      : "",
                    coal_cost_pph22_export_value: res.financials
                      .pph22_export_value
                      ? res.financials.pph22_export_value
                      : "",
                    vat_applicable: res.financials.vat_applicable
                      ? res.financials.vat_applicable
                      : "No",
                    vat_amount: res.financials.vat_amount
                      ? res.financials.vat_amount
                      : "",
                    vat_percent: res.financials.vat_percent
                      ? res.financials.vat_percent
                      : "",
                    vat_value: res.financials.vat_value
                      ? res.financials.vat_value
                      : "",
                    paid_to_supplier:
                      parseFloat(res.financials.payable_to_supplier) -
                      parseFloat(totalAdjustments),
                    coalPricePerMT: res.financials.coal_price_pmt
                      ? res.financials.coal_price_pmt
                      : "",
                    // purchase_index_linked: res.financials.is_index_linked
                    //   ? res.financials.is_index_linked
                    //   : "No",
                    selectedPurchaseContractId: res.financials
                      .purchase_contract_id
                      ? res.financials.purchase_contract_id
                      : "",
                    advance_adjustments: res.financials.advance_adjustments,
                    display_pph22:
                      Number(res.financials.pph22_export_value) +
                      Number(res.financials.pph22_value),
                    display_adjustments: totalAdjustments,
                    coal_cost_pph22_export_value_USD:
                      Number(
                        res.financials.currency_xchg_rate
                          ? res.financials.currency_xchg_rate
                          : 1
                      ) * Number(res.financials.pph22_export_value),
                    coal_cost_pph22_percent_val_IDR:
                      Number(
                        res.financials.currency_xchg_rate
                          ? res.financials.currency_xchg_rate
                          : 1
                      ) * Number(res.financials.pph22_value),
                    coal_cost_pph22_export_value_IDR:
                      Number(
                        res.financials.currency_xchg_rate
                          ? res.financials.currency_xchg_rate
                          : 1
                      ) * Number(res.financials.pph22_export_value),
                    files: res.financials.files ? res.financials.files : [],
                    invoice_date: res.financials.invoice_date
                      ? res.financials.invoice_date
                      : null,
                    ppn: res.financials.ppn ? res.financials.ppn : "",
                    ppn_date: res.financials.ppn_date
                      ? res.financials.ppn_date
                      : null,
                    invoice_number: res.financials.invoice_no
                      ? res.financials.invoice_no
                      : "",

                    created_by: res.financials.created_by
                      ? res.financials.created_by
                      : "",
                    approval_status: res.financials.approval_status
                      ? res.financials.approval_status
                      : "Pending",
                    approved_by: res.financials.approved_by
                      ? res.financials.approved_by
                      : null,
                    approved_by_name: res.financials.approved_by_name
                      ? res.financials.approved_by_name
                      : "",
                    approved_date: res.financials.approved_date
                      ? res.financials.approved_date
                      : null,

                    approved_date_2: res.financials.approved_date_2
                      ? res.financials.approved_date_2
                      : null,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              });
            }
          });
      }
    }

    document.title =
      config.documentTitle + "Barge Purchase Financial Coal Costing View";
  }

  async callingGet_pc_barge_coal_costingAsync(bargeID) {
    const businessNoBargeID = bargeID;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const comboID = this.props.comboID;
    const bargePurFinaID = comboID.split("$#")[0];

    await api
      .get_barge_pur_fin_coal_costing(bargePurFinaID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              var barges = res.financials.barge_id.split(",");
              let coal_index_ids = res.financials.index_ids?.split(",") || [];
              let coal_index = {
                ...this.state.coal_index,
                coal_index_type: res.financials.coal_index_type,
                index_ids: coal_index_ids,
                average_coal_index_price:
                  res.financials.average_coal_index_price,
                prorata_gar: res.financials.prorata_gar,
                prorata_coal_index_price:
                  res.financials.prorata_coal_index_price,
                bonus_coal_index_pmt: res.financials.bonus_coal_index_pmt,
                penality_coal_index_pmt: res.financials.penality_coal_index_pmt,
                derivered_coal_index_pmt:
                  res.financials.derivered_coal_index_pmt,
                final_coal_index_pmt: res.financials.final_coal_index_pmt,
              };
              this.setState({
                bargeList: barges,
                coal_index: coal_index,
                bargePurFinaID: bargePurFinaID,
                costing_no: res.financials.costing_no,
                invoice_file: res.financials.invoice_file,
                dsr_file: res.financials.dsr_file,
                bl_file: res.financials.bl_file,
                coa_file: res.financials.coa_file,
                coal_cost_coal_quantity: res.financials.barge_quantity
                  ? Number(res.financials.barge_quantity)
                  : "",
                coal_cost_coal_price_pmt: res.financials.coal_price_pmt
                  ? Number(res.financials.coal_price_pmt)
                  : null,
                coal_cost_coal_price: res.financials.coal_price
                  ? res.financials.coal_price
                  : "",

                invoice_percentage:
                  Number(
                    res.financials.invoice_percentage
                      ? res.financials.invoice_percentage
                      : 0
                  ) > 1
                    ? res.financials.invoice_percentage
                    : 100,
                coal_cost_billing_currency: res.financials.billing_currency
                  ? res.financials.billing_currency
                  : "",
                coal_cost_penality_currency: res.financials.penality_currency
                  ? res.financials.penality_currency
                  : "",
                coal_cost_gar_bonus: res.financials.gar_bonus_pmt
                  ? Number(res.financials.gar_bonus_pmt)
                  : null,
                coal_cost_gar_penality: res.financials.gar_penality_pmt
                  ? Number(res.financials.gar_penality_pmt)
                  : null,
                coal_cost_ash_penality: res.financials.ash_penality_pmt
                  ? Number(res.financials.ash_penality_pmt)
                  : null,
                coal_cost_sulphur_penality: res.financials.sulphur_penality_pmt
                  ? Number(res.financials.sulphur_penality_pmt)
                  : null,
                coal_cost_adjusted_coal_price_pmt: res.financials
                  .adjusted_coal_price_pmt
                  ? Number(res.financials.adjusted_coal_price_pmt).toFixed(2)
                  : "",
                coal_cost_adjusted_coal_price: res.financials
                  .adjusted_coal_price
                  ? res.financials.adjusted_coal_price
                  : "",
                coal_cost_currency_xchg_rate: res.financials.currency_xchg_rate
                  ? Number(res.financials.currency_xchg_rate)
                  : "",
                currency_xchg_rate: res.financials.currency_xchg_rate
                  ? Number(res.financials.currency_xchg_rate)
                  : "",
                coal_cost_adjusted_coal_price_in_local_currency: res.financials
                  .adjusted_coal_price_in_local_currency
                  ? res.financials.adjusted_coal_price_in_local_currency
                  : "",
                coal_cost_pph22_barge_quantity_in_mt: res.financials
                  .pph22_barge_quantity
                  ? Number(res.financials.pph22_barge_quantity)
                  : "",
                coal_cost_pph22_coal_price_per_mt: res.financials
                  .pph22_coal_price_pmt
                  ? Number(res.financials.pph22_coal_price_pmt)
                  : "",
                coal_cost_pph22_percent: res.financials.pph22_percent
                  ? res.financials.pph22_percent
                  : "",
                coal_cost_pph22_percent_val: res.financials.pph22_value
                  ? res.financials.pph22_value
                  : "",
                coal_cost_pph22_export: res.financials.pph22_export_applicable
                  ? res.financials.pph22_export_applicable
                  : "No",
                coal_cost_pph22_export_amount: res.financials
                  .pph22_export_amount
                  ? Number(res.financials.pph22_export_amount)
                  : "",
                coal_cost_pph22_export_percent: res.financials
                  .pph22_export_percent
                  ? res.financials.pph22_export_percent
                  : "",
                coal_cost_pph22_export_value: res.financials.pph22_export_value
                  ? res.financials.pph22_export_value
                  : "",
                pph22_export_value: res.financials.pph22_export_value
                  ? res.financials.pph22_export_value
                  : 0,
                paid_to_supplier: res.financials.payable_to_supplier
                  ? res.financials.payable_to_supplier
                  : "",
                coalPricePerMT: res.financials.coal_price_pmt
                  ? res.financials.coal_price_pmt
                  : "",
                selectedPurchaseContractId: res.financials.purchase_contract_id
                  ? res.financials.purchase_contract_id
                  : "",
                advance_adjustments: res.financials.advance_adjustments,
                vat_applicable: res.financials.vat_applicable
                  ? res.financials.vat_applicable
                  : "No",
                vat_amount: res.financials.vat_amount
                  ? res.financials.vat_amount
                  : "",
                vat_percent: res.financials.vat_percent
                  ? res.financials.vat_percent
                  : 10,
                vat_value: res.financials.vat_value
                  ? res.financials.vat_value
                  : "",
                //  : res.financials.is_index_linked,
                // isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      barge_id: bargeID,
      vendor_type: "Coal",
    };

    await api.get_business_no_info_for_barge(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState({
              vendor_name: res.vendor_name,
              mv_name: res.mv_name,
            });
          }
        });
      }
    });

    // Get All Purchase Specification data based on Barge ID.
    await api
      .getAllPurchaseSpecsByBargeID(loginUserID, idToken, businessNoBargeID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                purchasing_term: res.purchasing_term,
              });
              if (res.pcq_typical_specs) {
                this.setState({
                  garActiualValue: res.pcq_typical_specs
                    .qality_calorific_value_actual
                    ? res.pcq_typical_specs.qality_calorific_value_actual
                    : "",
                  garTypicalValue: res.pcq_typical_specs
                    .quality_calorific_value_typical
                    ? res.pcq_typical_specs.quality_calorific_value_typical
                    : "",
                  ashPenality: res.pcq_typical_specs.quality_ash_penality
                    ? res.pcq_typical_specs.quality_ash_penality
                    : "",
                  sulphurPenality: res.pcq_typical_specs
                    .quality_total_sulphur_penality
                    ? res.pcq_typical_specs.quality_total_sulphur_penality
                    : "",
                  bonusCap: res.pcq_typical_specs
                    .quality_calorific_value_bonus_cap
                    ? res.pcq_typical_specs.quality_calorific_value_bonus_cap
                    : "",
                  bonusCap2: res.pcq_typical_specs
                    .quality_calorific_value2_bonus_cap
                    ? res.pcq_typical_specs.quality_calorific_value2_bonus_cap
                    : "",
                  bonusCap3: res.pcq_typical_specs
                    .quality_calorific_value3_bonus_cap
                    ? res.pcq_typical_specs.quality_calorific_value3_bonus_cap
                    : "",
                  penalityCap: res.pcq_typical_specs
                    .quality_calorific_value_penality_cap
                    ? res.pcq_typical_specs.quality_calorific_value_penality_cap
                    : "",
                  penalityCap2: res.pcq_typical_specs
                    .quality_calorific_value2_penality_cap
                    ? res.pcq_typical_specs
                        .quality_calorific_value2_penality_cap
                    : "",
                  penalityCap3: res.pcq_typical_specs
                    .quality_calorific_value3_penality_cap
                    ? res.pcq_typical_specs
                        .quality_calorific_value3_penality_cap
                    : "",

                  quality_calorific_value_typical:
                    res.pcq_typical_specs.quality_calorific_value_typical,
                  quality_calorific_value_rejection:
                    res.pcq_typical_specs.quality_calorific_value_rejection,
                  quality_calorific_value_basis:
                    res.pcq_typical_specs.quality_calorific_value_basis,
                  quality_calorific_value2_typical:
                    res.pcq_typical_specs.quality_calorific_value2_typical,
                  quality_calorific_value2_rejection:
                    res.pcq_typical_specs.quality_calorific_value2_rejection,
                  quality_calorific_value2_basis:
                    res.pcq_typical_specs.quality_calorific_value2_basis,
                  quality_calorific_value3_typical:
                    res.pcq_typical_specs.quality_calorific_value3_typical,
                  quality_calorific_value3_rejection:
                    res.pcq_typical_specs.quality_calorific_value3_rejection,
                  quality_calorific_value3_basis:
                    res.pcq_typical_specs.quality_calorific_value3_basis,

                  quality_ash_typical:
                    res.pcq_typical_specs.quality_ash_typical,
                  quality_ash_rejection:
                    res.pcq_typical_specs.quality_ash_rejection,
                  quality_ash_basis: res.pcq_typical_specs.quality_ash_basis,

                  quality_total_sulphur_typical:
                    res.pcq_typical_specs.quality_total_sulphur_typical,
                  quality_total_sulphur_rejection:
                    res.pcq_typical_specs.quality_total_sulphur_rejection,
                  quality_total_sulphur_basis:
                    res.pcq_typical_specs.quality_total_sulphur_basis,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    // get Barge Purchase Quality Results By Barge ID
    await api
      .getQualityResultsByBargeID(loginUserID, idToken, businessNoBargeID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_purchase_qualities) {
                this.setState({
                  barge_pur_quality_id: res.barge_purchase_qualities.id,
                  quality_calorific_value_actual:
                    res.barge_purchase_qualities.quality_calorific_value_actual,
                  quality_calorific_value2_actual:
                    res.barge_purchase_qualities
                      .quality_calorific_value2_actual,
                  quality_calorific_value3_actual:
                    res.barge_purchase_qualities
                      .quality_calorific_value3_actual,
                  quality_ash_actual:
                    res.barge_purchase_qualities.quality_ash_actual,
                  quality_total_sulphur_actual:
                    res.barge_purchase_qualities.quality_total_sulphur_actual,
                });
              } else {
                //alert('Unexpected error occured. Please contact administrator.');
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    //  Calling Get Business Number Operation Barge Details API
    await api.get_barge(loginUserID, idToken, bargeID).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              //Get purchase Coal Price.
              api
                .get_purchase_contract_data_by_barge_id(
                  loginUserID,
                  idToken,
                  businessNoBargeID
                )
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.code === "200") {
                        // Gar Penality Calcualtions
                        var garPenality = "";
                        var garBonus = "";
                        var ashPenality = "";
                        var sulphurPenality = "";

                        let garActiualValue = this.state.garActiualValue;
                        let garTypicalValue = this.state.garTypicalValue;
                        let bonusCap = this.state.bonusCap;
                        var penalityCap = this.state.penalityCap;

                        var coal_cost_currency_xchg_rate =
                          this.state.coal_cost_currency_xchg_rate;
                        if (coal_cost_currency_xchg_rate === "") {
                          coal_cost_currency_xchg_rate = 1;
                        }
                        let coal_cost_coal_price_pmt = Number(
                          res.purchase_contract_data.purchase_price_pmt
                        ).toFixed(4);
                        if (
                          res.purchase_contract_data.purchase_index_linked ===
                          "Yes"
                        ) {
                          coal_cost_coal_price_pmt = Number(
                            this.state.coal_cost_coal_price_pmt
                          ).toFixed(4);
                        }

                        if (
                          this.state.purchasing_term === "Specs" &&
                          this.state.costing_type === "Commercial"
                        ) {
                          if (
                            garActiualValue !== null &&
                            garTypicalValue !== null &&
                            garActiualValue !== "" &&
                            garTypicalValue !== ""
                          ) {
                            if (
                              Number(garActiualValue) > Number(garTypicalValue)
                            ) {
                              if (bonusCap !== null && bonusCap !== "") {
                                if (
                                  Number(garActiualValue) > Number(bonusCap)
                                ) {
                                  garActiualValue = bonusCap;
                                }
                              }
                            }
                            if (
                              Number(garActiualValue) < Number(garTypicalValue)
                            ) {
                              if (penalityCap !== null && penalityCap !== "") {
                                if (
                                  Number(garActiualValue) < Number(penalityCap)
                                ) {
                                  garActiualValue = penalityCap;
                                }
                              }
                            }
                            if (
                              Number(garActiualValue) > Number(garTypicalValue)
                            ) {
                              garBonus =
                                ((Number(garActiualValue) -
                                  Number(garTypicalValue)) /
                                  Number(garTypicalValue)) *
                                Number(coal_cost_coal_price_pmt);
                            }
                            if (
                              Number(garTypicalValue) > Number(garActiualValue)
                            ) {
                              garPenality =
                                ((Number(garTypicalValue) -
                                  Number(garActiualValue)) /
                                  Number(garTypicalValue)) *
                                Number(coal_cost_coal_price_pmt);
                            }
                          }
                          if (
                            this.state.quality_ash_actual !== null &&
                            this.state.quality_ash_actual !== "" &&
                            this.state.quality_ash_typical !== null &&
                            this.state.quality_ash_typical !== ""
                          ) {
                            if (
                              Number(this.state.quality_ash_actual) >
                                Number(this.state.quality_ash_typical) &&
                              this.state.ashPenality !== null &&
                              this.state.ashPenality !== ""
                            ) {
                              ashPenality =
                                Number(this.state.ashPenality) *
                                ((Number(this.state.quality_ash_actual) -
                                  Number(this.state.quality_ash_typical)) /
                                  1);
                            }
                          }
                          if (
                            this.state.quality_total_sulphur_actual !== null &&
                            this.state.quality_total_sulphur_actual !== "" &&
                            this.state.quality_total_sulphur_typical !== null &&
                            this.state.quality_total_sulphur_typical !== ""
                          ) {
                            if (
                              Number(this.state.quality_total_sulphur_actual) >
                                Number(
                                  this.state.quality_total_sulphur_typical
                                ) &&
                              this.state.sulphurPenality !== null &&
                              this.state.sulphurPenality !== ""
                            ) {
                              sulphurPenality =
                                Number(this.state.sulphurPenality) *
                                ((Number(
                                  this.state.quality_total_sulphur_actual
                                ) -
                                  Number(
                                    this.state.quality_total_sulphur_typical
                                  )) /
                                  0.1);
                            }
                          }
                        }

                        var coal_cost_gar_bonus =
                          this.state.coal_cost_gar_bonus;
                        var coal_cost_gar_penality =
                          this.state.coal_cost_gar_penality;
                        var coal_cost_ash_penality =
                          this.state.coal_cost_ash_penality;
                        var coal_cost_sulphur_penality =
                          this.state.coal_cost_sulphur_penality;
                        if (
                          garBonus !== "" &&
                          this.state.coal_cost_gar_bonus === null
                        ) {
                          coal_cost_gar_bonus = garBonus.toFixed(2);
                          // this.setState({
                          //   coal_cost_gar_bonus: Number(coal_cost_gar_bonus)
                          // })
                        }
                        if (
                          garPenality !== "" &&
                          this.state.coal_cost_gar_penality === null
                        ) {
                          coal_cost_gar_penality = garPenality.toFixed(2);
                          // this.setState({
                          //   coal_cost_gar_penality: Number(coal_cost_gar_penality)
                          // })
                        }
                        if (
                          ashPenality !== "" &&
                          this.state.coal_cost_ash_penality === null
                        ) {
                          coal_cost_ash_penality = ashPenality.toFixed(2);
                          // this.setState({
                          //   coal_cost_ash_penality: Number(coal_cost_ash_penality)
                          // })
                        }
                        if (
                          sulphurPenality !== "" &&
                          this.state.coal_cost_sulphur_penality === null
                        ) {
                          coal_cost_sulphur_penality =
                            sulphurPenality.toFixed(2);
                          // this.setState({
                          //   coal_cost_sulphur_penality: Number(coal_cost_sulphur_penality)
                          // })
                        }
                        var purchase_currency =
                          res.purchase_contract_data.purchase_currency;

                        if (
                          this.state.coal_cost_billing_currency === "0" ||
                          purchase_currency ===
                            this.state.coal_cost_billing_currency
                        ) {
                          coal_cost_currency_xchg_rate = 1;
                        }
                        if (
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) {
                          coal_cost_currency_xchg_rate =
                            1 /
                            Number(
                              coal_cost_currency_xchg_rate
                                ? coal_cost_currency_xchg_rate
                                : 1
                            );
                        }
                        var coal_cost_adjusted_coal_price_pmt =
                          this.state.coal_cost_adjusted_coal_price_pmt;
                        let purchase_price_pmt = (
                          Number(coal_cost_coal_price_pmt) *
                          Number(coal_cost_currency_xchg_rate)
                        ).toFixed(4);

                        let coal_cost_coal_price_pmt_local_currency = (
                          Number(coal_cost_coal_price_pmt) *
                          Number(coal_cost_currency_xchg_rate)
                        ).toFixed(2);
                        if (
                          coal_cost_adjusted_coal_price_pmt ===
                            coal_cost_coal_price_pmt_local_currency &&
                          (coal_cost_gar_bonus !== "" ||
                            coal_cost_gar_penality !== "" ||
                            coal_cost_ash_penality !== "" ||
                            coal_cost_sulphur_penality !== "")
                        ) {
                          coal_cost_adjusted_coal_price_pmt = (
                            Number(purchase_price_pmt) +
                            Number(coal_cost_gar_bonus) -
                            Number(coal_cost_gar_penality) -
                            Number(coal_cost_ash_penality) -
                            Number(coal_cost_sulphur_penality)
                          ).toFixed(2);
                        }

                        var coal_cost_adjusted_coal_price = (
                          Number(this.state.coal_cost_coal_quantity) *
                          Number(coal_cost_adjusted_coal_price_pmt)
                        ).toFixed(2);
                        var coal_cost_adjusted_coal_price_in_local_currency =
                          Number(coal_cost_adjusted_coal_price).toFixed(2);

                        if (this.props.costing_type === "Performa") {
                          let invoice_percentage = this.state.invoice_percentage
                            ? parseFloat(this.state.invoice_percentage)
                            : 100;
                          coal_cost_adjusted_coal_price_in_local_currency =
                            (parseFloat(coal_cost_adjusted_coal_price) *
                              parseFloat(invoice_percentage)) /
                            100;
                        }
                        var coal_cost_pph22_export_amount =
                          this.state.coal_cost_pph22_export_amount;
                        if (
                          coal_cost_pph22_export_amount ===
                          coal_cost_adjusted_coal_price
                        ) {
                          coal_cost_pph22_export_amount =
                            coal_cost_adjusted_coal_price;
                        }

                        coal_cost_pph22_export_amount = Number(
                          coal_cost_pph22_export_amount
                        ).toFixed(2);
                        var coal_cost_pph22_export_percent = 1.5;
                        var coal_cost_pph22_export_value = Number(
                          (Number(coal_cost_pph22_export_amount) *
                            Number(coal_cost_pph22_export_percent)) /
                            100
                        );

                        var coal_cost_pph22_percent_val = "";

                        var row = this.state.advance_adjustments;
                        var total_advance_adjustment_value = 0;
                        for (var i of row) {
                          total_advance_adjustment_value += Number(
                            i.advance_adjustment_value
                          );
                        }
                        var vat_amount =
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                            ? 0
                            : Number(
                                coal_cost_adjusted_coal_price_in_local_currency
                              );

                        var vat_percent = this.state.vat_percent
                          ? this.state.vat_percent
                          : 10;
                        var vat_value =
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                            ? 0
                            : (Number(vat_amount) * Number(vat_percent)) / 100;

                        if (this.state.vat_applicable === "No") {
                          vat_value = 0;
                        }
                        var paid_to_supplier = (
                          Number(this.state.coal_cost_coal_quantity) *
                            Number(coal_cost_adjusted_coal_price_pmt) +
                          Number(vat_value) -
                          Number(coal_cost_pph22_percent_val) -
                          Number(coal_cost_pph22_export_value) -
                          Number(total_advance_adjustment_value)
                        ).toFixed(2);
                        var coal_cost_pph22_barge_quantity_in_mt =
                          this.state.coal_cost_pph22_barge_quantity_in_mt;

                        var coal_cost_pph22_coal_price_per_mt =
                          this.state.coal_cost_pph22_coal_price_per_mt;
                        if (
                          coal_cost_pph22_coal_price_per_mt ===
                          coal_cost_adjusted_coal_price_pmt
                        ) {
                          coal_cost_pph22_coal_price_per_mt =
                            coal_cost_adjusted_coal_price_pmt;
                        }

                        var coal_cost_pph22_export_value_local_cur = "";
                        var coal_cost_pph22_percent_val_local_cur = "";
                        if (
                          ((config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") &&
                            purchase_currency !== "IDR") ||
                          ((config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            purchase_currency !== "USD")
                        ) {
                          coal_cost_pph22_export_value = Number(
                            (Number(coal_cost_pph22_export_amount) *
                              Number(coal_cost_pph22_export_percent)) /
                              100
                          );

                          if (
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                          ) {
                            coal_cost_pph22_export_value_local_cur = Number(
                              coal_cost_pph22_export_value
                            );
                            if (this.state.coal_cost_pph22_export === "No") {
                              coal_cost_pph22_percent_val = 0;
                              coal_cost_pph22_export_value = 0;
                              coal_cost_pph22_export_value_local_cur = 0;
                            }
                            paid_to_supplier = (
                              Number(
                                coal_cost_adjusted_coal_price_in_local_currency
                              ) +
                              Number(vat_value) -
                              Number(coal_cost_pph22_percent_val) -
                              Number(coal_cost_pph22_export_value_local_cur) -
                              Number(total_advance_adjustment_value)
                            ).toFixed(2);
                          } else {
                            coal_cost_pph22_percent_val = Number(
                              (Number(coal_cost_pph22_barge_quantity_in_mt) *
                                Number(coal_cost_pph22_coal_price_per_mt) *
                                Number(this.state.coal_cost_pph22_percent)) /
                                100
                            );
                            coal_cost_pph22_percent_val_local_cur = Number(
                              coal_cost_pph22_percent_val
                            ).toFixed(2);

                            coal_cost_pph22_export_value = Number(
                              (Number(coal_cost_pph22_export_amount) *
                                Number(coal_cost_pph22_export_percent)) /
                                100
                            );
                            coal_cost_pph22_export_value_local_cur = Number(
                              coal_cost_pph22_export_value
                            );
                            if (
                              (config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                              this.state.coal_cost_billing_currency === "IDR"
                            ) {
                              coal_cost_pph22_percent_val_local_cur = Number(
                                coal_cost_pph22_percent_val_local_cur
                              ).toFixed(0);
                              coal_cost_pph22_export_value_local_cur = Number(
                                coal_cost_pph22_export_value_local_cur
                              ).toFixed(0);
                            }
                            if (this.state.coal_cost_pph22_export === "No") {
                              coal_cost_pph22_export_value = 0;
                              coal_cost_pph22_export_value_local_cur = 0;
                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                Number(vat_value) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur) -
                                Number(total_advance_adjustment_value)
                              ).toFixed(2);
                            } else {
                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) +
                                Number(vat_value) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur) -
                                Number(total_advance_adjustment_value)
                              ).toFixed(2);
                            }
                          }
                        } else {
                          coal_cost_pph22_export_value = Number(
                            (Number(coal_cost_pph22_export_amount) *
                              Number(coal_cost_pph22_export_percent)) /
                              100
                          );

                          if (
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                          ) {
                            coal_cost_pph22_export_value_local_cur = Number(
                              coal_cost_pph22_export_value
                            );
                            if (this.state.coal_cost_pph22_export === "No") {
                              coal_cost_pph22_percent_val = 0;
                              coal_cost_pph22_export_value = 0;
                              coal_cost_pph22_export_value_local_cur = 0;
                            }
                            paid_to_supplier = (
                              Number(
                                coal_cost_adjusted_coal_price_in_local_currency
                              ) -
                              Number(coal_cost_pph22_percent_val) -
                              Number(coal_cost_pph22_export_value_local_cur) -
                              Number(total_advance_adjustment_value)
                            ).toFixed(2);
                          } else {
                            coal_cost_pph22_percent_val = Number(
                              (Number(coal_cost_pph22_barge_quantity_in_mt) *
                                Number(coal_cost_pph22_coal_price_per_mt) *
                                Number(this.state.coal_cost_pph22_percent)) /
                                100
                            );
                            coal_cost_pph22_percent_val_local_cur = Number(
                              coal_cost_pph22_percent_val
                            ).toFixed(2);

                            coal_cost_pph22_export_value = Number(
                              (Number(coal_cost_pph22_export_amount) *
                                Number(coal_cost_pph22_export_percent)) /
                                100
                            );
                            coal_cost_pph22_export_value_local_cur = Number(
                              coal_cost_pph22_export_value
                            );
                            if (
                              (config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                              this.state.coal_cost_billing_currency === "IDR"
                            ) {
                              coal_cost_pph22_percent_val_local_cur = Number(
                                coal_cost_pph22_percent_val_local_cur
                              ).toFixed(0);
                              coal_cost_pph22_export_value_local_cur = Number(
                                coal_cost_pph22_export_value_local_cur
                              ).toFixed(0);
                            }
                            if (this.state.coal_cost_pph22_export === "No") {
                              coal_cost_pph22_export_value = 0;
                              coal_cost_pph22_export_value_local_cur = 0;
                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur) -
                                Number(total_advance_adjustment_value)
                              ).toFixed(2);
                            } else {
                              paid_to_supplier = (
                                Number(
                                  coal_cost_adjusted_coal_price_in_local_currency
                                ) -
                                Number(coal_cost_pph22_percent_val_local_cur) -
                                Number(coal_cost_pph22_export_value_local_cur) -
                                Number(total_advance_adjustment_value)
                              ).toFixed(2);
                            }
                          }
                        }
                        var display_pph22 =
                          Number(coal_cost_pph22_percent_val_local_cur) +
                          Number(coal_cost_pph22_export_value_local_cur);

                        let coal_cost_pph22_percent_val_local_cur_new = Number(
                          coal_cost_pph22_percent_val
                        );
                        let coal_cost_pph22_export_value_local_cur_new = Number(
                          coal_cost_pph22_export_value_local_cur
                        ).toFixed(2);
                        if (
                          this.state.coal_cost_billing_currency === "0" ||
                          purchase_currency ===
                            this.state.coal_cost_billing_currency
                        ) {
                          coal_cost_pph22_percent_val_local_cur_new = (
                            Number(coal_cost_pph22_percent_val) *
                            Number(this.state.coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                          coal_cost_pph22_export_value_local_cur_new = (
                            Number(coal_cost_pph22_export_value_local_cur) *
                            Number(this.state.coal_cost_currency_xchg_rate)
                          ).toFixed(2);
                        }
                        if (
                          (config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") &&
                          this.state.coal_cost_billing_currency === "IDR"
                        ) {
                          coal_cost_coal_price_pmt_local_currency = (
                            Number(coal_cost_coal_price_pmt) *
                            Number(coal_cost_currency_xchg_rate)
                          ).toFixed(0);
                          coal_cost_adjusted_coal_price_pmt = Number(
                            coal_cost_adjusted_coal_price_pmt
                          ).toFixed(0);
                        }
                        this.setState(
                          {
                            purchase_index_linked:
                              res.purchase_contract_data.purchase_index_linked,
                            pc_quality: res.purchase_contract_data.pc_quality,
                            coal_cost_gar_bonus:
                              coal_cost_gar_bonus !== null
                                ? Number(coal_cost_gar_bonus)
                                : "",
                            coal_cost_gar_penality:
                              coal_cost_gar_penality !== null
                                ? Number(coal_cost_gar_penality)
                                : "",
                            coal_cost_ash_penality:
                              coal_cost_ash_penality !== null
                                ? Number(coal_cost_ash_penality)
                                : "",
                            coal_cost_sulphur_penality:
                              coal_cost_sulphur_penality !== null
                                ? Number(coal_cost_sulphur_penality)
                                : "",
                            // coal_cost_coal_quantity: this.state.coal_cost_coal_quantity ? this.state.coal_cost_coal_quantity : '',
                            // coal_cost_coal_price: Number(coal_cost_coal_price).toFixed(2),
                            coal_cost_coal_price_pmt: coal_cost_coal_price_pmt,
                            coal_cost_coal_price_pmt_local_currency:
                              coal_cost_coal_price_pmt_local_currency,
                            purchase_currency:
                              res.purchase_contract_data.purchase_currency,
                            coal_cost_adjusted_coal_price:
                              coal_cost_adjusted_coal_price,
                            coal_cost_adjusted_coal_price_pmt:
                              coal_cost_adjusted_coal_price_pmt,
                            // coal_cost_currency_xchg_rate: coal_cost_currency_xchg_rate !== 1 ? Number(coal_cost_currency_xchg_rate) : '',
                            coal_cost_adjusted_coal_price_in_local_currency:
                              coal_cost_adjusted_coal_price_in_local_currency,

                            coal_cost_pph22_barge_quantity_in_mt:
                              coal_cost_pph22_barge_quantity_in_mt
                                ? Number(coal_cost_pph22_barge_quantity_in_mt)
                                : "",
                            coal_cost_pph22_coal_price_per_mt:
                              coal_cost_pph22_coal_price_per_mt
                                ? Number(coal_cost_pph22_coal_price_per_mt)
                                : "",
                            // coal_cost_pph22_percent_val:
                            //   config.company === "SRPL" || config.company === "AVS"  ||
                            //   config.company === "PRPL"
                            //     ? (
                            //         Number(coal_cost_pph22_percent_val) /
                            //         Number(coal_cost_currency_xchg_rate)
                            //       ).toFixed(0)
                            //     : (
                            //         Number(coal_cost_pph22_percent_val) *
                            //         Number(coal_cost_currency_xchg_rate)
                            //       ).toFixed(0),
                            coal_cost_pph22_percent_val_local_cur:
                              coal_cost_pph22_percent_val_local_cur,
                            coal_cost_pph22_percent_val_local_cur_new:
                              coal_cost_pph22_percent_val_local_cur_new,
                            vat_amount: vat_amount,
                            vat_value: vat_value,
                            coal_cost_pph22_export_amount:
                              coal_cost_pph22_export_amount
                                ? Number(coal_cost_pph22_export_amount)
                                : "",
                            coal_cost_pph22_export_percent:
                              coal_cost_pph22_export_percent,
                            coal_cost_pph22_export_value: Number(
                              coal_cost_pph22_export_value
                            ).toFixed(0),
                            coal_cost_pph22_export_value_local_cur: Number(
                              coal_cost_pph22_export_value_local_cur
                            ),
                            coal_cost_pph22_export_value_local_cur_new:
                              coal_cost_pph22_export_value_local_cur_new,

                            paid_to_supplier: paid_to_supplier,

                            display_pph22: Number(display_pph22).toFixed(0),
                          },
                          () => {
                            this.onAdvanceAdjustmentsCal();
                          }
                        );
                      }
                    });
                  }
                });

              var purchaseType = res.barges.purchase_type;
              var barge_quantity = "";
              barge_quantity = res.barges.barge_quantity_in_mt;
              if (barge_quantity === null || Number(barge_quantity) <= 0) {
                barge_quantity = res.barges.barge_planned_quantity_in_mt;
              }
              if (
                purchaseType === "FAS Anchorage" &&
                res.barges.redraft_quantity !== null &&
                res.barges.quantity_difference_account === "Supplier"
              ) {
                barge_quantity = res.barges.redraft_quantity;
              }
              this.setState({
                redraftParty: res.barges.redraft_party,
                redraftPartyQuantity: res.barges.redraft_quantity,
                barge_quantity: barge_quantity,
                purchase_type: res.barges.purchase_type,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          }
        });
      }
    });
  }

  onAdvanceAdjustmentsCal = () => {
    var row = this.state.advance_adjustments;
    var total_advance_adjustment_value = 0;
    for (var i of row) {
      total_advance_adjustment_value += Number(i.advance_adjustment_value);
    }
    var {
      coal_cost_adjusted_coal_price_in_local_currency,
      vat_value,
      coal_cost_pph22_percent_val_local_cur,
      coal_cost_pph22_export_value_local_cur,
    } = this.state;

    var display_pph22 =
      Number(coal_cost_pph22_percent_val_local_cur) +
      Number(coal_cost_pph22_export_value_local_cur);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      display_pph22 = Number(coal_cost_pph22_export_value_local_cur);
      vat_value = 0;
    }
    var payable_to_supplier =
      Number(coal_cost_adjusted_coal_price_in_local_currency) +
      Number(vat_value) -
      Number(coal_cost_pph22_percent_val_local_cur) -
      Number(coal_cost_pph22_export_value_local_cur) -
      Number(total_advance_adjustment_value);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      payable_to_supplier =
        Number(coal_cost_adjusted_coal_price_in_local_currency) +
        Number(vat_value) -
        Number(coal_cost_pph22_export_value_local_cur) -
        Number(total_advance_adjustment_value);
    }
    this.setState({
      paid_to_supplier: Number(payable_to_supplier).toFixed(2),
      total_advance_adjustment_value: Number(
        total_advance_adjustment_value
      ).toFixed(0),
      display_pph22: Number(display_pph22).toFixed(0),
      advance_adjustments: row,
      isLoading: false,
    });
  };

  // get_weekly_coal_prices = () => {
  //   const { offset = 0, limit = 1000 } = this.state;
  //   const reqParams = {
  //     login_user_id: this.Cookie.getCookie("loginUserId"),
  //     idtoken: this.Cookie.getIdTokenCookie(),
  //     offset: offset,
  //     limit: limit,
  //   };
  //   api.get_weekly_coal_indexes(reqParams).then((response) => {
  //     if (response.status >= 200 && response.status < 300) {
  //       response.json().then((res) => {
  //         if (res.code === "200") {
  //           this.setState({
  //             weekly_coal_indexes: res.weekly_coal_indexes,
  //           });
  //         }
  //       });
  //     }
  //   });
  // };

  // get_monthly_coal_prices = async () => {
  //   const { offset = 0, limit = 1000 } = this.state;
  //   const reqParams = {
  //     login_user_id: this.Cookie.getCookie("loginUserId"),
  //     idtoken: this.Cookie.getIdTokenCookie(),
  //     offset: offset,
  //     limit: limit,
  //   };
  //   await api.get_monthly_coal_indexes(reqParams).then((response) => {
  //     if (response.status >= 200 && response.status < 300) {
  //       response.json().then((res) => {
  //         if (res.code === "200") {
  //           this.setState({
  //             monthly_coal_indexes: res.monthly_coal_indexes,
  //             // submitting: false,
  //           });
  //         }
  //       });
  //     }
  //   });
  // };

  getBargePurchasePaymentPosting = (e) => {
    if (e) {
      var paymentTitle = "";
      if (e.payment_posting_type === "Coal") {
        paymentTitle = "Update Coal";
      }

      this.setState({
        existingAmount: e.amount,
        payment_posting_id: e.id,
        paymentTitle: paymentTitle,
        payment_posting_type: e.payment_posting_type,
        amount: e.amount,
        date_of_payment: e.date_of_payment,
        payment_due_date: e.payment_due_date,
        invoice_no: e.invoice_no ? e.invoice_no : "",
        description: e.description ? e.description : "",
        date_of_paymentError: false,
        amountError: false,
        paymentErrorMsg: "",
        paymentMode: "Update",
      });
      window.$("#bargePurchasePaymentPostingModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  addBargePurchasePaymentPostingHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      bargePurFinaID,
      payment_posting_type,
      date_of_payment,
      payment_due_date,
      amount,
      invoice_no,
      description,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var status = "Active";

    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }

    // Doing Amount calculation
    var remainingAmount = 0;
    if (this.state.payment_posting_type === "Coal") {
      remainingAmount =
        Number(this.state.paid_to_supplier) -
        Number(this.state.totalCoalPaymentsAmount);
      if (this.state.paymentMode === "Update") {
        remainingAmount =
          Number(this.state.paid_to_supplier) -
          Number(this.state.totalCoalPaymentsAmount) +
          Number(this.state.existingAmount);
      }
      if (Number(amount) > Number(remainingAmount)) {
        this.setState({
          amountError: true,
          submitting: false,
          paymentErrorMsg: "Amount Is Exceeding Payable to Supplier",
        });
        return;
      }
    }

    if (amount !== "" && invoice_no !== "") {
      // return;
      api
        .add_barge_pur_fin_coal_payment(
          loginUserID,
          idToken,
          bargePurFinaID,
          payment_posting_type,
          amount,
          date_of_payment,
          payment_due_date,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  paymentTitle: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  payment_due_date: null,
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                });
                window.$("#bargePurchasePaymentPostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
      });
      alert("Please fill mandatory fields.");
    }
  };

  UpdateBargePurchasePaymentPostingHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      bargePurFinaID,
      payment_posting_type,
      date_of_payment,
      payment_due_date,
      amount,
      invoice_no,
      description,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var payment_id = this.state.payment_posting_id;
    var status = "Active";

    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }
    // Doing Amount calculation
    var remainingAmount = 0;
    if (this.state.payment_posting_type === "Coal") {
      remainingAmount =
        Number(this.state.paid_to_supplier) -
        Number(this.state.totalCoalPaymentsAmount);
      if (this.state.paymentMode === "Update") {
        remainingAmount =
          Number(this.state.paid_to_supplier) -
          Number(this.state.totalCoalPaymentsAmount) +
          Number(this.state.existingAmount);
      }
      if (Number(amount) > Number(remainingAmount)) {
        this.setState({
          amountError: true,
          submitting: false,
          paymentErrorMsg: "Amount Is Exceeding Payable to Supplier",
        });
        return;
      }
    }

    if (amount !== "" && invoice_no !== "") {
      //
      api
        .update_barge_pur_fin_coal_payment(
          loginUserID,
          idToken,
          bargePurFinaID,
          payment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          payment_due_date,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  paymentTitle: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  payment_due_date: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                });
                window.$("#bargePurchasePaymentPostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
      });
      alert("Please fill mandatory fields.");
    }
  };

  deleteBargePurchasePaymentPosting = (e) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var bargePurFinaID = this.state.bargePurFinaID;
    var payment_id = e.id;
    var payment_posting_type = e.payment_posting_type;
    var amount = e.amount;
    var date_of_payment = e.date_of_payment;
    var payment_due_date = e.payment_due_date;
    var invoice_no = e.invoice_no ? e.invoice_no : "";
    var description = e.description ? e.description : "";
    var status = "Deleted";
    api
      .update_barge_pur_fin_coal_payment(
        loginUserID,
        idToken,
        bargePurFinaID,
        payment_id,
        payment_posting_type,
        amount,
        date_of_payment,
        payment_due_date,
        invoice_no,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  approveHandler = () => {
    this.setState({ isLoading: true });
    var comboID = this.props.comboID;
    var bargePurFinaID = comboID.split("$#")[0];
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: bargePurFinaID,
      costing_type: "Barge Purchase performa Coal Costing",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "barge_purchase_financial_coal_payments_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_purchase_financial_coal_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  // getCoalIndexes = () => {
  //   const { coal_index_type = "Weekly", index_ids = [] } =
  //     this.state.coal_index || {};

  //   const { weekly_coal_indexes = [], monthly_coal_indexes = [] } =
  //     this.state || {};

  //   let coal_indexes =
  //     coal_index_type === "Weekly"
  //       ? weekly_coal_indexes.filter((e) =>
  //           index_ids.find((el) => el === parseFloat(e.id))
  //         )
  //       : monthly_coal_indexes.filter((e) =>
  //           index_ids.find((el) => el === parseFloat(e.id))
  //         );

  //   return coal_indexes;
  // };

  qualityResultsCalculations = (type) => {
    if (
      this.state.purchasing_term === "Specs" &&
      this.state.costing_type === "Commercial"
    ) {
      var garPenality = "";
      var garBonus = "";
      var ashPenality = "";
      var sulphurPenality = "";
      let {
        garActiualValue,
        garTypicalValue,
        bonusCap,
        penalityCap,
        coal_cost_coal_price_pmt,
      } = this.state;
      if (
        garActiualValue !== null &&
        garTypicalValue !== null &&
        garActiualValue !== "" &&
        garTypicalValue !== ""
      ) {
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        }
        if (Number(garActiualValue) < Number(garTypicalValue)) {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          garBonus =
            ((Number(garActiualValue) - Number(garTypicalValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);

          if (type === "GAR") {
            return `${garActiualValue} - ${garTypicalValue} / ${garTypicalValue} * ${parseFloat(
              coal_cost_coal_price_pmt
            )}   =  ${parseFloat(garBonus).toFixed(2)}`;
          }
        }
        if (Number(garTypicalValue) > Number(garActiualValue)) {
          garPenality =
            ((Number(garTypicalValue) - Number(garActiualValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
          if (type === "GAR") {
            return `${garTypicalValue} - ${garActiualValue} / ${garTypicalValue} * ${coal_cost_coal_price_pmt}   =  ${parseFloat(
              garPenality
            ).toFixed(2)}`;
          }
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
            Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          ashPenality =
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
          if (type === "Ash") {
            return `${this.state.ashPenality} * ${parseFloat(
              this.state.quality_ash_actual
            )} - ${parseFloat(
              this.state.quality_ash_typical
            )} / 1    =  ${parseFloat(ashPenality).toFixed(2)}`;
          }
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
            Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
          if (type === "Sulphur") {
            return `${this.state.sulphurPenality} * ${parseFloat(
              this.state.quality_total_sulphur_actual
            )} - ${parseFloat(
              this.state.quality_total_sulphur_typical
            )} / 0.1  =  ${parseFloat(sulphurPenality).toFixed(2)}`;
          }
        }
      }
    }
  };

  payable_to_vendor = () => {
    // $payable = $adjusted_coal_price_in_local_currency + $vat_value - $pph22_value - $pph22_export_value - $comm_adv_adj_value - $performa_payments_amount - $performa_adv_adj_value;
    const {
      coal_cost_adjusted_coal_price_in_local_currency = 0,
      vat_value = 0,
      pph22_value = 0,
      pph22_export_value = 0,
      total_advance_adjustment_value = 0,
      performa_payments_amount = 0,
      performa_advance_adjustment = 0,
    } = this.state;

    return (
      parseFloat(coal_cost_adjusted_coal_price_in_local_currency) +
      parseFloat(vat_value) -
      parseFloat(pph22_value) -
      parseFloat(pph22_export_value) -
      parseFloat(total_advance_adjustment_value) -
      parseFloat(performa_payments_amount) -
      parseFloat(performa_advance_adjustment)
    );
  };

  renderInvoiceLink = () => {
    var comboID = this.props.comboID;
    var bargePurFinaID = comboID.split("$#")[0];
    const data = this.state.purchaseBargeList.map((e) =>
      e.barges.filter((obj) => obj.barge_pur_fin_id === bargePurFinaID)
    )[0];
    const {
      costing_no,
      invoiceDetails: { invoice_no = "", invoice_date = "" } = {},
      coal_cost_coal_quantity,
      // coal_cost_coal_price,
      coal_cost_adjusted_coal_price_in_local_currency = 0,
      advance_adjustments,
      coal_cost_currency_xchg_rate = 1,
      coal_cost_adjusted_coal_price_pmt,
      // coal_index = {},
      vendor_name = "",
      mv_name = "",
      purchase_type = "FOB Barge",
      purchase_index_linked = "No",
    } = this.state;

    let costed_barges = data;

    if (purchase_type !== "FOB Barge") {
      costed_barges = data.filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.purchase_contract_no === v.purchase_contract_no
          ) === i
      );
    }

    const filter_advance = advance_adjustments.filter(
      (e) => e.advance_adjustment !== "No"
    );

    const advanceAdjustments = [];
    for (var i of filter_advance) {
      if (i.adjustment_from === "Purchase Contract") {
        advanceAdjustments.push({
          desc: `Adv deduction against contract: ${i.purchase_contract_no}`,
          qty: `${toLocaleString(
            i.advance_adjustment_barge_quantity
              ? i.advance_adjustment_barge_quantity
              : " "
          )}`,
          adjustedPrice: `${toLocaleString(
            i.adjustment_per_mt ? i.adjustment_per_mt : " "
          )}`,
          amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
        });
      } else if (
        i.adjustment_from === "Advance Contract" ||
        i.adjustment_from === "Down Payment"
      ) {
        advanceAdjustments.push({
          desc: `Adv deduction against contract: ${i.vendor_contract_no}`,
          qty: `${toLocaleString(
            i.advance_adjustment_barge_quantity
              ? i.advance_adjustment_barge_quantity
              : " "
          )}`,
          adjustedPrice: `${toLocaleString(
            i.adjustment_per_mt ? i.adjustment_per_mt : " "
          )}`,
          amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
        });
      }
    }
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: this.props.costing_type,
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: costed_barges ? costed_barges.flat() : [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(this.payable_to_vendor()),
      purchase_type: purchase_type,
      purchase_index_linked: purchase_index_linked,
      description: [
        {
          qty: toLocaleString(coal_cost_coal_quantity),
          adjustedPrice: toLocaleString(coal_cost_adjusted_coal_price_pmt),
          desc: `Coal price ${
            this.props.costing_type === "Performa"
              ? `@ ${toLocaleString(this.state.invoice_percentage)} %`
              : ""
          }`,
          amount: toLocaleString(
            Number(coal_cost_adjusted_coal_price_in_local_currency)
          ),
        },
        ...(coal_cost_currency_xchg_rate > 1
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Exchange Rate @ ${coal_cost_currency_xchg_rate}  ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.purchase_currency !==
        this.state.coal_cost_billing_currency
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Billing Currency ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.performa_advance_adjustment &&
        this.state.costing_type === "Commercial"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Performa advance adjustments",
                amount: `( ${toLocaleString(
                  this.state.performa_advance_adjustment
                )} )`,
              },
            ]
          : []),
        ...(this.state.costing_type === "Commercial" &&
        this.state.performa_payments_amount
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Received against performa invoice",
                amount: `( ${toLocaleString(
                  this.state.performa_payments_amount
                )} )`,
              },
            ]
          : []),
        ...advanceAdjustments,
        ...(this.state.vat_applicable === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.vat_value)}`,
              },
            ]
          : []),
        ...(this.state.coal_cost_pph22_export === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Export",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_export_value
                )} )`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) &&
        Number(this.state.coal_cost_pph22_percent_val) !== 0 &&
        this.state.coal_cost_pph22_percent
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Local",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_percent_val
                )} )`,
              },
            ]
          : []),
      ],
      // coalIndexes: {
      //   ...coal_index,
      //   show:
      //     this.state.purchase_index_linked === "Yes" &&
      //     this.state.costing_type !== "Performa",
      //   coal_index: this.getCoalIndexes(),
      // },

      qualityResults:
        this.state.purchasing_term === "Specs" &&
        this.state.costing_type === "Commercial"
          ? [
              {
                type: "GAR",
                units: "kcal/kg",
                basis: this.state.quality_calorific_value_basis,
                typical: this.state.quality_calorific_value_typical,
                rejection: this.state.quality_calorific_value_rejection,
                actual: this.state.quality_calorific_value_actual,
                bouns: this.state.bonusCap,
                penality: this.state.penalityCap,
                desc: this.qualityResultsCalculations("GAR"),
                // desc: `${
                //   this.state.coal_cost_gar_penality
                //     ? `- ${toLocaleString(this.state.coal_cost_gar_penality)}`
                //     : `+ ${toLocaleString(this.state.coal_cost_gar_bonus)}`
                // }`,
              },
              {
                type: "Ash",
                units: "kcal/kg",
                basis: this.state.quality_ash_basis,
                typical: this.state.quality_ash_typical,
                rejection: this.state.quality_ash_rejection,
                actual: this.state.quality_ash_actual,
                bouns: " ",
                penality: " ",
                desc: this.qualityResultsCalculations("Ash"),
                // desc: toLocaleString(this.state.coal_cost_ash_penality),
              },
              {
                type: "Sulphur",
                units: "kcal/kg",
                basis: this.state.quality_total_sulphur_basis,
                typical: this.state.quality_total_sulphur_typical,
                rejection: this.state.quality_total_sulphur_rejection,
                actual: this.state.quality_total_sulphur_actual,
                bouns: " ",
                penality: " ",
                desc: this.qualityResultsCalculations("Sulphur"),
                // desc: toLocaleString(this.state.coal_cost_sulphur_penality),
              },
            ]
          : null,
      paymentDetails: this.state.coalPaymentPostings,
    };

    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const comboID = this.props.comboID;
    const bargePurFinaID = comboID.split("$#")[0];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-9 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Barge ${this.state.costing_type} Coal Costing - ${this.state.costing_no}`}

                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                this.state.invoice_file &&
                this.state.approved_by !==
                  this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("388") ||
                  this.state.features.includes("439") ||
                  this.state.features.includes("97")) && (
                  <div
                    className="col-lg-2 text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={this.approveHandler}
                    >
                      Approve
                    </button>
                  </div>
                )}
              <div className="col-lg-1 pl-0">{this.renderInvoiceLink()}</div>
              {this.props.costing_type === "Performa" &&
                parseFloat(this.state.invoice_percentage) < 100 && (
                  <div className="col-lg-2 pl-0">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={() => {
                        this.setState(
                          {
                            CoalCostingAdd: true,
                            showDrawer: true,
                          },
                          () => {
                            this.props.invoiceCostingHandler({
                              barges: this.state.bargeList,
                              coalPricePerMT: this.state.coalPricePerMT,
                              purchase_currency: this.state.purchase_currency,
                              invoice_percentage: this.state.invoice_percentage,
                              purchase_contract_id:
                                this.state.selectedPurchaseContractId,
                              barge_pur_quality_id:
                                this.state.barge_pur_quality_id,
                              barge_quantity:
                                this.state.coal_cost_coal_quantity,
                              barge_id: this.state.bargeList[0],
                            });
                          }
                        );
                      }}
                    >
                      New Costing
                    </button>
                  </div>
                )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card p-2">
              {this.state.purchaseBargeList.map((p, index) => (
                <div
                  className={
                    p.new_barges.length > 0
                      ? "col shadow-sm p-0 mb-2 bg-white border"
                      : ""
                  }
                  key={index}
                  style={{
                    display:
                      p.purchase_contract_id !==
                      this.state.selectedPurchaseContractId
                        ? "none"
                        : "",
                  }}
                >
                  {p.new_barges.length > 0 && (
                    // <h5 className="mb-0 p-2 section_header">
                    <h5 className="mb-2 p-2 bg-light">
                      {p.purchase_contract_no} - {p.supplier_name}
                    </h5>
                  )}
                  {p.new_barges.length > 0 && (
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-bordered table-sm">
                          <thead className="table-header">
                            <tr>
                              <th nowrap="true"> Barge ID </th>
                              <th nowrap="true"> Barge Nomination </th>
                              <th nowrap="true"> Business No </th>
                              <th nowrap="true"> Purchase </th>
                              <th nowrap="true"> Quality </th>
                              <th nowrap="true"> Purchase Type </th>
                              <th className="text-right" nowrap="true">
                                Quantity
                              </th>
                              <th className="text-right" nowrap="true">
                                Price PMT
                              </th>
                              <th className="text-right" nowrap="true">
                                Coal Price
                              </th>
                              <th className="text-right" nowrap="true">
                                Advance Adjustment
                              </th>
                              <th className="text-right" nowrap="true">
                                Total Payable
                              </th>
                              <th className="text-right" nowrap="true">
                                Paid
                              </th>
                              <th className="text-right" nowrap="true">
                                Remaining
                              </th>
                            </tr>
                          </thead>
                          {p.new_barges.map((id, idx) => (
                            <tbody key={idx}>
                              {id.new_barges.map((idr, indx) => (
                                <tr
                                  key={indx}
                                  style={{
                                    display:
                                      this.state.bargePurFinaID ===
                                      idr.barge_pur_fin_id
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <td>
                                    <a
                                      href={"/view-barge/" + btoa(idr.barge_id)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {pad(idr.barge_id)}
                                    </a>
                                  </td>
                                  <td nowrap="true">{idr.barge_nomination}</td>
                                  <td nowrap="true">{idr.business_no}</td>
                                  {indx === 0 && (
                                    <td
                                      rowSpan={id.new_barges.length}
                                      nowrap="true purchase_contract_id"
                                    >
                                      <a
                                        href={
                                          "/view-purchase-contract/" +
                                          btoa(idr.purchase_contract_id)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: config.themeColor }}
                                      >
                                        {idr.purchase_contract_no}
                                      </a>
                                    </td>
                                  )}
                                  {indx === 0 && (
                                    <td
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {idr.quality}
                                    </td>
                                  )}
                                  {indx === 0 && (
                                    <td
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {idr.purchase_type}
                                    </td>
                                  )}
                                  <td
                                    className="table_td text-right"
                                    nowrap="true"
                                  >
                                    {this.state.coal_cost_coal_quantity}
                                  </td>
                                  <td
                                    className="table_td text-right"
                                    nowrap="true"
                                  >
                                    {toLocaleString(idr.price_pmt) +
                                      " " +
                                      idr.purchase_currency}
                                  </td>
                                  {indx === 0 && (
                                    <td
                                      className="table_td text-right"
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {toLocaleString(idr.coal_price) +
                                        " " +
                                        idr.billing_currency}
                                    </td>
                                  )}
                                  {indx === 0 && (
                                    <td
                                      className="table_td text-right"
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {toLocaleString(idr.adv_adjustments)}
                                    </td>
                                  )}
                                  {/* <td className="table_td" nowrap='true'>{toLocaleString(idr.adv_adjustments)}</td> */}
                                  {indx === 0 && (
                                    <td
                                      className="table_td text-right"
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {toLocaleString(idr.total_payable)}
                                    </td>
                                  )}
                                  {/* <td className="table_td" nowrap='true'>{toLocaleString(idr.total_payable)}</td> */}
                                  {indx === 0 && (
                                    <td
                                      className="table_td text-right"
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {toLocaleString(idr.paid)}
                                    </td>
                                  )}
                                  {/* <td className="table_td" nowrap='true'>{toLocaleString(idr.paid)}</td> */}
                                  {indx === 0 && (
                                    <td
                                      className="table_td text-right"
                                      rowSpan={id.new_barges.length}
                                      nowrap="true"
                                    >
                                      {toLocaleString(idr.remaining_amount)}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              {/* newCHanges */}
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-right">Qty</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Coal Price{" "}
                          {this.props.costing_type === "Performa"
                            ? `@ ${toLocaleString(
                                this.state.invoice_percentage
                              )} %`
                            : ""}
                          {this.state.coal_cost_currency_xchg_rate > 1 && (
                            <>
                              <div>
                                Coal Price Per MT @
                                {parseFloat(
                                  this.state.coal_cost_coal_price_pmt
                                ).toFixed(2)}
                              </div>
                              <div>
                                Billing Currency{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                              {this.state.purchase_currency !==
                                this.state.coal_cost_billing_currency && (
                                <div>
                                  Exchange Rate @{" "}
                                  {this.state.coal_cost_currency_xchg_rate}
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td className="text-right">
                          {this.state.coal_cost_coal_quantity
                            ? toLocaleString(this.state.coal_cost_coal_quantity)
                            : ""}
                        </td>
                        <td className="text-right">
                          {`${toLocaleString(
                            this.state.coal_cost_adjusted_coal_price_pmt
                          )} ${this.state.purchase_currency}`}
                        </td>
                        {/* <td className="text-right">
                          {(parseFloat(
                            this.state.coal_cost_adjusted_coal_price
                              ? this.state.coal_cost_adjusted_coal_price
                              : 1
                          ) *
                            parseFloat(
                              this.state.invoice_percentage
                                ? this.state.invoice_percentage
                                : 100
                            )) /
                            100}
                        </td> */}
                        <td className="text-right">
                          {parseFloat(
                            this.state
                              .coal_cost_adjusted_coal_price_in_local_currency
                          )}
                        </td>
                      </tr>
                      {this.state.costing_type === "Commercial" && (
                        <>
                          <tr>
                            <td>Received against performa invoice</td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                              (
                              {parseFloat(
                                this.state.performa_payments_amount
                                  ? this.state.performa_payments_amount
                                  : 0
                              ).toFixed(2)}
                              )
                            </td>
                          </tr>
                          <tr>
                            <td>Performa advance adjustments</td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                              (
                              {parseFloat(
                                this.state.performa_advance_adjustment
                              ).toFixed(2)}
                              )
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <th colSpan={4}>
                          Advance Adjustments &nbsp;
                          <i
                            className="fa fa-plus-circle"
                            style={{
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({ openAdvAdjustments: true });
                            }}
                          />
                        </th>
                      </tr>

                      {this.state.advance_adjustments
                        ?.filter((e) => e.advance_adjustment !== "No")
                        .map((item, idx) => (
                          <tr key={item.id}>
                            <td className="">
                              {`Adv deduction against contract:
                               ${
                                 item.adjustment_from === `Purchase Contract`
                                   ? item.purchase_contract_no
                                   : item.adjustment_from ===
                                       `Advance Contract` ||
                                     item.adjustment_from === `Down Payment`
                                   ? item.vendor_contract_no
                                   : `-`
                               }`}{" "}
                              &nbsp;
                              <i
                                className="fa fa-pencil"
                                style={{
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    openAdvAdjustments:
                                      !prevState.openAdvAdjustments,
                                  }))
                                }
                              />
                            </td>
                            <td className="text-right ">
                              {item.advance_adjustment !== "Lumpsum"
                                ? toLocaleString(
                                    item.advance_adjustment_barge_quantity
                                  )
                                : ""}
                            </td>
                            <td className="text-right ">
                              {item.advance_adjustment !== "Lumpsum"
                                ? toLocaleString(item.adjustment_per_mt)
                                : ""}
                            </td>
                            <td className="text-right ">
                              {`( ${toLocaleString(
                                item.advance_adjustment_value
                              )} )`}
                            </td>
                          </tr>
                        ))}
                      {(config.company !== "SRPL" ||
                        config.company !== "PRPL" ||
                        config.company !== "AVS") &&
                        this.state.vat_value !== 0 && (
                          <tr>
                            <td>VAT @ 10 %</td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              {toLocaleString(this.state.vat_value)}
                            </td>
                          </tr>
                        )}
                      {this.state.coal_cost_pph22_export === "Yes" && (
                        <tr>
                          <td>
                            PPH22 Export @{" "}
                            {toLocaleString(
                              this.state.coal_cost_pph22_export_percent
                            )}{" "}
                            %
                          </td>
                          <td className="text-right"></td>
                          <td className="text-right"></td>
                          <td className="text-right">
                            (
                            {Number(
                              this.state.coal_cost_pph22_export_value
                            ).toFixed(2)}
                            )
                          </td>
                        </tr>
                      )}
                      {!(
                        config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                      ) &&
                        Number(this.state.coal_cost_pph22_percent_val) !== 0 &&
                        this.state.coal_cost_pph22_percent && (
                          <tr>
                            <td>
                              PPH22 Local @{" "}
                              {toLocaleString(
                                this.state.coal_cost_pph22_percent
                              )}
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              (
                              {Number(
                                this.state.coal_cost_pph22_percent_val
                              ).toFixed(2)}
                              )
                            </td>
                          </tr>
                        )}
                      {/* {!(
                        config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS"
                      ) && (
                          <tr>
                            <td>PPH @1.2</td>
                            <td className="text-right "></td>
                            <td className="text-right "></td>
                            <td className="text-right ">pph</td>
                          </tr>
                        )} */}
                      <tr>
                        <td
                          colSpan={3}
                          className="text-right "
                          style={{ fontWeight: "bold" }}
                        >
                          Payable to Vendor :{" "}
                        </td>
                        {
                          <td
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            {` ${
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                                ? "USD"
                                : "IDR"
                            } `}
                            {toLocaleString(this.payable_to_vendor())}
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* {this.state.purchasing_term === "Specs" &&
                  this.state.costing_type === "Commercial" && (
                    <div>
                      <div className="col shadow-sm p-0 mb-2 bg-white border ">
                        <h5
                          className="mb-0 p-2 section_header"
                          style={{ fontSize: 14 }}
                        >
                          GAR Penality / Bonus per MT
                        </h5>
                        <table className="table border-0 mb-0">
                          <tbody>
                            <tr>
                              <td className="p-0">
                                <table className="table mb-0 border-0">
                                  <thead className="border-0">
                                    <tr>
                                      <th className="allocation_table_header_label border-0">
                                        Units
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Basis
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Typical
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Rejection
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Actual
                                      </th>
                                      <th
                                        className="allocation_table_header_label border-0"
                                        style={{ minWidth: 95 }}
                                      >
                                        Bonus Cap
                                      </th>
                                      <th
                                        className="allocation_table_header_label border-0"
                                        style={{ minWidth: 100 }}
                                      >
                                        Penality Cap
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="allocation_table_value border-0">
                                        Kcal/kg
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value_basis
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value_typical
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value_rejection
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value_actual
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.bonusCap}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.penalityCap}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="allocation_table_value border-0">
                                        Kcal/kg
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value2_basis
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value2_typical
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value2_rejection
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value2_actual
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.bonusCap2}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.penalityCap2}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="allocation_table_value border-0">
                                        Kcal/kg
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value3_basis
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value3_typical
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value3_rejection
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_calorific_value3_actual
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.bonusCap3}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.penalityCap3}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style={{ width: "22%", padding: 8 }}>
                                {this.state.coal_cost_gar_penality
                                  ? "Penality - " +
                                    toLocaleString(
                                      this.state.coal_cost_gar_penality
                                    )
                                  : ""}
                                <br />
                                {this.state.coal_cost_gar_bonus
                                  ? "Bonus - " +
                                    toLocaleString(
                                      this.state.coal_cost_gar_bonus
                                    )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col shadow-sm p-0 mb-2 bg-white border ">
                        <h5
                          className="mb-0 p-2 section_header"
                          style={{ fontSize: 14 }}
                        >
                          ASH Penality per MT
                        </h5>
                        <table className="table border-0 mb-0">
                          <tbody>
                            <tr>
                              <td className="p-0">
                                <table className="table mb-0 border-0">
                                  <thead className="border-0">
                                    <tr>
                                      <th className="allocation_table_header_label border-0">
                                        Units
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Basis
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Typical
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Rejection
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Actual
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="allocation_table_value border-0">
                                        Kcal/kg
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.quality_ash_basis}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.quality_ash_typical}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.quality_ash_rejection}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.quality_ash_actual}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style={{ width: "20%", padding: 8 }}>
                                {this.state.coal_cost_ash_penality
                                  ? toLocaleString(
                                      this.state.coal_cost_ash_penality
                                    )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col shadow-sm p-0 mb-2 bg-white border ">
                        <h5
                          className="mb-0 p-2 section_header"
                          style={{ fontSize: 14 }}
                        >
                          Sulphur Penality per MT
                        </h5>
                        <table className="table border-0 mb-0">
                          <tbody>
                            <tr>
                              <td className="p-0">
                                <table className="table mb-0 border-0">
                                  <thead className="border-0">
                                    <tr>
                                      <th className="allocation_table_header_label border-0">
                                        Units
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Basis
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Typical
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Rejection
                                      </th>
                                      <th className="allocation_table_header_label border-0">
                                        Actual
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="allocation_table_value border-0">
                                        Kcal/kg
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {this.state.quality_total_sulphur_basis}
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_total_sulphur_typical
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_total_sulphur_rejection
                                        }
                                      </td>
                                      <td className="allocation_table_value border-0">
                                        {
                                          this.state
                                            .quality_total_sulphur_actual
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style={{ width: "20%", padding: 8 }}>
                                {this.state.coal_cost_sulphur_penality
                                  ? toLocaleString(
                                      this.state.coal_cost_sulphur_penality
                                    )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )} */}
                {this.state.purchasing_term === "Specs" &&
                  this.state.costing_type === "Commercial" && (
                    <div className="table-responsive p-2">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Type</th>
                            <th className="text-right">Basis</th>
                            <th className="text-right">Typical</th>
                            <th className="text-right">Rejection</th>
                            <th className="text-right">Actual</th>
                            {/* <th className="text-right">Bonus Cap</th> */}
                            <th className="text-center">Bonus / Penality</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>GAR</td>
                            <td className="text-right">
                              {this.state.quality_calorific_value_basis}
                            </td>
                            <td className="text-right">
                              {this.state.quality_calorific_value_typical}
                            </td>
                            <td className="text-right">
                              {this.state.quality_calorific_value_rejection}
                            </td>
                            <td className="text-right">
                              {this.state.quality_calorific_value_actual}
                            </td>
                            {/* <td className="text-right">{this.state.bonusCap}</td> */}
                            <td className="text-center">
                              {this.qualityResultsCalculations("GAR")}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>{this.state.quality_calorific_value2_basis}</td>
                            <td>
                              {this.state.quality_calorific_value2_typical}
                            </td>
                            <td>
                              {this.state.quality_calorific_value2_rejection}
                            </td>
                            <td>
                              {this.state.quality_calorific_value2_actual}
                            </td>
                            <td>{this.state.bonusCap2}</td>
                            <td>{this.state.penalityCap2}</td>
                          </tr>
                          <tr>
                            <td>{this.state.quality_calorific_value3_basis}</td>
                            <td>
                              {this.state.quality_calorific_value3_typical}
                            </td>
                            <td>
                              {this.state.quality_calorific_value3_rejection}
                            </td>
                            <td>
                              {this.state.quality_calorific_value3_actual}
                            </td>
                            <td>{this.state.bonusCap3}</td>
                            <td>{this.state.penalityCap3}</td>
                          </tr> */}
                          <tr>
                            <td>ASH</td>
                            <td> {this.state.quality_ash_basis}</td>
                            <td> {this.state.quality_ash_typical}</td>
                            <td className="text-right">
                              {this.state.quality_ash_rejection}
                            </td>
                            <td className="text-right">
                              {this.state.quality_ash_actual}
                            </td>
                            {/* <td className="text-right"></td> */}
                            <td className="text-center">
                              {this.qualityResultsCalculations("Ash")}
                            </td>
                          </tr>
                          <tr>
                            <td>Sulphur</td>
                            <td>{this.state.quality_total_sulphur_basis}</td>
                            <td> {this.state.quality_total_sulphur_typical}</td>
                            <td className="text-right">
                              {this.state.quality_total_sulphur_rejection}
                            </td>
                            <td className="text-right">
                              {" "}
                              {this.state.quality_total_sulphur_actual}
                            </td>
                            {/* <td className="text-right"></td> */}
                            <td className="text-center">
                              {this.qualityResultsCalculations("Sulphur")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
              {(this.state.features.includes("131") ||
                this.state.features.includes("97")) && (
                <div className="col shadow-sm p-0 mb-2 bg-white border ">
                  <InvoiceForm
                    open={this.state.invoiceDialog}
                    data={this.state.invoiceDetails}
                    costingID={bargePurFinaID}
                    costing_type={"Coal Costing"}
                    callbackFn={(data) => this.updateInvoice(data)}
                  />

                  <div className="row">
                    <div className="col p-3">
                      {this.state.coalPaymentPostings <= 0 && (
                        <h6>No payments made</h6>
                      )}
                    </div>
                    {(this.state.features.includes("130") ||
                      this.state.features.includes("97")) &&
                      this.state.is_commercial_invoices_raised === 0 &&
                      this.state.invoiceDetails?.invoice_no && (
                        <div>
                          {this.state.costing_type === "Commercial" ? (
                            <button
                              className="header_button header_button_text payment_button_adjustment"
                              style={{ marginTop: "10px" }}
                              data-toggle="modal"
                              title="Barge Payment Postings"
                              data-placement="bottom"
                              data-keyboard="false"
                              data-target="#confirmationAddPaymentModal"
                              data-backdrop="static"
                            >
                              Add Payment
                            </button>
                          ) : (
                            <button
                              // className="row p-1 ml-1" // className="header_button header_button_text payment_button_adjustment"
                              className="header_button header_button_text payment_button_adjustment"
                              style={{
                                marginTop: "10px",
                                backgroundColor: "white",
                                border: 0,
                              }}
                              data-toggle="modal"
                              title="Barge Payment Postings"
                              data-placement="bottom"
                              data-keyboard="false"
                              data-target="#bargePurchasePaymentPostingModal"
                              data-backdrop="static"
                              onClick={() => {
                                this.setState({
                                  paymentTitle: "Add Coal",
                                  payment_posting_type: "Coal",
                                  date_of_payment: null,
                                  amount: "",
                                  invoice_no:
                                    this.state.invoiceDetails?.invoice_no,
                                  description: "",
                                  paymentErrorMsg: "",
                                  date_of_paymentError: false,
                                  amountError: false,
                                  invoice_noError: false,
                                  paymentMode: "Add",
                                });
                              }}
                            >
                              Add Payment
                              {/* <i
                                  className="fa fa-plus-circle"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                /> */}
                            </button>
                          )}
                        </div>
                      )}
                  </div>

                  {this.state.coalPaymentPostings.length > 0 && (
                    <div className="row">
                      <div className="col-lg">
                        <div className="table-responsive border-0">
                          <table className="table table-borderless border-0">
                            <thead>
                              <tr>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  Payment ID
                                </th>
                                {/* <th
                                    nowrap="true"
                                    className="table_header_barge_label text-left border-0"
                                    style={{ textAlign: "center" }}
                                  >
                                    Date of Payment
                                  </th> */}
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-right border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  Payment Due Date
                                </th>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-right border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  Amount
                                </th>
                                {/* <th
                                    nowrap="true"
                                    className="table_header_barge_label text-left border-0"
                                    style={{ textAlign: "center" }}
                                  >
                                    Invoice No
                                  </th> */}
                                <th
                                  className="table_header_barge_label text-left border-0"
                                  style={{
                                    width: 300,
                                    maxWidth: 250,
                                    textAlign: "center",
                                  }}
                                >
                                  Remarks
                                </th>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  Created Date
                                </th>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                >
                                  Approval
                                </th>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                >
                                  Approved On
                                </th>
                                {/* <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                >
                                  2nd Level Approval
                                </th>
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                >
                                  2nd Level Approved On
                                </th> */}
                                <th
                                  nowrap="true"
                                  className="table_header_barge_label text-left border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.coalPaymentPostings.map(
                                (e, index) => (
                                  <tr key={index}>
                                    <td nowrap="true">{e.payment_id}</td>
                                    <td
                                      nowrap="true"
                                      className="table_td"
                                      style={{ minWidth: 170 }}
                                    >
                                      {/* {this.state.approval_status ===
                                        "Approved" &&
                                        e.approval_status === "Approved" &&
                                        (e.approval_status_2 === "Optional" ||
                                          e.approval_status_2 ===
                                            "Approved") && ( */}
                                      {/* {this.state.approval_status ===
                                          "Approved" && (
                                            <MuiPickersUtilsProvider
                                              utils={DateFnsUtils}
                                            >
                                              <DatePicker
                                                autoOk={true}
                                                inputVariant="outlined"
                                                variant="inline"
                                                margin="dense"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={e.date_of_payment}
                                                error={
                                                  this.state.date_of_paymentError
                                                }
                                                onChange={(date) => {
                                                  let data =
                                                    this.state.coalPaymentPostings;
                                                  data[index].date_of_payment =
                                                    date;
                                                  this.setState(
                                                    {
                                                      coalPaymentPostings: data,
                                                      barge_cps_costing_id: e.id,
                                                      payment_posting_id: e.id,
                                                      payment_posting_type:
                                                        e.payment_posting_type,
                                                      date_of_payment: date,
                                                      amount: e.amount,
                                                      invoice_no: e.invoice_no,
                                                      description: e.description,
                                                      date_of_paymentError: false,
                                                    },
                                                    () => {
                                                      this.UpdateBargePurchasePaymentPostingHandler();
                                                    }
                                                  );
                                                }}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <IconButton>
                                                        <EventIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                  className: "pl-0",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          )} */}
                                    </td>
                                    <td
                                      nowrap="true"
                                      className="table_td text-right"
                                    >
                                      {localDateFormate(e.payment_due_date)}
                                    </td>
                                    <td
                                      nowrap="true"
                                      className="table_td text-right"
                                    >
                                      {e.amount ? toLocaleString(e.amount) : ""}
                                    </td>
                                    {/* <td nowrap="true">
                                        {this.state.invoiceDetails?.invoice_no}
                                      </td> */}
                                    <td className="table_td">
                                      {e.description}
                                    </td>
                                    <td nowrap="true">
                                      {e.created_date
                                        ? localDateFormate(e.created_date)
                                        : null}
                                    </td>
                                    {this.state.approval_status ===
                                      "Approved" &&
                                    e.approval_status === "Pending" &&
                                    this.Cookie.getCookie("loginUserId") !==
                                      e.created_by &&
                                    (this.state.features.includes("389") ||
                                      this.state.features.includes("97") >=
                                        0) ? (
                                      <td nowrap="true" className=" text-left">
                                        <button
                                          className="header_button header_button_text add_button_adjustment"
                                          style={{
                                            width: "auto",
                                            marginTop: "17px",
                                          }}
                                          onClick={() =>
                                            this.approvePaymentsHandler(e.id)
                                          }
                                        >
                                          Approve
                                        </button>
                                      </td>
                                    ) : (
                                      <td nowrap="true" className=" text-left">
                                        {e.approved_by_name
                                          ? e.approved_by_name
                                          : "-"}
                                      </td>
                                    )}

                                    <td nowrap="true" className=" text-left">
                                      {e.approved_date
                                        ? localDateFormate(e.approved_date)
                                        : "-"}
                                    </td>
                                    {/* {this.state.approval_status ===
                                      "Approved" &&
                                    e.approval_status_2 === "Pending" &&
                                    this.Cookie.getCookie("loginUserId") !==
                                      e.created_by &&
                                    this.Cookie.getCookie("loginUserId") !==
                                      e.approved_by &&
                                    (this.state.features.includes("440") ||
                                      this.state.features.includes("97") >=
                                        0) ? (
                                      <td nowrap="true" className=" text-left">
                                        <button
                                          className="header_button header_button_text add_button_adjustment"
                                          style={{
                                            width: "auto",
                                            marginTop: "17px",
                                          }}
                                          onClick={() =>
                                            this.approvePaymentsHandler(e.id)
                                          }
                                        >
                                          Approve
                                        </button>
                                      </td>
                                    ) : (
                                      <td nowrap="true" className=" text-left">
                                        {e.approved_by_2_name
                                          ? e.approved_by_2_name
                                          : "-"}
                                      </td>
                                    )}
                                    <td nowrap="true" className=" text-left">
                                      {e.approved_date_2
                                        ? localDateFormate(e.approved_date_2)
                                        : "-"}
                                    </td> */}

                                    <td nowrap="true">
                                      {(e.approval_status === "pending"
                                        ? this.state.features.includes("132") >=
                                            0 ||
                                          this.state.features.includes("389") >=
                                            0 ||
                                          this.state.features.includes("97")
                                        : e.approval_status_2 === "Pending"
                                        ? this.Cookie.getCookie(
                                            "loginUserId"
                                          ) !== e.approved_by ||
                                          this.state.features.includes("400") >=
                                            0 ||
                                          this.state.features.includes("97")
                                        : this.state.features.includes("97") >=
                                          0) && (
                                        <Tooltip
                                          title="Edit"
                                          arrow
                                          aria-label="Edit"
                                        >
                                          <div
                                            className="fa fa-pencil-square-o mr-2"
                                            style={{
                                              fontSize: 20,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            title="Edit Coal Payment Posting"
                                            data-placement="bottom"
                                            onClick={() =>
                                              this.getBargePurchasePaymentPosting(
                                                e
                                              )
                                            }
                                          ></div>
                                        </Tooltip>
                                      )}
                                      {(this.state.features.includes("133") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) &&
                                        e.approval_status === "Pending" && (
                                          <DeletePayments
                                            data={e}
                                            table={
                                              "barge_purchase_financial_coal_payments"
                                            }
                                            callbackFn={() =>
                                              this.componentDidMount()
                                            }
                                          />
                                        )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <AdvanceAdjustmentsView
                adjustments={this.state.advance_adjustments}
                vendorType="Coal"
              /> */}
              <AdvanceAdjustments
                open={this.state.openAdvAdjustments}
                adjustments={this.state.advance_adjustments}
                vendorType="Coal"
                table_name="barge_purchase_financial_adv_adjustments"
                costing_id={bargePurFinaID}
                bargeID={this.state.bargeList[0]}
                callbackFn={() => this.componentDidMount()}
                closeModal={() => this.setState({ openAdvAdjustments: false })}
              />
              {/*=====================================================
                =                 Coal Payment Postings            =
                ======================================================*/}

              <ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                feature_name="Purchase_fin_coal_costing"
                invoice_file={this.state.invoice_file}
                coa_file={this.state.invoice_file}
                bl_file={this.state.bl_file}
                dsr_file={this.state.dsr_file}
              />

              <AuditInfo info={this.state} />
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("128") ||
                this.state.features.includes("388") ||
                this.state.features.includes("97")
              : this.state.features.includes("97")) && (
              <button
                type="button"
                className="drawer_button drawer_text"
                onClick={() => this.props.onEdit(true)}
              >
                Edit
              </button>
            )}
          </div>

          <div
            className="modal fade"
            id="confirmationAddPaymentModal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <WarningIcon
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />
                    Please ensure performa payments are entered before creating
                    commercial invoice.
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    title="Barge Payment Postings"
                    data-placement="bottom"
                    data-keyboard="false"
                    data-target="#bargePurchasePaymentPostingModal"
                    data-backdrop="static"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        paymentTitle: "Add Coal",
                        payment_posting_type: "Coal",
                        date_of_payment: null,
                        amount: "",
                        description: "",
                        paymentErrorMsg: "",
                        date_of_paymentError: false,
                        amountError: false,
                        invoice_noError: false,
                        paymentMode: "Add",
                      });
                    }}
                  >
                    Proceed
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*
           *
           * Barge Payements Posting Modals
           *
           */}

          <div
            className="modal fade"
            id="bargePurchasePaymentPostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content  payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.paymentTitle} Payment Posting
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body p-0 mt-2">
                  <div className="col-lg-12">
                    <div
                      style={{
                        fontSize: 14,
                        color: "red",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {this.state.paymentErrorMsg}
                    </div>

                    <div className="row">
                      {/* <div className='col-lg-6 mb-2'>
                        <label className='form_label mb-0'>Date of Payment <span style={{ color: 'red' }}>*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.date_of_payment}
                            error={this.state.date_of_paymentError}
                            onChange={(date) => {
                              this.setState({
                                date_of_payment: date,
                                date_of_paymentError: false
                              })
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: 'pl-0'
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div> */}

                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="amount"
                          margin="dense"
                          variant="outlined"
                          placeholder="Amount"
                          required
                          value={this.state.amount}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.amountError}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                amount: e.target.value,
                                amountError: false,
                                paymentErrorMsg: "",
                              });
                              var remainingAmount = 0;
                              if (this.state.payment_posting_type === "Coal") {
                                remainingAmount =
                                  Number(this.state.paid_to_supplier) -
                                  Number(this.state.totalCoalPaymentsAmount);
                                if (this.state.paymentMode === "Update") {
                                  remainingAmount =
                                    Number(this.state.paid_to_supplier) -
                                    Number(this.state.totalCoalPaymentsAmount) +
                                    Number(this.state.existingAmount);
                                }
                                if (
                                  Number(e.target.value) >
                                  Number(remainingAmount)
                                ) {
                                  this.setState({
                                    amountError: true,
                                    submitting: false,
                                    paymentErrorMsg:
                                      "Amount Is Exceeding Payable to Supplier",
                                  });
                                  return;
                                }
                              }
                            }
                          }}
                        />
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Invoice No <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="mt-3 text-uppercase">
                          {this.state.invoiceDetails?.invoice_no}
                        </div>
                        {/* <TextField
                          name="invoice_no"
                          margin="dense"
                          variant="outlined"
                          placeholder="Invoice No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={this.state.invoice_noError}
                          value={this.state.invoice_no}
                          onChange={(e) =>
                            this.setState({
                              invoice_no: e.target.value,
                              invoice_noError: false,
                            })
                          }
                          fullWidth
                        /> */}
                      </div>
                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Payment Due Date{" "}
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.payment_due_date}
                            // error={this.state.date_of_paymentError}
                            onChange={(date) => {
                              this.setState({
                                payment_due_date: date,
                                // date_of_paymentError: false
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">Description</label>
                        <TextField
                          name="description"
                          margin="dense"
                          variant="outlined"
                          placeholder="Description"
                          multiline
                          rows={3}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row bottom_buttons_section">
                    <button
                      type="button"
                      className="btn previous_button"
                      name="submit"
                      data-dismiss="modal"
                      onClick={() => {
                        this.setState({
                          paymentTitle: "",
                          payment_posting_type: "",
                          date_of_payment: null,
                          amount: "",
                          description: "",
                          paymentErrorMsg: "",
                          date_of_paymentError: false,
                          amountError: false,
                          invoice_noError: false,
                          paymentMode: "",
                          submitting: false,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    {this.state.paymentMode === "Add" ? (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.addBargePurchasePaymentPostingHandler}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.UpdateBargePurchasePaymentPostingHandler}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
