

import React, { Component } from 'react';
import {
  InputAdornment,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Loader from '../../common/Loader';
import CookieHandler from "../../common/CookieHandler";
import config from '../../../config/config';
import api from '../../../api/api';
import { toLocaleString } from '../../common/common';
import FileUpload from '../../common/FileUpload';

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class MvDeadFreightAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      features: [],
      dead_freight_qty: this.props.deadFreightValue ? this.props.deadFreightValue : 0,
      currencyData: [],
      billing_currency: config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR",
      pph_account: "0",
      vat_percentage: 10,
      vat_amount: '',
      pph_percentage: 2,
      pph_amount: '',
      vat_applicability: "Yes",
      vat_type: "Exclusive",
      remarks: '',
      files: [],
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    if (!(features.includes("562") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    await api.getCurrencies(loginUserID, idToken).then((response) => {

      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" disabled key={-1}>
                  Select Currency
                </MenuItem>
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem value={res.Currency[i].currency} key={[i]}>
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
                isLoading: false
              });
            } else {
              alert(
                "Unexpected error occured. Please contact administrator."
              );
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              successMsg: "",
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

  }


  calculations = () => {

    let { exchange_rate = 0, vat_applicability, dead_freight_qty, price_pmt, vat_percentage, pph_account, pph_percent, vat_type } = this.state;
    let currency_exchange_rate = exchange_rate ? exchange_rate : 1;
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      currency_exchange_rate = 1 / Number(currency_exchange_rate);
    }
    var total_price = 0;
    total_price = (
      Number(dead_freight_qty ? dead_freight_qty : 0) *
      Number(price_pmt ? price_pmt : 0) *
      Number(currency_exchange_rate)
    ).toFixed(4);
    vat_percentage = vat_percentage ? vat_percentage : 10;
    if (vat_applicability === "No") {
      vat_percentage = 0;
    }
    pph_percent = pph_percent
      ? Number(pph_percent)
      : 2;

    if (pph_account === "SDAM") {
      let payable_to_vendor = Number(total_price).toFixed(2);
      let base_price = (
        Number(payable_to_vendor * 100) /
        (100 - Number(pph_percent ? pph_percent : 2))
      ).toFixed(2);
      let pph_amount = (
        (Number(base_price) * Number(pph_percent)) /
        100
      ).toFixed(2);
      let cost_to_company = (Number(base_price) + Number(pph_amount)).toFixed(
        2
      );

      this.setState({
        base_price: base_price,
        pph_amount: pph_amount,
        payable_to_vendor: payable_to_vendor,
        cost_to_company: cost_to_company,
      });
    }

    if (vat_type === "Exclusive") {
      let vat_amount = ((Number(total_price) * vat_percentage) / 100).toFixed(2);
      let price_total = (Number(total_price) + Number(vat_amount)).toFixed(2);
      let pph_amount = ((Number(total_price) * pph_percent) / 100).toFixed(
        2
      );
      let payable_to_vendor = (
        Number(price_total) - Number(pph_amount)
      ).toFixed(2);
      let cost_to_company = price_total;
      if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
        payable_to_vendor = price_total;
        pph_amount = "";
      }


      this.setState({
        base_price: total_price,
        //vat_percentage : vat_percentage,
        vat_amount: vat_amount,
        //pph_percent: pph_percent,
        pph_amount: pph_amount,
        payable_to_vendor: payable_to_vendor,
        cost_to_company: cost_to_company,
      });
    } else if (vat_type === "Inclusive") {
      let base_price = (
        (Number(total_price) * 100) /
        (100 + Number(vat_percentage ? vat_percentage : 10))
      ).toFixed(2);
      let vat_amount = ((Number(base_price) * vat_percentage) / 100).toFixed(2);
      let pph_amount = ((Number(base_price) * pph_percent) / 100).toFixed(2);
      let price_total = (Number(base_price) + Number(vat_amount)).toFixed(2);
      let payable_to_vendor = (
        Number(price_total) - Number(pph_amount)
      ).toFixed(2);
      let cost_to_company = price_total;
      if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
        payable_to_vendor = price_total;
        pph_amount = "";
      }
      this.setState({
        base_price: base_price,
        //vat_percentage : vat_percentage,
        vat_amount: vat_amount,
        price_total: price_total,
        pph_amount: pph_amount,
        payable_to_vendor: payable_to_vendor,
        cost_to_company: cost_to_company,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();

    const { dead_freight_qty, price_pmt, exchange_rate, base_price, vat_applicability, vat_type, vat_percentage, vat_amount, pph_account, pph_percentage, pph_amount, payable_to_vendor, cost_to_company, billing_currency, remarks, files } = this.state || {}

    let request = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      business_no_id: this.props.businessNoID,
      dead_freight_qty: dead_freight_qty,
      price_pmt: price_pmt,
      exchange_rate: exchange_rate,
      base_price: base_price,
      vat_applicability: vat_applicability,
      vat_type: vat_type,
      vat_percentage: vat_percentage,
      vat_amount: vat_amount,
      pph_account: pph_account,
      pph_percentage: pph_percentage,
      pph_amount: pph_amount,
      payable_to_vendor: payable_to_vendor,
      cost_to_company: cost_to_company,
      billing_currency: billing_currency,
      remarks: remarks ? remarks.toUpperCase() : null,
      invoice_no: "",
      invoice_date: "",
      ppn: "",
      ppn_date: "",
      files: files
    }
    api.add_mv_dead_freight(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.props.onAddResponse();

          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

  }

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const re = /^\d*(\.\d{0,10})?$/;
    return (
      <>
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Mother Vessel Dead Freight
              </h4>
            </div>
          </div>
          <div className="drawer-section">
            {this.state.isLoading ? <Loader /> :
              <div className="card p-3">
                <div
                  className="card-body p-0"
                  style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                  id="accordion"
                >
                  <div className="row">
                    <div className="row col-lg-12 border_dashed rounded-lg p-2 mb-3">
                      <div className="row col-lg-6 pl-0 mb-2 pr-2">
                        <div className="col-lg-12 mb-2">
                          <label className="form_label mb-0">Quantity</label>
                          <TextField
                            name="dead_freight_qty"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Quantity"
                            value={this.state.dead_freight_qty}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState({
                                  dead_freight_qty: e.target.value,
                                },
                                  () => this.calculations());
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-12 mb-2">
                          <label className="form_label mb-0">
                            Price PMT
                          </label>
                          <TextField
                            name="price_pmt"
                            margin="dense"
                            variant="outlined"
                            value={this.state.price_pmt}
                            className="rounded-left"
                            placeholder="Price"
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    price_pmt: e.target.value,
                                  },
                                  () => this.calculations()
                                );
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  // variant="outlined"
                                  className="adornment_background"
                                  position="end"
                                  style={{ marginRight: "-14px", width: "50%" }}
                                >
                                  <TextField
                                    name="billing_currency"
                                    margin="dense"
                                    // variant='outlined'
                                    fullWidth
                                    value={this.state.billing_currency}
                                    select
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        margin: 0,
                                      },
                                      disableUnderline: true,
                                    }}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          billing_currency: e.target.value,
                                        },
                                        () =>
                                          this.calculations()
                                      );
                                    }}
                                  >
                                    {this.state.currencyData}
                                  </TextField>
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>

                        {(((config.company === 'SRPL' || config.company === "PRPL" || config.company === "AVS" ) && this.state.billing_currency !== "USD") || ((config.company !== "SRPL" && config.company !== "PRPL") && this.state.billing_currency !== "IDR")) && (
                          <div className="col-lg-12 mb-2">
                            <label
                              className="form_label mb-0"
                              style={{ fontSize: "10px" }}
                            >
                              Exchange Rate
                            </label>
                            <TextField
                              name="exchange_rate"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.exchange_rate}
                              type="number"
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Exchange rate"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  if (value === null || value === "") {
                                    value = 1;
                                  }

                                  this.setState(
                                    {
                                      exchange_rate: value,
                                    },
                                    () =>
                                      this.calculations()
                                  );
                                }
                              }}
                            />
                          </div>
                        )}

                        {(config.company !== "SRPL" &&
                          config.company !== "PRPL") && (
                            <div className="col-lg-12 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH Account
                              </label>
                              <TextField
                                name="pph_account"
                                margin="dense"
                                variant="outlined"
                                value={this.state.pph_account}
                                select
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                fullWidth
                                onChange={(e) => {

                                  this.setState(
                                    {
                                      pph_account: e.target.value,
                                    },
                                    () => this.calculations()
                                  );
                                }}
                              >
                                <MenuItem value="0">Please Select</MenuItem>
                                <MenuItem value="Supplier">Supplier</MenuItem>
                                <MenuItem value="SDAM">SDAM</MenuItem>
                              </TextField>
                            </div>
                          )}
                        <div className="col-lg-12 mb-2">
                          <label className="form_label mb-0">
                            VAT Applicability
                          </label>
                          <RadioGroup
                            aria-label="vat_applicability"
                            name="vat_applicability"
                            style={{ flexDirection: "row" }}
                            onChange={(e) => {
                              this.setState(
                                {
                                  vat_applicability: e.target.value,
                                },
                                () => this.calculations()
                              );
                            }}
                          >
                            <FormControlLabel
                              className="mb-0"
                              value="Yes"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.vat_applicability === "Yes"
                                  }
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              className="mb-0"
                              value="No"
                              control={
                                <NewRadio
                                  checked={this.state.vat_applicability === "No"}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </div>

                      </div>

                      <div
                        className="col-lg-6 p-1 rounded"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-1">
                          <h6>Dead Freight</h6>
                        </div>
                        {(config.company === "SRPL" || config.company === "AVS"  ||
                          config.company === "PRPL" ||
                          this.state.pph_account === "Supplier") && (
                            <div className="row pl-2">
                              <div className="col-lg-12  p-0">
                                <label className="form_label mb-0">
                                  VAT Type
                                </label>
                                <RadioGroup
                                  aria-label="vat_type"
                                  name="vat_type"
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        vat_type: e.target.value,
                                      },
                                      () => {
                                        this.calculations();
                                      }
                                    );
                                  }}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="Inclusive"
                                    control={
                                      <NewRadio
                                        checked={this.state.vat_type === "Inclusive"}
                                      />
                                    }
                                    label="Inclusive"
                                  />
                                  <FormControlLabel
                                    value="Exclusive"
                                    control={
                                      <NewRadio
                                        checked={this.state.vat_type === "Exclusive"}
                                      />
                                    }
                                    label="Exclusive"
                                  />
                                </RadioGroup>
                              </div>
                              <div className="col-lg-4 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Base Price
                                </label>
                                <label
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.base_price)}
                                </label>
                                {/* <TextField
                                  name="base_price"
                                  margin="dense"
                                  type="number"
                                  variant="standard"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={this.state.base_price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      let vat_amount = Number(
                                        (Number(e.target.value) *
                                          Number(this.state.vat_percentage)) /
                                        100
                                      ).toFixed(2);
                                      let pph_amount = (
                                        (Number(e.target.value) *
                                          Number(this.state.pph_percentage)) /
                                        100
                                      ).toFixed(2);
                                      let payable_to_vendor = (
                                        Number(e.target.value) +
                                        Number(vat_amount) -
                                        Number(pph_amount)
                                      ).toFixed(2);
                                      let cost_to_company = (
                                        Number(e.target.value) +
                                        Number(vat_amount)
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                      ) {
                                        payable_to_vendor = cost_to_company;
                                        pph_amount = "";
                                      }
                                      this.setState({
                                        base_price: e.target.value,
                                        vat_amount: vat_amount,
                                        pph_amount: pph_amount,
                                        payable_to_vendor: payable_to_vendor,
                                        cost_to_company: cost_to_company,
                                      });
                                    }
                                  }}
                                /> */}
                              </div>
                              <div className="col-lg-4 pr-0 mb-2">
                                <label className="form_label mb-0">
                                  VAT %
                                </label>
                                <label
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.vat_percentage)}
                                </label>
                                {/* <TextField
                                  name="vat_percentage"
                                  margin="dense"
                                  type="number"
                                  variant="standard"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={this.state.vat_percentage}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          vat_percentage: e.target.value,
                                        },
                                        () => this.calculations()
                                      );
                                    }
                                  }}
                                /> */}
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <label
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.vat_amount)}
                                </label>
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="col-lg-6 mb-2 pl-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH23 %
                                    </label>
                                    <label
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(this.state.pph_percent)}
                                    </label>
                                    {/* <TextField
                                      name="pph_percent"
                                      margin="dense"
                                      type="number"
                                      variant="standard"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={this.state.pph_percentage}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              pph_percentage: e.target.value,
                                            },
                                            () => this.calculations()
                                          );
                                        }
                                      }}
                                    /> */}
                                  </div>
                                )}
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="col-lg-6 mb-2 pl-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH23 Value
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(this.state.pph_amount)}
                                    </div>
                                  </div>
                                )}
                              <div className="col-lg-6 mb-2 pl-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.payable_to_vendor)}
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.cost_to_company)}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          )}

                        {this.state.pph_account === "SDAM" && (
                          <div className="row p-2">
                            <div className="col-lg-4 mb-2 p-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Base Price
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.base_price)}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-2 pr-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                PPH23 %
                              </label>
                              <label
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.pph_percent)}
                              </label>
                              {/* <TextField
                                name="pph_percent"
                                margin="dense"
                                type="number"
                                variant="standard"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.pph_percentage}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        pph_percentage: e.target.value,
                                      },
                                      () => this.calculations()
                                    );
                                  }
                                }}
                              /> */}
                            </div>
                            <div className="col-lg-4 mb-2 pr-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                PPH23
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.pph_amount)}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2 p-0">
                              <label className="form_label mb-0">
                                Payable To Vendor
                              </label>
                              <label
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.payable_to_vendor)}
                              </label>
                              {/* <TextField
                                name="payable_to_vendor"
                                margin="dense"
                                type="number"
                                //variant='outlined'
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.payable_to_vendor}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    let base_price = (
                                      (Number(e.target.value) * 100) /
                                      (100 -
                                        Number(
                                          this.state.pph_percentage
                                            ? this.state.pph_percentage
                                            : 2
                                        ))
                                    ).toFixed(2);
                                    let pph_amount = (
                                      (Number(base_price) *
                                        Number(this.state.pph_percentage)) /
                                      100
                                    ).toFixed(2);
                                    var cost_to_company = (
                                      Number(base_price) +
                                      Number(pph_amount)
                                    ).toFixed(2);
                                    this.setState({
                                      base_price: base_price,
                                      pph_amount: pph_amount,
                                      payable_to_vendor: e.target.value,
                                      cost_to_company: cost_to_company
                                    });
                                  }
                                }}
                              /> */}
                            </div>
                            <div className="col-lg-6 mb-2 pr-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Cost to Company
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.cost_to_company)}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 p-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder="Remarks"
                          value={this.state.remarks}
                          multiline
                          rows={3}
                          onChange={(e) => {
                            this.setState({
                              remarks: e.target.value,
                            });
                          }}
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                        />
                      </div>
                    </div>

                  </div>
                  <FileUpload
                    files={this.state.files}
                    feature_name="mv_dead_freight"
                    callbackFn={(data) =>
                      this.setState({
                        files: data,
                      })
                    }
                  />
                </div>
              </div>
            }

          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>

      </>
    )
  }
}
