import React, { Component } from "react";

import SideBar from "../../common/SideBar";
import {
  MenuItem,
  TextField,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import CookieHandler from "../../common/CookieHandler";
import Header from "../../common/Header";
import AddCircle from "@material-ui/icons/AddCircle";
import api from "../../../api/api";
import config from "../../../config/config";
import FileUpload from "../../common/FileUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { dateFormateToDB, localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";
import SalesQualities from "../Qualities/SalesQualities";
import QualityView from "../Qualities/QualitiesView";
import { SPECFICIATIONS } from "../constants";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
/* <!--------------------------------     Class Component     --- ------------------------> */

var qualityDuplicate = true;

export default class AddSalesContract extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      customer: "",
      oldContractNo: "",
      buyerRefNo: "",
      dialogText: "Please fill all mandatory fields.",
      contractDate: null,
      customersDataList: [],
      qualityDuplicate: qualityDuplicate,

      salesTypeRows: [
        {
          quality: "",
          quantity_in_mt: "",
          tolerance: "",
          sales_types: [
            {
              mine: "",
              sales_type: "0",
              anchorage_name: "",
              price_pmt: "",
              sales_typeError: false,
              price_pmtError: false,
              coal_index_type: "Weekly",
              index_ids: [],
              average_coal_index_price: 0,
              prorata_gar: "0",
              prorata_coal_index_price: 0,
              bonus_coal_index_pmt: "",
              penality_coal_index_pmt: "",
              derivered_coal_index_pmt: "",
              final_coal_index_pmt: "",
              coal_index_remarks: "",
            },
          ],
          qualityError: false,
          quantity_in_mtError: false,
          toleranceError: false,
          anchorage_nameError: false,
          sales_term: "0",
          specification_standard: "0",
          astmClone: false,
          specifications: SPECFICIATIONS,
        },
      ],
      stevedorePrice: "",
      PNBP: "",
      bargingPrice: "",
      LaycanData: [{ s_no: "1", laycan: "", laycan_quantity: "" }],
      loadingRate: "",
      sales_term: "0",
      indexLinked: "No",
      analysisTypeValues: ["ASTM", "ISO"],
      supplierSurveyorName: [],
      supplierSurveyorAnalysisType: [],
      saiiSurveyorName: [],
      saiiSurveyorAnalysisType: [],
      buyerSurveyorName: [],
      buyerSurveyorAnalysisType: [],
      surveyorsData: [],
      currency: "0",
      portOfLoading: "",
      dischargeRateField: false,
      portOfDischargeRows: [{ discharge_rate: "", portOf_discharge: "" }],
      vesselType: "0",
      paymentTerm1: "",
      loadingRateforGeared: "",
      loadingRateforGearless: "",
      paymentTerm2: "",
      remarks: "",
      shareDocLink: "",
      currencyData: [],
      clickedCurrency: "",
      newCurrency: "",

      activeTabIndex: false,

      customerError: false,
      vendorTypeError: false,
      sales_termError: false,
      qualityError: false,
      quantityError: false,
      toleranceError: false,
      priceError: false,
      currencyError: false,
      PodError: false,
      vesselTypeError: false,
      laycan1Error: false,
      errorMessage: "",
      successMessage: "",
      isLoading: true,
      features: [],
      activeStep: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_Contract",
        },
      ],
      open: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("16") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      // for get_Currencies
      await api.getCurrencies(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              if (res.Currency) {
                var currencies = [
                  <MenuItem value="0" key={-1} disabled>
                    {" "}
                    Please Select
                  </MenuItem>,
                ];
                for (var i in res.Currency) {
                  currencies.push(
                    <MenuItem value={res.Currency[i].currency} key={[i]}>
                      {res.Currency[i].currency}
                    </MenuItem>
                  );
                }
                this.setState({
                  currencyData: currencies,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      // fot get_customers
      await api.getCustomers(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              if (res.customers) {
                const customerData = [];
                for (let i = 0; i < res.customers.length; i++) {
                  customerData.push({
                    name: res.customers[i].customer_name,
                    value: res.customers[i].id,
                    key: i,
                  });
                }
                this.setState({
                  customersDataList: customerData,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
          });
        }
      });
      //for get_vendors
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              if (res.vendors) {
                const surveyorsData = [];
                const vendorAsCoal = [];
                for (let i = 0; i < res.vendors.length; i++) {
                  if (res.vendors[i].vendor_type === "Surveyor") {
                    surveyorsData.push({
                      name: res.vendors[i].vendor_name,
                      value: res.vendors[i].id,
                      key: i,
                    });
                  }
                  if (res.vendors[i].vendor_type === "Coal") {
                    vendorAsCoal.push({
                      mine_name: res.vendors[i].vendor_name,
                      value: res.vendors[i].id,
                      key: i,
                    });
                  }
                }
                this.setState({
                  surveyorsData: surveyorsData,
                  vendorTypeAsCoalData: vendorAsCoal,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }

    document.title = config.documentTitle + "New Sales Contract";
  }

  handleAddCurrency = () => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var newCurrency = this.state.newCurrency;
    var clickedCurrency = this.state.clickedCurrency;
    var status = "Active";
    if (newCurrency === "") {
      alert("Please enter Currency");
      this.setState({ modalCurrencyError: true });
      return;
    }
    api
      .addCurrency(loginUserID, idToken, newCurrency, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMessage: res.message,
                errorMessage: "",
                currencyError: false,
              });
              window.$("#myModal").modal("hide");
              if (res.id) {
                api.getCurrencies(loginUserID, idToken).then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res) {
                        if (res.idtoken)
                          this.Cookie.setIdTokenCookie(res.idtoken);
                        var currencies = [
                          <MenuItem value="0" key={-1} disabled>
                            Please Select
                          </MenuItem>,
                        ];
                        for (var i in res.Currency) {
                          currencies.push(
                            <MenuItem
                              value={res.Currency[i].currency}
                              key={[i]}
                            >
                              {res.Currency[i].currency}
                            </MenuItem>
                          );
                        }
                        this.setState({
                          currencyData: currencies,
                          modalCurrencyError: false,
                        });
                        if (clickedCurrency === "Currency") {
                          this.setState({
                            currency: newCurrency.toUpperCase(),
                            newCurrency: "",
                          });
                        }
                      }
                    });
                  }
                  if (response.status === 607) {
                    window.location.href = "/logout";
                  }
                });
              }
            }
            if (res.code === "601") {
              this.setState({ errorMessage: res.message, successMessage: "" });
            }
            if (res.code === "602") {
              alert(res.message);
              this.setState({
                errorMessage: res.message,
                successMessage: "",
                modalCurrencyError: true,
              });
            }
            if (res.code === "607") {
              window.location.href = "/logout";
            }
            if (res.code === "624") {
              this.setState({
                modalCurrencyError: true,
              });
            }
          });
        }
      });
  };

  handleDecimalField = (e) => {
    if (e.target.name === "stevedorePrice") {
      const re = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "PNBP") {
      const re = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "bargingPrice") {
      const re = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  handleText = (e) => {
    if (e.target.name === "sales_term") {
      this.setState({
        [e.target.name]: e.target.value,
        sales_termError: false,
      });
    }
    if (e.target.name === "currency") {
      this.setState({
        [e.target.name]: e.target.value,
        currencyError: false,
      });
    } else if (e.target.name === "newCurrency") {
      this.setState({
        [e.target.name]: e.target.value,
        modalCurrencyError: false,
      });
    }
    if (e.target.name === "portOfLoading") {
      this.setState({
        [e.target.name]: e.target.value,
        PodError: false,
      });
    }
    if (e.target.name === "vesselType") {
      this.setState({
        [e.target.name]: e.target.value,
        vesselTypeError: false,
      });
    }
    if (e.target.name === "loadingRateforGeared") {
      this.setState({
        [e.target.name]: e.target.value,
        loadingRateError: false,
        loadingRateforGearedError: false,
        loadingRateforGearLessError: false,
      });
    }
    if (e.target.name === "loadingRateforGearless") {
      this.setState({
        [e.target.name]: e.target.value,
        loadingRateError: false,
        loadingRateforGearLessError: false,
        loadingRateforGearedError: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      modalCurrencyError: false,
    });
  };

  laycanTextHandler = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.LaycanData;
    if (name === "laycan") {
      rows[idx].laycan = e.target.value;
    }
    if (name === "laycan_quantity") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].laycan_quantity = e.target.value;
      }
    }
    this.setState({
      LaycanData: rows,
      laycan1Error: false,
    });
  };

  handleRemoveLaycanRows = (idx) => () => {
    const rows = this.state.LaycanData;
    rows.splice(idx, 1);
    this.setState({
      LaycanData: rows,
    });
  };

  handleLaycanRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.LaycanData + 1),
      laycan: "",
      laycan_quantity: "",
    };
    this.setState({
      LaycanData: [...this.state.LaycanData, items],
    });
  };

  MultiSelectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  AddportOfDischargeRowsHandler = () => {
    const item = { discharge_rate: "", portOf_discharge: "" };
    this.setState({
      portOfDischargeRows: [...this.state.portOfDischargeRows, item],
    });
  };

  removePortOfDischargeRows = (idx) => () => {
    const rows = this.state.portOfDischargeRows;
    rows.splice(idx, 1);

    this.setState({
      portOfDischargeRows: rows,
    });
  };

  portOfDischargeRowsTextHandler = (idx) => (e) => {
    const rows = this.state.portOfDischargeRows;
    if (e.target.name === "dischargeRate") {
      rows[idx].discharge_rate = e.target.value;
    }
    if (e.target.name === "portOfDischarge") {
      rows[idx].portOf_discharge = e.target.value;
    }

    this.setState({
      portOfDischargeRows: rows,
    });
  };

  contractDateHandler = (date) => {
    this.setState({
      contractDate: date,
      contractDateError: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      customer,
      oldContractNo,
      buyerRefNo,
      contractDate,
      salesTypeRows,
      stevedorePrice,
      PNBP,
      currency,
      portOfLoading,
      vesselType,
      payment_adjustment,
      paymentTerm1,
      paymentTerm2,
      remarks,
      shareDocLink,
      loadingRateforGeared,
      loadingRateforGearless,
      indexLinked,
      files,
    } = this.state;

    var supplierSurveyorNames = "";
    for (let i = 0; i < this.state.supplierSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.supplierSurveyorName[i] ===
          this.state.surveyorsData[j].name
        ) {
          supplierSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var supplierSurveyorName = supplierSurveyorNames.slice(0, -1);
    var supplierSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.supplierSurveyorAnalysisType.length; i++) {
      supplierSurveyorAnalysisTypes +=
        this.state.supplierSurveyorAnalysisType[i] + ",";
    }
    var supplierSurveyorAnalysisType = supplierSurveyorAnalysisTypes.slice(
      0,
      -1
    );
    var saiiSurveyorNames = "";
    for (let i = 0; i < this.state.saiiSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.saiiSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          saiiSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var saiiSurveyorName = saiiSurveyorNames.slice(0, -1);
    var saiiSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.saiiSurveyorAnalysisType.length; i++) {
      saiiSurveyorAnalysisTypes += this.state.saiiSurveyorAnalysisType[i] + ",";
    }
    var saiiSurveyorAnalysisType = saiiSurveyorAnalysisTypes.slice(0, -1);
    var jointSurveyorNames = "";
    for (let i = 0; i < this.state.buyerSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.buyerSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          jointSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var buyerSurveyorName = jointSurveyorNames.slice(0, -1);
    var jointSurveyorAnysTypes = "";
    for (let i = 0; i < this.state.buyerSurveyorAnalysisType.length; i++) {
      jointSurveyorAnysTypes += this.state.buyerSurveyorAnalysisType[i] + ",";
    }
    var buyerSurveyorAnalysisType = jointSurveyorAnysTypes.slice(0, -1);
    //TODO:<!-------------------- Accessing the laycanData --------------------!>
    var laycan1 = "",
      laycan2 = "",
      laycan3 = "",
      laycan4 = "",
      laycan5 = "";
    var laycanQuantity1 = "",
      laycanQuantity2 = "",
      laycanQuantity3 = "",
      laycanQuantity4 = "",
      laycanQuantity5 = "";
    for (var k in this.state.LaycanData) {
      if (k === "0") {
        laycan1 = this.state.LaycanData[k].laycan;
        laycanQuantity1 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "1") {
        laycan2 = this.state.LaycanData[k].laycan;
        laycanQuantity2 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "2") {
        laycan3 = this.state.LaycanData[k].laycan;
        laycanQuantity3 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "3") {
        laycan4 = this.state.LaycanData[k].laycan;
        laycanQuantity4 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "4") {
        laycan5 = this.state.LaycanData[k].laycan;
        laycanQuantity5 = this.state.LaycanData[k].laycan_quantity;
      }
    }

    //TODO::<!----------------- Accessing the portOfDischargeRows Data ------!>

    var dischargeRate1 = "",
      portOfDischarge1 = "",
      dischargeRate2 = "",
      portOfDischarge2 = "",
      dischargeRate3 = "",
      portOfDischarge3 = "";
    for (var j in this.state.portOfDischargeRows) {
      if (j === "0") {
        dischargeRate1 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge1 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
      if (j === "1") {
        dischargeRate2 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge2 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
      if (j === "2") {
        dischargeRate3 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge3 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
    }

    var srows = this.state.salesTypeRows;
    for (var i in this.state.salesTypeRows) {
      // TODO: <!------------------- if sales Term as Specs sending empty values ---------- !>
      if (
        srows[i].sales_term === "Specs" ||
        srows[i].sales_term === "Non Specs"
      ) {
        if (srows[i].specifications.quality_calorific_value_basis === "0") {
          srows[i].specifications.quality_calorific_value_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value2_basis === "0") {
          srows[i].specifications.quality_calorific_value2_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value3_basis === "0") {
          srows[i].specifications.quality_calorific_value3_basis = "";
        }
        if (srows[i].specifications.quality_total_moisture_basis === "0") {
          srows[i].specifications.quality_total_moisture_basis = "";
        }
        if (srows[i].specifications.quality_inherent_moisture_basis === "0") {
          srows[i].specifications.quality_inherent_moisture_basis = "";
        }
        if (srows[i].specifications.quality_ash_basis === "0") {
          srows[i].specifications.quality_ash_basis = "";
        }
        if (srows[i].specifications.quality_volatile_matter_basis === "0") {
          srows[i].specifications.quality_volatile_matter_basis = "";
        }
        if (srows[i].specifications.quality_fixed_carbon_basis === "0") {
          srows[i].specifications.quality_fixed_carbon_basis = "";
        }
        if (srows[i].specifications.quality_fuel_ratio_basis === "0") {
          srows[i].specifications.quality_fuel_ratio_basis = "";
        }
        if (srows[i].specifications.quality_total_sulphur_basis === "0") {
          srows[i].specifications.quality_total_sulphur_basis = "";
        }
        if (srows[i].specifications.ultimate_carbon_basis === "0") {
          srows[i].specifications.ultimate_carbon_basis = "";
        }
        if (srows[i].specifications.ultimate_hydrogen_basis === "0") {
          srows[i].specifications.ultimate_hydrogen_basis = "";
        }
        if (srows[i].specifications.ultimate_nitrogen_basis === "0") {
          srows[i].specifications.ultimate_nitrogen_basis = "";
        }
        if (srows[i].specifications.ash_sio2_basis === "0") {
          srows[i].specifications.ash_sio2_basis = "";
        }
        if (srows[i].specifications.ash_ai203_basis === "0") {
          srows[i].specifications.ash_ai203_basis = "";
        }
        if (srows[i].specifications.ash_sio2_basis === "0") {
          srows[i].specifications.ash_sio2_basis = "";
        }
        if (srows[i].specifications.ash_fe203_basis === "0") {
          srows[i].specifications.ash_fe203_basis = "";
        }
        if (srows[i].specifications.ash_cao_basis === "0") {
          srows[i].specifications.ash_cao_basis = "";
        }
        if (srows[i].specifications.ash_mgo_basis === "0") {
          srows[i].specifications.ash_mgo_basis = "";
        }
        if (srows[i].specifications.ash_na2o_basis === "0") {
          srows[i].specifications.ash_na2o_basis = "";
        }
        if (srows[i].specifications.ash_k2o_basis === "0") {
          srows[i].specifications.ash_k2o_basis = "";
        }
        if (srows[i].specifications.ash_tio2_basis === "0") {
          srows[i].specifications.ash_tio2_basis = "";
        }
        if (srows[i].specifications.ash_mn3o4_basis === "0") {
          srows[i].specifications.ash_mn3o4_basis = "";
        }
        if (srows[i].specifications.ash_so3_basis === "0") {
          srows[i].specifications.ash_so3_basis = "";
        }
        if (srows[i].specifications.ash_p2o5_basis === "0") {
          srows[i].specifications.ash_p2o5_basis = "";
        }
        if (srows[i].specifications.trace_arsenic_basis === "0") {
          srows[i].specifications.trace_arsenic_basis = "";
        }
        if (srows[i].specifications.trace_mercury_basis === "0") {
          srows[i].specifications.trace_mercury_basis = "";
        }
        if (srows[i].specifications.trace_selenium_basis === "0") {
          srows[i].specifications.trace_selenium_basis = "";
        }
        if (srows[i].specifications.trace_boron_basis === "0") {
          srows[i].specifications.trace_boron_basis = "";
        }
        if (srows[i].specifications.minor_ba_basis === "0") {
          srows[i].specifications.minor_ba_basis = "";
        }
        if (srows[i].specifications.minor_phosphorous_basis === "0") {
          srows[i].specifications.minor_phosphorous_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value_basis_iso === "0") {
          srows[i].specifications.quality_calorific_value_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_calorific_value2_basis_iso === "0"
        ) {
          srows[i].specifications.quality_calorific_value2_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_calorific_value3_basis_iso === "0"
        ) {
          srows[i].specifications.quality_calorific_value3_basis_iso = "";
        }
        if (srows[i].specifications.quality_total_moisture_basis_iso === "0") {
          srows[i].specifications.quality_total_moisture_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_inherent_moisture_basis_iso === "0"
        ) {
          srows[i].specifications.quality_inherent_moisture_basis_iso = "";
        }
        if (srows[i].specifications.quality_ash_basis_iso === "0") {
          srows[i].specifications.quality_ash_basis_iso = "";
        }
        if (srows[i].specifications.quality_volatile_matter_basis_iso === "0") {
          srows[i].specifications.quality_volatile_matter_basis_iso = "";
        }
        if (srows[i].specifications.quality_fixed_carbon_basis_iso === "0") {
          srows[i].specifications.quality_fixed_carbon_basis_iso = "";
        }
        if (srows[i].specifications.quality_fuel_ratio_basis_iso === "0") {
          srows[i].specifications.quality_fuel_ratio_basis_iso = "";
        }
        if (srows[i].specifications.quality_total_sulphur_basis_iso === "0") {
          srows[i].specifications.quality_total_sulphur_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_carbon_basis_iso === "0") {
          srows[i].specifications.ultimate_carbon_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_hydrogen_basis_iso === "0") {
          srows[i].specifications.ultimate_hydrogen_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_nitrogen_basis_iso === "0") {
          srows[i].specifications.ultimate_nitrogen_basis_iso = "";
        }
        if (srows[i].specifications.ash_sio2_basis_iso === "0") {
          srows[i].specifications.ash_sio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_ai203_basis_iso === "0") {
          srows[i].specifications.ash_ai203_basis_iso = "";
        }
        if (srows[i].specifications.ash_sio2_basis_iso === "0") {
          srows[i].specifications.ash_sio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_fe203_basis_iso === "0") {
          srows[i].specifications.ash_fe203_basis_iso = "";
        }
        if (srows[i].specifications.ash_cao_basis_iso === "0") {
          srows[i].specifications.ash_cao_basis_iso = "";
        }
        if (srows[i].specifications.ash_mgo_basis_iso === "0") {
          srows[i].specifications.ash_mgo_basis_iso = "";
        }
        if (srows[i].specifications.ash_na2o_basis_iso === "0") {
          srows[i].specifications.ash_na2o_basis_iso = "";
        }
        if (srows[i].specifications.ash_k2o_basis_iso === "0") {
          srows[i].specifications.ash_k2o_basis_iso = "";
        }
        if (srows[i].specifications.ash_tio2_basis_iso === "0") {
          srows[i].specifications.ash_tio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_mn3o4_basis_iso === "0") {
          srows[i].specifications.ash_mn3o4_basis_iso = "";
        }
        if (srows[i].specifications.ash_so3_basis_iso === "0") {
          srows[i].specifications.ash_so3_basis_iso = "";
        }
        if (srows[i].specifications.ash_p2o5_basis_iso === "0") {
          srows[i].specifications.ash_p2o5_basis_iso = "";
        }
        if (srows[i].specifications.trace_arsenic_basis_iso === "0") {
          srows[i].specifications.trace_arsenic_basis_iso = "";
        }
        if (srows[i].specifications.trace_mercury_basis_iso === "0") {
          srows[i].specifications.trace_mercury_basis_iso = "";
        }
        if (srows[i].specifications.trace_selenium_basis_iso === "0") {
          srows[i].specifications.trace_selenium_basis_iso = "";
        }
        if (srows[i].specifications.trace_boron_basis_iso === "0") {
          srows[i].specifications.trace_boron_basis_iso = "";
        }
        if (srows[i].specifications.minor_ba_basis_iso === "0") {
          srows[i].specifications.minor_ba_basis_iso = "";
        }
        if (srows[i].specifications.minor_phosphorous_basis_iso === "0") {
          srows[i].specifications.minor_phosphorous_basis_iso = "";
        }
      }
    }
    this.setState({ salesTypeRows: srows });
    const content_files = files.filter((e) => e.file_content !== "");
    // }
    var status = "Active";
    if (customer !== "" && contractDate !== null) {
      var contractType = "Original";
      var baseContractID = "";
      var baseContractNo = "";
      var addendumContractID = "";
      var addendumContractNo = "";
      //Add sales contract service
      api
        .addSalesContract(
          loginUserID,
          idToken,
          customer,
          oldContractNo,
          buyerRefNo,
          contractDate,
          salesTypeRows,
          stevedorePrice,
          PNBP,
          currency,
          laycan1,
          laycan2,
          laycan3,
          laycan4,
          laycan5,
          laycanQuantity1,
          laycanQuantity2,
          laycanQuantity3,
          laycanQuantity4,
          laycanQuantity5,
          supplierSurveyorName,
          supplierSurveyorAnalysisType,
          saiiSurveyorName,
          saiiSurveyorAnalysisType,
          buyerSurveyorName,
          buyerSurveyorAnalysisType,
          portOfLoading,
          dischargeRate1,
          portOfDischarge1,
          dischargeRate2,
          portOfDischarge2,
          dischargeRate3,
          portOfDischarge3,
          loadingRateforGeared,
          loadingRateforGearless,
          payment_adjustment,
          paymentTerm1,
          paymentTerm2,
          remarks,
          vesselType,
          shareDocLink,
          contractType,
          baseContractID,
          baseContractNo,
          addendumContractID,
          addendumContractNo,
          status,
          indexLinked,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMessage: res.message,
                    errorMessage: "",
                  });
                  if (res.sale_contract_id) {
                    window.location.href =
                      "/view-sales-contract/" + btoa(res.sale_contract_id);
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                }
                if (res.code === "601") {
                  this.setState({
                    isLoading: false,
                    successMessage: "",
                    errorMessage: res.message,
                  });
                }
                if (res.code === "624") {
                  this.setState({ isLoading: false });
                  for (var i = 0; i < res.columns.length; i++) {
                    if (res.columns[i] === "customer_id") {
                      this.setState({ customerError: true });
                    }
                    if (res.columns[i] === "currency") {
                      this.setState({ currencyError: true });
                    }
                  }
                  this.setState({
                    errorMessage: res.message,
                    successMessage: "",
                  });
                  alert(res.message);
                  return;
                }
              } else {
                this.setState({ isLoading: false });
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      this.setState({ isLoading: false });
      alert("Please fill all mandatory fields.");
    }
  };

  getVendorvalue = (id) => {
    var vendorname = this.state.customersDataList.filter(
      (e, index) => e.value === this.state.customer
    );
    return vendorname[0].name;
  };

  getMinevalue = (id) => {
    var mineName = this.state.vendorTypeAsCoalData.filter(
      (e, index) => e.value === id
    );
    var mine_name = "";
    if (mineName.length > 0) {
      mine_name = mineName[0].name;
    }
    return mine_name;
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      var { customer, contractDate, currency } = this.state;
      if (customer === "") {
        this.setState({ customerError: true });
      }
      if (contractDate === null) {
        this.setState({ contractDateError: true });
      }
      if (currency === "0") {
        this.setState({ currencyError: true });
      }
      var laycan1 = "";
      for (var i in this.state.LaycanData) {
        if (i === "0") {
          laycan1 = this.state.LaycanData[i].laycan;
        }
      }
      if (laycan1 === "") {
        this.setState({ isLoading: false, laycan1Error: true });
      }

      if (
        customer === "" ||
        contractDate == null ||
        currency === "0" ||
        laycan1 === ""
      ) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
    }

    if (this.state.activeStep === 1) {
      var { salesTypeRows } = this.state;
      var rows = salesTypeRows;
      var purchase_typesAvailable = 0;
      for (var j in salesTypeRows) {
        if (salesTypeRows[j].sales_term === "Specs") {
          if (salesTypeRows[j].quality === "") {
            rows[j].qualityError = true;
            purchase_typesAvailable = purchase_typesAvailable + 1;
          } else {
            var quality = salesTypeRows[j].quality;
            salesTypeRows[j].quality = quality.toUpperCase();
          }
        }
        if (salesTypeRows[j].quantity_in_mt === "") {
          rows[j].quantity_in_mtError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
        if (salesTypeRows[j].tolerance === "") {
          rows[j].toleranceError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }

        for (var k in salesTypeRows[j].sales_types) {
          if (salesTypeRows[j].sales_types[k].sales_type === "0") {
            rows[j].sales_types[k].sales_typeError = true;
            this.setState({ isLoading: false, salesTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
          if (salesTypeRows[j].sales_types[k].price_pmt === "") {
            rows[j].sales_types[k].price_pmtError = true;
            this.setState({ isLoading: false, salesTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
        }
      }
      this.setState({ salesTypeRows: rows });
      if (purchase_typesAvailable > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill all mandatory fields.",
        });
        return;
      }

      let purchaseQualityRows = this.state.salesTypeRows;
      for (var p = 0; p < purchaseQualityRows.length; p++) {
        if (purchaseQualityRows[p].sales_term === "0") {
          purchaseQualityRows[p].purchasingTermError = true;
          this.setState({
            isLoading: false,
            salesTypeRows: purchaseQualityRows,
            snackBarErrorOpen: true,
            errorMsg: "Please select purchasing term(Specs or Non Specs).",
          });
          return;
        } else {
          if (purchaseQualityRows[p].sales_term === "Specs") {
            if (purchaseQualityRows[p].specification_standard === "0") {
              purchaseQualityRows[p].specification_standardError = true;
              this.setState({
                isLoading: false,
                salesTypeRows: purchaseQualityRows,
                snackBarErrorOpen: true,
                errorMsg: "Please select specification standard(ASTM or ISO).",
              });
              return;
            }
          } else {
            this.setState({
              dialogText: "Please fill all mandatory fields.",
            });
          }
        }
      }
    }

    if (this.state.activeStep === 2) {
      var { vesselType, loadingRateforGeared, loadingRateforGearless } =
        this.state;

      if (vesselType === "0") {
        this.setState({ isLoading: false, vesselTypeError: true, open: true });
        return;
      }
      if (vesselType === "Geared & Grabbed") {
        if (loadingRateforGeared === "") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: "Please fill mandatory fields.",
            loadingRateforGearedError: true,
            loadingRateError: false,
          });
          return;
        }
      }
      if (vesselType === "Gearless") {
        if (loadingRateforGearless === "") {
          this.setState({
            isLoading: false,
            loadingRateforGearLessError: true,
            snackBarErrorOpen: true,
            errorMsg: "Please fill mandatory fields.",
            loadingRateError: false,
          });
          return;
        }
      }
    }

    if (this.state.activeStep === 3) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                New Coal Sales Contract
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="error-class">{this.state.errorMessage}</p>
                  </div>
                </div>
              )}
              {this.state.successMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="success-class">{this.state.successMessage}</p>
                  </div>
                </div>
              )}
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Contract Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Add Quality
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Surveyor Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 3
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Payment Terms
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 4
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 4
                          ? config.themeColor
                          : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 4
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "100%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create Sales Contract
                      </h5>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Customer <span style={{ color: "red" }}>*</span>
                          </label>
                          <Autocomplete
                            options={this.state.customersDataList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              value != null
                                ? this.setState({
                                    customer: value.value,
                                    customerError: false,
                                  })
                                : this.setState({ customer: "" });
                            }}
                            name="customer"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Customer *"
                                // label="Search Customer"
                                variant="outlined"
                                error={this.state.customerError}
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Buyer Reference No
                          </label>
                          <TextField
                            name="buyerRefNo"
                            margin="dense"
                            variant="outlined"
                            placeholder="Buyer Reference No"
                            value={this.state.buyerRefNo}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={this.handleText}
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Contract Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={this.state.contractDate}
                              onChange={(date) =>
                                this.contractDateHandler(date)
                              }
                              error={this.state.contractDateError}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Currency <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="currency"
                            variant="outlined"
                            margin="dense"
                            select
                            value={this.state.currency}
                            onChange={this.handleText}
                            style={{ width: "87%" }}
                            error={this.state.currencyError}
                          >
                            {this.state.currencyData}
                          </TextField>
                          <AddCircle
                            onClick={() =>
                              this.setState({ clickedCurrency: "Currency" })
                            }
                            style={{
                              marginTop: 15,
                              marginLeft: 5,
                              color: config.themeColor,
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.LaycanData.map((e, idx) => (
                      <div className="row mb-2" key={idx}>
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">
                            Laycan{" "}
                            {idx === 0 && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <TextField
                            name="laycan"
                            margin="dense"
                            // label='Laycan *'
                            variant="outlined"
                            placeholder="Laycan *"
                            error={this.state.laycan1Error}
                            value={this.state.LaycanData[idx].laycan}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={this.laycanTextHandler(idx)}
                            fullWidth
                          />
                        </div>
                        <div
                          className={
                            this.state.LaycanData.length > 0
                              ? "col-lg-5 pr-0"
                              : "col-lg-6 pr-0"
                          }
                        >
                          <label
                            className="form_label mb-0"
                            style={{ visibility: "hidden" }}
                          >
                            None
                          </label>
                          <TextField
                            name="laycan_quantity"
                            margin="dense"
                            label="Quantity in MT"
                            variant="outlined"
                            placeholder="Quantity in MT"
                            value={
                              this.state.LaycanData[idx].laycan_quantity
                                ? this.state.LaycanData[idx].laycan_quantity
                                : ""
                            }
                            onChange={this.laycanTextHandler(idx)}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            fullWidth
                          />
                        </div>
                        {this.state.LaycanData.length > 1 && (
                          <center className="col-lg-1 d-flex justify-content-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={this.handleRemoveLaycanRows(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </center>
                        )}
                      </div>
                    ))}

                    <div className="row mb-3 mt-1">
                      <div className="col-lg-6 p-0">
                        {this.state.LaycanData.length < 5 && (
                          <button
                            type="button"
                            onClick={this.handleLaycanRows}
                            className="header_button header_button_text addrow_button_adjustment w-auto"
                            style={{ border: "none", color: config.themeColor }}
                          >
                            ADD ROW
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add Quality
                      </h5>
                    </div>

                    <SalesQualities
                      quality={this.state.salesTypeRows}
                      mines={this.state.vendorTypeAsCoalData}
                      callbackFn={(qualities) => {
                        this.setState({
                          salesTypeRows: qualities,
                        });
                      }}
                    />

                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <label className="form_label mb-0">
                          Quality Adjustment
                        </label>
                        <TextField
                          name="payment_adjustment"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Quality Adjustment"
                          value={this.state.payment_adjustment}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Surveyor
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="supplierSurveyorName"
                            value={this.state.supplierSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={this.state.supplierSurveyorName.includes(
                                    e.name
                                  )}
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo1-mutiple-CheckBox"
                            multiple
                            name="supplierSurveyorAnalysisType"
                            fullWidth
                            value={this.state.supplierSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                            //MenuProps={MenuProps}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={this.state.supplierSurveyorAnalysisType.includes(
                                    e
                                  )}
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo2-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="saiiSurveyorName"
                            value={this.state.saiiSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={this.state.saiiSurveyorName.includes(
                                    e.name
                                  )}
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            multiple
                            name="saiiSurveyorAnalysisType"
                            fullWidth
                            value={this.state.saiiSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={this.state.saiiSurveyorAnalysisType.includes(
                                    e
                                  )}
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo4-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="buyerSurveyorName"
                            value={this.state.buyerSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={this.state.buyerSurveyorName.includes(
                                    e.name
                                  )}
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo5-mutiple-CheckBox"
                            multiple
                            name="buyerSurveyorAnalysisType"
                            fullWidth
                            value={this.state.buyerSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={this.state.buyerSurveyorAnalysisType.includes(
                                    e
                                  )}
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Loading
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Vessel Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vesselType"
                          variant="outlined"
                          margin="dense"
                          value={this.state.vesselType}
                          onChange={this.handleText}
                          select
                          fullWidth
                          error={this.state.vesselTypeError}
                        >
                          <MenuItem value="0" disabled>
                            Please Select
                          </MenuItem>
                          <MenuItem value="Geared & Grabbed">
                            Geared & Grabbed
                          </MenuItem>
                          <MenuItem value="Gearless">Gearless</MenuItem>
                          <MenuItem value="NA">NA</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Loading Rate for Geared{" "}
                          {this.state.vesselType === "Geared & Grabbed" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <TextField
                          name="loadingRateforGeared"
                          margin="dense"
                          variant="outlined"
                          placeholder="Loading Rate for Geared *"
                          value={this.state.loadingRateforGeared}
                          onChange={this.handleText}
                          error={this.state.loadingRateforGearedError}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Loading Rate for Gearless{" "}
                          {this.state.vesselType === "Gearless" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <TextField
                          name="loadingRateforGearless"
                          margin="dense"
                          variant="outlined"
                          placeholder="Loading Rate for Gearless *"
                          value={this.state.loadingRateforGearless}
                          error={this.state.loadingRateforGearLessError}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>

                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Port of Loading{" "}
                        </label>
                        <TextField
                          name="portOfLoading"
                          variant="outlined"
                          margin="dense"
                          placeholder="Port of Loading"
                          value={this.state.portOfLoading}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>

                    {this.state.dischargeRateField && (
                      <span className="row">
                        <label className="form_label mb-0">
                          Port of Discharge
                        </label>
                        <div className="col-lg-12 p-0">
                          {this.state.portOfDischargeRows.map((e, idx) => (
                            <div className="row" key={idx}>
                              <div className="col-lg-6 p-0">
                                <TextField
                                  name="dischargeRate"
                                  margin="dense"
                                  fullWidth
                                  placeholder="Discharge Rate"
                                  variant="outlined"
                                  value={
                                    this.state.portOfDischargeRows[idx]
                                      .discharge_rate
                                  }
                                  onChange={this.portOfDischargeRowsTextHandler(
                                    idx
                                  )}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>
                              <div className="col-lg-6 pr-0">
                                <TextField
                                  name="portOfDischarge"
                                  margin="dense"
                                  fullWidth
                                  placeholder="Port of Discharge"
                                  variant="outlined"
                                  onChange={this.portOfDischargeRowsTextHandler(
                                    idx
                                  )}
                                  value={
                                    this.state.portOfDischargeRows[idx]
                                      .portOf_discharge
                                  }
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>
                              {idx === 0 ? (
                                <div
                                  style={{ visibility: "hidden", padding: 10 }}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.removePortOfDischargeRows(
                                      idx
                                    )}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              ) : (
                                <div style={{ padding: 10 }}>
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.removePortOfDischargeRows(
                                      idx
                                    )}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                            </div>
                          ))}
                          <div className="row mb-3 mt-1">
                            <div className="col-lg-6 p-0">
                              {this.state.portOfDischargeRows.length < 3 && (
                                <button
                                  type="button"
                                  onClick={this.AddportOfDischargeRowsHandler}
                                  className="header_button header_button_text addrow_button_adjustment"
                                  style={{
                                    border: "none",
                                    color: config.themeColor,
                                  }}
                                >
                                  ADD ROW
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </span>
                    )}
                  </div>
                )}
                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Payment
                      </h5>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Payment Terms 1
                        </label>
                        <TextField
                          name="paymentTerm1"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Payment Terms 1"
                          value={this.state.paymentTerm1}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Payment Terms 2
                        </label>
                        <TextField
                          name="paymentTerm2"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Payment Terms 2"
                          value={this.state.paymentTerm2}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Remarks"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.remarks}
                          onChange={this.handleText}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Share Point Document Link
                        </label>
                        <TextField
                          name="shareDocLink"
                          margin="dense"
                          variant="outlined"
                          placeholder="Share Point Document Link"
                          value={this.state.shareDocLink}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("327") ||
                      this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Sales_Contract"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                  </div>
                )}

                {this.state.activeStep === 4 && (
                  <div className="section_block">
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Contract Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Customer
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.customer
                                ? this.getVendorvalue(this.state.customer)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Buyer Reference No
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.buyerRefNo
                                ? this.state.buyerRefNo
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Contract Date
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {localDateFormate(
                                this.state.contractDate
                                  ? dateFormateToDB(this.state.contractDate)
                                  : null
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Currency
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.currency ? this.state.currency : "-"}{" "}
                            </div>
                          </div>

                          {this.state.LaycanData.map((e, idx) => (
                            <div className="col-lg-4 mb-3" key={idx}>
                              <label className="contract_display_header_label">
                                Laycan {idx + 1}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {e.laycan ? e.laycan : "-"} ,{" "}
                                {e.laycan_quantity
                                  ? this.toLocaleString(e.laycan_quantity)
                                  : " -"}{" "}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <QualityView
                      contractType={"Sales"}
                      qualities={this.state.salesTypeRows}
                      callbackFn={() => {}}
                    />

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Surveyor Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Surveyor
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.supplierSurveyorName.toString()
                                ? this.state.supplierSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.supplierSurveyorAnalysisType.toString()
                                ? this.state.supplierSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>

                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.saiiSurveyorName.toString()
                                ? this.state.saiiSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.saiiSurveyorAnalysisType.toString()
                                ? this.state.saiiSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.buyerSurveyorName.toString()
                                ? this.state.buyerSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.buyerSurveyorAnalysisType.toString()
                                ? this.state.buyerSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Loading
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Vessel Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.vesselType
                                ? this.state.vesselType
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Loading Rate for Geared
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.loadingRateforGeared
                                ? this.state.loadingRateforGeared
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Loading Rate for Gearless
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.loadingRateforGearless
                                ? this.state.loadingRateforGearless
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lgx`-6">
                            <label className="contract_display_header_label">
                              Port of Loading
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.pod ? this.state.pod : "-"}
                            </label>
                          </div>
                          {this.state.dischargeRateField && (
                            <div>
                              <label
                                className="col-lg-12 contract_display_header_value pl-0"
                                style={{ fontWeight: "bold" }}
                              >
                                Port of Discharge
                              </label>
                              {this.state.portOfDischargeRows.map((e, idx) => (
                                <div className="col-lg-12 mt-2" key={idx}>
                                  <label className="contract_display_header_label">
                                    Port of Discharge {idx + 1}
                                  </label>
                                  <label
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {
                                      this.state.portOfDischargeRows[idx]
                                        .discharge_rate
                                    }
                                    {this.state.portOfDischargeRows[idx]
                                      .portOf_discharge
                                      ? " - " +
                                        this.state.portOfDischargeRows[idx]
                                          .portOf_discharge
                                      : "-"}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Payment
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Quality Adjustment
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_adjustment
                                ? this.state.payment_adjustment
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Payment Terms 1
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.paymentTerm1
                                ? this.state.paymentTerm1
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Payment Terms 2
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.paymentTerm2
                                ? this.state.paymentTerm2
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Remarks
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.remarks ? this.state.remarks : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Share Point Document Link
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.shareDocLink
                                ? this.state.shareDocLink
                                : "-"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () => (window.location.href = "/sales-contract-list")
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "BACK" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 4
                      ? this.handleSubmit
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 4 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent>
            <DialogContentText>{this.state.dialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  Add Currency
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <TextField
                  name="newCurrency"
                  placeholder="Add New Currency *"
                  margin="dense"
                  variant="outlined"
                  onChange={this.handleText}
                  value={this.state.newCurrency}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={this.state.modalCurrencyError}
                />
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.handleAddCurrency}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
