import React, { Component } from 'react';
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import api from "../../../api/api";
import config from "../../../config/config";

import CookieHandler from "../../common/CookieHandler";
import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
import QualityView from "../Qualities/QualitiesView";
import DocumentsUpload from '../../DocumentsUpload';
export default class SalesAddendumView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      features: [],
      qualities: [],
      portOfDischargeRows: [],
      snapshot_data: {},
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("17") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const status = "Active";
    const salesContractId = decodeURIComponent(
      window.atob(this.props.match.params.salesContractID)
    );
    this.setState({ salesContractID: salesContractId });
    await api
      .getSalesContract(loginUserID, salesContractId, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              if (res.sales_contract) {
                this.setState((prevState) => ({
                  ...prevState,
                  ...res.sales_contract,
                  // isLoading: false,
                }),
                  () => {
                    this.callSnapshot(this.state.snapshot_contract_id);
                  });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "629") {
              this.setState({
                successMsg: "",
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
  }

  callSnapshot = (snapID) => {
    if (snapID) {
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");
      api.getSalesContract(loginUserID, snapID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              var portOfDischargeData = [];
              if (
                res.sales_contract.discharge_rate_1 ||
                res.sales_contract.port_of_discharge_1
              ) {
                portOfDischargeData.push({
                  discharge_rate: res.sales_contract.discharge_rate_1
                    ? res.sales_contract.discharge_rate_1
                    : "",
                  portOf_discharge: res.sales_contract.port_of_discharge_1
                    ? res.sales_contract.port_of_discharge_1
                    : "",
                });
              }
              if (
                res.sales_contract.discharge_rate_2 ||
                res.sales_contract.port_of_discharge_2
              ) {
                portOfDischargeData.push({
                  discharge_rate: res.sales_contract.discharge_rate_2
                    ? res.sales_contract.discharge_rate_2
                    : "",
                  portOf_discharge: res.sales_contract.port_of_discharge_2
                    ? res.sales_contract.port_of_discharge_2
                    : "",
                });
              }
              if (
                res.sales_contract.discharge_rate_3 ||
                res.sales_contract.port_of_discharge_3
              ) {
                portOfDischargeData.push({
                  discharge_rate: res.sales_contract.discharge_rate_3
                    ? res.sales_contract.discharge_rate_3
                    : "",
                  portOf_discharge: res.sales_contract.port_of_discharge_3
                    ? res.sales_contract.port_of_discharge_3
                    : "",
                });
              }
              for (var k in res.sales_contract.qualities) {
                for (
                  let n = 0;
                  n < res.sales_contract.qualities[k].sales_types.length;
                  n++
                ) {
                  if (
                    res.sales_contract.qualities[k].sales_types[n]
                      .sales_type === "CFR DP" ||
                    res.sales_contract.qualities[k].sales_types[n]
                      .sales_type === "CIF DP"
                  ) {
                    this.setState({ dischargeRateField: true });
                  }
                }
              }
              this.setState({
                portOfDischargeRows: portOfDischargeData,
                snapshot_data: { ...res.sales_contract },
                isLoading: false
              },
                () => {
                  this.QualitiesDiff();
                })

            }
          })
        }
      })
    }
    else { this.setState({ isLoading: false }) }
  }

  QualitiesDiff = () => {
    const snap_qualities = this.state?.snapshot_data?.qualities;
    const addendum_qualites = this.state.qualities;
    const new_qualities = []

    // console.log("console", snap_qualities.filter(({ quality_seq: id1 }) => !addendum_qualites.some(({ quality_seq: id2 }) => id2 === id1)));

    const removed_records = snap_qualities.filter(({ quality_seq: id1 }) => !addendum_qualites.some(({ quality_seq: id2 }) => id2 === id1));
    for (var obj of removed_records) {
      obj.color = "#f65c8d1f";
      new_qualities.push(obj);
    }
    const new_records = addendum_qualites.filter(({ quality_seq: id1 }) => !snap_qualities.some(({ quality_seq: id2 }) => id2 === id1));

    for (var el of new_records) {
      el.color = '#41c1971f';
      new_qualities.push(el);
    }

    const common_records = snap_qualities.filter(item => addendum_qualites.filter(e => JSON.stringify(item.quality_seq) === JSON.stringify(e.quality_seq)));
    for (var el1 of common_records) {
      new_qualities.push(el1);
    }

    let copySnapData = {
      ...this.state.snapshot_data,
      qualities: new_qualities
    }

    this.setState({
      snapshot_data: copySnapData
    })
  }

  ContractApproveHandler = (status) => {
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      sale_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
      status: status,
    };

    if (status === "Approved") {
      let validation_errors = 0;
      for (var i of this.state.qualities) {

        const { quantity_allocated = 0, sc_quantity_delivered = 0 } = i || {};

        if (parseFloat(quantity_allocated) > parseFloat(i.quantity_in_mt) || parseFloat(sc_quantity_delivered) > parseFloat(i.quantity_in_mt)) {
          // rows[idx].quantity_in_mtError = false;
          validation_errors++;
          // setQualities(rows);
          // callbackFn(rows);
          // forceUpdate();
          // return;
        }
      }

      if (validation_errors > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please remove allocated quantity before approving"
        });
        return;
      }

      api.approve_sales_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    } else {
      api.updateSaleContractStatus(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res) {
              if (res.code === "200") {
                this.setState({
                  successMsg: "Contract has been update successfully",
                  snackBarSuccessOpen: true,
                });
                this.componentDidMount();
              }
              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
                if (res.code === "607") {
                  window.location.href = "/logout";
                }
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
    }
  };

  CheckDiffHandler = (lable) => {

    const snap_data = this.state.snapshot_data || {};
    const addendum_data = this.state;
    const diff = snap_data[lable] !== addendum_data[lable];
    if (diff) {
      return addendum_data[lable];
    }
    return false;
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  };

  render() {
    const salesContractId = decodeURIComponent(
      window.atob(this.props.match.params.salesContractID)
    );
    let drawerComponent;
    if (this.state.addDocuments && salesContractId) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Sales_Contract"}
          feature_seq_id={salesContractId}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <Drawer
            anchor="right"
            open={this.state.openDocumentsDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  <a href={"/sales-contract-list"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a> Sales Contract Addendum  - {this.state.contract_no} &nbsp;

                  <span
                    className={
                      this.state.status === "Active"
                        ? "type_1 Freezed"
                        : "type_2"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.status}
                  </span>
                </h4>
              </div>
              {(this.state.status === "Draft") &&
                <div
                  className="col-lg-3 text-right float-sm-left"
                  style={{ padding: "5px 20px" }}
                >
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "17px" }}
                    onClick={() => this.ContractApproveHandler("Pending Approval")}
                  >
                    Send for Approval
                  </button>
                </div>
              }
              {(this.state.status === "Pending Approval" && (this.state.features.includes('21') || this.state.features.includes("97")) && this.state.created_by !== this.Cookie.getCookie('loginUserId')) &&
                <div
                  className="col-lg-3 text-right float-sm-left"
                  style={{ padding: "5px 20px" }}
                >
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "17px" }}
                    onClick={() => this.ContractApproveHandler("Approved")}
                  >
                    Approve
                  </button>
                </div>
              }
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <div className="container p-3" id="pdf">
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <tr className="table-header">
                        <th>Customer</th>
                        <th>Base Contract No</th>
                        <th>Buyer Reference No</th>
                        <th>Contract Date</th>
                        <th>Currency</th>
                        <th>Laycan</th>

                      </tr>
                      <tr>
                        <td>
                          <a
                            href={"/view-customer/" + btoa(this.state.customer)}
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.customer_name}
                          </a>
                        </td>
                        <td>
                          <a
                            href={
                              "/view-sales-contract/" +
                              btoa(this.state.base_contract_id)
                            }
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.base_contract_no}
                          </a>
                        </td>
                        <td>
                          {this.CheckDiffHandler('buyer_ref_no') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.buyer_ref_no}</s>&nbsp;{this.CheckDiffHandler('buyer_ref_no')} </> : this.state.buyer_ref_no
                            ? this.state.buyer_ref_no
                            : "-"}
                        </td>
                        <td>
                          {this.CheckDiffHandler('contract_date') ? <><s style={{ textDecorationColor: 'red' }}>{localDateFormate(this.state.snapshot_data?.contract_date)}</s>&nbsp;{localDateFormate(this.CheckDiffHandler('contract_date'))} </> : localDateFormate(this.state.contract_date)}
                        </td>
                        <td>
                          {this.CheckDiffHandler('currency') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.currency}</s>&nbsp;{this.CheckDiffHandler('currency')} </> : this.state.currency}
                        </td>
                        <td>
                          {(this.state.laycan_1 ||
                            this.state.laycan_quantity_1) && (
                              <div>
                                {this.CheckDiffHandler('laycan_1') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_1}</s>&nbsp;{this.CheckDiffHandler('laycan_1')} </> : this.state.laycan_1} -
                                {this.CheckDiffHandler("laycan_quantity_1") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_quantity_1}</s>&nbsp;{this.CheckDiffHandler('laycan_quantity_1')} </> : toLocaleString(this.state.laycan_quantity_1)}
                              </div>
                            )}
                          {(this.state.laycan_2 ||
                            this.state.laycan_quantity_2) && (
                              <div>
                                {this.CheckDiffHandler('laycan_2') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_2}</s>&nbsp;{this.CheckDiffHandler('laycan_2')} </> : this.state.laycan_2}
                                - {this.CheckDiffHandler('laycan_quantity_2') ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(this.state.snapshot_data?.laycan_quantity_2)}</s>&nbsp;{this.CheckDiffHandler('laycan_quantity_2')} </> : toLocaleString(this.state.laycan_quantity_2)}
                              </div>
                            )}
                          {(this.state.laycan_3 ||
                            this.state.laycan_quantity_3) && (
                              <div>
                                {this.CheckDiffHandler('laycan_3') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_3}</s>&nbsp;{this.CheckDiffHandler('laycan_3')} </> : this.state.laycan_3}
                                - {this.CheckDiffHandler("laycan_quantity_3") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_quantity_3}</s>&nbsp;{this.CheckDiffHandler('laycan_quantity_3')} </> : toLocaleString(this.state.laycan_quantity_3)}

                              </div>
                            )}
                          {(this.state.laycan_4 ||
                            this.state.laycan_quantity_4) && (
                              <div>
                                {this.CheckDiffHandler("laycan_4") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_4}</s>&nbsp;{this.CheckDiffHandler('laycan_4')} </> : this.state.laycan_4}
                                - {this.CheckDiffHandler("laycan_quantity_4") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_quantity_4}</s>&nbsp;{this.CheckDiffHandler("laycan_quantity_4")} </> : toLocaleString(this.state.laycan_quantity_4)}

                              </div>
                            )}
                          {(this.state.laycan_5 ||
                            this.state.laycan_quantity_5) && (
                              <div>
                                {this.CheckDiffHandler("laycan_5") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_5}</s>&nbsp;{this.CheckDiffHandler("laycan_5")} </> : this.state.laycan_5}
                                - {this.CheckDiffHandler('laycan_quantity_5') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.laycan_quantity_5}</s>&nbsp;{this.CheckDiffHandler('laycan_quantity_5')} </> : toLocaleString(this.state.laycan_quantity_5)}

                              </div>
                            )}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>


                <QualityView
                  contractType={"Sales"}
                  qualities={this.state.snapshot_data?.qualities}
                  addendum_qualities={this.state.qualities}
                  callbackFn={() => { }}
                />

                <div className='row'>
                  <table className='table table-bordered table-sm'>
                    <tr>
                      <th className='table-header'>Quality Adjustment</th>
                    </tr>
                    <tr>
                      <td className='text-uppercase' style={{ whiteSpace: 'pre-line' }}>{this.CheckDiffHandler('payment_adjustment') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.payment_adjustment}</s>&nbsp;{this.CheckDiffHandler('payment_adjustment')} </> : this.state.payment_adjustment
                        ? this.state.payment_adjustment
                        : "-"}</td>
                    </tr>
                  </table>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">

                      <tr className="table-header">
                        <th></th>
                        <th>Supplier</th>
                        <th>{config.company} Surveyor</th>
                        <th>Buyer/Additional </th>
                      </tr>
                      <tr>
                        <th>Surveyor Name</th>
                        <td>
                          {this.CheckDiffHandler("supplier_surveyor_names") ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.supplier_surveyor_names}</s>&nbsp;{this.CheckDiffHandler('supplier_surveyor_names')} </> : this.state.supplier_surveyor_names
                            ? this.state.supplier_surveyor_names
                            : "-"}
                        </td>
                        <td>
                          {this.CheckDiffHandler('internal_surveyor_names') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.internal_surveyor_names}</s>&nbsp;{this.CheckDiffHandler('internal_surveyor_names')} </> : this.state.internal_surveyor_names
                            ? this.state.internal_surveyor_names
                            : "-"}

                        </td>
                        <td>
                          {this.CheckDiffHandler('internal_surveyor_analysis_type') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.internal_surveyor_analysis_type}</s>&nbsp;{this.CheckDiffHandler('internal_surveyor_analysis_type')} </> : this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Analysis Type</th>
                        <td>
                          {this.CheckDiffHandler('supplier_surveyor_analysis_type') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.supplier_surveyor_analysis_type}</s>&nbsp;{this.CheckDiffHandler('supplier_surveyor_analysis_type')} </> : this.state.supplier_surveyor_analysis_type
                            ? this.state.supplier_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.CheckDiffHandler('internal_surveyor_analysis_type') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.internal_surveyor_analysis_type}</s>&nbsp;{this.CheckDiffHandler('internal_surveyor_analysis_type')} </> : this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.CheckDiffHandler('joint_surveyor_analysis_type') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.joint_surveyor_analysis_type}</s>&nbsp;{this.CheckDiffHandler('joint_surveyor_analysis_type')} </> : this.state.joint_surveyor_analysis_type
                            ? this.state.joint_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">

                      <tr className="table-header">
                        <th>Vessel Type</th>
                        <th>Loading Rate for Geared</th>
                        <th>Loading Rate for Gearless</th>
                        <th> Port of Loading </th>

                        {(this.state.discharge_rate_1 || this.state.port_of_discharge_1 || this.state.discharge_rate_2 || this.state.port_of_discharge_2 || this.state.discharge_rate_3 || this.state.port_of_discharge_3) && this.state.dischargeRateField &&
                          <th>Port of Discharge</th>
                        }
                      </tr>
                      <tr>
                        <td rowSpan={this.state.portOfDischargeRows?.length}>{this.CheckDiffHandler('vessel_type') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.vessel_type}</s>&nbsp;{this.CheckDiffHandler('vessel_type')} </> : this.state.vessel_type
                          ? this.state.vessel_type
                          : "-"}</td>
                        <td rowSpan={this.state.portOfDischargeRows?.length}>
                          {this.CheckDiffHandler('loading_rate_for_geared') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.loading_rate_for_geared}</s>&nbsp;{this.CheckDiffHandler('loading_rate_for_geared')} </> : this.state.loading_rate_for_geared
                            ? this.state.loading_rate_for_geared
                            : "-"}
                        </td>
                        <td rowSpan={this.state.portOfDischargeRows?.length}>
                          {this.CheckDiffHandler('loading_rate_for_gearless') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.loading_rate_for_gearless}</s>&nbsp;{this.CheckDiffHandler('loading_rate_for_gearless')} </> : this.state.loading_rate_for_gearless
                            ? this.state.loading_rate_for_gearless
                            : "-"}

                        </td>
                        <td rowSpan={this.state.portOfDischargeRows?.length}>
                          {this.CheckDiffHandler('port_of_loading') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.port_of_loading}</s>&nbsp;{this.CheckDiffHandler('port_of_loading')} </> : this.state.port_of_loading
                            ? this.state.port_of_loading
                            : "-"}
                        </td>
                        {(this.state.discharge_rate_1 || this.state.port_of_discharge_2 || this.state.discharge_rate_2 || this.state.port_of_discharge_2 || this.state.discharge_rate_3 || this.state.port_of_discharge_3) && this.state.dischargeRateField &&
                          <td>

                            <div>
                              {this.CheckDiffHandler('discharge_rate_1') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.discharge_rate_1}</s>&nbsp;{this.CheckDiffHandler('discharge_rate_1')} </> : this.state.discharge_rate_1}
                              -
                              {this.CheckDiffHandler('port_of_discharge_1') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.port_of_discharge_1}</s>&nbsp;{this.CheckDiffHandler('port_of_discharge_1')} </> : this.state.port_of_discharge_1}
                            </div>

                            <div>
                              {this.CheckDiffHandler('discharge_rate_2') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.discharge_rate_2}</s>&nbsp;{this.CheckDiffHandler('discharge_rate_2')} </> : this.state.discharge_rate_2}
                              -
                              {this.CheckDiffHandler('port_of_discharge_2') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.port_of_discharge_2}</s>&nbsp;{this.CheckDiffHandler('port_of_discharge_2')} </> : this.state.port_of_discharge_2}
                            </div>

                            <div>
                              {this.CheckDiffHandler('discharge_rate_3') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.discharge_rate_3}</s>&nbsp;{this.CheckDiffHandler('discharge_rate_3')} </> : this.state.discharge_rate_3}
                              -
                              {this.CheckDiffHandler('port_of_discharge_3') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.port_of_discharge_3}</s>&nbsp;{this.CheckDiffHandler('port_of_discharge_3')} </> : this.state.port_of_discharge_3}
                            </div>

                          </td>
                        }
                      </tr>
                    </table>
                  </div>
                </div>


                <div className='row'>
                  <table className='table table-bordered table-sm'>
                    <tr>
                      <th className='table-header' nowrap="true"> Payment Terms 1</th>
                      <th className='table-header' nowrap="true"> Payment Terms 2</th>
                      <th className='table-header' nowrap="true"> Remarks</th>
                      <th className='table-header' nowrap="true">  Share Point Document Link</th>
                    </tr>
                    <tr>
                      <td className='text-uppercase' style={{ whiteSpace: 'pre-line' }}>
                        {this.CheckDiffHandler('payment_terms_1') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.payment_terms_1}</s>&nbsp;{this.CheckDiffHandler('payment_terms_1')} </> : this.state.payment_terms_1
                          ? this.state.payment_terms_1
                          : "-"}
                      </td>
                      <td className='text-uppercase' style={{ whiteSpace: 'pre-line' }}>
                        {this.CheckDiffHandler('payment_terms_2') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.payment_terms_2}</s>&nbsp;{this.CheckDiffHandler('payment_terms_2')} </> : this.state.payment_terms_2
                          ? this.state.payment_terms_2
                          : "-"}
                      </td>
                      <td className='text-uppercase' style={{ whiteSpace: 'pre-line' }}>
                        {this.CheckDiffHandler('remarks') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.remarks}</s>&nbsp;{this.CheckDiffHandler('remarks')} </> : this.state.remarks
                          ? this.state.remarks
                          : "-"}
                      </td>
                      <td className='text-uppercase' style={{ whiteSpace: 'pre-line' }}>
                        {this.state.share_point_doc_link &&
                          <label className="contract_display_header_value">
                            <a
                              rel="noopener noreferrer"
                              href={this.onLinkClick(this.state.share_point_doc_link)}
                              target="_blank"
                              style={{ color: config.themeColor }}
                            >
                              {this.CheckDiffHandler('share_point_doc_link') ? <><s style={{ textDecorationColor: 'red' }}>{this.state.snapshot_data?.share_point_doc_link}</s>&nbsp;{this.CheckDiffHandler('share_point_doc_link')} </> : this.state.share_point_doc_link}
                            </a>
                          </label>
                        }
                      </td>
                    </tr>
                  </table>
                </div>

                {(this.state.features.includes("327") ||
                  this.state.features.includes("97")) && (
                    <div className="card border mb-2">
                      <div className="row">
                        <span
                          className="col-lg-12 contract_display_header_value pl-0 mb-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Uploaded Documents
                        </span>
                        {this.state.files.length > 0 ? (
                          <div className="row">
                            {this.state.files.map((e, index) => (
                              <div
                                className={
                                  e.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                                }
                                key={index}
                              >
                                {e.file_path && (
                                  <div className="col-lg-12 p-0">
                                    <a
                                      href={config.apiDomain + "/" + e.file_path}
                                      role="button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {e.file_description}
                                    </a>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="row col-lg-12">
                            <p>No files to show</p>
                          </div>
                        )}
                        <p className="row col-lg-12">
                          Add Documents &nbsp;
                          <i
                            className="fa fa-plus-circle"
                            style={{
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({
                                openDocumentsDrawer: true,
                                addDocuments: true,
                              });
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  )}
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() =>
                    (window.location.href = "/sales-contract-list")
                  }
                >
                  Back
                </button>
                {(this.state.features.includes("3") ||
                  this.state.features.includes("97")) &&
                  this.state.status !== "Active" && (
                    <button
                      type="button"
                      className="btn next_button"
                      name="submit"
                      onClick={() =>
                      (window.location.href =
                        "/update-sales-contract/" +
                        btoa(salesContractId))
                      }
                    >
                      Edit
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
