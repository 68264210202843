import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Checkbox } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";

import api from "../../../api/api";
import CoalCostingAdd from "./CoalCostingAdd";
import CoalCostingView from "./CoalCostingView";
import CoalCostingUpdate from "./CoalCostingUpdate";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CoalCostingCommercialList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      features: [],
      purchaseBargeList: [],
      purchaseContractSelected: null,
      purchase_contract_id: null,
      deleting_barge_pur_fin_id: null,
      barge_nomination: "",
      purchaseContractError: false,
      deleteCosting: false,
      business_no_drop_value: [],
      business_no_ids: [],
      showDrawer: false,
      CoalCostingAdd: false,
      CoalCostingView: false,
      CoalCostingUpdate: false,
      comboID: null,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("127") || features.includes("97"))) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        var status = "";
        // Get business numbers
        await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.business_numbers) {
                  let business_no_drop_value = [];
                  for (var i of res.business_numbers) {
                    business_no_drop_value.push({
                      name: i.business_no,
                      value: i.id,
                      mother_vessel_name: i.mother_vessel_name,
                    });
                  }
                  this.setState({
                    business_no_drop_value: business_no_drop_value,
                    //loading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
        //Get purchase contracts
        await api
          .getPurchaseContracts(loginUserID, status, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                  if (res.purchase_contract) {
                    let purchaseDataforMenu = [];
                    const filteredPContracts = res.purchase_contract.filter(
                      (e, indx) =>
                        e.status === "Active" || e.status === "Freezed"
                    );
                    for (let i = 0; i < filteredPContracts.length; i++) {
                      purchaseDataforMenu.push({
                        name: filteredPContracts[i].contract_no,
                        value: filteredPContracts[i].id,
                        key: i,
                        vendor_name: filteredPContracts[i].vendor_name,
                      });
                    }

                    this.setState({
                      purchaseDataForMenu: purchaseDataforMenu,
                      //purchaseContractList: res.purchase_contract,
                      purchaseContractList: filteredPContracts,
                      isLoading: false,
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
      // this.callingAsync();
    }

    document.title =
      config.documentTitle + "Barge Purchase Financial Coal Costing List";
  }

  onPurchaseFinDelete = (barge_pur_fin_id) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    //Delete Barge sales fiancials group/individual
    api
      .delete_barge_pur_fin_coal_costing(loginUserID, idToken, barge_pur_fin_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              alert("Barge Purchase Financial data deleted successfully");
              this.setState(
                {
                  purchaseBargeList: [],
                  isLoading: false,
                  deleteCosting: false,
                  succesMsg: "",
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "650") {
              alert(res.message);
              this.setState({
                isLoading: false,
                deleteCosting: false,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  searchCostingsHandler = () => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var purchase_contract_id = this.state.purchase_contract_id;
    if (purchase_contract_id === null || purchase_contract_id === "") {
      alert("Please select Purchase Contract");
      this.setState({
        purchaseContractError: true,
        isLoading: false,
      });
      return;
    }
    //else {
    var barge_nomination = this.state.barge_nomination;
    var costing_type = "Commercial";
    let business_no = this.state.business_no_ids;
    // Get Purchase contract based Barges by Business number ID
    api
      .get_coal_costing_barges(
        loginUserID,
        idToken,
        purchase_contract_id,
        business_no,
        costing_type,
        barge_nomination
      )
      .then((response) => {
        // api.get_pc_barge_coal_list(loginUserID, idToken, businessNoID, costing_type).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.purchase_contracts) {
                // let listCount = 0;
                var purchase_contracts = res.purchase_contracts;
                for (let i = 0; i < purchase_contracts.length; i++) {
                  let barges = [];
                  let purchase_contracts_barges = purchase_contracts[i].barges;
                  let newData = [];
                  for (let k = 0; k < purchase_contracts_barges.length; k++) {
                    let id = purchase_contracts_barges[k].barge_pur_fin_id;
                    if (id !== null) {
                      barges.push(purchase_contracts_barges[k]);
                    }
                  }
                  var result = barges.reduce(function (r, a) {
                    r[a.barge_pur_fin_id] = r[a.barge_pur_fin_id] || [];
                    r[a.barge_pur_fin_id].push(a);
                    return r;
                  }, Object.create(null));
                  for (let l = 0; l < Object.keys(result).length; l++) {
                    newData.push({
                      new_barges: result[Object.keys(result)[l]],
                    });
                  }
                  purchase_contracts[i].new_barges = newData;
                  if (purchase_contracts.length === 0) {
                    this.setState({
                      errorMsg: "No records to display",
                    });
                  }
                  // listCount += newData.length;
                }
                this.setState({
                  purchaseBargeList: purchase_contracts.reverse(),
                  isLoading: false,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            }
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });
    //}
  };

  get_advance_adjustments = (data) => {
    var totalAdjustments = [];
    for (var i of data) {
      totalAdjustments.push(i.advance_adjustment_value);
    }
    return totalAdjustments.toString();
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        CoalCostingUpdate: false,
        CoalCostingAdd: false,
        CoalCostingView: true,
        updateDocuments: false,
      });
    }
  };

  onAddResponse = (value) => {
    this.setState({
      CoalCostingAdd: false,
      CoalCostingView: true,
      comboID: value,
      updateDocuments: false,
    });
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        CoalCostingUpdate: false,
        CoalCostingAdd: false,
        CoalCostingView: false,
        showDrawer: false,
        comboID: null,
        updateDocuments: false,
      });
    }
  };

  onBack = (value) => {
    if (value === false) {
      this.setState({
        CoalCostingUpdate: false,
        CoalCostingAdd: false,
        CoalCostingView: true,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        CoalCostingUpdate: true,
        showDrawer: true,
        CoalCostingAdd: false,
        CoalCostingView: false,
        updateDocuments: false,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      CoalCostingUpdate: true,
      CoalCostingView: false,
      CoalCostingAdd: false,
      updateDocuments: true,
    });
  };

  render() {
    let drawerComponent;
    if (this.state.CoalCostingAdd) {
      drawerComponent = (
        <CoalCostingAdd
          costing_type="Commercial"
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.CoalCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <CoalCostingView
          comboID={this.state.comboID}
          returnDocumentsHandler={this.addDocuments}
          costing_type="Commercial"
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          invoiceCostingHandler={(data) => {
            this.setState({
              costingTopOfInvoice: data,
              invoiceCosting: true,
              showDrawer: true,
              comboID: null,
            });
          }}
        />
      );
    } else if (this.state.CoalCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <CoalCostingUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onBack={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <div id="wraper">
        <div className="content">
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="content-header">
              <Drawer
                anchor="right"
                open={this.state.showDrawer}
                variant="temporary"
                elevation={20}
                style={{ overflow: "initial" }}
              >
                <div className="row" style={{ width: 800 }}>
                  {drawerComponent}
                </div>
              </Drawer>
              <div className="container p-0">
                {this.state.errorMsg && (
                  <div className="row">
                    <div className="col text-center bg-white border mb-2">
                      <p className="error-class">{this.state.errorMsg}</p>
                    </div>
                  </div>
                )}
                {this.state.successMsg && (
                  <div className="row">
                    <div className="col text-center bg-white border mb-2 ">
                      <p className="success-class">{this.state.successMsg}</p>
                    </div>
                  </div>
                )}
                <div className="card p-2">
                  <div className="row">
                    <div className="col-lg-4 pl-0">
                      {/* <label className='form_label mb-0'>Purchase Contract *</label> */}
                      <Autocomplete
                        options={this.state.purchaseDataForMenu}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({
                              purchaseContractSelected: value,
                              purchase_contract_id: value.value,
                              purchase_contract_no: value.name,
                              purchaseContractError: false,
                            });
                          } else {
                            this.setState({
                              purchase_contract_id: "",
                              purchase_contract_no: "",
                              purchaseContractSelected: null,
                              purchaseContractError: false,
                            });
                          }
                        }}
                        value={this.state.purchaseContractSelected}
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        size="small"
                        name="purchaseContract"
                        renderOption={(option) => (
                          <React.Fragment>
                            {/* {option.name} ({option.vendor_name}) */}
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {option.vendor_name
                                  ? "( " + option.vendor_name + " )"
                                  : null}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Purchase Contract *"
                            label="Purchase Contract *"
                            error={this.state.purchaseContractError}
                            variant="standard"
                            fullWidth
                            style={{ top: 8 }}
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-4 pl-0">
                      {/* //<label className='form_label mb-0'>Purchase Contract *</label> */}
                      <Autocomplete
                        multiple
                        options={this.state.business_no_drop_value}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {/* {option.name} */}
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.name + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({
                              business_no_ids: data,
                              business_no_value: value,
                            });
                          } else {
                            this.setState({
                              business_no_ids: [],
                              business_no_value: { name: "", value: "" },
                            });
                          }
                        }}
                        disableCloseOnSelect
                        name="Business_Number"
                        size="small"
                        limitTags={1}
                        value={this.state.business_no_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Business No"
                            label="Business No"
                            variant="standard"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    {(this.state.features.includes("126") ||
                      this.state.features.includes("97")) && (
                      <div className="col-lg-2">
                        <button
                          onClick={this.searchCostingsHandler}
                          className="header_button header_button_text add_button_adjustment"
                          style={{ width: "auto", marginTop: "25px" }}
                        >
                          Load
                        </button>
                      </div>
                    )}
                    <div className="col text-right float-sm-left">
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "25px" }}
                        onClick={() =>
                          this.setState({
                            CoalCostingAdd: true,
                            showDrawer: true,
                          })
                        }
                      >
                        New
                      </button>
                    </div>
                  </div>
                  {this.state.purchaseBargeList.length > 0 && (
                    <div>
                      {this.state.purchaseBargeList.map((p, index) => (
                        <div
                          className={
                            p.new_barges.length > 0
                              ? "shadow-sm p-2 mb-2 bg-white border mt-2"
                              : ""
                          }
                          key={index}
                        >
                          {p.new_barges.length > 0 && (
                            <h5 className="bg-light mb-3 p-2 section_header">
                              {p.purchase_contract_no} - {p.supplier_name}
                            </h5>
                          )}
                          {p.new_barges.length > 0 ? (
                            <div className="row">
                              <div className="table-responsive">
                                <table className="table table-bordered table-sm mb-0">
                                  <thead className="table-header">
                                    <tr>
                                      <th
                                        nowrap="true"
                                        style={{ minWidth: 90 }}
                                      ></th>
                                      <th nowrap="true"> Costing ID </th>
                                      <th nowrap="true"> Barge ID </th>
                                      <th nowrap="true"> Barge Nomination </th>
                                      <th nowrap="true"> Business No </th>
                                      <th nowrap="true"> Purchase Contract </th>
                                      <th nowrap="true"> Quality </th>
                                      <th nowrap="true"> Purchase Type </th>
                                      <th nowrap="true" className="text-right">
                                        Quantity
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Price PMT
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Coal Price
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        PPH22
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Performa Advance Adjustments
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Advance Adjustment
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Total Payable
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Paid
                                      </th>
                                      <th nowrap="true" className="text-right">
                                        Remaining
                                      </th>
                                    </tr>
                                  </thead>
                                  {p.new_barges.map((id, idx) => (
                                    <tbody key={idx}>
                                      {id.new_barges.map((idr, indx) => (
                                        <tr key={indx}>
                                          {indx === 0 && (
                                            <td
                                              className="table_td"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                              }}
                                            >
                                              <i
                                                className="fa fa-eye mr-2"
                                                aria-hidden="true"
                                                style={{
                                                  fontSize: 22,
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.setState({
                                                    CoalCostingView: true,
                                                    comboID:
                                                      idr.barge_pur_fin_id +
                                                      "$#" +
                                                      p.purchase_contract_id,
                                                    showDrawer: true,
                                                  })
                                                }
                                              ></i>
                                              {(this.state.features.includes(
                                                "129"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) && (
                                                <i
                                                  className="fa fa-trash-o"
                                                  style={{
                                                    fontSize: 20,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      deleting_barge_pur_fin_id:
                                                        idr.barge_pur_fin_id,
                                                      deleteCosting: true,
                                                    })
                                                  }
                                                ></i>
                                              )}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value"
                                              rowSpan={id.new_barges.length}
                                            >
                                              {idr.costing_seq_id}
                                            </td>
                                          )}
                                          <td className="allocation_table_value">
                                            {pad(idr.barge_id)}
                                          </td>
                                          <td
                                            className="allocation_table_value"
                                            nowrap="true"
                                          >
                                            {idr.barge_nomination}
                                          </td>
                                          <td
                                            className="allocation_table_value"
                                            nowrap="true"
                                          >
                                            {idr.business_no}
                                          </td>
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {idr.purchase_contract_no}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {idr.quality}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {idr.purchase_type}
                                            </td>
                                          )}
                                          <td
                                            className="allocation_table_value text-right"
                                            nowrap="true"
                                          >
                                            {toLocaleString(
                                              idr.coal_costing_quantity
                                            )}
                                          </td>
                                          <td
                                            className="allocation_table_value text-right"
                                            nowrap="true"
                                          >
                                            {toLocaleString(idr.adjusted_pmt) +
                                              " " +
                                              idr.billing_currency}
                                          </td>
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(idr.coal_price) +
                                                " " +
                                                idr.billing_currency}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(idr.pph_value)}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(
                                                idr.performa_advance_adjustments
                                              )}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(
                                                idr.adv_adjustments
                                              )}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(
                                                idr.total_payable
                                              )}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(idr.paid)}
                                            </td>
                                          )}
                                          {indx === 0 && (
                                            <td
                                              className="allocation_table_value text-right"
                                              rowSpan={id.new_barges.length}
                                              style={{
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderLeft: "1px solid #dee2e6",
                                              }}
                                              nowrap="true"
                                            >
                                              {toLocaleString(
                                                idr.remaining_amount
                                              )}
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  ))}
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span className="contract_display_header_value pt-2">
                                No costings made
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {this.state.deleteCosting && (
                    <DeleteConfirmation
                      open={this.state.deleteCosting}
                      close={() => this.setState({ deleteCosting: false })}
                      callbackFn={() =>
                        this.onPurchaseFinDelete(
                          this.state.deleting_barge_pur_fin_id
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
