import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import WarningIcon from "@material-ui/icons/Warning";
import {
  TextField,
  Snackbar,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import { Alert } from "@material-ui/lab";

// import ViewCoalPriceIndexes from "../../CoalIndexPrices/coalPriceIndexView";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";
import SalesAdvanceAdjustments from "../../contracts/SalesAdvanceAdjustments";
import InvoiceForm from "../../common/InvoiceForm";

export default class BusinessNoSalesCoalCostingView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      description: "",
      coal_cost_billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      coal_cost_penality_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      invoice_date: null,
      payment_invoice_date: null,
      sales_index_linked: "No",
      ppn: "",
      ppn_date: null,
      coal_cost_coal_quantity: "",
      coal_cost_coal_price: "",
      coal_cost_gar_penality: null,
      coal_cost_ash_penality: null,
      coal_cost_sulphur_penality: null,
      coal_cost_gar_bonus: null,
      coal_cost_adjusted_coal_price_pmt: "",
      coal_cost_adjusted_coal_price: "",
      coal_cost_pph22_barge_quantity_in_mt: "",
      coal_cost_pph22_coal_price_per_mt: "",
      coal_cost_pph22_percent: "",
      coal_cost_pph22_percent_val: "",
      coal_cost_pph22_export: "",
      coal_cost_pph22_export_amount: "",
      coal_cost_pph22_export_percent: "1.5",
      coal_cost_pph22_export_value: "",
      coal_cost_currency_xchg_rate: "",
      coal_cost_adjusted_coal_price_in_local_currency: "",
      performa_mapping_data: [],
      performa_advance_adjustments: 0,
      performa_amount: 0,
      //Advance adjustments part

      advance_adjustments: [
        {
          advance_adjustment: "No",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          sales_contract_id: "",
          total_customer_adv_payment: "",
        },
      ],

      sales_term: "Non Specs",
      openAdvAdjustments: false,
      displayQualityResAlert: false,

      totalCoalPaymentsAmount: "",
      // Invoices date
      invoiceFormTitle: "Add",
      commercialInvoicesData: [],
      salesBargeList: [],
      performa_invoices: [],
      date_of_payment: null,
      performaExpandSelected: null,
      commercialExpandSelected: null,
      selectedSalesContractId: null,

      errorMsg: "",
      successMsg: "",
      isLoading: true,
      features: [],
      files: [],
      coal_index: {
        coal_index_type: "Weekly",
        index_ids: [],
        average_coal_index_price: 0,
        prorata_gar: "0",
        prorata_coal_index_price: 0,
        bonus_coal_index_pmt: "",
        penality_coal_index_pmt: "",
        derivered_coal_index_pmt: "",
        final_coal_index_pmt: "",
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("191") ||
          features.includes("404") ||
          features.includes("455") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();

        try {
          var comboID = this.props.comboID;
          var barge_sales_financials_id = comboID.split("$#")[0];
          var businessNoID = comboID.split("$#")[1];
        } catch (e) {
          window.location.href = "/business-number-list";
        }
        var status = "Active";
        let costing_type = "";
        await api
          .get_sales_barge_coal_costing(
            barge_sales_financials_id,
            loginUserID,
            idToken,
            businessNoID
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  var totalAdjustments = 0;
                  for (var i of res.financials.advance_adjustments) {
                    totalAdjustments += Number(i.advance_adjustment_value);
                  }
                  let coal_cost_coal_price_pmt_local_currency = (
                    Number(res.financials.coal_price_pmt) *
                    Number(res.financials.currency_xchg_rate)
                  ).toFixed(2);
                  if (
                    (config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") &&
                    res.financials.billing_currency === "IDR"
                  ) {
                    coal_cost_coal_price_pmt_local_currency = (
                      Number(res.financials.coal_price_pmt) *
                      Number(res.financials.currency_xchg_rate)
                    ).toFixed(0);
                  }
                  costing_type = res.financials.costing_type;
                  api
                    .get_sc_barge_coal_list(
                      loginUserID,
                      idToken,
                      businessNoID,
                      costing_type
                    )
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.code === "200") {
                            if (res.sales_contracts) {
                              var sales_contracts = res.sales_contracts;
                              for (let i = 0; i < sales_contracts.length; i++) {
                                let barges = [];
                                let purchase_contracts_barges =
                                  sales_contracts[i].barges;
                                let newData = [];
                                for (
                                  let k = 0;
                                  k < purchase_contracts_barges.length;
                                  k++
                                ) {
                                  let id =
                                    purchase_contracts_barges[k]
                                      .com_sales_fin_id;
                                  if (id !== null) {
                                    barges.push(purchase_contracts_barges[k]);
                                  }
                                }
                                var result = barges.reduce(function (r, a) {
                                  r[a.com_sales_fin_id] =
                                    r[a.com_sales_fin_id] || [];
                                  r[a.com_sales_fin_id].push(a);
                                  return r;
                                }, Object.create(null));
                                for (
                                  let l = 0;
                                  l < Object.keys(result).length;
                                  l++
                                ) {
                                  newData.push({
                                    new_barges: result[Object.keys(result)[l]],
                                  });
                                }
                                sales_contracts[i].new_barges = newData;
                              }
                              this.setState({
                                salesBargeList: sales_contracts,
                                // isLoading: false
                              });
                            } else {
                              alert(
                                "Unexpected error occured. Please contact administrator."
                              );
                            }
                          } else if (res.code === "601") {
                            this.setState({
                              errorMsg: res.message,
                              successMsg: "",
                              isLoading: false,
                            });
                          } else if (res.code === "607") {
                            window.location.href = "/logout";
                          }
                        });
                      }
                    });
                  // let performa_invoices = [];
                  // if (costing_type === "Commercial") {
                  //   for (let n = 0; n < res.financials.invoices.length; n++) {
                  //     if (
                  //       res.financials.invoices[n].invoice_type !==
                  //       "Commercial Invoices"
                  //     ) {
                  //       performa_invoices.push(res.financials.invoices[n]);
                  //       performa_payments_amount += Number(
                  //         res.financials.invoices[n].performa_payments_amount
                  //       );
                  //     }
                  //   }
                  //   for (
                  //     let n = 0;
                  //     n < res.financials.performa_advance_adjustments.length;
                  //     n++
                  //   ) {
                  //     performa_advance_adjustment += Number(
                  //       res.financials.performa_advance_adjustments[n]
                  //         .advance_adjustment_value
                  //     );
                  //   }
                  // }
                  let coal_index_ids =
                    res.financials.index_ids?.split(",") || [];
                  let coal_index = {
                    ...this.state.coal_index,
                    coal_index_type: res.financials.coal_index_type,
                    index_ids: coal_index_ids,
                    average_coal_index_price:
                      res.financials.average_coal_index_price,
                    prorata_gar: res.financials.prorata_gar,
                    prorata_coal_index_price:
                      res.financials.prorata_coal_index_price,
                    bonus_coal_index_pmt: res.financials.bonus_coal_index_pmt,
                    penality_coal_index_pmt:
                      res.financials.penality_coal_index_pmt,
                    derivered_coal_index_pmt:
                      res.financials.derivered_coal_index_pmt,
                    final_coal_index_pmt: res.financials.final_coal_index_pmt,
                  };
                  const invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: res.financials.invoice_no,
                    invoice_date: res.financials.invoice_date,
                    ppn: res.financials.ppn,
                    ppn_date: res.financials.ppn_date,
                    invoice_percentage: res.financials.invoice_percentage,
                  };
                  this.setState({
                    invoiceDetails: invoice_details,
                    coal_index: coal_index,
                    performa_mapping_data: res.financials.performa_mapping_data,
                    performa_advance_adjustments:
                      res.financials.performa_mapping_data?.reduce(
                        (acc, curr) =>
                          parseFloat(acc) +
                          parseFloat(
                            curr.performa_adv_adjustment
                              ? curr.performa_adv_adjustment
                              : 1
                          ),
                        0
                      ),
                    performa_amount:
                      res.financials.performa_mapping_data?.reduce(
                        (acc, curr) =>
                          parseFloat(acc) +
                          parseFloat(
                            curr.performa_amount ? curr.performa_amount : 1
                          ),
                        0
                      ),
                    barge_sales_financials_id: barge_sales_financials_id,
                    is_commercial_invoices_raised:
                      res.financials.is_commercial_invoices_raised,
                    costing_type: costing_type,
                    contract_invoice_no: res.financials.invoice_no
                      ? res.financials.invoice_no
                      : "",
                    costing_no: res.financials.costing_no,
                    mv_name: res.financials.mother_vessel_name,
                    invoice_file: res.financials.invoice_file,
                    coa_file: res.financials.coa_file,
                    bl_file: res.financials.bl_file,
                    dsr_file: res.financials.dsr_file,

                    created_by: res.financials.created_by
                      ? res.financials.created_by
                      : null,
                    approval_status: res.financials.approval_status
                      ? res.financials.approval_status
                      : "Pending",
                    approved_by: res.financials.approved_by
                      ? res.financials.approved_by
                      : "",
                    approval_status_2: res.financials.approval_status_2
                      ? res.financials.approval_status_2
                      : "Pending",
                    coal_cost_coal_quantity: res.financials.barge_quantity
                      ? res.financials.barge_quantity
                      : "",
                    coal_cost_coal_price_pmt: res.financials.coal_price_pmt
                      ? res.financials.coal_price_pmt
                      : null,
                    coal_cost_coal_price_pmt_local_currency:
                      res.financials.coal_price_pmt &&
                      res.financials.currency_xchg_rate
                        ? coal_cost_coal_price_pmt_local_currency
                        : null,
                    coal_cost_coal_price: res.financials.coal_price
                      ? res.financials.coal_price
                      : "",
                    coal_cost_billing_currency: res.financials.billing_currency
                      ? res.financials.billing_currency
                      : "",
                    coal_cost_penality_currency: res.financials
                      .penality_currency
                      ? res.financials.penality_currency
                      : "",
                    // performa_payments_amount: performa_payments_amount,
                    // performa_advance_adjustment: performa_advance_adjustment,
                    // performa_invoices: performa_invoices,
                    coal_cost_gar_bonus: res.financials.gar_bonus_pmt
                      ? res.financials.gar_bonus_pmt
                      : null,
                    coal_cost_gar_penality: res.financials.gar_penality_pmt
                      ? res.financials.gar_penality_pmt
                      : null,
                    coal_cost_ash_penality: res.financials.ash_penality_pmt
                      ? res.financials.ash_penality_pmt
                      : null,
                    coal_cost_sulphur_penality: res.financials
                      .sulphur_penality_pmt
                      ? res.financials.sulphur_penality_pmt
                      : null,
                    coal_cost_adjusted_coal_price_pmt: res.financials
                      .adjusted_coal_price_pmt
                      ? res.financials.adjusted_coal_price_pmt
                      : "",
                    coal_cost_adjusted_coal_price: res.financials
                      .adjusted_coal_price
                      ? res.financials.adjusted_coal_price
                      : "",
                    coal_cost_currency_xchg_rate: res.financials
                      .currency_xchg_rate
                      ? res.financials.currency_xchg_rate
                      : "",
                    coal_cost_adjusted_coal_price_in_local_currency: res
                      .financials.adjusted_coal_price_in_local_currency
                      ? res.financials.adjusted_coal_price_in_local_currency
                      : "",
                    coal_cost_pph22_barge_quantity_in_mt: res.financials
                      .pph22_barge_quantity
                      ? res.financials.pph22_barge_quantity
                      : "",
                    coal_cost_pph22_coal_price_per_mt: res.financials
                      .pph22_coal_price_pmt
                      ? res.financials.pph22_coal_price_pmt
                      : "",
                    coal_cost_pph22_percent: res.financials.pph22_percent
                      ? res.financials.pph22_percent
                      : "",
                    coal_cost_pph22_percent_val: res.financials.pph22_value
                      ? res.financials.pph22_value
                      : "",
                    coal_cost_pph22_export: res.financials
                      .pph22_export_applicable
                      ? res.financials.pph22_export_applicable
                      : "No",
                    coal_cost_pph22_export_amount: res.financials
                      .pph22_export_amount
                      ? res.financials.pph22_export_amount
                      : "",
                    coal_cost_pph22_export_percent: res.financials
                      .pph22_export_percent
                      ? res.financials.pph22_export_percent
                      : "",
                    coal_cost_pph22_export_value: res.financials
                      .pph22_export_value
                      ? res.financials.pph22_export_value
                      : "",
                    freight_price_pmt: res.financials.freight_price_pmt
                      ? res.financials.freight_price_pmt
                      : "",
                    receivable_from_supplier: res.financials
                      .receivable_from_supplier
                      ? res.financials.receivable_from_supplier
                      : "",
                    selectedSalesContractId: res.financials.sales_contract_id
                      ? res.financials.sales_contract_id
                      : "",
                    selected_sc_quality: res.financials.quality
                      ? res.financials.quality
                      : "",
                    selected_sales_type: res.financials.sales_type
                      ? res.financials.sales_type
                      : "",
                    coalPricePerMT: res.financials.coal_price_pmt
                      ? res.financials.coal_price_pmt
                      : "",
                    display_adjustments: totalAdjustments,
                    advance_adjustments: res.financials.advance_adjustments,
                    vat_applicable: res.financials.vat_applicable
                      ? res.financials.vat_applicable
                      : "No",
                    vat_amount: res.financials.vat_amount,
                    vat_percent: res.financials.vat_percent
                      ? res.financials.vat_percent
                      : 10,
                    vat_value: res.financials.vat_value,
                    files: res.financials.commercial_files
                      ? res.financials.commercial_files
                      : [],
                    sales_index_linked: res.financials.sales_index_linked,

                    //isLoading: false
                  });
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        //invoices
        await api
          .get_barge_sales_fin_coal_invoices(
            loginUserID,
            idToken,
            barge_sales_financials_id,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.barge_sales_fin_coal_invoices) {
                    let commercialInvoicesData = [];
                    let commercialInvoicesTotalAmount = 0;
                    let commercialInvoicesReceivedAmount = 0;
                    for (
                      let i = 0;
                      i < res.barge_sales_fin_coal_invoices.length;
                      i++
                    ) {
                      if (
                        costing_type === "Commercial" &&
                        res.barge_sales_fin_coal_invoices[i].invoice_type ===
                          "Commercial Invoices"
                      ) {
                        commercialInvoicesData.push(
                          res.barge_sales_fin_coal_invoices[i]
                        );
                        commercialInvoicesTotalAmount += Number(
                          res.barge_sales_fin_coal_invoices[i].invoice_amount
                        );
                        for (
                          let k = 0;
                          k <
                          res.barge_sales_fin_coal_invoices[i].payments.length;
                          k++
                        ) {
                          commercialInvoicesReceivedAmount += Number(
                            res.barge_sales_fin_coal_invoices[i].payments[k]
                              .amount
                          );
                        }
                      }
                    }
                    let receivedFromBuyer = Number(
                      commercialInvoicesReceivedAmount
                    );
                    let remainingAmount =
                      Number(
                        this.state.receivable_from_supplier
                          ? this.state.receivable_from_supplier
                          : 0
                      ) -
                      Number(receivedFromBuyer) -
                      Number(
                        this.state.performa_amount
                          ? this.state.performa_amount
                          : 0
                      ) -
                      Number(
                        this.state.performa_advance_adjustments
                          ? this.state.performa_advance_adjustments
                          : 0
                      );
                    //remainingAmount = remainingAmount <= 0 ? 0 : remainingAmount;
                    this.setState(
                      {
                        receivedFromBuyer: receivedFromBuyer,
                        remainingAmount: remainingAmount,

                        commercialInvoicesData: commercialInvoicesData,
                        commercialInvoicesTotalAmount:
                          commercialInvoicesTotalAmount,
                        commercialInvoicesReceivedAmount:
                          commercialInvoicesReceivedAmount,
                        commercialInvoicesRemainingAmount:
                          Number(commercialInvoicesTotalAmount) -
                          Number(commercialInvoicesReceivedAmount),
                      },
                      () => this.callingAsync()
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    }

    document.title =
      config.documentTitle + "Barge Sales Financial Coal Costing View";
  }

  async callingAsync() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    try {
      var comboID = this.props.comboID;
      var barge_sales_financials_id = comboID.split("$#")[0];
      var business_no_id = comboID.split("$#")[1];
      var business_allocation_id = comboID.split("$#")[2];
      var bargeId = comboID.split("$#")[3];
      this.setState({ barge_sales_financials_id: barge_sales_financials_id });
    } catch (e) {}
    await api
      .getAllSalesSpecByBargeID(
        loginUserID,
        idToken,
        business_no_id,
        business_allocation_id,
        bargeId
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.scq_typical_specs) {
                this.setState({
                  sales_term: res.sales_term,
                  garActiualValue: res.scq_typical_specs
                    .qality_calorific_value_actual
                    ? res.scq_typical_specs.qality_calorific_value_actual
                    : "",
                  garTypicalValue: res.scq_typical_specs
                    .quality_calorific_value_typical
                    ? res.scq_typical_specs.quality_calorific_value_typical
                    : "",
                  ashPenality: res.scq_typical_specs.quality_ash_penality
                    ? res.scq_typical_specs.quality_ash_penality
                    : "",
                  sulphurPenality: res.scq_typical_specs
                    .quality_total_sulphur_penality
                    ? res.scq_typical_specs.quality_total_sulphur_penality
                    : "",
                  quality_calorific_value_typical:
                    res.scq_typical_specs.quality_calorific_value_typical,
                  quality_calorific_value_rejection:
                    res.scq_typical_specs.quality_calorific_value_rejection,
                  quality_calorific_value_basis:
                    res.scq_typical_specs.quality_calorific_value_basis,
                  quality_calorific_value2_typical:
                    res.scq_typical_specs.quality_calorific_value2_typical,
                  quality_calorific_value2_rejection:
                    res.scq_typical_specs.quality_calorific_value2_rejection,
                  quality_calorific_value2_basis:
                    res.scq_typical_specs.quality_calorific_value2_basis,
                  quality_calorific_value3_typical:
                    res.scq_typical_specs.quality_calorific_value3_typical,
                  quality_calorific_value3_rejection:
                    res.scq_typical_specs.quality_calorific_value3_rejection,
                  quality_calorific_value3_basis:
                    res.scq_typical_specs.quality_calorific_value3_basis,

                  bonusCap: res.scq_typical_specs
                    .quality_calorific_value_bonus_cap
                    ? res.scq_typical_specs.quality_calorific_value_bonus_cap
                    : "",
                  bonusCap2: res.scq_typical_specs
                    .quality_calorific_value2_bonus_cap
                    ? res.scq_typical_specs.quality_calorific_value2_bonus_cap
                    : "",
                  bonusCap3: res.scq_typical_specs
                    .quality_calorific_value3_bonus_cap
                    ? res.scq_typical_specs.quality_calorific_value3_bonus_cap
                    : "",
                  penalityCap: res.scq_typical_specs
                    .quality_calorific_value_penality_cap
                    ? res.scq_typical_specs.quality_calorific_value_penality_cap
                    : "",
                  penalityCap2: res.scq_typical_specs
                    .quality_calorific_value2_penality_cap
                    ? res.scq_typical_specs
                        .quality_calorific_value2_penality_cap
                    : "",
                  penalityCap3: res.scq_typical_specs
                    .quality_calorific_value3_penality_cap
                    ? res.scq_typical_specs
                        .quality_calorific_value3_penality_cap
                    : "",

                  quality_ash_typical:
                    res.scq_typical_specs.quality_ash_typical,
                  quality_ash_rejection:
                    res.scq_typical_specs.quality_ash_rejection,
                  quality_ash_basis: res.scq_typical_specs.quality_ash_basis,

                  quality_total_sulphur_typical:
                    res.scq_typical_specs.quality_total_sulphur_typical,
                  quality_total_sulphur_rejection:
                    res.scq_typical_specs.quality_total_sulphur_rejection,
                  quality_total_sulphur_basis:
                    res.scq_typical_specs.quality_total_sulphur_basis,
                });
              } else {
                //alert('Unexpected error occured. Please contact administrator.');
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api
      .get_barge_sales_fin_quality_results(
        loginUserID,
        idToken,
        business_no_id,
        business_allocation_id,
        bargeId
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_sales_qualities) {
                this.setState({
                  barge_pur_quality_id: res.barge_sales_qualities.id,
                  quality_calorific_value_actual:
                    res.barge_sales_qualities.quality_calorific_value_actual,
                  quality_calorific_value2_actual:
                    res.barge_sales_qualities.quality_calorific_value2_actual,
                  quality_calorific_value3_actual:
                    res.barge_sales_qualities.quality_calorific_value3_actual,
                  quality_ash_actual:
                    res.barge_sales_qualities.quality_ash_actual,
                  quality_total_sulphur_actual:
                    res.barge_sales_qualities.quality_total_sulphur_actual,
                });
              } else {
                //alert('Unexpected error occured. Please contact administrator.');
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    // Calling Get Business Number Operation Barge Details API
    await api
      .get_business_no_barge(
        bargeId,
        business_allocation_id,
        business_no_id,
        loginUserID,
        idToken
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              //var barge_quantity = '';
              if (res.business_no_barge) {
                //barge_quantity = res.business_no_barge.barge_quantity_in_mt;
                //Get purchase Coal Price.
                var sales_type = "";
                api
                  .getCoalPriceByBargeID(
                    loginUserID,
                    idToken,
                    bargeId,
                    business_allocation_id
                  )
                  .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                      response.json().then((res) => {
                        if (res.code === "200") {
                          sales_type = res.sales_type;
                          var garPenality = "";
                          var garBonus = "";
                          var ashPenality = "";
                          var sulphurPenality = "";

                          let garActiualValue = this.state.garActiualValue;
                          let bonusCap = this.state.bonusCap;
                          let penalityCap = this.state.penalityCap;

                          let coal_cost_coal_price_pmt = res.sales_price_pmt;
                          if (res.sales_index_linked === "Yes") {
                            coal_cost_coal_price_pmt =
                              this.state.coal_cost_coal_price_pmt;
                          }

                          if (
                            this.state.sales_term === "Specs" &&
                            this.state.costing_type === "Commercial"
                          ) {
                            if (
                              garActiualValue !== null &&
                              this.state.garTypicalValue !== null &&
                              garActiualValue !== "" &&
                              this.state.garTypicalValue !== ""
                            ) {
                              if (
                                Number(garActiualValue) >
                                Number(this.state.garTypicalValue)
                              ) {
                                if (bonusCap !== null && bonusCap !== "") {
                                  if (
                                    Number(garActiualValue) > Number(bonusCap)
                                  ) {
                                    garActiualValue = bonusCap;
                                  }
                                }
                              } else {
                                if (
                                  penalityCap !== null &&
                                  penalityCap !== ""
                                ) {
                                  if (
                                    Number(garActiualValue) <
                                    Number(penalityCap)
                                  ) {
                                    garActiualValue = penalityCap;
                                  }
                                }
                              }
                              if (
                                Number(this.state.garActiualValue) >
                                Number(this.state.garTypicalValue)
                              ) {
                                garBonus =
                                  ((Number(garActiualValue) -
                                    Number(this.state.garTypicalValue)) /
                                    Number(this.state.garTypicalValue)) *
                                  Number(coal_cost_coal_price_pmt);
                              }
                              if (
                                Number(this.state.garTypicalValue) >
                                Number(this.state.garActiualValue)
                              ) {
                                garPenality =
                                  ((Number(this.state.garTypicalValue) -
                                    Number(garActiualValue)) /
                                    Number(this.state.garTypicalValue)) *
                                  Number(coal_cost_coal_price_pmt);
                              }
                            }
                            if (
                              this.state.quality_ash_actual !== null &&
                              this.state.quality_ash_actual !== "" &&
                              this.state.quality_ash_typical !== null &&
                              this.state.quality_ash_typical !== ""
                            ) {
                              if (
                                Number(this.state.quality_ash_actual) >
                                  Number(this.state.quality_ash_typical) &&
                                this.state.ashPenality !== null &&
                                this.state.ashPenality !== ""
                              ) {
                                ashPenality =
                                  Number(this.state.ashPenality) *
                                  ((Number(this.state.quality_ash_actual) -
                                    Number(this.state.quality_ash_typical)) /
                                    1);
                              }
                            }
                            if (
                              this.state.quality_total_sulphur_actual !==
                                null &&
                              this.state.quality_total_sulphur_actual !== "" &&
                              this.state.quality_total_sulphur_typical !==
                                null &&
                              this.state.quality_total_sulphur_typical !== ""
                            ) {
                              if (
                                Number(
                                  this.state.quality_total_sulphur_actual
                                ) >
                                  Number(
                                    this.state.quality_total_sulphur_typical
                                  ) &&
                                this.state.sulphurPenality !== null &&
                                this.state.sulphurPenality !== ""
                              ) {
                                sulphurPenality =
                                  Number(this.state.sulphurPenality) *
                                  ((Number(
                                    this.state.quality_total_sulphur_actual
                                  ) -
                                    Number(
                                      this.state.quality_total_sulphur_typical
                                    )) /
                                    0.1);
                              }
                            }
                          }

                          var coal_cost_gar_bonus_new =
                            this.state.coal_cost_gar_bonus;
                          var coal_cost_gar_penality_new =
                            this.state.coal_cost_gar_penality;
                          var coal_cost_ash_penality_new =
                            this.state.coal_cost_ash_penality;
                          var coal_cost_sulphur_penality_new =
                            this.state.coal_cost_sulphur_penality;
                          if (
                            garBonus !== "" &&
                            (this.state.coal_cost_gar_bonus === null ||
                              this.state.coal_cost_gar_bonus === "")
                          ) {
                            coal_cost_gar_bonus_new = garBonus.toFixed(2);
                          }
                          if (
                            garPenality !== "" &&
                            (this.state.coal_cost_gar_penality === null ||
                              this.state.coal_cost_gar_penality === "")
                          ) {
                            coal_cost_gar_penality_new = garPenality.toFixed(2);
                          }
                          if (
                            ashPenality !== "" &&
                            (this.state.coal_cost_ash_penality === null ||
                              this.state.coal_cost_ash_penality === "")
                          ) {
                            coal_cost_ash_penality_new = ashPenality.toFixed(2);
                          }
                          if (
                            sulphurPenality !== "" &&
                            (this.state.coal_cost_sulphur_penality === null ||
                              this.state.coal_cost_sulphur_penality === "")
                          ) {
                            coal_cost_sulphur_penality_new =
                              sulphurPenality.toFixed(2);
                          }

                          let displayQualityResAlert = false;
                          if (
                            this.state.coal_cost_gar_bonus !== null &&
                            this.state.coal_cost_gar_penality !== null &&
                            this.state.coal_cost_ash_penality !== null &&
                            this.state.coal_cost_sulphur_penality !== null &&
                            (this.state.garActiualValue !== null ||
                              this.state.quality_ash_actual !== null ||
                              this.state.quality_total_sulphur_actual !==
                                null) &&
                            (coal_cost_gar_bonus_new !== "" ||
                              coal_cost_gar_penality_new !== "" ||
                              coal_cost_ash_penality_new !== "" ||
                              coal_cost_sulphur_penality_new !== "")
                          ) {
                            displayQualityResAlert = true;
                          }

                          this.setState({
                            coalPriceCurrency: res.sales_currency,
                            coalPricePerMT: Number(
                              coal_cost_coal_price_pmt
                            ).toFixed(2),
                            displayQualityResAlert: displayQualityResAlert,
                          });
                        }
                      });
                    }
                  });

                var row = this.state.advance_adjustments;

                for (var i in row) {
                  row[i].sales_contract_id =
                    res.business_no_barge.sales_contract_id;
                  row[i].sales_contract_no =
                    res.business_no_barge.sales_contract_no;
                  row[i].total_customer_adv_payment =
                    res.business_no_barge.total_customer_adv_payment;
                }

                var redraftQuantity = res.business_no_barge.barge_quantity_in_mt
                  ? res.business_no_barge.barge_quantity_in_mt
                  : "";
                if (
                  sales_type === "FAS Anchorage" &&
                  res.business_no_barge.redraft_quantity !== null
                ) {
                  redraftQuantity = res.business_no_barge.redraft_quantity;
                }
                this.setState({
                  advance_adjustments: row,
                  redraftQuantity: redraftQuantity, //res.business_no_barge.redraft_quantity ? res.business_no_barge.redraft_quantity:'',
                  advance_adjustment_value: Number(redraftQuantity).toFixed(2), //res.business_no_barge.redraft_quantity ? res.business_no_barge.redraft_quantity:'',

                  businessNoID: res.business_no_barge.business_no_id,
                  sales_contract_no: res.business_no_barge.sales_contract_no,
                  total_customer_adv_payment: res.business_no_barge
                    .total_customer_adv_payment
                    ? res.business_no_barge.total_customer_adv_payment
                    : "",
                  remaining_cus_adv_payment: Number(
                    res.business_no_barge.remaining_cus_adv_payment
                      ? res.business_no_barge.remaining_cus_adv_payment
                      : 0
                  ),

                  barge_quantity: res.business_no_barge.barge_quantity_in_mt,

                  sales_type: res.business_no_barge.sales_type,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  addBargePurchasePaymentPostingHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      date_of_payment,
      amount,
      invoice_no,
      description,
      invoiceID,
      invoiceNumber,
      performa_amount,
      performa_advance_adjustments,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    //var status = "Active";
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoiceNumber === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }

    // Coal Costing payments
    var remainingAmount =
      Number(this.state.receivable_from_supplier) -
      Number(this.state.totalCoalPaymentsAmount) -
      Number(performa_amount) -
      Number(performa_advance_adjustments);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.receivable_from_supplier) -
        Number(this.state.totalCoalPaymentsAmount) -
        Number(performa_amount) -
        Number(performa_advance_adjustments) +
        Number(this.state.existingAmount);
    }
    if (Number(amount) > Number(remainingAmount)) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount Is Exceeding Receivable from Buyer",
      });
      return;
    }
    invoice_no = invoiceNumber;
    const payment_posting_type = "Coal";
    api
      .add_barge_sales_financials_coal_payment(
        loginUserID,
        idToken,
        invoiceID,
        invoice_no,
        date_of_payment,
        amount,
        payment_posting_type,
        description
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  paymentTitle: "",
                  invoiceID: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                  snackBarSuccessOpen: true,
                  succesMsg: res.message,
                },
                () => {
                  window.$("#bargeSalesPaymentPostingModal").modal("hide");
                  this.componentDidMount();
                }
              );
              // window.$("#bargeSalesPaymentPostingModal").modal({
              //   backdrop: 'static',
              //   keyboard: true,
              //   show: false
              // });
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                submitting: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  getBargePurchasePaymentPosting = (e) => {
    if (e) {
      var paymentTitle = "";
      if (e.payment_posting_type === "Coal") {
        paymentTitle = "Update Coal";
      }

      this.setState({
        existingAmount: e.amount,
        payment_posting_id: e.id,
        paymentTitle: paymentTitle,
        payment_posting_type: e.payment_posting_type,
        invoice_id: e.invoice_id,
        barge_sales_financials_id: e.barge_sales_financials_id,
        amount: e.amount,
        date_of_payment: e.date_of_payment,
        invoiceNumber: e.invoice_no ? e.invoice_no : "",
        invoice_no: e.invoice_no ? e.invoice_no : "",
        description: e.description ? e.description : "",
        date_of_paymentError: false,
        amountError: false,
        paymentErrorMsg: "",
        paymentMode: "Update",
      });
      window.$("#bargeSalesPaymentPostingModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  deleteBargeSalesPaymentPosting = (e) => {
    let paymentData = this.state.deleteData;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const invoice_id = paymentData.invoice_id;
    var payment_posting_id = paymentData.payments[0].id;
    var payment_posting_type = paymentData.payments[0].payment_posting_type;
    var amount = paymentData.payments[0].amount;
    var date_of_payment = paymentData.payments[0].date_of_payment;
    var invoice_no = paymentData.payments[0].invoice_no
      ? paymentData.payments[0].invoice_no
      : "";
    var description = paymentData.payments[0].description
      ? paymentData.payments[0].description
      : "";
    var status = "Deleted";
    api
      .update_barge_sales_financials_coal_payment(
        loginUserID,
        idToken,
        invoice_id,
        payment_posting_id,
        invoice_no,
        date_of_payment,
        amount,
        payment_posting_type,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              window.$("#deletePaymentPostingModal").modal("hide");
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  UpdateBargePurchasePaymentPostingHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      invoice_id,
      payment_posting_type,
      date_of_payment,
      amount,
      performa_amount,
      performa_advance_adjustments,
      invoice_no,
      description,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var payment_id = this.state.payment_posting_id;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }
    // Coal Costing payments
    var remainingAmount = 0;
    if (this.state.payment_posting_type === "Coal") {
      remainingAmount =
        Number(this.state.receivable_from_supplier) -
        Number(this.state.totalCoalPaymentsAmount) -
        Number(performa_amount) -
        Number(performa_advance_adjustments);
      if (this.state.paymentMode === "Update") {
        remainingAmount =
          Number(this.state.receivable_from_supplier) -
          Number(this.state.totalCoalPaymentsAmount) -
          Number(performa_amount) -
          Number(performa_advance_adjustments) +
          Number(this.state.existingAmount);
      }
      if (Number(amount) > Number(remainingAmount)) {
        this.setState({
          amountError: true,
          submitting: false,
          paymentErrorMsg: "Amount Is Exceeding Receivable from Buyer",
        });
        return;
      }
    }

    api
      .update_barge_sales_financials_coal_payment(
        loginUserID,
        idToken,
        invoice_id,
        payment_id,
        invoice_no,
        date_of_payment,
        amount,
        payment_posting_type,
        description
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  paymentTitle: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  window.$("#bargeSalesPaymentPostingModal").modal("hide");
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                submitting: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  // add invoics api
  addInvoiesHandler = () => {
    var {
      barge_sales_financials_id,
      payment_invoice_date,
      invoice_amount,
      invoice_percent,
      invoice_type,
      remarks,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var invoice_number = this.state.invoice_number
      ? this.state.invoice_number
      : "";
    if (payment_invoice_date === null) {
      this.setState({
        invoice_dateError: true,
      });
    }
    if (invoice_amount === "") {
      this.setState({
        invoice_amountError: true,
      });
    }
    if (invoice_number === "") {
      this.setState({
        invoice_numberError: true,
      });
    }
    const payment_posting_type = "Coal";
    invoice_type = "Commercial Invoices";
    if (
      payment_invoice_date !== null &&
      invoice_amount !== "" &&
      invoice_number !== ""
    ) {
      api
        .add_barge_sales_fin_coal_invoice(
          loginUserID,
          idToken,
          barge_sales_financials_id,
          invoice_number,
          payment_invoice_date,
          invoice_amount,
          invoice_type,
          payment_posting_type,
          invoice_percent,
          remarks
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    invoice_no: "",
                    invoice_amount: "",
                    invoice_percent: "",
                    invoice_type: "",
                    payment_invoice_date: null,
                    remarks: "",
                    snackBarSuccessOpen: true,
                    succesMsg: res.message,
                  },
                  () => {
                    window.$("#invoicesPostingModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getInvoiceData = (e) => {
    if (e) {
      this.setState({
        barge_sales_financials_id: e.barge_sales_financials_id,
        invoice_id: e.invoice_id,
        invoice_number: e.invoice_no,
        invoice_amount: e.invoice_amount,
        invoice_percent: e.invoice_percent,
        invoice_type: e.invoice_type,
        payment_invoice_date: e.invoice_date,
        remarks: e.remarks,
        invoiceMode: "Update",
        invoiceFormTitle: "Update",
      });
      window.$("#invoicesPostingModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  updateInvoicesHandler = () => {
    var {
      barge_sales_financials_id,
      invoice_id,
      invoice_type,
      payment_invoice_date,
      payment_posting_type,
      invoice_amount,
      invoice_percent,
      remarks,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var invoice_number = this.state.invoice_number
      ? this.state.invoice_number
      : "";
    if (payment_invoice_date === null) {
      this.setState({
        invoice_dateError: true,
      });
    }
    if (invoice_amount === "") {
      this.setState({
        invoice_amountError: true,
      });
    }
    if (invoice_number === "") {
      this.setState({
        invoice_numberError: true,
      });
    }
    if (
      payment_invoice_date !== null &&
      invoice_amount !== "" &&
      invoice_number !== ""
    ) {
      api
        .update_barge_sales_fin_coal_invoice(
          loginUserID,
          idToken,
          barge_sales_financials_id,
          invoice_id,
          invoice_number,
          payment_invoice_date,
          invoice_amount,
          invoice_type,
          payment_posting_type,
          invoice_percent,
          remarks
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    invoice_no: "",
                    invoice_amount: "",
                    invoice_percent: "",
                    invoice_type: "",
                    payment_invoice_date: null,
                    remarks: "",
                    snackBarSuccessOpen: true,
                    succesMsg: res.message,
                  },
                  () => {
                    window.$("#invoicesPostingModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  deleteInvoicePosting = (e) => {
    if (e) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const barge_sales_financials_id = e.barge_sales_financials_id;
      const invoice_id = e.invoice_id;
      const invoice_no = e.invoice_no ? e.invoice_no : "";
      const invoice_date = e.invoice_date;
      const invoice_amount = e.invoice_amount;
      const invoice_type = e.invoice_type;
      const invoice_percent = e.invoice_percent;
      const payment_posting_type = e.payment_posting_type;
      const remarks = e.remarks ? e.remarks : "";
      const status = "Deleted";
      api
        .update_barge_sales_fin_coal_invoice(
          loginUserID,
          idToken,
          barge_sales_financials_id,
          invoice_id,
          invoice_no,
          invoice_date,
          invoice_amount,
          invoice_type,
          payment_posting_type,
          invoice_percent,
          remarks,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    invoice_no: "",
                    invoice_amount: "",
                    payment_invoice_date: null,
                    remarks: "",
                    snackBarSuccessOpen: true,
                    succesMsg: res.message,
                  },
                  () => {
                    window.$("#deleteInvoicePostingModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let comboID = this.props.comboID;
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: comboID.split("$#")[0],
      costing_type: "sales_commercial_coal_costing_data",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  qualityResultsCalculations = (type) => {
    if (
      this.state.sales_term === "Specs" &&
      this.state.costing_type === "Commercial"
    ) {
      var garPenality = "";
      var garBonus = "";
      var ashPenality = "";
      var sulphurPenality = "";
      let {
        garActiualValue,
        garTypicalValue,
        bonusCap,
        penalityCap,
        coal_cost_coal_price_pmt,
      } = this.state;
      if (
        garActiualValue !== null &&
        garTypicalValue !== null &&
        garActiualValue !== "" &&
        garTypicalValue !== ""
      ) {
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        }
        if (Number(garActiualValue) < Number(garTypicalValue)) {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }
        if (Number(garActiualValue) > Number(garTypicalValue)) {
          garBonus =
            ((Number(garActiualValue) - Number(garTypicalValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);

          if (type === "GAR") {
            return `${garActiualValue} - ${garTypicalValue} / ${garTypicalValue} * ${parseFloat(
              coal_cost_coal_price_pmt
            )}   =  ${parseFloat(garBonus).toFixed(2)}`;
          }
        }
        if (Number(garTypicalValue) > Number(garActiualValue)) {
          garPenality =
            ((Number(garTypicalValue) - Number(garActiualValue)) /
              Number(garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
          if (type === "GAR") {
            return `${garTypicalValue} - ${garActiualValue} / ${garTypicalValue} * ${coal_cost_coal_price_pmt}   =  ${parseFloat(
              garPenality
            ).toFixed(2)}`;
          }
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
            Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          ashPenality =
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
          if (type === "Ash") {
            return `${this.state.ashPenality} * ${parseFloat(
              this.state.quality_ash_actual
            )} - ${parseFloat(
              this.state.quality_ash_typical
            )} / 1    =  ${parseFloat(ashPenality).toFixed(2)}`;
          }
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
            Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
          if (type === "Sulphur") {
            return `${this.state.sulphurPenality} * ${parseFloat(
              this.state.quality_total_sulphur_actual
            )} - ${parseFloat(
              this.state.quality_total_sulphur_typical
            )} / 0.1  =  ${parseFloat(sulphurPenality).toFixed(2)}`;
          }
        }
      }
    }
  };

  payable_to_vendor = () => {
    // $payable = $adjusted_coal_price_in_local_currency + $vat_value - $pph22_value - $pph22_export_value - $comm_adv_adj_value - $performa_amount - $performa_adv_adj_value;
    const {
      coal_cost_adjusted_coal_price_in_local_currency = 0,
      vat_value = 0,
      pph22_value = 0,
      pph22_export_value = 0,
      total_advance_adjustment_value = 0,
      performa_amount = 0,
      performa_advance_adjustments = 0,
    } = this.state;

    return (
      parseFloat(coal_cost_adjusted_coal_price_in_local_currency) +
      parseFloat(vat_value) -
      parseFloat(pph22_value) -
      parseFloat(pph22_export_value) -
      parseFloat(total_advance_adjustment_value) -
      parseFloat(performa_amount) -
      parseFloat(performa_advance_adjustments)
    );
  };

  renderInvoiceLink = () => {
    const {
      costing_no,
      invoiceDetails: { invoice_no = "", invoice_date = "" } = {},
      coal_cost_coal_quantity,
      // coal_cost_coal_price,
      coal_cost_adjusted_coal_price_in_local_currency = 0,
      // paid_to_supplier = 0,
      advance_adjustments,
      coal_cost_currency_xchg_rate = 1,
      // coal_cost_adjusted_coal_price,
      coal_cost_adjusted_coal_price_pmt,
      // coal_index = {},
      vendor_name = "",
      mv_name = "",
      purchase_type = "FOB Barge",
      sales_index_linked = "No",
      freight_price_pmt = null,
      sales_type,
    } = this.state;

    const filter_advance = advance_adjustments.filter(
      (e) => e.advance_adjustment !== "No"
    );

    const advanceAdjustments = [];
    for (var i of filter_advance) {
      advanceAdjustments.push({
        desc: `Adv deduction against contract: ${this.state.sales_contract_no}`,
        qty: `${toLocaleString(
          i.advance_adjustment_barge_quantity
            ? i.advance_adjustment_barge_quantity
            : " "
        )}`,
        adjustedPrice: `${toLocaleString(
          i.adjustment_per_mt ? i.adjustment_per_mt : " "
        )}`,
        amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
      });
    }
    // const Grand_total = this.payable_to_vendor();
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: "Sales Commercial",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(this.state.receivable_from_supplier),
      purchase_type: purchase_type,
      purchase_index_linked: sales_index_linked,
      description: [
        {
          qty: toLocaleString(coal_cost_coal_quantity),
          adjustedPrice: toLocaleString(coal_cost_adjusted_coal_price_pmt),
          desc: `Coal price ${
            this.props.costing_type === "Performa"
              ? `@ ${toLocaleString(this.state.invoice_percentage)} %`
              : ""
          }`,
          amount: toLocaleString(
            Number(coal_cost_adjusted_coal_price_in_local_currency)
          ),
        },
        ...(coal_cost_currency_xchg_rate > 1
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Exchange Rate @ ${parseFloat(
                  coal_cost_currency_xchg_rate
                ).toFixed(2)}  ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.purchase_currency !==
        this.state.coal_cost_billing_currency
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Billing Currency ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.performa_advance_adjustments
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Performa advance adjustments",
                amount: `( ${toLocaleString(
                  this.state.performa_advance_adjustments
                )} )`,
              },
            ]
          : []),
        ...(this.state.performa_amount
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Received against performa",
                amount: `( ${toLocaleString(this.state.performa_amount)} )`,
              },
            ]
          : []),
        ...(freight_price_pmt && sales_type === "CIF DP"
          ? [
              {
                qty: toLocaleString(coal_cost_coal_quantity),
                adjustedPrice: toLocaleString(freight_price_pmt),
                desc: "Freight Price PMT",
                amount: ` ${toLocaleString(this.totalFreight())}`,
              },
            ]
          : []),
        ...advanceAdjustments,
        ...(this.state.vat_applicable === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.vat_value)}`,
              },
            ]
          : []),
        ...(this.state.coal_cost_pph22_export === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Export",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_export_value
                )} )`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) &&
        Number(this.state.coal_cost_pph22_percent_val) !== 0 &&
        this.state.coal_cost_pph22_percent
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Local",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_percent_val
                )} )`,
              },
            ]
          : []),
      ],
      // coalIndexes: {
      //   ...coal_index,
      //   show:
      //     this.state.purchase_index_linked === "Yes" &&
      //     this.state.costing_type !== "Performa",
      //   coal_index: this.getCoalIndexes(),
      // },

      qualityResults:
        this.state.sales_term === "Specs" &&
        this.state.costing_type === "Commercial"
          ? [
              {
                type: "GAR",
                units: "kcal/kg",
                basis: this.state.quality_calorific_value_basis,
                typical: this.state.quality_calorific_value_typical,
                rejection: this.state.quality_calorific_value_rejection,
                actual: this.state.quality_calorific_value_actual,
                bouns: this.state.bonusCap,
                penality: this.state.penalityCap,
                desc: this.qualityResultsCalculations("GAR"),
                // desc: `${
                //   this.state.coal_cost_gar_penality
                //     ? `- ${toLocaleString(this.state.coal_cost_gar_penality)}`
                //     : `+ ${toLocaleString(this.state.coal_cost_gar_bonus)}`
                // }`,
              },
              {
                type: "Ash",
                units: "kcal/kg",
                basis: this.state.quality_ash_basis,
                typical: this.state.quality_ash_typical,
                rejection: this.state.quality_ash_rejection,
                actual: this.state.quality_ash_actual,
                bouns: " ",
                penality: " ",
                desc: this.qualityResultsCalculations("Ash"),
                // desc: toLocaleString(this.state.coal_cost_ash_penality),
              },
              {
                type: "Sulphur",
                units: "kcal/kg",
                basis: this.state.quality_total_sulphur_basis,
                typical: this.state.quality_total_sulphur_typical,
                rejection: this.state.quality_total_sulphur_rejection,
                actual: this.state.quality_total_sulphur_actual,
                bouns: " ",
                penality: " ",
                desc: this.qualityResultsCalculations("Sulphur"),
                // desc: toLocaleString(this.state.coal_cost_sulphur_penality),
              },
            ]
          : null,
    };

    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_sales_fin_coal_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  totalFreight = () => {
    const { coal_cost_coal_quantity, currency_xchg_rate, freight_price_pmt } =
      this.state;

    let exchg_rate = currency_xchg_rate ? currency_xchg_rate : 1;
    let totalFreight = 0;
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      exchg_rate = 1 / Number(currency_xchg_rate ? currency_xchg_rate : 1);
    }
    totalFreight = (
      parseFloat(coal_cost_coal_quantity ? coal_cost_coal_quantity : 1) *
      parseFloat(freight_price_pmt ? freight_price_pmt : 1) *
      parseFloat(exchg_rate)
    ).toFixed(2);

    return totalFreight;
  };

  render() {
    return (
      <div>
        {this.state.isLoading && <Loader />}

        {!this.state.isLoading && (
          <div className="content-header">
            <div className="container p-0" style={{ minHeight: "100vh" }}>
              <div className="clearfix content-header border-bottom">
                <div className="row">
                  <div className="col-lg-8 text-left pl-0">
                    <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                      {`Sales Commerical Coal Costing - ${this.state.costing_no}`}
                      <span
                        className={
                          this.state.approval_status === "Pending"
                            ? "type_2"
                            : "type_1"
                        }
                        style={{ padding: 8, marginLeft: 16 }}
                      >
                        {this.state.approval_status_2}
                      </span>
                    </h4>
                  </div>
                  <div className="col-lg-1 pl-0">
                    {this.renderInvoiceLink()}
                  </div>

                  {this.state.approval_status === "Pending" &&
                    this.state.invoice_file &&
                    this.Cookie.getCookie("loginUserId") !==
                      this.state.created_by &&
                    this.state.approved_by !==
                      this.Cookie.getCookie("loginUserId") &&
                    (this.state.features.includes("404") ||
                      this.state.features.includes("455") ||
                      this.state.features.includes("97")) && (
                      <div
                        className="col text-right float-sm-left"
                        style={{ padding: "5px 20px" }}
                      >
                        <button
                          className="header_button header_button_text add_button_adjustment"
                          style={{ width: "auto", marginTop: "17px" }}
                          onClick={this.approveHandler}
                        >
                          Approve
                        </button>
                      </div>
                    )}
                </div>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarErrorOpen: false })}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMsg}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={100000}
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarSuccessOpen: false })}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.succesMsg}
                </Alert>
              </Snackbar>
              <div className="drawer-section">
                <div className="card p-2">
                  {this.state.salesBargeList.map((p, index) => (
                    <div
                      className={
                        p.new_barges.length > 0
                          ? "shadow-sm p-0 mb-2 bg-white border rounded"
                          : ""
                      }
                      key={index}
                      style={{
                        display:
                          p.sales_contract_id ===
                            this.state.selectedSalesContractId &&
                          p.quality === this.state.selected_sc_quality &&
                          p.sales_type === this.state.selected_sales_type
                            ? "show"
                            : "none",
                      }}
                    >
                      {p.new_barges.length > 0 && (
                        <h5 className="mb-0 p-2 section_header bg-light">
                          {p.sales_contract_no} -{" "}
                          {p.quality ? "(" + p.quality + ")" : ""} -{" "}
                          {p.sales_type} - {p.buyer_name}
                        </h5>
                      )}
                      {p.new_barges.length > 0 && (
                        <div className="row">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0 table-sm">
                              <thead className="table-header">
                                <tr>
                                  <th nowrap="true"> Barge ID </th>
                                  <th nowrap="true"> Allocation ID </th>
                                  <th nowrap="true"> Barge Nomination </th>
                                  <th nowrap="true"> Quantity</th>
                                  <th nowrap="true"> Price PMT </th>
                                  <th nowrap="true"> Coal Price </th>
                                  <th nowrap="true"> VAT </th>
                                  <th nowrap="true"> PPH22 </th>
                                  <th nowrap="true"> Advance Adjustement</th>
                                  <th nowrap="true"> Total Receivable </th>
                                  <th nowrap="true"> Received </th>
                                  <th nowrap="true"> Remaining </th>
                                </tr>
                              </thead>
                              {p.new_barges.map((id, idx) => (
                                <tbody key={idx}>
                                  {id.new_barges.map((idr, indx) => (
                                    <tr
                                      key={indx}
                                      style={{
                                        display:
                                          this.state
                                            .barge_sales_financials_id ===
                                          idr.com_sales_fin_id
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <td className="table_td text-left">
                                        {pad(idr.business_no_barge_id)}
                                      </td>
                                      <td className="table_td text-left">
                                        {idr.display_allocation_id}
                                      </td>
                                      <td nowrap="true">
                                        {idr.barge_nomination}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(
                                          idr.coal_costing_quantity
                                        )}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(
                                          idr.adjusted_coal_price_pmt
                                        )}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(idr.coal_price)}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(idr.vat_value)}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(idr.pph_value)}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(
                                          idr.adv_adjustments
                                        )}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(idr.total_payable)}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(idr.paid)}
                                      </td>
                                      <td nowrap="true">
                                        {this.toLocaleString(
                                          idr.remaining_amount
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {/* {this.state.sales_index_linked === "Yes" && (
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <h5
                        className="mb-0 p-2 section_header"
                        style={{ fontSize: 14 }}
                      >
                        Coal Price Index
                      </h5>
                      <ViewCoalPriceIndexes
                        coal_index={this.state.coal_index}
                      />
                    </div>
                  )} */}

                  {/*=============================================
                    =                 Coal Costing Section                  =
                    =============================================*/}
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Coal Price
                              {this.state.coal_cost_currency_xchg_rate > 1 && (
                                <>
                                  <div>
                                    Coal Price Per MT @
                                    {parseFloat(
                                      this.state.coal_cost_coal_price_pmt
                                    ).toFixed(2)}
                                  </div>
                                  <div>
                                    Billing Currency{" "}
                                    {this.state.coal_cost_billing_currency}
                                  </div>
                                  {this.state.purchase_currency !==
                                    this.state.coal_cost_billing_currency && (
                                    <div>
                                      Exchange Rate @{" "}
                                      {parseFloat(
                                        this.state.coal_cost_currency_xchg_rate
                                      ).toFixed(2)}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="text-right">
                              {this.state.coal_cost_coal_quantity
                                ? toLocaleString(
                                    this.state.coal_cost_coal_quantity
                                  )
                                : ""}
                            </td>
                            <td className="text-right">
                              {`${toLocaleString(
                                parseFloat(
                                  this.state.coal_cost_adjusted_coal_price_pmt
                                )
                              )} ${this.state.coalPriceCurrency}`}
                            </td>
                            <td className="text-right">
                              {parseFloat(
                                this.state.coal_cost_adjusted_coal_price
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan={4}>
                              Advance Adjustments &nbsp;
                              <i
                                className="fa fa-plus-circle"
                                style={{
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.setState({ openAdvAdjustments: true });
                                }}
                              />
                            </th>
                          </tr>

                          {this.state.advance_adjustments
                            ?.filter((e) => e.advance_adjustment !== "No")
                            .map((item, idx) => (
                              <tr key={item.id}>
                                <td className="">
                                  {`Adv deduction against contract:
                               ${this.state.sales_contract_no}`}
                                  &nbsp;
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState((prevState) => ({
                                        openAdvAdjustments:
                                          !prevState.openAdvAdjustments,
                                      }))
                                    }
                                  />
                                </td>
                                <td className="text-right ">
                                  {item.advance_adjustment !== "Lumpsum"
                                    ? toLocaleString(
                                        item.advance_adjustment_barge_quantity
                                      )
                                    : ""}
                                </td>
                                <td className="text-right ">
                                  {item.advance_adjustment !== "Lumpsum"
                                    ? toLocaleString(item.adjustment_per_mt)
                                    : ""}
                                </td>
                                <td className="text-right ">
                                  {`( ${toLocaleString(
                                    item.advance_adjustment_value
                                  )} )`}
                                </td>
                              </tr>
                            ))}
                          {(config.company !== "SRPL" ||
                            config.company !== "PRPL" ||
                            config.company !== "AVS") &&
                            this.state.vat_value !== 0 && (
                              <tr>
                                <td>VAT @ 10 %</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  {toLocaleString(this.state.vat_value)}
                                </td>
                              </tr>
                            )}
                          {this.state.coal_cost_pph22_export === "Yes" && (
                            <tr>
                              <td>
                                PPH22 Export @{" "}
                                {toLocaleString(
                                  this.state.coal_cost_pph22_export_percent
                                )}{" "}
                                %
                              </td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                (
                                {Number(
                                  this.state.coal_cost_pph22_export_value
                                ).toFixed(2)}
                                )
                              </td>
                            </tr>
                          )}
                          {this.state.freight_price_pmt &&
                            this.state.sales_type === "CIF DP" && (
                              <tr>
                                <td>Freight Price PMT</td>
                                <td className="text-right">
                                  {toLocaleString(
                                    this.state.coal_cost_coal_quantity
                                  )}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(this.state.freight_price_pmt)}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(this.totalFreight())}
                                </td>
                              </tr>
                            )}
                          {!(
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                          ) &&
                            Number(this.state.coal_cost_pph22_percent_val) !==
                              0 &&
                            this.state.coal_cost_pph22_percent && (
                              <tr>
                                <td>
                                  PPH22 Local @{" "}
                                  {toLocaleString(
                                    this.state.coal_cost_pph22_percent
                                  )}
                                </td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  (
                                  {Number(
                                    this.state.coal_cost_pph22_percent_val
                                  ).toFixed(2)}
                                  )
                                </td>
                              </tr>
                            )}
                          {Number(this.state.performa_advance_adjustments) !==
                            0 && (
                            <tr>
                              <td>Performa Advance Adjustments</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                (
                                {Number(
                                  this.state.performa_advance_adjustments
                                ).toFixed(2)}
                                )
                              </td>
                            </tr>
                          )}
                          {Number(this.state.performa_amount) !== 0 && (
                            <tr>
                              <td>Received Against Performa</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                ({Number(this.state.performa_amount).toFixed(2)}
                                )
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td
                              colSpan={3}
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              Receivable From Buyer :{" "}
                            </td>
                            <td
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              {` ${
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                              } `}
                              {toLocaleString(
                                this.state.receivable_from_supplier
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              Received From Buyer :
                            </td>
                            <td
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              {` ${
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                              } `}
                              {toLocaleString(this.state.receivedFromBuyer)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              Remaining Amount :{" "}
                            </td>
                            <td
                              className="text-right "
                              style={{ fontWeight: "bold" }}
                            >
                              {` ${
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                              } `}
                              {/* {toLocaleString(this.payable_to_vendor())} */}
                              {toLocaleString(this.state.remainingAmount)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {this.state.sales_term === "Specs" &&
                      this.state.costing_type === "Commercial" && (
                        <div className="table-responsive p-2">
                          <table className="table table-sm table-bordered">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Type</th>
                                <th className="text-right">Basis</th>
                                <th className="text-right">Typical</th>
                                <th className="text-right">Rejection</th>
                                <th className="text-right">Actual</th>
                                {/* <th className="text-right">Bonus Cap</th> */}
                                <th className="text-center">
                                  Bonus / Penality
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>GAR</td>
                                <td className="text-right">
                                  {this.state.quality_calorific_value_basis}
                                </td>
                                <td className="text-right">
                                  {this.state.quality_calorific_value_typical}
                                </td>
                                <td className="text-right">
                                  {this.state.quality_calorific_value_rejection}
                                </td>
                                <td className="text-right">
                                  {this.state.quality_calorific_value_actual}
                                </td>
                                {/* <td className="text-right">{this.state.bonusCap}</td> */}
                                <td className="text-center">
                                  {this.qualityResultsCalculations("GAR")}
                                </td>
                              </tr>
                              {/* <tr>
                            <td>{this.state.quality_calorific_value2_basis}</td>
                            <td>
                              {this.state.quality_calorific_value2_typical}
                            </td>
                            <td>
                              {this.state.quality_calorific_value2_rejection}
                            </td>
                            <td>
                              {this.state.quality_calorific_value2_actual}
                            </td>
                            <td>{this.state.bonusCap2}</td>
                            <td>{this.state.penalityCap2}</td>
                          </tr>
                          <tr>
                            <td>{this.state.quality_calorific_value3_basis}</td>
                            <td>
                              {this.state.quality_calorific_value3_typical}
                            </td>
                            <td>
                              {this.state.quality_calorific_value3_rejection}
                            </td>
                            <td>
                              {this.state.quality_calorific_value3_actual}
                            </td>
                            <td>{this.state.bonusCap3}</td>
                            <td>{this.state.penalityCap3}</td>
                          </tr> */}
                              <tr>
                                <td>ASH</td>
                                <td> {this.state.quality_ash_basis}</td>
                                <td> {this.state.quality_ash_typical}</td>
                                <td className="text-right">
                                  {this.state.quality_ash_rejection}
                                </td>
                                <td className="text-right">
                                  {this.state.quality_ash_actual}
                                </td>
                                {/* <td className="text-right"></td> */}
                                <td className="text-center">
                                  {this.qualityResultsCalculations("Ash")}
                                </td>
                              </tr>
                              <tr>
                                <td>Sulphur</td>
                                <td>
                                  {this.state.quality_total_sulphur_basis}
                                </td>
                                <td>
                                  {" "}
                                  {this.state.quality_total_sulphur_typical}
                                </td>
                                <td className="text-right">
                                  {this.state.quality_total_sulphur_rejection}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {this.state.quality_total_sulphur_actual}
                                </td>
                                {/* <td className="text-right"></td> */}
                                <td className="text-center">
                                  {this.qualityResultsCalculations("Sulphur")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>

                  <div className="col p-0 mb-2 border-0">
                    {/* <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Coal Costing</h5>
                      </div>
                      <div className="card-body pb-0">
                        {this.state.displayQualityResAlert &&
                          this.state.costing_type === "Commercial" && (
                            <span
                              className="row col ml-2 mb-4 mt-4"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              Quality results are available. Please edit to view
                              bonus/penalities and review the adjusted price.
                            </span>
                          )}
                        <div className="row">
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Invoice No
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.contract_invoice_no
                                ? this.state.contract_invoice_no
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Invoice Date
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.invoice_date
                                ? localDateFormate(this.state.invoice_date)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Fakthur Pazak No
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.ppn ? this.state.ppn : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Fakthur Pazak Date
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.ppn_date
                                ? localDateFormate(this.state.ppn_date)
                                : "-"}
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Barge Quantity in MT
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_coal_quantity
                                ? this.toLocaleString(
                                    this.state.coal_cost_coal_quantity
                                  )
                                : null}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Coal Price per MT
                            </label>
                            <div className="contract_display_header_value">
                              {this.toLocaleString(this.state.coalPricePerMT)}{" "}
                              {this.state.coalPriceCurrency}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Billing Currency
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                          {(config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            this.state.coalPriceCurrency !== "USD" &&
                            this.state.coalPriceCurrency && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Exchange rate {this.state.coalPriceCurrency}{" "}
                                  to USD
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.coal_cost_currency_xchg_rate
                                    ? this.toLocaleString(
                                        this.state.coal_cost_currency_xchg_rate
                                      )
                                    : ""}
                                </div>
                              </div>
                            )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") &&
                            this.state.coalPriceCurrency !== "IDR" &&
                            this.state.coalPriceCurrency && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Exchange rate {this.state.coalPriceCurrency}{" "}
                                  to IDR
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.coal_cost_currency_xchg_rate
                                    ? this.toLocaleString(
                                        this.state.coal_cost_currency_xchg_rate
                                      )
                                    : ""}
                                </div>
                              </div>
                            )}
                          {this.state.coal_cost_billing_currency !==
                            this.state.coalPriceCurrency &&
                            this.state.coal_cost_billing_currency !== "" && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Coal Price per MT in{" "}
                                  {this.state.coal_cost_billing_currency}
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state
                                    .coal_cost_coal_price_pmt_local_currency
                                    ? this.toLocaleString(
                                        this.state
                                          .coal_cost_coal_price_pmt_local_currency
                                      ) +
                                      " " +
                                      this.state.coal_cost_billing_currency
                                    : ""}
                                </div>
                              </div>
                            )}
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Coal Price
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_coal_price
                                ? this.toLocaleString(
                                    this.state.coal_cost_coal_price
                                  )
                                : 0}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>

                          {this.state.coal_cost_penality_currency === "USD" &&
                            this.state.coal_cost_billing_currency !==
                              this.state.purchase_currency &&
                            this.state.sales_term === "Specs" &&
                            this.state.costing_type === "Commercial" && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Adjusted Coal Price PMT in{" "}
                                  {this.state.coal_cost_penality_currency}
                                </label>
                                <div className="contract_display_header_value">
                                  {this.toLocaleString(
                                    (
                                      Number(
                                        this.state
                                          .coal_cost_adjusted_coal_price_pmt
                                      ) /
                                      Number(
                                        this.state.coal_cost_currency_xchg_rate
                                      )
                                    ).toFixed(2)
                                  )}
                                </div>
                              </div>
                            )}
                          {this.state.sales_term === "Specs" &&
                            this.state.costing_type === "Commercial" && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Adjusted Coal Price PMT in{" "}
                                  {this.state.coal_cost_billing_currency}
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.coal_cost_adjusted_coal_price_pmt
                                    ? this.toLocaleString(
                                        this.state
                                          .coal_cost_adjusted_coal_price_pmt
                                      )
                                    : ""}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                            )}
                          {this.state.sales_term === "Specs" &&
                            this.state.costing_type === "Commercial" && (
                              <div className="col-lg-4 mb-3 pl-0">
                                <label className="contract_display_header_label">
                                  Adjusted Coal Price
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.coal_cost_adjusted_coal_price
                                    ? this.toLocaleString(
                                        this.state.coal_cost_adjusted_coal_price
                                      )
                                    : ""}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                            )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <div className="col-lg-4 mb-3 pl-0">
                              <label className="contract_display_header_label">
                                VAT Value
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.vat_value !== "0"
                                  ? Number(this.state.vat_value)
                                    ? this.toLocaleString(this.state.vat_value)
                                    : `( ${this.toLocaleString(
                                        this.state.vat_value
                                      )} )`
                                  : 0}{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                            </div>
                          )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <div className="col-lg-4 mb-3 pl-0">
                              <label className="contract_display_header_label">
                                PPh22 Local
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.coal_cost_pph22_percent_val !== "0"
                                  ? Number(
                                      this.state.coal_cost_pph22_percent_val
                                    )
                                    ? this.toLocaleString(
                                        this.state.coal_cost_pph22_percent_val
                                      )
                                    : `( ${this.toLocaleString(
                                        this.state.coal_cost_pph22_percent_val
                                      )} )`
                                  : 0}{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                            </div>
                          )}
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              PPh22 Export
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_pph22_export_value !== "0"
                                ? Number(
                                    this.state.coal_cost_pph22_export_value
                                  )
                                  ? this.toLocaleString(
                                      this.state.coal_cost_pph22_export_value
                                    )
                                  : `( ${this.toLocaleString(
                                      this.state.coal_cost_pph22_export_value
                                    )} )`
                                : 0}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Advace Adjustment
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.display_adjustments
                                ? Number(this.state.display_adjustments)
                                  ? this.toLocaleString(
                                      this.state.display_adjustments
                                    )
                                  : `( ${this.toLocaleString(
                                      this.state.display_adjustments
                                    )} )`
                                : 0}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                          {this.state.sales_type === "CIF DP" && (
                            <div className="col-lg-4 mb-3 pl-0">
                              <label className="contract_display_header_label">
                                Freight Price PMT in USD
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.freight_price_pmt
                                  ? this.toLocaleString(
                                      this.state.freight_price_pmt
                                    )
                                  : 0}{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                            </div>
                          )}
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Receivable from Buyer
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: config.themeColor }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_from_supplier
                              )}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                          {this.state.costing_type === "Commercial" && (
                            <div className="col-lg-4 mb-3 pl-0">
                              <label className="contract_display_header_label">
                                Received against Performa
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: config.themeColor }}
                              >
                                {this.state.performa_amount !== 0
                                  ? this.toLocaleString(
                                      this.state.performa_amount
                                    )
                                  : 0}{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                            </div>
                          )}
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Received from Buyer
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: config.themeColor }}
                            >
                              {this.toLocaleString(
                                this.state.receivedFromBuyer
                              )}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                          {this.state.costing_type !== "Commercial" && (
                            <div className="col-lg-4 mb-3 pl-0">
                              <label className="contract_display_header_label">
                                Adjusted to Commercial
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: config.themeColor }}
                              >
                                {this.toLocaleString(
                                  this.state.performaInvoicesAdjustedAmount
                                )}{" "}
                                {this.state.coal_cost_billing_currency}
                              </div>
                            </div>
                          )}
                          <div className="col-lg-4 mb-3 pl-0">
                            <label className="contract_display_header_label">
                              Remaining Amount
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: config.themeColor }}
                            >
                              {this.toLocaleString(this.state.remainingAmount)}{" "}
                              {this.state.coal_cost_billing_currency}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <SalesAdvanceAdjustments
                      open={this.state.openAdvAdjustments}
                      adjustments={this.state.advance_adjustments}
                      table_name="barge_sales_fin_adv_adjustments"
                      costing_id={this.props.comboID?.split("$#")[0]}
                      callbackFn={() => this.componentDidMount()}
                      info={{
                        sales_contract_id: this.state.selectedSalesContractId,
                        sales_contract_no: this.state.sales_contract_no,
                        remaining_cus_adv_payment:
                          this.state.remaining_cus_adv_payment,
                      }}
                      closeModal={() =>
                        this.setState({ openAdvAdjustments: false })
                      }
                    />

                    <InvoiceForm
                      open={this.state.invoiceDialog}
                      costingID={this.props.comboID?.split("$#")[0]}
                      costing_type={"Barge Sales Commercial Coal Costing"}
                      data={this.state.invoiceDetails}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />

                    {this.state.costing_type === "Commercial" && (
                      <div className="row col-lg-12">
                        <div className="col-lg-4 mb-3 pl-0">
                          <label className="contract_display_header_label">
                            Total Invoiced Amount
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.commercialInvoicesTotalAmount
                              ? this.toLocaleString(
                                  this.state.commercialInvoicesTotalAmount
                                )
                              : 0}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3 pl-0">
                          <label className="contract_display_header_label">
                            Amount Received
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.commercialInvoicesReceivedAmount
                              ? this.toLocaleString(
                                  this.state.commercialInvoicesReceivedAmount
                                )
                              : 0}
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3 pl-0">
                          <label className="contract_display_header_label">
                            Remaining Amount
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.commercialInvoicesRemainingAmount <= 0
                              ? 0
                              : this.toLocaleString(
                                  this.state.commercialInvoicesRemainingAmount
                                )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.costing_type === "Commercial" && (
                      <div className="card p-0 border mb-3">
                        <div className="card-header section_header">
                          <h5>Commercial Invoices</h5>
                        </div>
                        <div className="card-body p-2">
                          <div className="row">
                            <div className="col-lg-9 pl-0">
                              {this.state.commercialInvoicesData.length <=
                                0 && (
                                <h6 className="mt-3">No invoices added</h6>
                              )}
                            </div>
                            {(this.state.features.includes("194") ||
                              this.state.features.includes("97")) && (
                              <div className="col text-right float-right p-0 mb-3">
                                {this.state.commercialInvoicesData.length <=
                                0 ? (
                                  <button
                                    className="btn payment_button"
                                    data-toggle="modal"
                                    title="Barge Payment Postings"
                                    data-placement="bottom"
                                    data-keyboard="false"
                                    data-target="#confirmationAddPaymentModal"
                                    data-backdrop="static"
                                  >
                                    Add invoice
                                  </button>
                                ) : (
                                  <button
                                    className="btn payment_button"
                                    data-toggle="modal"
                                    title="Barge Payment Postings"
                                    data-placement="bottom"
                                    data-keyboard="false"
                                    data-target="#invoicesPostingModal"
                                    data-backdrop="static"
                                    onClick={() => {
                                      this.setState({
                                        invoiceFormTitle: "Add",
                                        payment_invoice_date: null,
                                        invoice_amount: "",
                                        invoice_percent: "",
                                        invoice_type: "Commercial Invoices",
                                        invoice_number: "",
                                        remarks: "",
                                        invoiceMode: "Add",
                                      });
                                    }}
                                  >
                                    Add invoice
                                  </button>
                                )}
                              </div>
                            )}
                          </div>

                          {this.state.commercialInvoicesData.length > 0 && (
                            <div className="row">
                              <div className="col-lg p-0">
                                {this.state.commercialInvoicesData.map(
                                  (e, index) => (
                                    <div
                                      className="table-responsive border-0 mb-0"
                                      key={index}
                                    >
                                      <table className="table table-borderless border-0">
                                        <thead
                                          style={{
                                            visibility:
                                              index === 0
                                                ? "visible"
                                                : "collapse",
                                          }}
                                        >
                                          <tr>
                                            <th
                                              nowrap="true"
                                              className="table_header_barge_label text-left border-0"
                                            >
                                              Invoice No
                                            </th>
                                            <th
                                              nowrap="true"
                                              className="table_header_barge_label text-right border-0"
                                            >
                                              Invoice Amount
                                            </th>
                                            <th
                                              nowrap="true"
                                              className="table_header_barge_label text-left border-0"
                                            >
                                              Invoice Date
                                            </th>
                                            <th
                                              className="table_header_barge_label text-left border-0"
                                              style={{ minWidth: 100 }}
                                            >
                                              Remarks
                                            </th>
                                            <th
                                              nowrap="true"
                                              className="table_header_barge_label text-center border-0"
                                            >
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              nowrap="true"
                                              className="table_td text-left"
                                            >
                                              {e.invoice_no}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-right"
                                            >
                                              {" "}
                                              {e.invoice_amount
                                                ? this.toLocaleString(
                                                    e.invoice_amount
                                                  )
                                                : ""}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-left"
                                            >
                                              {e.invoice_date
                                                ? localDateFormate(
                                                    e.invoice_date
                                                  )
                                                : null}
                                            </td>
                                            <td className="table_td text-left">
                                              {e.remarks}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-center"
                                            >
                                              {(this.state.features.includes(
                                                "196"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) && (
                                                <i
                                                  className="fa fa-pencil-square-o mr-3"
                                                  style={{
                                                    fontSize: 20,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  data-toggle="modal"
                                                  title="Edit invoice"
                                                  data-placement="bottom"
                                                  onClick={() => {
                                                    this.setState({
                                                      invoice_type:
                                                        "Commercial Invoices",
                                                    });
                                                    this.getInvoiceData(e);
                                                  }}
                                                ></i>
                                              )}
                                              {(this.state.features.includes(
                                                "197"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) && (
                                                <i
                                                  className="fa fa-trash-o mr-3"
                                                  style={{
                                                    fontSize: 20,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  data-toggle="modal"
                                                  title="Delete Invoice"
                                                  data-placement="bottom"
                                                  data-keyboard="false"
                                                  data-target="#deleteInvoicePostingModal"
                                                  data-backdrop="static"
                                                  onClick={() =>
                                                    this.setState({
                                                      invoiceDeleteData: e,
                                                    })
                                                  }
                                                ></i>
                                              )}
                                              {(this.state.features.includes(
                                                "198"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) && (
                                                <Tooltip
                                                  title="Add Payment"
                                                  arrow
                                                >
                                                  <i
                                                    className="fa fa-plus-circle mr-2"
                                                    style={{
                                                      fontSize: 20,
                                                      color: config.themeColor,
                                                      cursor: "pointer",
                                                    }}
                                                    data-toggle="modal"
                                                    data-keyboard="false"
                                                    data-target="#bargeSalesPaymentPostingModal"
                                                    data-backdrop="static"
                                                    onClick={() =>
                                                      this.setState({
                                                        invoiceID: e.invoice_id,
                                                        invoiceNumber:
                                                          e.invoice_no,
                                                        paymentMode: "Add",
                                                      })
                                                    }
                                                  ></i>
                                                </Tooltip>
                                              )}
                                              {this.state
                                                .commercialExpandSelected ===
                                              index ? (
                                                <i
                                                  className="fa fa-chevron-up"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "#483b34",
                                                    cursor: "pointer",
                                                    float: "right",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      commercialExpandSelected:
                                                        null,
                                                    })
                                                  }
                                                ></i>
                                              ) : (
                                                <i
                                                  className="fa fa-chevron-down"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "#483b34",
                                                    cursor: "pointer",
                                                    float: "right",
                                                    display:
                                                      this.state.features.includes(
                                                        "199"
                                                      ) ||
                                                      this.state.features.includes(
                                                        "97"
                                                      )
                                                        ? "initial"
                                                        : "none",
                                                    visibility:
                                                      e.payments.length > 0
                                                        ? "initial"
                                                        : "hidden",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      commercialExpandSelected:
                                                        index,
                                                    })
                                                  }
                                                ></i>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {e.payments.length > 0 ? (
                                        <div
                                          className="row"
                                          style={{
                                            visibility:
                                              this.state
                                                .commercialExpandSelected ===
                                              index
                                                ? "visible"
                                                : "collapse",
                                            padding:
                                              this.state
                                                .commercialExpandSelected ===
                                              index
                                                ? 6
                                                : 0,
                                          }}
                                        >
                                          <div className="table-responsive">
                                            <table className="table table-hover table-bordered table-sm mb-0">
                                              <thead className="table-header">
                                                <tr>
                                                  <th
                                                    className="table_header_barge_label text-left border-0"
                                                    nowrap="true"
                                                  >
                                                    Receivable ID
                                                  </th>
                                                  <th
                                                    className="table_header_barge_label text-right border-0"
                                                    nowrap="true"
                                                  >
                                                    Amount
                                                  </th>
                                                  <th
                                                    className="table_header_barge_label text-left border-0"
                                                    nowrap="true"
                                                  >
                                                    Date Received
                                                  </th>
                                                  <th
                                                    className="table_header_barge_label text-left border-0"
                                                    style={{
                                                      width: 300,
                                                      maxWidth: 250,
                                                    }}
                                                  >
                                                    Description
                                                  </th>
                                                  <th
                                                    className="table_header_barge_label text-left border-0"
                                                    nowrap="true"
                                                  >
                                                    Created Date
                                                  </th>
                                                  <th
                                                    className="table_header_barge_label text-center border-0"
                                                    nowrap="true"
                                                  >
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              {e.payments.map((p, idx) => (
                                                <tr key={idx}>
                                                  <td
                                                    className="table_td text-right"
                                                    nowrap="true"
                                                  >
                                                    {p.payment_id}
                                                  </td>
                                                  <td
                                                    className="table_td text-right"
                                                    nowrap="true"
                                                  >
                                                    {p.amount
                                                      ? this.toLocaleString(
                                                          p.amount
                                                        )
                                                      : ""}
                                                  </td>
                                                  <td
                                                    className="table_td text-left"
                                                    nowrap="true"
                                                  >
                                                    {p.date_of_payment
                                                      ? localDateFormate(
                                                          p.date_of_payment
                                                        )
                                                      : null}
                                                  </td>

                                                  <td className="table_td text-left">
                                                    {p.description}
                                                  </td>
                                                  <td
                                                    className="table_td text-left"
                                                    nowrap="true"
                                                  >
                                                    {p.created_date
                                                      ? localDateFormate(
                                                          p.created_date
                                                        )
                                                      : null}
                                                  </td>
                                                  <td
                                                    className="table_td text-center"
                                                    nowrap="true"
                                                  >
                                                    {(this.state.features.includes(
                                                      "200"
                                                    ) ||
                                                      this.state.features.includes(
                                                        "97"
                                                      )) && (
                                                      <Tooltip
                                                        title="Edit"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-pencil-square-o mr-3"
                                                          style={{
                                                            fontSize: 20,
                                                            color:
                                                              config.themeColor,
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Edit Coal Payment Posting"
                                                          data-placement="bottom"
                                                          onClick={() =>
                                                            this.getBargePurchasePaymentPosting(
                                                              p
                                                            )
                                                          }
                                                        ></i>
                                                      </Tooltip>
                                                    )}
                                                    {(this.state.features.includes(
                                                      "201"
                                                    ) ||
                                                      this.state.features.includes(
                                                        "97"
                                                      )) && (
                                                      <Tooltip
                                                        title="Delete"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-trash-o"
                                                          style={{
                                                            fontSize: 20,
                                                            color:
                                                              config.themeColor,
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Delete Coal Payment Posting"
                                                          data-placement="bottom"
                                                          data-keyboard="false"
                                                          data-target="#deletePaymentPostingModal"
                                                          data-backdrop="static"
                                                          onClick={() =>
                                                            this.setState({
                                                              deleteData: e,
                                                            })
                                                          }
                                                        ></i>
                                                      </Tooltip>
                                                    )}
                                                  </td>
                                                </tr>
                                              ))}
                                            </table>
                                          </div>
                                        </div>
                                      ) : (
                                        <h6
                                          style={{
                                            display:
                                              this.state
                                                .commercialExpandSelected ===
                                              index
                                                ? "inline"
                                                : "none",
                                            padding:
                                              this.state
                                                .commercialExpandSelected ===
                                              index
                                                ? 6
                                                : 0,
                                          }}
                                        >
                                          No payments made
                                        </h6>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <ViewFiles
                      {...this.props}
                      files_data={this.state.files}
                      type="Costings"
                      feature_name="Sales_fin_commercial_coal_costing"
                      invoice_file={this.state.invoice_file}
                      bl_file={this.state.bl_file}
                      coa_file={this.state.coa_file}
                      dsr_file={this.state.dsr_file}
                    />
                    <AuditInfo info={this.state} />
                  </div>
                </div>
              </div>
              <div
                className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
                style={{ bottom: 0, background: "white" }}
              >
                <button
                  type="button"
                  className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
                  onClick={() => this.props.onCancel(false)}
                >
                  Cancel
                </button>

                {(this.state.approval_status === "Pending"
                  ? this.state.features.includes("192") ||
                    this.state.features.includes("404") ||
                    this.state.features.includes("97")
                  : this.state.features.includes("97")) && (
                  <button
                    type="button"
                    className="drawer_button drawer_text"
                    onClick={() => this.props.onEdit(true)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className="modal fade"
          id="noSalesDataModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6 className="modal-title">
                  {" "}
                  <WarningIcon
                    style={{ color: "#e0a51e", marginBottom: "5px" }}
                  />{" "}
                  No Sales Financials data available for this Barge.
                </h6>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn btn-warning"
                  onClick={() =>
                    (window.location.href =
                      "/view-business/" + btoa(this.state.business_no_id))
                  }
                >
                  Back
                </button>
                {/* <button className='btn btn-secondary'data-dismiss="modal">Cancel</button> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deletePaymentPostingModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {" "}
                  <WarningIcon
                    style={{ color: "#e0a51e", marginBottom: "5px" }}
                  />{" "}
                  Are you sure to delete ?
                </h6>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn btn-danger mr-3"
                  onClick={() =>
                    this.deleteBargeSalesPaymentPosting(this.state.deleteData)
                  }
                >
                  Delete
                </button>
                <button className="btn btn-secondary" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*
         *
         * Barge Payements Posting Modals
         *
         */}

        <div
          className="modal fade"
          id="bargeSalesPaymentPostingModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {this.state.paymentTitle} Payment Posting
                </h6>
                {/* //<button type="button" className="close" data-dismiss="modal">&times;</button> */}
              </div>
              <div className="modal-body">
                <div className="col-lg-12">
                  <div
                    style={{
                      fontSize: 14,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    {this.state.paymentErrorMsg}
                  </div>
                  <div className="row">
                    <label className="label-text col-lg-5">
                      Date Received <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.date_of_payment}
                          error={this.state.date_of_paymentError}
                          onChange={(date) => {
                            this.setState({
                              date_of_payment: date,
                              date_of_paymentError: false,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div className="row">
                    <label className="label-text col-lg-5">
                      Amount <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <TextField
                        name="amount"
                        margin="dense"
                        variant="outlined"
                        placeholder="Amount"
                        required
                        type="number"
                        value={this.state.amount}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        fullWidth
                        error={this.state.amountError}
                        onChange={(e) => {
                          //const re = /^[0-9.-]*\.?[0-9.-]*$/;
                          const re = /^[\d./-]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({
                              amount: e.target.value,
                              amountError: false,
                              paymentErrorMsg: "",
                            });
                            var remainingAmount =
                              Number(this.state.receivable_from_supplier) -
                              Number(this.state.totalCoalPaymentsAmount);
                            if (this.state.paymentMode === "Update") {
                              remainingAmount =
                                Number(this.state.receivable_from_supplier) -
                                Number(this.state.totalCoalPaymentsAmount) +
                                Number(this.state.existingAmount);
                            }
                            if (
                              Number(e.target.value) > Number(remainingAmount)
                            ) {
                              this.setState({
                                amountError: true,
                                paymentErrorMsg:
                                  "Amount Is Exceeding Receivable from Buyer",
                              });
                              return;
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="label-text col-lg-5">
                      Invoice No <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <label className="label-text col-lg-6">
                      {this.state.invoiceNumber}
                    </label>
                    {/* <div className='col-lg-6 pl-0'>
                      <TextField
                        name='invoice_no'
                        margin='dense'
                        variant='outlined'
                        placeholder='Invoice No'
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        error={this.state.invoice_noError}
                        value={this.state.invoice_no}
                        onChange={(e) => this.setState({ invoice_no: e.target.value, invoice_noError: false })}
                        fullWidth
                      />
                    </div> */}
                  </div>

                  <div className="row">
                    <label className="label-text col-lg-5">Description</label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <TextField
                        name="description"
                        margin="dense"
                        variant="outlined"
                        placeholder="Description"
                        multiline
                        rows={3}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                {this.state.paymentMode === "Add" && (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this.addBargePurchasePaymentPostingHandler}
                    disabled={this.state.submitting}
                  >
                    Submit
                    {this.state.submitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "green",
                          position: "absolute",
                          bottom: "6%",
                          left: "56%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </button>
                )}

                {this.state.paymentMode === "Update" && (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this.UpdateBargePurchasePaymentPostingHandler}
                    disabled={this.state.submitting}
                  >
                    Submit
                    {this.state.submitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "green",
                          position: "absolute",
                          bottom: "6%",
                          left: "56%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      paymentTitle: "",
                      payment_posting_type: "",
                      date_of_payment: null,
                      amount: "",
                      invoice_no: "",
                      description: "",
                      paymentErrorMsg: "",
                      date_of_paymentError: false,
                      amountError: false,
                      invoice_noError: false,
                      paymentMode: "",
                      submitting: false,
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="confirmationAddPaymentModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {" "}
                  <WarningIcon
                    style={{ color: "#e0a51e", marginBottom: "5px" }}
                  />
                  Please ensure performa payments are entered before creating
                  commercial invoice.
                </h6>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-keyboard="false"
                  data-target="#invoicesPostingModal"
                  data-backdrop="static"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      invoiceFormTitle: "Add",
                      invoice_date: null,
                      invoice_amount: "",
                      invoice_percent: "",
                      invoice_type: "Commercial Invoices",
                      invoice_number: "",
                      remarks: "",
                      invoiceMode: "Add",
                    });
                  }}
                >
                  Proceed
                </button>
                <button className="btn btn-secondary" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="invoicesPostingModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {this.state.invoiceFormTitle} Invoice
                </h6>
              </div>
              <div className="modal-body">
                <div className="col-lg-12">
                  <div className="row">
                    <label className="label-text col-lg-5">
                      Invoice No <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <TextField
                        name="invoice_number"
                        margin="dense"
                        variant="outlined"
                        placeholder="Invoice No"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={this.state.invoice_numberError}
                        value={this.state.invoice_number}
                        onChange={(e) =>
                          this.setState({
                            invoice_number: e.target.value,
                            invoice_numberError: false,
                          })
                        }
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="label-text col-lg-5">
                      Invoice Amount <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <TextField
                        name="invoice_amount"
                        margin="dense"
                        variant="outlined"
                        placeholder="Amount"
                        required
                        value={this.state.invoice_amount}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        fullWidth
                        error={this.state.invoice_amountError}
                        onChange={(e) => {
                          const pr = /^\d*(\.\d{0,4})?$/;
                          if (
                            e.target.value === "" ||
                            pr.test(e.target.value)
                          ) {
                            this.setState({
                              invoice_amount: e.target.value,
                              invoice_amountError: false,
                              //paymentErrorMsg: ''
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  {this.state.invoice_type === "Performa Invoices" && (
                    <div className="row">
                      <label className="label-text col-lg-5">Invoice %</label>
                      <label className="label-text">:</label>
                      <div className="col-lg-6 pl-0">
                        <TextField
                          name="invoice_percent"
                          margin="dense"
                          variant="outlined"
                          placeholder="Percent"
                          required
                          value={this.state.invoice_percent}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.invoice_percentError}
                          onChange={(e) =>
                            this.setState({ invoice_percent: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <label className="label-text col-lg-5">
                      Invoice Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.payment_invoice_date}
                          error={this.state.invoice_dateError}
                          onChange={(date) => {
                            this.setState({
                              payment_invoice_date: date,
                              invoice_dateError: false,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div className="row">
                    <label className="label-text col-lg-5">Remarks</label>
                    <label className="label-text">:</label>
                    <div className="col-lg-6 pl-0">
                      <TextField
                        name="remarks"
                        margin="dense"
                        variant="outlined"
                        placeholder="Remarks"
                        multiline
                        rows={3}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={this.state.remarks}
                        onChange={(e) =>
                          this.setState({ remarks: e.target.value })
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                {this.state.invoiceMode === "Add" && (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this.addInvoiesHandler}
                    disabled={this.state.submitting}
                  >
                    Submit
                    {this.state.submitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "green",
                          position: "absolute",
                          bottom: "6%",
                          left: "56%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </button>
                )}

                {this.state.invoiceMode === "Update" && (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this.updateInvoicesHandler}
                    disabled={this.state.submitting}
                  >
                    Update
                    {this.state.submitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "green",
                          position: "absolute",
                          bottom: "6%",
                          left: "56%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      invoiceFormTitle: "",
                      payment_invoice_date: null,
                      invoice_amount: "",
                      invoice_type: "",
                      invoice_percent: "",
                      invoice_number: "",
                      remarks: "",
                      invoiceMode: "",
                      submitting: false,
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteInvoicePostingModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {" "}
                  <WarningIcon
                    style={{ color: "#e0a51e", marginBottom: "5px" }}
                  />{" "}
                  Are you sure to delete ?
                </h6>
              </div>
              <div className="modal-footer border-0">
                <button
                  className="btn btn-danger mr-3"
                  onClick={() =>
                    this.deleteInvoicePosting(this.state.invoiceDeleteData)
                  }
                >
                  Delete
                </button>
                <button className="btn btn-secondary" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
