import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// TODO : Common imported Components
import Login from "./components/common/Login";
import Dashboard from "./components/common/Dashboard";
import ChangePassword from "./components/common/ChangePswd";
import PageNotFound from "./components/common/PageNotFound";
import Logout from "./components/common/Logout";

// TODO :: Business Partners

/* customers */
import BusinessPartner from "./components/BusinessPartners/BusinessPartner";

import Customer from "./components/BusinessPartners/customers/customerAdd";
import UpdateCustomer from "./components/BusinessPartners/customers/customerUpdate";
import ViewCustomer from "./components/BusinessPartners/customers/customerView";

/* vendors */
import Vendor from "./components/BusinessPartners/vendors/vendorAdd";
import UpdateVendor from "./components/BusinessPartners/vendors/vendorUpdate";
import ViewVendor from "./components/BusinessPartners/vendors/vendorView";

// TODO :: contracts
import VendorAdvanceContractAdd from "./components/contracts/Advance/vendorAdvanceContractAdd";
import VendorAdvanceContractList from "./components/contracts/Advance/vendorAdvanceContractList";
import VendorAdvanceContractUpdate from "./components/contracts/Advance/vendorAdvanceContractUpdate";
import ViewVendorAdvanceContract from "./components/contracts/Advance/vendorAdvanceContractView";

/*  Purchase Contract imported components */
import AddPurchaseContract from "./components/contracts/PurchaseContracts/PurchaseContractAdd";
import PurchaseContractList from "./components/contracts/PurchaseContracts/PurchaseContractList";
import UpdatePurchaseContract from "./components/contracts/PurchaseContracts/PurchaseContractUpdate";
import ViewPurchaseContract from "./components/contracts/PurchaseContracts/PurchaseContractView";
// import PurchaseContractAddendum from "./components/contracts/PurchaseContracts/PurchaseContractAddendum";
import PurchaseContractAddendum from "./components/contracts/PurchaseContracts/PurchaseAddendum";
import PurchaseAddendumView from "./components/contracts/PurchaseContracts/PurchaseAddendumView";
//TODO: Sales Contract imported components
import SalesContract from "./components/contracts/SalesContracts/SalesContractAdd";
import SalesContractList from "./components/contracts/SalesContracts/SalesContractList";
import UpdateSalesContract from "./components/contracts/SalesContracts/SalesContractUpdate";
import ViewSalesPurchase from "./components/contracts/SalesContracts/SalesContractView";
// import SalesContractAddendum from "./components/contracts/SalesContracts/SalesContractAddendum";
import SalesContractAddendum from "./components/contracts/SalesContracts/SalesAddendum";

//TODO: Users imported components
import AddUser from "./components/Users/UserAdd";
import UserList from "./components/Users/UserList";
import UpdateUser from "./components/Users/UserUpdate";
import ViewUser from "./components/Users/UserView";

//TODO: Business Number imported components
import NewBusinessNumber from "./components/BusinessNumber/NewBusinessNumber";
import BusinessNumberList from "./components/BusinessNumber/NewBusinessNumberList";
import ViewBusinessNumber from "./components/BusinessNumber/NewBusinessNumberView";
import UpdateBusinessNumber from "./components/BusinessNumber/NewBusinessNumberUpdate";
// TODO : Business Number OP Mother Vessel Components
import BusinessNoOpMVAdd from "./components/BusinessNumberOpMV/BusinessNoOpMVAdd";
import BusinessNoOpMVUpdate from "./components/BusinessNumberOpMV/BusinessNoOpMVUpdate";

import * as serviceWorker from "./serviceWorker";

import UserAccessManagement from "./components/common/UserAccessManagement";

import Barge_List from "./components/Barges/Barge-List";
import Barge_Add from "./components/Barges/Barge-Add";
import Barge_Update from "./components/Barges/Barge-Update";
import Barge_View from "./components/Barges/Barge-View";
import CloneBarge from "./components/Barges/cloneBarge";

// Purchase Quality Results
import PurchaseQualityResultsAdd from "./components/QualityResults/PurchaseQualityAdd";
import PurchaseQualityResultsView from "./components/QualityResults/PurchaseQualityView";
import PcQualityResultsUpdate from "./components/QualityResults/PurchaseQualityUpdate";
import Expenditure from "./components/Expenditure/Expenditure-List";
import Business_Profit_Loss_Summary from "./components/Business-No-P&L-Summary/Business-P&L-Summary";

// Sales Quality Results
import ServiceList from "./components/serviceRequests/serviceRequests";

// Reports
import FixtureNoteList from "./components/contracts/FixtureNotes/fixtureNoteList";
import FixtureNoteAdd from "./components/contracts/FixtureNotes/fixtureNoteAdd";
import FixtureNoteView from "./components/contracts/FixtureNotes/fixtureNoteView";
import FixtureNoteUpdate from "./components/contracts/FixtureNotes/fixtureNoteUpdate";
import Reports from "./components/reports/Reports";
import CoalVendorSummary from "./components/reports/CoalSupplierReports";
import VatInputReports from "./components/reports/VatInputReports";

import PaymentLimitsForApproval from "./components/reports/PaymentLimtsForApproval";
import VatImplementation from "./components/reports/VatImplementation";

import CoalProcurementServiceContract from "./components/contracts/ProcurementService/coalProcurementContractList";
import CpsContractAdd from "./components/contracts/ProcurementService/coalProcurementContractAdd";
import ViewCpsContract from "./components/contracts/ProcurementService/coalProcurementContractView";
import UpdateCpsContract from "./components/contracts/ProcurementService/coalProcurementContractUpdate";
import BusinessNumberFinancial from "./components/MV-Financials/BusinessNumberFinancial";
import PurchaseAvailableQuantityResports from "./components/reports/PurchaseQuantityReports";

import mailConfig from "./components/reports/MailConfig";
import WeeklyIndex from "./components/CoalIndexPrices/WeeklyCoalPrices";
import DownPayment from "./components/contracts/Advance/DownPayment";
import BargeAgreementNew from "./components/contracts/BargeAgreements/BargeAgreementNew";

import BusinessApprovals from "./components/Approvals/BusinessApprovals";
import PaymentPendingApprovals from "./components/Approvals/FinancialApprovals";
import SalesAddendumView from "./components/contracts/SalesContracts/SalesAddendumView";
import ContractApprovals from "./components/Approvals/ContractApprovals";
import ProcurementServiceAddendum from "./components/contracts/ProcurementService/ProcurementServiceAddendum";

//budget and costing impoprt
import BudgetAndCosting from "./components/BudgetAndCosting/BudgetAndCostingList";
import BudgetAndCostingAdd from "./components/BudgetAndCosting/BudgetAndCostingAdd";
import BudgetAndCostingView from "./components/BudgetAndCosting/BudgetAndCostingView";
import BudgetAndCostingUpdate from "./components/BudgetAndCosting/BudgetAndCostingUpdate";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/user-access" component={UserAccessManagement} />
      <Route path="/change-pswd" component={ChangePassword} />
      <Route path="/business-partner" component={BusinessPartner} />

      <Route path="/add-vendor" component={Vendor} />
      <Route path="/view-vendor/:vendorId" component={ViewVendor} />
      <Route path="/update-vendor/:vendorId" component={UpdateVendor} />
      <Route
        path="/new-vendor-advance-contract"
        component={VendorAdvanceContractAdd}
      />
      <Route path="/advance-payments" component={VendorAdvanceContractList} />
      <Route
        path="/vendor-advance-contract/:vendorAdvanceContractID"
        component={VendorAdvanceContractUpdate}
      />

      <Route path={"/contract-approvals"} component={ContractApprovals} />
      <Route
        path="/vendor-advance-view-contract/:vendorAdvanceContractID"
        component={ViewVendorAdvanceContract}
      />
      <Route path="/vendor-down-payment" component={DownPayment} />

      <Route path="/add-customer" component={Customer} />
      <Route path="/view-customer/:customerId" component={ViewCustomer} />
      <Route path="/update-customer/:customerId" component={UpdateCustomer} />

      <Route path="/add-user" component={AddUser} />
      <Route path="/update-user/:userID" component={UpdateUser} />
      <Route path="/view-user/:userID" component={ViewUser} />
      <Route path="/user-list" component={UserList} />

      <Route path="/add-purchase" component={AddPurchaseContract} />
      <Route
        path="/view-purchase-contract/:PurchaseID"
        component={ViewPurchaseContract}
      />
      <Route
        path="/update-purchase-contract/:PurchaseID"
        component={UpdatePurchaseContract}
      />
      <Route path="/purchase-contract-list" component={PurchaseContractList} />
      <Route
        path="/purchase-contract-addendum/:PurchaseID"
        component={PurchaseContractAddendum}
      />
      <Route
        path="/purchase-contract-view-addendum/:PurchaseID"
        component={PurchaseAddendumView}
      />

      <Route
        path={"/sales-contract-view-addendum/:salesContractID"}
        component={SalesAddendumView}
      />

      <Route
        path="/coal-procurement-contracts"
        component={CoalProcurementServiceContract}
      />
      <Route path="/new-cps-contract" component={CpsContractAdd} />
      <Route
        path="/view-cps-contract/:cps_contract_id"
        component={ViewCpsContract}
      />
      <Route
        path="/cpsf-contract-addendum/:cps_contract_id"
        component={ProcurementServiceAddendum}
      />
      <Route
        path="/update-cps-contract/:cps_contract_id"
        component={UpdateCpsContract}
      />
      <Route path="/add-sales-contract" component={SalesContract} />
      <Route
        path="/view-sales-contract/:salesContractID"
        component={ViewSalesPurchase}
      />
      <Route
        path="/update-sales-contract/:salesContractID"
        component={UpdateSalesContract}
      />
      <Route path="/sales-contract-list" component={SalesContractList} />
      <Route
        path="/sales-contract-addendum/:salesContractID"
        component={SalesContractAddendum}
      />

      <Route path="/new-business" component={NewBusinessNumber} />
      <Route path="/business-number-list" component={BusinessNumberList} />
      <Route
        path="/business-number-financial"
        component={BusinessNumberFinancial}
      />
      <Route path="/financials" component={BusinessNumberFinancial} />
      <Route path="/view-business/:businessID" component={ViewBusinessNumber} />
      <Route
        path="/update-business/:businessID"
        component={UpdateBusinessNumber}
      />

      <Route
        path="/add-mother-vessel/:businessNumberID"
        component={BusinessNoOpMVAdd}
      />
      <Route
        path="/update-mother-vessel/:motherVesselID"
        component={BusinessNoOpMVUpdate}
      />

      <Route path="/barge-list" component={Barge_List} />
      <Route path="/add-barge" component={Barge_Add} />
      <Route path="/update-barge/:bargeID" component={Barge_Update} />
      <Route path="/view-barge/:bargeID" component={Barge_View} />
      <Route path="/clone-barge/:bargeID" component={CloneBarge} />

      <Route path="/emailConfig" component={mailConfig} />

      {/**

        Purchase Quality Results
       */}

      <Route
        path="/purchase-quality-results"
        component={PurchaseQualityResultsAdd}
      />
      <Route
        path="/view-purchase-quality-results/:bargeID"
        component={PurchaseQualityResultsView}
      />
      <Route
        path="/update-purchase-quality-results/:bargeID"
        component={PcQualityResultsUpdate}
      />

      {/**
       *
       *  GENERIC EXPENSES URL'S
       *
       */}

      <Route path="/indirect-expenditure" component={Expenditure} />

      {/**
       *
       *  BUSINESS NUMBER PROFIT AND LOSS SUMMARY
       *
       */}
      <Route
        path="/business-profit-and-loss-summary/:businessNo"
        component={Business_Profit_Loss_Summary}
      />

      {/**
       *
       *  Services url's
       *
       */}

      <Route path="/services" component={ServiceList} />

      {/**
       *
       *
       * Budget And Costing
       *
       */}
      <Route path="/budget-costing" component={BudgetAndCosting} />
      <Route path="/budget-costing-add" component={BudgetAndCostingAdd} />
      <Route
        path="/view-budget-costing/:budgetID"
        component={BudgetAndCostingView}
      />
      <Route path="/update-budget-costing/:budgetID" component={BudgetAndCostingUpdate} />

      <Route path="/reports" exact component={Reports} />
      <Route path="/reports/coal-summary" exact component={CoalVendorSummary} />
      <Route
        path="/reports/vat-input-summary"
        exact
        component={VatInputReports}
      />
      <Route path="/business-approvals" component={BusinessApprovals} />
      <Route path="/financial-approvals" component={PaymentPendingApprovals} />
      <Route
        path="/reports/payment-limits-for-second-approval"
        component={PaymentLimitsForApproval}
      />
      <Route path="/reports/vat-implementation" component={VatImplementation} />
      <Route
        path="/reports/pc-quantity-reports"
        component={PurchaseAvailableQuantityResports}
      />
      <Route path="/weekly-coal-index" component={WeeklyIndex} />

      {/**
       *
       * Fixture Notes
       *
       */}

      <Route path="/fixture-notes" component={FixtureNoteList} />
      <Route path="/new-fixture-note" component={FixtureNoteAdd} />
      <Route path="/view-fixture-note/:fixtureID" component={FixtureNoteView} />
      <Route
        path="/update-fixture-note/:fixtureID"
        component={FixtureNoteUpdate}
      />
      <Route path="/new-barging-agreement" component={BargeAgreementNew} />

      <Route path="/logout" component={Logout} />
      <Route component={PageNotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
