import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "../common/CookieHandler";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  MenuItem,
  Radio,
  IconButton,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  Drawer,
  Popper,
  Paper,
  Snackbar,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from "@material-ui/icons/Event";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import exportToExcel from "../Export_To_Excel";

// import mother vessel stevedore costing
import MVStevedorCostingList from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostList";

// import mother vessel floatig crane
import MvFloatingCraneChargesList from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesList";

// import mother vessel documentation charges
import MvDocList from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-List";

// Import Mother vessel sampling & analaysis charges
import LaporanChargesList from "../MvPurchaseFinancial/MvLaporanCharges/LaporanChargesList";

// Import Mother vessel sampling & analaysis charges
import MvSacList from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-List";

// import Mother vessel pre-shipment
import MvPreShipmentList from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-List";

// import freight costing
import FreightCostingView from "../MvPurchaseFinancial/Mv-Freight-Costing/Mv_Freight_Costing_View";

// import Mother vessel Export tax
import ExportTaxList from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-List";

// import mother vessel coal procurement charges
import MVProcurementChargesList from "../MvPurchaseFinancial/Coal_Procurement_Charges/MVProcurementChargesList";

// import mother vessel coal sales charges
import MvCscList from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesList";

//Mother Vessel
import MotherVesselAdd from "../BusinessNumberOpMV/BusinessNoOpMVAdd";
import MotherVesselUpdate from "../BusinessNumberOpMV/BusinessNoOpMVUpdate";

//Map Barges
import MapBarges from "../Barges/Barge-Mapping";

import BusinessProfitLossSummary from "../Business-No-P&L-Summary/Business-P&L-Summary";
import BusinessNoSalesCoalCostingList from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingList";

// Demurrages
import BuyerDemurrageList from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-List";

// Notify paty list
import BusinessNotifyList from "../Business-Notify-Party/Notify-Party-List";

// Vessel completion report
import BusinessNoVesselReportList from "../Business-Mv-Report/Vessel-Completion-Report-List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import VesselInsuranceView from "../MvPurchaseFinancial/Vessel-Insurance/Vessel-Insurance-View";

// Sales quality results
import SalesQualityResultsAdd from "../QualityResults/SalesQualityAdd";
import SalesQualityResultsView from "../QualityResults/SalesQualityView";
import SalesQualityResultsUpdate from "../QualityResults/SalesQualityUpdate";

//MV Demmurage Supplier
import MvSupplierDemurrageList from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageList";

//MV Demmurage Buyer
import MvBuyerDemurrageList from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageList";

//MV Other Income List
import OtherIncomeList from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-List";

//MV Other Expense List
import OtherExpenseList from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-List";

//MV Incoming Credit Note List
import IncomingCreditNoteList from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-List";

//MV Outgoing Credit Note List
import OutgoingCreditNoteList from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-List";

//MV Incoming Debit Note List
import IncomingDebitNoteList from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-List";

//MV Outgoing Debit Note List
import OutgoingDebitNoteList from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-List";

import PnbpChargesList from "../MvPurchaseFinancial/PNBP/PnbpChargesList";
// Mother vessel sales performa list

// import SalesPIList from '../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-List';
import MvDeadFreightList from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightList";
import SalesPIList from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-List";
import {
  dateFormateToDB,
  localDateFormate,
  localTimeFormate,
  pad,
} from "../common/common";
import Loader from "../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#42352d",
  },
}))(LinearProgress);

export default class ViewBusinessNumber extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      business_number_allocation: [],
      businessNo_motherVessel: [],
      isLoading: true,
      businessNo: "",
      oldBusinessNo: "",
      errorMsg: "",
      successMsg: "",
      quantityAllocating: "",
      modalName: "",
      quantityRows: [],
      buyBackQuantityRows: [],
      businessFlow: "",
      supplierReferenceNumber: "",
      buy_back_alloc: "No",
      //quantityBuyBackRows :[],
      purchaseDataForMenu: [],
      purchaseContractList: [],
      purchaseTypes: [],
      updatePurchaseTypes: [],
      purchaseTypeSelection: null,
      modalID: "",
      openQuantityAllocation: false,
      salesDataforMenu: [],
      salesContractsList: [],
      salesTypes: [],
      updateSalesTypes: [],
      salesTypeSelection: null,
      salesTypeDataShow: false,

      totalBarges: [],
      anchorEl: null,
      mother_vesselName: "",
      combOfBarges: [],
      UpdatedBarges: [],

      viewMenuOpen: false,
      viewMVMenuOpen: false,
      features: [],

      // Update Quality Standard
      activeTabIndex: 1,
      financialOthersActiveTabIndex: 1,
      businessTabIndex: 0,
      sales_fin_active_tab_index: 0,
      pc_contract_editable: false,
      pc_contract_index: null,
      updatePurchaseContractID: null,

      sc_contract_editable: false,
      sc_contract_index: null,
      updateSalesContractID: null,

      updateEnabled: true,
      updateButtonEnabled: false,
      allocationID: null,
      // Move Barges data
      get_all_business_no: [],
      move_barge_id: "0",
      allocationPurchaseErrorMessage: "",
      allocationPurchaseErrorId: null,
      allocationSalesErrorMessage: "",
      allocationSalesErrorId: null,
      bargesDataExpand: true,
      financialActiveTabIndex: 3,
      financialBargeServicesActiveTabIndex: 0,
      financialMVServicesActiveTabIndex: 0,
      map_barges_dialogue: false,
      barges: [],
      mapping_data: [],
      snackBarSuccessOpen: false,
      snackBarErrorOpen: false,
      motherVesselDrawer: false,
      openMapBarges: false,
      openDrawer: false,
      business_allocation_id: "",
      financialMVDemurrageActiveTabIndex: 1,
      financialMVDebitActiveTabIndex: 1,
      financialMVCreditActiveTabIndex: 1,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("38") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const businessNo = decodeURIComponent(
        window.atob(this.props.match.params.businessID)
      );
      this.setState({ businessNoID: businessNo });

      // Get purchase contracts API

      const status = "";
      await api
        .getPurchaseContracts(loginUserID, status, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.purchase_contract) {
                  const purchaseDataforMenu = [];
                  const filteredPContracts = res.purchase_contract.filter(
                    (e, indx) =>
                      e.status !== "Closed" && e.status !== "cancelled"
                  );
                  filteredPContracts.forEach((item) => {
                    purchaseDataforMenu.push({
                      name: item.contract_no,
                      value: item.contract_no,
                      key: item.id,
                      vendor_name: item.vendor_name,
                    });
                  });
                  this.setState({
                    purchaseDataForMenu: purchaseDataforMenu,
                    //purchaseContractList: res.purchase_contract,
                    purchaseContractList: filteredPContracts,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      // Get sales contracts API
      await api
        .getSalesContractList(loginUserID, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.sale_contract) {
                  const salesDataforMenu = [];
                  const filteredSContracts = res.sale_contract.filter(
                    (e, indx) =>
                      e.status !== "Closed" && e.status !== "cancelled"
                  );
                  filteredSContracts.forEach((item) => {
                    salesDataforMenu.push({
                      name: item.contract_no,
                      value: item.contract_no,
                      key: item.id,
                      customer_name: item.customer_name,
                    });
                  });
                  this.setState({
                    salesDataforMenu: salesDataforMenu,
                    //salesContractsList: res.sale_contract,
                    salesContractsList: filteredSContracts,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      // Get All business number for dispaly move barge
      await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_numbers) {
                const get_all_business_no = [];
                res.business_numbers.forEach((item) => {
                  get_all_business_no.push({
                    name: item.business_no,
                    value: item.id,
                    key: item.id,
                  });
                });
                this.setState({
                  get_all_business_no: get_all_business_no,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            }
          });
        }
      });

      await api
        .get_mapped_barges_for_business_no_id(loginUserID, idToken, businessNo)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barges) {
                  var sumOfBargeQuantity = 0;
                  var sumOfRedraftQuantity = 0;
                  var redraft_quantity = 0;

                  res.barges.forEach((item) => {
                    if (item.allocation_type === "Full") {
                      sumOfBargeQuantity += Number(item.barge_quantity);
                    } else {
                      sumOfBargeQuantity += Number(item.bm_quantity);
                    }

                    if (item.redraft_quantity === null) {
                      if (item.allocation_type === "Full") {
                        redraft_quantity = item.barge_quantity;
                      } else {
                        redraft_quantity = item.bm_quantity;
                      }
                    } else {
                      redraft_quantity = item.redraft_quantity;
                    }
                    sumOfRedraftQuantity += Number(redraft_quantity);
                  });

                  var ar = res.barges;
                  //var final = ar.filter(obj => ar.filter(element => element.coal_vendor_name === obj.coal_vendor_name).length > 1);
                  var final = ar.filter(
                    (obj) => obj.coal_vendor_name.length > 1
                  );
                  //var UniqueElements= ar.filter(obj => ar.filter(element => element.coal_vendor_name === obj.coal_vendor_name).length === 1);
                  var UniqueElements = [];
                  for (let j = 0; j < final.length; j++) {
                    if (
                      UniqueElements.filter(
                        (obj) =>
                          obj.coal_vendor_name === final[j].coal_vendor_name &&
                          obj.mine_name === final[j].mine_name &&
                          obj.pc_quality === final[j].pc_quality
                      ).length === 0
                    ) {
                      var list = final.filter(
                        (obj) =>
                          obj.coal_vendor_name === final[j].coal_vendor_name &&
                          obj.mine_name === final[j].mine_name &&
                          obj.pc_quality === final[j].pc_quality
                      );
                      if (list) {
                        var bargeQuantity = 0;
                        var redfratQuantity = 0;
                        let sumOfRedraftQuantity = 0;

                        for (var k = 0; k < list.length; k++) {
                          if (list[k].allocation_type === "Full") {
                            bargeQuantity += Number(list[k].barge_quantity);
                          } else {
                            bargeQuantity += Number(
                              list[k].bm_quantity ? list[k].bm_quantity : 0
                            );
                          }
                          if (list[k].redraft_quantity === null) {
                            if (list[k].allocation_type === "Full") {
                              redfratQuantity = list[k].barge_quantity;
                            } else {
                              redfratQuantity = list[k].bm_quantity;
                            }
                            //redfratQuantity = list[k].barge_quantity;
                          } else {
                            redfratQuantity = list[k].redraft_quantity;
                          }
                          sumOfRedraftQuantity += Number(redfratQuantity);

                          //redfratQuantity += Number(list[k].redraft_quantity);
                        }

                        final[j].total_barge_quantity = bargeQuantity;
                        final[j].total_redraft_quantity = sumOfRedraftQuantity;
                        UniqueElements.push(final[j]);
                      } else {
                        final[j].total_barge_quantity = final[j].barge_quantity;
                        final[j].total_redraft_quantity =
                          final[j].redraft_quantity;
                        UniqueElements.push(final[j]);
                      }
                    }
                  }

                  this.setState({
                    combOfBarges: UniqueElements,
                    totalBarges: res.barges,
                    sumOfBargeQuantity: sumOfBargeQuantity,
                    sumOfRedraftQuantity: sumOfRedraftQuantity,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      //Get businessNumber API based on businessNumber
      await api
        .getBusinessNumber(businessNo, loginUserID, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.business_number) {
                  //let businessAllocationData = res.business_number.business_number_allocation;
                  if (
                    res.business_number.consignment_type === "Mother Vessel"
                  ) {
                    // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                    //Get Business No Operational Mother Vessel list
                    api
                      .getBusinessNumberMotherVessel(
                        businessNo,
                        loginUserID,
                        idToken
                      )
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.idtoken)
                              this.Cookie.setIdTokenCookie(res.idtoken);
                            if (res) {
                              if (res.business_no_MV.length > 0) {
                                var motherVesselID = res.business_no_MV[0].id;
                                //Get Business No Operational Mother Vessel Details
                                api
                                  .getBusinessNoOpMVDetails(
                                    motherVesselID,
                                    loginUserID,
                                    idToken
                                  )
                                  .then((response) => {
                                    if (
                                      response.status >= 200 &&
                                      response.status < 300
                                    ) {
                                      response.json().then((res) => {
                                        if (res.business_no_MV) {
                                          this.setState({
                                            motherVesselID: motherVesselID,
                                            loadingCommencement: res
                                              .business_no_MV
                                              .loading_commencement
                                              ? res.business_no_MV
                                                  .loading_commencement
                                              : null,
                                            loadingCompletion: res
                                              .business_no_MV.loading_completion
                                              ? res.business_no_MV
                                                  .loading_completion
                                              : null,
                                            documentsOnBoard: res.business_no_MV
                                              .documents_on_board
                                              ? res.business_no_MV
                                                  .documents_on_board
                                              : null,
                                            vesselSailing: res.business_no_MV
                                              .vessel_sailing
                                              ? res.business_no_MV
                                                  .vessel_sailing
                                              : null,
                                            vessel_arrived_on: res
                                              .business_no_MV.vessel_arrived_on
                                              ? res.business_no_MV
                                                  .vessel_arrived_on
                                              : null,
                                            vessel_type: res.business_no_MV
                                              .vessel_type
                                              ? res.business_no_MV.vessel_type
                                              : "",
                                            nor_acceptance: res.business_no_MV
                                              .nor_acceptance
                                              ? res.business_no_MV
                                                  .nor_acceptance
                                              : null,
                                            eta_nor: res.business_no_MV.eta_nor
                                              ? res.business_no_MV.eta_nor
                                              : null,
                                            load_port_agent: res.business_no_MV
                                              .load_port_agent
                                              ? res.business_no_MV
                                                  .load_port_agent
                                              : "",

                                            vessel_final_quantity_in_mt: res
                                              .business_no_MV
                                              .vessel_final_quantity_in_mt
                                              ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                              : "",
                                            cargo_gain_loss_in_mt: res
                                              .business_no_MV
                                              .cargo_gain_loss_in_mt
                                              ? res.business_no_MV
                                                  .cargo_gain_loss_in_mt
                                              : "",
                                            supplierSurveyorNameValue:
                                              res.business_no_MV
                                                .supplier_surveyor === null
                                                ? ""
                                                : {
                                                    name: res.business_no_MV
                                                      .supplier_surveyor_names,
                                                    value:
                                                      res.business_no_MV
                                                        .supplier_surveyor,
                                                  },
                                            SDAMSAIISurveyorNameValue:
                                              res.business_no_MV
                                                .internal_surveyor === null
                                                ? ""
                                                : {
                                                    name: res.business_no_MV
                                                      .internal_surveyor_names,
                                                    value:
                                                      res.business_no_MV
                                                        .internal_surveyor,
                                                  },
                                            buyerAdditionalSurveyorNameValue:
                                              res.business_no_MV
                                                .joint_surveyor === null
                                                ? ""
                                                : {
                                                    name: res.business_no_MV
                                                      .joint_surveyor_names,
                                                    value:
                                                      res.business_no_MV
                                                        .joint_surveyor,
                                                  },
                                            stevedoreOnMVValue:
                                              res.business_no_MV
                                                .stevedore_on_mv === null
                                                ? ""
                                                : {
                                                    name: res.business_no_MV
                                                      .stevedore_name,
                                                    value:
                                                      res.business_no_MV
                                                        .stevedore_on_mv,
                                                  },
                                            documentationAgentValue:
                                              res.business_no_MV
                                                .documentation_agent === null
                                                ? ""
                                                : {
                                                    name: res.business_no_MV
                                                      .documentation_agent_name,
                                                    value:
                                                      res.business_no_MV
                                                        .documentation_agent,
                                                  },
                                            dischargePort:
                                              res.business_no_MV
                                                .discharge_port === null
                                                ? ""
                                                : res.business_no_MV
                                                    .discharge_port,
                                            loadingType:
                                              res.business_no_MV
                                                .loading_type === null
                                                ? ""
                                                : res.business_no_MV
                                                    .loading_type,
                                            loadingRatePerDay:
                                              res.business_no_MV
                                                .load_rate_per_day === null
                                                ? ""
                                                : res.business_no_MV
                                                    .load_rate_per_day,
                                            deadFreightIncurred:
                                              res.business_no_MV
                                                .dead_freight_incurred === null
                                                ? ""
                                                : res.business_no_MV
                                                    .dead_freight_incurred,
                                            deadFreightAccount:
                                              res.business_no_MV
                                                .dead_freight_account === null
                                                ? ""
                                                : res.business_no_MV
                                                    .dead_freight_account,
                                            supplierSurveyorName:
                                              res.business_no_MV
                                                .supplier_surveyor_names ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .supplier_surveyor_names,
                                            supplierSurveyorAnalysisType:
                                              res.business_no_MV
                                                .supplier_surveyor_analysis_type ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .supplier_surveyor_analysis_type,
                                            SDAMSAIISurveyorName:
                                              res.business_no_MV
                                                .internal_surveyor_names ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .internal_surveyor_names,
                                            SDAMSAIISurveyorAnalysisType:
                                              res.business_no_MV
                                                .internal_surveyor_analysis_type ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .internal_surveyor_analysis_type,
                                            buyerAdditionalSurveyorName:
                                              res.business_no_MV
                                                .joint_surveyor_names === null
                                                ? "0"
                                                : res.business_no_MV
                                                    .joint_surveyor_names,
                                            surveyorBasePrice:
                                              res.business_no_MV
                                                .surveyor_base_price === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_base_price,
                                            surveyorVAT:
                                              res.business_no_MV
                                                .surveyor_vat_price === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_vat_price,
                                            surveyorTotalPrice:
                                              res.business_no_MV
                                                .surveyor_total_price === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_total_price,
                                            surveyorCurrencyType:
                                              res.business_no_MV
                                                .surveyor_currency === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_currency,
                                            surveyorAdditionalDays:
                                              res.business_no_MV
                                                .surveyor_additional_days ===
                                              null
                                                ? "0"
                                                : res.business_no_MV
                                                    .surveyor_additional_days,
                                            stevedoreOnMV:
                                              res.business_no_MV
                                                .stevedore_vendor_names === null
                                                ? ""
                                                : res.business_no_MV
                                                    .stevedore_vendor_names,
                                            stevedoreAdditionalDays:
                                              res.business_no_MV
                                                .stevedore_additional_days ===
                                              null
                                                ? "0"
                                                : res.business_no_MV
                                                    .stevedore_additional_days,
                                            floatingCraneName:
                                              res.business_no_MV
                                                .floating_crane_name === null
                                                ? ""
                                                : res.business_no_MV
                                                    .floating_crane_name,
                                            floating_crane_additional_days: res
                                              .business_no_MV
                                              .floating_crane_additional_days
                                              ? res.business_no_MV
                                                  .floating_crane_additional_days
                                              : "",

                                            floatingCraneDemurrageDespatch:
                                              res.business_no_MV
                                                .floating_crane_despatch ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .floating_crane_despatch,
                                            floatingCraneDemurrageDespatchCurrencyType:
                                              res.business_no_MV
                                                .floating_crane_despatch_currency ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .floating_crane_despatch_currency,
                                            supplierDocumentationAgent: res
                                              .business_no_MV
                                              .supplier_documentation_agent
                                              ? res.business_no_MV
                                                  .supplier_documentation_agent
                                              : "",
                                            buyerDocumentationAgent: res
                                              .business_no_MV
                                              .joint_documentation_agent
                                              ? res.business_no_MV
                                                  .joint_documentation_agent
                                              : "",
                                            SDAM_DocumentationAgent: res
                                              .business_no_MV
                                              .internal_documentation_agent
                                              ? res.business_no_MV
                                                  .internal_documentation_agent
                                              : "",
                                            motherVesselRemarks: res
                                              .business_no_MV.remarks
                                              ? res.business_no_MV.remarks
                                              : "",
                                          });
                                        } else {
                                          alert(
                                            "Unexpected error occured. Please contact administrator."
                                          );
                                        }
                                      });
                                    }
                                  });
                                this.setState({
                                  businessNo_motherVessel: true,
                                });
                              } else {
                                this.setState({
                                  businessNo_motherVessel: false,
                                });
                              }
                            } else {
                              alert(
                                "Unexpected error occured. Please contact administrator."
                              );
                            }
                          });
                        }
                      });
                  }

                  const filterBuyBackInvolvedData =
                    res.business_number.business_number_allocation.filter(
                      (e, idx) => e.buy_back_alloction === "Yes"
                    );
                  const filterBuyBackNotInvolvedData =
                    res.business_number.business_number_allocation.filter(
                      (e, idx) => e.buy_back_alloction === "No"
                    );

                  this.setState({
                    businessNo_id: res.business_number.id,
                    totalAllocated: res.business_number.total_allocated
                      ? res.business_number.total_allocated
                      : 0,
                    businessType: res.business_number.business_type
                      ? res.business_number.business_type
                      : "0",
                    businessFlow: res.business_number.business_flow
                      ? res.business_number.business_flow
                      : "",
                    supplierReferenceNumber: res.business_number
                      .supplier_reference_number
                      ? res.business_number.supplier_reference_number
                      : "",
                    businessNo: res.business_number.business_no
                      ? res.business_number.business_no
                      : "",
                    oldBusinessNo: res.business_number.old_business_no
                      ? res.business_number.old_business_no
                      : "",
                    business_no_id: res.business_number.id
                      ? res.business_number.id
                      : "",
                    consignment_type: res.business_number.consignment_type
                      ? res.business_number.consignment_type
                      : "0",
                    activeTabIndex:
                      res.business_number.consignment_type === "Mother Vessel"
                        ? 0
                        : 1,
                    barge_name: res.business_number.barge_name
                      ? res.business_number.barge_name
                      : "",
                    mother_vesselName: res.business_number.mother_vessel_name
                      ? res.business_number.mother_vessel_name
                      : "",
                    shipment_type: res.business_number.shipment_type
                      ? res.business_number.shipment_type
                      : "",
                    vessel_type: res.business_number.vessel_type
                      ? res.business_number.vessel_type
                      : "",
                    stevedore_vendor: res.business_number.stv_vendor_name
                      ? res.business_number.stv_vendor_name
                      : "",
                    stevedore_vendor_names: res.business_number
                      .stevedore_vendor_names
                      ? res.business_number.stevedore_vendor_names
                      : "",
                    floating_crane_vendor: res.business_number.fctv_vendor_name
                      ? res.business_number.fctv_vendor_name
                      : "",
                    floating_crane_name: res.business_number.floating_crane_name
                      ? res.business_number.floating_crane_name
                      : "",
                    floating_crane_vendor_names: res.business_number
                      .floating_crane_vendor_names
                      ? res.business_number.floating_crane_vendor_names
                      : "",
                    loading_anchorage: res.business_number.loading_anchorage
                      ? res.business_number.loading_anchorage
                      : "",
                    load_port_agent: res.business_number.load_port_agent
                      ? res.business_number.load_port_agent
                      : "",
                    ld_rte_pr_mt_day: res.business_number.load_rate_per_day
                      ? res.business_number.load_rate_per_day
                      : "",
                    vessel_freight: res.business_number.vessel_freight
                      ? res.business_number.vessel_freight
                      : "",
                    vessel_owner: res.business_number.vessel_owner
                      ? res.business_number.vessel_owner
                      : "",
                    vessel_owner_name: res.business_number.vessel_owner_name
                      ? res.business_number.vessel_owner_name
                      : "",
                    vessel_freight_price: res.business_number
                      .vessel_freight_price
                      ? res.business_number.vessel_freight_price
                      : "",
                    shipment_laycan: res.business_number.shipment_laycan
                      ? res.business_number.shipment_laycan
                      : "",
                    vessel_arrived_on: res.business_number.vessel_arrived_on
                      ? res.business_number.vessel_arrived_on
                      : "",
                    eta_nor: res.business_number.eta_nor
                      ? res.business_number.eta_nor
                      : "",
                    nor_acceptance: res.business_number.nor_acceptance
                      ? res.business_number.nor_acceptance
                      : "",
                    documents_on_board: res.business_number.documents_on_board
                      ? res.business_number.documents_on_board
                      : "",
                    vessel_sailing: res.business_number.vessel_sailing
                      ? res.business_number.vessel_sailing
                      : "",
                    stowage_plan_in_mt: res.business_number.stowage_plan_in_mt
                      ? res.business_number.stowage_plan_in_mt
                      : "",
                    supply_tonnage_in_mt: res.business_number
                      .supply_tonnage_in_mt
                      ? res.business_number.supply_tonnage_in_mt
                      : "",
                    demurrage_rate_from_buyer: res.business_number
                      .demurrage_rate_from_buyer
                      ? res.business_number.demurrage_rate_from_buyer
                      : "",
                    demurrage_rate_to_supplier: res.business_number
                      .demurrage_rate_to_supplier
                      ? res.business_number.demurrage_rate_to_supplier
                      : "",
                    remarks: res.business_number.remarks
                      ? res.business_number.remarks
                      : "",
                    //business_number_allocation: res.business_number.business_number_allocation ? res.business_number.business_number_allocation : [],
                    business_number_allocation: filterBuyBackNotInvolvedData,
                    business_number_buy_back_allocation:
                      filterBuyBackInvolvedData,
                    buy_back_involved: res.business_number.buy_back_involved
                      ? res.business_number.buy_back_involved
                      : "No",
                    status: res.business_number.status
                      ? res.business_number.status
                      : "Active",
                    business_head_name: res.business_number.business_head_name,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    document.title = config.documentTitle + "View Business Number";
  }

  deleteBusinessNumberAllocation = (businessNo, businessAllocationID) => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    api
      .deleteBusinessNumberAllocation(
        loginUserID,
        idToken,
        businessNo,
        businessAllocationID
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              this.componentDidMount();
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message,
                errorMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "601") {
              response.json().then((res) => {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  successMsg: "",
                });
              });
            } else if (res.code === "624") {
              response.json().then((res) => {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  successMsg: "",
                });
              });
            }
          });
        }
      });
  };

  handleText = (e) => {
    if (e.target.name === "quantityAllocating") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
          quantityAllocationError: false,
          errorMsg: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        quantityAllocationError: false,
      });
    }
  };

  addBusinessNumberAllocation = () => {
    var data = this.state.quantityRows[0];
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      data = this.state.buyBackQuantityRows[0];
      row = this.state.buyBackQuantityRows;
    }
    var Mandatory = true;
    if (data.purchase_contract === "") {
      row[0].purchase_contractError = true;
      Mandatory = false;
      this.setState({
        quantityRows:
          this.state.buy_back_alloc !== "Yes" ? row : this.state.quantityRows,
        buyBackQuantityRows:
          this.state.buy_back_alloc === "Yes"
            ? row
            : this.state.buyBackQuantityRows,
      });
      alert("Please select purchase contract with types.");
      return;
    }
    if (data.purchase_procurement === "0") {
      row[0].purchase_procurementError = true;
      Mandatory = false;
    }
    if (data.purchase_quantityAllocating === "") {
      row[0].quantityAllocationError = true;
      Mandatory = false;
    }
    if (data.sales_contract === "") {
      row[0].sales_contractError = true;
      Mandatory = false;
      this.setState({
        quantityRows: row,
      });
      alert("Please select sales contract with types.");
      return;
    }
    if (data.sales_sellingQuality === "0") {
      row[0].sales_sellingQualityError = true;
      Mandatory = false;
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }

    if (Mandatory) {
      //checking allocating Quantity
      var purchaseQuantityAvailable = this._toBeDelivered(
        data.purchase_quality[0]
      );
      //var purchaseQuantityAvailable =  Number(data.purchase_quality[0].quantity_in_mt)-Number(data.purchase_quality[0].quantity_allocated);
      var salesQuantityAvailable = this._toBeDelivered(data.sales_quality[0]);
      //var salesQuantityAvailable = Number(data.sales_quality[0].quantity_in_mt)-Number(data.sales_quality[0].quantity_allocated);
      var quantityAllocating = Number(data.purchase_quantityAllocating);
      if (
        quantityAllocating > purchaseQuantityAvailable ||
        quantityAllocating > salesQuantityAvailable
      ) {
        row[0].quantityAllocationError = true;
        this.setState({
          quantityRows:
            this.state.buy_back_alloc !== "Yes" ? row : this.state.quantityRows,
          buyBackQuantityRows:
            this.state.buy_back_alloc === "Yes"
              ? row
              : this.state.buyBackQuantityRows,
        });
        alert(
          "Quantity allocating is greater than available/required quantity"
        );
        return;
      }
      var idToken = this.Cookie.getIdTokenCookie();
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var businessNo = decodeURIComponent(
        window.atob(this.props.match.params.businessID)
      );
      var businessType = this.state.businessType;
      var consignmentType = this.state.consignment_type;
      var shipmentType = this.state.shipment_type;
      var pc_purchase_type_id = data.purchase_types[0].id;
      var purchase_contract_id = data.purchase_contractID;
      var pc_qualities_id = data.purchase_quality[0].id;
      var purchase_contract_quality = data.quantity_in_mt;
      var procurement_quality_standard = data.purchase_procurement;
      var quantity_allocated = data.purchase_quantityAllocating;
      var sale_contract_id = data.sales_contractID;
      var sc_sales_type_id = data.sales_types[0].id;
      var sc_qualities_id = data.sales_quality[0].id;
      var selling_quality_standard = data.sales_sellingQuality;
      var business_no = this.state.businessNo;
      var buy_back_alloc = this.state.buy_back_alloc;
      // adding businessNumber Quantity allocation
      api
        .addBusinessNumberAllocation(
          loginUserID,
          idToken,
          business_no,
          businessNo,
          businessType,
          consignmentType,
          shipmentType,
          pc_purchase_type_id,
          purchase_contract_id,
          pc_qualities_id,
          purchase_contract_quality,
          procurement_quality_standard,
          quantity_allocated,
          sale_contract_id,
          sc_qualities_id,
          sc_sales_type_id,
          selling_quality_standard,
          buy_back_alloc
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    quantityRows: [],
                    buyBackQuantityRows: [],
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    errorMsg: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  successMsg: "",
                });
              }
              //alert('Unexpected error occured. Please contact administrator.');
            });
          }
        });
    } else {
      alert("Please fill Mandatory fields.");
    }
  };

  addQuantityAllocationData = () => {
    var item = {
      s_no: "0",
      purchase_contract: "",
      purchase_contractID: "",
      purchase_contractError: false,
      purchase_quality: [],
      purchase_types: [
        {
          quality: "",
          mine_name: "",
          purchase_type: "",
          quantity_in_mt: "",
          tolerance: "",
          anchorage_name: "",
          quantity_allocated: "",
        },
      ],
      purchase_procurement: "0",
      purchase_procurementError: false,
      purchase_quantityAllocating: "",
      sales_contract: "",
      sales_contractID: "",
      sales_contractError: false,
      sales_quality: [],
      sales_types: [
        {
          quality: "",
          mine_name: "",
          purchase_type: "",
          quantity_in_mt: "",
          tolerance: "",
          anchorage_name: "",
          quantity_allocated: "",
        },
      ],
      sales_sellingQuality: "0",
      quantityTobe_Delivered: "",
      quantityAllocationError: false,
      quantity_deliveredError: false,
    };

    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: [...this.state.buyBackQuantityRows, item],
      });
    } else {
      this.setState({
        quantityRows: [...this.state.quantityRows, item],
      });
    }
  };

  //! Remove Quantity Allocation row Handler
  RemoveQuantityAllocationRow = () => {
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: [],
      });
    } else {
      this.setState({
        quantityRows: [],
      });
    }
  };

  incrementORDecrementBusinessAllocation = (e, index, modalName) => {
    var data = e;
    // var purchaseQuantityAvailable = (Number(data.pc_quantity_adjusted)-Number(data.quantity_allocated)).toFixed(3);
    var purchaseQuantityAvailable = this._businessPurchaseToBeAllocated(data);
    //var salesQuantityAvailable =  (Number(data.sc_quantity_adjusted)-Number(data.quantity_allocated)).toFixed(3);
    var salesQuantityAvailable = this._businessSalesToBeAllocated(data);
    var quantityAllocating = Number(this.state.quantityAllocating).toFixed(3);
    var updateType = modalName;
    if (updateType === "increment") {
      if (
        Number(quantityAllocating) > Number(purchaseQuantityAvailable) ||
        Number(quantityAllocating) > Number(salesQuantityAvailable)
      ) {
        this.setState({
          quantityAllocationError: true,
          errorMsg: "Cannot increase more than contract value",
          snackBarErrorOpen: true,
        });
        return;
      }
    }

    if (updateType === "decrement") {
      var prev_quantityAllocating = Number(data.quantity_allocated).toFixed(3);
      if (Number(prev_quantityAllocating) < Number(quantityAllocating)) {
        this.setState({
          quantityAllocationError: true,
          errorMsg: "Cannot decrease more than allocated quantity",
          snackBarErrorOpen: true,
        });
        return;
      }

      // if( purchaseQuantityAvailable > quantityAllocating || salesQuantityAvailable > quantityAllocating) {
      //   this.setState({ quantityAllocationError : true, errorMsg: 'Cannot decrease more than allocated quantity' });
      //   return;
      // }
    }
    // Increment or Decrement BusinessNumber Allocation
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const businessNo = data.business_no_id;
    const businessAllocationID = data.busuiness_allocation_id;
    const purchaseTypeID = data.pc_type_id;
    const salesTypeID = data.sc_sales_id;
    const pc_qualities_id = data.pc_qualities_id;
    const sc_qualities_id = data.sc_qualities_id;

    // ! Checking mandatory fields.
    if (quantityAllocating === "0.000") {
      this.setState({
        quantityAllocationError: true,
      });
    }

    if (quantityAllocating !== "0.000") {
      api
        .updateBusinessNumberAllocation(
          loginUserID,
          idToken,
          businessNo,
          businessAllocationID,
          updateType,
          quantityAllocating,
          purchaseTypeID,
          pc_qualities_id,
          salesTypeID,
          sc_qualities_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMsg: res.message,
                    snackBarSuccessOpen: true,
                    openQuantityAllocation: false,
                    quantityAllocating: "",
                    errorMsg: "",
                  });
                  // window.$("#myModal").modal("hide");
                  this.componentDidMount();
                }
                if (res.code === "624") {
                  this.setState({
                    successMsg: "",
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                    quantityAllocationError: true,
                  });
                  alert(
                    "Quantity cannot be " +
                      updateType +
                      " more than contract value"
                  );
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      alert("Please enter quantity");
    }
  };

  handleQuantityRowsTextHandler = (index) => (e) => {
    this.setState({ modalID: index });
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    if (e.target.name === "purchaseProcurement") {
      row[index].purchase_procurement = e.target.value;
      row[index].purchase_procurementError = false;
    }
    if (e.target.name === "purchase_quantityAllocating") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        row[index].purchase_quantityAllocating = e.target.value;
        row[index].quantityAllocationError = false;
      }
    }
    if (e.target.name === "quantityTobe_Delivered") {
      const re = /^[0-9]*\.?[0-9]*$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        row[index].quantityTobe_Delivered = e.target.value;
        row[index].quantity_deliveredError = false;
      }
    }
    if (e.target.name === "saleSellingQuality") {
      row[index].sales_sellingQuality = e.target.value;
      row[index].sales_sellingQualityError = false;
    }

    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
  };

  handlePurchaseContract = (index, value) => {
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    if (value) {
      this.setState({ modalID: index });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      row[index].purchase_contract = value.name;
      row[index].purchase_contractError = false;
      window.$("#demo1" + this.state.purchaseQualitySelection).collapse("hide");
      var contractNo = value.value;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId = filteredContract[0].pcid;
      row[index].purchase_contractID = purchaseContractId;
      api
        .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                window.$("#purchaseModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
                this.setState({
                  purchaseTypes: res.purchase_contract.qualities,
                  purchaseCurrency: res.purchase_contract.currency,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].purchase_contract = "";
      row[index].purchase_types = [];
      row[index].purchase_quality = [];
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
  };

  handleUpdatePurchaseContract = (index, value) => {
    var row = this.state.business_number_allocation;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.business_number_buy_back_allocation;
    }
    if (value) {
      this.setState({ modalID: index });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      row[index].purchase_contract = value.name;
      row[index].purchase_contractError = false;
      window.$("#demo1" + this.state.purchaseQualitySelection).collapse("hide");
      var contractNo = value.value;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId = filteredContract[0].pcid;
      row[index].purchase_contractID = purchaseContractId;
      api
        .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                window.$("#updatePurchaseModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
                this.setState({
                  updatePurchaseContractID: purchaseContractId,
                  updatePurchaseTypes: res.purchase_contract.qualities,
                  purchaseCurrency: res.purchase_contract.currency,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].purchase_contract = "";
      row[index].purchase_types = [];
      row[index].purchase_quality = [];
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        business_number_buy_back_allocation: row,
      });
    } else {
      this.setState({
        business_number_allocation: row,
      });
    }
  };

  handleUpdateSalesContract = (index, value) => {
    var row = this.state.business_number_allocation;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.business_number_buy_back_allocation;
    }
    if (value) {
      this.setState({ modalID: index });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      row[index].sales_contract = value.value;
      row[index].sales_contractError = false;
      window.$("#demo" + this.state.salesQualitySelection).collapse("hide");
      let contractNo = value.value;
      var filteredSalesContract = this.state.salesContractsList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var salesContractId = filteredSalesContract[0].scid;
      row[index].sales_contractID = salesContractId;
      api
        .getSalesContract(loginUserID, salesContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.sales_contract) {
                this.setState({
                  updateSalesContractID: salesContractId,
                  updateSalesTypes: res.sales_contract.qualities,
                  salesCurrency: res.sales_contract.currency,
                });
                window.$("#updateSalesContractModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].sales_contract = "";
      row[index].sales_types = [];
      row[index].sales_quality = [];
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        business_number_buy_back_allocation: row,
      });
    } else {
      this.setState({
        business_number_allocation: row,
      });
    }
  };

  handleSalesContract = (index, value) => {
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    if (value) {
      this.setState({ modalID: index });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      row[index].sales_contract = value.value;
      row[index].sales_contractError = false;
      window.$("#demo" + this.state.salesQualitySelection).collapse("hide");
      let contractNo = value.value;
      var filteredSalesContract = this.state.salesContractsList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var salesContractId = filteredSalesContract[0].scid;
      row[index].sales_contractID = salesContractId;
      api
        .getSalesContract(loginUserID, salesContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.sales_contract) {
                this.setState({
                  salesTypes: res.sales_contract.qualities,
                  salesCurrency: res.sales_contract.currency,
                });
                window.$("#salesContractModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].sales_contract = "";
      row[index].sales_types = [];
      row[index].sales_quality = [];
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
  };

  purchaseContractModalClose = (modalID) => {
    //TODO: if modal can close to set sales_contract value ='0'
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    row[modalID].purchase_contract = "";
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
    window.$("#purchaseModal").modal("hide");
  };

  updatePurchaseContractModalClose = (modalID) => {
    //TODO: if modal can close to set sales_contract value ='0'
    var row = this.state.business_number_allocation;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.business_number_buy_back_allocation;
    }
    row[modalID].purchase_contract = "";
    // this.setState({
    //   business_number_allocation: this.state.buy_back_alloc !== "Yes" ? row : this.state.business_number_allocation,
    //   business_number_buy_back_allocation : this.state.buy_back_alloc === "Yes" ? row : this.state.business_number_buy_back_allocation,
    //    updateEnabled: true
    //   });
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        business_number_buy_back_allocation: row,
        updateEnabled: true,
      });
    } else {
      this.setState({
        business_number_allocation: row,
        updateEnabled: true,
      });
    }
    window.$("#updatePurchaseModal").modal("hide");
  };

  updateSalesContractModalClose = (modalID) => {
    //TODO: if modal can close to set sales_contract value ='0'
    var row = this.state.business_number_allocation;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.business_number_buy_back_allocation;
    }
    row[modalID].sales_contract = "";
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        business_number_buy_back_allocation: row,
        updateEnabled: true,
      });
    } else {
      this.setState({
        business_number_allocation: row,
        updateEnabled: true,
      });
    }
    window.$("#updateSalesContractModal").modal("hide");
  };

  handlePurchaseTypesRadioButtons = (e) => {
    if (e.target.name === "purchaseQualitySelection") {
      this.setState({
        purchaseQualitySelection: e.target.value,
      });
    }
    if (e.target.name === "purchaseTypeSelection") {
      this.setState({
        purchaseTypeSelection: e.target.value,
      });
    }
  };

  handleUpdatePurchaseTypesRadioButtons = (e, name) => {
    if (name === "updatePurchaseQualitySelectionID") {
      var remainingValue = this._toBeDelivered(e);
      var business_number_allocation = this.state.business_number_allocation;
      var filterPurchaseType = [];
      if (this.state.buy_back_alloc === "Yes") {
        business_number_allocation =
          this.state.business_number_buy_back_allocation;
        filterPurchaseType = business_number_allocation.filter(
          (p, idx) => idx === this.state.pc_contract_buy_back_index
        );
      } else {
        filterPurchaseType = business_number_allocation.filter(
          (p, idx) => idx === this.state.pc_contract_index
        );
      }
      if (
        Number(remainingValue) >=
        Number(filterPurchaseType[0].quantity_allocated)
      ) {
        this.setState({
          allocationPurchaseErrorId: null,
          updatePurchaseQualitySelectionID: e.id,
        });
      } else {
        this.setState({
          allocationPurchaseErrorId: e.id,
          updatePurchaseQualitySelectionID: null,
          allocationPurchaseErrorMessage:
            "Allocated value is greater than selected remaining quantity.",
        });
        // alert("Allocated value is greater than selected remaining quantity.")
        return;
      }
    }
    if (name === "updatePurchaseTypeSelectionID") {
      this.setState({
        updatePurchaseTypeSelectionID: e.id,
        updateEnabled: false,
      });
    }
  };

  handleUpdateSalesTypesRadioButtons = (e, name) => {
    if (name === "updateSalesQualitySelectionID") {
      var remainingValue = this._toBeDelivered(e);
      var business_number_allocation = this.state.business_number_allocation;
      var filterPurchaseType = [];
      if (this.state.buy_back_alloc === "Yes") {
        business_number_allocation =
          this.state.business_number_buy_back_allocation;
        filterPurchaseType = business_number_allocation.filter(
          (p, idx) => idx === this.state.sc_contract_buy_back_index
        );
      } else {
        filterPurchaseType = business_number_allocation.filter(
          (p, idx) => idx === this.state.sc_contract_index
        );
      }
      if (
        Number(remainingValue) >=
        Number(filterPurchaseType[0].quantity_allocated)
      ) {
        this.setState({
          allocationSalesErrorId: null,
          updateSalesQualitySelectionID: e.id,
        });
      } else {
        this.setState({
          allocationSalesErrorId: e.id,
          updateSalesQualitySelectionID: null,
          allocationSalesErrorMessage:
            "Allocated value is greater than selected remaining quantity.",
        });
        // alert("Allocated value is greater than selected remaining quantity.")
        return;
      }
    }
    if (name === "updateSalesTypeSelectionID") {
      this.setState({
        updateSalesTypeSelectionID: e.id,
        updateEnabled: false,
      });
    }
  };

  handleSalesTypesRadioButtons = (e) => {
    if (e.target.name === "salesQualitySelection") {
      this.setState({
        salesQualitySelection: e.target.value,
      });
    }
    if (e.target.name === "salesTypeSelection") {
      this.setState({
        salesTypeSelection: e.target.value,
      });
    }
  };

  //! Purchase Contract On Select Quality
  purchaseTypeSelected = (modalID) => {
    var selectedQuality = this.state.purchaseQualitySelection;
    var selectedPurchaseType = this.state.purchaseTypeSelection;
    var filterSelectedQuality = this.state.purchaseTypes.filter(
      (e, index) => e.id === selectedQuality
    );
    if (filterSelectedQuality.length > 0) {
      //TODO : check purchase Type is available or not in Quality Row
      var filterPurchaseType = filterSelectedQuality[0].purchase_types.filter(
        (p, idx) => p.id === selectedPurchaseType
      );
      if (filterPurchaseType.length === 0) {
        alert(
          "Purchase Type Selection Invalid. please select valid purchase type"
        );
        return;
      }
    } else {
      alert("Please Select Quality");
      return;
    }
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    row[modalID].purchase_types = filterPurchaseType;
    row[modalID].purchase_quality = filterSelectedQuality;
    if (filterSelectedQuality.length > 0) {
      if (filterSelectedQuality[0].specification_standard === "ASTM/ISO") {
        row[modalID].purchase_procurement = "0";
      } else {
        row[modalID].purchase_procurement =
          filterSelectedQuality[0].specification_standard;
      }
      if (filterSelectedQuality[0].purchasing_term === "Non Specs") {
        row[modalID].purchase_procurement =
          filterSelectedQuality[0].purchasing_term;
        filterSelectedQuality[0].specification_standard =
          filterSelectedQuality[0].purchasing_term;
      }
      this.setState({
        PC_specification_standard:
          filterSelectedQuality[0].specification_standard,
      });
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({ quantityRows: row });
    }
    window.$("#purchaseModal").modal("hide");
  };

  updatePurchaseTypeSelected = (modalID) => {
    this.setState({
      updateButtonEnabled: true,
      updateEnabled: true,
    });
    var {
      allocationID,
      updatePurchaseContractID,
      updatePurchaseQualitySelectionID,
      updatePurchaseTypeSelectionID,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    //var businessNo = decodeURIComponent(window.atob(this.props.match.params.businessID));
    // return;
    //Update purchase contract in Allocation
    api
      .updatePurchaseContractAllocation(
        loginUserID,
        idToken,
        allocationID,
        updatePurchaseContractID,
        updatePurchaseQualitySelectionID,
        updatePurchaseTypeSelectionID
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              this.setState(
                {
                  updateEnabled: true,
                  updateButtonEnabled: false,
                  updatePurchaseContractID: null,
                  updatePurchaseQualitySelectionID: null,
                  updatePurchaseTypeSelectionID: null,
                  allocationID: null,
                  successMsg:
                    "Purchase Contract has been updated Successfully. Please make sure to update Financial data.",
                  snackBarSuccessOpen: true,
                  pc_contract_editable: false,
                  pc_contract_index: "",
                },
                () => this.componentDidMount()
              );
              window.$("#updatePurchaseModal").modal("hide");
              // window.location.reload();
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
  };

  updateSalesTypeSelected = (modalID) => {
    this.setState({
      updateButtonEnabled: true,
      updateEnabled: true,
    });
    var {
      allocationID,
      updateSalesContractID,
      updateSalesQualitySelectionID,
      updateSalesTypeSelectionID,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    //var businessNo = decodeURIComponent(window.atob(this.props.match.params.businessID));
    // return;
    //Update purchase contract in Allocation
    api
      .updateSalesContractAllocation(
        loginUserID,
        idToken,
        allocationID,
        updateSalesContractID,
        updateSalesQualitySelectionID,
        updateSalesTypeSelectionID
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              // alert("Sales Contract has been updated Successfully. Please make sure to update Financial data.");
              this.setState(
                {
                  updateEnabled: true,
                  updateButtonEnabled: false,
                  updateSalesContractID: null,
                  updateSalesQualitySelectionID: null,
                  updateSalesTypeSelectionID: null,
                  allocationID: null,
                  successMsg:
                    "Sales Contract has been updated Successfully. Please make sure to update Financial data.",
                  snackBarSuccessOpen: true,
                  sc_contract_editable: false,
                  sc_contract_index: "",
                },
                () => this.componentDidMount()
              );
              window.$("#updateSalesContractModal").modal("hide");
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
  };

  //! Sales Contract on Select Quality & Sales Type
  salesTypeSelected = (modalID) => {
    var selectedSalesQuality = this.state.salesQualitySelection;
    var selectedSalesType = this.state.salesTypeSelection;
    var filterSalesQuality = this.state.salesTypes.filter(
      (e, index) => e.id === selectedSalesQuality
    );
    if (filterSalesQuality.length > 0) {
      //TODO : check Sales Type is available or not in Quality Row
      var filterSalesType = filterSalesQuality[0].sales_types.filter(
        (s, idx) => s.id === selectedSalesType
      );
      if (filterSalesType.length === 0) {
        alert("Sales Type Selection Invalid. please select valid sales type");
        return;
      }
    } else {
      alert("Please Select Quality");
      return;
    }
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    row[modalID].sales_types = filterSalesType;
    row[modalID].sales_quality = filterSalesQuality;
    if (filterSalesQuality.length > 0) {
      if (filterSalesQuality[0].specification_standard === "ASTM/ISO") {
        row[modalID].sales_sellingQuality = "0";
      } else {
        row[modalID].sales_sellingQuality =
          filterSalesQuality[0].specification_standard;
      }
      if (filterSalesQuality[0].sales_term === "Non Specs") {
        row[modalID].sales_sellingQuality = filterSalesQuality[0].sales_term;
        filterSalesQuality[0].specification_standard =
          filterSalesQuality[0].sales_term;
      }
      this.setState({
        SC_specification_standard: filterSalesQuality[0].specification_standard,
      });
    }
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
    window.$("#salesContractModal").modal("hide");
  };

  salesContractModalClose = (modalID) => {
    //TODO: if modal can close to set purchase_contract value ='0'
    var row = this.state.quantityRows;
    if (this.state.buy_back_alloc === "Yes") {
      row = this.state.buyBackQuantityRows;
    }
    row[modalID].sales_contract = "";
    if (this.state.buy_back_alloc === "Yes") {
      this.setState({
        buyBackQuantityRows: row,
      });
    } else {
      this.setState({
        quantityRows: row,
      });
    }
    window.$("#salesContractModal").modal("hide");
  };

  //TODO: Calculationg Remaining Quality for purchaseContract_Quantities & SalesContract_Quantites
  _toBeDelivered(data) {
    if (data) {
      if (data.purchase_contract_id) {
        var remainingPurchaseQuality = Number(
          Number(data.quantity_adjusted) - Number(data.quantity_allocated)
        );
        return remainingPurchaseQuality;
      } else if (data.sale_contract_id) {
        var remainingSalesQuality = Number(
          Number(data.quantity_adjusted) - Number(data.quantity_allocated)
        );
        return remainingSalesQuality;
      }
    }
  }

  //TODO: Calculating the TO Be Allocated value in Purchase contract
  _businessPurchaseToBeAllocated(e) {
    if (e) {
      var remainingPurchaseQuality = Number(
        Number(e.pc_quantity_adjusted) - Number(e.pc_quantity_allocated)
      );
      return remainingPurchaseQuality;
    }
  }

  //TODO: Calculating the TO Be Allocated value in Sales contract
  _businessSalesToBeAllocated(e) {
    if (e) {
      var remainingSalesQuality = Number(
        Number(e.sc_quantity_adjusted) - Number(e.sc_quantity_allocated)
      );
      return remainingSalesQuality;
    }
  }

  viewMenuClose = () => {
    this.setState({
      viewMenuOpen: false,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  // TODO Excel method handler
  convertExcelHandler = (data, filename) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Allocation ID": obj.display_allocation_id,
        "Barge Nomination": obj.barge_nomination,
        "Loading Jetty": obj.loading_jetty,
        Shipper: obj.mine_name ? obj.mine_name : obj.coal_vendor_name,
        "Purchase Quality": obj.pc_quality,
        "Barge Quantity": obj.barge_quantity ? Number(obj.barge_quantity) : "",
        "Redraft Quantity": obj.redraft_quantity,
        "Barge Status": obj.barge_status,
        "Internal Surveyor": obj.internal_surveyor,
        "Supplier Surveyor": obj.supplier_surveyor,
        "Barge Captain Phone No": obj.barge_captain_phone_no,
        Supplier_barge_window: obj.supplier_barge_window,
        "Buyer Barge Window": obj.buyer_barge_window,
        "Loading Completion Time": localDateFormate(
          obj.Loading_completion_time
        ),
        "Nor Anchorage": localDateFormate(obj.nor_anchorage),
        "Commence Discharge": localDateFormate(obj.commence_discharge),
        "Completion Discharge": localDateFormate(obj.completion_discharge),
      };
    });
    exportToExcel(Modified, filename + "-mapped-barges");
  };

  UpdateQualityStandardHandler = (data) => {
    this.setState({
      isLoading: true,
    });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var businessAllocationID = data;
    var type = this.state.type;
    var selling_quality_standard = this.state.sc_quality_std;
    var procurement_quality_standard = this.state.pc_quality_std;
    if (type === "procurement") {
      selling_quality_standard = "";
    } else if (type === "selling") {
      procurement_quality_standard = "";
    }
    api
      .updateQualityStandard(
        loginUserID,
        idToken,
        businessAllocationID,
        type,
        selling_quality_standard,
        procurement_quality_standard
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  pc_quality_std_edit: false,
                  sc_quantity_std_edit: false,
                  pc_buy_back_quality_std_edit: false,
                  sc_buy_back_quality_std_edit: false,
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  TabsHandler = (event, value) => {
    this.setState({
      activeTabIndex: value,
    });
  };

  businessTabsHandler = (event, value) => {
    this.setState({
      businessTabIndex: value,
    });
  };

  // ! Update barge status And Dates Method Handler

  editBargeStatusHandler = () => {
    const UpdatedBarges = [];
    var totalBarges = this.state.totalBarges;
    totalBarges.forEach((item) => {
      UpdatedBarges.push({
        business_allocation_id: item.business_allocation_id,
        barge_id: item.barge_id,
        barge_status: item.barge_status,
        barge_quantity_in_mt: item.barge_quantity,
        loading_date: item.loading_date,
        Loading_completion_time: item.Loading_completion_time,
        nor_anchorage: item.nor_anchorage,
        commence_discharge: item.commence_discharge,
        completion_discharge: item.completion_discharge,
      });
    });

    this.setState({
      UpdatedBarges: UpdatedBarges,
      EditStatusFields: true,
    });
  };

  UpdateBargeStatusHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const UpdatedBarges = this.state.UpdatedBarges;
    const businessNoID = this.state.businessNoID;
    api
      .update_barges_status_and_dates(
        loginUserID,
        idToken,
        businessNoID,
        UpdatedBarges
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  successMsg: res.message,
                  errorMsg: "",
                  snackBarSuccessOpen: true,
                  EditStatusFields: false,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState(
                {
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  BusinessNoStatusOpenMenu = (event) => {
    this.setState({
      BusinessNoStatusOpenMenu: true,
      anchorEl: event.currentTarget,
    });
  };

  BusinessNoStatusMenuClose = (event) => {
    this.setState({
      BusinessNoStatusOpenMenu: false,
      anchorEl: event.currentTarget,
    });
  };

  BusinessNoCloseHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var status = "Closed";
    try {
      var business_no_id = decodeURIComponent(
        window.atob(this.props.match.params.businessID)
      );
      api
        .update_business_number_status(
          loginUserID,
          idToken,
          business_no_id,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: "Business Number has been Closed successfully",
                    errorMsg: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                this.setState({
                  successMsg: "",
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
  };

  BusinessNoCancelHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var status = "Cancelled";
    try {
      var business_no_id = decodeURIComponent(
        window.atob(this.props.match.params.businessID)
      );
      api
        .update_business_number_status(
          loginUserID,
          idToken,
          business_no_id,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg:
                      "Business Number has been Cancelled successfully",
                    errorMsg: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                this.setState({
                  successMsg: "",
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
  };

  progressBarSize(data, type) {
    if (data) {
      if (type === "PC") {
        var remainingPurchaseQuality =
          Number(
            Number(data.pc_quantity_allocated) /
              Number(data.pc_quantity_adjusted)
          ) * 100;
        return remainingPurchaseQuality;
      } else if (type === "SC") {
        var remainingSalesQuality =
          Number(
            Number(data.sc_quantity_allocated) /
              Number(data.sc_quantity_adjusted)
          ) * 100;
        return remainingSalesQuality;
      } else if (type === "NPC") {
        remainingPurchaseQuality =
          Number(
            Number(data.quantity_allocated) / Number(data.quantity_adjusted)
          ) * 100;
        return remainingPurchaseQuality;
      } else if (type === "NSC") {
        remainingSalesQuality =
          Number(
            Number(data.quantity_allocated) / Number(data.quantity_adjusted)
          ) * 100;
        return remainingSalesQuality;
      }
    }
  }

  handleSuccessClose = () => {
    this.setState({ snackBarSuccessOpen: false });
  };

  handleErrorClose = () => {
    this.setState({ snackBarErrorOpen: false });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDrawer: childData,
      motherVesselDrawer: false,
      MotherVesselAdd: false,
      MotherVesselUpdate: false,
      openMapBarges: false,
      salesQualityResultsAdd: false,
    });
  };

  editCallback = (childData) => {
    this.setState({
      openDrawer: true,
      salesQualityResultsAdd: false,
      salesQualityResultsUpdate:
        childData === "salesQualityResults" ? true : false,
      salesQualityResultsView: false,
    });
  };

  onAddResponse = (value) => {
    this.setState({
      salesQualityResultsAdd: false,
      salesQualityResultsView: false,
      salesQualityResultsUpdate: false,
      openDrawer: false,
    });
  };

  CallbackViewPage = (childData) => {
    this.setState({
      openDrawer: true,
      salesQualityResultsAdd: false,
      salesQualityResultsView:
        childData === "salesQualityResults" ? true : false,
      salesQualityResultsUpdate: false,
    });
  };

  onGetResponse = (data) => {
    if (data === "200") {
      this.setState(
        {
          motherVesselDrawer: false,
          openMapBarges: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  isShownDeadFreight = () => {
    const { stowage_plan_in_mt = 0, vessel_final_quantity_in_mt = 0 } =
      this.state;
    return (
      Number(stowage_plan_in_mt ? stowage_plan_in_mt : 0) -
        Number(vessel_final_quantity_in_mt ? vessel_final_quantity_in_mt : 0) >
      0
    );
  };

  MvDeadFreightValue = () => {
    const { stowage_plan_in_mt = 0, vessel_final_quantity_in_mt = 0 } =
      this.state;

    return (
      Number(stowage_plan_in_mt ? stowage_plan_in_mt : 0) -
      Number(vessel_final_quantity_in_mt ? vessel_final_quantity_in_mt : 0)
    );
  };

  render() {
    let drawerComponent;
    try {
      var businessNo = decodeURIComponent(
        window.atob(this.props.match.params.businessID)
      );
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    // let drawerComponent;

    if (
      this.state.salesQualityResultsView &&
      businessNo &&
      this.state.comboID
    ) {
      drawerComponent = (
        <SalesQualityResultsView
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
        />
      );
    }
    if (
      this.state.salesQualityResultsUpdate &&
      businessNo &&
      this.state.comboID
    ) {
      drawerComponent = (
        <SalesQualityResultsUpdate
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnCallbackViewPage={this.CallbackViewPage}
        />
      );
    }

    if (this.state.salesQualityResultsAdd) {
      drawerComponent = (
        <SalesQualityResultsAdd
          businessNoID={businessNo}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    var motherVesselDrawer;
    if (this.state.MotherVesselAdd) {
      motherVesselDrawer = (
        <MotherVesselAdd
          businessNoID={businessNo}
          onGetResponse={this.onGetResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (
      this.state.MotherVesselUpdate &&
      this.state.motherVesselID !== null
    ) {
      motherVesselDrawer = (
        <MotherVesselUpdate
          motherVesselID={this.state.motherVesselID}
          onGetResponse={this.onGetResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    var mapBargesDrawer;
    if (this.state.openMapBarges) {
      mapBargesDrawer = (
        <MapBarges
          businessNoID={businessNo}
          onGetResponse={this.onGetResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div>
              <div className="clearfix row content-header">
                <div className="row col-lg-8 text-left pl-0">
                  <span></span>
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    <a href={"/business-number-list"}>
                      <i className="fa fa-arrow-left left_arrow_adjst" />
                    </a>
                    {this.state.businessNo}
                    {this.state.mother_vesselName !== ""
                      ? " (" + this.state.mother_vesselName + ")"
                      : ""}
                    <span
                      className={
                        this.state.status === "Active" ? "type_1" : "type_2"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {this.state.status}
                    </span>
                  </h4>
                </div>
                <div className="col-lg text-right float-sm-left pt-3 pr-4">
                  <i
                    data-toggle="tooltip"
                    title="Action"
                    data-html="true"
                    data-placement="bottom"
                    className="fa fa-ellipsis-v"
                    aria-hidden="true"
                    style={{
                      fontSize: 22,
                      color: config.themeColor,
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={this.BusinessNoStatusOpenMenu}
                  ></i>
                  {/* <span className="mr-1" data-toggle="tooltip" title="Action" data-placement="bottom" ><DashboardIcon style={{ fontSize: 30, color: "#138496", cursor: 'pointer', marginTop: "-25px" }} onClick={this.BusinessNoStatusOpenMenu} /></span> */}
                </div>
              </div>
              <div className="container p-3">
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={this.state.snackBarErrorOpen}
                  autoHideDuration={10000}
                  onClose={this.handleErrorClose}
                  style={{ width: "450px" }}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleErrorClose}
                    severity="error"
                    style={{ width: "100%" }}
                  >
                    {this.state.errorMsg}
                  </Alert>
                </Snackbar>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{ width: "450px" }}
                  open={this.state.snackBarSuccessOpen}
                  autoHideDuration={10000}
                  onClose={this.handleSuccessClose}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSuccessClose}
                    severity="success"
                    style={{ width: "100%" }}
                  >
                    {this.state.successMsg}
                  </Alert>
                </Snackbar>
                <div className="card p-0">
                  <Drawer
                    anchor="right"
                    open={this.state.showDrawer}
                    variant="temporary"
                    elevation={20}
                    style={{ overflow: "initial" }}
                  >
                    <div className="row" style={{ width: 800 }}>
                      {drawerComponent}
                    </div>
                  </Drawer>
                  <Tabs
                    value={this.state.businessTabIndex}
                    onChange={this.businessTabsHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: { background: config.themeColor },
                    }}
                    className="border-bottom"
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.businessTabIndex === 0
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Shipment
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    {(this.state.features.includes("43") ||
                      this.state.features.includes("46") ||
                      this.state.features.includes("97")) && (
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.businessTabIndex === 1
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Operational
                          </h4>
                        }
                        value={1}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                    )}
                    {(this.state.features.includes("43") ||
                      this.state.features.includes("46") ||
                      this.state.features.includes("77") ||
                      this.state.features.includes("97")) && (
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.businessTabIndex === 2
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Financial
                          </h4>
                        }
                        value={2}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                    )}
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.businessTabIndex === 3
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Notify Party
                        </h4>
                      }
                      value={3}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    {(this.state.features.includes("329") ||
                      this.state.features.includes("330") ||
                      this.state.features.includes("331") ||
                      this.state.features.includes("97")) && (
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.businessTabIndex === 4
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Vessel Completion Report
                          </h4>
                        }
                        value={4}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                    )}
                  </Tabs>
                  {this.state.businessTabIndex === 0 && (
                    <div className="card p-0">
                      <div className="mb-2">
                        <h5 className="ml-3 p-2 sub_heading_label mb-0 mt-3">
                          Shipment Planning
                          {this.state.status === "Active" && (
                            <a
                              href={
                                "/update-business/" +
                                btoa(this.state.business_no_id)
                              }
                              rel="noopener noreferrer"
                              role="button"
                              data-html="true"
                              data-toggle="tooltip1"
                              title="Update Business No"
                              data-placement="bottom"

                              // style={{ fontSize: '20px', color: config.themeColor, cursor: 'pointer', paddingLeft: '15px', verticalAlign: 'middle' }}
                            >
                              <i className="fa fa-pencil edit_icon" />
                            </a>
                          )}
                        </h5>
                        <div className="row">
                          {/* col1
                          -------------------------------------------------- */}
                          <div className="col-sm-3 pt-0 pl-0">
                            <div className="col m-3 pl-0">
                              <div
                                className="row mb-0"
                                style={{ display: "inline-block" }}
                              >
                                <label className="contract_display_header_label">
                                  Business Number
                                </label>
                              </div>
                              <label className="contract_display_header_value">
                                {this.state.businessNo}
                              </label>
                            </div>

                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Shipment Type
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.shipment_type}
                              </label>
                            </div>

                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Vessel Arrived
                              </label>
                              <label className="contract_display_header_value">
                                {localDateFormate(this.state.vessel_arrived_on)}
                              </label>
                            </div>

                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Floating Crane Name
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.floating_crane_name !== ""
                                        ? this.state.floating_crane_name
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Load Port Agent
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.load_port_agent !== ""
                                        ? this.state.load_port_agent
                                        : "-"}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  ETA / NOR
                                </label>
                                <label className="contract_display_header_value">
                                  {localTimeFormate(this.state.eta_nor)}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Vessel Freight
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.vessel_freight !== ""
                                        ? Number(
                                            this.state.vessel_freight
                                          ).toLocaleString()
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      ETA / NOR
                                    </label>
                                    <label className="contract_display_header_value">
                                      {localTimeFormate(this.state.eta_nor)}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Supply Tonnage in MT
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.supply_tonnage_in_mt !== ""
                                    ? Number(
                                        this.state.supply_tonnage_in_mt
                                      ).toLocaleString()
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {(this.state.shipment_type === "CFR MV" ||
                              this.state.shipment_type === "CIF MV") && (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Vessel Owner
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.vessel_owner_name !== ""
                                    ? this.state.vessel_owner_name
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {(this.state.shipment_type === "CFR MV" ||
                              this.state.shipment_type === "CIF MV") &&
                              (this.state.features.includes("122") ||
                                this.state.features.includes("97")) && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Vessel Freight Price
                                  </label>
                                  <label className="contract_display_header_value">
                                    {this.state.vessel_freight_price
                                      ? this.toLocaleString(
                                          this.state.vessel_freight_price
                                        )
                                      : "-"}
                                  </label>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type === "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Supply Tonnage in MT
                                  </label>
                                  <label className="contract_display_header_value">
                                    {this.state.supply_tonnage_in_mt !== ""
                                      ? Number(
                                          this.state.supply_tonnage_in_mt
                                        ).toLocaleString()
                                      : "-"}
                                  </label>
                                </div>
                              )}
                          </div>

                          {/* col2
                          -------------------------------------------------- */}
                          <div className="col-sm-3 pt-0 pl-0">
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Business Head
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.business_head_name
                                  ? this.state.business_head_name
                                  : "-"}
                              </div>
                            </div>

                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Business Type
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.businessType !== ""
                                  ? this.state.businessType
                                  : "-"}
                              </div>
                            </div>

                            {this.state.consignment_type ===
                              "Mother Vessel" && (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Vessel Type
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.vessel_type !== ""
                                    ? this.state.vessel_type
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {this.state.consignment_type === "Barge" && (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Load Rate per MT/Day
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.ld_rte_pr_mt_day !== ""
                                    ? Number(
                                        this.state.ld_rte_pr_mt_day
                                      ).toLocaleString()
                                    : "-"}
                                </div>
                              </div>
                            )}

                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                {" "}
                                NOR Acceptance
                              </label>
                              <div className="contract_display_header_value">
                                {localTimeFormate(this.state.nor_acceptance)}
                              </div>
                            </div>

                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Loading Anchorage
                                    </label>

                                    <div className="contract_display_header_value">
                                      {this.state.loading_anchorage !== ""
                                        ? this.state.loading_anchorage
                                        : "-"}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Load Rate per MT/Day
                                    </label>

                                    <div className="contract_display_header_value">
                                      {this.state.ld_rte_pr_mt_day !== ""
                                        ? Number(
                                            this.state.ld_rte_pr_mt_day
                                          ).toLocaleString()
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Documents on-board
                                </label>
                                <div className="contract_display_header_value">
                                  {localTimeFormate(
                                    this.state.documents_on_board
                                  )}
                                </div>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Shipment Laycan
                                    </label>

                                    <div className="contract_display_header_value">
                                      {this.state.shipment_laycan !== ""
                                        ? this.state.shipment_laycan
                                        : "-"}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Documents on-board
                                    </label>

                                    <div className="contract_display_header_value">
                                      {localTimeFormate(
                                        this.state.documents_on_board
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Remarks
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {this.state.remarks !== ""
                                    ? this.state.remarks
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Stowage Plan in MT
                                  </label>
                                  <div className="contract_display_header_value">
                                    {this.state.stowage_plan_in_mt !== ""
                                      ? Number(
                                          this.state.stowage_plan_in_mt
                                        ).toLocaleString()
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type === "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Remarks
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {this.state.remarks !== ""
                                      ? this.state.remarks
                                      : "-"}
                                  </div>
                                </div>
                              )}

                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Vessel Sailing
                                  </label>
                                  <div className="contract_display_header_value">
                                    {localTimeFormate(
                                      this.state.vessel_sailing
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>

                          {/* col3
                          -------------------------------------------------- */}

                          <div className="col-sm-3 pt-0 pl-0">
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Consignment Type
                              </label>

                              <div className="contract_display_header_value">
                                {this.state.consignment_type !== ""
                                  ? this.state.consignment_type
                                  : "-"}
                              </div>
                            </div>
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Stevedore Vendor
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.stevedore_vendor_names !== ""
                                        ? this.state.stevedore_vendor_names
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Floating Crane Name
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.floating_crane_name !== ""
                                        ? this.state.floating_crane_name
                                        : "-"}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Vessel Freight
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.vessel_freight !== ""
                                    ? Number(
                                        this.state.vessel_freight
                                      ).toLocaleString()
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Load Port Agent
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.load_port_agent !== ""
                                        ? this.state.load_port_agent
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Vessel Freight
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.vessel_freight !== ""
                                        ? Number(
                                            this.state.vessel_freight
                                          ).toLocaleString()
                                        : "-"}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Vessel Sailing
                                </label>
                                <label className="contract_display_header_value">
                                  {localTimeFormate(this.state.vessel_sailing)}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    ETA / NOR
                                  </label>
                                  <label className="contract_display_header_value">
                                    {localTimeFormate(this.state.eta_nor)}
                                  </label>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type === "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Vessel Sailing
                                  </label>
                                  <label className="contract_display_header_value">
                                    {localTimeFormate(
                                      this.state.vessel_sailing
                                    )}
                                  </label>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Supply Tonnage in MT
                                  </label>
                                  <label className="contract_display_header_value">
                                    {this.state.supply_tonnage_in_mt !== ""
                                      ? Number(
                                          this.state.supply_tonnage_in_mt
                                        ).toLocaleString()
                                      : "-"}
                                  </label>
                                </div>
                              )}
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Demurrage Rate from Buyer
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.demurrage_rate_from_buyer !== ""
                                  ? this.state.demurrage_rate_from_buyer
                                  : "-"}
                              </label>
                            </div>
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Buy Back Involved
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.buy_back_involved !== ""
                                  ? this.state.buy_back_involved
                                  : "-"}
                              </label>
                            </div>
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Business Flow
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.businessFlow !== ""
                                  ? this.state.businessFlow
                                  : "-"}
                              </label>
                            </div>
                          </div>

                          {/* col4
                          -------------------------------------------------- */}

                          <div className="col-sm-3 pt-0 pl-0">
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Mother Vessel Name
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.mother_vesselName !== ""
                                    ? this.state.mother_vesselName
                                    : "-"}
                                </label>
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Barge Name
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.barge_name !== ""
                                    ? this.state.barge_name
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Floating Crane Vendor
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state
                                        .floating_crane_vendor_names !== ""
                                        ? this.state.floating_crane_vendor_names
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Loading Anchorage
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.loading_anchorage !== ""
                                        ? this.state.loading_anchorage
                                        : "-"}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Shipment Laycan
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.shipment_laycan !== ""
                                    ? this.state.shipment_laycan
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" ? (
                              <div>
                                {this.state.shipment_type !== "FAS MV" ? (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Load Rate per MT/Day
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.ld_rte_pr_mt_day !== ""
                                        ? Number(
                                            this.state.ld_rte_pr_mt_day
                                          ).toLocaleString()
                                        : "-"}
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col m-3 pl-0">
                                    <label className="contract_display_header_label">
                                      Shipment Laycan
                                    </label>
                                    <label className="contract_display_header_value">
                                      {this.state.shipment_laycan !== ""
                                        ? this.state.shipment_laycan
                                        : "-"}
                                    </label>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col m-3 pl-0">
                                <label className="contract_display_header_label">
                                  Stowage Plan in MT
                                </label>
                                <label className="contract_display_header_value">
                                  {this.state.stowage_plan_in_mt !== ""
                                    ? Number(
                                        this.state.stowage_plan_in_mt
                                      ).toLocaleString()
                                    : "-"}
                                </label>
                              </div>
                            )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Documents on-board
                                  </label>
                                  <label className="contract_display_header_value">
                                    {localTimeFormate(
                                      this.state.documents_on_board
                                    )}
                                  </label>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type === "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Stowage Plan in MT
                                  </label>
                                  <label className="contract_display_header_value">
                                    {this.state.stowage_plan_in_mt !== ""
                                      ? Number(
                                          this.state.stowage_plan_in_mt
                                        ).toLocaleString()
                                      : "-"}
                                  </label>
                                </div>
                              )}
                            {this.state.consignment_type === "Mother Vessel" &&
                              this.state.shipment_type !== "FAS MV" && (
                                <div className="col m-3 pl-0">
                                  <label className="contract_display_header_label">
                                    Remarks
                                  </label>
                                  <label
                                    className="contract_display_header_value"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {this.state.remarks !== ""
                                      ? this.state.remarks
                                      : "-"}
                                  </label>
                                </div>
                              )}
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Demurrage Rate to Supplier
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.demurrage_rate_to_supplier !== ""
                                  ? this.state.demurrage_rate_to_supplier
                                  : "-"}
                              </label>
                            </div>
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Status
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.status !== ""
                                  ? this.state.status
                                  : "-"}
                              </label>
                            </div>
                            <div className="col m-3 pl-0">
                              <label className="contract_display_header_label">
                                Supplier Reference No
                              </label>
                              <label className="contract_display_header_value">
                                {this.state.supplierReferenceNumber !== ""
                                  ? this.state.supplierReferenceNumber
                                  : "-"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      {/**
                       *
                       *
                       *
                       *  TODO /// Business No Allocation data
                       *
                       *
                       */}

                      <div className="row">
                        <div className="col-lg p-0">
                          <h5 className="ml-2 p-2 sub_heading_label mb-0">
                            Quantity Allocation
                          </h5>
                          <div className="p-3 lr-3 table-responsive ">
                            <table className="border-0 root">
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 180 }}
                                  >
                                    Contract No
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 150 }}
                                  >
                                    Supplier/Buyer
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Quality
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Tolerance
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 160 }}
                                    nowrap="true"
                                  >
                                    Inco Terms
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 200 }}
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    className="allocation_table_header_label text-center"
                                    style={{ minWidth: 150 }}
                                  >
                                    Allocated
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 150 }}
                                  >
                                    Quality Standard
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  ></th>
                                </tr>
                              </thead>
                              {this.state.business_number_allocation.map(
                                (e, index) => (
                                  <tbody
                                    className="allocation_box_shadow"
                                    key={index}
                                  >
                                    <tr>
                                      {this.state.pc_contract_editable &&
                                      this.state.pc_contract_index === index &&
                                      this.state.status === "Active" ? (
                                        <td
                                          className="allocation_table_value"
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          <span className="allocation_value">
                                            {e.display_allocation_id}
                                          </span>
                                          <Autocomplete
                                            options={
                                              this.state.purchaseDataForMenu
                                            }
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            name="purchaseContract"
                                            value={e.purchase_contract}
                                            onChange={(event, value) =>
                                              this.handleUpdatePurchaseContract(
                                                index,
                                                value
                                              )
                                            }
                                            renderOption={(option) => (
                                              <React.Fragment>
                                                {option.name} (
                                                {option.vendor_name})
                                              </React.Fragment>
                                            )}
                                            size="small"
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Purchase Contract *"
                                                label="Purchase Contract *"
                                                // error={this.state.quantityRows[index].purchase_contractError}
                                                // inputProps={{ style:{padding: '2px'}}}
                                                variant="outlined"
                                                style={{ width: 230 }}
                                              />
                                            )}
                                          />
                                        </td>
                                      ) : (
                                        <td
                                          className="allocation_table_value"
                                          nowrap="true"
                                        >
                                          <span className="allocation_value">
                                            {e.display_allocation_id}
                                          </span>
                                          <a
                                            href={
                                              "/view-purchase-contract/" +
                                              btoa(e.pc_contract_id)
                                            }
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{
                                              color: config.themeColor,
                                              position: "relative",
                                              top: "-8px",
                                            }}
                                          >
                                            {e.pc_contract_no}
                                          </a>
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  position: "relative",
                                                  top: "-11px",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    pc_contract_editable: true,
                                                    pc_contract_index: index,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      <td className="allocation_table_value pl-0">
                                        {e.supplier_name}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_qualities_quality}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_tolerance_adjusted + "%"}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_purchase_type}
                                      </td>
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e, "PC")}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.pc_quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._businessPurchaseToBeAllocated(
                                                  e
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        rowSpan={2}
                                        className="allocation_table_value text-center"
                                      >
                                        <i
                                          title="Increase Allocation"
                                          data-html="true"
                                          data-placement="bottom"
                                          className="fa fa-arrow-circle-o-up mr-2"
                                          style={{
                                            fontSize: 22,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          // onClick={() =>
                                          //   this.setState({
                                          //     modalName: "increment",
                                          //     index: index,
                                          //     rowValue: e
                                          //   })
                                          // }
                                          onClick={(l) => {
                                            this.setState({
                                              modalName: "increment",
                                              index: index,
                                              rowValue: e,
                                              openQuantityAllocation: true,
                                              anchorEl: l.currentTarget,
                                            });
                                          }}
                                          // data-toggle="modal"
                                          // data-target="#myModal"
                                        ></i>
                                        {this.toLocaleString(
                                          Number(e.quantity_allocated).toFixed(
                                            3
                                          )
                                        )}
                                        <i
                                          title="Decrease Allocation"
                                          data-html="true"
                                          data-placement="bottom"
                                          className="fa fa-arrow-circle-o-down ml-2"
                                          style={{
                                            fontSize: 22,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          // onClick={() => this.setState({ modalName: "decrement", index: index, rowValue: e })}
                                          onClick={(l) => {
                                            this.setState({
                                              modalName: "decrement",
                                              index: index,
                                              rowValue: e,
                                              openQuantityAllocation: true,
                                              anchorEl: l.currentTarget,
                                            });
                                          }}
                                          // data-toggle="modal" data-target="#myModal"
                                        ></i>
                                        <Popper
                                          open={
                                            this.state.openQuantityAllocation
                                          }
                                          anchorEl={this.state.anchorEl}
                                          placement={"bottom"}
                                          onClose={this.handleClose}
                                          modifiers={{
                                            flip: {
                                              enabled: true,
                                            },
                                            arrow: {
                                              enabled: true,
                                              // element: arrowRef,
                                            },
                                          }}
                                          style={{ width: 350 }}
                                        >
                                          <Paper>
                                            <div className="col-lg-12 mb-4 mt-2 p-2">
                                              <label className="popper_label mb-1">
                                                {this.state.modalName ===
                                                "increment"
                                                  ? "Increase Allocation"
                                                  : "Decrease Allocation"}
                                              </label>
                                              <i
                                                title="Increase Allocation"
                                                data-html="true"
                                                data-placement="bottom"
                                                className="fa fa-times mr-2"
                                                style={{
                                                  fontSize: 14,
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={(l) => {
                                                  this.setState({
                                                    openQuantityAllocation: false,
                                                  });
                                                }}
                                              ></i>
                                              <TextField
                                                name="quantityAllocating"
                                                placeholder="Value"
                                                margin="dense"
                                                variant="outlined"
                                                type="text"
                                                onChange={this.handleText}
                                                error={
                                                  this.state
                                                    .quantityAllocationError
                                                }
                                                value={
                                                  this.state.quantityAllocating
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                  },
                                                }}
                                                autoComplete="off"
                                                className="col"
                                              />
                                              <span className="please-enter-the-increase-quantity-number">
                                                Please enter the difference
                                                Quantity
                                              </span>
                                              <div className="col-lg-12 pl-0 mt-4 text-center">
                                                <button
                                                  type="button"
                                                  className="drawer_button drawer_text mr-3"
                                                  onClick={() =>
                                                    this.incrementORDecrementBusinessAllocation(
                                                      this.state.rowValue,
                                                      this.state.index,
                                                      this.state.modalName
                                                    )
                                                  }
                                                >
                                                  Update
                                                </button>
                                              </div>
                                            </div>
                                          </Paper>
                                        </Popper>
                                      </td>
                                      {this.state.pc_quality_std_edit ===
                                        true &&
                                      this.state.pc_quality_std_index ===
                                        index ? (
                                        <td>
                                          <TextField
                                            name="pc_quality_std"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.pc_quality_std}
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  [e.target.name]:
                                                    e.target.value,
                                                },
                                                () =>
                                                  this.UpdateQualityStandardHandler(
                                                    this.state
                                                      .business_number_allocation[
                                                      index
                                                    ].busuiness_allocation_id
                                                  )
                                              );
                                            }}
                                            select
                                            className="col-lg-10"
                                          >
                                            <MenuItem value="0" disabled>
                                              Please Select
                                            </MenuItem>
                                            {e.pc_quality_std === "ASTM" && (
                                              <MenuItem value="ASTM">
                                                ASTM
                                              </MenuItem>
                                            )}
                                            {e.pc_quality_std === "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                            {e.pc_quality_std !== "ASTM" &&
                                              e.pc_quality_std !== "ISO" &&
                                              e.pc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ASTM">
                                                  ASTM{" "}
                                                </MenuItem>
                                              )}
                                            {e.pc_quality_std !== "ASTM" &&
                                              e.pc_quality_std !== "ISO" &&
                                              e.pc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ISO">
                                                  ISO
                                                </MenuItem>
                                              )}
                                            {e.pc_quality_std ===
                                              "Non Specs" && (
                                              <MenuItem value="Non Specs">
                                                Non Specs
                                              </MenuItem>
                                            )}
                                          </TextField>
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                            style={{
                                              float: "right",
                                              color: config.themeColor,
                                              cursor: "pointer",
                                              position: "relative",
                                              top: 20,
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                type: "selling",
                                                pc_quality_std_edit: false,
                                                pc_quality_std_index: "",
                                                allocationID: "",
                                                pc_quality_std: "0",
                                              });
                                            }}
                                          ></i>
                                        </td>
                                      ) : (
                                        <td className="allocation_table_value pl-0 pr-0">
                                          {e.procurement_quality_standard}
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  display:
                                                    e.pc_quality_std ===
                                                    e.procurement_quality_standard
                                                      ? "none"
                                                      : "",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    type: "procurement",
                                                    pc_quality_std_edit: true,
                                                    pc_quality_std_index: index,
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    pc_quality_std:
                                                      e.procurement_quality_standard,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      {(this.state.features.includes("41") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) &&
                                        this.state.status === "Active" && (
                                          <td
                                            rowSpan={2}
                                            nowrap="true"
                                            className="text-center"
                                          >
                                            <span>
                                              {e.bn_barge.length > 0 ||
                                              e.sales_commercial_available ? null : (
                                                <i
                                                  data-toggle="tooltip1"
                                                  title="Delete Allocation"
                                                  data-html="true"
                                                  data-placement="bottom"
                                                  className="fa fa-trash-o"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: 22,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.deleteBusinessNumberAllocation(
                                                      e.business_no_id,
                                                      e.busuiness_allocation_id
                                                    )
                                                  }
                                                ></i>
                                              )}
                                            </span>
                                          </td>
                                        )}
                                    </tr>
                                    <tr>
                                      {this.state.sc_contract_editable &&
                                      this.state.sc_contract_index === index &&
                                      this.state.status === "Active" ? (
                                        <td className="allocation_table_value">
                                          <Autocomplete
                                            options={
                                              this.state.salesDataforMenu
                                            }
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            onChange={(event, value) =>
                                              this.handleUpdateSalesContract(
                                                index,
                                                value
                                              )
                                            }
                                            renderOption={(option) => (
                                              <React.Fragment>
                                                {option.name} (
                                                {option.customer_name})
                                              </React.Fragment>
                                            )}
                                            size="small"
                                            name="sales_contract"
                                            fullWidth
                                            style={{ padding: "0px" }}
                                            // value= {this.state.supplierSurveyorNameValue}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                // error={this.state.quantityRows[index].sales_contractError}
                                                // inputProps={{ style:{padding: '2px'}}}
                                                placeholder="Sales Contract *"
                                                label="Sales Contract *"
                                                variant="outlined"
                                                style={{ width: 230 }}
                                              />
                                            )}
                                          />
                                        </td>
                                      ) : (
                                        <td
                                          className="allocation_table_value"
                                          nowrap="true"
                                        >
                                          <a
                                            href={
                                              "/view-sales-contract/" +
                                              btoa(e.sc_contract_id)
                                            }
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{ color: config.themeColor }}
                                          >
                                            {e.sc_contract_no}
                                          </a>
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    sc_contract_editable: true,
                                                    sc_contract_index: index,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      <td className="allocation_table_value pl-0">
                                        {e.buyer_name}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_qualities_quality}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_tolerance_adjusted + " %"}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_sales_type}
                                      </td>
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e, "SC")}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.sc_quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._businessSalesToBeAllocated(
                                                  e
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      {this.state.sc_quantity_std_edit ===
                                        true &&
                                      this.state.sc_quality_std_index ===
                                        index ? (
                                        <td className="allocation_table_value pl-0">
                                          <TextField
                                            name="sc_quality_std"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.sc_quality_std}
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  [e.target.name]:
                                                    e.target.value,
                                                },
                                                () =>
                                                  this.UpdateQualityStandardHandler(
                                                    this.state
                                                      .business_number_allocation[
                                                      index
                                                    ].busuiness_allocation_id
                                                  )
                                              );
                                            }}
                                            select
                                            className="col-lg-10"
                                          >
                                            <MenuItem value="0" disabled>
                                              Please Select
                                            </MenuItem>
                                            {e.sc_quality_std === "ASTM" && (
                                              <MenuItem value="ASTM">
                                                ASTM
                                              </MenuItem>
                                            )}
                                            {e.sc_quality_std === "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                            {e.sc_quality_std !== "ASTM" &&
                                              e.sc_quality_std !== "ISO" &&
                                              e.sc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ASTM">
                                                  ASTM{" "}
                                                </MenuItem>
                                              )}
                                            {e.sc_quality_std !== "ASTM" &&
                                              e.sc_quality_std !== "ISO" &&
                                              e.sc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ISO">
                                                  ISO
                                                </MenuItem>
                                              )}
                                            {e.sc_quality_std ===
                                              "Non Specs" && (
                                              <MenuItem value="Non Specs">
                                                Non Specs
                                              </MenuItem>
                                            )}
                                          </TextField>
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                            style={{
                                              float: "right",
                                              color: config.themeColor,
                                              cursor: "pointer",
                                              position: "relative",
                                              top: 20,
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                type: "selling",
                                                sc_quantity_std_edit: false,
                                                sc_quality_std_index: "",
                                                allocationID: "",
                                                sc_quality_std: "0",
                                              });
                                            }}
                                          ></i>
                                        </td>
                                      ) : (
                                        <td className="allocation_table_value pl-0 pr-0">
                                          {e.selling_quality_standard}
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  display:
                                                    e.sc_quality_std ===
                                                    e.selling_quality_standard
                                                      ? "none"
                                                      : "",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    type: "selling",
                                                    sc_quantity_std_edit: true,
                                                    sc_quality_std_index: index,
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    sc_quality_std:
                                                      e.selling_quality_standard,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                    </tr>
                                  </tbody>
                                )
                              )}

                              {/**
                               *
                               *  ADDING QUANTITY ALLOCATION
                               *
                               */}

                              {this.state.quantityRows.map((e, index) => (
                                <tbody
                                  className="allocation_box_shadow"
                                  key={index}
                                >
                                  <tr>
                                    <td
                                      className="allocation_table_value"
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <Autocomplete
                                        options={this.state.purchaseDataForMenu}
                                        getOptionLabel={(option) => option.name}
                                        name="purchaseContract"
                                        // value= {e.purchase_contract}
                                        onChange={(event, value) =>
                                          this.handlePurchaseContract(
                                            index,
                                            value
                                          )
                                        }
                                        renderOption={(option) => (
                                          <React.Fragment>
                                            {option.name} ({option.vendor_name})
                                          </React.Fragment>
                                        )}
                                        size="small"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Purchase Contract *"
                                            label="Purchase Contract *"
                                            error={
                                              this.state.quantityRows[index]
                                                .purchase_contractError
                                            }
                                            // inputProps={{ style:{padding: '2px'}}}
                                            variant="outlined"
                                            style={{ width: 230 }}
                                          />
                                        )}
                                      />
                                    </td>
                                    <td></td>
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.purchase_quality[0].quality}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.purchase_quality[0]
                                          .tolerance_adjusted + " %"}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_types.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.purchase_types[0].purchase_type}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(
                                            e.purchase_quality[0],
                                            "NPC"
                                          )}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.purchase_quality[0]
                                                    .quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._toBeDelivered(
                                                  e.purchase_quality[0]
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}

                                    <td
                                      className="allocation_table_value"
                                      rowSpan={2}
                                    >
                                      <TextField
                                        name="purchase_quantityAllocating"
                                        margin="dense"
                                        variant="outlined"
                                        //label='Quantity Allocation *'
                                        fullWidth
                                        style={{ width: 180 }}
                                        placeholder="Quantity Allocation"
                                        value={
                                          this.state.quantityRows[index]
                                            .purchase_quantityAllocating
                                        }
                                        error={
                                          this.state.quantityRows[index]
                                            .quantityAllocationError
                                        }
                                        onChange={this.handleQuantityRowsTextHandler(
                                          index
                                        )}
                                      />
                                    </td>
                                    <td className="allocation_table_value">
                                      <TextField
                                        name="purchaseProcurement"
                                        margin="dense"
                                        variant="outlined"
                                        //label='Procurement Quality Standard *'
                                        fullWidth
                                        value={
                                          this.state.quantityRows[index]
                                            .purchase_procurement
                                        }
                                        error={
                                          this.state.quantityRows[index]
                                            .purchase_procurementError
                                        }
                                        onChange={this.handleQuantityRowsTextHandler(
                                          index
                                        )}
                                        select
                                      >
                                        <MenuItem value="0">
                                          Please Select
                                        </MenuItem>
                                        {this.state
                                          .PC_specification_standard ===
                                          "ASTM" && (
                                          <MenuItem value="ASTM">ASTM</MenuItem>
                                        )}
                                        {this.state
                                          .PC_specification_standard ===
                                          "ISO" && (
                                          <MenuItem value="ISO">ISO</MenuItem>
                                        )}
                                        {this.state
                                          .PC_specification_standard !==
                                          "ASTM" &&
                                          this.state
                                            .PC_specification_standard !==
                                            "ISO" &&
                                          this.state
                                            .PC_specification_standard !==
                                            "Non Specs" && (
                                            <MenuItem value="ASTM">
                                              ASTM{" "}
                                            </MenuItem>
                                          )}
                                        {this.state
                                          .PC_specification_standard !==
                                          "ASTM" &&
                                          this.state
                                            .PC_specification_standard !==
                                            "ISO" &&
                                          this.state
                                            .PC_specification_standard !==
                                            "Non Specs" && (
                                            <MenuItem value="ISO">ISO</MenuItem>
                                          )}

                                        {this.state
                                          .PC_specification_standard ===
                                          "Non Specs" && (
                                          <MenuItem value="Non Specs">
                                            Non Specs
                                          </MenuItem>
                                        )}
                                      </TextField>
                                    </td>
                                    <td
                                      className="allocation_table_value text-center"
                                      rowSpan={2}
                                      style={{
                                        verticalAlign: "middle",
                                        zIndex: 5,
                                      }}
                                      nowrap="true"
                                    >
                                      {/* <i className="fa fa-minus-square-o" aria-hidden="true" style={{ color: 'red', fontSize: 30 }} data-toggle="tooltip" title="Delete Row" data-html="true" data-placement="bottom" onClick={this.RemoveQuantityAllocationRow}></i> */}
                                      <i
                                        data-toggle="tooltip1"
                                        title="Delete Allocation"
                                        data-html="true"
                                        data-placement="bottom"
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: 22,
                                          color: config.themeColor,
                                          cursor: "pointer",
                                        }}
                                        onClick={
                                          this.RemoveQuantityAllocationRow
                                        }
                                      ></i>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="allocation_table_value">
                                      <Autocomplete
                                        options={this.state.salesDataforMenu}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) =>
                                          this.handleSalesContract(index, value)
                                        }
                                        renderOption={(option) => (
                                          <React.Fragment>
                                            {option.name} (
                                            {option.customer_name})
                                          </React.Fragment>
                                        )}
                                        size="small"
                                        name="sales_contract"
                                        fullWidth
                                        style={{ padding: "0px" }}
                                        // value= {this.state.supplierSurveyorNameValue}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            error={
                                              this.state.quantityRows[index]
                                                .sales_contractError
                                            }
                                            // inputProps={{ style:{padding: '2px'}}}
                                            placeholder="Sales Contract *"
                                            label="Sales Contract *"
                                            variant="outlined"
                                            style={{ width: 230 }}
                                          />
                                        )}
                                      />
                                    </td>
                                    <td></td>
                                    {e.sales_quality.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.sales_quality[0].quality}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.sales_quality.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.sales_quality[0].tolerance_adjusted +
                                          " %"}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.sales_types.length > 0 ? (
                                      <td className="allocation_table_value">
                                        {e.sales_types[0].sales_type}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.sales_quality.length > 0 ? (
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(
                                            e.sales_quality[0],
                                            "NSC"
                                          )}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.sales_quality[0]
                                                    .quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._toBeDelivered(
                                                  e.sales_quality[0]
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      // <td className="allocation_table_value" style={{ textAlign: 'right' }}>{this.toLocaleString(e.sales_quality[0].quantity_adjusted)}</td>
                                      <td></td>
                                    )}

                                    <td className="allocation_table_value">
                                      <TextField
                                        name="saleSellingQuality"
                                        margin="dense"
                                        variant="outlined"
                                        //label='Selling Quality Standard *'
                                        placeholder="Selling Quality Standard *"
                                        fullWidth
                                        value={
                                          this.state.quantityRows[index]
                                            .sales_sellingQuality
                                        }
                                        error={
                                          this.state.quantityRows[index]
                                            .sales_sellingQualityError
                                        }
                                        onChange={this.handleQuantityRowsTextHandler(
                                          index
                                        )}
                                        select
                                      >
                                        <MenuItem value="0">
                                          Please Select
                                        </MenuItem>
                                        {this.state
                                          .SC_specification_standard ===
                                          "ASTM" && (
                                          <MenuItem value="ASTM">ASTM</MenuItem>
                                        )}
                                        {this.state
                                          .SC_specification_standard ===
                                          "ISO" && (
                                          <MenuItem value="ISO">ISO</MenuItem>
                                        )}
                                        {this.state
                                          .SC_specification_standard !==
                                          "ASTM" &&
                                          this.state
                                            .SC_specification_standard !==
                                            "ISO" &&
                                          this.state
                                            .SC_specification_standard !==
                                            "Non Specs" && (
                                            <MenuItem value="ASTM">
                                              ASTM{" "}
                                            </MenuItem>
                                          )}
                                        {this.state
                                          .SC_specification_standard !==
                                          "ASTM" &&
                                          this.state
                                            .SC_specification_standard !==
                                            "ISO" &&
                                          this.state
                                            .SC_specification_standard !==
                                            "Non Specs" && (
                                            <MenuItem value="ISO">ISO</MenuItem>
                                          )}
                                        {this.state
                                          .SC_specification_standard ===
                                          "Non Specs" && (
                                          <MenuItem value="Non Specs">
                                            Non Specs
                                          </MenuItem>
                                        )}
                                      </TextField>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>

                          <div className="pl-2 pb-2">
                            <br />
                            {this.state.quantityRows.length === 0 ? (
                              <div>
                                {(this.state.features.includes("41") ||
                                  this.state.features.includes("97")) &&
                                  this.state.status === "Active" && (
                                    <button
                                      type="button"
                                      className="header_button header_button_text addrow_button_adjustment"
                                      onClick={this.addQuantityAllocationData}
                                      style={{
                                        color: config.themeColor,
                                        width: 150,
                                      }}
                                    >
                                      Add Quantity
                                    </button>
                                  )}
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="drawer_button drawer_text mr-3"
                                onClick={() =>
                                  this.setState(
                                    { buy_back_alloc: "No" },
                                    this.addBusinessNumberAllocation
                                  )
                                }
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/**Buyback feature */}
                      {this.state.buy_back_involved === "Yes" && (
                        <div className="row">
                          <h5 className="ml-2 p-2 sub_heading_label mb-0">
                            Financial Assistance
                          </h5>
                          <div className="p-3 lr-3 table-responsive ">
                            <table className="border-0 root">
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 180 }}
                                  >
                                    Contract No
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 150 }}
                                  >
                                    Supplier/Buyer
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Quality
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Tolerance
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 160 }}
                                    nowrap="true"
                                  >
                                    Inco Terms
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 200 }}
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    className="allocation_table_header_label text-center"
                                    style={{ minWidth: 150 }}
                                  >
                                    Allocated
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 150 }}
                                  >
                                    Quality Standard
                                  </th>
                                  <th
                                    className="allocation_table_header_label"
                                    style={{ minWidth: 100 }}
                                  ></th>
                                </tr>
                              </thead>
                              {this.state.business_number_buy_back_allocation.map(
                                (e, index) => (
                                  <tbody
                                    className="allocation_box_shadow"
                                    key={index}
                                  >
                                    <tr>
                                      {this.state
                                        .pc_contract_buy_back_editable &&
                                      this.state.pc_contract_buy_back_index ===
                                        index ? (
                                        <td
                                          className="allocation_table_value"
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          <span className="allocation_value">
                                            {e.display_allocation_id}
                                          </span>
                                          <Autocomplete
                                            options={
                                              this.state.purchaseDataForMenu
                                            }
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            name="purchaseContract"
                                            value={e.purchase_contract}
                                            onChange={(event, value) =>
                                              this.setState(
                                                { buy_back_alloc: "Yes" },
                                                this.handleUpdatePurchaseContract(
                                                  index,
                                                  value
                                                )
                                              )
                                            }
                                            renderOption={(option) => (
                                              <React.Fragment>
                                                {option.name} (
                                                {option.vendor_name})
                                              </React.Fragment>
                                            )}
                                            size="small"
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Purchase Contract *"
                                                label="Purchase Contract *"
                                                // error={this.state.quantityRows[index].purchase_contractError}
                                                // inputProps={{ style:{padding: '2px'}}}
                                                variant="outlined"
                                                style={{ width: 230 }}
                                              />
                                            )}
                                          />
                                        </td>
                                      ) : (
                                        <td
                                          className="allocation_table_value"
                                          nowrap="true"
                                        >
                                          <span className="allocation_value">
                                            {e.display_allocation_id}
                                          </span>
                                          <a
                                            href={
                                              "/view-purchase-contract/" +
                                              btoa(e.pc_contract_id)
                                            }
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{
                                              color: config.themeColor,
                                              position: "relative",
                                              top: "-8px",
                                            }}
                                          >
                                            {e.pc_contract_no}
                                          </a>
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  position: "relative",
                                                  top: "-11px",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    pc_contract_buy_back_editable: true,
                                                    pc_contract_buy_back_index:
                                                      index,
                                                    buy_back_alloc: "Yes",
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      <td className="allocation_table_value pl-0">
                                        {e.supplier_name}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_qualities_quality}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_tolerance_adjusted + "%"}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.pc_purchase_type}
                                      </td>
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e, "PC")}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.pc_quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._businessPurchaseToBeAllocated(
                                                  e
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        rowSpan={2}
                                        className="allocation_table_value text-center"
                                      >
                                        <i
                                          title="Increase Allocation"
                                          data-html="true"
                                          data-placement="bottom"
                                          className="fa fa-arrow-circle-o-up mr-2"
                                          style={{
                                            fontSize: 22,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          onClick={(l) => {
                                            this.setState({
                                              modalName: "increment",
                                              index: index,
                                              rowValue: e,
                                              openQuantityAllocation: true,
                                              anchorEl: l.currentTarget,
                                            });
                                          }}
                                          // data-toggle="modal"
                                          // data-target="#myModal"
                                        ></i>
                                        {this.toLocaleString(
                                          Number(e.quantity_allocated).toFixed(
                                            3
                                          )
                                        )}
                                        <i
                                          title="Decrease Allocation"
                                          data-html="true"
                                          data-placement="bottom"
                                          className="fa fa-arrow-circle-o-down ml-2"
                                          style={{
                                            fontSize: 22,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          // onClick={() => this.setState({ modalName: "decrement", index: index, rowValue: e })}
                                          onClick={(l) => {
                                            this.setState({
                                              modalName: "decrement",
                                              index: index,
                                              rowValue: e,
                                              openQuantityAllocation: true,
                                              anchorEl: l.currentTarget,
                                            });
                                          }}
                                          // data-toggle="modal" data-target="#myModal"
                                        ></i>
                                        <Popper
                                          open={
                                            this.state.openQuantityAllocation
                                          }
                                          anchorEl={this.state.anchorEl}
                                          placement={"bottom"}
                                          onClose={this.handleClose}
                                          modifiers={{
                                            flip: {
                                              enabled: true,
                                            },
                                            arrow: {
                                              enabled: true,
                                              // element: arrowRef,
                                            },
                                          }}
                                          style={{ width: 350 }}
                                        >
                                          <Paper>
                                            <div className="col-lg-12 mb-4 mt-2 p-2">
                                              <label className="popper_label mb-1">
                                                {this.state.modalName ===
                                                "increment"
                                                  ? "Increase Allocation"
                                                  : "Decrease Allocation"}
                                              </label>
                                              <i
                                                title="Increase Allocation"
                                                data-html="true"
                                                data-placement="bottom"
                                                className="fa fa-times mr-2"
                                                style={{
                                                  fontSize: 14,
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={(l) => {
                                                  this.setState({
                                                    openQuantityAllocation: false,
                                                  });
                                                }}
                                              ></i>
                                              <TextField
                                                name="quantityAllocating"
                                                placeholder="Value"
                                                margin="dense"
                                                variant="outlined"
                                                type="text"
                                                onChange={this.handleText}
                                                error={
                                                  this.state
                                                    .quantityAllocationError
                                                }
                                                value={
                                                  this.state.quantityAllocating
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                  },
                                                }}
                                                autoComplete="off"
                                                className="col"
                                              />
                                              <span className="please-enter-the-increase-quantity-number">
                                                Please enter the difference
                                                Quantity
                                              </span>
                                              <div className="col-lg-12 pl-0 mt-4 text-center">
                                                <button
                                                  type="button"
                                                  className="drawer_button drawer_text mr-3"
                                                  onClick={() =>
                                                    this.incrementORDecrementBusinessAllocation(
                                                      this.state.rowValue,
                                                      this.state.index,
                                                      this.state.modalName
                                                    )
                                                  }
                                                >
                                                  Update
                                                </button>
                                              </div>
                                            </div>
                                          </Paper>
                                        </Popper>
                                      </td>
                                      {this.state
                                        .pc_buy_back_quality_std_edit ===
                                        true &&
                                      this.state
                                        .pc_buy_back_quality_std_index ===
                                        index ? (
                                        <td>
                                          <TextField
                                            name="pc_quality_stc"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.pc_quality_stc}
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  [e.target.name]:
                                                    e.target.value,
                                                },
                                                () =>
                                                  this.UpdateQualityStandardHandler(
                                                    this.state
                                                      .business_number_buy_back_allocation[
                                                      index
                                                    ].busuiness_allocation_id
                                                  )
                                              );
                                            }}
                                            select
                                          >
                                            <MenuItem value="0" disabled>
                                              Please Select
                                            </MenuItem>
                                            {e.pc_quality_std === "ASTM" && (
                                              <MenuItem value="ASTM">
                                                ASTM
                                              </MenuItem>
                                            )}
                                            {e.pc_quality_std === "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                            {e.pc_quality_std !== "ASTM" &&
                                              e.pc_quality_std !== "ISO" &&
                                              e.pc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ASTM">
                                                  ASTM{" "}
                                                </MenuItem>
                                              )}
                                            {e.pc_quality_std !== "ASTM" &&
                                              e.pc_quality_std !== "ISO" &&
                                              e.pc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ISO">
                                                  ISO
                                                </MenuItem>
                                              )}
                                            {e.pc_quality_std ===
                                              "Non Specs" && (
                                              <MenuItem value="Non Specs">
                                                Non Specs
                                              </MenuItem>
                                            )}
                                          </TextField>
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                            style={{
                                              float: "right",
                                              color: config.themeColor,
                                              cursor: "pointer",
                                              position: "relative",
                                              top: 20,
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                type: "selling",
                                                pc_buy_back_quality_std_edit: false,
                                                pc_buy_back_quality_std_index:
                                                  "",
                                                pc_quality_stc: "0",
                                              });
                                            }}
                                          ></i>
                                        </td>
                                      ) : (
                                        <td>
                                          {e.procurement_quality_standard}
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  display:
                                                    e.pc_quality_std ===
                                                    e.procurement_quality_standard
                                                      ? "none"
                                                      : "",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    type: "procurement",
                                                    pc_buy_back_quality_std_edit: true,
                                                    pc_buy_back_quality_std_index:
                                                      index,
                                                    pc_quality_stc:
                                                      e.procurement_quality_standard,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      {(this.state.features.includes("41") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) &&
                                        this.state.status === "Active" && (
                                          <td
                                            rowSpan={2}
                                            nowrap="true"
                                            className="text-center"
                                          >
                                            <span>
                                              {e.bn_barge.length > 0 ||
                                              e.sales_commercial_available ? null : (
                                                <i
                                                  data-toggle="tooltip1"
                                                  title="Delete Allocation"
                                                  data-html="true"
                                                  data-placement="bottom"
                                                  className="fa fa-trash-o"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: 22,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.deleteBusinessNumberAllocation(
                                                      e.business_no_id,
                                                      e.busuiness_allocation_id
                                                    )
                                                  }
                                                ></i>
                                              )}
                                            </span>
                                          </td>
                                        )}
                                    </tr>
                                    <tr>
                                      {this.state.sc_contract_editable &&
                                      this.state.sc_contract_index === index &&
                                      this.state.status === "Active" ? (
                                        <td className="allocation_table_value">
                                          <Autocomplete
                                            options={
                                              this.state.salesDataforMenu
                                            }
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            onChange={(event, value) =>
                                              this.setState(
                                                {
                                                  buy_back_alloc: "Yes",
                                                },
                                                this.handleUpdateSalesContract(
                                                  index,
                                                  value
                                                )
                                              )
                                            }
                                            renderOption={(option) => (
                                              <React.Fragment>
                                                {option.name} (
                                                {option.customer_name})
                                              </React.Fragment>
                                            )}
                                            size="small"
                                            name="sales_contract"
                                            fullWidth
                                            style={{ padding: "0px" }}
                                            // value= {this.state.supplierSurveyorNameValue}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                // error={this.state.quantityRows[index].sales_contractError}
                                                // inputProps={{ style:{padding: '2px'}}}
                                                placeholder="Sales Contract *"
                                                label="Sales Contract *"
                                                variant="outlined"
                                                style={{ width: 230 }}
                                              />
                                            )}
                                          />
                                        </td>
                                      ) : (
                                        <td
                                          className="allocation_table_value"
                                          nowrap="true"
                                        >
                                          <a
                                            href={
                                              "/view-sales-contract/" +
                                              btoa(e.sc_contract_id)
                                            }
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{ color: config.themeColor }}
                                          >
                                            {e.sc_contract_no}
                                          </a>
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    allocationID:
                                                      e.busuiness_allocation_id,
                                                    sc_contract_buy_back_editable: true,
                                                    sc_contract_buy_back_index:
                                                      index,
                                                    buy_back_alloc: "Yes",
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                      <td className="allocation_table_value pl-0">
                                        {e.buyer_name}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_qualities_quality}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_tolerance_adjusted + " %"}
                                      </td>
                                      <td className="allocation_table_value pl-0">
                                        {e.sc_sales_type}
                                      </td>
                                      <td className="allocation_table_value p-0">
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e, "SC")}
                                          style={{ borderRadius: 5 }}
                                        />
                                        <div className="row col-lg-12 p-0 mb-2">
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Total
                                              </label>
                                              {this.toLocaleString(
                                                Number(
                                                  e.sc_quantity_adjusted
                                                ).toFixed(3)
                                              )}
                                            </label>
                                          </div>
                                          <div className="col-lg-2 p-0"></div>
                                          <div className="col-lg-5 p-0">
                                            <label className="linear_allocation_value mb-0">
                                              <label className="linear_allocation_label mb-0">
                                                Remaining
                                              </label>
                                              {this.toLocaleString(
                                                this._businessSalesToBeAllocated(
                                                  e
                                                )
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      {this.state
                                        .sc_buy_back_quality_std_edit ===
                                        true &&
                                      this.state
                                        .sc_buy_back_quality_std_index ===
                                        index ? (
                                        <td className="allocation_table_value pl-0">
                                          <TextField
                                            name="sc_quality_std"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.sc_quality_std}
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  [e.target.name]:
                                                    e.target.value,
                                                },
                                                () =>
                                                  this.UpdateQualityStandardHandler(
                                                    this.state
                                                      .business_number_buy_back_allocation[
                                                      index
                                                    ].busuiness_allocation_id
                                                  )
                                              );
                                            }}
                                            select
                                          >
                                            <MenuItem value="0" disabled>
                                              Please Select
                                            </MenuItem>
                                            {e.sc_quality_std === "ASTM" && (
                                              <MenuItem value="ASTM">
                                                ASTM
                                              </MenuItem>
                                            )}
                                            {e.sc_quality_std === "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                            {e.sc_quality_std !== "ASTM" &&
                                              e.sc_quality_std !== "ISO" &&
                                              e.sc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ASTM">
                                                  ASTM{" "}
                                                </MenuItem>
                                              )}
                                            {e.sc_quality_std !== "ASTM" &&
                                              e.sc_quality_std !== "ISO" &&
                                              e.sc_quality_std !==
                                                "Non Specs" && (
                                                <MenuItem value="ISO">
                                                  ISO
                                                </MenuItem>
                                              )}
                                            {e.sc_quality_std ===
                                              "Non Specs" && (
                                              <MenuItem value="Non Specs">
                                                Non Specs
                                              </MenuItem>
                                            )}
                                          </TextField>
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                            style={{
                                              float: "right",
                                              color: config.themeColor,
                                              cursor: "pointer",
                                              position: "relative",
                                              top: 20,
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                type: "selling",
                                                sc_buy_back_quality_std_edit: false,
                                                sc_buy_back_quality_std_index:
                                                  "",
                                                sc_quality_std: "0",
                                              });
                                            }}
                                          ></i>
                                        </td>
                                      ) : (
                                        <td className="allocation_table_value pl-0 pr-0">
                                          {e.selling_quality_standard}
                                          {(this.state.features.includes(
                                            "41"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) &&
                                            this.state.status === "Active" && (
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                                style={{
                                                  float: "right",
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  display:
                                                    e.sc_quality_std ===
                                                    e.selling_quality_standard
                                                      ? "none"
                                                      : "",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    type: "selling",
                                                    sc_buy_back_quality_std_edit: true,
                                                    sc_buy_back_quality_std_index:
                                                      index,
                                                    sc_quality_std:
                                                      e.selling_quality_standard,
                                                  });
                                                }}
                                              ></i>
                                            )}
                                        </td>
                                      )}
                                    </tr>
                                  </tbody>
                                )
                              )}
                              {/**
                               *
                               *  ADDING NEW BUY BACK QUANTITY ALLOCATIONS
                               *
                               */}
                              {this.state.buyBackQuantityRows.map(
                                (e, index) => (
                                  <tbody
                                    className="allocation_box_shadow"
                                    key={index}
                                  >
                                    <tr>
                                      <td
                                        className="allocation_table_value"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <Autocomplete
                                          options={
                                            this.state.purchaseDataForMenu
                                          }
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          name="purchaseContract"
                                          // value= {e.purchase_contract}
                                          onChange={(event, value) =>
                                            this.setState(
                                              { buy_back_alloc: "Yes" },
                                              this.handlePurchaseContract(
                                                index,
                                                value
                                              )
                                            )
                                          }
                                          renderOption={(option) => (
                                            <React.Fragment>
                                              {option.name} (
                                              {option.vendor_name})
                                            </React.Fragment>
                                          )}
                                          size="small"
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Purchase Contract *"
                                              label="Purchase Contract *"
                                              error={
                                                this.state.buyBackQuantityRows[
                                                  index
                                                ].purchase_contractError
                                              }
                                              // inputProps={{ style:{padding: '2px'}}}
                                              variant="outlined"
                                              style={{ width: 230 }}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td></td>
                                      {e.purchase_quality.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.purchase_quality[0].quality}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.purchase_quality.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.purchase_quality[0]
                                            .tolerance_adjusted + " %"}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.purchase_types.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.purchase_types[0].purchase_type}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.purchase_quality.length > 0 ? (
                                        <td className="allocation_table_value p-0">
                                          <BorderLinearProgress
                                            variant="determinate"
                                            value={this.progressBarSize(
                                              e.purchase_quality[0],
                                              "NPC"
                                            )}
                                            style={{ borderRadius: 5 }}
                                          />
                                          <div className="row col-lg-12 p-0 mb-2">
                                            <div className="col-lg-5 p-0">
                                              <label className="linear_allocation_value mb-0">
                                                <label className="linear_allocation_label mb-0">
                                                  Total
                                                </label>
                                                {this.toLocaleString(
                                                  Number(
                                                    e.purchase_quality[0]
                                                      .quantity_adjusted
                                                  ).toFixed(3)
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-lg-2 p-0"></div>
                                            <div className="col-lg-5 p-0">
                                              <label className="linear_allocation_value mb-0">
                                                <label className="linear_allocation_label mb-0">
                                                  Remaining
                                                </label>
                                                {this.toLocaleString(
                                                  this._toBeDelivered(
                                                    e.purchase_quality[0]
                                                  )
                                                )}
                                              </label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}

                                      <td
                                        className="allocation_table_value"
                                        rowSpan={2}
                                      >
                                        <TextField
                                          name="purchase_quantityAllocating"
                                          margin="dense"
                                          variant="outlined"
                                          //label='Quantity Allocation *'
                                          fullWidth
                                          style={{ width: 180 }}
                                          placeholder="Quantity Allocation"
                                          value={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].purchase_quantityAllocating
                                          }
                                          error={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].quantityAllocationError
                                          }
                                          onChange={this.handleQuantityRowsTextHandler(
                                            index
                                          )}
                                        />
                                      </td>
                                      <td className="allocation_table_value">
                                        <TextField
                                          name="purchaseProcurement"
                                          margin="dense"
                                          variant="outlined"
                                          //label='Procurement Quality Standard *'
                                          fullWidth
                                          value={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].purchase_procurement
                                          }
                                          error={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].purchase_procurementError
                                          }
                                          onChange={this.handleQuantityRowsTextHandler(
                                            index
                                          )}
                                          select
                                        >
                                          <MenuItem value="0">
                                            Please Select
                                          </MenuItem>
                                          {this.state
                                            .PC_specification_standard ===
                                            "ASTM" && (
                                            <MenuItem value="ASTM">
                                              ASTM
                                            </MenuItem>
                                          )}
                                          {this.state
                                            .PC_specification_standard ===
                                            "ISO" && (
                                            <MenuItem value="ISO">ISO</MenuItem>
                                          )}
                                          {this.state
                                            .PC_specification_standard !==
                                            "ASTM" &&
                                            this.state
                                              .PC_specification_standard !==
                                              "ISO" && (
                                              <MenuItem value="ASTM">
                                                ASTM{" "}
                                              </MenuItem>
                                            )}
                                          {this.state
                                            .PC_specification_standard !==
                                            "ASTM" &&
                                            this.state
                                              .PC_specification_standard !==
                                              "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                        </TextField>
                                      </td>
                                      <td
                                        className="allocation_table_value text-center"
                                        rowSpan={2}
                                        style={{
                                          verticalAlign: "middle",
                                          zIndex: 5,
                                        }}
                                        nowrap="true"
                                      >
                                        <i
                                          data-toggle="tooltip1"
                                          title="Delete Allocation"
                                          data-html="true"
                                          data-placement="bottom"
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: 22,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.setState(
                                              { buy_back_alloc: "Yes" },
                                              this.RemoveQuantityAllocationRow
                                            )
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="allocation_table_value">
                                        <Autocomplete
                                          options={this.state.salesDataforMenu}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          onChange={(event, value) =>
                                            this.setState(
                                              { buy_back_alloc: "Yes" },
                                              this.handleSalesContract(
                                                index,
                                                value
                                              )
                                            )
                                          }
                                          renderOption={(option) => (
                                            <React.Fragment>
                                              {option.name} (
                                              {option.customer_name})
                                            </React.Fragment>
                                          )}
                                          size="small"
                                          name="sales_contract"
                                          fullWidth
                                          style={{ padding: "0px" }}
                                          // value= {this.state.supplierSurveyorNameValue}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              error={
                                                this.state.buyBackQuantityRows[
                                                  index
                                                ].sales_contractError
                                              }
                                              // inputProps={{ style:{padding: '2px'}}}
                                              placeholder="Sales Contract *"
                                              label="Sales Contract *"
                                              variant="outlined"
                                              style={{ width: 230 }}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td></td>
                                      {e.sales_quality.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.sales_quality[0].quality}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_quality.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.sales_quality[0]
                                            .tolerance_adjusted + " %"}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_types.length > 0 ? (
                                        <td className="allocation_table_value">
                                          {e.sales_types[0].sales_type}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_quality.length > 0 ? (
                                        <td className="allocation_table_value p-0">
                                          <BorderLinearProgress
                                            variant="determinate"
                                            value={this.progressBarSize(
                                              e.sales_quality[0],
                                              "NSC"
                                            )}
                                            style={{ borderRadius: 5 }}
                                          />
                                          <div className="row col-lg-12 p-0 mb-2">
                                            <div className="col-lg-5 p-0">
                                              <label className="linear_allocation_value mb-0">
                                                <label className="linear_allocation_label mb-0">
                                                  Total
                                                </label>
                                                {this.toLocaleString(
                                                  Number(
                                                    e.sales_quality[0]
                                                      .quantity_adjusted
                                                  ).toFixed(3)
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-lg-2 p-0"></div>
                                            <div className="col-lg-5 p-0">
                                              <label className="linear_allocation_value mb-0">
                                                <label className="linear_allocation_label mb-0">
                                                  Remaining
                                                </label>
                                                {this.toLocaleString(
                                                  this._toBeDelivered(
                                                    e.sales_quality[0]
                                                  )
                                                )}
                                              </label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : (
                                        // <td className="allocation_table_value" style={{ textAlign: 'right' }}>{this.toLocaleString(e.sales_quality[0].quantity_adjusted)}</td>
                                        <td></td>
                                      )}

                                      <td className="allocation_table_value">
                                        <TextField
                                          name="saleSellingQuality"
                                          margin="dense"
                                          variant="outlined"
                                          //label='Selling Quality Standard *'
                                          placeholder="Selling Quality Standard *"
                                          fullWidth
                                          value={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].sales_sellingQuality
                                          }
                                          error={
                                            this.state.buyBackQuantityRows[
                                              index
                                            ].sales_sellingQualityError
                                          }
                                          onChange={this.handleQuantityRowsTextHandler(
                                            index
                                          )}
                                          select
                                        >
                                          <MenuItem value="0">
                                            Please Select
                                          </MenuItem>
                                          {this.state
                                            .SC_specification_standard ===
                                            "ASTM" && (
                                            <MenuItem value="ASTM">
                                              ASTM
                                            </MenuItem>
                                          )}
                                          {this.state
                                            .SC_specification_standard ===
                                            "ISO" && (
                                            <MenuItem value="ISO">ISO</MenuItem>
                                          )}
                                          {this.state
                                            .SC_specification_standard !==
                                            "ASTM" &&
                                            this.state
                                              .SC_specification_standard !==
                                              "ISO" && (
                                              <MenuItem value="ASTM">
                                                ASTM{" "}
                                              </MenuItem>
                                            )}
                                          {this.state
                                            .SC_specification_standard !==
                                            "ASTM" &&
                                            this.state
                                              .SC_specification_standard !==
                                              "ISO" && (
                                              <MenuItem value="ISO">
                                                ISO
                                              </MenuItem>
                                            )}
                                        </TextField>
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                            </table>
                          </div>
                          <div className="pl-2 pb-2">
                            <br />
                            {this.state.buyBackQuantityRows.length === 0 ? (
                              <div>
                                {(this.state.features.includes("41") ||
                                  this.state.features.includes("97")) &&
                                  this.state.status === "Active" && (
                                    <button
                                      type="button"
                                      className="header_button header_button_text addrow_button_adjustment"
                                      onClick={() =>
                                        this.setState(
                                          {
                                            buy_back_alloc: "Yes",
                                          },
                                          this.addQuantityAllocationData
                                        )
                                      }
                                      style={{
                                        color: config.themeColor,
                                        width: 150,
                                      }}
                                    >
                                      Add Quantity
                                    </button>
                                  )}
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="drawer_button drawer_text"
                                onClick={() =>
                                  this.setState(
                                    { buy_back_alloc: "Yes" },
                                    this.addBusinessNumberAllocation
                                  )
                                }
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.businessTabIndex === 1 && (
                    <div className="mt-3">
                      {this.state.consignment_type === "Mother Vessel" && (
                        <Button
                          className="ml-3 mr-2 specs_button"
                          onClick={() => {
                            this.setState({
                              activeTabIndex: 0,
                            });
                          }}
                          style={{
                            width: 150,
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.activeTabIndex === 0
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.activeTabIndex === 0
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Mother Vessel
                        </Button>
                      )}
                      <Button
                        className="ml-3 specs_button"
                        onClick={() => {
                          this.setState({
                            activeTabIndex: 1,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.activeTabIndex === 1
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.activeTabIndex === 1
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Barge
                      </Button>
                      {this.state.activeTabIndex === 0 && (
                        <div>
                          {this.state.consignment_type === "Mother Vessel" && (
                            <div>
                              {(this.state.features.includes("43") ||
                                this.state.features.includes("97")) && (
                                <div className="card">
                                  {!!this.state.businessNo_motherVessel && (
                                    <h5 className="ml-3 p-2 sub_heading_label mb-0 mt-3">
                                      Mother Vessel Data
                                      {
                                        (this.state.features.includes("44") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                          this.state.status === "Active" && (
                                            // <a
                                            //   href={'/update-mother-vessel/' + btoa(Number(this.state.motherVesselID))}
                                            //   rel="noopener noreferrer"
                                            //   role="button"
                                            //   data-html="true"
                                            //   data-toggle="tooltip1"
                                            //   title="Update Mother Vessel"
                                            //   data-placement="bottom"
                                            //   className="fa fa-pencil edit_icon"
                                            // // style={{ fontSize: '20px', color: config.themeColor, cursor: 'pointer', paddingLeft: '15px', verticalAlign: 'middle' }}
                                            // >
                                            // </a>
                                            <i
                                              className="fa fa-pencil edit_icon"
                                              aria-hidden="true"
                                              onClick={() =>
                                                this.setState({
                                                  motherVesselDrawer: true,
                                                  MotherVesselAdd: false,
                                                  MotherVesselUpdate: true,
                                                })
                                              }
                                            />
                                          )
                                        // <a href={'/update-mother-vessel/' + btoa(Number(this.state.motherVesselID))} rel="noopener noreferrer" role="button" data-html="true" data-toggle="tooltip1" title="Update Mother Vessel Data" data-placement="bottom" className="fa fa-pencil-square-o" style={{ fontSize: '24px', color: '#4b636f', cursor: 'pointer', paddingLeft: '15px', verticalAlign: 'middle' }}><EditIcon style={{ display: 'none' }} /></a>
                                      }
                                    </h5>
                                  )}
                                  {!this.state.businessNo_motherVessel && (
                                    <h5 className="m-0 p-2 sub_heading_label">
                                      Mother Vessel Data
                                      {
                                        (this.state.features.includes("42") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                          this.state.status === "Active" && (
                                            // <a
                                            //   href={'/add-mother-vessel/' + btoa(this.state.business_no_id)}
                                            //   rel="noopener noreferrer"
                                            //   role="button"
                                            //   data-html="true"
                                            //   data-toggle="tooltip1"
                                            //   title="Add Mother Vessel"
                                            //   data-placement="bottom"
                                            //   className="fa fa-plus-circle"
                                            //   style={{ fontSize: '20px', color: config.themeColor, cursor: 'pointer', paddingLeft: '15px', verticalAlign: 'middle' }}
                                            // >
                                            // </a>
                                            <i
                                              className="fa fa-plus-circle mr-2"
                                              aria-hidden="true"
                                              style={{
                                                fontSize: "20px",
                                                color: config.themeColor,
                                                cursor: "pointer",
                                                paddingLeft: "15px",
                                                verticalAlign: "middle",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  motherVesselDrawer: true,
                                                  MotherVesselAdd: true,
                                                  MotherVesselUpdate: false,
                                                })
                                              }
                                            />
                                          )
                                        // <a href={'/update-mother-vessel/' + btoa(Number(this.state.motherVesselID))} rel="noopener noreferrer" role="button" data-html="true" data-toggle="tooltip1" title="Update Mother Vessel Data" data-placement="bottom" className="fa fa-pencil-square-o" style={{ fontSize: '24px', color: '#4b636f', cursor: 'pointer', paddingLeft: '15px', verticalAlign: 'middle' }}><EditIcon style={{ display: 'none' }} /></a>
                                      }
                                    </h5>
                                  )}
                                  {!!this.state.businessNo_motherVessel && (
                                    <div className="row col-lg-12 p-0">
                                      <div className="col-lg-3 pl-0">
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Loading Commencement
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localDateFormate(
                                                this.state.loadingCommencement
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Vessel Arrived
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localDateFormate(
                                                this.state.vessel_arrived_on
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Cargo Gain/Loss in MT
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {Number(
                                                this.state.cargo_gain_loss_in_mt
                                                  ? this.state
                                                      .cargo_gain_loss_in_mt
                                                  : 0
                                              ) < 0 ? (
                                                <span className="text-danger">
                                                  {this.state
                                                    .cargo_gain_loss_in_mt
                                                    ? "(" +
                                                      this.toLocaleString(
                                                        Math.abs(
                                                          this.state
                                                            .cargo_gain_loss_in_mt
                                                        )
                                                      ) +
                                                      ")"
                                                    : null}
                                                </span>
                                              ) : (
                                                <span>
                                                  {this.state
                                                    .cargo_gain_loss_in_mt
                                                    ? this.toLocaleString(
                                                        this.state
                                                          .cargo_gain_loss_in_mt
                                                      )
                                                    : "-"}
                                                </span>
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Stevedore on MV
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.stevedoreOnMV !== ""
                                                ? this.state.stevedoreOnMV
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Dead Freight Incurred
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .deadFreightIncurred !== ""
                                                ? this.toLocaleString(
                                                    this.state
                                                      .deadFreightIncurred
                                                  )
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Load Port Agent
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.load_port_agent !== ""
                                                ? this.state.load_port_agent
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Surveyor Price
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.surveyorBasePrice &&
                                              this.state.surveyorVAT &&
                                              this.state
                                                .surveyorCurrencyType ? (
                                                <span
                                                  style={{ paddingLeft: 3 }}
                                                >
                                                  Baseprice(
                                                  {this.toLocaleString(
                                                    this.state.surveyorBasePrice
                                                  )}
                                                  )+VAT({this.state.surveyorVAT}
                                                  ) ={" "}
                                                  {this.toLocaleString(
                                                    this.state
                                                      .surveyorTotalPrice
                                                  )}{" "}
                                                  {
                                                    this.state
                                                      .surveyorCurrencyType
                                                  }
                                                </span>
                                              ) : (
                                                <span
                                                  style={{ paddingLeft: 3 }}
                                                >
                                                  {" "}
                                                  Baseprice(
                                                  {this.toLocaleString(
                                                    this.state.surveyorBasePrice
                                                  )}
                                                  )+VAT(
                                                  {this.state.surveyorVAT
                                                    ? this.state.surveyorVAT
                                                    : 0}
                                                  ) ={" "}
                                                  {this.toLocaleString(
                                                    this.state
                                                      .surveyorTotalPrice
                                                  )}{" "}
                                                  {
                                                    this.state
                                                      .surveyorCurrencyType
                                                  }
                                                </span>
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Remarks
                                            </label>
                                          </div>
                                          <div>
                                            <label
                                              className="contract_display_header_value"
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {this.state
                                                .motherVesselRemarks !== ""
                                                ? this.state.motherVesselRemarks
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 pl-0">
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Loading Completion
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localDateFormate(
                                                this.state.loadingCompletion
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Vessel Type
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.vessel_type !== ""
                                                ? this.state.vessel_type
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Discharge Port
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.dischargePort !== ""
                                                ? this.state.dischargePort
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Stevedore Additional days
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .stevedoreAdditionalDays !== ""
                                                ? parseInt(
                                                    this.state
                                                      .stevedoreAdditionalDays
                                                  )
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Supplier Surveyor Name
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .supplierSurveyorName !== ""
                                                ? this.state
                                                    .supplierSurveyorName
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              {config.company} Surveyor Name
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .SDAMSAIISurveyorName !== ""
                                                ? this.state
                                                    .SDAMSAIISurveyorName
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Surveyor Additional days
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.surveyorAdditionalDays
                                                ? parseInt(
                                                    this.state
                                                      .surveyorAdditionalDays
                                                  )
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 pl-0">
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Loading Type
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.loadingType !== ""
                                                ? this.state.loadingType
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Vessel Sailing
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localTimeFormate(
                                                this.state.vesselSailing
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              ETA / NOR
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localTimeFormate(
                                                this.state.eta_nor
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Documents on-board
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localTimeFormate(
                                                this.state.documentsOnBoard
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Supplier Surveyor Analysis Type
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .supplierSurveyorAnalysisType !==
                                              ""
                                                ? this.state
                                                    .supplierSurveyorAnalysisType
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              {config.company} Surveyor Analysis
                                              Type
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .SDAMSAIISurveyorAnalysisType !==
                                              ""
                                                ? this.state
                                                    .SDAMSAIISurveyorAnalysisType
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Buyer/Additional Surveyor Name
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .buyerAdditionalSurveyorName !==
                                              ""
                                                ? this.state
                                                    .buyerAdditionalSurveyorName
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 pl-0">
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Loading rate per day
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.loadingRatePerDay !==
                                              ""
                                                ? this.state.loadingRatePerDay
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Vessel Final Quantity in MT
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .vessel_final_quantity_in_mt !==
                                              ""
                                                ? this.toLocaleString(
                                                    this.state
                                                      .vessel_final_quantity_in_mt
                                                  )
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              NOR Acceptance
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {localTimeFormate(
                                                this.state.nor_acceptance
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Dead Freight Account
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state.deadFreightAccount !==
                                              ""
                                                ? this.state.deadFreightAccount
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Supplier Documentation Agent
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .supplierDocumentationAgent !==
                                              ""
                                                ? this.state
                                                    .supplierDocumentationAgent
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              {config.company} Documentation
                                              Agent
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .SDAM_DocumentationAgent !== ""
                                                ? this.state
                                                    .SDAM_DocumentationAgent
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col m-3 pl-0">
                                          <div className="mb-0">
                                            <label className="contract_display_header_label">
                                              Buyer Documentation Agent
                                            </label>
                                          </div>
                                          <div>
                                            <label className="contract_display_header_value">
                                              {this.state
                                                .buyerDocumentationAgent !== ""
                                                ? this.state
                                                    .buyerDocumentationAgent
                                                : "-"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {/**
                           *
                           *  Mother Vessel Drawer
                           *
                           */}

                          <Drawer
                            anchor="right"
                            open={this.state.motherVesselDrawer}
                            variant="temporary"
                            elevation={20}
                          >
                            <div style={{ width: 850 }}>
                              {motherVesselDrawer}
                            </div>
                          </Drawer>
                        </div>
                      )}

                      {this.state.activeTabIndex === 1 && (
                        <div>
                          {(this.state.features.includes("46") ||
                            this.state.features.includes("97")) && (
                            <div className="card">
                              <h5 className="p-2 sub_heading_label mb-0 m-0">
                                Barge Data
                              </h5>
                              {(this.state.features.includes("45") ||
                                this.state.features.includes("97")) && (
                                <div className="col mb-0 p-0">
                                  <div className="clearfix">
                                    <div className="float-left p-2 w-50">
                                      {this.state.totalBarges.length > 0 && (
                                        <div className="row">
                                          <div className="col-lg-6 pl-0">
                                            <span className="contract_display_header_label">
                                              Total Barge Quantity
                                            </span>
                                            <span
                                              className="contract_display_header_value"
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              {this.state.sumOfBargeQuantity
                                                ? this.toLocaleString(
                                                    this.state
                                                      .sumOfBargeQuantity
                                                  )
                                                : 0}
                                            </span>
                                          </div>
                                          <div className="col-lg-6 pl-0">
                                            <span className="contract_display_header_label">
                                              Total Redraft Quantity
                                            </span>
                                            <span
                                              className="contract_display_header_value"
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              {this.state.sumOfRedraftQuantity
                                                ? this.toLocaleString(
                                                    this.state
                                                      .sumOfRedraftQuantity
                                                  )
                                                : 0}
                                            </span>
                                          </div>
                                          {/* <p className='pt-2'>Total Barge Quantity : {this.state.sumOfBargeQuantity ? this.toLocaleString(this.state.sumOfBargeQuantity) : ''}</p>
                                          <p className='pt-2'>Total Redraft Quantity : {this.state.sumOfRedraftQuantity ? this.toLocaleString(this.state.sumOfRedraftQuantity) : ''}</p> */}
                                        </div>
                                      )}
                                    </div>
                                    <div className="float-right p-2">
                                      {this.state.status === "Active" && (
                                        <Button
                                          className="ml-3 specs_button"
                                          style={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            height: 30,
                                            color: config.themeColor,
                                            backgroundColor: "#fff7f2",
                                            fontSize: "14px",
                                            border: "0px",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              openMapBarges: true,
                                            })
                                          }
                                        >
                                          Map Barges
                                        </Button>
                                      )}
                                      {this.state.totalBarges.length > 0 && (
                                        <span>
                                          <Button
                                            onClick={
                                              this.editBargeStatusHandler
                                            }
                                            className="ml-2 specs_button"
                                            style={{
                                              textTransform: "capitalize",
                                              borderRadius: 2,
                                              height: 30,
                                              color: config.themeColor,
                                              backgroundColor: "#fff7f2",
                                              fontSize: "14px",
                                              border: "0px",
                                            }}
                                          >
                                            Edit Barges
                                          </Button>
                                          <FontAwesomeIcon
                                            icon={faFileExcel}
                                            style={{
                                              marginTop: 10,
                                              fontSize: 22,
                                              marginLeft: 3,
                                              color: "#0abb87",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.convertExcelHandler(
                                                this.state.totalBarges,
                                                this.state.businessNo
                                              )
                                            }
                                          />
                                          {/* <i className="fa fa-file-excel-o excel_icon" aria-hidden="true" ata-toggle="tooltip" title="Export Excel" data-html="true" data-placement="bottom" onClick={() => this.convertExcelHandler(this.state.totalBarges, this.state.businessNo)}></i> */}
                                        </span>
                                      )}

                                      {/**
                                       *
                                       *  Map Barges Drawer
                                       *
                                       */}

                                      <Drawer
                                        anchor="right"
                                        open={this.state.openMapBarges}
                                        variant="temporary"
                                        elevation={20}
                                      >
                                        <div style={{ width: 850 }}>
                                          {mapBargesDrawer}
                                        </div>
                                      </Drawer>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.totalBarges.length > 0 && (
                                <div className="row mt-2">
                                  <div className="table-responsive border-0">
                                    <table className="table table-borderless border-0">
                                      <thead className=" border-0">
                                        <tr>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label freeze-pane-left border-0"
                                            style={{
                                              backgroundColor: "#fff7f2",
                                            }}
                                          >
                                            Barge ID
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Allocation ID
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Barge Nomination
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Jetty
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Supplier
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Mine
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Purchase Quality
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-right border-0"
                                          >
                                            Barge Quantity
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-right border-0"
                                          >
                                            Redraft Quantity
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                            style={{ width: 170 }}
                                          >
                                            Barge Status
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Supplier Surveyor
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            {config.company} Surveyor
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Barge Captain Phone No
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Supplier Barge Window
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Buyer Barge Window
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Loading Start Date at Jetty
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Loading completion time at Jetty
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            NOR Anchorage
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Commence Discharging
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label border-0"
                                          >
                                            Completed Discharging
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label freeze-pane-right border-0"
                                            style={{
                                              backgroundColor: "#fff7f2",
                                              boxShadow: "none",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.totalBarges.map(
                                        (e, index) => (
                                          <tbody key={index}>
                                            <tr>
                                              <td
                                                className="table_td border-0 freeze-pane-left"
                                                style={{
                                                  backgroundColor: "#fff7f2",
                                                }}
                                              >
                                                <a
                                                  style={{
                                                    textDecoration: "none",
                                                    color: config.themeColor,
                                                  }}
                                                  href={
                                                    "/view-barge/" +
                                                    btoa(e.barge_id)
                                                  }
                                                >
                                                  {pad(e.barge_id)}
                                                </a>
                                              </td>
                                              <td className="table_td border-0">
                                                {e.display_allocation_id}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.barge_nomination
                                                  ? e.barge_nomination
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.loading_jetty
                                                  ? e.loading_jetty
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.coal_vendor_name
                                                  ? e.coal_vendor_name
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.mine_name
                                                  ? e.mine_name
                                                  : "-"}
                                              </td>
                                              <td className="table_td border-0">
                                                {e.pc_quality}
                                              </td>
                                              <td
                                                className="table_td border-0 text-right"
                                                nowrap="true"
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.allocation_type === "Full" ? (
                                                  <TextField
                                                    name="barge_quantity"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={
                                                      this.state.UpdatedBarges[
                                                        index
                                                      ].barge_quantity_in_mt
                                                        ? this.state
                                                            .UpdatedBarges[
                                                            index
                                                          ].barge_quantity_in_mt
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      var row =
                                                        this.state
                                                          .UpdatedBarges;
                                                      row[
                                                        index
                                                      ].barge_quantity_in_mt =
                                                        e.target.value;
                                                      this.setState({
                                                        UpdatedBarges: row,
                                                      });
                                                    }}
                                                    fullWidth
                                                  />
                                                ) : (
                                                  <span>
                                                    {e.allocation_type ===
                                                    "Full"
                                                      ? e.barge_quantity
                                                        ? this.toLocaleString(
                                                            e.barge_quantity
                                                          )
                                                        : 0
                                                      : e.bm_quantity
                                                      ? this.toLocaleString(
                                                          e.bm_quantity
                                                        )
                                                      : 0}
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0 text-right"
                                                nowrap="true"
                                              >
                                                {e.redraft_quantity
                                                  ? this.toLocaleString(
                                                      e.redraft_quantity
                                                    )
                                                  : 0}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {this.state.EditStatusFields ===
                                                true ? (
                                                  <TextField
                                                    name="barge_status"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={
                                                      this.state.UpdatedBarges[
                                                        index
                                                      ].barge_status
                                                        ? this.state
                                                            .UpdatedBarges[
                                                            index
                                                          ].barge_status
                                                        : "0"
                                                    }
                                                    onChange={(e) => {
                                                      var row =
                                                        this.state
                                                          .UpdatedBarges;
                                                      row[index].barge_status =
                                                        e.target.value;
                                                      this.setState({
                                                        UpdatedBarges: row,
                                                      });
                                                    }}
                                                    fullWidth
                                                    select
                                                  >
                                                    <MenuItem value="0">
                                                      Please Select
                                                    </MenuItem>
                                                    <MenuItem value="Waiting for Loading at Jetty">
                                                      Waiting for Loading at
                                                      Jetty
                                                    </MenuItem>
                                                    <MenuItem value="Loading at Jetty">
                                                      Loading at Jetty
                                                    </MenuItem>
                                                    <MenuItem value="On the way to Jetty">
                                                      On the way to Jetty
                                                    </MenuItem>
                                                    <MenuItem value="Waiting for Documents to Sail">
                                                      Waiting for Documents to
                                                      Sail
                                                    </MenuItem>
                                                    <MenuItem value="On the way to Anchorage">
                                                      On the way to Anchorage
                                                    </MenuItem>
                                                    <MenuItem value="Waiting for Discharging">
                                                      Waiting for Discharging
                                                    </MenuItem>
                                                    <MenuItem value="Discharging">
                                                      Discharging
                                                    </MenuItem>
                                                    <MenuItem value="Discharged">
                                                      Discharged
                                                    </MenuItem>
                                                  </TextField>
                                                ) : e.barge_status ? (
                                                  e.barge_status
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.supplier_surveyor
                                                  ? e.supplier_surveyor
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.internal_surveyor
                                                  ? e.internal_surveyor
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {e.barge_captain_phone_no
                                                  ? e.barge_captain_phone_no
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.supplier_barge_window
                                                  ? e.supplier_barge_window
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                              >
                                                {e.buyer_barge_window
                                                  ? e.buyer_barge_window
                                                  : "-"}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{ width: 170 }}
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.completion_discharge ===
                                                  null ? (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      autoOk
                                                      hideTabs
                                                      inputVariant="outlined"
                                                      variant="inline"
                                                      margin="dense"
                                                      fullWidth
                                                      ampm={false}
                                                      placeholder="Loading Start Date"
                                                      format="dd/MM/yyyy HH:mm"
                                                      value={
                                                        this.state
                                                          .UpdatedBarges[index]
                                                          .loading_date
                                                      }
                                                      onChange={(date) => {
                                                        let row =
                                                          this.state
                                                            .UpdatedBarges;
                                                        row[
                                                          index
                                                        ].loading_date =
                                                          dateFormateToDB(date);
                                                        this.setState({
                                                          UpdatedBarges: row,
                                                        });
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            className="p-0"
                                                          >
                                                            <IconButton>
                                                              <EventIcon />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                ) : (
                                                  localDateFormate(
                                                    e.loading_date
                                                  )
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{ width: 170 }}
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.completion_discharge ===
                                                  null ? (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      autoOk
                                                      hideTabs
                                                      inputVariant="outlined"
                                                      variant="inline"
                                                      margin="dense"
                                                      fullWidth
                                                      ampm={false}
                                                      placeholder="Loading Completion Time"
                                                      format="dd/MM/yyyy HH:mm"
                                                      value={
                                                        this.state
                                                          .UpdatedBarges[index]
                                                          .Loading_completion_time
                                                      }
                                                      onChange={(date) => {
                                                        let row =
                                                          this.state
                                                            .UpdatedBarges;
                                                        row[
                                                          index
                                                        ].Loading_completion_time =
                                                          dateFormateToDB(date);
                                                        this.setState({
                                                          UpdatedBarges: row,
                                                        });
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            className="p-0"
                                                          >
                                                            <IconButton>
                                                              <EventIcon />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                ) : (
                                                  localDateFormate(
                                                    e.Loading_completion_time
                                                  )
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{ width: 200 }}
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.completion_discharge ===
                                                  null ? (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      autoOk
                                                      hideTabs
                                                      inputVariant="outlined"
                                                      variant="inline"
                                                      margin="dense"
                                                      fullWidth
                                                      ampm={false}
                                                      placeholder="NOR Anchorage"
                                                      format="dd/MM/yyyy HH:mm"
                                                      value={
                                                        this.state
                                                          .UpdatedBarges[index]
                                                          .nor_anchorage
                                                      }
                                                      onChange={(date) => {
                                                        let row =
                                                          this.state
                                                            .UpdatedBarges;
                                                        row[
                                                          index
                                                        ].nor_anchorage =
                                                          dateFormateToDB(date);
                                                        this.setState({
                                                          UpdatedBarges: row,
                                                        });
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            className="p-0"
                                                          >
                                                            <IconButton>
                                                              <EventIcon />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                ) : (
                                                  localDateFormate(
                                                    e.nor_anchorage
                                                  )
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{ width: 200 }}
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.completion_discharge ===
                                                  null ? (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      autoOk
                                                      hideTabs
                                                      inputVariant="outlined"
                                                      variant="inline"
                                                      margin="dense"
                                                      fullWidth
                                                      ampm={false}
                                                      placeholder="Commence Discharge"
                                                      format="dd/MM/yyyy HH:mm"
                                                      value={
                                                        this.state
                                                          .UpdatedBarges[index]
                                                          .commence_discharge
                                                      }
                                                      onChange={(date) => {
                                                        let row =
                                                          this.state
                                                            .UpdatedBarges;
                                                        row[
                                                          index
                                                        ].commence_discharge =
                                                          dateFormateToDB(date);
                                                        this.setState({
                                                          UpdatedBarges: row,
                                                        });
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            className="p-0"
                                                          >
                                                            <IconButton>
                                                              <EventIcon />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                ) : (
                                                  localDateFormate(
                                                    e.commence_discharge
                                                  )
                                                )}
                                              </td>
                                              <td
                                                className="table_td border-0"
                                                nowrap="true"
                                                style={{ width: 200 }}
                                              >
                                                {this.state.EditStatusFields ===
                                                  true &&
                                                e.completion_discharge ===
                                                  null ? (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      autoOk
                                                      hideTabs
                                                      inputVariant="outlined"
                                                      variant="inline"
                                                      margin="dense"
                                                      fullWidth
                                                      ampm={false}
                                                      placeholder="Completion Discharge"
                                                      format="dd/MM/yyyy HH:mm"
                                                      value={
                                                        this.state
                                                          .UpdatedBarges[index]
                                                          .completion_discharge
                                                      }
                                                      onChange={(date) => {
                                                        let row =
                                                          this.state
                                                            .UpdatedBarges;
                                                        row[
                                                          index
                                                        ].completion_discharge =
                                                          dateFormateToDB(date);
                                                        this.setState({
                                                          UpdatedBarges: row,
                                                        });
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            className="p-0"
                                                          >
                                                            <IconButton>
                                                              <EventIcon />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                ) : (
                                                  localDateFormate(
                                                    e.completion_discharge
                                                  )
                                                )}
                                              </td>
                                              <td
                                                className="freeze-pane-right text-center"
                                                nowrap="true"
                                                style={{
                                                  border: "none",
                                                  boxShadow: "none",
                                                  backgroundColor: "#fff7f2",
                                                }}
                                              >
                                                <i
                                                  data-toggle="tooltip"
                                                  title="Add"
                                                  data-html="true"
                                                  data-placement="bottom"
                                                  className="fa fa-ellipsis-h"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: 22,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                    verticalAlign: "middle",
                                                  }}
                                                  onClick={(event) =>
                                                    this.setState({
                                                      viewMenuOpen: true,
                                                      forViewbargeID:
                                                        e.barge_id,
                                                      business_allocation_id:
                                                        e.business_allocation_id,
                                                      anchorEl:
                                                        event.currentTarget,
                                                    })
                                                  }
                                                ></i>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      )}
                                    </table>
                                  </div>
                                  {this.state.EditStatusFields === true && (
                                    <div>
                                      <button
                                        type="button"
                                        className="drawer_button drawer_text drawer_calculate_adjustment mt-2  mr-2"
                                        onClick={() =>
                                          this.setState({
                                            EditStatusFields: false,
                                          })
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="mt-2 drawer_button drawer_text"
                                        onClick={this.UpdateBargeStatusHandler}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}

                                  <div className="table-responsive mt-2">
                                    {this.state.combOfBarges.length > 0 && (
                                      <table className="table table-borderless mt-2 border-0 w-100">
                                        <thead>
                                          <tr>
                                            <th
                                              className="table_header_barge_label"
                                              style={{ border: "none" }}
                                            >
                                              Quality
                                            </th>
                                            <th
                                              className="table_header_barge_label"
                                              style={{ border: "none" }}
                                            >
                                              Supplier
                                            </th>
                                            <th
                                              className="table_header_barge_label"
                                              style={{ border: "none" }}
                                            >
                                              Mine
                                            </th>
                                            <th
                                              className="table_header_barge_label text-right"
                                              style={{ border: "none" }}
                                            >
                                              Total Barge Quantity
                                            </th>
                                            <th
                                              className="table_header_barge_label text-right"
                                              style={{ border: "none" }}
                                            >
                                              Total Redraft Quantity
                                            </th>
                                          </tr>
                                        </thead>
                                        {this.state.combOfBarges.map(
                                          (e, idx) => (
                                            <tbody key={idx}>
                                              <tr>
                                                <td className="table_td border-0">
                                                  {e.pc_quality
                                                    ? e.pc_quality
                                                    : "-"}
                                                </td>
                                                <td className="table_td border-0">
                                                  {e.coal_vendor_name
                                                    ? e.coal_vendor_name
                                                    : "-"}
                                                </td>
                                                <td className="table_td border-0">
                                                  {e.mine_name
                                                    ? e.mine_name
                                                    : "-"}
                                                </td>
                                                <td className="table_td border-0 text-right">
                                                  {e.total_barge_quantity
                                                    ? this.toLocaleString(
                                                        e.total_barge_quantity
                                                      )
                                                    : 0}
                                                </td>
                                                <td className="table_td border-0 text-right">
                                                  {e.total_redraft_quantity
                                                    ? this.toLocaleString(
                                                        e.total_redraft_quantity
                                                      )
                                                    : 0}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    )}
                                  </div>
                                  <div>
                                    <ButtonGroup
                                      color="primary"
                                      aria-label="large outlined primary button group"
                                    >
                                      {/* {(this.state.features.includes('55')  || this.state.features.includes('97') ) &&
                                        <Button data-toggle="tooltip" title="Add" data-html="true" data-placement="bottom" >
                                          <i className="fa fa-plus-circle mr-2" aria-hidden="true" style={{ fontSize: 22, color: '#3256a8', cursor: 'pointer' }}></i>
                                          <a href={'/purchase-quality-result/' + btoa(businessNo)}>Purchase Quality Results</a>
                                        </Button>
                                      } */}
                                      {(this.state.features.includes("55") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <button
                                          className="header_button header_button_text addrow_button_adjustment"
                                          style={{ width: 250 }}
                                          onClick={() =>
                                            this.setState({
                                              openDrawer: true,
                                              salesQualityResultsAdd: true,
                                              salesQualityResultsView: false,
                                              salesQualityResultsUpdate: false,
                                            })
                                          }
                                        >
                                          <i
                                            className="fa fa-plus-circle mr-2"
                                            aria-hidden="true"
                                            style={{
                                              fontSize: 22,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                          >
                                            &nbsp;Sales Quality Results
                                          </i>

                                          {/* <a href={'/sales-quality-result/' + btoa(businessNo)} style={{ color: config.themeColor, verticalAlign: 'text-top' }}>Sales Quality Results</a> */}
                                        </button>
                                      )}
                                    </ButtonGroup>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.businessTabIndex === 2 && (
                    <div className="mt-3">
                      <div className="row border-bottom pb-2">
                        {(this.state.features.includes("314") ||
                          this.state.features.includes("97")) && (
                          <Button
                            className="ml-3 specs_button"
                            onClick={() => {
                              this.setState({
                                financialActiveTabIndex: 1,
                              });
                            }}
                            style={{
                              textTransform: "capitalize",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.financialActiveTabIndex === 1
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.financialActiveTabIndex === 1
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Summary
                          </Button>
                        )}
                        {(this.state.features.includes("190") ||
                          this.state.features.includes("191") ||
                          this.state.features.includes("97")) && (
                          <Button
                            className="ml-3 specs_button"
                            onClick={() => {
                              this.setState({
                                financialActiveTabIndex: 2,
                              });
                            }}
                            style={{
                              textTransform: "capitalize",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.financialActiveTabIndex === 2
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.financialActiveTabIndex === 2
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Coal
                          </Button>
                        )}
                        <Button
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialActiveTabIndex: 3,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialActiveTabIndex === 3
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialActiveTabIndex === 3
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Services
                        </Button>
                        <Button
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialActiveTabIndex: 4,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialActiveTabIndex === 4
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialActiveTabIndex === 4
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Others
                        </Button>
                      </div>

                      {(this.state.features.includes("314") ||
                        this.state.features.includes("97")) &&
                        this.state.financialActiveTabIndex === 1 && (
                          <span>
                            <BusinessProfitLossSummary
                              businessNoID={businessNo}
                            />
                          </span>
                        )}

                      <div className="row col-lg">
                        {this.state.financialActiveTabIndex === 2 && (
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState({
                                sales_fin_active_tab_index: 0,
                              });
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.sales_fin_active_tab_index === 0
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.sales_fin_active_tab_index === 0
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Performa Coal Costing
                          </Button>
                        )}
                        {this.state.financialActiveTabIndex === 2 &&
                          (this.state.features.includes("190") ||
                            this.state.features.includes("191") ||
                            this.state.features.includes("97")) && (
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  sales_fin_active_tab_index: 1,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.sales_fin_active_tab_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.sales_fin_active_tab_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Commercial Coal Costing
                            </Button>
                          )}
                      </div>

                      {this.state.sales_fin_active_tab_index === 0 &&
                        this.state.financialActiveTabIndex === 2 && (
                          <div className="row col-lg">
                            <div className="row mt-2 mb-2 col-lg p-0">
                              <SalesPIList businessNoID={businessNo} />
                            </div>
                          </div>
                        )}
                      {this.state.sales_fin_active_tab_index === 1 &&
                        this.state.financialActiveTabIndex === 2 && (
                          <div className="row col-lg">
                            <div className="row mt-2 mb-2 col-lg p-0">
                              <BusinessNoSalesCoalCostingList
                                businessNoID={businessNo}
                                costing_type="Commercial"
                              />
                            </div>
                          </div>
                        )}

                      {this.state.financialActiveTabIndex === 3 && (
                        <div className="col mt-3">
                          <Accordion
                            defaultExpanded={false}
                            className="accordion_collapse"
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              style={{
                                height: 15,
                                backgroundColor: "#f7f3f0",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                            >
                              <h5 className="section-header">Barge</h5>
                            </AccordionSummary>
                            <AccordionDetails className="row col-lg p-2">
                              <div className="row col-lg-12 border-bottom pb-2 pl-0">
                                {(this.state.features.includes("53") ||
                                  this.state.features.includes("151") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-0 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialBargeServicesActiveTabIndex: 1,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialBargeServicesActiveTabIndex ===
                                        1
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialBargeServicesActiveTabIndex ===
                                        1
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Buyer Demurrage
                                  </Button>
                                )}
                              </div>
                              <div className="row col-lg-12 p-0">
                                {this.state
                                  .financialBargeServicesActiveTabIndex === 1 &&
                                  (this.state.features.includes("53") ||
                                    this.state.features.includes("151") ||
                                    this.state.features.includes("97")) && (
                                    <BuyerDemurrageList
                                      businessNo={businessNo}
                                    />
                                  )}
                              </div>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            defaultExpanded={true}
                            className="accordion_collapse"
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              style={{
                                height: 15,
                                backgroundColor: "#f7f3f0",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                            >
                              <h5 className="section-header">Mother Vessel</h5>
                            </AccordionSummary>
                            <AccordionDetails className="row col-lg p-0">
                              <div className="row mt-2 mb-2">
                                {(this.state.features.includes("258") ||
                                  this.state.features.includes("259") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 1,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        1
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        1
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Floating Crane Charges
                                  </Button>
                                )}
                                {(this.state.features.includes("226") ||
                                  this.state.features.includes("227") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 2,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        2
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        2
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Stevedore Costing
                                  </Button>
                                )}

                                {(this.state.features.includes("226") ||
                                  this.state.features.includes("227") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 14,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        14
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        14
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    PNBP Charges
                                  </Button>
                                )}
                                {(this.state.features.includes("379") ||
                                  this.state.features.includes("380") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 3,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        3
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        3
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Freight Costing
                                  </Button>
                                )}
                                {(this.state.features.includes("234") ||
                                  this.state.features.includes("235") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 4,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        4
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        4
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Coal Procurement Charges
                                  </Button>
                                )}
                                <Button
                                  className="ml-3 specs_button"
                                  onClick={() => {
                                    this.setState({
                                      financialMVServicesActiveTabIndex: 11,
                                    });
                                  }}
                                  style={{
                                    textTransform: "capitalize",
                                    borderRadius: 35,
                                    height: 30,
                                    color:
                                      this.state
                                        .financialMVServicesActiveTabIndex ===
                                      11
                                        ? config.themeColor
                                        : "#483b34b3",
                                    backgroundColor:
                                      this.state
                                        .financialMVServicesActiveTabIndex ===
                                      11
                                        ? "#fff7f2"
                                        : "#fff",
                                    fontSize: "14px",
                                    border: "0px",
                                  }}
                                >
                                  Coal Sales Charges
                                </Button>
                                {(this.state.features.includes("242") ||
                                  this.state.features.includes("243") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 5,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        5
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        5
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Sampling & Analysis Charges
                                  </Button>
                                )}
                                {(this.state.features.includes("250") ||
                                  this.state.features.includes("251") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 6,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        6
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        6
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Pre-Shipment Inspection
                                  </Button>
                                )}
                                {(this.state.features.includes("266") ||
                                  this.state.features.includes("267") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 7,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        7
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        7
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Export Tax
                                  </Button>
                                )}
                                {(this.state.features.includes("274") ||
                                  this.state.features.includes("275") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 8,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        8
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        8
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Documentation Charges
                                  </Button>
                                )}

                                {(this.state.features.includes("274") ||
                                  this.state.features.includes("275") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 13,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        12
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        13
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Laporan Charges
                                  </Button>
                                )}

                                {this.state.features.includes("97") &&
                                  this.isShownDeadFreight() && (
                                    <Button
                                      className="ml-3 specs_button"
                                      onClick={() => {
                                        this.setState({
                                          financialMVServicesActiveTabIndex: 12,
                                        });
                                      }}
                                      style={{
                                        textTransform: "capitalize",
                                        borderRadius: 35,
                                        height: 30,
                                        color:
                                          this.state
                                            .financialMVServicesActiveTabIndex ===
                                          12
                                            ? config.themeColor
                                            : "#483b34b3",
                                        backgroundColor:
                                          this.state
                                            .financialMVServicesActiveTabIndex ===
                                          12
                                            ? "#fff7f2"
                                            : "#fff",
                                        fontSize: "14px",
                                        border: "0px",
                                      }}
                                    >
                                      Dead Freight
                                    </Button>
                                  )}
                                {(this.state.features.includes("49") ||
                                  this.state.features.includes("50") ||
                                  this.state.features.includes("97")) && (
                                  <Button
                                    className="ml-3 specs_button"
                                    onClick={() => {
                                      this.setState({
                                        financialMVServicesActiveTabIndex: 9,
                                      });
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      borderRadius: 35,
                                      height: 30,
                                      color:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        9
                                          ? config.themeColor
                                          : "#483b34b3",
                                      backgroundColor:
                                        this.state
                                          .financialMVServicesActiveTabIndex ===
                                        9
                                          ? "#fff7f2"
                                          : "#fff",
                                      fontSize: "14px",
                                      border: "0px",
                                    }}
                                  >
                                    Demurrage
                                  </Button>
                                )}
                                {(this.state.shipment_type === "CIF MV" ||
                                  this.state.shipment_type === "CFR MV") &&
                                  (this.state.features.includes("354") ||
                                    this.state.features.includes("355") ||
                                    this.state.features.includes("97")) && (
                                    <Button
                                      className="ml-3 specs_button"
                                      onClick={() => {
                                        this.setState({
                                          financialMVServicesActiveTabIndex: 10,
                                        });
                                      }}
                                      style={{
                                        textTransform: "capitalize",
                                        borderRadius: 35,
                                        height: 30,
                                        color:
                                          this.state
                                            .financialMVServicesActiveTabIndex ===
                                          10
                                            ? config.themeColor
                                            : "#483b34b3",
                                        backgroundColor:
                                          this.state
                                            .financialMVServicesActiveTabIndex ===
                                          10
                                            ? "#fff7f2"
                                            : "#fff",
                                        fontSize: "14px",
                                        border: "0px",
                                      }}
                                    >
                                      Insurance
                                    </Button>
                                  )}
                              </div>
                              {this.state.financialMVServicesActiveTabIndex ===
                                1 &&
                                (this.state.features.includes("258") ||
                                  this.state.features.includes("259") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MvFloatingCraneChargesList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}

                              {this.state.financialMVServicesActiveTabIndex ===
                                12 && (
                                <div className="col-lg-12">
                                  <MvDeadFreightList
                                    businessNoID={businessNo}
                                    deadFreightValue={this.MvDeadFreightValue()}
                                  />
                                </div>
                              )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                14 && (
                                <div className="col-lg-12">
                                  <PnbpChargesList businessNoID={businessNo} />
                                </div>
                              )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                2 &&
                                (this.state.features.includes("226") ||
                                  this.state.features.includes("227") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MVStevedorCostingList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                3 &&
                                (this.state.features.includes("379") ||
                                  this.state.features.includes("380") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <FreightCostingView
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                4 &&
                                (this.state.features.includes("234") ||
                                  this.state.features.includes("235") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MVProcurementChargesList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                5 &&
                                (this.state.features.includes("242") ||
                                  this.state.features.includes("243") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MvSacList businessNoID={businessNo} />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                6 &&
                                (this.state.features.includes("250") ||
                                  this.state.features.includes("251") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MvPreShipmentList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                7 &&
                                (this.state.features.includes("266") ||
                                  this.state.features.includes("267") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <ExportTaxList businessNoID={businessNo} />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                8 &&
                                (this.state.features.includes("274") ||
                                  this.state.features.includes("275") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MvDocList businessNoID={businessNo} />
                                  </div>
                                )}

                              {this.state.financialMVServicesActiveTabIndex ===
                                13 &&
                                (this.state.features.includes("274") ||
                                  this.state.features.includes("275") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <LaporanChargesList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}

                              {this.state.financialMVServicesActiveTabIndex ===
                                9 && (
                                <div className="col-lg-12">
                                  <hr className="mb-1" />
                                  {(this.state.features.includes("49") ||
                                    this.state.features.includes("50") ||
                                    this.state.features.includes("97")) && (
                                    <Button
                                      className="tab_button"
                                      onClick={() => {
                                        this.setState({
                                          financialMVDemurrageActiveTabIndex: 1,
                                        });
                                      }}
                                      style={{
                                        textTransform: "capitalize",
                                        height: 30,
                                        color:
                                          this.state
                                            .financialMVDemurrageActiveTabIndex ===
                                          1
                                            ? config.themeColor
                                            : "#483b34b3",
                                        backgroundColor:
                                          this.state
                                            .financialMVDemurrageActiveTabIndex ===
                                          1
                                            ? "#fff7f2"
                                            : "#fff",
                                        fontSize: "14px",
                                        border: "0px",
                                      }}
                                    >
                                      Supplier Demurrage
                                    </Button>
                                  )}
                                  {(this.state.features.includes("49") ||
                                    this.state.features.includes("50") ||
                                    this.state.features.includes("97")) && (
                                    <Button
                                      className="tab_button"
                                      onClick={() => {
                                        this.setState({
                                          financialMVDemurrageActiveTabIndex: 2,
                                        });
                                      }}
                                      style={{
                                        textTransform: "capitalize",
                                        height: 30,
                                        color:
                                          this.state
                                            .financialMVDemurrageActiveTabIndex ===
                                          2
                                            ? config.themeColor
                                            : "#483b34b3",
                                        backgroundColor:
                                          this.state
                                            .financialMVDemurrageActiveTabIndex ===
                                          2
                                            ? "#fff7f2"
                                            : "#fff",
                                        fontSize: "14px",
                                        border: "0px",
                                      }}
                                    >
                                      Buyer Demurrage
                                    </Button>
                                  )}
                                  {this.state
                                    .financialMVDemurrageActiveTabIndex === 1 &&
                                    (this.state.features.includes("49") ||
                                      this.state.features.includes("50") ||
                                      this.state.features.includes("97") >=
                                        0) && (
                                      <div className="col-lg-12">
                                        <MvSupplierDemurrageList
                                          businessNoID={businessNo}
                                        />
                                      </div>
                                    )}
                                  {this.state
                                    .financialMVDemurrageActiveTabIndex === 2 &&
                                    (this.state.features.includes("49") ||
                                      this.state.features.includes("50") ||
                                      this.state.features.includes("97") >=
                                        0) && (
                                      <div className="col-lg-12">
                                        <MvBuyerDemurrageList
                                          businessNoID={businessNo}
                                        />
                                      </div>
                                    )}
                                </div>
                              )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                10 &&
                                (this.state.features.includes("354") ||
                                  this.state.features.includes("355") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <VesselInsuranceView
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVServicesActiveTabIndex ===
                                11 &&
                                (this.state.features.includes("282") ||
                                  this.state.features.includes("283") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12">
                                    <MvCscList businessNoID={businessNo} />
                                  </div>
                                )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}

                      {this.state.financialActiveTabIndex === 4 && (
                        <div className="col mt-3">
                          <div className="row mt-2 mb-2">
                            <Button
                              className="ml-3 specs_button"
                              onClick={() => {
                                this.setState({
                                  financialOthersActiveTabIndex: 1,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.financialOthersActiveTabIndex === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.financialOthersActiveTabIndex === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Debit Note
                            </Button>
                            <Button
                              className="ml-3 specs_button"
                              onClick={() => {
                                this.setState({
                                  financialOthersActiveTabIndex: 2,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.financialOthersActiveTabIndex === 2
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.financialOthersActiveTabIndex === 2
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Credit Note
                            </Button>
                            <Button
                              className="ml-3 specs_button"
                              onClick={() => {
                                this.setState({
                                  financialOthersActiveTabIndex: 3,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.financialOthersActiveTabIndex === 3
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.financialOthersActiveTabIndex === 3
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Other Expenses
                            </Button>
                            <Button
                              className="ml-3 specs_button"
                              onClick={() => {
                                this.setState({
                                  financialOthersActiveTabIndex: 4,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.financialOthersActiveTabIndex === 4
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.financialOthersActiveTabIndex === 4
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Other Income
                            </Button>
                          </div>

                          {this.state.financialOthersActiveTabIndex === 1 && (
                            <div className="col-lg-12">
                              <hr className="mb-1" />
                              {(this.state.features.includes("82") ||
                                this.state.features.includes("83") ||
                                this.state.features.includes("97")) && (
                                <Button
                                  className="tab_button"
                                  onClick={() => {
                                    this.setState({
                                      financialMVDebitActiveTabIndex: 1,
                                    });
                                  }}
                                  style={{
                                    textTransform: "capitalize",
                                    height: 30,
                                    color:
                                      this.state
                                        .financialMVDebitActiveTabIndex === 1
                                        ? config.themeColor
                                        : "#483b34b3",
                                    backgroundColor:
                                      this.state
                                        .financialMVDebitActiveTabIndex === 1
                                        ? "#fff7f2"
                                        : "#fff",
                                    fontSize: "14px",
                                    border: "0px",
                                  }}
                                >
                                  Incoming
                                </Button>
                              )}
                              {(this.state.features.includes("82") ||
                                this.state.features.includes("83") ||
                                this.state.features.includes("97")) && (
                                <Button
                                  className="tab_button"
                                  onClick={() => {
                                    this.setState({
                                      financialMVDebitActiveTabIndex: 2,
                                    });
                                  }}
                                  style={{
                                    textTransform: "capitalize",
                                    height: 30,
                                    color:
                                      this.state
                                        .financialMVDebitActiveTabIndex === 2
                                        ? config.themeColor
                                        : "#483b34b3",
                                    backgroundColor:
                                      this.state
                                        .financialMVDebitActiveTabIndex === 2
                                        ? "#fff7f2"
                                        : "#fff",
                                    fontSize: "14px",
                                    border: "0px",
                                  }}
                                >
                                  Outgoing
                                </Button>
                              )}
                              {this.state.financialMVDebitActiveTabIndex ===
                                1 &&
                                (this.state.features.includes("82") ||
                                  this.state.features.includes("83") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12 p-0">
                                    <IncomingDebitNoteList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVDebitActiveTabIndex ===
                                2 &&
                                (this.state.features.includes("82") ||
                                  this.state.features.includes("83") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12 p-0">
                                    <OutgoingDebitNoteList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                            </div>
                          )}
                          {this.state.financialOthersActiveTabIndex === 2 && (
                            <div className="col-lg-12">
                              <hr className="mb-1" />
                              {(this.state.features.includes("82") ||
                                this.state.features.includes("83") ||
                                this.state.features.includes("97")) && (
                                <Button
                                  className="tab_button"
                                  onClick={() => {
                                    this.setState({
                                      financialMVCreditActiveTabIndex: 1,
                                    });
                                  }}
                                  style={{
                                    textTransform: "capitalize",
                                    height: 30,
                                    color:
                                      this.state
                                        .financialMVCreditActiveTabIndex === 1
                                        ? config.themeColor
                                        : "#483b34b3",
                                    backgroundColor:
                                      this.state
                                        .financialMVCreditActiveTabIndex === 1
                                        ? "#fff7f2"
                                        : "#fff",
                                    fontSize: "14px",
                                    border: "0px",
                                  }}
                                >
                                  Incoming
                                </Button>
                              )}
                              {(this.state.features.includes("82") ||
                                this.state.features.includes("83") ||
                                this.state.features.includes("97")) && (
                                <Button
                                  className="tab_button"
                                  onClick={() => {
                                    this.setState({
                                      financialMVCreditActiveTabIndex: 2,
                                    });
                                  }}
                                  style={{
                                    textTransform: "capitalize",
                                    height: 30,
                                    color:
                                      this.state
                                        .financialMVCreditActiveTabIndex === 2
                                        ? config.themeColor
                                        : "#483b34b3",
                                    backgroundColor:
                                      this.state
                                        .financialMVCreditActiveTabIndex === 2
                                        ? "#fff7f2"
                                        : "#fff",
                                    fontSize: "14px",
                                    border: "0px",
                                  }}
                                >
                                  Outgoing
                                </Button>
                              )}
                              {this.state.financialMVCreditActiveTabIndex ===
                                1 &&
                                (this.state.features.includes("82") ||
                                  this.state.features.includes("83") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12 p-0">
                                    <IncomingCreditNoteList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                              {this.state.financialMVCreditActiveTabIndex ===
                                2 &&
                                (this.state.features.includes("82") ||
                                  this.state.features.includes("83") ||
                                  this.state.features.includes("97")) && (
                                  <div className="col-lg-12 p-0">
                                    <OutgoingCreditNoteList
                                      businessNoID={businessNo}
                                    />
                                  </div>
                                )}
                            </div>
                          )}
                          {this.state.financialOthersActiveTabIndex === 3 &&
                            (this.state.features.includes("294") ||
                              this.state.features.includes("295") ||
                              this.state.features.includes("97")) && (
                              <div className="col-lg-12 p-0">
                                <OtherExpenseList businessNoID={businessNo} />
                              </div>
                            )}
                          {this.state.financialOthersActiveTabIndex === 4 &&
                            (this.state.features.includes("302") ||
                              this.state.features.includes("303") ||
                              this.state.features.includes("97")) && (
                              <div className="col-lg-12 p-0">
                                <OtherIncomeList businessNoID={businessNo} />
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.businessTabIndex === 3 && (
                    <div>
                      <BusinessNotifyList businessNo={businessNo} />
                    </div>
                  )}
                  {this.state.businessTabIndex === 4 && (
                    <div>
                      <BusinessNoVesselReportList businessNo={businessNo} />
                    </div>
                  )}
                </div>
                <br />
              </div>
            </div>
          )}
        </div>

        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content allocation_modal">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  style={{
                    border: "none",
                    padding: 0,
                    textTransform: "capitalize",
                  }}
                >
                  {this.state.modalName === "increment"
                    ? "Increase Allocation"
                    : "Decrease Allocation"}
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body pb-0 pt-0">
                <div className="row mt-1 pl-0">
                  <span style={{ color: "red", fontSize: 12 }}>
                    {this.state.errorMsg}
                  </span>
                </div>
                <TextField
                  name="quantityAllocating"
                  placeholder={
                    this.state.modalName === "increment"
                      ? "Quantity Increase *"
                      : "Quantity Decrease *"
                  }
                  margin="dense"
                  variant="outlined"
                  type="text"
                  onChange={this.handleText}
                  error={this.state.quantityAllocationError}
                  value={this.state.quantityAllocating}
                />
                <br />
                <div className="row mt-1 pl-0">
                  {/* {this.state.modalName === 'increment' ? <p><i>Note:</i> </p> : <p><i>Note :</i> </p> } */}
                  <p style={{ color: "red", fontSize: 12 }}>
                    Note: Please enter the difference Quantity
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    this.incrementORDecrementBusinessAllocation(
                      this.state.rowValue,
                      this.state.index,
                      this.state.modalName
                    )
                  }
                >
                  Submit
                </button>
                &emsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      quantityAllocationError: false,
                      quantityAllocating: "",
                      errorMsg: "",
                    })
                  }
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="purchaseModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content allocation_modal">
              <div className="modal-body">
                <label className="form_label mb-2 mt-2">
                  Select Purchase Quality
                </label>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="row col-lg-12 p-0">
                  {this.state.purchaseTypes.map((e, index) => (
                    <div className="col col-lg-6 p-1 mb-2" key={index}>
                      <div
                        className="row col-lg-12 rounded p-0"
                        style={{
                          border:
                            e.id === this.state.purchaseQualitySelection
                              ? "1px solid #ec7833"
                              : "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-1 p-0">
                          <NewRadio
                            checked={
                              e.id === this.state.purchaseQualitySelection
                            }
                            onChange={this.handlePurchaseTypesRadioButtons}
                            value={e.id}
                            name="purchaseQualitySelection"
                            inputProps={{ "aria-label": "B" }}
                            className="collapsed"
                            data-toggle="collapse"
                            data-target={"#demo1" + e.id}
                            aria-expanded="false"
                            aria-controls={"group-of-rows-1"}
                          />
                        </div>
                        <div className="col-lg-11 pr-0">
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quality
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.quality}
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_label mb-0">
                                Tolerence
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.tolerance_adjusted + "%"}
                              </label>
                            </div>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quantity
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_total mb-0">
                                Total
                              </label>
                              <label className="drawer_section_total_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_adjusted).toFixed(3)
                                )}
                              </label>
                            </div>
                          </div>
                          <div style={{ width: "98%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={this.progressBarSize(e, "NPC")}
                              style={{ borderRadius: 5 }}
                            />
                          </div>
                          <div className="row col-lg-12 p-0 mb-2">
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_allocated).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Allocated
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.pc_quantity_delivered).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Delivered
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(this._toBeDelivered(e))}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Remaining
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <label className="form_label mb-2 mt-2">
                  Select Purchase Type
                </label>
                <div className="pl-0">
                  {this.state.purchaseTypes.map((e, index) => (
                    <div key={index}>
                      {e.id === this.state.purchaseQualitySelection && (
                        <table style={{ width: "100%" }}>
                          <thead className="thead-light">
                            <tr>
                              <th></th>
                              <th className="drawer_table_header_label">
                                Mine Name
                              </th>
                              <th className="drawer_table_header_label">
                                Purchase Type
                              </th>
                              <th className="drawer_table_header_label">
                                Anchorage Name
                              </th>
                              <th className="drawer_table_header_label">
                                Coal Price
                              </th>
                            </tr>
                          </thead>
                          {e.purchase_types.map((p, idx) => (
                            <tbody key={idx}>
                              <tr
                                style={{
                                  border:
                                    p.id === this.state.purchaseTypeSelection
                                      ? "1px solid #ec7833"
                                      : "1px solid #dee2e6",
                                }}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <NewRadio
                                    checked={
                                      p.id === this.state.purchaseTypeSelection
                                    }
                                    onChange={
                                      this.handlePurchaseTypesRadioButtons
                                    }
                                    value={p.id}
                                    name="purchaseTypeSelection"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                </td>
                                <td className="drawer_table_value">
                                  {p.mine_name}
                                </td>
                                <td className="drawer_table_value">
                                  {p.purchase_type}
                                </td>
                                <td className="drawer_table_value">
                                  {p.purchase_type === "FAS Anchorage"
                                    ? p.anchorage_name
                                    : ""}
                                </td>
                                <td className="drawer_table_value">
                                  {p.price_pmt} {this.state.purchaseCurrency}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  onClick={() =>
                    this.purchaseContractModalClose(this.state.modalID)
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  onClick={() => this.purchaseTypeSelected(this.state.modalID)}
                >
                  Select
                </button>
                &emsp;
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="updatePurchaseModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content allocation_modal">
              <div className="modal-body">
                <label className="form_label mb-2 mt-2">
                  Select Purchase Quality
                </label>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="row col-lg-12 p-0">
                  {this.state.updatePurchaseTypes.map((e, index) => (
                    <div className="col col-lg-6 p-1 mb-2" key={index}>
                      <div
                        className="row col-lg-12 rounded p-0"
                        style={{
                          border:
                            e.id === this.state.updatePurchaseQualitySelectionID
                              ? "1px solid #ec7833"
                              : "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-1 p-0">
                          <NewRadio
                            checked={
                              e.id ===
                              this.state.updatePurchaseQualitySelectionID
                            }
                            onChange={(event) =>
                              this.handleUpdatePurchaseTypesRadioButtons(
                                e,
                                "updatePurchaseQualitySelectionID"
                              )
                            }
                            value={e.id}
                            name="updatePurchaseQualitySelectionID"
                            inputProps={{ "aria-label": "B" }}
                            className="collapsed"
                            data-toggle="collapse"
                            data-target={"#demo1" + e.id}
                            aria-expanded="false"
                            aria-controls={"group-of-rows-1"}
                          />
                        </div>
                        <div className="col-lg-11 pr-0">
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quality
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.quality}
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_label mb-0">
                                Tolerence
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.tolerance_adjusted + "%"}
                              </label>
                            </div>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quantity
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_total mb-0">
                                Total
                              </label>
                              <label className="drawer_section_total_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_adjusted).toFixed(3)
                                )}
                              </label>
                            </div>
                          </div>
                          <div style={{ width: "98%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={this.progressBarSize(e, "NPC")}
                              style={{ borderRadius: 5 }}
                            />
                          </div>
                          <div className="row col-lg-12 p-0 mb-2">
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_allocated).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Allocated
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.pc_quantity_delivered).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Delivered
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(this._toBeDelivered(e))}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Remaining
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.allocationPurchaseErrorMessage !== "" &&
                        e.id === this.state.allocationPurchaseErrorId && (
                          <span
                            className="please-enter-the-increase-quantity-number"
                            style={{ color: "red" }}
                          >
                            {this.state.allocationPurchaseErrorMessage}
                          </span>
                        )}
                    </div>
                  ))}
                </div>
                <label className="form_label mb-2 mt-2">
                  Select Purchase Type
                </label>
                <div className="pl-0">
                  {this.state.updatePurchaseTypes.map((e, index) => (
                    <div key={index}>
                      {e.id === this.state.updatePurchaseQualitySelectionID && (
                        <table style={{ width: "100%" }}>
                          <thead className="thead-light">
                            <tr>
                              <th></th>
                              <th className="drawer_table_header_label">
                                Mine Name
                              </th>
                              <th className="drawer_table_header_label">
                                Purchase Type
                              </th>
                              <th className="drawer_table_header_label">
                                Anchorage Name
                              </th>
                              <th className="drawer_table_header_label">
                                Coal Price
                              </th>
                            </tr>
                          </thead>
                          {e.purchase_types.map((p, idx) => (
                            <tbody key={idx}>
                              <tr
                                style={{
                                  border:
                                    p.id ===
                                    this.state.updatePurchaseTypeSelectionID
                                      ? "1px solid #ec7833"
                                      : "1px solid #dee2e6",
                                }}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <NewRadio
                                    checked={
                                      p.id ===
                                      this.state.updatePurchaseTypeSelectionID
                                    }
                                    onChange={(event) =>
                                      this.handleUpdatePurchaseTypesRadioButtons(
                                        p,
                                        "updatePurchaseTypeSelectionID"
                                      )
                                    }
                                    value={p.id}
                                    name="updatePurchaseTypeSelectionID"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                </td>
                                <td className="drawer_table_value">
                                  {p.mine_name}
                                </td>
                                <td className="drawer_table_value">
                                  {p.purchase_type}
                                </td>
                                <td className="drawer_table_value">
                                  {p.purchase_type === "FAS Anchorage"
                                    ? p.anchorage_name
                                    : ""}
                                </td>
                                <td className="drawer_table_value">
                                  {p.price_pmt} {this.state.purchaseCurrency}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  onClick={() =>
                    this.updatePurchaseContractModalClose(this.state.modalID)
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  disabled={this.state.updateEnabled}
                  onClick={() =>
                    this.updatePurchaseTypeSelected(this.state.modalID)
                  }
                >
                  Update
                  {this.state.updateButtonEnabled && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "green",
                        position: "absolute",
                        bottom: "22px",
                        left: "20%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="salesContractModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content allocation_modal">
              <div className="modal-body">
                <label className="form_label mb-2 mt-2">
                  Select Sales Quality
                </label>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="row col-lg-12 pl-0">
                  {this.state.salesTypes.map((e, index) => (
                    <div className="col col-lg-6 p-1 mb-2" key={index}>
                      <div
                        className="row col-lg-12 rounded p-0"
                        style={{
                          border:
                            e.id === this.state.salesQualitySelection
                              ? "1px solid #ec7833"
                              : "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-1 p-0">
                          <NewRadio
                            checked={e.id === this.state.salesQualitySelection}
                            onChange={this.handleSalesTypesRadioButtons}
                            value={e.id}
                            name="salesQualitySelection"
                            inputProps={{ "aria-label": "B" }}
                            className="collapsed"
                            data-toggle="collapse"
                            data-target={"#demo" + e.id}
                            aria-expanded="false"
                            aria-controls={"group-of-rows-1"}
                          />
                        </div>
                        <div className="col-lg-11 pr-0">
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quality
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.quality}
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_label mb-0">
                                Tolerence
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.tolerance_adjusted + "%"}
                              </label>
                            </div>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quantity
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_total mb-0">
                                Total
                              </label>
                              <label className="drawer_section_total_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_adjusted).toFixed(3)
                                )}
                              </label>
                            </div>
                          </div>
                          <div style={{ width: "98%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={this.progressBarSize(e, "NSC")}
                              style={{ borderRadius: 5 }}
                            />
                          </div>
                          <div className="row col-lg-12 p-0 mb-2">
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_allocated).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Allocated
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.sc_quantity_delivered).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Delivered
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(this._toBeDelivered(e))}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Remaining
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <label className="form_label mb-2 mt-2">
                  Select Sales Type
                </label>
                <div className="pl-0">
                  {this.state.salesTypes.map((e, index) => (
                    <div key={index}>
                      {e.id === this.state.salesQualitySelection && (
                        <table style={{ width: "100%" }}>
                          <thead className="thead-light">
                            <tr>
                              <th></th>
                              <th className="drawer_table_header_label">
                                Mine Name
                              </th>
                              <th className="drawer_table_header_label">
                                Purchase Type
                              </th>
                              <th className="drawer_table_header_label">
                                Anchorage Name
                              </th>
                              <th className="drawer_table_header_label">
                                Coal Price
                              </th>
                            </tr>
                          </thead>
                          {e.sales_types.map((s, idx) => (
                            <tbody key={idx}>
                              <tr
                                style={{
                                  border:
                                    s.id === this.state.salesTypeSelection
                                      ? "1px solid #ec7833"
                                      : "1px solid #dee2e6",
                                }}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <NewRadio
                                    checked={
                                      s.id === this.state.salesTypeSelection
                                    }
                                    onChange={this.handleSalesTypesRadioButtons}
                                    value={s.id}
                                    name="salesTypeSelection"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                </td>
                                <td className="drawer_table_value">
                                  {s.mine_name}
                                </td>
                                <td className="drawer_table_value">
                                  {s.sales_type}
                                </td>
                                <td className="drawer_table_value">
                                  {s.sales_type === "FAS Anchorage"
                                    ? s.anchorage_name
                                    : ""}
                                </td>
                                <td className="drawer_table_value">
                                  {s.price_pmt} {this.state.purchaseCurrency}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  onClick={() =>
                    this.salesContractModalClose(this.state.modalID)
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  onClick={() => this.salesTypeSelected(this.state.modalID)}
                >
                  Select
                </button>
                &emsp;
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="updateSalesContractModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content allocation_modal">
              <div className="modal-body">
                <label className="form_label mb-2 mt-2">
                  Select Sales Quality
                </label>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="row col-lg-12 pl-0">
                  {this.state.updateSalesTypes.map((e, index) => (
                    <div className="col col-lg-6 p-1 mb-2" key={index}>
                      <div
                        className="row col-lg-12 rounded p-0"
                        style={{
                          border:
                            e.id === this.state.updateSalesQualitySelectionID
                              ? "1px solid #ec7833"
                              : "1px solid #dee2e6",
                        }}
                      >
                        <div className="col-lg-1 p-0">
                          <NewRadio
                            checked={
                              e.id === this.state.updateSalesQualitySelectionID
                            }
                            onChange={(event) =>
                              this.handleUpdateSalesTypesRadioButtons(
                                e,
                                "updateSalesQualitySelectionID"
                              )
                            }
                            value={e.id}
                            name="updateSalesQualitySelectionID"
                            inputProps={{ "aria-label": "B" }}
                            className="collapsed"
                            data-toggle="collapse"
                            data-target={"#demo" + e.id}
                            aria-expanded="false"
                            aria-controls={"group-of-rows-1"}
                          />
                        </div>
                        <div className="col-lg-11 pr-0">
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quality
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.quality}
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_label mb-0">
                                Tolerence
                              </label>
                              <label className="drawer_section_value mb-0">
                                {e.tolerance_adjusted + "%"}
                              </label>
                            </div>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="drawer_section_label mb-0">
                                Quantity
                              </label>
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="drawer_section_total mb-0">
                                Total
                              </label>
                              <label className="drawer_section_total_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_adjusted).toFixed(3)
                                )}
                              </label>
                            </div>
                          </div>
                          <div style={{ width: "98%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={this.progressBarSize(e, "NSC")}
                              style={{ borderRadius: 5 }}
                            />
                          </div>
                          <div className="row col-lg-12 p-0 mb-2">
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.quantity_allocated).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Allocated
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(
                                  Number(e.sc_quantity_delivered).toFixed(3)
                                )}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Delivered
                              </label>
                            </div>
                            <div className="col-lg-4 pl-0">
                              <label className="drawer_section_value mb-0">
                                {this.toLocaleString(this._toBeDelivered(e))}
                              </label>
                              <label className="drawer_section_label mb-0">
                                Remaining
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.allocationSalesErrorMessage !== "" &&
                        e.id === this.state.allocationSalesErrorId && (
                          <span
                            className="please-enter-the-increase-quantity-number"
                            style={{ color: "red" }}
                          >
                            {this.state.allocationSalesErrorMessage}
                          </span>
                        )}
                    </div>
                  ))}
                </div>
                <label className="form_label mb-2 mt-2">
                  Select Sales Type
                </label>
                <div className="pl-0">
                  {this.state.updateSalesTypes.map((e, index) => (
                    <div key={index}>
                      {e.id === this.state.updateSalesQualitySelectionID && (
                        <table style={{ width: "100%" }}>
                          <thead className="thead-light">
                            <tr>
                              <th></th>
                              <th className="drawer_table_header_label">
                                Mine Name
                              </th>
                              <th className="drawer_table_header_label">
                                Purchase Type
                              </th>
                              <th className="drawer_table_header_label">
                                Anchorage Name
                              </th>
                              <th className="drawer_table_header_label">
                                Coal Price
                              </th>
                            </tr>
                          </thead>
                          {e.sales_types.map((s, idx) => (
                            <tbody key={idx}>
                              <tr
                                style={{
                                  border:
                                    s.id ===
                                    this.state.updateSalesTypeSelectionID
                                      ? "1px solid #ec7833"
                                      : "1px solid #dee2e6",
                                }}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <NewRadio
                                    checked={
                                      s.id ===
                                      this.state.updateSalesTypeSelectionID
                                    }
                                    onChange={(event) =>
                                      this.handleUpdateSalesTypesRadioButtons(
                                        s,
                                        "updateSalesTypeSelectionID"
                                      )
                                    }
                                    value={s.id}
                                    name="updateSalesTypeSelectionID"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                </td>
                                <td className="drawer_table_value">
                                  {s.mine_name}
                                </td>
                                <td className="drawer_table_value">
                                  {s.sales_type}
                                </td>
                                <td className="drawer_table_value">
                                  {s.sales_type === "FAS Anchorage"
                                    ? s.anchorage_name
                                    : ""}
                                </td>
                                <td className="drawer_table_value">
                                  {s.price_pmt} {this.state.purchaseCurrency}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  onClick={() =>
                    this.updateSalesContractModalClose(this.state.modalID)
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  disabled={this.state.updateEnabled}
                  onClick={() =>
                    this.updateSalesTypeSelected(this.state.modalID)
                  }
                >
                  Update
                  {this.state.updateButtonEnabled && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "green",
                        position: "absolute",
                        bottom: "22px",
                        left: "20%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <Popover
          open={this.state.viewMenuOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetorigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.viewMenuClose}
        >
          <div className="card border">
            {(this.state.features.includes("56") ||
              this.state.features.includes("97")) && (
              <MenuItem
                className="border-bottom"
                style={{ color: config.themeColor }}
                onClick={() => {
                  this.setState({
                    comboID:
                      businessNo +
                      "$#" +
                      this.state.business_allocation_id +
                      "$#" +
                      this.state.forViewbargeID,
                    salesQualityResultsView: true,
                    openDrawer: true,
                    salesQualityResultsAdd: false,
                    salesQualityResultsUpdate: false,
                  });
                }}
              >
                Sales Quality Results
              </MenuItem>
            )}
          </div>
        </Popover>

        <Popover
          open={this.state.BusinessNoStatusOpenMenu}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetorigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.BusinessNoStatusMenuClose}
        >
          <div className="card border" style={{ width: "200px" }}>
            {this.state.features.includes("40") && (
              <MenuItem
                className=""
                style={{
                  fontSize: "17px",
                  color:
                    config.company === "SRPL" ||
                    config.company === "AVS" ||
                    config.company === "PRPL"
                      ? "#918176"
                      : "rgb(169, 126, 103)",
                }}
                onClick={this.BusinessNoCloseHandler}
              >
                Close
              </MenuItem>
            )}
            {this.state.features.includes("115") && (
              <MenuItem
                className=""
                style={{
                  fontSize: "17px",
                  color:
                    config.company === "SRPL" ||
                    config.company === "AVS" ||
                    config.company === "PRPL"
                      ? "#918176"
                      : "rgb(169, 126, 103)",
                }}
                onClick={this.BusinessNoCancelHandler}
              >
                Cancel
              </MenuItem>
            )}
          </div>
        </Popover>

        {/**
         *
         *  Mother Vessel Drawer
         *
         */}

        <Drawer
          anchor="right"
          open={this.state.openDrawer}
          variant="temporary"
          elevation={20}
        >
          <div style={{ width: 850 }}>{drawerComponent}</div>
        </Drawer>
      </div>
    );
  }
}
