import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import MvSupplierDemurrageAdd from "./Mv_supplier_DemurrageAdd";
import MvSupplierDemurrageView from "./Mv_supplier_DemurrageView";
import MvSupplierDemurrageUpdate from "./Mv_supplier_DemurrageUpdate";
import Loader from "../../common/Loader";

export default class MvSupplierDemurrageList extends Component {
  constructor(props) {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 1,

      supplierData: [
        {
          supplier: "0",
          demurrage_per_day: "",
          billing_currency: "0",
          despatch_per_day: "",
          despatch_currency: "0",
          demurrage_or_despatch: "Demurrage",
          demurrage_in_days: "",
          demurrage_incurred: "",
          despatch_in_days: "",
          despatch_incurred: "",
        },
      ],

      supplier_menu: [],
      isLoading: true,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("50") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;

    await api
      .get_mv_supplier_demurrage(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_supplier_demurrage) {
                for (var i in res.mv_supplier_demurrage) {
                  var total_paid_amout = 0;
                  for (var j of res.mv_supplier_demurrage[i]
                    .supplier_demurrage_payments) {
                    total_paid_amout += Number(j.amount);
                  }
                  res.mv_supplier_demurrage[i].total_paid_amout =
                    total_paid_amout;
                  res.mv_supplier_demurrage[i].remaining_amount =
                    Number(
                      res.mv_supplier_demurrage[i].supplier_total
                        ? res.mv_supplier_demurrage[i].supplier_total
                        : 0
                    ) - Number(total_paid_amout);
                }

                this.setState({
                  supplierData: res.mv_supplier_demurrage,
                  isLoading: false,
                });
                if (res.mv_supplier_demurrage.length === 0) {
                  this.setState({
                    errorMsg: "No Supplier Demurrage Data Available.",
                  });
                  window.$("#AlertMessageModal").modal({
                    backdrop: "static",
                    keyboard: true,
                    show: true,
                  });
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MvSupplierDemurrageAdd: false,
          MvSupplierDemurrageView: false,
          MvSupplierDemurrageUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MvSupplierDemurrageAdd: false,
        MvSupplierDemurrageView:
          value.screen === "MvSupplierDemurrage" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        MvSupplierDemurrageUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MvSupplierDemurrageAdd: false,
        MvSupplierDemurrageView: false,
        MvSupplierDemurrageUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MvSupplierDemurrageAdd: false,
          MvSupplierDemurrageView:
            value.code === "200" && value.screen === "MvSupplierDemurrage"
              ? true
              : false,
          MvSupplierDemurrageUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvSupplierDemurrageUpdate: false,
        MvSupplierDemurrageAdd: false,
        MvSupplierDemurrageView:
          childData === "MvSupplierDemurrage" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvSupplierDemurrageUpdate:
          childData === "MvSupplierDemurrage" ? true : false,
        MvSupplierDemurrageAdd: false,
        MvSupplierDemurrageView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.supplier_demurrage_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Update
    api.update_mv_pur_fin_supplier_demurrage(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MvSupplierDemurrageAdd) {
      drawerComponent = (
        <MvSupplierDemurrageAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MvSupplierDemurrageView && this.state.businessNo) {
      drawerComponent = (
        <MvSupplierDemurrageView
          MvSupplierDemurrageID={this.state.MvSupplierDemurrageID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MvSupplierDemurrageUpdate: true,
              MvSupplierDemurrageView: false,
              MvSupplierDemurrageAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MvSupplierDemurrageUpdate && this.state.businessNo) {
      drawerComponent = (
        <MvSupplierDemurrageUpdate
          MvSupplierDemurrageID={this.state.MvSupplierDemurrageID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                {`Supplier Demurrage - ${
                  this.props.mother_vessel_name
                    ? this.props.mother_vessel_name
                    : ""
                } ${this.props.name ? `( ${this.props.name} )` : ""}`}
              </h4>
              {(this.state.features.includes("49") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MvSupplierDemurrageAdd: true,
                      MvSupplierDemurrageView: false,
                      MvSupplierDemurrageUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Costing ID</th>
                          <th nowrap="true">Supplier</th>
                          <th nowrap="true" className="text-right">
                            Supplier Demurrage Per Day
                          </th>
                          <th nowrap="true" className="text-right">
                            Despatch
                          </th>
                          <th nowrap="true">Type</th>
                          <th nowrap="true" className="text-right">
                            Demurrage/Despatch Incurred
                          </th>
                          <th nowrap="true" className="text-right">
                            Cost to Company
                          </th>
                          <th nowrap="true" className="text-left pl-4">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {
                        (this.props.demurrageType === "Despatch" &&
                          this.state.supplierData.length) > 0 && (
                          <tbody>
                            {this.state.supplierData
                              .filter(
                                (e) => e.demurrage_or_despatch === "Despatch"
                              )
                              .map((idr, indx) => (
                                <tr key={indx}>
                                  {this.state.features.includes("50") ||
                                  this.state.features.includes("97") ? (
                                    <td>
                                      <i
                                        aria-hidden="true"
                                        style={{
                                          fontStyle: "normal",
                                          color: config.themeColor,
                                          cursor: "pointer",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            openDrawer: true,
                                            MvSupplierDemurrageAdd: false,
                                            MvSupplierDemurrageView: true,
                                            MvSupplierDemurrageUpdate: false,
                                            businessNo: idr.business_no_id,
                                            MvSupplierDemurrageID: idr.id,
                                          })
                                        }
                                      >
                                        {idr.costing_no}
                                      </i>
                                    </td>
                                  ) : (
                                    <td nowrap="true">{idr.costing_no}</td>
                                  )}
                                  <td nowrap="true">{idr.supplier_name}</td>
                                  <td className="text-right" nowrap="true">
                                    {idr.demurrage_per_day
                                      ? this.toLocaleString(
                                          idr.demurrage_per_day
                                        )
                                      : 0}{" "}
                                    {idr.billing_currency}
                                  </td>
                                  <td className="text-right" nowrap="true">
                                    {idr.despatch_per_day
                                      ? this.toLocaleString(
                                          idr.despatch_per_day
                                        )
                                      : 0}
                                  </td>
                                  <td nowrap="true">
                                    {idr.demurrage_or_despatch}
                                  </td>
                                  {idr.demurrage_or_despatch === "Demurrage" ? (
                                    <td className="text-right" nowrap="true">
                                      {idr.demurrage_incurred
                                        ? this.toLocaleString(
                                            idr.demurrage_incurred
                                          )
                                        : 0}
                                    </td>
                                  ) : (
                                    <td className="text-right" nowrap="true">
                                      {idr.despatch_incurred
                                        ? this.toLocaleString(
                                            idr.despatch_incurred
                                          )
                                        : 0}
                                    </td>
                                  )}
                                  <td className="text-right" nowrap="true">
                                    {idr.supplier_ctc
                                      ? this.toLocaleString(idr.supplier_ctc)
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </td>
                                  <td
                                    nowrap="true"
                                    className="table_td text-left pl-4"
                                  >
                                    {(this.state.features.includes("51") ||
                                      this.state.features.includes("97")) && (
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          fontSize: 14,
                                          color: config.themeColor,
                                          cursor: "pointer",
                                        }}
                                        data-toggle="modal"
                                        title="Edit floating crane charges"
                                        data-placement="bottom"
                                        onClick={() =>
                                          this.setState({
                                            openDrawer: true,
                                            MvSupplierDemurrageAdd: false,
                                            MvSupplierDemurrageView: false,
                                            MvSupplierDemurrageUpdate: true,
                                            businessNo: idr.business_no_id,
                                            MvSupplierDemurrageID: idr.id,
                                          })
                                        }
                                      ></i>
                                    )}
                                    {idr.payment_postings_availability === 0 &&
                                      (this.state.features.includes("388") ||
                                        this.state.features.includes("97")) && (
                                        <i
                                          className="fa fa-trash-o"
                                          style={{
                                            paddingLeft: 10,
                                            fontSize: 14,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          title="Delete floating crane charges"
                                          data-placement="bottom"
                                          data-keyboard="false"
                                          data-target="#deleteConformationModal"
                                          data-backdrop="static"
                                          onClick={() =>
                                            this.deleteCharges(idr)
                                          }
                                        ></i>
                                      )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )
                        // : (
                        //   <tbody>
                        //     <tr>
                        //       <td colSpan="7" className="p-2">
                        //         No data available
                        //       </td>
                        //     </tr>{" "}
                        //   </tbody>
                        // )
                      }
                      {
                        (this.props.demurrageType === "Demurrage" &&
                          this.state.supplierData.length) > 0 && (
                          <tbody>
                            {this.state.supplierData
                              .filter(
                                (e) => e.demurrage_or_despatch === "Demurrage"
                              )
                              .map((idr, indx) => (
                                <tr key={indx}>
                                  {this.state.features.includes("50") ||
                                  this.state.features.includes("97") ? (
                                    <td>
                                      <i
                                        aria-hidden="true"
                                        style={{
                                          fontStyle: "normal",
                                          color: config.themeColor,
                                          cursor: "pointer",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            openDrawer: true,
                                            MvSupplierDemurrageAdd: false,
                                            MvSupplierDemurrageView: true,
                                            MvSupplierDemurrageUpdate: false,
                                            businessNo: idr.business_no_id,
                                            MvSupplierDemurrageID: idr.id,
                                          })
                                        }
                                      >
                                        {idr.costing_no}
                                      </i>
                                    </td>
                                  ) : (
                                    <td nowrap="true">{idr.costing_no}</td>
                                  )}
                                  <td nowrap="true">{idr.supplier_name}</td>
                                  <td className="text-right" nowrap="true">
                                    {idr.demurrage_per_day
                                      ? this.toLocaleString(
                                          idr.demurrage_per_day
                                        )
                                      : 0}{" "}
                                    {idr.billing_currency}
                                  </td>
                                  <td className="text-right" nowrap="true">
                                    {idr.despatch_per_day
                                      ? this.toLocaleString(
                                          idr.despatch_per_day
                                        )
                                      : 0}
                                  </td>
                                  <td nowrap="true">
                                    {idr.demurrage_or_despatch}
                                  </td>
                                  {idr.demurrage_or_despatch === "Demurrage" ? (
                                    <td className="text-right" nowrap="true">
                                      {idr.demurrage_incurred
                                        ? this.toLocaleString(
                                            idr.demurrage_incurred
                                          )
                                        : 0}
                                    </td>
                                  ) : (
                                    <td className="text-right" nowrap="true">
                                      {idr.despatch_incurred
                                        ? this.toLocaleString(
                                            idr.despatch_incurred
                                          )
                                        : 0}
                                    </td>
                                  )}
                                  <td className="text-right" nowrap="true">
                                    {idr.supplier_ctc
                                      ? this.toLocaleString(idr.supplier_ctc)
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </td>
                                  <td
                                    nowrap="true"
                                    className="table_td text-left pl-4"
                                  >
                                    {(this.state.features.includes("51") ||
                                      this.state.features.includes("97")) && (
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          fontSize: 14,
                                          color: config.themeColor,
                                          cursor: "pointer",
                                        }}
                                        data-toggle="modal"
                                        title="Edit floating crane charges"
                                        data-placement="bottom"
                                        onClick={() =>
                                          this.setState({
                                            openDrawer: true,
                                            MvSupplierDemurrageAdd: false,
                                            MvSupplierDemurrageView: false,
                                            MvSupplierDemurrageUpdate: true,
                                            businessNo: idr.business_no_id,
                                            MvSupplierDemurrageID: idr.id,
                                          })
                                        }
                                      ></i>
                                    )}
                                    {idr.payment_postings_availability === 0 &&
                                      (this.state.features.includes("388") ||
                                        this.state.features.includes("97")) && (
                                        <i
                                          className="fa fa-trash-o"
                                          style={{
                                            paddingLeft: 10,
                                            fontSize: 14,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          title="Delete floating crane charges"
                                          data-placement="bottom"
                                          data-keyboard="false"
                                          data-target="#deleteConformationModal"
                                          data-backdrop="static"
                                          onClick={() =>
                                            this.deleteCharges(idr)
                                          }
                                        ></i>
                                      )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )
                        // : (
                        //   <tbody>
                        //     <tr>
                        //       <td colSpan="7" className="p-2">
                        //         No data available
                        //       </td>
                        //     </tr>{" "}
                        //   </tbody>
                        // )
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
