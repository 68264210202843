import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField, Tooltip } from "@material-ui/core";
import { pad, toLocaleString } from "../../common/common";
import InvoiceForm from "../../common/InvoiceForm";

import ViewFiles from "../../common/ViewFiles";
import PaymentPostings from "../../common/PaymentPostings";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
// import AdvanceAdjustmentsView from "../../contracts/AdvanceAdjustmentsView";
import AdvanceAdjustments from "../../contracts/AdvanceAdjustments";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import AuditInfo from "../../common/AuditInfo";

export default class BargeCostingView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      pph15_account: null,
      barge_base_price: "",
      barge_vat_percent: "10",
      barge_vat_value: "",
      barge_pph15_percent: "1.2",
      barge_pph15_value: "",
      barge_vendor_total_value: "",
      barge_ctc: "",

      sdf_base_price: "",
      sdf_vat_percent: "10",
      sdf_vat_value: "",
      sdf_pph15_percent: "1.2",
      sdf_pph15_value: "",
      sdf_vendor_total_value: "",
      sdf_ctc: "",

      idf_base_price: "",
      idf_vat_percent: "10",
      idf_vat_value: "",
      idf_pph15_percent: "1.2",
      idf_pph15_value: "",
      idf_vendor_total_value: "",
      idf_ctc: "",
      barge_quantity: "",

      isLoading: true,
      approval_status: "Pending",

      total_barge_quantity: null,
      activeStep: 0,
      bargeCostingActualPayments: [],
      bargeCostingSupplierPayments: [],
      barge_group_id: [],
      features: [],
      addDocuments: false,
      invoiceDialog: false,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
        invoice_percentage: "",
      },
      billing_currency:
        config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS"
          ? "USD"
          : "IDR",
      paymentMode: "Add",
      bargeList: [],
      advance_adjustments: [
        {
          advance_adjustment: "No",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          purchase_contract_id: "",
          pc_adv_remaining_amount: "",
          vendor_contract_no: "",
        },
      ],
      editExchg: false,
      openAdvAdjustments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("135") ||
          this.state.features.includes("390") ||
          this.state.features.includes("441") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // get Costed barges
    const barge_vendor = "",
      barge_name = "",
      jetty_name = "",
      business_no = [];
    await api
      .get_barges_for_pur_fin_barging(
        loginUserID,
        idToken,
        business_no,
        barge_vendor,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({
                    new_barges: result[Object.keys(result)[l]],
                  });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barge_costing_list: purchase_contracts_barges,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const barge_pur_fin_id = this.props.bargeCostingID;
    // barge_costing_payments
    await api
      .get_barge_purchase_financial_barge_payments(
        loginUserID,
        idToken,
        barge_pur_fin_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.payment_posting) {
                const filterBargeCostingActualPayments =
                  res.payment_posting.filter(
                    (e) => e.payment_posting_type === "payable"
                  );
                var totalActualPayments = 0;
                for (var j of filterBargeCostingActualPayments) {
                  if (j.date_of_payment) {
                    totalActualPayments += Number(j.amount);
                  }
                }

                const filterBargeCostingSupplierPayments =
                  res.payment_posting.filter(
                    (e) => e.payment_posting_type === "receivable"
                  );
                var totalSupplierPayments = 0;
                for (var k of filterBargeCostingSupplierPayments) {
                  if (k.date_of_payment) {
                    totalSupplierPayments += Number(k.amount);
                  }
                }

                this.setState({
                  openAdvAdjustments: false,
                  //Barge costing Actual
                  bargeCostingActualPayments: filterBargeCostingActualPayments,
                  totalActualPayments: totalActualPayments,
                  paid: totalActualPayments,
                  // Barge Costing Supplier
                  bargeCostingSupplierPayments:
                    filterBargeCostingSupplierPayments,
                  totalSupplierPayments: totalSupplierPayments,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api
      .get_barge_pur_fin_barging(loginUserID, idToken, barge_pur_fin_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_purchase_financial_barge_costing) {
                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_date:
                    res.barge_purchase_financial_barge_costing[0].invoice_date,
                  ppn: res.barge_purchase_financial_barge_costing[0].ppn,
                  ppn_date:
                    res.barge_purchase_financial_barge_costing[0].ppn_date,
                  invoice_no:
                    res.barge_purchase_financial_barge_costing[0].invoice_no,
                  invoice_percentage:
                    res.barge_purchase_financial_barge_costing[0]
                      .invoice_percentage,
                };
                var totalAdjustments = 0;
                for (var i of res.barge_purchase_financial_barge_costing[0]
                  .advance_adjustments) {
                  totalAdjustments += Number(i.advance_adjustment_value);
                }

                const {
                  barge_vendor_total_value = 0,
                  idf_vendor_total_value = 0,
                } = res.barge_purchase_financial_barge_costing[0] || {};

                const total_payable =
                  parseFloat(
                    barge_vendor_total_value ? barge_vendor_total_value : 0
                  ) +
                  parseFloat(
                    idf_vendor_total_value ? idf_vendor_total_value : 0
                  ) -
                  parseFloat(totalAdjustments ? totalAdjustments : 0);

                this.setState(
                  (prevState) => ({
                    ...prevState,
                    ...res.barge_purchase_financial_barge_costing[0],
                    invoiceDetails: invoice_details,
                    total_payable_to_vendor: total_payable,
                    remaining_amount:
                      parseFloat(total_payable) -
                      parseFloat(this.state.paid ? this.state.paid : 0),

                    bargeList: res.barge_purchase_financial_barge_costing[0]
                      .barge_id
                      ? res.barge_purchase_financial_barge_costing[0].barge_id.split(
                          ","
                        )
                      : [],
                    totalAdjustments: totalAdjustments,
                  }),
                  async () => {
                    if (this.state.bargeList.length > 0) {
                      const barge_id = this.state.bargeList[0];

                      let request = {
                        login_user_id: this.Cookie.getCookie("loginUserId"),
                        idtoken: this.Cookie.getIdTokenCookie(),
                        barge_id: barge_id,
                        vendor_type: "Barge",
                      };

                      await api
                        .get_business_no_info_for_barge(request)
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((res) => {
                              if (res.code === "200") {
                                this.setState({
                                  vendor_name: res.vendor_name,
                                  mv_name: res.mv_name,
                                });
                              }
                            });
                          }
                        });

                      await api
                        .get_barge(loginUserID, idToken, barge_id)
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((barge_res) => {
                              if (barge_res.code === "200") {
                                if (barge_res.barges) {
                                  let barging_base_price = 0;
                                  let barging_base_price_currency = null;
                                  if (
                                    barge_res.barges.barging_type === "Per MT"
                                  ) {
                                    barging_base_price =
                                      barge_res.barges.barging_base_price;
                                    barging_base_price_currency =
                                      barge_res.barges
                                        .barging_base_price_currency;
                                  } else if (
                                    barge_res.barges.barging_type === "Lumpsum"
                                  ) {
                                    barging_base_price =
                                      barge_res.barges.lumpsum_price;
                                    barging_base_price_currency =
                                      barge_res.barges.lumpsum_price_currency;
                                  }
                                  this.setState({
                                    barging_base_price: barging_base_price,
                                    bargeBasePrice: barging_base_price,
                                    barging_type: barge_res.barges.barging_type,
                                    barging_base_price_currency:
                                      barging_base_price_currency,
                                    vat_applicability:
                                      barge_res.barges.vat_applicability,
                                    pph15_account:
                                      config.company !== "SRPL" &&
                                      config.company !== "PRPL"
                                        ? barge_res.barges.vat_applicability ===
                                          "Exclusive"
                                          ? "SDAM"
                                          : "Supplier"
                                        : null,
                                    isLoading: false,
                                  });
                                }
                              } else if (barge_res.code === "601") {
                                this.setState({
                                  snackBarErrorOpen: true,
                                  errorMsg: res.message,
                                  isLoading: false,
                                });
                              } else if (barge_res.code === "607") {
                                window.location.href = "/logout";
                              }
                            });
                          }
                        });
                    } else {
                      this.setState({ isLoading: false });
                    }
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_purchase_financial_barge_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  bargingCalculation = () => {
    const {
      vat_applicability = "Inclusive",
      barging_type = "Lumpsum",
      barge_quantity = 1,
      bargeBasePrice = 1,
      currency_xchg_rate = 1,
      supplierDeadFreightValue = 1,
      DeadFreightValue = 1,
      barging_base_price_currency = "USD",
    } = this.state;

    const { company } = config;
    const billing_currency = barging_base_price_currency;
    const exchange_rate = currency_xchg_rate
      ? parseFloat(currency_xchg_rate)
      : 1;

    let qty = parseFloat(barge_quantity).toFixed(2);
    let price = parseFloat(bargeBasePrice).toFixed(2);
    let deadFreightValue = DeadFreightValue
      ? parseFloat(DeadFreightValue) - qty
      : 0;
    if (deadFreightValue <= 0) {
      deadFreightValue = 0;
    }
    let supDeadFreightValue = supplierDeadFreightValue;

    if (
      (company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS") &&
      billing_currency !== "USD"
    ) {
      price = (parseFloat(price) / parseFloat(exchange_rate)).toFixed(2);
      deadFreightValue = (
        parseFloat(DeadFreightValue) / parseFloat(exchange_rate)
      ).toFixed(2);
      supDeadFreightValue = (
        parseFloat(supplierDeadFreightValue) / parseFloat(exchange_rate)
      ).toFixed(2);
    }
    if (
      (company !== "SRPL" || company !== "PRPL") &&
      billing_currency !== "IDR"
    ) {
      price = (parseFloat(price) * parseFloat(exchange_rate)).toFixed(2);
      deadFreightValue = (
        parseFloat(DeadFreightValue) * parseFloat(exchange_rate)
      ).toFixed(2);
      supDeadFreightValue = (
        parseFloat(supplierDeadFreightValue) * parseFloat(exchange_rate)
      ).toFixed(2);
    }
    let total_price = (parseFloat(qty) * parseFloat(price)).toFixed(2);
    let barge_base_price_in_local_currency = price;

    if (barging_type === "Lumpsum") {
      total_price = price;
      barge_base_price_in_local_currency = price;
    }

    let barge_base_price = total_price;
    let barge_vat_percent = 10;
    let barge_vat_value = 0;
    let barge_pph15_percent = 1.2;
    let barge_pph15_value = 0;
    let barge_vendor_total_value = 0;
    let barge_ctc = 0;

    let idf_base_price = deadFreightValue;
    let idf_vat_percent = 10;
    let idf_vat_value = 0;
    let idf_pph15_percent = 1.2;
    let idf_pph15_value = 0;
    let idf_vendor_total_value = 0;
    let idf_ctc = 0;

    let sdf_base_price = supDeadFreightValue;
    let sdf_vat_percent = 10;
    let sdf_vat_value = 0;
    let sdf_pph15_percent = 1.2;
    let sdf_pph15_value = 0;
    let sdf_vendor_total_value = 0;
    let sdf_ctc = 0;

    if (vat_applicability === "Inclusive") {
      barge_base_price = parseFloat((total_price * 100) / 110).toFixed(2);
      barge_vat_value = (
        (Number(barge_base_price) * barge_vat_percent) /
        100
      ).toFixed(2);
      barge_pph15_value = Number(
        (barge_base_price * barge_pph15_percent) / 100
      ).toFixed(2);
      if (company === "SRPL" || company === "PRPL") {
        barge_pph15_value = 0;
      }
      barge_vendor_total_value = (
        parseFloat(barge_base_price) +
        parseFloat(barge_vat_value) -
        parseFloat(barge_pph15_value)
      ).toFixed(2);
      barge_ctc = (
        parseFloat(barge_base_price) + parseFloat(barge_vat_value)
      ).toFixed(2);

      // Dead Freight
      idf_base_price = parseFloat((idf_base_price * 100) / 110).toFixed(2);
      idf_vat_value = (
        (Number(idf_base_price) * idf_vat_percent) /
        100
      ).toFixed(2);
      idf_pph15_value = Number((idf_base_price * idf_base_price) / 100).toFixed(
        2
      );
      if (company === "SRPL" || company === "PRPL") {
        idf_pph15_value = 0;
      }
      idf_vendor_total_value = (
        parseFloat(idf_base_price) +
        parseFloat(idf_vat_value) -
        parseFloat(idf_pph15_value)
      ).toFixed(2);
      idf_ctc = (
        parseFloat(idf_base_price) + parseFloat(idf_vat_value)
      ).toFixed(2);

      // Supplier

      sdf_base_price = parseFloat((sdf_base_price * 100) / 110).toFixed(2);
      sdf_vat_value = (
        (Number(sdf_base_price) * sdf_vat_percent) /
        100
      ).toFixed(2);
      sdf_pph15_value = Number((sdf_base_price * sdf_base_price) / 100).toFixed(
        2
      );
      if (company === "SRPL" || company === "PRPL") {
        sdf_pph15_value = 0;
      }
      sdf_vendor_total_value = (
        parseFloat(sdf_base_price) +
        parseFloat(sdf_vat_value) -
        parseFloat(sdf_pph15_value)
      ).toFixed(2);
      sdf_ctc = (
        parseFloat(sdf_base_price) + parseFloat(sdf_vat_value)
      ).toFixed(2);
    } else if (vat_applicability === "Exclusive") {
      barge_base_price = parseFloat((total_price * 100) / 98.8).toFixed(2);
      if (company === "SRPL" || company === "PRPL") {
        barge_base_price = total_price;
      }
      barge_vat_percent = 0;
      barge_vat_value = 0;
      barge_pph15_value = Number(
        (barge_base_price * barge_pph15_percent) / 100
      ).toFixed(2);
      if (company === "SRPL" || company === "PRPL") {
        barge_pph15_value = 0;
      }
      barge_vendor_total_value = (
        parseFloat(barge_base_price) +
        parseFloat(barge_vat_value) -
        parseFloat(barge_pph15_value)
      ).toFixed(2);
      barge_ctc = (
        parseFloat(barge_base_price) + parseFloat(barge_vat_value)
      ).toFixed(2);

      // Dead Freight
      idf_base_price = parseFloat((idf_base_price * 100) / 110).toFixed(2);
      idf_vat_percent = 0;
      idf_vat_value = 0;
      idf_pph15_value = Number((idf_base_price * idf_base_price) / 100).toFixed(
        2
      );
      if (company === "SRPL" || company === "PRPL") {
        idf_pph15_value = 0;
      }
      idf_vendor_total_value = (
        parseFloat(idf_base_price) +
        parseFloat(idf_vat_value) -
        parseFloat(idf_pph15_value)
      ).toFixed(2);
      idf_ctc = (
        parseFloat(idf_base_price) + parseFloat(idf_vat_value)
      ).toFixed(2);

      // Supplier

      sdf_base_price = parseFloat((sdf_base_price * 100) / 110).toFixed(2);
      sdf_vat_percent = 0;
      sdf_vat_value = 0;
      sdf_pph15_value = Number((sdf_base_price * sdf_base_price) / 100).toFixed(
        2
      );
      if (company === "SRPL" || company === "PRPL") {
        sdf_pph15_value = 0;
      }
      sdf_vendor_total_value = (
        parseFloat(sdf_base_price) +
        parseFloat(sdf_vat_value) -
        parseFloat(sdf_pph15_value)
      ).toFixed(2);
      sdf_ctc = (
        parseFloat(sdf_base_price) + parseFloat(sdf_vat_value)
      ).toFixed(2);
    }

    this.setState({
      barge_base_price_in_local_currency: barge_base_price_in_local_currency,

      // barging
      barge_base_price: barge_base_price,
      barge_vat_percent: barge_vat_percent,
      barge_vat_value: barge_vat_value,
      barge_pph15_percent: barge_pph15_percent,
      barge_pph15_value: barge_pph15_value,
      barge_vendor_total_value: barge_vendor_total_value,
      barge_ctc: barge_ctc,

      //supplier

      sdf_base_price: sdf_base_price ? sdf_base_price : "",
      sdf_vat_percent: sdf_vat_percent,
      sdf_vat_value: sdf_vat_value,
      sdf_pph15_percent: sdf_pph15_percent,
      sdf_pph15_value: sdf_pph15_value,
      sdf_vendor_total_value: sdf_vendor_total_value,
      sdf_ctc: sdf_ctc ? sdf_ctc : "",

      // Internal Dead freight state values
      idf_base_price: idf_base_price,
      idf_vat_percent: idf_vat_percent,
      idf_vat_value: idf_vat_value,
      idf_pph15_percent: idf_pph15_percent,
      idf_pph15_value: idf_pph15_value,
      idf_vendor_total_value: idf_vendor_total_value,
      idf_ctc: idf_ctc,
    });
  };

  renderPaymentPostings = (data, type) => {
    const {
      bargeCostingActualPayments = [],
      bargeCostingSupplierPayments = [],
      remaining_amount = 10000,
      currency_xchg_rate = 1,
      billing_currency = config.company === "SRPL" ||
      config.company === "PRPL" ||
      config.company === "AVS"
        ? "USD"
        : "IDR",
      approval_status = "Pending",
    } = data;
    const { invoiceDetails: { invoice_no = null } = {} } = data;
    let payment_related_data = {
      paymentTitle: type,
      payment_posting_type:
        type === "Actual Barging" ? "payable" : "receivable",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
        ? "USD"
        : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: this.props.bargeCostingID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "138",
      view_payment: "139",
      update_payment: "140",
      delete_payment: "141",
      approver: "390",
      approver2: "441",
    };

    return (
      <PaymentPostings
        payments={
          type === "Actual Barging"
            ? bargeCostingActualPayments
            : bargeCostingSupplierPayments
        }
        costingType="Barge Costing"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  saveHandler = () => {
    const {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      barge_quantity,
      pph15_account,
      barge_base_price,
      barge_vat_percent,
      barge_vat_value,
      barge_pph15_percent,
      barge_pph15_value,
      barge_vendor_total_value,
      barge_ctc,
      sdf_base_price,
      sdf_vat_percent,
      sdf_vat_value,
      sdf_vendor_total_value,
      sdf_pph15_percent,
      sdf_ctc,
      idf_base_price,
      idf_vat_percent,
      idf_vat_value,
      idf_pph15_percent,
      idf_pph15_value,
      idf_vendor_total_value,
      idf_ctc,
      billing_currency,
      advance_adjustments,
      files,
      currency_xchg_rate,
      barge_base_price_in_local_currency,
    } = this.state;

    let existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    // Add barge purchase financial api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_id = this.state.bargeList.toString();
    const costing_files = [];

    const barge_pur_fin_id = this.props.bargeCostingID;
    if (this.state.bargeList.length > 0) {
      api
        .update_barge_pur_fin_barging(
          loginUserID,
          idToken,
          barge_id,
          barge_pur_fin_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          barge_quantity,
          pph15_account,
          barge_base_price,
          barge_vat_percent,
          barge_vat_value,
          barge_pph15_percent,
          barge_pph15_value,
          barge_vendor_total_value,
          billing_currency,
          currency_xchg_rate,
          barge_base_price_in_local_currency,
          barge_ctc,
          sdf_base_price,
          sdf_vat_percent,
          sdf_vat_value,
          sdf_vendor_total_value,
          sdf_pph15_percent,
          sdf_ctc,
          idf_base_price,
          idf_vat_percent,
          idf_vat_value,
          idf_pph15_percent,
          idf_pph15_value,
          idf_vendor_total_value,
          idf_ctc,
          advance_adjustments,
          existing_file_ids,
          content_files,
          costing_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              //this.props.onUpdateCosting(res.code);
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: "Costing updated successfully",
                    errorMsg: "",
                    submitLoading: false,
                  },
                  //() => this.props.onUpdateResponse(res.code)
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                  submitLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                  submitLoading: false,
                });
              }
            });
          }
        });
    } else {
      alert("Please select atleast one barge to submit");
      this.setState({
        isLoading: false,
        errorMsg: "Please select atleast one barge to submit",
        successMsg: "",
        snackBarErrorOpen: true,
        submitLoading: false,
      });
    }
  };

  renderExchgRate = () => {
    if (
      ((config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS") &&
        this.state.barging_base_price_currency !== "USD" &&
        this.state.barging_base_price_currency) ||
      ((config.company !== "SRPL" || config.company !== "PRPL") &&
        this.state.barging_base_price_currency !== "IDR" &&
        this.state.barging_base_price_currency)
    ) {
      if (this.state.editExchg) {
        return (
          <>
            <TextField
              name="currency_xchg_rate"
              margin="dense"
              variant="outlined"
              placeholder="Enter Value"
              label="Exchange Rate @"
              value={this.state.currency_xchg_rate}
              onChange={(e) => {
                const re = /^\d*(\.\d{0,10})?$/;

                if (e.target.value === "" || re.test(e.target.value)) {
                  this.setState(
                    {
                      currency_xchg_rate: e.target.value,
                    },
                    () => this.bargingCalculation()
                  );
                }
              }}
            />
            <Tooltip title="Save" arrow>
              <i
                className="fa fa-check fa-3x edit_icon"
                style={{
                  color: config.themeColor,
                  cursor: "pointer",
                  marginTop: 5,
                }}
                onClick={this.saveHandler}
              />
            </Tooltip>
            <Tooltip title="Cancel" arrow>
              <i
                className="fa fa-times fa-3x edit_icon"
                style={{
                  color: config.themeColor,
                  cursor: "pointer",
                  marginTop: 5,
                }}
                onClick={() => {
                  this.setState({ editExchg: false });
                }}
              />
            </Tooltip>
          </>
        );
      } else {
        return (
          <>
            Exchange Rate @ {toLocaleString(this.state.currency_xchg_rate)}{" "}
            <i
              className="fa fa-pencil edit_icon"
              style={{
                color: config.themeColor,
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState((prevState) => ({
                  editExchg: !prevState.editExchg,
                }))
              }
            />
          </>
        );
      }
    }
  };

  renderInvoiceLink = () => {
    const data = this.state.barge_costing_list.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.props.bargeCostingID)
    );

    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      barge_quantity = 0,
      barging_base_price = 0,
      currency_xchg_rate = 1,
      advance_adjustments,
      barge_base_price = 0,
      total_payable_to_vendor = 0,
      vendor_name,
      mv_name,
    } = this.state;

    const filter_advance = advance_adjustments.filter(
      (e) => e.advance_adjustment !== "No"
    );
    const advanceAdjustments = [];
    let advance_adjustment = 0;

    for (var i of filter_advance) {
      advance_adjustment += parseFloat(i.advance_adjustment_value);
      advanceAdjustments.push({
        desc: `Adv deduction against contract: ${i.vendor_contract_no}`,
        qty: `${toLocaleString(
          i.advance_adjustment_barge_quantity
            ? i.advance_adjustment_barge_quantity
            : " "
        )}`,
        adjustedPrice: `${toLocaleString(
          i.adjustment_per_mt ? i.adjustment_per_mt : " "
        )}`,
        amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
      });
    }

    const GrandTotal = (
      parseFloat(total_payable_to_vendor ? total_payable_to_vendor : 1) -
      parseFloat(advance_adjustment)
    ).toFixed(2);

    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: "Barging",
      invoice_no: invoice_no,
      invoice_date: invoice_date,
      bargeDetails: data ? data.flat() : [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(GrandTotal),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(barge_quantity),
          adjustedPrice: toLocaleString(barging_base_price),
          desc: `${
            currency_xchg_rate > 1
              ? `Exchange rate @ ${parseFloat(currency_xchg_rate)}`
              : ""
          }`,
          amount: barge_base_price,
        },
        ...advanceAdjustments,
        ...(this.state.pph15_account !== "SDAM"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.barge_vat_value)}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${toLocaleString(this.state.barge_pph15_value)} )`,
              },
            ]
          : []),
        ...(this.state.idf_base_price > 0
          ? [
              {
                qty: `${toLocaleString(this.state.idf_base_price)}`,
                adjustedPrice: " ",
                desc: "Dead Freight",
                amount: `( ${toLocaleString(
                  this.state.idf_vendor_total_value
                )} )`,
              },
            ]
          : []),
      ],
    };

    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    try {
      var bargeCostingID = this.props.bargeCostingID;
    } catch (e) {
      window.location.href = "/barge-list";
    }

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-9 pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Barge Costing - ${this.state?.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>

              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                this.state.approved_by !==
                  this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("390") ||
                  this.state.features.includes("441") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={this.props.bargeCostingID}
                    costingType="Barge Costing"
                    accessInfo={{
                      ...this.state,
                      approver_1: "390",
                      approver_2: "441",
                    }}
                    callbackFn={() => this.componentDidMount()}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {this.state.barge_costing_list.length > 0 && (
                <div className="row mb-3">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered mb-0">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true">Barge Nomination</th>
                          <th nowrap="true">Business No</th>
                          <th nowrap="true">Barge Vendor</th>
                          <th nowrap="true">Jetty</th>
                          <th nowrap="true">Supplier</th>
                          <th nowrap="true">Grade</th>

                          <th className="text-right" nowrap="true">
                            {" "}
                            Quantity{" "}
                          </th>
                          <th className="text-right" nowrap="true">
                            {" "}
                            Price PMT{" "}
                          </th>
                          <th className="text-right" nowrap="true">
                            {" "}
                            Total Payable{" "}
                          </th>
                          <th className="text-right " nowrap="true">
                            {" "}
                            Paid{" "}
                          </th>
                          <th className="text-right " nowrap="true">
                            {" "}
                            Remaining{" "}
                          </th>
                        </tr>
                      </thead>
                      {this.state.barge_costing_list.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display:
                                  bargeCostingID === idr.id ? "" : "none",
                              }}
                            >
                              <td nowrap="true">
                                <a
                                  href={"/view-barge/" + btoa(idr.barge_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {pad(idr.barge_id)}
                                </a>
                              </td>
                              <td nowrap="true">{idr.barge_nomination}</td>
                              <td nowrap="true">{idr.business_no}</td>
                              <td nowrap="true">{idr.barge_vendor}</td>
                              <td nowrap="true">{idr.loading_jetty}</td>
                              <td nowrap="true">{idr.supplier_name}</td>
                              <td nowrap="true">{idr.grade}</td>
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.barge_quantity)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.price_per_mt)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.total_payable)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.paid)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.Remaining_amount)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}

              <div className="table-responsive">
                <table className="table table-sm table-bordered mb-0">
                  <thead className="table-header">
                    <tr>
                      <th className="text-center">Description</th>
                      <th className="text-right">Qty</th>
                      <th className="text-right">Unit Price</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.renderExchgRate()}</td>
                      <td className="text-right">
                        {toLocaleString(this.state.barge_quantity)}
                      </td>
                      <td className="text-right">
                        {toLocaleString(this.state.barging_base_price)}{" "}
                        {this.state.barging_base_price_currency}
                      </td>
                      <td className="text-right">
                        {" "}
                        {toLocaleString(this.state.barge_base_price)}
                      </td>
                    </tr>
                    {(this.state.vat_applicability === "Inclusive" ||
                      config.company === "SRPL" ||
                      config.company === "PRPL" ||
                      config.company === "AVS") && (
                      <tr>
                        <td>VAT @ 10</td>
                        <td className="text-right"></td>
                        <td className="text-right"></td>
                        <td className="text-right">
                          {toLocaleString(this.state.barge_vat_value)}
                        </td>
                      </tr>
                    )}
                    {!(
                      config.company === "SRPL" ||
                      config.company === "PRPL" ||
                      config.company === "AVS"
                    ) && (
                      <tr>
                        <td>PPH @1.2</td>
                        <td className="text-right "></td>
                        <td className="text-right "></td>
                        <td className="text-right ">{`( ${toLocaleString(
                          this.state.barge_pph15_value
                        )} )`}</td>
                      </tr>
                    )}
                    <tr>
                      <th colSpan={4}>
                        Advance Adjustments &nbsp;
                        <i
                          className="fa fa-plus-circle"
                          style={{
                            color: config.themeColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({ openAdvAdjustments: true });
                          }}
                        />
                      </th>
                    </tr>
                    {this.state.advance_adjustments
                      ?.filter((e) => e.advance_adjustment !== "No")
                      .map((item, idx) => (
                        <tr key={item.id}>
                          <td className="">
                            {`Adv deduction against contract: ${item.vendor_contract_no}`}{" "}
                            &nbsp;
                            <i
                              className="fa fa-pencil"
                              style={{
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState((prevState) => ({
                                  openAdvAdjustments:
                                    !prevState.openAdvAdjustments,
                                }))
                              }
                            />
                          </td>
                          <td className="text-right ">
                            {item.advance_adjustment !== "Lumpsum"
                              ? toLocaleString(
                                  item.advance_adjustment_barge_quantity
                                )
                              : ""}
                          </td>
                          <td className="text-right ">
                            {item.advance_adjustment !== "Lumpsum"
                              ? toLocaleString(item.adjustment_per_mt)
                              : ""}
                          </td>
                          <td className="text-right ">
                            {`( ${toLocaleString(
                              item.advance_adjustment_value
                            )} )`}
                          </td>
                        </tr>
                      ))}
                    {this.state.idf_base_price &&
                      this.state.idf_base_price > 0 && (
                        <tr>
                          <td className="">Dead Freight</td>
                          <td className="text-right ">{`${toLocaleString(
                            this.state.idf_base_price
                          )}`}</td>
                          <td className="text-right "></td>
                          <td className="text-right ">{`${toLocaleString(
                            this.state.idf_vendor_total_value
                          )}`}</td>
                        </tr>
                      )}
                    <tr>
                      <td
                        colSpan={3}
                        className="text-right "
                        style={{ fontWeight: "bold" }}
                      >
                        Payable to Vendor
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-right "
                      >{`${toLocaleString(
                        this.state.total_payable_to_vendor
                      )} ${
                        config.company === "SRPL" ||
                        config.company === "PRPL" ||
                        config.company === "AVS"
                          ? "USD"
                          : "IDR"
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        className="text-right "
                        style={{ fontWeight: "bold" }}
                      >
                        paid
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-right "
                      >{`${toLocaleString(this.state.paid)}`}</td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        className="text-right "
                        style={{ fontWeight: "bold" }}
                      >
                        Remaining Amount
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-right "
                      >{`${toLocaleString(this.state.remaining_amount)}`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="card p-0 mb-3 mt-3">
                <div className="card-body p-1">
                  <InvoiceForm
                    open={this.state.invoiceDialog}
                    costingID={this.props.bargeCostingID}
                    costing_type={"Barge Costing"}
                    data={this.state.invoiceDetails}
                    callbackFn={(data) => this.updateInvoice(data)}
                  />

                  {/*=============================================
                      =         Actual Barging Payment Postings       =
                      =============================================*/}
                  {this.renderPaymentPostings(this.state, "Actual Barging")}
                </div>
              </div>

              {/* <AdvanceAdjustmentsView
                    adjustments={this.state.advance_adjustments}
                    vendorType="Barge"
                  /> */}
              <AdvanceAdjustments
                open={this.state.openAdvAdjustments}
                adjustments={this.state.advance_adjustments}
                vendorType="Barge"
                table_name="barge_pur_fin_barging_adv_adjustments"
                costing_id={bargeCostingID}
                bargeID={this.state.bargeList[0]}
                callbackFn={() => this.componentDidMount()}
                closeModal={() => this.setState({ openAdvAdjustments: false })}
              />

              <div className="card p-0 border mb-3">
                <div className="card-body p-1">
                  <div className="row mb-3">
                    <div
                      className="col-lg pr-0 p-2 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-0">
                        <h6>Supplier Dead Freight Value</h6>
                      </div>

                      <div className="row pl-2">
                        <div className="col-lg-6 p-0 mb-2">
                          <label
                            className="form_label mb-2"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Base price
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.state.sdf_base_price
                              ? this.state.sdf_base_price
                              : "-"}
                          </div>
                        </div>
                        {this.state.pph15_account !== "SDAM" && (
                          <div className="col-lg-6 pr-0 mb-2">
                            <label
                              className="form_label mb-2"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              {`Includes VAT @ ${this.state.sdf_vat_percent}%`}
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.sdf_vat_value
                                ? this.state.sdf_vat_value
                                : "-"}
                            </div>
                          </div>
                        )}
                        <div className="col-lg-8 p-0 mb-3">
                          <label
                            className="form_label mb-2"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Receivable from Supplier
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {toLocaleString(this.state.sdf_ctc)}
                            {config.company === "SRPL" ||
                            config.company === "PRPL" ||
                            config.company === "AVS"
                              ? "USD"
                              : "IDR"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row col-lg-12 p-0">
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Received from Supplier
                      </label>
                      <div className="contract_display_header_value">
                        {toLocaleString(this.state.totalSupplierPayments)}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Remaining Amount
                      </label>
                      <div className="contract_display_header_value">
                        {toLocaleString(
                          Number(this.state.sdf_ctc) -
                            Number(this.state.totalSupplierPayments)
                        )}
                      </div>
                    </div>
                  </div>
                  {/*=============================================
                      =         Supplier Barging Payment Postings           =
                      =============================================*/}

                  {this.renderPaymentPostings(this.state, "Dead Freight")}
                </div>
              </div>

              <ViewFiles
                {...this.props}
                type="Costings"
                files_data={this.state.files}
                invoice_file={this.state.invoice_file}
              />

              <AuditInfo info={this.state} />
            </div>
          </div>

          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>

            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("136") ||
                this.state.features.includes("190") ||
                this.state.features.includes("97")
              : this.state.features.includes("97")) && (
              <button
                type="button"
                className="drawer_button drawer_text"
                onClick={() => this.props.onEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      );
    }
  }
}
