import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import api from "../../../api/api";
import config from "../../../config/config";
import CookieHandler from "../../common/CookieHandler";
import { Snackbar, MenuItem, Popover, Drawer } from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import { Alert } from "@material-ui/lab";

import DocumentsUpload from "../../DocumentsUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
import AdvancePaymentPostings from "../AdvancePaymentPostings";
import QualityView from "../Qualities/QualitiesView";

export default class ViewPurchaseContract extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      purchaseContractId: "",
      vendorType: "",
      purchaseTypes: [],
      contract_no: "",
      old_contract_no: "",
      purchaseType: "",
      purchasing_term: "0",
      surveyor_type: "0",
      supplier_surveyor_names: "0",
      supplier_surveyor_analysis_type: "0",
      internal_surveyor_names: "0",
      internal_surveyor_analysis_type: "0",
      joint_surveyor_names: "0",
      joint_surveyor_analysis_type: "0",
      surveyor_name: "0",
      analysis_type: "0",
      currency: "0",
      pol: "",
      vesselType: "0",
      errorMsg: "",
      successMsg: "",
      businessNoData: [],
      unassigned_business_no_data: [],
      unassigned_partial_quantity_data: [],
      adjustTolerance: "",
      activeTabIndex: false,
      ISOactiveTabIndex: false,
      specsAstmClickedRow: 0,
      specsIsoClickedRow: 0,

      isLoading: true,
      payment_date: null,
      amount: "",
      purchase_adv_contract: [],
      cps_advance_adjustments: [],
      payment_currency: "0",
      currency_xchg_rate: "",
      amount_in_payment_currency: "",
      openDocumentsDrawer: false,
      addDocuments: false,
      features: [],
      purchase_contract_invoices: [],
      files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("8") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      try {
        var purchaseContractId = decodeURIComponent(
          window.atob(this.props.match.params.PurchaseID)
        );
        this.setState({ purchaseContractId: purchaseContractId });
      } catch (e) {
        window.location.href = "/Pagenotfound";
      }

      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const status = "Active";

      // Get Advance Adjustments By based on contract
      await api
        .get_adv_adjustments_by_purchase_contract_id(
          loginUserID,
          idToken,
          purchaseContractId
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.purchase_adv_contract) {

                  const findDuplicates = res.purchase_adv_contract?.filter((el, i, a) => a.findIndex((element) => element.business_number_id === el.business_number_id) === i)
                  var sumOfAdvance_adjustment = 0;
                  for (var i of findDuplicates) {
                    sumOfAdvance_adjustment += Number(i.advance_adjustment);
                  }
                  for (var j of res.cps_advance_adjustments) {
                    sumOfAdvance_adjustment += Number(
                      j.cps_advance_adjustment_value
                    );
                  }
                  this.setState({
                    // purchase_adv_contract: res.purchase_adv_contract,
                    purchase_adv_contract: findDuplicates,
                    cps_advance_adjustments: res.cps_advance_adjustments,
                    sumOfAdvance_adjustment: sumOfAdvance_adjustment,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      const request = {
        login_user_id: loginUserID,
        idtoken: idToken,
        purchase_contract_id: purchaseContractId,
      };

      await api.get_pc_adv_invoices_by_pc(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                purchase_contract_invoices: res.purchase_contract_invoices,
              });
            }
          });
        }
      });

      await api.get_cps_contract_by_pc(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.cps_contract) {
                this.setState({
                  cps_contract: res.cps_contract,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

      // Get Business NUmber By Purchase Contract
      await api
        .getBusinessByPC(loginUserID, purchaseContractId, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                var sumOfAllocated_quantity = 0;
                var sumOfDelivered_quantity = 0;
                for (var i of res.business_no_data) {
                  sumOfAllocated_quantity += Number(i.allocated_quantity);
                  sumOfDelivered_quantity += Number(i.delivered_quantity);
                }
                for (var j of res.unassigned_business_no_data) {
                  sumOfDelivered_quantity += Number(j.barge_quantity);
                }
                for (var k of res.unassigned_partial_quantity_data) {
                  sumOfDelivered_quantity += Number(
                    k.remaining_partial_quantity
                  );
                }
                this.setState({
                  businessNoData: res.business_no_data
                    ? res.business_no_data
                    : [],
                  unassigned_business_no_data: res.unassigned_business_no_data
                    ? res.unassigned_business_no_data
                    : [],
                  unassigned_partial_quantity_data:
                    res.unassigned_partial_quantity_data
                      ? res.unassigned_partial_quantity_data
                      : [],
                  sumOfAllocated_quantity: sumOfAllocated_quantity,
                  sumOfDelivered_quantity: sumOfDelivered_quantity,
                });
              }

              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              }
              if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      // for get-purchase-contract
      await api
        .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.code === "200") {
                if (res.purchase_contract) {
                  let sum_of_qty = 0;
                  for (var i of res.purchase_contract?.qualities) {
                    sum_of_qty += Number(
                      i.quantity_in_mt ? i.quantity_in_mt : 0
                    );
                  }

                  const available_qty =
                    parseFloat(sum_of_qty) -
                    parseFloat(
                      this.state.sumOfDelivered_quantity
                        ? this.state.sumOfDelivered_quantity
                        : 0
                    );
                  this.setState((prevState) => ({
                    purchaseContractId: purchaseContractId,
                    ...prevState,
                    ...res.purchase_contract,
                    available_qty: available_qty,
                    isLoading: false,
                  }));
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              }
              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              }
              if (res.code === "607") {
                window.location.href = "/logout";
              }
              if (res.code === "629") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
                //window.location.href='/dashboard';
              }
            });
          }
        });
    }

    document.title = config.documentTitle + "View Purchase Contract";
  }

  adjustToleranceAPIHandler = (data) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const pcQualityId = data.id;
    const adjustedTolerance = this.state.adjustTolerance;
    if (adjustedTolerance === "") {
      this.setState({ adjustToleranceError: true });
      return;
    }
    if (Number(data.tolerance) > Number(adjustedTolerance)) {
      alert("Update Tolerance should be greater than Tolerance");
      this.setState({ adjustToleranceError: true });
      return;
    }
    api
      .purchaseQualityToleranceAdjusting(
        loginUserID,
        idToken,
        pcQualityId,
        adjustedTolerance
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) {
              this.Cookie.setIdTokenCookie(res.idtoken);
            }
            if (res.code === "200") {
              this.setState(
                {
                  successMsg: res.message,
                  snackBarSuccessOpen: true,
                  adjustTolerance: "",
                  openQuantityAllocation: false,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  toLocalDecimalString(value) {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  }

  AddPaymentPostingHandler = (data) => {
    let request = {
      ...data,
      purchase_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      ),
    };

    api.add_pc_adv_payments(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            window.$("#addAdvancePayment").modal("hide");
            this.componentDidMount();
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  UpdatePaymentPostingHandler = (data) => {
    let request = {
      ...data,
      purchase_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      ),
      pc_adv_payments_id: data?.id,
    };

    api.update_pc_adv_payments(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            window.$("#addAdvancePayment").modal("hide");
            this.componentDidMount();
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  delete_PC_AdvancePayment = (data) => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var status = "Deleted";
    var pc_adv_payments_id = data.id;
    try {
      var purchaseContractId = decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      );
      api
        .delete_pc_adv_payment(
          loginUserID,
          idToken,
          purchaseContractId,
          pc_adv_payments_id,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                window.$("#deleteAdvancePayment").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/purchase-contract-list";
    }
  };

  cloneHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    this.setState({ isLoading: true });
    try {
      var purchaseContractId = decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      );
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    api
      .clone_purchase_contract(loginUserID, purchaseContractId, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.purchase_contract_id) {
                this.setState({
                  clonedPC_contract_id: res.purchase_contract_id,
                  addButtonOpen: false,
                  isLoading: false,
                  successMsg: "Purchase Contract Cloned Sucessfully",
                  snackBarSuccessOpen: true,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  addButtonOpen = (event) => {
    event.preventDefault();
    this.setState({
      addButtonOpen: true,
      openDrawer: false,
      anchorEn: event.currentTarget,
    });
  };

  addButtonClose = () => {
    this.setState({
      addButtonOpen: false,
    });
  };

  deleteAdvancePayment = (data) => {
    if (data) {
      var idToken = this.Cookie.getIdTokenCookie();
      var loginUserID = this.Cookie.getCookie("loginUserId");
      let payment_date = data.payment_date;
      let amount = data.amount;
      let purchaseContractId = data.purchase_contract_id;
      let pc_adv_payments_id = data.id;
      let remarks = data.remarks ? data.remarks : "";
      let invoice = data.invoice ? data.invoice : "";
      let status = "Deleted";
      var payment_currency = this.state.payment_currency
        ? this.state.payment_currency
        : "";
      var currency_xchg_rate = this.state.currency_xchg_rate
        ? this.state.currency_xchg_rate
        : "";
      var amount_in_payment_currency = this.state.amount_in_payment_currency
        ? this.state.amount_in_payment_currency
        : "";
      if (payment_date !== null && amount !== "") {
        api
          .update_pc_adv_payments(
            loginUserID,
            idToken,
            pc_adv_payments_id,
            purchaseContractId,
            payment_date,
            amount,
            payment_currency,
            currency_xchg_rate,
            amount_in_payment_currency,
            invoice,
            remarks,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState({
                    payment_date: null,
                    amount: "",
                    invoice: "",
                    remarks: "",
                    successMsg: res.message,
                    snackBarSuccessOpen: true,
                  });
                  window.$("#deletePaymentModal").modal("hide");
                  this.componentDidMount();
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        alert("Please fill all mandatory fields.");
      }
    }
  };

  onLinkClick(link) {
    var links = link.split("://");
    if (links.length === 1) {
      return "http://" + link;
    } else {
      return link;
    }
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  };

  AddInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      purchase_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      ),
    };
    api.add_pc_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  UpdateInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      pc_adv_invoice_id: invoiceDetails?.id,
      purchase_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      ),
    };

    api.update_pc_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  ContractApproveHandler = (status) => {
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      purchase_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      ),
      status: status,
    };

    if (status === "Approved") {
      let validation_errors = 0;
      for (var i of this.state.qualities) {
        const { quantity_allocated = 0, barge_delivered_quantity = 0 } =
          i || {};

        if (
          parseFloat(quantity_allocated) > parseFloat(i.quantity_in_mt) ||
          parseFloat(barge_delivered_quantity) > parseFloat(i.quantity_in_mt)
        ) {
          // rows[idx].quantity_in_mtError = false;
          validation_errors++;
          // setQualities(rows);
          // callbackFn(rows);
          // forceUpdate();
          // return;
        }
      }

      if (validation_errors > 0) {
        return;
      }
      api.approve_purchase_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    } else {
      api.updatePurchaseContractStatus(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res) {
              if (res.code === "200") {
                this.setState({
                  successMsg: "Contract has been update successfully",
                  snackBarSuccessOpen: true,
                });
                this.componentDidMount();
                window.$("#beforeFreezed").modal("hide");
              }
              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
                if (res.code === "607") {
                  window.location.href = "/logout";
                }
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.addDocuments && this.state.purchaseContractId) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Purchase_Contract"}
          feature_seq_id={this.state.purchaseContractId}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />

          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  <a href={"/purchase-contract-list"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>
                  Coal Purchase Contract - {this.state.contract_no} &nbsp;
                  <span
                    className={
                      this.state.status === "Active" ? "type_1" : "type_2"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.status}
                  </span>
                </h4>
              </div>
              {this.state.status === "Draft" && (
                <div
                  className="col-lg-3 text-right float-sm-left"
                  style={{ padding: "5px 20px" }}
                >
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "17px" }}
                    onClick={() =>
                      this.ContractApproveHandler("Pending Approval")
                    }
                  >
                    Send for Approval
                  </button>
                </div>
              )}
              {this.state.status === "Pending Approval" &&
                (this.state.features?.includes("12") ||
                  this.state.features?.includes("97")) &&
                this.state.created_by !==
                  this.Cookie.getCookie("loginUserId") && (
                  <div
                    className="col-lg-3 text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={() => this.ContractApproveHandler("Approved")}
                    >
                      Approve
                    </button>
                  </div>
                )}
              <div className="col-lg-2 text-right float-sm-left">
                <span
                  className="mr-1"
                  data-toggle="tooltip"
                  title="Action"
                  data-placement="bottom"
                >
                  <DashboardIcon
                    style={{
                      fontSize: 30,
                      marginTop: 10,
                      color: "#138496",
                      cursor: "pointer",
                    }}
                    onClick={this.addButtonOpen}
                  />
                </span>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDocumentsDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          {!this.state.isLoading && (
            <div className="container p-3">
              <div
                className="col-lg card bg-white"
                id="purchase-pdf"
                style={{ overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <tr className="table-header">
                        <th>Vendor</th>
                        <th>Supplier Reference No</th>
                        <th>Contract Date</th>
                        <th>Currency</th>
                        <th>Laycan</th>
                        {this.state.addendums.length > 0 && <th>Addendums</th>}
                      </tr>
                      <tr>
                        <td rowSpan={this.state.addendums.length}>
                          <a
                            href={"/view-vendor/" + btoa(this.state.vendor)}
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.vendor_name}
                          </a>
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {this.state.supplier_ref_no
                            ? this.state.supplier_ref_no
                            : "-"}
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {localDateFormate(this.state.contract_date)}
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {this.state.currency ? this.state.currency : "-"}{" "}
                        </td>
                        <td>
                          {(this.state.laycan_1 ||
                            this.state.laycan_quantity_1) && (
                            <div>
                              {this.state.laycan_1} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_1
                              )}
                            </div>
                          )}
                          {(this.state.laycan_2 ||
                            this.state.laycan_quantity_2) && (
                            <div>
                              {this.state.laycan_2} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_2
                              )}
                            </div>
                          )}
                          {(this.state.laycan_3 ||
                            this.state.laycan_quantity_3) && (
                            <div>
                              {this.state.laycan_3} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_3
                              )}
                            </div>
                          )}
                          {(this.state.laycan_4 ||
                            this.state.laycan_quantity_4) && (
                            <div>
                              {this.state.laycan_4} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_4
                              )}
                            </div>
                          )}
                          {(this.state.laycan_5 ||
                            this.state.laycan_quantity_5) && (
                            <div>
                              {this.state.laycan_5} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_5
                              )}
                            </div>
                          )}
                        </td>
                        {this.state.addendums.length > 0 && (
                          <td>
                            {this.state.addendums?.map((e, idx) => (
                              <div>
                                <a
                                  rel="noopener noreferrer"
                                  href={
                                    "/purchase-contract-view-addendum/" +
                                    btoa(e.addendum_contract_id)
                                  }
                                  target="_blank"
                                  style={{ color: config.themeColor }}
                                >
                                  {e.addendum_contract_no}
                                </a>
                              </div>
                            ))}
                          </td>
                        )}
                      </tr>
                    </table>
                  </div>
                </div>

                <QualityView
                  contractType={"Purchase"}
                  qualities={this.state.qualities}
                  callbackFn={() => {}}
                />

                <table className="table table-bordered table-sm">
                  <tr>
                    <th className="table-header">Quality Adjustment</th>
                  </tr>
                  <tr>
                    <td>
                      {this.state.payment_adjustment
                        ? this.state.payment_adjustment
                        : "-"}
                    </td>
                  </tr>
                </table>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <tr className="table-header">
                        <th></th>
                        <th>Supplier</th>
                        <th>{config.company} Surveyor</th>
                        <th>Buyer/Additional Surveyor </th>
                      </tr>
                      <tr>
                        <th>Surveyor Name</th>
                        <td>
                          {this.state.supplier_surveyor_names
                            ? this.state.supplier_surveyor_names
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_names
                            ? this.state.internal_surveyor_names
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Analysis Type</th>
                        <td>
                          {this.state.supplier_surveyor_analysis_type
                            ? this.state.supplier_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.state.joint_surveyor_analysis_type
                            ? this.state.joint_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Payment Terms 1</th>
                          <th nowrap="true">Payment Terms 2</th>
                          <th nowrap="true">Remarks</th>
                          <th nowrap="true">Share Point Document Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.payment_terms_1
                              ? this.state.payment_terms_1
                              : "-"}
                          </td>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.payment_terms_2
                              ? this.state.payment_terms_2
                              : "-"}
                          </td>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.remarks ? this.state.remarks : "-"}
                          </td>
                          <td>
                            {this.state.share_point_doc_link ? (
                              <div
                                className="contract_display_header_value"
                                style={{
                                  textTransform: "uppercase",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={this.onLinkClick(
                                    this.state.share_point_doc_link
                                  )}
                                  target="_blank"
                                  style={{ color: config.themeColor }}
                                >
                                  {this.state.share_point_doc_link}
                                </a>
                              </div>
                            ) : (
                              <div className="contract_display_header_value">
                                {"-"}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {(this.state.features.includes("326") ||
                  this.state.features.includes("97")) && (
                  <div className="row">
                    <span
                      className="col-lg-12 contract_display_header_value pl-0 mb-2"
                      style={{ fontWeight: "bold" }}
                    >
                      Uploaded Documents
                    </span>
                    {this.state.files.map((e, index) => (
                      <div
                        className={
                          e.file_path ? "row col-lg-12 mb-2" : "row col-lg-12"
                        }
                        key={index}
                      >
                        {e.file_path && (
                          <div className="col-lg-4 p-0">
                            <div className="">
                              <a
                                href={config.apiDomain + "/" + e.file_path}
                                role="button"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: config.themeColor }}
                              >
                                {e.file_description}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    <p>
                      Add Documents{" "}
                      <i
                        className="fa fa-plus-circle"
                        style={{
                          color: config.themeColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            openDocumentsDrawer: true,
                            addDocuments: true,
                          });
                        }}
                      />
                    </p>
                  </div>
                )}
                {(this.state.businessNoData.length > 0 ||
                  this.state.unassigned_partial_quantity_data.length > 0 ||
                  this.state.unassigned_business_no_data.length > 0) && (
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th className="text-center" colSpan={5}>
                              Associated Business Numbers
                            </th>
                          </tr>
                          <tr className="table-header">
                            <th className=" text-left">Business No</th>
                            <th className="text-left">Quality</th>
                            <th className="text-right">Contract Quantity</th>
                            <th className="text-right">Quantity Allocated</th>
                            <th className="text-right">Quantity Delivered</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.businessNoData.map((e, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                <a
                                  href={
                                    "/view-business/" + btoa(e.business_no_id)
                                  }
                                  rel="noopener noreferrer"
                                  role="button"
                                  style={{ color: config.themeColor }}
                                >
                                  {e.business_no}{" "}
                                  {e.mother_vessel_name
                                    ? "( " + e.mother_vessel_name + " )"
                                    : ""}
                                </a>
                              </td>
                              <td className="text-left">{e.quality}</td>
                              <td className="text-right">
                                {e.contract_quantity
                                  ? this.toLocaleString(e.contract_quantity)
                                  : ""}
                              </td>
                              <td className="text-right">
                                {e.allocated_quantity
                                  ? this.toLocaleString(e.allocated_quantity)
                                  : ""}
                              </td>
                              <td className="text-right">
                                {e.delivered_quantity
                                  ? this.toLocaleString(e.delivered_quantity)
                                  : ""}
                              </td>
                            </tr>
                          ))}
                          {this.state.unassigned_partial_quantity_data.map(
                            (e, index) => (
                              <tr key={index}>
                                <td className="text-left">
                                  Unassigned Partial Quantity
                                </td>
                                <td className="text-left">{e.quality}</td>
                                <td className="text-right">
                                  {e.contract_quantity
                                    ? this.toLocaleString(e.contract_quantity)
                                    : ""}
                                </td>
                                {/* <td className='text-right'>{e.allocated_quantity ? this.toLocaleString(e.allocated_quantity) : ""}</td> */}
                                <td className="text-right">-</td>
                                <td className="text-right">
                                  {e.remaining_partial_quantity
                                    ? this.toLocaleString(
                                        e.remaining_partial_quantity
                                      )
                                    : 0}
                                </td>
                              </tr>
                            )
                          )}
                          {this.state.unassigned_business_no_data.map(
                            (e, index) => (
                              <tr key={index}>
                                <td className="text-left">
                                  Unassigned &nbsp;{" "}
                                  <a
                                    href={"/view-barge/" + btoa(e.barge_id)}
                                    rel="noopener noreferrer"
                                    role="button"
                                    style={{ color: config.themeColor }}
                                  >
                                    {pad(e.barge_id)}
                                  </a>
                                </td>
                                <td className="text-left">{e.quality}</td>
                                <td className="text-right">
                                  {e.contract_quantity
                                    ? this.toLocaleString(e.contract_quantity)
                                    : ""}
                                </td>
                                {/* <td className='text-right'>{e.allocated_quantity ? this.toLocaleString(e.allocated_quantity) : ""}</td> */}
                                <td className="text-right">-</td>
                                <td className="text-right">
                                  {e.barge_quantity
                                    ? this.toLocaleString(e.barge_quantity)
                                    : 0}
                                </td>
                              </tr>
                            )
                          )}
                          {(this.state.businessNoData.length > 0 ||
                            this.state.unassigned_business_no_data.length > 0 ||
                            this.state.unassigned_partial_quantity_data.length >
                              0) && (
                            <tr>
                              <td colSpan={3}></td>
                              <td
                                className="text-right"
                                style={{ fontWeight: "bold" }}
                              >
                                Total :{" "}
                                {this.state.sumOfAllocated_quantity
                                  ? this.toLocaleString(
                                      this.state.sumOfAllocated_quantity
                                    )
                                  : 0}
                              </td>
                              <td
                                className="text-right"
                                style={{ fontWeight: "bold" }}
                              >
                                Total :{" "}
                                {this.state.sumOfDelivered_quantity
                                  ? this.toLocaleString(
                                      this.state.sumOfDelivered_quantity
                                    )
                                  : 0}
                              </td>
                            </tr>
                          )}

                          {(this.state.businessNoData.length > 0 ||
                            this.state.unassigned_business_no_data.length > 0 ||
                            this.state.unassigned_partial_quantity_data.length >
                              0) && (
                            <tr>
                              <td colSpan={4}></td>
                              <td
                                className="text-right text-success"
                                style={{ fontWeight: "bold" }}
                              >
                                Remaining : &nbsp;
                                {this.state.available_qty
                                  ? this.toLocaleString(
                                      this.state.available_qty
                                    )
                                  : 0}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {this.state.cps_contract?.length > 0 && (
                  <div className="card p-0 border mb-2">
                    <div className="card-header section_header">
                      <h5>Procurement Service Summary</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center" nowrap="true">
                                Contract No
                              </th>
                              <th className="text-center" nowrap="true">
                                Vendor Name
                              </th>
                              <th className="text-right" nowrap="true">
                                Quantity
                              </th>
                              <th className="text-center" nowrap="true">
                                Deduction Bases
                              </th>
                              <th className="text-right" nowrap="true">
                                Price PMT
                              </th>
                              <th className="text-right" nowrap="true">
                                Amount
                              </th>
                              <th className="text-right" nowrap="true">
                                Delivered Quantity
                              </th>
                              <th className="text-right" nowrap="true">
                                Remaining Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.cps_contract.map((e, idx) => (
                              <tr key={e.id}>
                                <td className="table_header_barge_value text-center">
                                  <a
                                    href={"/view-cps-contract/" + btoa(e.id)}
                                    rel="noopener noreferrer"
                                    role="button"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.cps_contract_no}
                                  </a>
                                </td>
                                <td className="table_header_barge_value text-center">
                                  {e.vendor_name
                                    ? e.vendor_name
                                    : e.other_vendor_name}
                                </td>
                                <td className="table_header_barge_valuetext-right">
                                  {toLocaleString(e.quantity_in_mt)}
                                </td>
                                <td className="table_header_barge_value text-center">
                                  {e.price_type}
                                </td>
                                <td className="table_header_barge_value text-right">
                                  {toLocaleString(e.price_pmt)}
                                </td>

                                <td className="table_header_barge_value  text-right">
                                  {toLocaleString(e.total_price)}
                                </td>
                                <td className="table_header_barge_value  text-right">
                                  {toLocaleString(e.delivered_quantity)}
                                </td>
                                <td className="table_header_barge_value  text-right">
                                  {toLocaleString(e.remaining_quantity)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {(this.state.features.includes("104") ||
                  this.state.features.includes("97")) && (
                  <div className="card p-0 border mb-2">
                    <div className="card-header section_header">
                      <h5>Purchase Contract Advance Payments</h5>
                    </div>
                    <div className="card-body">
                      <AdvancePaymentPostings
                        patmentsData={this.state.purchase_contract_invoices}
                        accessInfo={{
                          invoice_approver: "12",
                          invoice_approver_2: "593",
                          payment_approver: "594",
                          payment_approver_2: "595",
                        }}
                        costingType={"Purchase Contract Advance Payments"}
                        paymentApprType={"pc_adv_payments_data"}
                        paymentsHandler={(paymentMode, data) => {
                          if (paymentMode === "ADD") {
                            this.AddPaymentPostingHandler(data);
                          } else {
                            this.UpdatePaymentPostingHandler(data);
                          }
                        }}
                        invoicesHandler={(invoiceMode, data) => {
                          if (invoiceMode === "ADD") {
                            this.AddInvoicesHandler(data);
                          } else {
                            this.UpdateInvoicesHandler(data);
                          }
                        }}
                        callbackFn={() => this.componentDidMount()}
                      />
                    </div>
                  </div>
                )}

                {(this.state.purchase_adv_contract.length > 0 ||
                  this.state.cps_advance_adjustments.length > 0) && (
                  <div className="card p-0 border mb-2">
                    <div className="card-header section_header">
                      <h5>Advance Adjustments:</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-bordered table-sm ">
                          <thead>
                            <tr>
                              <th className="">Business No</th>
                              <th className="" style={{ textAlign: "right" }}>
                                Advance Adjustment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.purchase_adv_contract.map(
                              (e, index) => (
                                <tr key={index}>
                                  {e.business_number_id ? (
                                    <td>
                                      <a
                                        href={
                                          "/view-business/" +
                                          btoa(e.business_number_id)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        role="button"
                                        style={{ color: config.themeColor }}
                                      >
                                        {e.business_no ? e.business_no : ""}
                                        {e.mother_vessel_name
                                          ? " (" + e.mother_vessel_name + ")"
                                          : e.barge_name
                                          ? " (" + e.barge_name + ")"
                                          : ""}{" "}
                                        {e.barge_nomination
                                          ? "- " + e.barge_nomination
                                          : ""}{" "}
                                      </a>
                                    </td>
                                  ) : (
                                    <td>
                                      {e.barge_name
                                        ? " (" + e.barge_name + ")"
                                        : ""}{" "}
                                      {e.barge_nomination
                                        ? "- " + e.barge_nomination
                                        : ""}
                                    </td>
                                  )}
                                  <td className="text-right">
                                    {e.advance_adjustment
                                      ? this.toLocalDecimalString(
                                          e.advance_adjustment
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )
                            )}
                            
                            {this.state.cps_advance_adjustments.map(
                              (e, index) => (
                                <tr key={index}>
                                  {/* <td className='table_td'><a href={'/view-business/' + btoa(e.business_number_id)}  rel="noopener noreferrer" role="button">{e.business_no}</a></td> */}
                                  {e.business_number_id ? (
                                    <td>
                                      <a
                                        href={
                                          "/view-business/" +
                                          btoa(e.business_number_id)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        role="button"
                                        style={{ color: config.themeColor }}
                                      >
                                        {e.business_no ? e.business_no : ""}
                                        {e.mother_vessel_name
                                          ? " (" + e.mother_vessel_name + ")"
                                          : e.barge_name
                                          ? " (" + e.barge_name + ")"
                                          : ""}{" "}
                                        {e.barge_nomination
                                          ? "- " + e.barge_nomination
                                          : ""}{" "}
                                      </a>
                                    </td>
                                  ) : (
                                    <td>
                                      Coal Procurement Service Fee
                                      {e.barge_name
                                        ? " (" + e.barge_name + ")"
                                        : ""}{" "}
                                      {e.barge_nomination
                                        ? "- " + e.barge_nomination
                                        : ""}
                                    </td>
                                  )}
                                  <td className="text-right">
                                    {e.cps_advance_adjustment_value
                                      ? this.toLocalDecimalString(
                                          e.cps_advance_adjustment_value
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )
                            )}
                            {(this.state.purchase_adv_contract.length > 0 ||
                              this.state.cps_advance_adjustments.length >
                                0) && (
                              <tr>
                                <td></td>
                                <td
                                  className="text-right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total :{" "}
                                  {this.state.sumOfAdvance_adjustment
                                    ? this.toLocalDecimalString(
                                        this.state.sumOfAdvance_adjustment
                                      )
                                    : ""}
                                </td>
                              </tr>
                            )}
                            {(this.state.purchase_adv_contract.length > 0 ||
                              this.state.cps_advance_adjustments.length >
                                0) && (
                              <tr>
                                <td></td>
                                <td
                                  className="text-right text-success"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Remaining Advance :{" "}
                                  {this.toLocalDecimalString(
                                    Number(
                                      this.state.total_adv_payments
                                        ? this.state.total_adv_payments
                                        : 0
                                    ) -
                                      Number(
                                        this.state.sumOfAdvance_adjustment
                                          ? this.state.sumOfAdvance_adjustment
                                          : 0
                                      )
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() =>
                    (window.location.href = "/purchase-contract-list")
                  }
                >
                  Back
                </button>
                {(this.state.features.includes("9") ||
                  this.state.features.includes("97")) &&
                  this.state.status !== "Active" && (
                    <button
                      type="button"
                      className="btn next_button"
                      name="submit"
                      onClick={() =>
                        (window.location.href =
                          "/update-purchase-contract/" +
                          btoa(this.state.purchaseContractId))
                      }
                    >
                      Edit
                    </button>
                  )}
              </div>
            </div>
          )}

          <div
            className="modal fade"
            id="deletePaymentModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />{" "}
                    Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() =>
                      this.deleteAdvancePayment(this.state.deletePayment)
                    }
                  >
                    Delete
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="deleteAdvancePayment" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    Are you sure to delete Purchase Contract Advance Payment?
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    onClick={() =>
                      this.delete_PC_AdvancePayment(
                        this.state.deleteAdvancePayment
                      )
                    }
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Popover
            open={this.state.addButtonOpen}
            anchorEl={this.state.anchorEn}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
            onClose={this.addButtonClose}
          >
            <div className="card border">
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={this.cloneHandler}
              >
                <i
                  className="fa fa-clone mr-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 25,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 3px",
                    width: "20px",
                  }}
                />
                Clone
              </MenuItem>

              {this.state.pc_status === "Active" && (
                <MenuItem style={{ fontFamily: "Poppins" }}>
                  <i
                    className="fa fa-plus-circle mr-4 ml-0"
                    style={{
                      fontSize: 26,
                      color: "#3256a8",
                      opacity: "0.5",
                      margin: "0px 6px",
                      width: "16px",
                    }}
                  />
                  <a
                    href={
                      "/purchase-contract-addendum/" +
                      btoa(this.state.purchaseContractId)
                    }
                  >
                    Create Addendum
                  </a>
                </MenuItem>
              )}
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={() => this.ContractApproveHandler("Closed")}
              >
                <i
                  className="fa fa-times mr-4 ml-0"
                  style={{
                    fontSize: 26,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 6px",
                    width: "16px",
                  }}
                />
                Close
              </MenuItem>
              {this.state.status !== "Active" &&
                (this.state.features.includes("100") ||
                  this.state.features.includes("97")) && (
                  <MenuItem
                    style={{ fontFamily: "Poppins" }}
                    onClick={() => this.ContractApproveHandler("cancelled")}
                  >
                    <i
                      className="fa fa-minus-square-o mr-3"
                      aria-hidden="true"
                      style={{
                        fontSize: 26,
                        color: "#3256a8",
                        opacity: "0.5",
                        margin: "0px 3px",
                        width: "20px",
                      }}
                    />
                    Cancel
                  </MenuItem>
                )}
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}
