import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  MenuItem,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class MvSupplierDemurrageUpdate extends Component {
  constructor(props) {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 1,

      supplierData: [
        {
          supplier: "0",
          demurrage_per_day: "",
          invoice_date: null,
          invoice_no: "",
          ppn: "",
          demurrage_currency: "0",
          despatch_per_day: "",
          despatch_currency: "0",
          demurrage_or_despatch: "Demurrage",
          demurrage_in_days: "",
          demurrage_incurred: "",
          despatch_in_days: "",
          despatch_incurred: "",
        },
      ],

      supplier_menu: [],
      currencyData: [],
      isLoading: true,
      files: [],
      features: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("51") ||
          features.includes("432") ||
          features.includes("483") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      try {
        var loginUserID = this.Cookie.getCookie("loginUserId");
        var idToken = this.Cookie.getIdTokenCookie();
        // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;

        const getCurrencies = await api.getCurrencies(loginUserID, idToken);
        if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
          getCurrencies.json().then((res) => {
            if (res.code === "200") {
              if (res.Currency) {
                var currencies = [
                  <MenuItem value="0" key={-1}>
                    Select Currency
                  </MenuItem>,
                ];
                for (var i in res.Currency) {
                  currencies.push(
                    <MenuItem value={res.Currency[i].currency} key={[i]}>
                      {res.Currency[i].currency}
                    </MenuItem>
                  );
                }
                this.setState({
                  currencyData: currencies,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        const getBusinessNumber = await api.getBusinessNumber(
          businessNoID,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                var supplier_menu = [
                  <MenuItem value="0" key={-1}>
                    Select Supplier
                  </MenuItem>,
                ];
                for (var i of res.business_number.business_number_allocation) {
                  supplier_menu.push(
                    <MenuItem value={i.supplier_id} key={[i]}>
                      {i.supplier_name}
                    </MenuItem>
                  );
                }

                this.setState({
                  supplier_menu: supplier_menu,
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vesselName: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        var MvSupplierDemurrageID = this.props.MvSupplierDemurrageID;
        const getData = await api.get_mv_pur_fin_supplier_demurrage(
          loginUserID,
          idToken,
          MvSupplierDemurrageID
        );
        if (getData.status >= 200 && getData.status < 300) {
          getData.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_supplier_demurrage) {
                if (res.mv_supplier_demurrage.length > 0) {
                  this.setState({
                    supplierData: res.mv_supplier_demurrage,
                    files: res.mv_supplier_demurrage[0].files,
                    invoice_file: res.mv_supplier_demurrage[0].invoice_file,
                  });
                }
                this.setState({ isLoading: false });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
    }
    document.title = config.documentTitle + "Mother Vessel Supplier Demurrage";
  }

  addMoreSupplierRowsHandler = () => {
    const item = {
      supplier: "0",
      demurrage_per_day: "",
      invoice_date: null,
      invoice_no: "",
      ppn: "",
      demurrage_currency: "0",
      despatch_per_day: "",
      despatch_currency: "0",
      demurrage_or_despatch: "Demurrage",
      demurrage_in_days: "",
      demurrage_incurred: "",
      despatch_in_days: "",
      despatch_incurred: "",
      supplier_currency_xchg_rate: "",
      supplier_pph23_account: "0",
      supplier_base_price: "",
      supplier_vat_percent: "10",
      supplier_vat_value: "",
      supplier_pph23_percent: "2",
      supplier_pph23_value: "",
      supplier_total: "",
      supplier_ctc: "",
    };

    this.setState({
      supplierData: [...this.state.supplierData, item],
    });
  };

  removeSupplierRowsHandler = (idx) => () => {
    const rows = this.state.supplierData;
    rows.splice(idx, 1);
    this.setState({
      supplierData: rows,
    });
  };

  submitHandler = () => {
    var { supplierData } = this.state;

    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    var finalData = supplierData[0];
    finalData.supplier_demurrage_id = this.props.MvSupplierDemurrageID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;
    finalData["costing_files"] = costing_file;
    api.update_mv_pur_fin_supplier_demurrage(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                successMsg: res.message,
                snackBarSuccessOpen: true,
              },
              () => {
                setTimeout(() => {
                  this.props.onUpdateCosting({
                    screen: "MvSupplierDemurrage",
                    code: res.code,
                    message: "",
                  });
                }, 2000);
              }
            );
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              successMsg: "",
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };
  supplier_demurrage_calculations = (idx) => {
    var row = this.state.supplierData;
    var currency_xchg_rate = row[idx].supplier_currency_xchg_rate
      ? row[idx].supplier_currency_xchg_rate
      : 1;
    if (
      (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
      (row[idx].demurrage_currency === "USD" ||
        row[idx].despatch_currency === "USD")
    ) {
      currency_xchg_rate = 1;
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      (row[idx].demurrage_currency === "IDR" ||
        row[idx].despatch_currency === "IDR")
    ) {
      currency_xchg_rate = 1;
    }
    var despatch = Number(row[idx].demurrage_per_day) / 2;
    var demurrage_incurred = (
      (Number(row[idx].demurrage_per_day) *
        Number(row[idx].demurrage_in_days)) /
      currency_xchg_rate
    ).toFixed(2);
    var despatch_incurred = (
      (Number(despatch) * Number(row[idx].despatch_in_days)) /
      currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      ((row[idx].demurrage_currency !== "IDR" &&
        row[idx].demurrage_currency !== "0" &&
        row[idx].demurrage_currency) ||
        (row[idx].despatch_currency !== "IDR" &&
          row[idx].despatch_currency !== "0" &&
          row[idx].despatch_currency))
    ) {
      demurrage_incurred = (
        Number(row[idx].demurrage_per_day) *
        Number(row[idx].demurrage_in_days) *
        Number(currency_xchg_rate)
      ).toFixed(2);
      despatch_incurred = (
        Number(despatch) *
        Number(row[idx].despatch_in_days) *
        Number(currency_xchg_rate)
      ).toFixed(2);
    }
    //row[idx].demurrage_per_day = e.target.value;
    row[idx].despatch_per_day = despatch;
    row[idx].demurrage_incurred = demurrage_incurred;
    row[idx].despatch_incurred = despatch_incurred;

    var supplier_base_price = "";
    if (row[idx].demurrage_or_despatch === "Demurrage") {
      supplier_base_price = Number(demurrage_incurred).toFixed(2);
    } else if (row[idx].demurrage_or_despatch === "Despatch") {
      supplier_base_price = Number(despatch_incurred).toFixed(2);
    }

    var incurred_value = supplier_base_price;

    let supplier_vat_percent = 10;
    let supplier_vat_value = ((Number(supplier_base_price) * 10) / 100).toFixed(
      2
    );
    let supplier_pph23_percent = 2;
    let supplier_pph23_value = Number((supplier_base_price * 2) / 100).toFixed(
      2
    );
    let supplier_total = (
      Number(supplier_base_price) +
      Number(supplier_vat_value) -
      Number(supplier_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      supplier_total = (
        Number(supplier_base_price) + Number(supplier_vat_value)
      ).toFixed(2);
    }
    let supplier_ctc = Number(
      Number(supplier_base_price) + Number(supplier_vat_value)
    ).toFixed(2);

    row[idx].supplier_base_price = supplier_base_price;
    row[idx].supplier_vat_percent = supplier_vat_percent;
    row[idx].supplier_vat_value = supplier_vat_value;
    row[idx].supplier_pph23_percent = supplier_pph23_percent;
    row[idx].supplier_pph23_value = supplier_pph23_value;
    row[idx].supplier_total = supplier_total;
    row[idx].supplier_ctc = supplier_ctc;

    if (row[idx].supplier_pph23_account === "SDAM") {
      let supplier_base_price = Number((incurred_value * 100) / 98).toFixed(2);
      let supplier_pph23_percent = 2;
      let supplier_pph23_value = Number(
        ((supplier_base_price * 2) / 100).toFixed(2)
      );
      let supplier_total = Number(incurred_value).toFixed(2);
      //let supplier_ctc = Number(supplier_base_price).toFixed(2);
      let supplier_ctc = (
        Number(supplier_base_price) + Number(supplier_pph23_value)
      ).toFixed(2);
      row[idx].supplier_base_price = supplier_base_price;
      row[idx].supplier_pph23_percent = supplier_pph23_percent;
      row[idx].supplier_pph23_value = supplier_pph23_value;
      row[idx].supplier_total = supplier_total;
      row[idx].supplier_ctc = supplier_ctc;
    }
    this.setState({
      supplierData: row,
    });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const re = /^\d*(\.\d{0,10})?$/;

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Supplier Demurrage data for Mother Vessel{" "}
                {this.state.bargeNomination
                  ? "(" + this.state.bargeNomination + ")"
                  : null}{" "}
                - {this.state.businessNo ? this.state.businessNo : ""}
                {this.state.mother_vesselName
                  ? " (" + this.state.mother_vesselName + ")"
                  : ""}
              </h4>
            </div>
          </div>
          <div
            className="card p-3"
            style={{ overflowY: "scroll", minHeight: "86vh" }}
          >
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              {!this.props.updateDocuments && (
                <div>
                  {this.state.supplierData.map((e, idx) => (
                    <div className="row" key={idx}>
                      <div className="row col-lg-12 border_dashed rounded-lg p-2 mb-3">
                        <div className="row col-lg-6 pl-0 mb-2 pr-2">
                          <div className="col-lg-12 pl-0 mb-2">
                            <label className="form_label mb-0">Supplier</label>
                            <TextField
                              name="supplier"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Supplier"
                              select
                              value={e.supplier}
                              onChange={(e) => {
                                var row = this.state.supplierData;
                                row[idx].supplier = e.target.value;
                                this.setState({
                                  supplierData: row,
                                });
                              }}
                            >
                              {this.state.supplier_menu}
                            </TextField>
                          </div>
                          <div className="col-lg-9 pl-0 mb-2">
                            <label
                              className="form_label mb-0"
                              style={{ fontSize: "11px" }}
                            >
                              Supplier Demurrage per Day
                            </label>
                            <TextField
                              name="demurrage_per_day"
                              margin="dense"
                              variant="outlined"
                              value={e.demurrage_per_day}
                              className="rounded-left"
                              placeholder="Supplier Demurrage per Day"
                              style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                              fullWidth
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var row = this.state.supplierData;
                                  //var despatch = (Number(e.target.value) / 2);
                                  row[idx].demurrage_per_day = e.target.value;
                                  // row[idx].despatch_per_day = despatch
                                  // row[idx].demurrage_incurred = (Number(e.target.value) * Number(row[idx].demurrage_in_days));
                                  // row[idx].despatch_incurred = (Number(despatch) * Number(row[idx].despatch_in_days));
                                  this.setState(
                                    {
                                      supplierData: row,
                                    },
                                    () =>
                                      this.supplier_demurrage_calculations(idx)
                                  );
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    // variant="outlined"
                                    className="adornment_background"
                                    position="end"
                                    style={{
                                      marginRight: "-14px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="demurrage_currency"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      value={e.billing_currency}
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          margin: 0,
                                        },
                                        disableUnderline: true,
                                      }}
                                      onChange={(e) => {
                                        var row = this.state.supplierData;
                                        row[idx].demurrage_currency =
                                          e.target.value;
                                        this.setState(
                                          {
                                            supplierData: row,
                                          },
                                          () =>
                                            this.supplier_demurrage_calculations(
                                              idx
                                            )
                                        );
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-3 pl-0 mb-2">
                            <label className="form_label mb-0">
                              Despatch per Day
                            </label>
                            <div
                              className="contract_display_header_value mt-2"
                              style={{ color: "#42352d" }}
                            >
                              {e.despatch_per_day
                                ? toLocaleString(e.despatch_per_day)
                                : ""}
                            </div>
                          </div>

                          <div className="col-lg-12 pl-0 mb-2">
                            <label className="form_label mb-0">
                              Demurrage / Despatch
                            </label>
                            <RadioGroup
                              aria-label="demurrage_or_despatch"
                              name="demurrage_or_despatch"
                              style={{ flexDirection: "row" }}
                              onChange={(e) => {
                                var row = this.state.supplierData;
                                row[idx].demurrage_or_despatch = e.target.value;
                                if (e.target.value === "Demurrage") {
                                  row[idx].despatch_in_days = "";
                                  row[idx].despatch_incurred = "";
                                } else if (e.target.value === "Despatch") {
                                  row[idx].demurrage_in_days = "";
                                  row[idx].demurrage_incurred = "";
                                }
                                this.setState(
                                  {
                                    supplierData: row,
                                  },
                                  () =>
                                    this.supplier_demurrage_calculations(idx)
                                );
                              }}
                            >
                              <FormControlLabel
                                className="mb-0"
                                value="Demurrage"
                                control={
                                  <NewRadio
                                    checked={
                                      e.demurrage_or_despatch === "Demurrage"
                                    }
                                  />
                                }
                                label="Demurrage"
                              />
                              <FormControlLabel
                                className="mb-0"
                                value="Despatch"
                                control={
                                  <NewRadio
                                    checked={
                                      e.demurrage_or_despatch === "Despatch"
                                    }
                                  />
                                }
                                label="Despatch"
                              />
                            </RadioGroup>
                          </div>

                          {e.demurrage_or_despatch === "Demurrage" && (
                            <div className="col-lg-6 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Demurrage in days
                              </label>
                              <TextField
                                name="demurrage_in_days"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Demurrage in days"
                                value={e.demurrage_in_days}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.supplierData;
                                    row[idx].demurrage_in_days = e.target.value;
                                    //row[idx].demurrage_incurred = (Number(row[idx].demurrage_per_day) * Number(e.target.value));
                                    this.setState(
                                      {
                                        supplierData: row,
                                      },
                                      () =>
                                        this.supplier_demurrage_calculations(
                                          idx
                                        )
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}

                          {e.demurrage_or_despatch === "Despatch" && (
                            <div className="col-lg-6 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Despatch in days
                              </label>
                              <TextField
                                name="despatch_in_days"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Despatch in days"
                                value={e.despatch_in_days}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.supplierData;
                                    row[idx].despatch_in_days = e.target.value;
                                    //row[idx].despatch_incurred = (Number(row[idx].despatch_per_day) * Number(e.target.value));
                                    this.setState(
                                      {
                                        supplierData: row,
                                      },
                                      () =>
                                        this.supplier_demurrage_calculations(
                                          idx
                                        )
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {(config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL") &&
                            ((e.demurrage_currency !== "USD" &&
                              e.demurrage_currency !== "0") ||
                              (e.despatch_currency !== "USD" &&
                                e.despatch_currency !== "0")) && (
                              <div className="col-lg-6 pl-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ fontSize: "10px" }}
                                >
                                  Exchange rate from{" "}
                                  {e.demurrage_or_despatch === "Demurrage"
                                    ? e.demurrage_currency
                                    : e.despatch_currency}{" "}
                                  to USD
                                  {e.demurrage_currency}
                                </label>
                                <TextField
                                  name="supplier_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={e.supplier_currency_xchg_rate}
                                  type="number"
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Exchange rate"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var row = this.state.supplierData;
                                      row[idx].supplier_currency_xchg_rate =
                                        e.target.value;

                                      this.setState(
                                        {
                                          supplierData: row,
                                        },
                                        () =>
                                          this.supplier_demurrage_calculations(
                                            idx
                                          )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}

                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") &&
                            ((e.demurrage_currency !== "IDR" &&
                              e.demurrage_currency !== "0") ||
                              (e.despatch_currency !== "IDR" &&
                                e.despatch_currency !== "0")) && (
                              <div className="col-lg-6 pl-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ fontSize: "10px" }}
                                >
                                  Exchange rate from{" "}
                                  {e.demurrage_or_despatch === "Demurrage"
                                    ? e.demurrage_currency
                                    : e.despatch_currency}{" "}
                                  to IDR
                                  {e.demurrage_currency}
                                </label>
                                <TextField
                                  name="supplier_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={e.supplier_currency_xchg_rate}
                                  type="number"
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Exchange rate"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var row = this.state.supplierData;
                                      row[idx].supplier_currency_xchg_rate =
                                        e.target.value;

                                      this.setState(
                                        {
                                          supplierData: row,
                                        },
                                        () =>
                                          this.supplier_demurrage_calculations(
                                            idx
                                          )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          {e.demurrage_or_despatch === "Demurrage" && (
                            <div className="col-lg-6 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Demurrage incurred
                              </label>
                              <TextField
                                name="demurrage_incurred"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Demurrage incurred"
                                value={e.demurrage_incurred}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.supplierData;
                                    row[idx].demurrage_incurred =
                                      e.target.value;

                                    var incurred_value = e.target.value;

                                    let supplier_vat_percent = 10;
                                    let supplier_vat_value = (
                                      (Number(incurred_value) * 10) /
                                      100
                                    ).toFixed(2);
                                    let supplier_pph23_percent = 2;
                                    let supplier_pph23_value = Number(
                                      (incurred_value * 2) / 100
                                    ).toFixed(2);
                                    let supplier_total = (
                                      Number(incurred_value) +
                                      Number(supplier_vat_value) -
                                      Number(supplier_pph23_value)
                                    ).toFixed(2);
                                    if (
                                      config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                    ) {
                                      supplier_total = (
                                        Number(incurred_value) +
                                        Number(supplier_vat_value)
                                      ).toFixed(2);
                                    }
                                    let supplier_ctc = Number(
                                      Number(incurred_value) +
                                        Number(supplier_vat_value)
                                    ).toFixed(2);

                                    row[idx].supplier_base_price =
                                      incurred_value;
                                    row[idx].supplier_vat_percent =
                                      supplier_vat_percent;
                                    row[idx].supplier_vat_value =
                                      supplier_vat_value;
                                    row[idx].supplier_pph23_percent =
                                      supplier_pph23_percent;
                                    row[idx].supplier_pph23_value =
                                      supplier_pph23_value;
                                    row[idx].supplier_total = supplier_total;
                                    row[idx].supplier_ctc = supplier_ctc;

                                    if (
                                      row[idx].supplier_pph23_account === "SDAM"
                                    ) {
                                      let supplier_base_price = Number(
                                        (incurred_value * 100) / 98
                                      ).toFixed(2);
                                      let supplier_pph23_percent = 2;
                                      let supplier_pph23_value = Number(
                                        (
                                          (supplier_base_price * 2) /
                                          100
                                        ).toFixed(2)
                                      );
                                      let supplier_total =
                                        Number(incurred_value).toFixed(2);
                                      //let supplier_ctc = Number(supplier_base_price).toFixed(2);
                                      let supplier_ctc = (
                                        Number(supplier_base_price) +
                                        Number(supplier_pph23_value)
                                      ).toFixed(2);
                                      row[idx].supplier_base_price =
                                        supplier_base_price;
                                      row[idx].supplier_pph23_percent =
                                        supplier_pph23_percent;
                                      row[idx].supplier_pph23_value =
                                        supplier_pph23_value;
                                      row[idx].supplier_total = supplier_total;
                                      row[idx].supplier_ctc = supplier_ctc;
                                    }
                                    this.setState({
                                      supplierData: row,
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}

                          {e.demurrage_or_despatch === "Despatch" && (
                            <div className="col-lg-6 pl-0 mb-2">
                              <label className="form_label mb-0">
                                Despatch incurred
                              </label>
                              <TextField
                                name="despatch_incurred"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Despatch Incurred"
                                value={e.despatch_incurred}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.supplierData;
                                    row[idx].despatch_incurred = e.target.value;
                                    var incurred_value = e.target.value;

                                    let supplier_vat_percent = 10;
                                    let supplier_vat_value = (
                                      (Number(incurred_value) * 10) /
                                      100
                                    ).toFixed(2);
                                    let supplier_pph23_percent = 2;
                                    let supplier_pph23_value = Number(
                                      (incurred_value * 2) / 100
                                    ).toFixed(2);
                                    let supplier_total = (
                                      Number(incurred_value) +
                                      Number(supplier_vat_value) -
                                      Number(supplier_pph23_value)
                                    ).toFixed(2);
                                    if (
                                      config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                    ) {
                                      supplier_total = (
                                        Number(incurred_value) +
                                        Number(supplier_vat_value)
                                      ).toFixed(2);
                                    }
                                    let supplier_ctc = Number(
                                      Number(incurred_value) +
                                        Number(supplier_vat_value)
                                    ).toFixed(2);

                                    row[idx].supplier_base_price =
                                      incurred_value;
                                    row[idx].supplier_vat_percent =
                                      supplier_vat_percent;
                                    row[idx].supplier_vat_value =
                                      supplier_vat_value;
                                    row[idx].supplier_pph23_percent =
                                      supplier_pph23_percent;
                                    row[idx].supplier_pph23_value =
                                      supplier_pph23_value;
                                    row[idx].supplier_total = supplier_total;
                                    row[idx].supplier_ctc = supplier_ctc;
                                    if (
                                      row[idx].supplier_pph23_account === "SDAM"
                                    ) {
                                      let supplier_base_price = Number(
                                        (incurred_value * 100) / 98
                                      ).toFixed(2);
                                      let supplier_pph23_percent = 2;
                                      let supplier_pph23_value = Number(
                                        (
                                          (supplier_base_price * 2) /
                                          100
                                        ).toFixed(2)
                                      );
                                      let supplier_total =
                                        Number(incurred_value).toFixed(2);
                                      //let supplier_ctc = Number(supplier_base_price).toFixed(2);
                                      let supplier_ctc = (
                                        Number(supplier_base_price) +
                                        Number(supplier_pph23_value)
                                      ).toFixed(2);
                                      row[idx].supplier_base_price =
                                        supplier_base_price;
                                      row[idx].supplier_pph23_percent =
                                        supplier_pph23_percent;
                                      row[idx].supplier_pph23_value =
                                        supplier_pph23_value;
                                      row[idx].supplier_total = supplier_total;
                                      row[idx].supplier_ctc = supplier_ctc;
                                    }
                                    this.setState({
                                      supplierData: row,
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="col-lg-6 pl-0 mb-2 pr-0">
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <div className="col-lg-12 pl-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH23 Account
                              </label>
                              <TextField
                                name="supplier_pph23_account"
                                margin="dense"
                                variant="outlined"
                                value={e.supplier_pph23_account}
                                select
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                fullWidth
                                onChange={(e) => {
                                  var row = this.state.supplierData;
                                  row[idx].supplier_pph23_account =
                                    e.target.value;
                                  this.setState(
                                    {
                                      supplierData: row,
                                    },
                                    () =>
                                      this.supplier_demurrage_calculations(idx)
                                  );
                                }}
                              >
                                <MenuItem value="0">Please Select</MenuItem>
                                <MenuItem value="Supplier">Supplier</MenuItem>
                                <MenuItem value="SDAM">SDAM</MenuItem>
                              </TextField>
                            </div>
                          )}
                          {(config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL") && (
                            <div
                              className="col-lg-12 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Demurrage</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {e.demurrage_or_despatch === "Despatch"
                                      ? "Final Despatch"
                                      : e.demurrage_or_despatch === "Demurrage"
                                      ? "Final Demurrage"
                                      : null}
                                  </label>
                                  <TextField
                                    name="supplier_base_price"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    // variant='outlined'
                                    value={e.supplier_base_price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.supplierData;
                                        let supplier_vat_percent = 10;
                                        let supplier_vat_value = (
                                          (Number(e.target.value) * 10) /
                                          100
                                        ).toFixed(2);
                                        let supplier_pph23_percent = 2;
                                        let supplier_pph23_value = Number(
                                          (e.target.value * 2) / 100
                                        ).toFixed(2);
                                        let supplier_total = (
                                          Number(e.target.value) +
                                          Number(supplier_vat_value) -
                                          Number(supplier_pph23_value)
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          supplier_total = (
                                            Number(e.target.value) +
                                            Number(supplier_vat_value)
                                          ).toFixed(2);
                                        }
                                        let supplier_ctc = Number(
                                          Number(e.target.value) +
                                            Number(supplier_vat_value)
                                        ).toFixed(2);

                                        row[idx].supplier_base_price =
                                          e.target.value;
                                        row[idx].supplier_vat_percent =
                                          supplier_vat_percent;
                                        row[idx].supplier_vat_value =
                                          supplier_vat_value;
                                        row[idx].supplier_pph23_percent =
                                          supplier_pph23_percent;
                                        row[idx].supplier_pph23_value =
                                          supplier_pph23_value;
                                        row[idx].supplier_total =
                                          supplier_total;
                                        row[idx].supplier_ctc = supplier_ctc;
                                        this.setState({
                                          supplierData: row,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_vat_percent
                                      ? toLocaleString(e.supplier_vat_percent)
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT Value
                                  </label>
                                  <TextField
                                    name="supplier_vat_value"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    // variant='outlined'
                                    value={e.supplier_vat_value}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.supplierData;
                                        let supplier_total = (
                                          Number(row[idx].supplier_base_price) +
                                          Number(e.target.value) -
                                          Number(row[idx].supplier_pph23_value)
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          supplier_total = (
                                            Number(
                                              row[idx].supplier_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);
                                        }
                                        let supplier_ctc = Number(
                                          Number(row[idx].supplier_base_price) +
                                            Number(e.target.value)
                                        ).toFixed(2);

                                        row[idx].supplier_vat_value =
                                          e.target.value;
                                        row[idx].supplier_total =
                                          supplier_total;
                                        row[idx].supplier_ctc = supplier_ctc;
                                        this.setState({
                                          supplierData: row,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {e.demurrage_or_despatch === "Demurrage"
                                      ? "Receivable"
                                      : "Payable"}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_total
                                      ? toLocaleString(e.supplier_total)
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_ctc
                                      ? toLocaleString(e.supplier_ctc)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <div
                              className="col-lg-12 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Demurrage</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {e.demurrage_or_despatch === "Despatch"
                                      ? "Final Despatch"
                                      : e.demurrage_or_despatch === "Demurrage"
                                      ? "Final Demurrage"
                                      : null}
                                  </label>
                                  <TextField
                                    name="supplier_base_price"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    // variant='outlined'
                                    value={e.supplier_base_price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.supplierData;
                                        let supplier_vat_percent = 10;
                                        let supplier_vat_value = (
                                          (Number(e.target.value) * 10) /
                                          100
                                        ).toFixed(2);
                                        let supplier_pph23_percent = 2;
                                        let supplier_pph23_value = Number(
                                          (e.target.value * 2) / 100
                                        ).toFixed(2);
                                        let supplier_total = (
                                          Number(e.target.value) +
                                          Number(supplier_vat_value) -
                                          Number(supplier_pph23_value)
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          supplier_total = (
                                            Number(e.target.value) +
                                            Number(supplier_vat_value)
                                          ).toFixed(2);
                                        }
                                        let supplier_ctc = Number(
                                          Number(e.target.value) +
                                            Number(supplier_vat_value)
                                        ).toFixed(2);

                                        row[idx].supplier_base_price =
                                          e.target.value;
                                        row[idx].supplier_vat_percent =
                                          supplier_vat_percent;
                                        row[idx].supplier_vat_value =
                                          supplier_vat_value;
                                        row[idx].supplier_pph23_percent =
                                          supplier_pph23_percent;
                                        row[idx].supplier_pph23_value =
                                          supplier_pph23_value;
                                        row[idx].supplier_total =
                                          supplier_total;
                                        row[idx].supplier_ctc = supplier_ctc;

                                        this.setState({
                                          supplierData: row,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                {e.supplier_pph23_account === "Supplier" && (
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label m-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.supplier_vat_percent
                                        ? toLocaleString(e.supplier_vat_percent)
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                                {e.supplier_pph23_account === "Supplier" && (
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label m-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT Value
                                    </label>
                                    <TextField
                                      name="supplier_vat_value"
                                      margin="dense"
                                      type="number"
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      // variant='outlined'
                                      value={e.supplier_vat_value}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.supplierData;
                                          let supplier_total = (
                                            Number(
                                              row[idx].supplier_base_price
                                            ) +
                                            Number(e.target.value) -
                                            Number(
                                              row[idx].supplier_pph23_value
                                            )
                                          ).toFixed(2);
                                          let supplier_ctc = Number(
                                            Number(
                                              row[idx].supplier_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);

                                          row[idx].supplier_vat_value =
                                            e.target.value;
                                          row[idx].supplier_total =
                                            supplier_total;
                                          row[idx].supplier_ctc = supplier_ctc;

                                          this.setState({
                                            supplierData: row,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                )}
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.supplier_pph23_percent)}
                                  </div>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_pph23_value
                                      ? toLocaleString(e.supplier_pph23_value)
                                      : "-"}
                                  </div>
                                </div>
                                {e.supplier_pph23_account === "SDAM" ? (
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label m-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      {e.demurrage_or_despatch === "Demurrage"
                                        ? "Receivable"
                                        : "Payable"}
                                    </label>
                                    <TextField
                                      name="supplier_total"
                                      margin="dense"
                                      type="number"
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      // variant='outlined'
                                      value={e.supplier_total}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.supplierData;
                                          let supplier_base_price = Number(
                                            (e.target.value * 100) / 98
                                          ).toFixed(2);
                                          let supplier_pph23_percent = 2;
                                          let supplier_pph23_value = Number(
                                            (
                                              (supplier_base_price * 2) /
                                              100
                                            ).toFixed(2)
                                          );
                                          let supplier_ctc = (
                                            Number(supplier_base_price) +
                                            Number(supplier_pph23_value)
                                          ).toFixed(2);
                                          row[idx].supplier_base_price =
                                            supplier_base_price;
                                          row[idx].supplier_pph23_percent =
                                            supplier_pph23_percent;
                                          row[idx].supplier_pph23_value =
                                            supplier_pph23_value;
                                          row[idx].supplier_total =
                                            e.target.value;
                                          row[idx].supplier_ctc = supplier_ctc;

                                          this.setState({
                                            supplierData: row,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_total
                                      ? toLocaleString(e.supplier_total)
                                      : "-"}
                                  </div>
                                )}
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.supplier_ctc
                                      ? toLocaleString(e.supplier_ctc)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {(this.state.features.indexOf("526") >= 0 ||
                this.state.features.indexOf("97") >= 0) && (
                <>
                  <div className="card p-0">
                    <FileUpload
                      files={this.state.files}
                      invoice_file={this.state.invoice_file}
                      type="Costings"
                      feature_name="Mv_Supplier_Demurrage"
                      callbackFn={(data, mandatoryFiles) => {
                        this.setState({
                          files: data,
                          costing_files: mandatoryFiles,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("MvSupplierDemurrage");
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </footer>
        </div>
      );
    }
  }
}
