import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
// import AdvanceAdjustmentsView from "../../contracts/AdvanceAdjustmentsView";
import AdvanceAdjustments from "../../contracts/AdvanceAdjustments";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import AuditInfo from "../../common/AuditInfo";
export default class CPSView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      features: [],
      cpc_costing_list: [],
      bargeList: [],
      files: [],

      barge_cps_costing_data: {
        id: null,
        payment_to: null,
        payee_name: null,
        vat_type: null,
        billing_currency: null,
        pph23_account: null,
        barge_quantity: null,
        base_price_pmt: null,
        base_price: null,
        lumpsum_price: null,
        vat_percent: null,
        vat_value: null,
        pph23_percent: null,
        pph23_value: null,
        payable_to_vendor: null,
        cost_to_company: null,
        exchange_rate: null,
        invoice_date: null,
        ppn: null,
        ppn_date: null,
        invoice_no: null,
        approved_by: null,
        approved_date: null,
        approval_status_2: null,
        approved_by_2: null,
        approved_date_2: null,
        remarks: null,
        status: null,
        created_by: null,
        created_date: null,
        updated_by: null,
        updated_date: null,
        approved_by_name: null,
        approved_by_name_2: null,
        payment_to_vendor_name: "",
        approval_status: "Pending",
        advance_adjustments: [],
        cps_contract_payments: [],
        barge_group_id: "",
        files: [],
        totalAdjustments: 0,
      },
      invoiceDialog: false,
      openAdvAdjustments: false,
      costing_type: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("507") ||
        features.includes("514") ||
        features.includes("515") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.get_barge_cps_costings(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({
                  new_barges: result[Object.keys(result)[l]],
                });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                cpc_costing_list: purchase_contracts_barges,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const barge_cps_costing_id = this.props.invoiceID;

    await api
      .get_barge_cps_costing(loginUserID, idToken, barge_cps_costing_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_cps_costing_data) {
                let total_payment_amount = 0;
                for (var i of res.barge_cps_costing_data
                  .cps_contract_payments) {
                  if (i.date_of_payment) {
                    total_payment_amount += Number(i.amount ? i.amount : 0);
                  }
                }

                let totalAdjustments = 0;
                for (var j of res.barge_cps_costing_data.advance_adjustments) {
                  totalAdjustments += Number(j.advance_adjustment_value);
                }
                let resCopy = {
                  ...res.barge_cps_costing_data,
                  payable_to_supplier:
                    parseFloat(res.barge_cps_costing_data.payable_to_vendor) -
                    parseFloat(totalAdjustments),
                  remaining_amount:
                    parseFloat(res.barge_cps_costing_data.payable_to_vendor) -
                    parseFloat(totalAdjustments) -
                    parseFloat(total_payment_amount),
                  total_payment_amount: total_payment_amount,
                };
                this.setState(
                  {
                    totalAdjustments: totalAdjustments,
                    bargeList: res.barge_cps_costing_data.barge_group_id
                      ? res.barge_cps_costing_data.barge_group_id.split(",")
                      : [],
                    costing_no: res.barge_cps_costing_data.costing_no,
                    payment_to_vendor_name:
                      res.barge_cps_costing_data.payment_to_vendor_name,
                    barge_cps_costing_data: resCopy,
                    files: res.barge_cps_costing_data.files
                      ? res.barge_cps_costing_data.files
                      : [],

                    isLoading: false,
                    openAdvAdjustments: false,
                    costing_type: res.barge_cps_costing_data.costing_type,
                  },

                  () => {
                    if (this.state.bargeList.length > 0) {
                      const barge_id = this.state.bargeList[0];

                      let request = {
                        login_user_id: this.Cookie.getCookie("loginUserId"),
                        idtoken: this.Cookie.getIdTokenCookie(),
                        barge_id: barge_id,
                        vendor_type: "Barge",
                      };
                      api
                        .get_business_no_info_for_barge(request)
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((res) => {
                              if (res.code === "200") {
                                this.setState({
                                  vendor_name: res.vendor_name,
                                  mv_name: res.mv_name,
                                });
                              }
                            });
                          }
                        });
                    }
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_cps_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      cps_contract_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Procurement Service Fees",
      payment_posting_type: "coal_procurement",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: this.props.invoiceID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "510",
      view_payment: "511",
      update_payment: "512",
      delete_payment: "513",
      approver: "515",
      approver2: "517",
    };

    return (
      <PaymentPostings
        payments={cps_contract_payments}
        costingType="Barge Coal Procurement Service"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.cpc_costing_list.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.props.invoiceID)
    );

    const {
      costing_no,
      currency_xchg_rate = 1,
      mv_name,
      barge_cps_costing_data: {
        payment_to_vendor_name = "",
        invoice_no = "",
        invoice_date = "",
        barge_quantity = "",
        vat_value = "",
        pph23_value = "",
        payable_to_vendor = 0,
        price_type = "Per MT",
        lumpsum_price = 0,
        base_price_pmt = 0,
        base_price = 0,
        advance_adjustments = [],
      } = {},
    } = this.state;

    const filter_advance = advance_adjustments.filter(
      (e) => e.advance_adjustment !== "No"
    );

    const advanceAdjustments = [];
    for (var i of filter_advance) {
      if (i.adjustment_from === "Purchase Contract") {
        advanceAdjustments.push({
          desc: `Adv deduction against contract: ${i.purchase_contract_no}`,
          qty: `${toLocaleString(
            i.advance_adjustment_barge_quantity
              ? i.advance_adjustment_barge_quantity
              : " "
          )}`,
          adjustedPrice: `${toLocaleString(
            i.adjustment_per_mt ? i.adjustment_per_mt : " "
          )}`,
          amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
        });
      } else if (
        i.adjustment_from === "Advance Contract" ||
        i.adjustment_from === "Down Payment"
      ) {
        advanceAdjustments.push({
          desc: `Adv deduction against contract: ${i.vendor_contract_no}`,
          qty: `${toLocaleString(
            i.advance_adjustment_barge_quantity
              ? i.advance_adjustment_barge_quantity
              : " "
          )}`,
          adjustedPrice: `${toLocaleString(
            i.adjustment_per_mt ? i.adjustment_per_mt : " "
          )}`,
          amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
        });
      }
    }
    const Grand_total = (
      parseFloat(payable_to_vendor ? payable_to_vendor : 1) -
      parseFloat(this.state.totalAdjustments ? this.state.totalAdjustments : 0)
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: payment_to_vendor_name,
      business_no: mv_name,
      costing_name: "Coal Procurement Service",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "CPS FOB Barge",
      description: [
        {
          qty: toLocaleString(barge_quantity),
          adjustedPrice:
            price_type === "Per MT"
              ? toLocaleString(base_price_pmt)
              : toLocaleString(lumpsum_price),
          desc:
            this.state.costing_type === `Coal Procurement Fees`
              ? `${
                  currency_xchg_rate > 1
                    ? `Coal Procurement @ ${currency_xchg_rate}`
                    : "Coal Procurement"
                } `
              : ` ${
                  currency_xchg_rate > 1
                    ? `Coal Service @ ${currency_xchg_rate}`
                    : "Coal Service"
                }`,
          amount: `${toLocaleString(base_price)}`,
        },
        ...advanceAdjustments,
        ...(this.state.pph15_account !== "SDAM" && !!vat_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(vat_value)}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) && !!pph23_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${toLocaleString(pph23_value)} )`,
              },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const costingData = this.state.barge_cps_costing_data;
    const costingID = this.props.invoiceID;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Coal Procurement Service Fee - ${this.state.costing_no}`}
                </h4>
              </div>
              <div className="col-lg-2">
                {costingData.approval_status === "Pending" &&
                  costingData.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !==
                    costingData.created_by &&
                  costingData.approved_by !==
                    this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("514") ||
                    this.state.features.includes("516") ||
                    this.state.features.includes("97")) && (
                    <BusinessApproveButton
                      costingID={costingID}
                      costingType={"Barge Coal Procurement Service"}
                      accessInfo={{
                        ...costingData,
                        approver_1: "514",
                        approver_2: "516",
                      }}
                      callbackFn={() => this.componentDidMount()}
                    />
                  )}
              </div>
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {this.state.cpc_costing_list.length > 0 && (
                <div className="row mb-3">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm mb-0">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Contract</th>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true"> Barge Nomination</th>
                          <th nowrap="true"> Business No </th>
                          <th nowrap="true"> Vendor </th>
                          <th nowrap="true" className="text-right">
                            Quantity
                          </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Price PMT
                          </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Total Payable{" "}
                          </th>
                          <th nowrap="true" className="text-right">
                            Paid{" "}
                          </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      {this.state.cpc_costing_list.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display: costingID === idr.id ? "" : "none",
                              }}
                            >
                              {indx === 0 && (
                                <td nowrap="true" rowSpan={p.new_barges.length}>
                                  {idr.cps_contract_no &&
                                  idr.cps_contract_id ? (
                                    <a
                                      rel="noopener noreferrer"
                                      href={
                                        "/view-cps-contract/" +
                                        btoa(idr.cps_contract_id)
                                      }
                                      target="_blank"
                                      style={{
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {idr.cps_contract_no}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              )}
                              <td>
                                <a
                                  href={"/view-barge/" + btoa(idr.barge_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {pad(idr.barge_id)}
                                </a>
                              </td>
                              <td nowrap="true">
                                {idr.barge_nomination
                                  ? idr.barge_nomination
                                  : "-"}
                              </td>
                              <td nowrap="true">
                                {idr.business_no ? idr.business_no : "-"}
                              </td>
                              <td nowrap="true">
                                {idr.payment_to_Vendor
                                  ? idr.payment_to_Vendor
                                  : "-"}
                              </td>
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.barge_quantity)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {idr.price_type === "Per MT"
                                    ? toLocaleString(idr.base_price_pmt)
                                    : toLocaleString(idr.lumpsum_price)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.total_payable)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.paid)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.Remaining_amount)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}

              {/* <div className="col-lg-12"> */}
              {/* New Changes */}
              <div className="table-responsive">
                <table className="table table-sm table-bordered mb-0">
                  <thead className="table-header">
                    <tr>
                      <th className="text-center">Description</th>
                      <th className="text-right">Qty</th>
                      <th className="text-right">Unit Price</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.costing_type === `Coal Procurement Fees`
                          ? `Coal Procurement`
                          : `Coal Service`}
                        <>
                          {(config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            this.state.purchase_currency !== "USD" &&
                            costingData.billing_currency !== "USD" && (
                              <>
                                {/* <div>
                                  Coal Price Per MT
                                </div>
                                <div>
                                  Billing Currency {costingData.billing_currency
                                    ? costingData.billing_currency
                                    : "-"}
                                </div> */}
                                {costingData.exchange_rate > 1 && (
                                  <div>
                                    Exchange Rate @
                                    {parseFloat(
                                      costingData.exchange_rate
                                    ).toFixed(2)}
                                  </div>
                                )}
                              </>
                            )}
                        </>
                      </td>
                      <td className="text-right">
                        {costingData.barge_quantity
                          ? toLocaleString(costingData.barge_quantity)
                          : ""}
                      </td>
                      <td className="text-right">
                        {toLocaleString(costingData.base_price_pmt)}
                        {/* {this.state.purchase_currency
                            ? this.state.purchase_currency
                            : config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL"
                              ? "USD"
                              : "IDR"} */}
                      </td>
                      <td className="text-right">
                        {toLocaleString(costingData.base_price)}
                        {/* {toLocaleString(costingData.lumpsum_price)} */}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={4}>
                        Advance Adjustments &nbsp;
                        <i
                          className="fa fa-plus-circle"
                          style={{
                            color: config.themeColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({ openAdvAdjustments: true });
                          }}
                        />
                      </th>
                    </tr>
                    {costingData.advance_adjustments
                      ?.filter((e) => e.advance_adjustment !== "No")
                      .map((item, idx) => (
                        <tr key={item.id}>
                          <td className="">
                            {`Adv deduction against contract:
                               ${
                                 item.adjustment_from === `Purchase Contract`
                                   ? item.purchase_contract_no
                                   : item.adjustment_from ===
                                       `Advance Contract` ||
                                     item.adjustment_from === `Down Payment`
                                   ? item.vendor_contract_no
                                   : `_`
                               }`}{" "}
                            &nbsp;
                            <i
                              className="fa fa-pencil"
                              style={{
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState((prevState) => ({
                                  openAdvAdjustments:
                                    !prevState.openAdvAdjustments,
                                }))
                              }
                            />
                          </td>
                          <td className="text-right ">
                            {item.advance_adjustment !== "Lumpsum"
                              ? toLocaleString(
                                  item.advance_adjustment_barge_quantity
                                )
                              : ""}
                          </td>
                          <td className="text-right ">
                            {item.advance_adjustment !== "Lumpsum"
                              ? toLocaleString(item.adjustment_per_mt)
                              : ""}
                          </td>
                          <td className="text-right ">
                            {`( ${toLocaleString(
                              item.advance_adjustment_value
                            )} )`}
                          </td>
                        </tr>
                      ))}
                    {(this.state.vat_applicability === "Inclusive" ||
                      config.company !== "SRPL" ||
                      config.company !== "PRPL" ||
                      config.company !== "AVS") &&
                      !!costingData.vat_value && (
                        <tr>
                          <td>VAT @ 10 %</td>
                          <td className="text-right"></td>
                          <td className="text-right"></td>
                          <td className="text-right">
                            {toLocaleString(costingData.vat_value)}
                          </td>
                        </tr>
                      )}
                    {!!parseFloat(costingData.pph23_value) &&
                      !(
                        config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                      ) && (
                        <>
                          <tr>
                            <td>
                              PPH4 @{toLocaleString(costingData.pph23_percent)}{" "}
                              %
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              ({toLocaleString(costingData.pph23_value)})
                            </td>
                          </tr>
                          {/* <tr>
                            <td>PPH4 Account{costingData.pph23_account
                              ? costingData.pph23_account
                              : "-"} </td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              {toLocaleString(costingData.pph23_value)}
                            </td>
                          </tr> */}
                        </>
                      )}
                    <tr>
                      <td
                        colSpan={3}
                        className="text-right "
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        Payable to Vendor :{" "}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-right"
                      >{`
                        ${
                          config.company === "SRPL" ||
                          config.company === "PRPL" ||
                          config.company === "AVS"
                            ? "USD"
                            : "IDR"
                        }
                        ${toLocaleString(
                          parseFloat(costingData.payable_to_supplier)
                        )} `}</td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        className="text-right "
                        style={{ fontWeight: "bold" }}
                      >
                        Paid to Vendor
                      </td>
                      <td style={{ fontWeight: "bold" }} className="text-right">
                        {config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                          ? " USD"
                          : " IDR"}{" "}
                        {toLocaleString(costingData.total_payment_amount)}
                      </td>
                    </tr>
                    <tr>
                      {costingData.remaining_amount ? (
                        <>
                          <td
                            colSpan={3}
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            Remaining Amount
                          </td>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-right "
                          >
                            {config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? " USD"
                              : " IDR"}{" "}
                            {toLocaleString(costingData.remaining_amount)}{" "}
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="row"> */}
              {/* <div className="col-lg-4 pl-0 mb-3">
                      <label className="contract_display_header_label">
                        Approval{" "}
                        {costingData.approval_status === "Approved"
                          ? "Approved By"
                          : ""}
                      </label>
                      <div className="contract_display_header_value">
                        {" "}
                        {costingData.approval_status === "Pending"
                          ? costingData.approval_status
                          : costingData.approved_by_name}
                      </div>
                    </div> */}
              {/* <div className="col-lg-4 pl-0 mb-3">
                      <label className="contract_display_header_label">
                        Approved On
                      </label>
                      <div className="contract_display_header_value">
                        {" "}
                        {costingData.approved_date
                          ? localDateFormate(costingData.approved_date)
                          : "-"}
                      </div>
                    </div> */}
              {/* </div> */}
              <div className="row mb-3">
                {/* <div className="col-lg-6 pl-0">
                    <div className="row">
                      <div className="col-lg-12 pl-0 mb-2">
                        <label className="contract_display_header_label mb-1">
                          Payment to
                        </label>
                        <div className="contract_display_header_value">
                          {costingData.payment_to_vendor_name
                            ? costingData.payment_to_vendor_name
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-6 pl-0 mb-2">
                        <label className="contract_display_header_label mb-1">
                          Quantity
                        </label>
                        <div className="contract_display_header_value">
                          {toLocaleString(costingData.barge_quantity)}
                        </div>
                      </div>
                      {costingData?.price_type === "Per MT" ? (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Base Price PMT
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(costingData.base_price_pmt)}
                            {this.state.purchase_currency
                              ? this.state.purchase_currency
                              : config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Lumpsum Price
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(costingData.lumpsum_price)}
                            {this.state.purchase_currency
                              ? this.state.purchase_currency
                              : config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                          </div>
                        </div>
                      )}

                      <div className="col-lg-6 pl-0 mb-2">
                        <label className="contract_display_header_label mb-1">
                          Billing Currency
                        </label>
                        <div className="contract_display_header_value">
                          {costingData.billing_currency
                            ? costingData.billing_currency
                            : "-"}
                        </div>
                      </div>
                      {!(
                        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                      ) &&
                        this.state.purchase_currency !== "IDR" &&
                        costingData.billing_currency !== "IDR" && (
                          <div className="col-lg-6 pl-0 mb-2">
                            <label className="contract_display_header_label mb-0">
                              Exchange Rate
                            </label>
                            <div className="contract_display_header_value">
                              {costingData.exchange_rate
                                ? toLocaleString(costingData.exchange_rate)
                                : "-"}
                            </div>
                          </div>
                        )}
                      {(config.company === "SRPL" || config.company === "AVS" ||
                        config.company === "PRPL") &&
                        costingData.billing_currency !== "USD" && (
                          <div className="col-lg-6 pl-0 mb-2">
                            <label className="contract_display_header_label mb-0">
                              Exchange Rate
                            </label>
                            <div className="contract_display_header_value">
                              {costingData.exchange_rate
                                ? toLocaleString(costingData.exchange_rate)
                                : "-"}
                            </div>
                          </div>
                        )}
                    </div>
                    {!(
                      config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                    ) && (
                        <div className="row">
                          <div className="col-lg-12 pl-0 mb-2">
                            <label className="contract_display_header_label mb-0">
                              PPH4 Account
                            </label>
                            <div className="contract_display_header_value">
                              {costingData.pph23_account
                                ? costingData.pph23_account
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )}
                  </div> */}
                {/* <div
                      className="col-lg-6 p-1 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Price</h6>
                      </div>
                      {(config.company === "SRPL" || config.company === "AVS" ||
                        config.company === "PRPL" ||
                        costingData.pph23_account === "Supplier") && (
                          <>
                            <div className="col-lg-12 pl-2 mb-2">
                              <label className="contract_display_header_label mb-0">
                                VAT Applicability
                              </label>
                              <div className="contract_display_header_value">
                                {costingData.vat_applicability
                                  ? costingData.vat_applicability
                                  : "-"}
                              </div>
                            </div>
                            {costingData.vat_applicability === "Yes" && (
                              <div className="col-lg-12 pl-2 mb-2">
                                <label className="contract_display_header_label mb-0">
                                  VAT Consideration
                                </label>
                                <div className="contract_display_header_value">
                                  {costingData.vat_type
                                    ? costingData.vat_type
                                    : "-"}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      <div className="row pl-2">
                        <div className="col-lg-4 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Base Price
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(costingData.base_price)}
                          </div>
                        </div>
                        {(config.company === "SRPL" || config.company === "AVS" ||
                          config.company === "PRPL" ||
                          costingData.pph23_account === "Supplier") && (
                            <div className="col-lg-4 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                VAT %
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(costingData.vat_percent)}
                              </div>
                            </div>
                          )}
                        {(config.company === "SRPL" || config.company === "AVS" ||
                          config.company === "PRPL" ||
                          costingData.pph23_account === "Supplier") && (
                            <div className="col-lg-4 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                VAT
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(costingData.vat_value)}
                              </div>
                            </div>
                          )}
                        {!(
                          config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                        ) && (
                            <div className="col-lg-6 p-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH4 %
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(costingData.pph23_percent)}
                              </div>
                            </div>
                          )}
                        {!(
                          config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                        ) && (
                            <div className="col-lg-6 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH4 Value
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(costingData.pph23_value)}
                              </div>
                            </div>
                          )}
                        <div className="col-lg-6 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Payable to Vendor
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(
                              parseFloat(costingData.payable_to_vendor)
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Cost to Comapny
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(costingData.cost_to_company)}
                            {config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div className="col-lg-6 p-0 mb-2">
                    <label className="contract_display_header_label mb-0">
                      Remarks
                    </label>
                    <div
                      style={{
                        textTransform: "uppercase",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {costingData.remarks}
                    </div>
                  </div> */}
                <div className="col-lg-12 p-0">
                  {/* <AdvanceAdjustmentsView
                          adjustments={costingData?.advance_adjustments}
                          vendorType="Coal"
                        /> */}
                  <AdvanceAdjustments
                    open={this.state.openAdvAdjustments}
                    adjustments={costingData?.advance_adjustments}
                    vendorType="Coal"
                    costing_id={costingID}
                    vendorName={costingData?.payment_to_vendor_name}
                    //payable_to_supplier={costingData?.payable_to_supplier}
                    table_name="barge_cps_costing_adv_adjustments"
                    //bargeID={this.state.bargeList[0]}
                    callbackFn={() => this.componentDidMount()}
                    closeModal={() =>
                      this.setState({ openAdvAdjustments: false })
                    }
                  />
                </div>

                <br />
                {(this.state.features.includes("511") ||
                  this.state.features.includes("97")) && (
                  <div className="col-lg-12 p-0">
                    <div className="card p-0 border mb-3">
                      <div className="card-body">
                        <InvoiceForm
                          open={this.state.invoiceDialog}
                          costingID={this.props.invoiceID}
                          data={costingData}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />

                        {/* <div className="row">
                              <div className="col-lg-4 mb-2">
                                <label className="contract_display_header_label mb-0">
                                  Paid to vendor
                                </label>
                                <div
                                  style={{
                                    textTransform: "uppercase",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {toLocaleString(
                                    costingData.total_payment_amount
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label className="contract_display_header_label mb-0">
                                  Remaining Amount
                                </label>
                                <div
                                  style={{
                                    textTransform: "uppercase",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {toLocaleString(costingData.remaining_amount)}
                                </div>
                              </div>
                            </div> */}
                        {this.renderPaymentPostings(costingData)}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 p-0">
                  <ViewFiles
                    {...this.props}
                    type="Costings"
                    invoice_file={costingData?.invoice_file}
                    files_data={this.state.files}
                  />
                </div>
              </div>
              {/* </div> */}

              <AuditInfo info={costingData} />
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            {this.props.onEdit && (
              <button
                type="button"
                className="drawer_button drawer_text"
                onClick={() => this.props.onEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      );
    }
  }
}
