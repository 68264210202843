import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "../common/CookieHandler";
import MaterialTable from "material-table";
import {
  Tabs,
  Tab,
  MenuItem,
  TextField,
  Radio,
  Snackbar,
  LinearProgress,
  Popover,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import PurchaseIcon from "../../images/icons/purchase_icon.svg";
import SalesIcon from "../../images/icons/sales_icon.svg";

import ChemicalSprayList from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-List";
import ExportTaxList from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-List";
import DocAdd from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-List";
import SacList from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-List";
import CoalProcurementChargesList from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-List";
import CPSList from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-List";
import PreShipmentInspectionList from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-List";
import BargeCostingList from "../Barge-Purchase-Financials/BargeCosting/BargeCostingList";

import CoalCostingPerformaList from "../Barge-Purchase-Financials/CoalCosting/CoalCostingPerformaList";
import CoalCostingCommercialList from "../Barge-Purchase-Financials/CoalCosting/CoalCostingCommercialList";
// Barge sales financials

import BargeCoalSalesChargesList from "../Barge-Sales-Financials/Coal-Sales-Charges/Barge_Coal_Sales_Charges_List";
// import Barge purchase financial demurrages

import BargeSupplierDemurrageList from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-List";
import BargeVendorDemurrageList from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-List";

import OtherExpenseList from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-List";

import exportToExcel from "../Export_To_Excel";
import InsuranceList from "../Barge-Purchase-Financials/Insurance/Insurance-List";

import ReturnCargoList from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoList";

import LaporanChargesList from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesList";
import { withStyles } from "@material-ui/core/styles";
import {
  localDateFormate,
  pad,
  progressBarSize,
  toLocaleString,
  _toBeDelivered,
} from "../common/common";
import Loader from "../common/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewRadio = withStyles({
  root: {
    color: config.themeColor,
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#42352d",
  },
}))(LinearProgress);

export default class Barge_List extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      barges: [],
      anchorEl: null,
      anchorEn: null,
      activeTabIndex: 0,
      fin_ActiveTabIndex: 0,
      coal_costing_tabs_index: 0,
      isLoading: true,
      activeStep: 0,
      features: [],
      check_business_no_for_cancel: false,
      business_no_filter_active_tab_index: 0,
      barge_status_filter_active_tab_index: 0,
      total_barges: [],
      sales_fin_active_tab_index: 0,
      sales_coal_costing_tabs_index: 0,
      barge_demurrage_tabs_index: 0,
      addQualityMenuOpen: false,
      viewMenuOpen: false,
      edit_pc: true,
      data: [],
      purchase_qualities: [],
      purchase_types: [],
      filter_selected_pc_qulaity: [],
      purchaseDataForMenu: [],
      bargeQuantityInMT: "",
      purchaseTypeSelection: null,
      purchaseQualitySelection: null,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('46')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // Get purchase contracts API
    let status = "";
    const pc_contracts = await api.getPurchaseContracts(
      loginUserID,
      status,
      idToken
    );
    if (pc_contracts.status >= 200 && pc_contracts.status < 300) {
      pc_contracts.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.purchase_contract) {
            let purchaseDataforMenu = [];
            const filteredPContracts = res.purchase_contract.filter(
              (e, indx) => (e.status !== "Closed" && e.status !== "cancelled")
            );
            for (let i = 0; i < filteredPContracts.length; i++) {
              purchaseDataforMenu.push({
                name: filteredPContracts[i].contract_no,
                value: filteredPContracts[i].id,
                key: i,
                vendor_name: filteredPContracts[i].vendor_name,
              });
            }

            this.setState({
              purchaseDataForMenu: purchaseDataforMenu,
              //purchaseContractList: res.purchase_contract,
              purchaseContractList: filteredPContracts,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    let financial_type = "barges";
    const get_barges = await api.get_barges(
      loginUserID,
      idToken,
      financial_type
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            for (var i of res.barges) {
              i.selected_pc = {
                name: i.purchase_contract_no,
                value: i.purchase_contract_id,
              };
            }
            this.setState(
              {
                total_barges: res.barges,
                barges: res.barges.filter(
                  (e) => e.business_no_id === null && e.status === "Active"
                ),
                isLoading: false,
              },
              () => this.bargeFiltersHandler()
            );
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    document.title = config.documentTitle + "Barge List";
  }

  TabsHandler = (event, value) => {
    this.setState({
      activeTabIndex: value,
    });
  };

  Financial_TabsHandler = (event, value) => {
    this.setState({
      fin_ActiveTabIndex: value,
    });
  };

  coal_costing_tabs_handler = (event, value) => {
    this.setState({
      coal_costing_tabs_index: value,
    });
  };

  handlePurchaseContract = (value) => {
    if (value) {
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "";
      var contractNo = value.name;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId =
        filteredContract.length > 0 ? filteredContract[0].pcid : null;

      if (purchaseContractId) {
        api
          .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                  if (res.purchase_contract) {
                    const pc_qualities = [
                      <MenuItem value="0" key={-1} disabled>
                        {" "}
                        Please Select
                      </MenuItem>,
                    ];
                    if (res.purchase_contract.qualities.length > 0) {
                      for (var i of res.purchase_contract.qualities) {
                        pc_qualities.push(
                          <MenuItem value={i.id} key={i.id}>
                            {i.quality}
                          </MenuItem>
                        );
                      }
                    }
                    this.setState(
                      {
                        purchase_qualities: res.purchase_contract.qualities,
                        pc_qualities: pc_qualities,
                        pc_qualities_id: "0",
                        purchase_type: null,
                        procurement_quality_standard: null,
                        pc_purchase_type_id: null,
                        isLoading: false,
                      },
                      () => {
                        window.$("#myModal").modal({
                          backdrop: "static",
                          keyboard: true,
                          show: true,
                        });
                      }
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        alert("Invaild purchase contract");
      }
    }
  };

  cancelBargeHandler = () => {
    this.setState({ isLoading: true, viewMenuOpen: false });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var barge_id = this.state.bargeID;
    api.cancel_barge(loginUserID, idToken, barge_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState({
              isLoading: false,
              snackBarSuccessOpen: true,
              successMsg: res.message,
            });
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  bargeFiltersHandler = () => {
    var data = this.state.total_barges;
    if (
      this.state.business_no_filter_active_tab_index === 0 &&
      this.state.barge_status_filter_active_tab_index === 0
    ) {
      this.setState({
        barges: data.filter(
          (e) => e.business_no_id === null && e.status === "Active"
        ),
      });
    }
    if (
      this.state.business_no_filter_active_tab_index === 0 &&
      this.state.barge_status_filter_active_tab_index === 1
    ) {
      this.setState({
        barges: data.filter(
          (e) => e.business_no_id === null && e.status === "Cancelled"
        ),
      });
    }
    if (
      this.state.business_no_filter_active_tab_index === 0 &&
      this.state.barge_status_filter_active_tab_index === 2
    ) {
      this.setState({ barges: data.filter((e) => e.business_no_id === null) });
    }
    if (
      this.state.business_no_filter_active_tab_index === 1 &&
      this.state.barge_status_filter_active_tab_index === 0
    ) {
      this.setState({
        barges: data.filter(
          (e) => e.business_no_id !== null && e.status === "Active"
        ),
      });
    }
    if (
      this.state.business_no_filter_active_tab_index === 1 &&
      this.state.barge_status_filter_active_tab_index === 1
    ) {
      this.setState({
        barges: data.filter(
          (e) => e.business_no_id !== null && e.status === "Cancelled"
        ),
      });
    }
    if (
      this.state.business_no_filter_active_tab_index === 1 &&
      this.state.barge_status_filter_active_tab_index === 2
    ) {
      this.setState({ barges: data.filter((e) => e.business_no_id !== null) });
    }
    if (
      this.state.business_no_filter_active_tab_index === 2 &&
      this.state.barge_status_filter_active_tab_index === 0
    ) {
      this.setState({ barges: data.filter((e) => e.status === "Active") });
    }
    if (
      this.state.business_no_filter_active_tab_index === 2 &&
      this.state.barge_status_filter_active_tab_index === 1
    ) {
      this.setState({ barges: data.filter((e) => e.status === "Cancelled") });
    }
    if (
      this.state.business_no_filter_active_tab_index === 2 &&
      this.state.barge_status_filter_active_tab_index === 2
    ) {
      this.setState({ barges: data });
    }
  };

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        // "Barge ID": obj.bargeID,
        "Barge Nomination": obj.barge_nomination,
        Jetty: obj.loading_jetty,
        "Purchase Contract": obj.purchase_contract_no,
        "Business No": obj.business_no,
        Supplier: obj.loading_jetty,
        Mine: obj.mine_name,
        "Purchase Quality": obj.purchase_quality,
        "Barge Quantity": obj.barge_quantity_in_mt,
        "Redraft Quantity": obj.redraft_quantity,
        "Barge Status": obj.barge_status,
        "Supplier Surveyor": obj.supplier_surveyor_name,
        "config.company +  Surveyor": obj.internal_surveyor_name,
        "Supplier Barge Window": obj.supplier_barge_window,
        "Buyer Barge Window": obj.buyer_barge_window,
        "Loading Start Date at Jetty": obj.loading_date,
        "Loading completion time at Jetty": obj.completion_time,
        "NOR Anchorage": obj.nor_anchorage,
        "Commence Discharging": obj.commence_discharge,
        "Completed Discharging": obj.completion_discharge,
        Status: obj.status,
      };
    });
    exportToExcel(Modified, filename);
  };

  updatePcHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let {
      barge_id,
      purchase_contract_id,
      pc_qualities_id,
      pc_purchase_type_id,
      procurement_quality_standard,
    } = this.state;
    if (
      barge_id !== "" &&
      purchase_contract_id !== "" &&
      pc_qualities_id !== "" &&
      pc_purchase_type_id !== ""
    ) {
      api
        .update_purchase_contract_data(
          loginUserID,
          idToken,
          barge_id,
          purchase_contract_id,
          pc_qualities_id,
          pc_purchase_type_id,
          procurement_quality_standard
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                let barge = this.state.barges;
                for (var i = 0; i < barge.length; i++) {
                  if (barge[i].id === barge_id) {
                    barge[i].edit_pc = false;
                  }
                }
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    barges: barge,
                  },
                  () => {
                    this.componentDidMount();
                    window.$("#myModal").modal("hide");
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill Mandatory fileds",
      });
    }
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />

          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Barges{" "}
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.barges.length}
                  </span>
                </h4>
              </div>
              {(this.state.features.includes("45") ||
                this.state.features.includes("97")) && (
                <div className="col text-right float-sm-left">
                  <a href="/add-barge">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                    >
                      New Barge
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="container p-2">
            {this.state.isLoading && <Loader />}
            {!this.state.isLoading && (
              <div className="card p-0" style={{ overflowY: "scroll" }}>
                <div className="row border-bottom">
                  <Tabs
                    value={this.state.activeTabIndex}
                    onChange={this.TabsHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: { background: config.themeColor },
                    }}
                  >
                    {(this.state.features.includes("46") ||
                      this.state.features.includes("97")) && (
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.activeTabIndex === 0
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Operational
                          </h4>
                        }
                        value={0}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                    )}
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.activeTabIndex === 1
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Purchase Financial
                        </h4>
                      }
                      value={1}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.activeTabIndex === 2
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Sales Financial
                        </h4>
                      }
                      value={2}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                  </Tabs>

                  {this.state.activeTabIndex === 0 && (
                    <div className="col text-right">
                      <a href="/purchase-quality-results" className="link">
                        <button
                          className="header_button header_button_text add_button_adjustment"
                          style={{ width: "100px", margin: "5px", top: "4px" }}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   this.setState({
                          //     addQualityMenuOpen: true,
                          //     anchorEn: e.currentTarget,
                          //   });
                          //   // props.action.onClick(e, props.data)
                          // }}
                        >
                          Add Quality
                        </button>
                      </a>
                    </div>
                  )}
                </div>

                {this.state.activeTabIndex === 0 && (
                  <div className="row mt-1">
                    <MaterialTable
                      title={
                        <div className="row">
                          <div className="col-lg-12">
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    business_no_filter_active_tab_index: 0,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .business_no_filter_active_tab_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .business_no_filter_active_tab_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Without Business No
                            </Button>
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    business_no_filter_active_tab_index: 1,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .business_no_filter_active_tab_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .business_no_filter_active_tab_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              With Business No
                            </Button>
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    business_no_filter_active_tab_index: 2,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .business_no_filter_active_tab_index === 2
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .business_no_filter_active_tab_index === 2
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              All
                            </Button>
                          </div>
                          <div className="col-lg-12">
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    barge_status_filter_active_tab_index: 0,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .barge_status_filter_active_tab_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .barge_status_filter_active_tab_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Active
                            </Button>
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    barge_status_filter_active_tab_index: 1,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .barge_status_filter_active_tab_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .barge_status_filter_active_tab_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Cancelled
                            </Button>
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    barge_status_filter_active_tab_index: 2,
                                  },
                                  () => this.bargeFiltersHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .barge_status_filter_active_tab_index === 2
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .barge_status_filter_active_tab_index === 2
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              All
                            </Button>
                          </div>
                        </div>
                      }
                      columns={[
                        {
                          title: "Barge ID",
                          field: "id",
                          width: 120,
                          render: (row) => {
                            return (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: config.themeColor,
                                }}
                                href={"/view-barge/" + btoa(row.id)}
                              >
                                {pad(row.id)}
                              </a>
                            );
                          },
                        },
                        {
                          title: "Barge Nomination",
                          field: "barge_nomination",
                          width: 200,
                          headerStyle: {
                            minWidth: 190,
                          },
                        },
                        {
                          title: "Jetty",
                          field: "loading_jetty",
                          width: 100,
                          headerStyle: {
                            minWidth: 150,
                          },
                        },

                        {
                          title: "Purchase Contract",
                          field: "purchase_contract_no",
                          width: 300,
                          headerStyle: {
                            minWidth: 200,
                          },
                          render: (row) => {
                            return (
                              <div>
                                {row.edit_pc && row.status !== "Cancelled" ? (
                                  <div className="row">
                                    <div className="col-lg-10">
                                      <Autocomplete
                                        options={this.state.purchaseDataForMenu}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {
                                          if (value) {
                                            this.setState(
                                              {
                                                purchase_contract_id:
                                                  value.value,
                                                purchase_contract_no:
                                                  value.name,
                                                pur_contract_drop_value: {
                                                  name: value.name,
                                                  value: value.value,
                                                },
                                                barge_id: row.id,
                                              },
                                              () =>
                                                this.handlePurchaseContract(
                                                  value
                                                )
                                            );
                                          } else {
                                            this.setState({
                                              purchase_contract_id: "",
                                              purchase_contract_no: "",
                                              pc_qualities_id: "0",
                                              purchase_type: "",
                                              procurement_quality_standard:
                                                null,
                                              pur_contract_drop_value: {
                                                name: "",
                                                value: "",
                                              },
                                              barge_quantity_error_msg: "",
                                              barge_planned_quantity_error_msg:
                                                "",
                                              barge_redfraft_quantity_error_msg:
                                                "",
                                              total_barge_quantity: "",
                                              pc_total_quantity: "",
                                              purchaseQualitySelection: null,
                                              purchaseTypeSelection: null,
                                            });
                                          }
                                        }}
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        size="small"
                                        name="purchaseContract"
                                        value={row.selected_pc}
                                        renderOption={(option) => (
                                          <React.Fragment>
                                            {option.name} ({option.vendor_name})
                                          </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Purchase Contract"
                                            variant="outlined"
                                            fullWidth
                                            style={{ top: 8 }}
                                          />
                                        )}
                                        sryle={{ display: "contents" }}
                                      />
                                    </div>

                                    <i
                                      className="fa fa-times fa-3x edit_icon m-auto "
                                      style={{
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let total_barges = this.state.barges;
                                        for (
                                          var i = 0;
                                          i < total_barges.length;
                                          i++
                                        ) {
                                          if (total_barges[i].id === row.id) {
                                            total_barges[i].edit_pc = false;
                                          }
                                        }
                                        this.setState({
                                          barge_id: row.id,
                                          barges: total_barges,
                                          bargeQuantityInMT:
                                            row.barge_quantity_in_mt,
                                        });
                                      }}
                                    ></i>
                                  </div>
                                ) : (
                                  <div>
                                    {row.purchase_contract_id ? (
                                      <a
                                        href={
                                          "/view-purchase-contract/" +
                                          btoa(row.purchase_contract_id)
                                        }
                                        rel="noopener noreferrer"
                                        role="button"
                                        style={{ color: config.themeColor }}
                                      >
                                        {row.purchase_contract_no}
                                      </a>
                                    ) : (
                                      <span>{row.purchase_contract_no}</span>
                                    )}
                                    {this.state.edit_pc &&
                                      row.status !== "Cancelled" && (
                                        <i
                                          className="fa fa-pencil edit_icon"
                                          style={{
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          title="Update Sales Contract"
                                          data-placement="bottom"
                                          data-keyboard="false"
                                          onClick={() => {
                                            let total_barges =
                                              this.state.barges;
                                            for (
                                              var i = 0;
                                              i < total_barges.length;
                                              i++
                                            ) {
                                              if (
                                                total_barges[i].id === row.id
                                              ) {
                                                total_barges[i].edit_pc = true;
                                              }
                                            }
                                            this.setState({
                                              barge_id: row.id,
                                              barges: total_barges,
                                              bargeQuantityInMT:
                                                row.barge_quantity_in_mt,
                                            });
                                          }}
                                        ></i>
                                      )}
                                  </div>
                                )}
                              </div>
                            );
                          },
                        },
                        {
                          title: "Business No",
                          field: "business_no",
                          //defaultSort: "asc",
                          // hidden: this.state.business_no_filter_active_tab_index === 0 ? true : false,
                          width: 200,
                          headerStyle: {
                            minWidth: 165,
                          },
                        },
                        {
                          title: "Supplier",
                          field: "supplier_name",
                          width: 200,
                          headerStyle: {
                            minWidth: 170,
                          },
                        },
                        {
                          title: "Mine",
                          field: "mine_name",
                          width: 150,
                          headerStyle: {
                            minWidth: 150,
                          },
                        },
                        {
                          title: "Purchase Quality",
                          field: "purchase_quality",
                          width: 200,
                          headerStyle: {
                            minWidth: 190,
                          },
                        },
                        {
                          title: "Barge Quantity",
                          field: "barge_quantity_in_mt",
                          width: 200,
                          render: (row) => {
                            return toLocaleString(row.barge_quantity_in_mt);
                          },
                          headerStyle: {
                            minWidth: 170,
                          },
                        },
                        {
                          title: "Return Cargo Quantity",
                          field: "return_cargo_quantity_in_mt",
                          width: 250,
                          render: (row) => {
                            return toLocaleString(
                              row.return_cargo_quantity_in_mt
                            );
                          },
                          headerStyle: {
                            minWidth: 250,
                          },
                        },
                        {
                          title: "Redraft Quantity",
                          field: "redraft_quantity",
                          width: 200,
                          render: (row) => {
                            return toLocaleString(row.redraft_quantity);
                          },
                          headerStyle: {
                            minWidth: 200,
                          },
                        },
                        {
                          title: "Barge Status",
                          field: "barge_status",
                          width: 200,
                          headerStyle: {
                            minWidth: 170,
                          },
                        },
                        {
                          title: "Supplier Surveyor",
                          field: "supplier_surveyor_name",
                          width: 200,
                          headerStyle: {
                            minWidth: 200,
                          },
                        },
                        {
                          title: config.company + " Surveyor",
                          field: "internal_surveyor_name",
                          width: 200,
                          headerStyle: {
                            minWidth: 200,
                          },
                        },
                        {
                          title: "Supplier Barge Window",
                          field: "supplier_barge_window",
                          width: 250,
                          headerStyle: {
                            minWidth: 250,
                          },
                        },
                        {
                          title: "Buyer Barge Window",
                          field: "buyer_barge_window",
                          width: 250,
                          headerStyle: {
                            minWidth: 250,
                          },
                        },
                        {
                          title: "Loading Start Date at Jetty",
                          field: "loading_date",
                          width: 300,
                          render: (row) => {
                            return localDateFormate(row.loading_date);
                          },
                          headerStyle: {
                            minWidth: 300,
                          },
                        },
                        {
                          title: "Loading completion time at Jetty",
                          field: "completion_time",
                          width: 300,
                          render: (row) => {
                            return localDateFormate(row.completion_time);
                          },
                          headerStyle: {
                            minWidth: 300,
                          },
                        },
                        {
                          title: "NOR Anchorage",
                          field: "nor_anchorage",
                          width: 200,
                          render: (row) => {
                            return localDateFormate(row.nor_anchorage);
                          },
                          headerStyle: {
                            minWidth: 200,
                          },
                        },
                        {
                          title: "Status",
                          field: "status",
                          render: (row) => {
                            return (
                              <span>
                                {row.status === "Active" ? (
                                  <span className="active_status">
                                    <i
                                      className="fa fa-circle dot_align"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {row.status}
                                  </span>
                                ) : (
                                  <span className="cancel_status">
                                    <i
                                      className="fa fa-circle dot_align"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {row.status}
                                  </span>
                                )}
                              </span>
                            );
                          },
                          width: 120,
                        },
                        {
                          title: "Actions",
                          field: "id",
                          width: 100,
                          render: (row) => {
                            return (
                              <MoreHorizIcon
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  this.setState({
                                    viewMenuOpen: true,
                                    bargeID: row.id,
                                    barge_status: row.status,
                                    check_business_no_for_cancel:
                                      row.business_no === null &&
                                      row.status === "Active" &&
                                      !row.costing_availability
                                        ? true
                                        : false,
                                    anchorEl: event.currentTarget,
                                  });
                                }}
                              />
                            );
                          },
                        },
                      ]}
                      data={this.state.barges}
                      isLoading={this.state.loading}
                      style={{
                        minHeight: 180,
                        fontFamily: "Poppins",
                        overflowY: "auto",
                        boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                      }}
                      options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                        headerStyle: {
                          backgroundColor: "#f9f9f9",
                          maxWidth: 200,
                          fontFamily: "Poppins",
                          fontSize: 14,
                          fontWeight: 400,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "0.65",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#42352d",
                        },
                        cellStyle: {
                          fontFamily: "Poppins",
                        },
                        //exportAllData: true,
                        //exportButton: true,
                        draggable: false,
                        exportFileName: "Barge",
                        actionsColumnIndex: -1,
                        fixedColumns: {
                          left: 1,
                          right: 1,
                        },
                        // tableLayout: 'fixed'
                      }}
                      actions={[
                        {
                          icon: "download",
                          tooltip: "Download",
                          isFreeAction: true,
                          onClick: (event) =>
                            this.convertExcelHandler(
                              this.state.barges,
                              "Barges"
                            ),
                        },
                      ]}
                    />
                  </div>
                )}

                {this.state.activeTabIndex === 1 && (
                  <div className="row pl-3 pr-3 border-bottom">
                    {(this.state.features.includes("126") ||
                      this.state.features.includes("127") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 0,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          margin: "5px 0px",
                          padding: "5px 20px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 0
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 0
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Coal Costing
                      </Button>
                    )}
                    {(this.state.features.includes("134") ||
                      this.state.features.includes("135") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 1,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          margin: "5px 0px",
                          padding: "5px 20px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 1
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 1
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Barge Costing
                      </Button>
                    )}
                    <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState({
                          fin_ActiveTabIndex: 7,
                        });
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.fin_ActiveTabIndex === 7
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.fin_ActiveTabIndex === 7
                            ? "#fff7f2"
                            : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Chemical Spray
                    </Button>

                    {(this.state.features.includes("174") ||
                      this.state.features.includes("175") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 6,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 6
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 6
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Export Tax
                      </Button>
                    )}
                    {(this.state.features.includes("274") ||
                      this.state.features.includes("275") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 5,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 5
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 5
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Documentation Charges
                      </Button>
                    )}
                    {(this.state.features.includes("274") ||
                      this.state.features.includes("275") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 13,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 13
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 13
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Laporan Charges
                      </Button>
                    )}
                    {(this.state.features.includes("158") ||
                      this.state.features.includes("159") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 3,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 3
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 3
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Sampling & Analysis Charges
                      </Button>
                    )}
                    {(this.state.features.includes("166") ||
                      this.state.features.includes("167") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 4,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 4
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 4
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Pre Shipment Inspection
                      </Button>
                    )}
                    {(this.state.features.includes("142") ||
                      this.state.features.includes("143") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 2,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 2
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 2
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Coal Procurement Charges
                      </Button>
                    )}
                    {(this.state.features.includes("142") ||
                      this.state.features.includes("143") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 11,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 11
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 11
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Coal Procurement Service Fees
                      </Button>
                    )}
                    {(this.state.features.includes("142") ||
                      this.state.features.includes("143") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 8,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 8
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 8
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Demurrage
                      </Button>
                    )}

                    {(this.state.features.includes("338") ||
                      this.state.features.includes("339") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 9,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 9
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 9
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Other Expenses
                      </Button>
                    )}
                    {(this.state.features.includes("346") ||
                      this.state.features.includes("347") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 10,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 10
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 10
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Insurance
                      </Button>
                    )}
                    {(this.state.features.includes("537") ||
                      this.state.features.includes("538") ||
                      this.state.features.includes("97")) && (
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            fin_ActiveTabIndex: 12,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "5px 20px",
                          margin: "5px 0px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.fin_ActiveTabIndex === 12
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.fin_ActiveTabIndex === 12
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Return Cargo Charges
                      </Button>
                    )}
                  </div>
                )}

                {this.state.activeTabIndex === 1 && (
                  <div>
                    {this.state.fin_ActiveTabIndex === 0 && (
                      <div>
                        <div className="pl-3 pr-3">
                          {(this.state.features.includes("126") ||
                            this.state.features.includes("127") ||
                            this.state.features.includes("97")) && (
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  coal_costing_tabs_index: 0,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.coal_costing_tabs_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.coal_costing_tabs_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Performa
                            </Button>
                          )}
                          {(this.state.features.includes("126") ||
                            this.state.features.includes("127") ||
                            this.state.features.includes("97")) && (
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  coal_costing_tabs_index: 1,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.coal_costing_tabs_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.coal_costing_tabs_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Commercial
                            </Button>
                          )}
                        </div>
                        {(this.state.features.includes("126") ||
                          this.state.features.includes("127") ||
                          this.state.features.includes("97")) &&
                          this.state.coal_costing_tabs_index === 0 && (
                            <CoalCostingPerformaList />
                          )}
                        {(this.state.features.includes("126") ||
                          this.state.features.includes("127") ||
                          this.state.features.includes("97")) &&
                          this.state.coal_costing_tabs_index === 1 && (
                            <CoalCostingCommercialList />
                          )}
                      </div>
                    )}

                    {this.state.fin_ActiveTabIndex === 8 && (
                      <div>
                        <div className="pl-3 pr-3">
                          {(this.state.features.includes("126") ||
                            this.state.features.includes("127") ||
                            this.state.features.includes("97")) && (
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  barge_demurrage_tabs_index: 0,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.barge_demurrage_tabs_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.barge_demurrage_tabs_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Barge Vendor
                            </Button>
                          )}
                          {(this.state.features.includes("126") ||
                            this.state.features.includes("127") ||
                            this.state.features.includes("97")) && (
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  barge_demurrage_tabs_index: 1,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.barge_demurrage_tabs_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.barge_demurrage_tabs_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Supplier
                            </Button>
                          )}
                        </div>
                        {/* {((this.state.features.includes('126')  || this.state.features.includes('127')  || this.state.features.includes('97') ) && this.state.barge_demurrage_tabs_index === 0) &&
                          <CoalCostingPerformaList />
                        } */}
                        {(this.state.features.includes("52") ||
                          this.state.features.includes("150") ||
                          this.state.features.includes("97")) &&
                          this.state.barge_demurrage_tabs_index === 0 && (
                            <BargeVendorDemurrageList />
                          )}
                        {(this.state.features.includes("52") ||
                          this.state.features.includes("150") ||
                          this.state.features.includes("97")) &&
                          this.state.barge_demurrage_tabs_index === 1 && (
                            <BargeSupplierDemurrageList />
                          )}
                      </div>
                    )}

                    {(this.state.features.includes("134") ||
                      this.state.features.includes("135") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 1 && (
                        <BargeCostingList />
                      )}

                    {(this.state.features.includes("142") ||
                      this.state.features.includes("143") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 2 && (
                        <CoalProcurementChargesList />
                      )}
                    {(this.state.features.includes("142") ||
                      this.state.features.includes("143") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 11 && <CPSList />}

                    {(this.state.features.includes("158") ||
                      this.state.features.includes("159") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 3 && <SacList />}
                    {(this.state.features.includes("166") ||
                      this.state.features.includes("167") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 4 && (
                        <PreShipmentInspectionList />
                      )}
                    {(this.state.features.includes("274") ||
                      this.state.features.includes("275") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 5 && <DocAdd />}
                    {(this.state.features.includes("174") ||
                      this.state.features.includes("175") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 6 && <ExportTaxList />}

                    {this.state.fin_ActiveTabIndex === 7 && (
                      <ChemicalSprayList />
                    )}
                    {(this.state.features.includes("338") ||
                      this.state.features.includes("339") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 9 && (
                        <OtherExpenseList />
                      )}
                    {(this.state.features.includes("346") ||
                      this.state.features.includes("347") ||
                      this.state.features.includes("97")) &&
                      this.state.fin_ActiveTabIndex === 10 && <InsuranceList />}

                    {this.state.fin_ActiveTabIndex === 12 && (
                      <ReturnCargoList />
                    )}

                    {this.state.fin_ActiveTabIndex === 13 && (
                      <LaporanChargesList />
                    )}
                  </div>
                )}

                {this.state.activeTabIndex === 2 && (
                  <div>
                    <div className="row pl-3 pr-3 border-bottom">
                      <Button
                        className="tab_button"
                        onClick={(e, value) => {
                          this.setState({
                            sales_fin_active_tab_index: 1,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          margin: "5px 0px",
                          padding: "5px 20px",
                          borderRadius: 35,
                          height: 30,
                          color:
                            this.state.sales_fin_active_tab_index === 1
                              ? config.themeColor
                              : "#483b34b3",
                          backgroundColor:
                            this.state.sales_fin_active_tab_index === 1
                              ? "#fff7f2"
                              : "#fff",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Coal Sales Charges
                      </Button>
                    </div>

                    {this.state.sales_fin_active_tab_index === 1 && (
                      <BargeCoalSalesChargesList />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <Popover
          open={this.state.viewMenuOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetorigin={{ horizontal: "left", vertical: "top" }}
          onClose={() => this.setState({ viewMenuOpen: false })}
        >
          <div className="card border">
            {this.state.barge_status === "Active" && (
              <MenuItem className="border-bottom">
                <a
                  href={"/update-barge/" + btoa(this.state.bargeID)}
                  rel="noopener noreferrer"
                  role="button"
                >
                  Edit Barge
                </a>
              </MenuItem>
            )}
            {this.state.check_business_no_for_cancel && (
              <MenuItem
                className="border-bottom text-primary"
                onClick={() => this.cancelBargeHandler()}
              >
                Cancel Barge
              </MenuItem>
            )}
            {(this.state.features.includes("56") ||
              this.state.features.includes("97")) && (
              <MenuItem className="border-bottom">
                <a
                  href={
                    "/view-purchase-quality-results/" + btoa(this.state.bargeID)
                  }
                  rel="noopener noreferrer"
                  role="button"
                >
                  Purchase Quality Results
                </a>
              </MenuItem>
            )}
          </div>
        </Popover>
        <Dialog
          onClose={() =>
            this.setState({ addQualityMenuOpen: false, anchorEn: null })
          }
          open={this.state.addQualityMenuOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ width: "450px" }}>
            <span className="popup_title">Select quality result</span>
          </DialogTitle>
          <DialogContent style={{ margin: "10px auto 25px" }}>
            <div style={{ flexDirection: "row", display: "inline-flex" }}>
              <a href="/purchase-quality-results" className="link">
                <Paper className="popup_block">
                  <img
                    src={PurchaseIcon}
                    width="64px"
                    alt="Purchase Icon"
                    className="popup_block_image"
                  />
                  <span className="popup_name">Purchase</span>
                </Paper>
              </a>
              <a href="/sales-quality-results">
                <Paper className="popup_block">
                  <img
                    src={SalesIcon}
                    width="64px"
                    alt="Sales Icon"
                    className="popup_block_image"
                  />
                  <span className="popup_name">Sales</span>
                </Paper>
              </a>
            </div>
          </DialogContent>
        </Dialog>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h4
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  Quality Selection
                </h4>
                {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
              </div>
              <div className="modal-body">
                <div className="card">
                  <label className="form_label mb-2">
                    Select Purchase Quality
                  </label>
                  <div className="row col-lg-12 p-0">
                    {this.state.purchase_qualities.map((e, index) => (
                      <div className="col col-lg-6 p-1 mb-2" key={index}>
                        <div
                          className="row col-lg-12 rounded p-0"
                          style={{
                            border:
                              e.id === this.state.purchaseQualitySelection
                                ? "1px solid #ec7833"
                                : "1px solid #dee2e6",
                          }}
                        >
                          <div className="col-lg-1 p-0">
                            <NewRadio
                              checked={
                                e.id === this.state.purchaseQualitySelection
                              }
                              onChange={(e) => {
                                var row = this.state.purchase_qualities;
                                this.setState(
                                  {
                                    purchaseQualitySelection: e.target.value,
                                    purchase_contract_id:
                                      row[index].purchase_contract_id,
                                    pc_qualities_id: row[index].id,
                                    pc_quality: row[index].quality,
                                    procurement_quality_standard:
                                      row[index].purchasing_term === "Specs" &&
                                      row[index].specification_standard ===
                                        "ASTM/ISO"
                                        ? "0"
                                        : row[index].purchasing_term ===
                                          "Non Specs"
                                        ? row[index].purchasing_term
                                        : row[index].specification_standard,
                                  },
                                  () => {
                                    const loginUserID =
                                      this.Cookie.getCookie("loginUserId");
                                    const idToken =
                                      this.Cookie.getIdTokenCookie();
                                    api
                                      .get_barges_qty_by_purchase_contract(
                                        loginUserID,
                                        idToken,
                                        row[index].purchase_contract_id,
                                        row[index].id
                                      )
                                      .then((response) => {
                                        if (
                                          response.status >= 200 &&
                                          response.status < 300
                                        ) {
                                          response.json().then((res) => {
                                            if (res.code === "200") {
                                              this.setState(
                                                {
                                                  total_barge_quantity:
                                                    res.total_barge_quantity,
                                                },
                                                () => {
                                                  // calculating purchase quality - quantity (including tolerance);
                                                  if (
                                                    row[index].id ===
                                                    this.state
                                                      .purchaseQualitySelection
                                                  ) {
                                                    let pc_tolerance_quantity =
                                                      (Number(
                                                        row[index]
                                                          .quantity_adjusted
                                                      ) *
                                                        Number(
                                                          row[index]
                                                            .tolerance_adjusted
                                                        )) /
                                                      100;
                                                    let pc_total_quantity =
                                                      Number(
                                                        row[index]
                                                          .quantity_adjusted
                                                      ) +
                                                      Number(
                                                        pc_tolerance_quantity
                                                      );

                                                    if (
                                                      Number(
                                                        pc_total_quantity
                                                      ) <
                                                      Number(
                                                        this.state
                                                          .bargeQuantityInMT
                                                      ) +
                                                        Number(
                                                          res.total_barge_quantity
                                                        )
                                                    ) {
                                                      this.setState({
                                                        barge_quantity_error: true,
                                                        barge_quantity_error_msg:
                                                          "Barge quantity is exceeding the purchase contract quantity with tolerance",
                                                      });
                                                    } else {
                                                      this.setState({
                                                        barge_quantity_error: false,
                                                        barge_quantity_error_msg:
                                                          "",
                                                      });
                                                    }
                                                    this.setState({
                                                      pc_total_quantity:
                                                        pc_total_quantity,
                                                    });
                                                  }
                                                }
                                              );
                                            } else if (res.code === "607") {
                                              window.location.href = "/logout";
                                            }
                                          });
                                        }
                                      });
                                  }
                                );
                              }}
                              value={e.id}
                              name="purchaseQualitySelection"
                              inputProps={{ "aria-label": "B" }}
                              className="collapsed"
                              data-toggle="collapse"
                              data-target={"#demo1" + e.id}
                              aria-expanded="false"
                              aria-controls={"group-of-rows-1"}
                            />
                          </div>
                          <div className="col-lg-11 pr-0">
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quality
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.quality}
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_label mb-0">
                                  Tolerence
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.tolerance_adjusted + "%"}
                                </label>
                              </div>
                            </div>
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quantity
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_total mb-0">
                                  Total
                                </label>
                                <label className="drawer_section_total_value mb-0">
                                  {toLocaleString(
                                    Number(e.quantity_adjusted).toFixed(3)
                                  )}
                                </label>
                              </div>
                            </div>
                            <div style={{ width: "98%" }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={progressBarSize(e, "NPC")}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                            <div className="row col-lg-12 p-0 mb-2">
                              <div className="col-lg pl-0">
                                <label className="drawer_section_value mb-0">
                                  {toLocaleString(
                                    Number(e.barge_delivered_quantity).toFixed(
                                      3
                                    )
                                  )}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Delivered
                                </label>
                              </div>
                              <div className="col-lg pr-0">
                                <label className="drawer_section_value mb-0">
                                  {toLocaleString(_toBeDelivered(e))}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Remaining
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="pl-0">
                    {this.state.purchase_qualities.map((e, index) => (
                      <div key={index}>
                        {e.id === this.state.purchaseQualitySelection && (
                          <div>
                            <div className="row">
                              <div className="col-lg-3 form_label label-text pl-0">
                                Quality Standard{" "}
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div className="label-text">:</div>
                              <div className="col-lg-5">
                                {e.purchasing_term === "Specs" &&
                                e.specification_standard === "ASTM/ISO" ? (
                                  <TextField
                                    name="purchaseProcurement"
                                    margin="dense"
                                    variant="outlined"
                                    //label='Procurement Quality Standard *'
                                    fullWidth
                                    value={
                                      this.state.procurement_quality_standard
                                    }
                                    error={this.state.purchase_procurementError}
                                    onChange={(e) => {
                                      this.setState({
                                        procurement_quality_standard:
                                          e.target.value,
                                        purchase_procurementError: false,
                                      });
                                    }}
                                    select
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="ASTM">ASTM</MenuItem>
                                    <MenuItem value="ISO">ISO</MenuItem>
                                  </TextField>
                                ) : (
                                  <div className="label-text">
                                    {e.purchasing_term === "Specs"
                                      ? e.specification_standard
                                      : e.purchasing_term}
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="mt-3" />
                            <label className="form_label mb-2 mt-2">
                              Select Purchase Type
                            </label>

                            <table style={{ width: "100%" }}>
                              <thead className="thead-light">
                                <tr>
                                  <th></th>
                                  <th className="drawer_table_header_label">
                                    Mine Name
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Purchase Type
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Anchorage Name
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Coal Price
                                  </th>
                                </tr>
                              </thead>
                              {e.purchase_types.map((p, idx) => (
                                <tbody key={idx}>
                                  <tr
                                    style={{
                                      border:
                                        p.id ===
                                        this.state.purchaseTypeSelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <td style={{ verticalAlign: "middle" }}>
                                      <NewRadio
                                        checked={
                                          p.id ===
                                          this.state.purchaseTypeSelection
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            purchaseTypeSelection:
                                              e.target.value,
                                            pc_purchase_type_id: p.id,
                                            purchase_type: p.purchase_type,
                                          });
                                        }}
                                        value={p.id}
                                        name="purchaseTypeSelection"
                                        inputProps={{ "aria-label": "B" }}
                                      />
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.mine_name}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.purchase_type}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.purchase_type === "FAS Anchorage"
                                        ? p.anchorage_name
                                        : ""}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.price_pmt}{" "}
                                      {this.state.purchaseCurrency}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  onClick={() => {
                    if (this.state.procurement_quality_standard === "0") {
                      this.setState({
                        snackBarErrorOpen: true,
                        purchase_procurementError: true,
                        errorMsg: "Please Select Specification Standard",
                      });
                      return;
                    }
                    if (!this.state.purchaseTypeSelection) {
                      this.setState({
                        snackBarErrorOpen: true,
                        purchase_procurementError: true,
                        errorMsg: "Please Select Purchase Type",
                      });
                      return;
                    }
                    if (!this.state.purchaseQualitySelection) {
                      this.setState({
                        snackBarErrorOpen: true,
                        purchase_procurementError: true,
                        errorMsg: "Please Select Purchase Quality",
                      });
                      return;
                    }
                    this.updatePcHandler();
                    //window.$("#myModal").modal("hide");
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
