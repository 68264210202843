import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import SacAdd from "./Sac-Add";
import SacView from "./Sac-View";
import SacUpdate from "./Sac-Update";

import { TextField, Snackbar, Checkbox } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Drawer from "@material-ui/core/Drawer";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class SacList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      sac_costing_barges: [],
      isLoading: true,
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_id: [],
      sampling_surveyors_drop_value: [],
      sampling_surveyors: [],
      features: [],
      showDrawer: false,
      SacAdd: false,
      SacView: false,
      SacUpdate: false,
      bargeCostingID: null,
      updateDocuments: false,
      files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("159") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                loading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    let status = "Active";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const sampling_surveyors = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  sampling_surveyors.push({ name: i.vendor_name, value: i.id });
                }
              }
              this.setState({
                sampling_surveyors_drop_value: sampling_surveyors,
                isLoading: false,
              });
            }
          }
        });
      }
    });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_name = this.state.barge_nomination;
    let jetty_name = this.state.jetty_name;
    let business_no = this.state.business_no_id;
    // if (barge_name === '') {
    //   this.setState({
    //     barge_name_error: true,
    //     snackBarErrorOpen: true,
    //     errorMsg: "Please Fill Mandatory Fields"
    //   });
    //   return;
    // }
    let sampling_surveyor = this.state.sampling_surveyors;
    api
      .get_barges_for_sac_charges(
        loginUserID,
        idToken,
        business_no,
        sampling_surveyor,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  sac_costing_barges: purchase_contracts_barges.reverse(),
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: false });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_sac_charges_id = data;
    api
      .delete_barge_sac_charges(loginUserID, idToken, barge_sac_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: res.message,
                snackBarSuccessOpen: true,
                isLoading: false,
                deleteCosting: false,
              });
              this.searchBargeHandler();
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                deleteCosting: false,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // successMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        SacUpdate: false,
        SacAdd: false,
        SacView: true,
        updateDocuments: false,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        SacAdd: false,
        SacView: false,
        showDrawer: false,
        updateDocuments: false,
      });
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        SacUpdate: false,
        SacAdd: false,
        SacView: false,
        showDrawer: false,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        SacUpdate: true,
        SacAdd: false,
        SacView: false,
        showDrawer: true,
        updateDocuments: false,
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.SacAdd) {
      drawerComponent = (
        <SacAdd onAddResponse={this.onAddResponse} onCancel={this.onCancel} />
      );
    } else if (this.state.SacView && this.state.bargeCostingID !== null) {
      drawerComponent = (
        <SacView
          comboID={this.state.bargeCostingID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              SacUpdate: true,
              SacView: false,
              SacAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.SacUpdate && this.state.bargeCostingID !== null) {
      drawerComponent = (
        <SacUpdate
          comboID={this.state.bargeCostingID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
              {this.state.SacView && this.state.bargeCostingID !== null && (
                <div
                  className="row col-lg-12 modal-footer justify-content-end"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    background: "white",
                  }}
                >
                  <button
                    type="button"
                    className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
                    onClick={() =>
                      this.setState({
                        SacUpdate: false,
                        SacAdd: false,
                        SacView: false,
                        showDrawer: false,
                        bargeCostingID: null,
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="drawer_button drawer_text"
                    onClick={() =>
                      this.setState({
                        SacUpdate: true,
                        SacAdd: false,
                        SacView: false,
                      })
                    }
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </Drawer>
          <div className="row mb-1 p-2">
            <div className="col-lg-3 p-0">
              <Autocomplete
                multiple
                options={this.state.business_no_drop_value}
                getOptionLabel={(option) => option.mother_vessel_name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">
                        {/* {option.name} */}
                        {option.mother_vessel_name}
                      </label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {"( " + option.name + " )"}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ business_no_id: data });
                  } else {
                    this.setState({ business_no_id: [] });
                  }
                }}
                disableCloseOnSelect
                name="Business_Number"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Business No"
                    label="Business No"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-3 pr-0">
              <Autocomplete
                multiple
                options={this.state.sampling_surveyors_drop_value}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ sampling_surveyors: data });
                  } else {
                    this.setState({ sampling_surveyors: [] });
                  }
                }}
                disableCloseOnSelect
                name="Sampling surveyors"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Sampling surveyors"
                    label="Surveyor"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col p-2">
              <button
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "15px" }}
                onClick={() => this.searchBargeHandler()}
              >
                Load
              </button>
            </div>
            {(this.state.features.includes("158") ||
              this.state.features.includes("97")) && (
              <div className="col p-2 text-right float-sm-left">
                {/* <a href={'/barge-purchase-fin-add-sac'}><button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', marginTop: "17px" }}>New Costing</button></a> */}
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "25px" }}
                  onClick={() =>
                    this.setState({ SacAdd: true, showDrawer: true })
                  }
                >
                  New
                </button>
              </div>
            )}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.sac_costing_barges.length > 0 && (
            <div
              className={
                this.state.sac_costing_barges.length > 0
                  ? "shadow-sm p-2 mb-2 bg-white border"
                  : ""
              }
            >
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm mb-0">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true" className=" text-uppercase"></th>
                        <th nowrap="true" className=" text-uppercase">
                          {" "}
                          Costing ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          {" "}
                          Barge ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          {" "}
                          Barge Nomination{" "}
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          {" "}
                          Business No
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          {" "}
                          Vendor{" "}
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Quantity
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Price PMT
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Cargo Charges
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Additional Charges
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Other Charges
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Total Payable
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Paid
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    {this.state.sac_costing_barges.map((p, index) => (
                      <tbody key={index}>
                        {p.new_barges.map((idr, indx) => (
                          <tr key={indx} className="">
                            {indx === 0 && (
                              <td
                                className="text-center"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 22,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      SacView: true,
                                      bargeCostingID:
                                        idr.barge_group_id + "$#" + idr.id,
                                      showDrawer: true,
                                    })
                                  }
                                ></i>
                                {(this.state.features.includes("185") ||
                                  this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: 21,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          deleteData: idr.id,
                                          deleteCosting: true,
                                        })
                                      }
                                    ></i>
                                  )}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-left"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {idr.costing_no}
                              </td>
                            )}
                            <td className="">{pad(idr.barge_id)}</td>
                            <td className=" text-left" nowrap="true">
                              {idr.barge_nomination}
                            </td>
                            <td className=" text-left" nowrap="true">
                              {idr.business_no}
                            </td>
                            <td className=" text-left" nowrap="true">
                              {idr.sampling_surveyor}
                            </td>
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.sac_quantity_in_mt)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.sac_price_per_mt)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.cargo_charges)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.additional_charges)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.other_charges)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.total_payable)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.paid)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {this.toLocaleString(idr.Remaining_amount)}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          )}
          {/**
           *
           *  Delete Barge Costing Alert Message Modal
           *
           */}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() =>
                this.deleteBargePurchaseFinancial(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
