import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { localDateFormate, pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
var total_barge_quantity = 0;

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class Barge_Purchase_Fin_Sac_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      barges: [],

      multiRows: [
        {
          sac_applicable: "Yes",
          sac_charges_vendor_id: "0",
          invoice_date: null,
          ppn: "",
          sac_surveyor_name: "",
          sac_quantity_in_mt: "",
          sac_price_per_mt: "",
          sac_surveyor_base_price: "",
          sac_surveyor_vat_percent: "10",
          sac_surveyor_vat_value: "",
          sac_surveyor_pph23_percent: "2",
          sac_surveyor_pph23_value: "",
          sac_surveyor_total: "",
          sac_surveyor_ctc: "",
          sac_surveyor_currency_xchg_rate: "",
          sac_surveyor_ctc_in_local_currency: "",
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "0",
          sac_other_charges: [
            {
              sac_other_base_price: "",
              sac_other_description: "",
              sac_other_vat_percent: "10",
              sac_other_vat_value: "",
              sac_other_pph23_percent: "2",
              sac_other_pph23_value: "",
              sac_other_total: "",
              sac_other_ctc: "",
              sac_other_currency_xchg_rate: "",
              sac_other_ctc_in_local_currency: "",
            },
          ],

          sac_surveyor_price_per_addl_day: "",
          sac_surveyor_addl_days: "",
          sac_surveyor_addl_days_base_price: "",
          sac_surveyor_addl_days_vat_percent: "10",
          sac_surveyor_addl_days_vat_value: "",
          sac_surveyor_addl_days_pph23_percent: "2",
          sac_surveyor_addl_days_pph23_value: "",
          sac_surveyor_addl_days_total: "",
          sac_surveyor_addl_days_ctc: "",
          sac_surveyor_addl_days_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "0",
          sac_surveyor_addl_days_currency_xchg_rate: "",
          sac_surveyor_addl_days_ctc_in_local_currency: "",
        },
      ],
      barge_id: null,
      isLoading: true,
      loading: false,
      features: [],
      business_no_drop_value: [],
      business_no_id: [],
      sampling_surveyors_drop_value: [],
      sampling_surveyors: [],
      selected_business_no: [],
      selected_surveyor: [],
      bargeList: [],
      files: [],
      expanded: false,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.indexOf("160") >= 0 ||
          features.indexOf("396") >= 0 ||
          features.indexOf("447") >= 0 ||
          features.indexOf("97") >= 0
        )
      ) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                let business_no_drop_value = [];
                for (var i of res.business_numbers) {
                  business_no_drop_value.push({
                    name: i.business_no,
                    value: i.id,
                    mother_vessel_name: i.mother_vessel_name,
                  });
                }
                this.setState({
                  business_no_drop_value: business_no_drop_value,
                  // loading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

      // for get_Currencies
      await api.getCurrencies(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.Currency) {
                var currencies = [
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value="0"
                    key={-1}
                    disabled
                  >
                    Please Select
                  </MenuItem>,
                ];
                for (var i in res.Currency) {
                  currencies.push(
                    <MenuItem
                      style={{ fontFamily: "Poppins", fontSize: "14px" }}
                      value={res.Currency[i].currency}
                      key={[i]}
                    >
                      {res.Currency[i].currency}
                    </MenuItem>
                  );
                }
                this.setState({
                  currencyData: currencies,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                // isLoading: false
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      // Get Vendors
      var status = "Active";
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                const surveyors = [
                  <MenuItem value="0" key="0">
                    Please Select
                  </MenuItem>,
                ];
                for (var i of res.vendors) {
                  if (i.vendor_type === "Surveyor") {
                    surveyors.push(
                      <MenuItem
                        style={{ fontFamily: "Poppins", fontSize: "14px" }}
                        value={i.id}
                        key={[i.id]}
                      >
                        {i.vendor_name}
                      </MenuItem>
                    );
                  }
                }
                this.setState({
                  surveyors: surveyors,
                  vendorsData: res.vendors,
                });
              }
            }
          });
        }
      });
      try {
        // var barge_id = decodeURIComponent(window.atob(this.props.match.params.bargeID));
        let comboID = this.props.comboID;
        let barge_sac_charges_id = comboID.split("$#")[1];
        let financial_type = "barge_sac_charges";
        await api
          .get_barges(loginUserID, idToken, financial_type)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.barges) {
                    let barges = res.barges;
                    barges.sort(function (a, b) {
                      return (
                        (a.sac_costing === null) - (b.sac_costing === null)
                      );
                    });
                    res.barges = barges;
                    const filter_not_costing_barges = res.barges.filter(
                      (e) =>
                        (e.sac_costing === null ||
                          e.sac_costing === barge_sac_charges_id) &&
                        e.status === "Active"
                    );
                    if (filter_not_costing_barges.length === 0) {
                      this.setState({
                        snackBarErrorOpen: true,
                        errorMsg: "No records to display",
                        barges: filter_not_costing_barges,
                        loading: false,
                        //isLoading: false
                      });
                    } else {
                      this.setState({
                        barges: filter_not_costing_barges,
                        loading: false,
                        //isLoading: false
                      });
                    }
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
        await api
          .get_barge_sac_charges(loginUserID, idToken, barge_sac_charges_id)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.barge_sac_charges) {
                    this.setState({
                      multiRows: res.barge_sac_charges,
                      sac_charges_vendor_name:
                        res.barge_sac_charges.length > 0
                          ? res.barge_sac_charges[0].sac_charges_vendor_name
                          : null,
                      invoice_file:
                        res.barge_sac_charges.length > 0
                          ? res.barge_sac_charges[0].invoice_file
                          : null,
                      bargeList:
                        res.barge_sac_charges.length > 0
                          ? res.barge_sac_charges[0].barge_group_id.split(",")
                          : [],
                      files: res.files ? res.files : [],

                      isLoading: false,
                    });
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        window.location.href = "/barge-list";
      }
    }

    document.title =
      config.documentTitle +
      "Barge Purchase Financial Sampling & Analysis Charges";
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  nextStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  addMoreRowsHandler = () => {
    const item = {
      sac_charges_vendor_id: "0",
      sac_charges_vendor_name: "",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      sac_applicable: "Yes",
      sac_quantity_in_mt: this.state.quantity_in_mt,
      sac_price_per_mt: "",
      sac_surveyor_base_price: "",
      sac_surveyor_vat_percent: "10",
      sac_surveyor_vat_value: "",
      sac_surveyor_pph23_percent: "2",
      sac_surveyor_pph23_value: "",
      sac_surveyor_total: "",
      sac_surveyor_ctc: "",
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "0",
      sac_surveyor_currency_xchg_rate: "",
      sac_surveyor_ctc_in_local_currency: "",
      sac_other_charges: [
        {
          sac_other_base_price: "",
          sac_other_description: "",
          sac_other_vat_percent: "10",
          sac_other_vat_value: "",
          sac_other_pph23_percent: "2",
          sac_other_pph23_value: "",
          sac_other_total: "",
          sac_other_ctc: "",
          sac_other_currency_xchg_rate: "",
          sac_other_ctc_in_local_currency: "",
        },
      ],
      sac_surveyor_price_per_addl_day:
        this.state.sac_surveyor_price_per_addl_day,
      sac_surveyor_addl_days: this.state.sac_surveyor_addl_days,
      sac_surveyor_addl_days_base_price: "",
      sac_surveyor_addl_days_vat_percent: "",
      sac_surveyor_addl_days_vat_value: "",
      sac_surveyor_addl_days_pph23_percent: "2",
      sac_surveyor_addl_days_pph23_value: "",
      sac_surveyor_addl_days_total: "",
      sac_surveyor_addl_days_ctc: "",
      sac_surveyor_addl_days_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "0",
      sac_surveyor_addl_days_currency_xchg_rate: "",
      sac_surveyor_addl_days_ctc_in_local_currency: "",
      remarks: "",
      sac_other_base_price: "",
      sac_other_description: "",
      sac_other_vat_percent: "",
      sac_other_vat_value: "",
      sac_other_pph23_percent: "2",
      sac_other_pph23_value: "",
      sac_other_total: "",
      sac_other_ctc: "",
      sac_other_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "0",
      sac_other_currency_xchg_rate: "",
      sac_other_ctc_in_local_currency: "",
    };

    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  surveyorChargesCalculatons = (idx) => {
    var row = this.state.multiRows;
    var quantity_in_mt = row[idx].sac_quantity_in_mt
      ? row[idx].sac_quantity_in_mt
      : 1;
    var sac_price_per_mt = row[idx].sac_price_per_mt
      ? row[idx].sac_price_per_mt
      : 0;
    var sac_surveyor_currency_xchg_rate = 1;
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].billing_currency !== "IDR" &&
      row[idx].billing_currency
    ) {
      sac_surveyor_currency_xchg_rate = row[idx].sac_surveyor_currency_xchg_rate
        ? row[idx].sac_surveyor_currency_xchg_rate
        : 1;
    }

    let sac_surveyor_base_price = (
      (Number(quantity_in_mt) * Number(sac_price_per_mt)) /
      sac_surveyor_currency_xchg_rate
    ).toFixed(2);
    let sac_surveyor_vat_value = (
      (Number(sac_surveyor_base_price) * 10) /
      100
    ).toFixed(2);
    let sac_surveyor_pph23_value = (
      (Number(sac_surveyor_base_price) * 2) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      sac_surveyor_pph23_value = 0;
    }
    let sac_surveyor_total = (
      Number(sac_surveyor_base_price) +
      Number(sac_surveyor_vat_value) -
      Number(sac_surveyor_pph23_value)
    ).toFixed(2);
    let sac_surveyor_ctc = (
      Number(sac_surveyor_base_price) + Number(sac_surveyor_vat_value)
    ).toFixed(2);

    row[idx].sac_surveyor_base_price = sac_surveyor_base_price;
    row[idx].sac_surveyor_vat_percent = 10;
    row[idx].sac_surveyor_vat_value = sac_surveyor_vat_value;
    row[idx].sac_surveyor_pph23_percent = 2;
    row[idx].sac_surveyor_pph23_value = sac_surveyor_pph23_value;
    row[idx].sac_surveyor_total = sac_surveyor_total;
    row[idx].sac_surveyor_ctc = sac_surveyor_ctc;
    row[idx].sac_surveyor_ctc_in_local_currency = sac_surveyor_ctc;
    this.setState({
      multiRows: row,
    });
  };

  surveyorAddDaysPriceCalculations = (idx) => {
    var row = this.state.multiRows;
    var sac_surveyor_addl_days = row[idx].sac_surveyor_addl_days
      ? row[idx].sac_surveyor_addl_days
      : 1;
    var sac_surveyor_price_per_addl_day = row[idx]
      .sac_surveyor_price_per_addl_day
      ? row[idx].sac_surveyor_price_per_addl_day
      : 0;
    let sac_surveyor_addl_days_currency_xchg_rate = 1;
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].billing_currency !== "IDR" &&
      row[idx].billing_currency
    ) {
      sac_surveyor_addl_days_currency_xchg_rate = row[idx]
        .sac_surveyor_addl_days_currency_xchg_rate
        ? row[idx].sac_surveyor_addl_days_currency_xchg_rate
        : 1;
    }

    var sac_surveyor_addl_days_base_price = (
      (Number(sac_surveyor_addl_days) *
        Number(sac_surveyor_price_per_addl_day)) /
      sac_surveyor_addl_days_currency_xchg_rate
    ).toFixed(2);
    let sac_surveyor_addl_days_vat_value = (
      (Number(sac_surveyor_addl_days_base_price) * 10) /
      100
    ).toFixed(2);
    let sac_surveyor_addl_days_pph23_value = (
      (Number(sac_surveyor_addl_days_base_price) * 2) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      sac_surveyor_addl_days_pph23_value = 0;
    }
    let sac_surveyor_addl_days_total = (
      Number(sac_surveyor_addl_days_base_price) +
      Number(sac_surveyor_addl_days_vat_value) -
      Number(sac_surveyor_addl_days_pph23_value)
    ).toFixed(2);
    let sac_surveyor_addl_days_ctc = (
      Number(sac_surveyor_addl_days_base_price) +
      Number(sac_surveyor_addl_days_vat_value)
    ).toFixed(0);

    row[idx].sac_surveyor_addl_days_base_price =
      sac_surveyor_addl_days_base_price;
    row[idx].sac_surveyor_addl_days_vat_value =
      sac_surveyor_addl_days_vat_value;
    row[idx].sac_surveyor_addl_days_pph23_value =
      sac_surveyor_addl_days_pph23_value;
    row[idx].sac_surveyor_addl_days_total = sac_surveyor_addl_days_total;
    row[idx].sac_surveyor_addl_days_ctc = sac_surveyor_addl_days_ctc;
    row[idx].sac_surveyor_addl_days_ctc_in_local_currency =
      sac_surveyor_addl_days_ctc;
    this.setState({
      multiRows: row,
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.bargeList.toString();
    let sac_charges_data = this.state.multiRows;
    let costing_file = this.state.costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    for (var i of sac_charges_data) {
      i["costing_files"] = costing_file;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    api
      .update_barge_sac_charges(
        loginUserID,
        idToken,
        barge_id,
        sac_charges_data,
        existing_file_ids,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onUpdateCosting(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                successMessage: "Costing Updated sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else {
              this.setState({ isLoading: false });
            }
          });
        }
      });
  };

  AddPurchaseTypeRows = (idx) => {
    const item = {
      sac_other_base_price: "",
      sac_other_description: "",
      sac_other_vat_percent: "10",
      sac_other_vat_value: "",
      sac_other_pph23_percent: "2",
      sac_other_pph23_value: "",
      sac_other_total: "",
      sac_other_ctc: "",
      sac_other_currency_xchg_rate: "",
      sac_other_ctc_in_local_currency: "",
    };
    var sac_other_charges = this.state.multiRows[idx].sac_other_charges;
    let data = [...sac_other_charges, item];
    //this.state.purchaseTypeRows[idx].purchase_types = data;
    let newState = Object.assign({}, this.state);
    newState.multiRows[idx].sac_other_charges = data;
    this.setState({
      newState,
    });
  };

  purchaseTypesRowRemoveHandler = (idx, index) => () => {
    const row = this.state.multiRows[idx].sac_other_charges;
    row.splice(index, 1);
    this.setState({
      multiRows: this.state.multiRows,
    });
  };

  async searchBargeHandler() {
    this.setState({ isLoading: true });

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_name = "";
    let jetty_name = "";
    let financial_type = "barge_sac_charges";
    let business_no = this.state.business_no_id;
    let barge_vendor = "";
    let costing_type = "";
    let purchase_contract_id = "";
    let sampling_surveyor = this.state.sampling_surveyors;
    const get_barges = await api.get_barges(
      loginUserID,
      idToken,
      financial_type,
      business_no,
      barge_vendor,
      purchase_contract_id,
      barge_name,
      costing_type,
      jetty_name,
      sampling_surveyor
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const filter_not_costing_barges = res.barges.filter(
              (e) => e.sac_costing === null && e.status === "Active"
            );
            if (filter_not_costing_barges.length === 0) {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "No records to display",
                barges: filter_not_costing_barges,
                loading: false,
                isLoading: false,
              });
            } else {
              this.setState({
                barges: filter_not_costing_barges,
                loading: false,
                isLoading: false,
              });
            }
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let totalBarges = this.state.barges;
    let row = this.state.multiRows;
    if (checked) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();

      //const filterNotAddedFinancialBarges = totalBarges.filter((e, idx) => e.barge_pre_shipment_id === null);
      if (totalBarges.length > 0) {
        let barge_id = totalBarges[0].id;

        api
          .get_purchase_contract_data_by_barge_id(
            loginUserID,
            idToken,
            barge_id
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.purchase_contract_data) {
                    let bargeList = [];
                    let all_barge_quantity = 0;
                    for (var i of totalBarges) {
                      bargeList.push(i.id);
                      if (i.barge_quantity === null) {
                        all_barge_quantity += Number(
                          i.barge_planned_quantity_in_mt
                        );
                      } else {
                        all_barge_quantity += Number(i.barge_quantity_in_mt);
                      }
                    }
                    total_barge_quantity = all_barge_quantity;
                    row[0].sac_quantity_in_mt = all_barge_quantity;
                    this.setState({
                      bargeList: bargeList,
                      multiRows: row,
                      barge_quantity: total_barge_quantity,
                      isLoading: false,
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "No barges selected.",
        });
      }
    } else {
      row[0].sac_quantity_in_mt = "";
      this.setState({
        multiRows: row,
        bargeList: [],
        barge_quantity: "",
        purchase_price_pmt: "",
        sales_type: "",
        purchase_type: "",
        isLoading: false,
      });
    }
  };

  onBargesCheck = (bargeId, checked, barge) => {
    var bargeQuantity = "";
    if (
      barge.barge_quantity_in_mt === null ||
      barge.barge_quantity_in_mt <= 0
    ) {
      bargeQuantity = barge.barge_planned_quantity_in_mt;
    } else {
      bargeQuantity = barge.barge_quantity_in_mt;
    }
    if (!!checked) {
      total_barge_quantity += Number(bargeQuantity);
      let bargeList = [...this.state.bargeList, bargeId];
      let row = this.state.multiRows;
      if (row.lenght > 0) {
        row[0].sac_quantity_in_mt = total_barge_quantity;
      }
      this.setState({
        bargeList: bargeList,
        multiRows: row,
        isLoading: true,
      });
      if (this.state.bargeList.length === 0) {
        this.callingAsync(barge);
      } else if (this.state.bargeList.length > 0) {
        let row = this.state.multiRows;
        row[0].sac_quantity_in_mt = total_barge_quantity;
        this.setState({
          multiRows: row,
          barge_quantity: total_barge_quantity,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } else {
      var data = this.state.bargeList;
      total_barge_quantity -= Number(bargeQuantity);
      if (data.indexOf(bargeId) >= 0) {
        let idx = data.indexOf(bargeId);
        data.splice(idx, 1);
      }
      this.setState({
        bargeList: data,
        barge_quantity: total_barge_quantity,
        isLoading: true,
      });
      if (this.state.bargeList.length !== 0) {
        var row = this.state.multiRows;
        row[0].sac_quantity_in_mt = total_barge_quantity;
        this.setState({
          multiRows: row,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    const re = /^[0-9]*\.?[0-9]*$/;

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Sampling & Analysis
                  Charges - ${this.state.costing_no}`}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {!this.props.updateDocuments && (
                  <div className=" w-100">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Select Barge to create costing
                      </h5>
                    </div>
                    <div className="row p-2">
                      <div className="col-lg-5 p-0">
                        <Autocomplete
                          multiple
                          options={this.state.business_no_drop_value}
                          getOptionLabel={(option) => option.mother_vessel_name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <CheckBox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.mother_vessel_name}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{ fontSize: 10, paddingLeft: 5 }}
                                >
                                  {option.name
                                    ? "( " + option.name + " )"
                                    : null}
                                </label>
                              </div>
                              {/* {option.name} {option.mother_vessel_name ? "( " + option.mother_vessel_name + " )" : null} */}
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              var data = [];
                              for (var i of value) {
                                data.push(i.value);
                              }
                              //var floating_crane_vendor = data.toString();
                              this.setState({
                                business_no_id: data,
                                selected_business_no: value,
                              });
                            } else {
                              this.setState({
                                business_no_id: [],
                                selected_business_no: [],
                              });
                            }
                          }}
                          name="Business_Number"
                          size="small"
                          value={this.state.selected_business_no}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Business No"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-5 pr-0">
                        <Autocomplete
                          multiple
                          options={this.state.sampling_surveyors_drop_value}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <CheckBox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              var data = [];
                              for (var i of value) {
                                data.push(i.value);
                              }
                              //var floating_crane_vendor = data.toString();
                              this.setState({
                                sampling_surveyors: data,
                                selected_surveyor: value,
                              });
                            } else {
                              this.setState({
                                sampling_surveyors: [],
                                selected_surveyor: [],
                              });
                            }
                          }}
                          disableCloseOnSelect
                          name="Sampling surveyors"
                          size="small"
                          value={this.state.selected_surveyor}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Sampling surveyors"
                              label="Surveyor"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-2 pr-0">
                        <button
                          className="btn btn-sm btn-info"
                          style={{
                            backgroundColor: config.themeColor,
                            borderColor: config.themeColor,
                            width: "auto",
                            marginTop: "13px",
                          }}
                          onClick={() => this.searchBargeHandler()}
                        >
                          <i
                            className="fa fa-search mr-1"
                            aria-hidden="true"
                          ></i>
                          Search
                        </button>
                      </div>
                    </div>

                    <Accordion
                      expanded={this.state.expanded}
                      className="mt-3 mb-2"
                      style={{
                        border: "#f7f3f0 1px solid",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#483b34" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          height: 15,
                          backgroundColor: "#f7f3f0",
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                        }}
                        className="text-white pl-1"
                        onClick={() =>
                          this.setState({ expanded: !this.state.expanded })
                        }
                      >
                        <h5 className="mb-0 pl-1">Select Barges</h5>
                      </AccordionSummary>
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-borderless border-0">
                            <thead className="border-0">
                              <tr>
                                <th
                                  className="p-2 text-center border-0"
                                  nowrap="true"
                                >
                                  <CheckBox
                                    //value={this.state.totalBarges}
                                    color="primary"
                                    //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length === this.state.totalBarges.filter(e => e.barge_pur_quality_results_id === null).length)}
                                    checked={
                                      this.state.barges.length !== 0 &&
                                      this.state.bargeList.length ===
                                        this.state.barges.filter(
                                          (e) =>
                                            e.barge_pur_quality_results_id !==
                                            null
                                        ).length
                                    }
                                    //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                    // onChange={this.onBargesCheck}
                                    onChange={(e) => {
                                      this.onSelectAllBarges(e.target.checked);
                                    }}
                                    className="p-0"
                                  />
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge ID
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge Nomination
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Jetty
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Loading Date
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Business No
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                  nowrap="true"
                                >
                                  Quantity
                                </th>
                              </tr>
                            </thead>
                            {this.state.barges.map((p, index) => (
                              <tbody key={index}>
                                <tr className="rounded">
                                  <td className="p-0 text-center">
                                    <CheckBox
                                      value={p.id}
                                      color="primary"
                                      checked={
                                        this.state.bargeList.indexOf(p.id) >= 0
                                      }
                                      onChange={(e) => {
                                        this.onBargesCheck(
                                          e.target.value,
                                          e.target.checked,
                                          p
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{pad(p.id)}</td>
                                  <td nowrap="true">{p.barge_nomination}</td>
                                  <td nowrap="true">{p.loading_jetty}</td>
                                  <td nowrap="true">
                                    {localDateFormate(p.loading_date)}
                                  </td>
                                  <td nowrap="true">{p.business_no}</td>
                                  <td nowrap="true" className="text-right">
                                    {this.toLocaleString(
                                      p.barge_quantity_in_mt
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    </Accordion>

                    {this.state.multiRows.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div
                          className={
                            this.state.multiRows.length > 1
                              ? "col-lg-11 border_dashed rounded-lg p-2 mb-3"
                              : "col-lg-12 border_dashed rounded-lg p-2 mb-3"
                          }
                        >
                          <div className="row">
                            <div className="col-lg-6 mb-1 p-0">
                              <label className="form_label mb-0">
                                Sampling & Analysis Charges Applicable
                              </label>
                              <RadioGroup
                                aria-label="sac_applicable"
                                name="sac_applicable"
                                onChange={(e) => {
                                  var row = this.state.multiRows;
                                  row[idx].sac_applicable = e.target.value;
                                  this.setState({
                                    multiRows: row,
                                  });
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <NewRadio
                                      checked={e.sac_applicable === "Yes"}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <NewRadio
                                      checked={e.sac_applicable === "No"}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                          </div>

                          {e.sac_applicable === "Yes" && (
                            <div>
                              <div className="row mb-3 ">
                                <div className="col-lg-5 pl-0">
                                  <div className="row">
                                    <div className="col-lg-12 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        Surveyor Name{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <TextField
                                        name="payment_to"
                                        margin="dense"
                                        variant="outlined"
                                        value={e.sac_charges_vendor_id}
                                        onChange={(e) => {
                                          var row = this.state.multiRows;
                                          row[idx].sac_charges_vendor_id =
                                            e.target.value;
                                          var sac_surveyor_name = "";
                                          if (e.target.value !== "0") {
                                            const data =
                                              this.state.vendorsData.filter(
                                                (obj, idx) =>
                                                  obj.id === e.target.value
                                              );
                                            if (data.length > 0) {
                                              sac_surveyor_name =
                                                data[0].vendor_name;
                                            }
                                          }
                                          row[idx].sac_surveyor_name =
                                            sac_surveyor_name;

                                          this.setState({
                                            multiRows: row,
                                            sac_surveyor_name:
                                              sac_surveyor_name,
                                            sac_charges_vendor_id:
                                              e.target.value,
                                          });
                                        }}
                                        select
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        {this.state.surveyors}
                                      </TextField>
                                    </div>
                                    <div className="col-lg-6 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        Quantity in MT
                                      </label>
                                      <TextField
                                        name="sac_quantity_in_mt"
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Quantity in MT"
                                        value={e.sac_quantity_in_mt}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            quan.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            row[idx].sac_quantity_in_mt =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () => {
                                                this.surveyorChargesCalculatons(
                                                  idx
                                                );
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="col-lg-6 mb-1 pr-0">
                                      <label className="form_label mb-0">
                                        Price Per MT
                                      </label>
                                      <TextField
                                        name="sac_price_per_mt"
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                            </InputAdornment>
                                          ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Price per MT"
                                        value={e.sac_price_per_mt}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            row[idx].sac_price_per_mt =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.surveyorChargesCalculatons(
                                                  idx
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) && (
                                      <div className="col-lg-6 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          Currency
                                        </label>
                                        <TextField
                                          name="billing_currency"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Currency"
                                          //label="Currency"
                                          value={e.billing_currency}
                                          select
                                          onChange={(e) => {
                                            var row = this.state.multiRows;
                                            row[idx].billing_currency =
                                              e.target.value;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </div>
                                    )}

                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency !== "0" && (
                                        <div className="col-lg-6 mb-1 pr-0">
                                          <label className="form_label mb-0">
                                            Exchange Rate
                                          </label>
                                          <TextField
                                            name="sac_surveyor_currency_xchg_rate"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                              style: {
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                              },
                                            }}
                                            placeholder="Enter Value"
                                            value={
                                              e.sac_surveyor_currency_xchg_rate
                                            }
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                var row = this.state.multiRows;
                                                row[
                                                  idx
                                                ].sac_surveyor_currency_xchg_rate =
                                                  e.target.value;
                                                row[
                                                  idx
                                                ].sac_surveyor_ctc_in_local_currency =
                                                  (
                                                    Number(
                                                      row[idx].sac_price_per_mt
                                                    ) * Number(e.target.value)
                                                  ).toFixed(2);
                                                this.setState(
                                                  {
                                                    multiRows: row,
                                                  },
                                                  () =>
                                                    this.surveyorChargesCalculatons(
                                                      idx
                                                    )
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      )}
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency !== "0" && (
                                        <div className="col-lg-6 mb-1 p-0">
                                          <label className="form_label mb-0">
                                            Price in IDR
                                          </label>
                                          <div className="">
                                            {e.sac_surveyor_ctc_in_local_currency
                                              ? this.toLocaleString(
                                                  e.sac_surveyor_ctc_in_local_currency
                                                ) + " IDR"
                                              : "-"}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div
                                  className="col-lg-7 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>Surveyor Charges</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-4 p-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Base price
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.sac_surveyor_base_price
                                          ? this.toLocaleString(
                                              e.sac_surveyor_base_price
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="col-4 pr-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        VAT %
                                      </label>
                                      <TextField
                                        name="sac_surveyor_vat_percent"
                                        margin="dense"
                                        //variant='outlined'
                                        fullWidth
                                        value={e.sac_surveyor_vat_percent}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            let sac_surveyor_vat_value = (
                                              (Number(
                                                row[idx].sac_surveyor_base_price
                                              ) *
                                                Number(e.target.value)) /
                                              100
                                            ).toFixed(2);
                                            let sac_surveyor_pph23_value = (
                                              (Number(
                                                row[idx].sac_surveyor_base_price
                                              ) *
                                                Number(
                                                  row[idx]
                                                    .sac_surveyor_pph23_percent
                                                )) /
                                              100
                                            ).toFixed(2);
                                            if (
                                              config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                            ) {
                                              sac_surveyor_pph23_value = 0;
                                            }
                                            let sac_surveyor_total = (
                                              Number(
                                                row[idx].sac_surveyor_base_price
                                              ) +
                                              Number(sac_surveyor_vat_value) -
                                              Number(sac_surveyor_pph23_value)
                                            ).toFixed(2);
                                            let sac_surveyor_ctc = (
                                              Number(
                                                row[idx].sac_surveyor_base_price
                                              ) + Number(sac_surveyor_vat_value)
                                            ).toFixed(2);
                                            row[idx].sac_surveyor_vat_percent =
                                              e.target.value;
                                            row[idx].sac_surveyor_vat_value =
                                              sac_surveyor_vat_value;
                                            row[idx].sac_surveyor_pph23_value =
                                              sac_surveyor_pph23_value;
                                            row[idx].sac_surveyor_total =
                                              sac_surveyor_total;
                                            row[idx].sac_surveyor_ctc =
                                              sac_surveyor_ctc;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-4 pr-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        VAT
                                      </label>
                                      {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_value ? this.toLocaleString(e.dc_vat_value) : "-"}</div> */}
                                      <TextField
                                        name="sac_surveyor_vat_value"
                                        margin="dense"
                                        //variant='outlined'
                                        fullWidth
                                        value={e.sac_surveyor_vat_value}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;

                                            let sac_surveyor_pph23_value = (
                                              (Number(
                                                row[idx].sac_surveyor_base_price
                                              ) *
                                                Number(
                                                  row[idx]
                                                    .sac_surveyor_pph23_percent
                                                )) /
                                              100
                                            ).toFixed(2);
                                            if (
                                              config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                            ) {
                                              sac_surveyor_pph23_value = 0;
                                            }
                                            let sac_surveyor_total = (
                                              Number(
                                                row[idx].sac_surveyor_base_price
                                              ) +
                                              Number(e.target.value) -
                                              Number(sac_surveyor_pph23_value)
                                            ).toFixed(2);
                                            let sac_surveyor_ctc = (
                                              Number(
                                                row[idx].sac_surveyor_base_price
                                              ) + Number(e.target.value)
                                            ).toFixed(2);

                                            row[idx].sac_surveyor_vat_value =
                                              e.target.value;
                                            row[idx].sac_surveyor_total =
                                              sac_surveyor_total;
                                            row[idx].sac_surveyor_ctc =
                                              sac_surveyor_ctc;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) && (
                                      <div className="col-lg-6 p-0 mb-3">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @ 2%
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.sac_surveyor_pph23_value
                                            ? this.toLocaleString(
                                                e.sac_surveyor_pph23_value
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="row">
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? "col-lg-6 p-0"
                                          : "col-lg-6 p-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.sac_surveyor_total
                                          ? this.toLocaleString(
                                              e.sac_surveyor_total
                                            )
                                          : 0}{" "}
                                        {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? "col-lg-6 pr-0"
                                          : "col-lg-6 pr-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.sac_surveyor_ctc
                                          ? this.toLocaleString(
                                              e.sac_surveyor_ctc
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div className="">
                                <div className="row mb-3">
                                  <div className="row col-lg-12 p-0">
                                    <h5>Others</h5>
                                  </div>

                                  {e.sac_other_charges.map((p, index) => (
                                    <div className="row" key={index}>
                                      <div
                                        className={
                                          e.sac_other_charges.length > 1
                                            ? "col-lg-11 border rounded p-2 mb-3 "
                                            : "col-lg-12 border rounded p-2 mb-3"
                                        }
                                      >
                                        <div className="row">
                                          <div className="col-lg-5 pl-0">
                                            <div className="row">
                                              <div className="col-lg-12 mb-1 p-0">
                                                <label className="form_label mb-0">
                                                  Base Price{" "}
                                                  {config.company === "SRPL" ||
                                                  config.company === "AVS" ||
                                                  config.company === "PRPL"
                                                    ? "(USD)"
                                                    : "(IDR)"}
                                                </label>
                                                <TextField
                                                  name="sac_other_base_price"
                                                  margin="dense"
                                                  variant="outlined"
                                                  //fullWidth
                                                  value={p.sac_other_base_price}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === "" ||
                                                      pr.test(e.target.value)
                                                    ) {
                                                      var rows =
                                                        this.state.multiRows;
                                                      var row =
                                                        rows[idx]
                                                          .sac_other_charges;

                                                      let sac_other_vat_value =
                                                        (
                                                          (Number(
                                                            e.target.value
                                                          ) *
                                                            Number(
                                                              row[index]
                                                                .sac_other_vat_percent
                                                            )) /
                                                          100
                                                        ).toFixed(2);
                                                      let sac_other_pph23_value =
                                                        (
                                                          (Number(
                                                            e.target.value
                                                          ) *
                                                            Number(
                                                              row[index]
                                                                .sac_other_pph23_percent
                                                            )) /
                                                          100
                                                        ).toFixed(2);
                                                      if (
                                                        config.company ===
                                                          "SRPL" ||
                                                        config.company ===
                                                          "PRPL"
                                                      ) {
                                                        sac_other_pph23_value = 0;
                                                      }
                                                      let sac_other_total = (
                                                        Number(e.target.value) +
                                                        Number(
                                                          sac_other_vat_value
                                                        ) -
                                                        Number(
                                                          sac_other_pph23_value
                                                        )
                                                      ).toFixed(2);
                                                      let sac_other_ctc = (
                                                        Number(e.target.value) +
                                                        Number(
                                                          sac_other_vat_value
                                                        )
                                                      ).toFixed(2);

                                                      row[
                                                        index
                                                      ].sac_other_base_price =
                                                        e.target.value;
                                                      row[
                                                        index
                                                      ].sac_other_vat_value = sac_other_vat_value;
                                                      row[
                                                        index
                                                      ].sac_other_pph23_value =
                                                        sac_other_pph23_value;
                                                      row[
                                                        index
                                                      ].sac_other_total = sac_other_total;
                                                      row[index].sac_other_ctc =
                                                        sac_other_ctc;
                                                      this.setState({
                                                        multiRows: rows,
                                                      });
                                                    }
                                                  }}
                                                  className="rounded-left"
                                                  style={{
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                  }}
                                                  fullWidth
                                                  InputProps={{
                                                    endAdornment:
                                                      (config.company ===
                                                        "SDAM" ||
                                                        config.company ===
                                                          "SRK" ||
                                                        config.company ===
                                                          "BTR") && (
                                                        <InputAdornment
                                                          // variant="outlined"
                                                          position="end"
                                                          style={{
                                                            marginRight:
                                                              "-14px",
                                                            marginTop: "-4px",
                                                            width: "50%",
                                                          }}
                                                        >
                                                          <TextField
                                                            name="sac_other_currency"
                                                            margin="dense"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={
                                                              p.sac_other_currency
                                                            }
                                                            select
                                                            inputProps={{
                                                              style: {
                                                                fontFamily:
                                                                  "Poppins",
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                            }}
                                                            onChange={(e) => {
                                                              var rows =
                                                                this.state
                                                                  .multiRows;
                                                              var row =
                                                                rows[idx]
                                                                  .sac_other_charges;
                                                              row[
                                                                index
                                                              ].sac_other_currency =
                                                                e.target.value;
                                                              this.setState({
                                                                multiRows: rows,
                                                              });
                                                            }}
                                                          >
                                                            {
                                                              this.state
                                                                .currencyData
                                                            }
                                                          </TextField>
                                                        </InputAdornment>
                                                      ),
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                />
                                              </div>
                                              {!(
                                                config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                              ) &&
                                                p.sac_other_currency !==
                                                  "IDR" &&
                                                p.sac_other_currency !==
                                                  "0" && (
                                                  <div className="col-lg-12 mb-1 p-0">
                                                    <label className="form_label mb-0">
                                                      Exchange Rate
                                                    </label>
                                                    <TextField
                                                      name="sac_other_currency_xchg_rate"
                                                      margin="dense"
                                                      variant="outlined"
                                                      fullWidth
                                                      inputProps={{
                                                        style: {
                                                          fontFamily: "Poppins",
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                      placeholder="Enter Value"
                                                      value={
                                                        p.sac_other_currency_xchg_rate
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.value ===
                                                            "" ||
                                                          re.test(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          var value =
                                                            e.target.value;
                                                          if (value === "") {
                                                            value = 1;
                                                          }
                                                          var rows =
                                                            this.state
                                                              .multiRows;
                                                          var row =
                                                            rows[idx]
                                                              .sac_other_charges;
                                                          row[
                                                            index
                                                          ].sac_other_currency_xchg_rate =
                                                            e.target.value;
                                                          row[
                                                            index
                                                          ].sac_other_ctc_in_local_currency =
                                                            (
                                                              Number(value) *
                                                              Number(
                                                                row[idx]
                                                                  .sac_other_ctc
                                                              )
                                                            ).toFixed(2);
                                                          this.setState({
                                                            multiRows: rows,
                                                          });
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              <div className="col-lg-6 mb-1 p-0">
                                                <label className="form_label mb-0">
                                                  VAT %
                                                </label>
                                                <TextField
                                                  name="sac_other_vat_percent"
                                                  margin="dense"
                                                  variant="outlined"
                                                  inputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  //fullWidth
                                                  value={
                                                    p.sac_other_vat_percent
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === "" ||
                                                      pr.test(e.target.value)
                                                    ) {
                                                      var rows =
                                                        this.state.multiRows;
                                                      var row =
                                                        rows[idx]
                                                          .sac_other_charges;

                                                      let sac_other_vat_value =
                                                        (
                                                          (Number(
                                                            row[index]
                                                              .sac_other_base_price
                                                          ) *
                                                            Number(
                                                              e.target.value
                                                            )) /
                                                          100
                                                        ).toFixed(2);
                                                      let sac_other_pph23_value =
                                                        (
                                                          (Number(
                                                            row[index]
                                                              .sac_other_base_price
                                                          ) *
                                                            Number(
                                                              row[index]
                                                                .sac_other_pph23_percent
                                                            )) /
                                                          100
                                                        ).toFixed(2);
                                                      if (
                                                        config.company ===
                                                          "SRPL" ||
                                                        config.company ===
                                                          "PRPL"
                                                      ) {
                                                        sac_other_pph23_value = 0;
                                                      }
                                                      let sac_other_total = (
                                                        Number(
                                                          row[index]
                                                            .sac_other_base_price
                                                        ) +
                                                        Number(
                                                          sac_other_vat_value
                                                        ) -
                                                        Number(
                                                          sac_other_pph23_value
                                                        )
                                                      ).toFixed(2);
                                                      let sac_other_ctc = (
                                                        Number(
                                                          row[index]
                                                            .sac_other_base_price
                                                        ) +
                                                        Number(
                                                          sac_other_vat_value
                                                        )
                                                      ).toFixed(2);

                                                      row[
                                                        index
                                                      ].sac_other_vat_percent =
                                                        e.target.value;
                                                      row[
                                                        index
                                                      ].sac_other_vat_value = sac_other_vat_value;
                                                      row[
                                                        index
                                                      ].sac_other_pph23_value =
                                                        sac_other_pph23_value;
                                                      row[
                                                        index
                                                      ].sac_other_total = sac_other_total;
                                                      row[index].sac_other_ctc =
                                                        sac_other_ctc;

                                                      this.setState({
                                                        multiRows: rows,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                              <div className="col-lg-6 mb-1 pr-0">
                                                <label className="form_label mb-0">
                                                  VAT
                                                </label>
                                                <TextField
                                                  name="sac_other_vat_value"
                                                  margin="dense"
                                                  variant="outlined"
                                                  inputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  //fullWidth
                                                  value={p.sac_other_vat_value}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === "" ||
                                                      pr.test(e.target.value)
                                                    ) {
                                                      var rows =
                                                        this.state.multiRows;
                                                      var row =
                                                        rows[idx]
                                                          .sac_other_charges;

                                                      //let sac_other_vat_value = ((Number(value)*Number(this.state.sac_other_vat_percent))/100).toFixed(2);
                                                      let sac_other_pph23_value =
                                                        (
                                                          (Number(
                                                            row[index]
                                                              .sac_other_base_price
                                                          ) *
                                                            Number(
                                                              row[index]
                                                                .sac_other_pph23_percent
                                                            )) /
                                                          100
                                                        ).toFixed(2);
                                                      if (
                                                        config.company ===
                                                          "SRPL" ||
                                                        config.company ===
                                                          "PRPL"
                                                      ) {
                                                        sac_other_pph23_value = 0;
                                                      }
                                                      let sac_other_total = (
                                                        Number(
                                                          row[index]
                                                            .sac_other_base_price
                                                        ) +
                                                        Number(e.target.value) -
                                                        Number(
                                                          sac_other_pph23_value
                                                        )
                                                      ).toFixed(2);
                                                      let sac_other_ctc = (
                                                        Number(
                                                          row[index]
                                                            .sac_other_base_price
                                                        ) +
                                                        Number(e.target.value)
                                                      ).toFixed(2);
                                                      row[
                                                        index
                                                      ].sac_other_vat_value =
                                                        e.target.value;
                                                      row[
                                                        index
                                                      ].sac_other_pph23_value =
                                                        sac_other_pph23_value;
                                                      row[
                                                        index
                                                      ].sac_other_total = sac_other_total;
                                                      row[index].sac_other_ctc =
                                                        sac_other_ctc;
                                                      this.setState({
                                                        multiRows: rows,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>

                                              <div className="col-lg-12 pl-0">
                                                <label className="form_label mb-0">
                                                  Description
                                                </label>
                                                <TextField
                                                  name="sac_other_description"
                                                  margin="dense"
                                                  variant="outlined"
                                                  fullWidth
                                                  inputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                      textTransform:
                                                        "uppercase",
                                                    },
                                                  }}
                                                  value={
                                                    p.sac_other_description
                                                  }
                                                  onChange={(e) => {
                                                    var rows =
                                                      this.state.multiRows;
                                                    var row =
                                                      rows[idx]
                                                        .sac_other_charges;
                                                    row[
                                                      index
                                                    ].sac_other_description =
                                                      e.target.value;
                                                    this.setState({
                                                      multiRows: rows,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="col-lg-7 p-2 rounded"
                                            style={{
                                              backgroundColor: "#f7f3f0",
                                            }}
                                          >
                                            {/* <div className='row col-lg-12 p-0'>
  <h6>OtherCharges</h6>
  </div> */}
                                            <div className="row">
                                              {!(
                                                config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                              ) && (
                                                <div className="col-lg-12 mb-3">
                                                  <label
                                                    className="form_label mb-0"
                                                    style={{
                                                      color:
                                                        "rgba(72, 59, 52, 0.7)",
                                                    }}
                                                  >
                                                    Includes PPH23 @ 2%
                                                  </label>
                                                  <div
                                                    className="contract_display_header_value"
                                                    style={{ color: "#42352d" }}
                                                  >
                                                    {p.sac_other_pph23_value
                                                      ? this.toLocaleString(
                                                          p.sac_other_pph23_value
                                                        )
                                                      : "-"}
                                                  </div>
                                                </div>
                                              )}

                                              <div className="col-lg-12 mb-3">
                                                <label
                                                  className="form_label mb-0"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  Payable to Vendor
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {p.sac_other_total
                                                    ? this.toLocaleString(
                                                        p.sac_other_total
                                                      )
                                                    : 0}{" "}
                                                  {config.company === "SRPL" ||
                                                  config.company === "AVS" ||
                                                  config.company === "PRPL"
                                                    ? "USD"
                                                    : "IDR"}
                                                </div>
                                              </div>
                                              <div className="col-lg-12 mb-3">
                                                <label
                                                  className="form_label mb-0"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  Cost to Company
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {p.sac_other_ctc
                                                    ? this.toLocaleString(
                                                        p.sac_other_ctc
                                                      )
                                                    : 0}{" "}
                                                  {config.company === "SRPL" ||
                                                  config.company === "AVS" ||
                                                  config.company === "PRPL"
                                                    ? "USD"
                                                    : "IDR"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {e.sac_other_charges.length > 1 && (
                                        <div className="col-lg-1 d-flex justify-content-center">
                                          <div className="text-align-center">
                                            <i
                                              className="fa fa-trash-o"
                                              onClick={this.purchaseTypesRowRemoveHandler(
                                                idx,
                                                index
                                              )}
                                              style={{
                                                fontSize: 24,
                                                color: config.themeColor,
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.AddPurchaseTypeRows(idx)
                                    }
                                    className="header_button header_button_text addrow_button_adjustment"
                                    style={{
                                      width: 160,
                                      border: "none",
                                      color: config.themeColor,
                                      backgroundColor: "#f4f2f0",
                                    }}
                                  >
                                    Add Others
                                  </button>
                                </div>
                              </div>

                              <hr />

                              <div className="row mb-3 ">
                                <div className="row col-lg-12 p-0">
                                  <h5>Additional Charges</h5>
                                </div>
                                <div className="row">
                                  <div className="col-lg-5 pl-0">
                                    <div className="row">
                                      <div className="col-lg-6 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          Extra Days
                                        </label>
                                        <TextField
                                          name="sac_surveyor_addl_days"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Enter value"
                                          value={e.sac_surveyor_addl_days}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              re.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              row[idx].sac_surveyor_addl_days =
                                                e.target.value;
                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () =>
                                                  this.surveyorAddDaysPriceCalculations(
                                                    idx
                                                  )
                                              );
                                            }
                                          }}
                                        />
                                      </div>

                                      <div className="col-lg-6 mb-1 pr-0">
                                        <label className="form_label mb-0">
                                          Price Per Day
                                        </label>
                                        <TextField
                                          name="sac_surveyor_price_per_addl_day"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Enter Value"
                                          value={
                                            e.sac_surveyor_price_per_addl_day
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              row[
                                                idx
                                              ].sac_surveyor_price_per_addl_day =
                                                e.target.value;

                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () =>
                                                  this.surveyorAddDaysPriceCalculations(
                                                    idx
                                                  )
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                      {!(
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) && (
                                        <div className="col-lg-6 mb-1 p-0">
                                          <label className="form_label mb-0">
                                            Currency
                                          </label>
                                          <TextField
                                            name="sac_surveyor_addl_days_currency"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Currency"
                                            //label="Currency"
                                            value={
                                              e.sac_surveyor_addl_days_currency
                                            }
                                            select
                                            onChange={(e) => {
                                              var row = this.state.multiRows;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_currency =
                                                e.target.value;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }}
                                          >
                                            {this.state.currencyData}
                                          </TextField>
                                        </div>
                                      )}
                                      {!(
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) &&
                                        e.sac_surveyor_addl_days_currency !==
                                          "IDR" &&
                                        e.sac_surveyor_addl_days_currency !==
                                          "0" && (
                                          <div className="col-lg-6 mb-1 pr-0">
                                            <label className="form_label mb-0">
                                              Exchange Rate
                                            </label>
                                            <TextField
                                              name="sac_surveyor_addl_days_currency_xchg_rate"
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              inputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              placeholder="Enter Value"
                                              value={
                                                e.sac_surveyor_addl_days_currency_xchg_rate
                                              }
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  re.test(e.target.value)
                                                ) {
                                                  var row =
                                                    this.state.multiRows;
                                                  row[
                                                    idx
                                                  ].sac_surveyor_addl_days_currency_xchg_rate =
                                                    e.target.value;

                                                  this.setState(
                                                    {
                                                      multiRows: row,
                                                    },
                                                    () =>
                                                      this.surveyorAddDaysPriceCalculations(
                                                        idx
                                                      )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      {!(
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) &&
                                        e.sac_surveyor_addl_days_currency !==
                                          "IDR" &&
                                        e.sac_surveyor_addl_days_currency !==
                                          "0" && (
                                          <div className="col-lg-6 mb-1 p-0">
                                            <label className="form_label mb-0">
                                              Price in IDR
                                            </label>
                                            <div className="">
                                              {e.sac_surveyor_addl_days_ctc_in_local_currency
                                                ? this.toLocaleString(
                                                    e.sac_surveyor_addl_days_ctc_in_local_currency
                                                  ) + " IDR"
                                                : "-"}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div
                                    className="col-lg-7 p-2 rounded"
                                    style={{ backgroundColor: "#f7f3f0" }}
                                  >
                                    <div className="row col-lg-12 p-0">
                                      <h6>Additional Charges</h6>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-4 p-0 mb-2">
                                        <label
                                          className="form_label mb-2"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Base price
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.sac_surveyor_addl_days_base_price
                                            ? this.toLocaleString(
                                                e.sac_surveyor_addl_days_base_price
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 pr-0 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          VAT %
                                        </label>
                                        <TextField
                                          name="sac_surveyor_addl_days_vat_percent"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          value={
                                            e.sac_surveyor_addl_days_vat_percent
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;

                                              let sac_surveyor_addl_days_vat_value =
                                                (
                                                  (Number(
                                                    row[idx]
                                                      .sac_surveyor_addl_days_base_price
                                                  ) *
                                                    Number(e.target.value)) /
                                                  100
                                                ).toFixed(2);
                                              let sac_surveyor_addl_days_pph23_value =
                                                (
                                                  (Number(
                                                    row[idx]
                                                      .sac_surveyor_addl_days_base_price
                                                  ) *
                                                    Number(
                                                      row[idx]
                                                        .sac_surveyor_addl_days_pph23_percent
                                                    )) /
                                                  100
                                                ).toFixed(2);
                                              if (
                                                config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_surveyor_addl_days_pph23_value = 0;
                                              }
                                              let sac_surveyor_addl_days_total =
                                                (
                                                  Number(
                                                    row[idx]
                                                      .sac_surveyor_addl_days_base_price
                                                  ) +
                                                  Number(
                                                    sac_surveyor_addl_days_vat_value
                                                  ) -
                                                  Number(
                                                    sac_surveyor_addl_days_pph23_value
                                                  )
                                                ).toFixed(2);
                                              let sac_surveyor_addl_days_ctc = (
                                                Number(
                                                  row[idx]
                                                    .sac_surveyor_addl_days_base_price
                                                ) +
                                                Number(
                                                  sac_surveyor_addl_days_vat_value
                                                )
                                              ).toFixed(2);

                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_vat_percent =
                                                e.target.value;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_vat_value =
                                                sac_surveyor_addl_days_vat_value;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_total = sac_surveyor_addl_days_total;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_ctc = sac_surveyor_addl_days_ctc;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-4 pr-0 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          VAT
                                        </label>
                                        <TextField
                                          name="sac_surveyor_addl_days_vat_value"
                                          margin="dense"
                                          //variant='outlined'
                                          fullWidth
                                          value={
                                            e.sac_surveyor_addl_days_vat_value
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;

                                              //let sac_surveyor_addl_days_vat_value = ((Number(sac_surveyor_addl_days_base_price)*Number(this.state.sac_surveyor_addl_days_vat_percent))/100).toFixed(2);
                                              let sac_surveyor_addl_days_pph23_value =
                                                (
                                                  (Number(
                                                    row[idx]
                                                      .sac_surveyor_addl_days_base_price
                                                  ) *
                                                    Number(
                                                      row[idx]
                                                        .sac_surveyor_addl_days_pph23_percent
                                                    )) /
                                                  100
                                                ).toFixed(2);
                                              if (
                                                config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_surveyor_addl_days_pph23_value = 0;
                                              }
                                              let sac_surveyor_addl_days_total =
                                                (
                                                  Number(
                                                    row[idx]
                                                      .sac_surveyor_addl_days_base_price
                                                  ) +
                                                  Number(e.target.value) -
                                                  Number(
                                                    sac_surveyor_addl_days_pph23_value
                                                  )
                                                ).toFixed(2);
                                              let sac_surveyor_addl_days_ctc = (
                                                Number(
                                                  row[idx]
                                                    .sac_surveyor_addl_days_base_price
                                                ) + Number(e.target.value)
                                              ).toFixed(2);

                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_vat_value =
                                                e.target.value;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_pph23_value =
                                                sac_surveyor_addl_days_pph23_value;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_total = sac_surveyor_addl_days_total;
                                              row[
                                                idx
                                              ].sac_surveyor_addl_days_ctc = sac_surveyor_addl_days_ctc;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      {!(
                                        config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) && (
                                        <div className="col-lg-6 p-0 mb-3">
                                          <label
                                            className="form_label mb-2"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            Includes PPH23 @ 2%
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {e.sac_surveyor_addl_days_pph23_value
                                              ? this.toLocaleString(
                                                  e.sac_surveyor_addl_days_pph23_value
                                                )
                                              : "-"}
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      <div
                                        className={
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                            ? "col-lg-6 p-0"
                                            : "col-lg-6 p-0"
                                        }
                                      >
                                        <label
                                          className="form_label mb-2"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Payable to Vendor
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.sac_surveyor_addl_days_total
                                            ? this.toLocaleString(
                                                e.sac_surveyor_addl_days_total
                                              )
                                            : 0}{" "}
                                          {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                            ? "col-lg-6 pr-0"
                                            : "col-lg-6 pr-0"
                                        }
                                      >
                                        <label
                                          className="form_label mb-2"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Cost to Company
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.sac_surveyor_addl_days_ctc
                                            ? this.toLocaleString(
                                                e.sac_surveyor_addl_days_ctc
                                              )
                                            : 0}{" "}
                                          {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {this.state.multiRows.length > 1 && (
                          <center className="col-lg-1 d-flex justify-content-center">
                            <i
                              className="fa fa-trash-o"
                              onClick={this.removeRowsHandler(idx)}
                              style={{
                                paddingLeft: 10,
                                fontSize: 20,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </center>
                        )}
                      </div>
                    ))}
                    <div className="row">
                      <button
                        type="button"
                        onClick={() => this.addMoreRowsHandler()}
                        className="header_button header_button_text addrow_button_adjustment w-auto"
                        style={{ color: config.themeColor }}
                      >
                        ADD ROW
                      </button>
                    </div>
                  </div>
                )}
                {/* {(this.state.features.indexOf("530") >= 0 ||
                  this.state.features.indexOf("97") >= 0) && ( */}
                <FileUpload
                  files={this.state.files}
                  invoice_file={this.state.invoice_file}
                  type="Costings"
                  feature_name="Barge_sac_charges"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="back"
              onClick={() => this.props.onCancel(false)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn next_button"
              name="submit"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </div>
        </div>
      );
    }
  }
}
